import React, { useEffect } from 'react';
import { ReactComponent as Stop } from '../../../assets/svg/Stop.svg';
import { ReactComponent as EditContent } from '../../../assets/svg/EditContent.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/DownloadIcon.svg';
import { ReactComponent as DeleteContentIcon } from '../../../assets/svg/DeleteContentIcon.svg';
import { ReactComponent as NotFoundIcon } from '../../../assets/svg/NotFoundIcon.svg';
import { baseUrl } from '../../../constants/Connections';
import { useTranslation } from 'react-i18next';
import { capitaliseFirstLetter, format_day, translateTargetText } from '../../../utils/util';
import ProgressBar from '../../../components/ProgressBar';
import BarCharts from '../../dashboard/BarCharts';
import { BarColors } from '../../../constants/BarColors';
import ContentJournalSection from './ContentJournalSection';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { getJournalById, stopContent } from '../../../server/server';
import i18n from '../../../i18n';
import Loading from '../../Loading';
function ContentJournalResults({ content, setRefresh, deleteContent = { deleteContent } }) {
	const [exporting, setExporting] = useState(false);
	const [contentData, setContentData] = useState({});
	const [loading, setLoading] = useState(true);
	const { t, locale } = useTranslation();

	useEffect(() => {
		loadResults();
	}, []);

	const exportData = async () => {
		setExporting(true);
		const token = localStorage.getItem('token');
		const data = content;
		var req = {
			method: 'POST',
			url: `${baseUrl}/api/export_dashboard_content`,
			responseType: 'arraybuffer',
			headers: { Authorization: token },
			data: data,
		};
		var reader = new FileReader();

		reader.onload = function (e) {
			window.open(decodeURIComponent(reader.result), '_self', '', false);
		};

		axios(req).then(
			function (response) {
				var contentType = response.headers['content-type'];
				var blob = new Blob([response.data], { type: contentType });

				var a = document.createElement('a');
				document.body.appendChild(a);
				a.style = 'display: none';

				var url = window.URL.createObjectURL(blob);
				a.href = url;

				a.download = `Content data-${content?.title}.xlsx`;
				a.click();
				setExporting(false);
			},
			function (resp) {}
		);
	};
	const stop = async () => {
		const resp = await stopContent({ id: content?.id });
		if (resp.status == 200) {
			content.status = 'stop';
			setRefresh(content?.id);
		}
	};

	const loadResults = async () => {
		const resp = await getJournalById(content.id);
		if (resp.status == 200) {
			setContentData(resp.data);
			setLoading(false);
		}
	};

	return (
		<div>
			<div className='bg-[#e2f5e7] w-full p-5 px-6 rounded-xl'>
				<div className='flex justify-between items-center'>
					<h2 className='font-bold text-xl  text-deepPurple text-left capitalize'>{t(content?.type)}</h2>
				</div>
				{content?.status == 'stop' ? (
					<div></div>
				) : (
					<div className='flex flex-row w-full justify-between items-center mt-4'>
						<div className='flex flex-row'>
							<div
								className='mr-3 border pointer fill-mudPurple bg-lightBG hover:fill-white hover:bg-deselectPurple text-mudPurple hover:text-white border-deselectPurple py-3 px-5 rounded-full w-52 flex justify-center items-center'
								onClick={stop}
							>
								<span className='text-[16px] font-semibold  mr-3'>{t('stop')}</span> <Stop style={{ fill: 'inherit' }} />
							</div>
						</div>

						<div
							onClick={() => deleteContent(content)}
							className='border pointer fill-deleteRed bg-lightBG hover:fill-white hover:bg-deleteRed text-deleteRed hover:text-white border-deleteRed py-3 px-5 rounded-full w-52 flex justify-center items-center'
						>
							<span className='text-[16px] font-semibold  mr-3'>{t('delete')}</span>{' '}
							<DeleteContentIcon style={{ fill: 'inherit' }} />
						</div>
					</div>
				)}
			</div>
			<div className='px-2 py-3 rounded-xl'>
				<div className='gray-small-scroll px-5' style={{ overflowY: 'scroll', height: '50vh' }}>
					{loading ? (
						<div className='w-full h-full flex items-center justify-center'>
							<Loading size={40} stroke={5} />
						</div>
					) : (
						<>
							<div className='flex justify-between items-center mb-3'>
								<p className='text-sm text-mudPurple'>
									<span className='text-[#9392ad99]'>{t('by')}:</span> {content?.created_by?.first_name}{' '}
									{content?.created_by?.last_name}
								</p>
								<span className='text-mudPurple font-medium text-sm'>
									<span className='text-[#9392ad99]'>{t('target')}:</span> {translateTargetText(content?.target_text)}
								</span>
								<p>
									<span className='text-[#9392ad99] text-sm'>
										{t('date')} & {t('time')}:{' '}
									</span>
									<span className='text-mudPurple text-sm'>
										{format_day(contentData?.publicationDate, true, i18n.language)}
									</span>
								</p>
							</div>

							<div className='single-content-body my-3 flex justify-between items-start'>
								<h2 className='text-deepPurple font-bold text-xl text-left max-w-xl'>{content?.title}</h2>
								<p className='text-right text-sm text-mudPurple font-medium'>
									{content?.answeredUsers?.length}/{content?.target_count}
								</p>
							</div>
							<p className='text-sm text-mudPurple text-left mt-3'>{content?.description}</p>
							<div>
								{contentData?.days?.map((section, index) => {
									return (
										<ContentJournalSection
											section={section}
											index={index}
											targetCount={content?.target_count}
											journalId={content.id}
										/>
									);
								})}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default ContentJournalResults;
