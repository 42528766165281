import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as Calendar } from '../../assets/svg/Calendar.svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { localeMap } from '../../constants/Constants';

function ReminderModal({ profile, modalIsOpen, createReminder, closeModal, selectedNote }) {
	const { t, locale } = useTranslation();
	const [title, setTitle] = useState(t('createReminder'));
	const [startDate, setStartDate] = useState(selectedNote?.reminder ? new Date(selectedNote.reminder) : new Date());
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

	React.useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);
	useEffect(() => {
		if (selectedNote !== null) {
			setTitle(t('editReminder'));
			setStartDate(new Date(selectedNote?.reminder));
			console.log('profile reminder : edit : ', selectedNote);
		} else {
			setTitle(t('createReminder'));
			console.log('profile reminder : create : ', selectedNote);
			setStartDate(new Date());
		}
	}, [modalIsOpen]);

	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 345,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '80%',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
					zIndex: 1,
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className='feedback-modal-icon' onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
			</div>
			<div className='reminder-modal'>
				<div
					className='reminder-modal-header'
					style={{ flexDirection: 'column', justifyContent: 'center', background: '#ECEBF8', minHeight: 154 }}
				>
					<p>{title}</p>
					<p>{t('Set a date when you would like to be reminded about this student')}</p>
				</div>
				<div className='reminder-modal-body'>
					<div
						className='reminder-datepicker '
						onClick={(e) => {
							e.preventDefault();
							setIsDatePickerOpen(true);
						}}
					>
						<Calendar style={{ color: '#5E639B' }} />
						<p>
							{t('Choose a date')}: <span>{startDate.toLocaleDateString()}</span>
						</p>
					</div>
					<div style={{ position: 'absolute', top: 0, left: 0, visibility: 'hidden' }}>
						<LocalizationProvider adapterLocale={localeMap[i18n.language]} dateAdapter={AdapterDateFns}>
							<DesktopDatePicker
								open={isDatePickerOpen}
								inputFormat='MM/dd/yyyy'
								value={startDate}
								onClose={() => setIsDatePickerOpen(false)}
								onChange={(date) => {
									setStartDate(date);
								}}
								onAfterClose={() => setIsDatePickerOpen(false)}
								closeOnSelect={true}
								renderInput={(params) => <TextField {...params} />}
							/>
						</LocalizationProvider>
					</div>
					<div
						className='reminder-submit'
						onClick={(e) => {
							e.preventDefault();
							createReminder(startDate);
							closeModal();
						}}
					>
						<p>{t('done')}</p>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default ReminderModal;
