import React from 'react';
import { useTranslation } from 'react-i18next';

function DonutPrimary() {
	const { t, locale } = useTranslation();
	return (
		<div
			className='rounded-full flex items-center justify-center'
			style={{
				width: 154,
				height: 154,
				marginTop: -24,
				marginBottom: -24,
				backgroundColor: '#ffffff',
			}}
		>
			<div
				className='rounded-full w-[106px] h-[106px] flex items-center justify-center p-3 '
				style={{ background: `linear-gradient(154deg, #595776 11%, #83819f 88.7%), #ECEBF8` }}
			>
				<p className='text-white text-[16px] leading-5 font-bold flex-wrap text-center'>{t('Primary class')}</p>
			</div>
		</div>
	);
}

export default DonutPrimary;
