import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/TargetGroupModal.css';
import { FaTimes, FaChevronLeft, FaSpinner, FaLastfmSquare, FaArrowRight, FaChevronRight } from 'react-icons/fa';
import { getBy, deleteById, getTarget, selectAllStaffAsync } from '../../server/server';
import { useUser } from '../../auth';
import ConfirmationModal from './ConfirmationModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import CreateTargetFavoriteModal from './CreateTargetFavoriteModal';
import { loadTargetItemsList, getAllStudentsTarget } from '../../server/server';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as FavStar } from '../../assets/svg/favStar.svg';
import { ReactComponent as TargetUndo } from '../../assets/svg/undoTarget.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/SearchIcon.svg';
import { useAppContext } from '../../context/AppContext';
import { RESULT_PREFIX, SUBTITLE, TITLE } from '../../constants/Constants';

function TargetGroupModal({ showTarget, closeTarget, setReturnTarget }) {
	const { t, locale } = useTranslation();
	const [searchTerm, setSearchTerm] = useState('');
	const { appState } = useAppContext();
	const additionalFields = appState?.additionalFields?.fields ?? [];
	const [mode, setMode] = useState('target');
	const [additionalFieldOpened, setAdditionalFieldOpened] = useState(null);
	const [showFavorite, setShowFavorite] = useState(false);
	const [deleteModalOpen, setDelModalOpen] = useState(false);
	const [favID, setFavID] = useState(null);
	const [loading, setLoading] = useState(true);
	const [results, setResults] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [resultsNum, setResultsNum] = useState(0);
	const { user, setUser } = useUser();
	const [hoveredButton, setHoveredButton] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [checkedResults, setCheckedResults] = useState([]);

	useEffect(() => {
		if (showTarget) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [showTarget]);

	useEffect(() => {
		if (mode !== 'target') {
			setResults([]);
			fetchResults(0, searchTerm);
			setLoading(true);
		}
		setResultsNum(0);
	}, [searchTerm]);

	useEffect(() => {
		if (mode !== 'target') {
			fetchResults();
			setLoading(true);
		}
	}, [mode]);

	const closeFavorite = () => {
		setShowFavorite(false);
	};
	const closeDelModal = () => {
		setDelModalOpen(false);
	};
	const deleteFavorite = async () => {
		const resp = await deleteById({ collection: 'favorite_target', id: favID });
		if (resp.status) {
			setDelModalOpen(false);
			setSearchTerm(' ');
			setSearchTerm('');
			setCheckedResults([]);
			checkedResults.filter((e) => toString(e) !== toString(favID));
		}
	};
	const fetchResults = async () => {
		const data = {
			user_id: user?.id,
			type: mode,
			text: searchTerm,
			numberOfLoadedItems: resultsNum,
			resetArray: false,
			additionalField: additionalFieldOpened,
		};
		const response = await loadTargetItemsList(data);
		const array = response?.data?.array;
		const size = response?.data?.size;
		const filter = response?.data?.filter;
		if (array) {
			if (resultsNum === 0) {
				setLoading(true);
				setResults(array);
			} else {
				setResults((prev) => [...prev, ...array]);
			}

			setLoading(false);
			setHasMore(size > results?.length);
		} else {
			setResults([]);
			setResultsNum(0);
		}
	};
	const handleMyStudents = () => {
		loadTarget(true);
		closeTarget();
	};

	const openDeleteModal = (id) => {
		setDelModalOpen(true);
		setFavID(id);
	};

	const closeModal = () => {
		setMode('target');
		setResultsNum(0);
		setSearchTerm('');
		closeTarget();
	};
	const loadTarget = async (isMyStudents = false) => {
		if (isMyStudents) {
			const response = await getAllStudentsTarget();
			if (response.status === 200) {
				setReturnTarget(response.data);
			}
		} else {
			const response = await getTarget({
				target: checkedResults,
				type: isMyStudents ? 'root' : mode,
			});
			if (response.status === 200) {
				setReturnTarget(response.data);
			}
		}
	};
	const loadAllStaff = async (isMyStudents = false) => {
		const response = await selectAllStaffAsync();
		if (response.status === 200) {
			setReturnTarget(response.data);
			closeTarget();
		}
	};
	useEffect(() => {
		if (mode !== 'target') {
			fetchResults();
			setLoading(true);
		}
	}, [resultsNum]);

	useEffect(() => {}, [searchTerm]);

	const handleContinue = () => {
		loadTarget();
		closeTarget();
	};
	const handleBack = () => {
		setMode('target');
		setResults([]);
		setResultsNum(0);
		setSearchTerm('');
		setCheckedResults([]);
		setAdditionalFieldOpened(null);
	};
	const selectedResults = (id) => {
		if (checkedResults.includes(id)) {
			console.log(
				'vec postoji',
				checkedResults.filter((e) => toString(e) !== toString(id))
			);
			if (mode === 'favorite') {
				setCheckedResults([]);
			} else {
				setCheckedResults(checkedResults.filter((y) => y !== id));
			}
		} else {
			if (mode === 'favorite') {
				setCheckedResults([id]);
			} else {
				setCheckedResults([...checkedResults, id]);
			}
		}
	};

	const handleSelectAll = (all) => {
		loadAllStaff();
	};

	return (
		<ReactModal
			closeTimeoutMS={600}
			isOpen={showTarget}
			onRequestClose={closeModal}
			shouldCloseOnOverlayClick={true}
			className='target-modal noScroll'
			style={{
				overlay: {
					zIndex: 5,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 490,
					minWidth: 490,
					margin: 'auto',
					paddingTop: '10px',
					backgroundColor: 'transparent',
					border: 'none',
					maxHeight: '100%',
					boxShadow: 'none',
					overflow: 'scroll',
					position: 'relative',
					paddingBottom: 35,
				},
			}}
		>
			<div className={`w-full justify-center flex  ${i18n.language == 'ar' ? 'ar-lang' : ''}`}>
				<div
					className='hover:bg-darkenPurple bg-deepPurple pointer duration-[230ms] ease-in-out transition-all absolute top-[20px] flex rounded-full w-[60px] h-[60px] justify-center items-center'
					onClick={() => closeTarget()}
				>
					<CloseModalIcon />
				</div>
				{mode === 'target' && (
					<div className='bg-containerGray rounded-xl mt-10 w-full p-8 '>
						<div className=''>
							<h2 className='text-deepPurple font-bold text-3xl mt-3 text-left'>{t('targetGroup')}</h2>
							<p className='text-left text-mudPurple text-sm mt-2'>{t('selectACategory')} </p>
						</div>
						<div className='mt-5'>
							<div className='flex flex-row justify-between items-center'>
								<div
									className='text-deepPurple font-semibold pointer text-base py-3 px-4 border border-deselectPurple hover:border-deepPurple rounded-full'
									onClick={() => handleMyStudents()}
								>
									{t('allStudents')}
								</div>
								<div
									className='flex items-center text-deepPurple pointer font-semibold text-base py-3 px-4 border border-deselectPurple hover:border-deepPurple rounded-full'
									onClick={() => setMode('favorite')}
								>
									<FavStar />
									<span className='ml-2'>{t('favorites')}</span>
								</div>
							</div>
						</div>
						<div className='flex flex-col mt-8 w-full gap-6'>
							<div className='flex flex-row gap-6 w-full  justify-center'>
								<div
									className='bg-white rounded-xl p-5 w-[194px] pointer border-[white] border transition-all ease-in-out duration-[230ms] hover:border-[#5E639B]'
									onClick={() => setMode('year')}
								>
									<p className='text-deepPurple font-bold text-lg text-left'>{t('years')}</p>
									<p className='text-sm text-mudPurple text-left mt-2'>
										{t('selectSpecificYearGroupsToGatherMoreInformationFrom')}
									</p>
								</div>
								<div
									className='bg-white rounded-xl p-5 w-[194px] pointer border-[white] border transition-all ease-in-out duration-[230ms] hover:border-[#5E639B]'
									onClick={() => setMode('class')}
								>
									<p className='text-deepPurple font-bold text-lg text-left'>{t('classes')}</p>
									<p className='text-sm text-mudPurple text-left mt-2'>
										{t('Select specific classes to gather more information from')}
									</p>
								</div>
							</div>
							<div className='flex flex-row gap-6 w-full justify-center'>
								<div
									className='bg-white rounded-xl p-5 w-[194px] pointer border-[white] border transition-all ease-in-out duration-[230ms] hover:border-[#5E639B]'
									onClick={() => setMode('custom')}
								>
									<p className='text-deepPurple font-bold text-lg text-left'>{t('customize')}</p>
									<p className='text-sm text-mudPurple text-left mt-2'>
										{t('Create your own customized group to gather information from')}
									</p>
								</div>

								<div
									className='bg-white rounded-xl p-5 w-[194px] pointer border-[white] border transition-all ease-in-out duration-[230ms] hover:border-[#5E639B]'
									onClick={() => setMode('staff')}
								>
									<p className='text-deepPurple font-bold text-lg text-left'>{t('staff')}</p>
									<p className='text-sm text-mudPurple text-left mt-2'>
										{t('Select specific teachers you would like to get information from')}
									</p>
								</div>
							</div>
							{additionalFields?.length > 0 && (
								<div className='flex flex-row flex-wrap gap-6'>
									{additionalFields?.map((field) => (
										<div
											className='bg-white rounded-xl p-5 w-[194px] pointer border-[white] border transition-all ease-in-out duration-[230ms] hover:border-[#5E639B]'
											onClick={() => {
												setAdditionalFieldOpened(field);
												setMode(field?.code);
											}}
											key={field?.code}
										>
											<p className='text-deepPurple font-bold text-lg text-left'>{t(field?.title)}</p>
											<p className='text-sm text-mudPurple text-left mt-2'>Filter by this category</p>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
				)}
				{mode !== 'target' && (
					<div
						className='bg-containerGray rounded-xl mt-10 h-full w-full p-8 dark-small-scroll'
						style={{ maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' }}
					>
						<div className='my-5'>
							<div className='text-deepPurple text-3xl font-bold text-left flex flex-row items-center justify-start'>
								<div
									className='border flex pointer mr-5 items-center justify-center duration-[230ms] ease-in-out transition-all border-deselectPurple hover:border-deepPurple min-w-[56px] w-[56px] h-[56px] rounded-full'
									onClick={() => handleBack()}
									onMouseEnter={() => setHoveredButton(true)}
									onMouseLeave={() => setHoveredButton(false)}
								>
									<TargetUndo
										fill={hoveredButton ? '#5e639b' : '#9392AD'}
										style={{ transition: 'fill 230ms ease-in-out' }}
									/>
								</div>
								<div>
									<h2>{!!additionalFieldOpened ? additionalFieldOpened?.title : t(TITLE[mode])}</h2>
									<p
										className='text-left text-sm text-mudPurple'
										style={i18n.language == 'ar' ? { textAlign: 'right' } : {}}
									>
										{!!additionalFieldOpened ? additionalFieldOpened?.subtitle : t(SUBTITLE[mode])}
									</p>
								</div>
							</div>
						</div>

						<div className=''>
							<div className='stroke-deselectPurple hover:stroke-deepPurple flex flex-row items-center border-deselectPurple border py-3 px-4 w-full rounded-full'>
								<input
									type='text'
									placeholder={t('search')}
									value={searchTerm}
									className='w-full h-full text-deepPurple font-semibold text-sm'
									onChange={(e) => setSearchTerm(e.target.value)}
									style={{ background: 'transparent' }}
								/>
								<SearchIcon className='w-[19px] h-[19px]' stroke='#5E639B' />
							</div>
							<div className='bg-white rounded-xl my-4 px-5 py-4 max-h-[530px]'>
								<div className='gray-small-scroll  max-h-[300px]  overflow-y-auto' id='scroll-inf-target'>
									<InfiniteScroll
										dataLength={results.length}
										next={() => setResultsNum(results.length)}
										hasMore={hasMore}
										scrollableTarget='scroll-inf-target'
									>
										{results?.map((result) => {
											return (
												<div
													className='flex items-center justify-start py-2 pointer hover:opacity-70'
													key={result.id}
													onClick={() => selectedResults(result.id)}
												>
													<div className='bg-white  border-[1.5px] w-[12px] h-[12px] rounded-full flex items-center justify-center'>
														{checkedResults.includes(result.id) && (
															<div className='rounded-full w-[6px] h-[6px]  bg-deepPurple'></div>
														)}
													</div>
													<p
														style={{
															flex: '1',
															display: 'flex',
															alignItems: 'center',
															marginLeft: '5px',
															textAlign: 'left',
														}}
													>
														<span
															style={{
																fontWeight: 600,
																color: checkedResults.includes(result.id) ? '#5E639B' : '#9392AD',
																flex: 1,
															}}
														>
															{t(RESULT_PREFIX[mode]) + ' '}
															{result.name}
														</span>
														{additionalFieldOpened && (
															<span className='pr-[10px] text-[#BEBECE] font-medium text-[16px]'>
																{result[additionalFieldOpened.code]}
															</span>
														)}
													</p>
												</div>
											);
										})}
									</InfiniteScroll>
									{results.length === 0 && (
										<div className='target_body_empty'>
											<p>{t('thereIsNoDataToShow')}</p>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className='flex flex-row items-center justify-between gap-1'>
							{mode === 'staff' && (
								<div
									className='text-deepPurple text-lg font-semibold border text-center items-center justify-center flex border-mudPurple hover:border-deepPurple pointer rounded-full h-[65px] w-[120px]'
									onClick={handleSelectAll}
								>
									{allSelected ? t('deselectAll') : t('selectAll')}
								</div>
							)}
							{checkedResults.length !== 0 && (
								<>
									{' '}
									{mode === 'favorite' && (
										<div
											className='hover:bg-deleteRed text-deleteRed hover:text-white border border-deleteRed text-lg font-semibold items-center justify-center flex h-[65px] w-[120px] pointer rounded-full'
											onClick={() => openDeleteModal(checkedResults[0])}
										>
											{t('delete')}
										</div>
									)}
									{mode !== 'favorite' && (
										<div
											className='text-deepPurple text-lg font-semibold border items-center text-center justify-center flex border-mudPurple hover:border-deepPurple pointer rounded-full h-[65px] w-[120px]'
											onClick={() => setShowFavorite(true)}
										>
											{t('addToFavorites')}
										</div>
									)}
									<div
										className='bg-deepPurple hover:bg-highlightColor text-highlightColor hover:text-deepPurple text-lg font-semibold items-center justify-center flex h-[65px] w-[120px] pointer uppercase rounded-full'
										onClick={() => handleContinue()}
									>
										{t('ok')}
									</div>
								</>
							)}
						</div>
					</div>
				)}
				<CreateTargetFavoriteModal showFav={showFavorite} closeFav={closeFavorite} checkedResults={checkedResults} type={mode} />
				<ConfirmationModal
					modalIsOpen={deleteModalOpen}
					closeModal={closeDelModal}
					confirmation={deleteFavorite}
					message={t('doYouWantToDeleteFavorite')}
				/>
			</div>
		</ReactModal>
	);
}

export default TargetGroupModal;
