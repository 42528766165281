import React, { useEffect } from 'react';
import { ReactComponent as DropDownSurvey } from '../../../assets/svg/dropdownSurvey.svg';
import { ReactComponent as DropUpSurvey } from '../../../assets/svg/dropupSurvey.svg';
import BarCharts from '../../dashboard/BarCharts';
import { BarColors } from '../../../constants/BarColors';
import { useState } from 'react';
import { emotions_color, emotions_list } from '../../../constants/Constants';
import { getSurveyResult } from '../../../server/server';
import Loading from '../../Loading';
import { useTranslation } from 'react-i18next';
function ContentSurveyQuestion({
	question,
	qi,
	index,
	survey,
	filterName = '',
	realAnswers = [],
	filteredUsers = [],
	filteredResults = [],
}) {
	const [isExpanded, setIsExpanded] = useState(false);
	const [results, setResults] = useState([]);
	const [resultsLoaded, setResultsLoaded] = useState(false);
	const { t, locale } = useTranslation();

	useEffect(() => {
		if (isExpanded && !resultsLoaded) loadResults();
	}, [isExpanded, resultsLoaded]);

	const loadResults = async () => {
		const resp = await getSurveyResult(survey?.id, index, qi);
		if (resp.status == 200) {
			const data = resp.data;
			setResults(data?.users ?? []);
			setResultsLoaded(true);
		}
	};

	const filterData = (item) => {
		return filteredUsers.includes(item.id);
	};

	return (
		<div key={qi} className={`p-4 rounded-xl my-5 ${isExpanded ? 'bg-lightBG' : 'bg-[#f4f4fc]'}`}>
			<div className='flex flex-row justify-between items-start'>
				<div className='max-w-[45rem]'>
					<h3 className='font-bold text-deepPurple text-xl text-left'>
						{index + 1}. {qi + 1}. {question?.title}
					</h3>
					{question.type === 'openAnswer' && <p className='text-left text-sm mt-2 text-mudPurple'>{t('open_question')} </p>}
					{!!question?.description && <p className='text-left text-sm mt-2 text-mudPurple'>{}</p>}
				</div>
				<div
					className={`pointer rounded-full w-[56px] h-[56px] flex items-center justify-center ${
						isExpanded ? 'bg-deselectPurple' : 'border border-deselectPurple'
					}`}
					onClick={() => setIsExpanded(!isExpanded)}
				>
					{isExpanded ? <DropUpSurvey /> : <DropDownSurvey />}
				</div>
			</div>
			{question.type !== 'openAnswer' && (
				<div className='mt-6'>
					<BarCharts
						options={question?.options}
						answers={realAnswers}
						showNum={true}
						subData={filteredResults.answers}
						haveSubData
						index={index}
					/>
				</div>
			)}
			{isExpanded && (
				<div>
					<h2 className='font-bold text-xl  text-deepPurple text-left mt-4'>{t('answers')} </h2>
					{!resultsLoaded && (
						<div className='w-full flex items-center justify-center h-[50px]'>
							<Loading />
						</div>
					)}
					<div>
						{results?.filter(filterData)?.map((userData, indexA) => {
							return (
								<div
									className='bg-containerGray w-full py-2 px-2 flex items-center justify-start text-left my-2 rounded-xl'
									key={indexA}
								>
									<div
										className='w-[10px] h-[10px] rounded-full mr-3'
										style={{
											background: BarColors[question?.options?.indexOf(userData?.answer) % BarColors?.length],
										}}
									></div>
									<div className='flex flex-col justify-between'>
										<p className='text-[16px] font-bold text-deepPurple'>
											{userData?.first_name} {userData?.last_name}
										</p>
										<p className='text-sm text-mudPurple'>{userData?.answer}</p>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
}

export default ContentSurveyQuestion;
