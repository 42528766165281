import React, { useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/DayReflectionModal.css';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as SecretKeyLogo } from '../../assets/svg/SecretKeyLogo.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { notify } from '../../utils/util';

function NewSecretKeyModal({ modalIsOpen, closeModal }) {
	const [secretKey, setSecretKey] = useState('');
	const { t } = useTranslation();

	const handleSubmit = async (e) => {
		e.preventDefault();
		notify(t('secretKeyHasBeenSaved'));
		await localStorage.setItem('secret_key', secretKey);
		closeModal();
	};

	React.useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);
	return (
		<ReactModal
			transparent
			isOpen={modalIsOpen}
			ariaHideApp={false}
			onAfterOpen={() => {}}
			onAfterClose={() => {}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 99,
				},
				content: {
					inset: 0,
					padding: 0,
					margin: 0,
					marginTop: 30,
					opacity: 1,
					width: 490,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className='day-reflection-modal-icon' onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
			</div>
			<div className='new-secret-key'>
				<SecretKeyLogo />
				<p className='new-secret-title'>{t('staySecuredFirst')}</p>
				<p className='new-secret-subtitle'>
					{t('Attention')}: {t('toEnsureYourPrivacyThisKeyIsNotRecoverableAllYourNotesWillBeLostIfYouForgetThisKey')}
				</p>
				<div style={{ width: '100%' }}>
					<p className='new-secret-key-label'>{t('secretKey')}</p>
					<input
						className='new-secret-key-input'
						type='password'
						placeholder={t('enterSecretKey')}
						value={secretKey}
						onChange={(e) => setSecretKey(e.target.value)}
						autoComplete='off'
					/>
				</div>
				<button className='new-secret-save-button' onClick={handleSubmit} disabled={!secretKey?.length}>
					<p className='new-secret-save-text'>{t('save')}</p>
				</button>
			</div>
		</ReactModal>
	);
}

export default NewSecretKeyModal;
