import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../auth';
import { update } from '../../server/server';
import { checkTextStrength, notify } from '../../utils/util';

function ChangePassword() {
	const { user, setUser } = useUser();
	// const {addToast} = useToasts()
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState(user.email);
	const [retypedPassword, setRetypedPassword] = useState('');
	const [newPasswordError, setNewPasswordError] = useState('');
	const { t } = useTranslation();

	const handleSubmit = async (e) => {
		e.preventDefault();

		let _user = { ...user, email };
		if (password === '' && retypedPassword === '') return;
		if (password !== retypedPassword) {
			setNewPasswordError(t('passwordsDoNotMatch'));
			setRetypedPassword('');
			setPassword('');
			return;
		} else if (!checkTextStrength(password)) {
			setNewPasswordError(t('passwordMustBeLongerThan8CharactersAndMustContainsUppercaseLowercaseNumberAndSpecialSymbol'));
			setRetypedPassword('');
			setPassword('');
			return;
		}
		_user['password'] = password;
		_user['new_password'] = true;

		const resp = await update({ collection: 'users', data: _user });
		if (resp.status === 200) {
			notify(t('userIsUpdated'));
			setUser(_user);
		}
	};
	return (
		<div>
			<div className='personal-info-container p-10'>
				<div className='text-3xl text-deepPurple font-bold text-left'>{t('changePassword')}</div>
				<p className='text-left flex justify-start mt-3 text-mudPurple text-base'>
					{t('passwordMustBeLongerThan8CharactersAndMustContainsUppercaseLowercaseNumberAndSpecialSymbol')}
				</p>
				<div className='profile-form'>
					<form onSubmit={handleSubmit}>
						<fieldset>
							<label htmlFor='password'>
								<p className='text-left flex justify-start mt-3 text-deepPurple font-semibold text-base'>{t('password')}</p>
							</label>
							<input
								className='w-full text-black border border-b-deselectPurple py-3 border-x-0 border-t-0'
								style={{ backgroundColor: 'transparent' }}
								type='password'
								name='user_password'
								placeholder={t('enterYourNewPassword')}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							{newPasswordError && <span className='input-error-text'>{newPasswordError}</span>}
						</fieldset>
						<fieldset>
							<label htmlFor='retyped_password'>
								<p className='text-left flex justify-start mt-3 text-deepPurple font-semibold text-base'>
									{t('retypePassword')}
								</p>
							</label>
							<input
								className='w-full text-black border border-b-deselectPurple py-3 border-x-0 border-t-0'
								style={{ backgroundColor: 'transparent' }}
								type='password'
								name='user_retyped_password'
								placeholder={t('repeatPassword')}
								value={retypedPassword}
								onChange={(e) => setRetypedPassword(e.target.value)}
							/>
						</fieldset>

						<button className='w-full uppercase font-bold mt-5 h-[55px] text-center justify-center items-center flex rounded-full bg-deepPurple text-white'>
							{t('save')}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default ChangePassword;
