import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { BarColors } from '../../constants/BarColors';
import '../../styles/dashboard/BarChart.css';
function BarCharts({ options, answers, showNum = false, showHover = false, subData: _subData = [], haveSubData = false, index = 0 }) {
	const [heightData, setHeightData] = useState({
		maxCount: 0,
		minCount: 0,
		usedMap: {},
	});
	const [additionalData, setAdditionalData] = useState(_subData);
	const [maxUsedHeight, setMaxUsedHeight] = useState(0);
	const [initialLoading, setInitialLoading] = useState(true);
	const isShowNum = (num) => {
		return showNum ? num + 25 : num;
	};
	useEffect(() => {
		const usedMap = [...options, ...answers].reduce((acc, curr) => ((acc[curr] = acc[curr] + 1 || 0), acc), {});
		const maxCount = Math.max(...Object.values(usedMap));
		const minCount = Math.min(...Object.values(usedMap));
		setHeightData({
			maxCount: maxCount,
			minCount: minCount,
			usedMap,
		});
		setMaxUsedHeight(`${maxCount == minCount ? (maxCount > 0 ? isShowNum(31) : isShowNum(6)) : isShowNum(62)}px`);
		setTimeout(() => setInitialLoading(false), 1000);
	}, []);

	useEffect(() => {
		if (haveSubData) {
			const subDataUsedMap = haveSubData
				? [...options, ..._subData].reduce((acc, curr) => ((acc[curr] = acc[curr] + 1 || 0), acc), {})
				: {};
			setAdditionalData(subDataUsedMap);
		}
	}, [_subData, haveSubData]);

	return (
		<div className='min-h-[70px] flex flex-row items-end '>
			<div className='flex-1 relative overflow-auto'>
				<div className='flex flex-row justify-between'>
					{options?.map((option, index) => {
						const color = BarColors[index % BarColors.length];
						return (
							<div className='flex flex-col  justify-end mx-[10px] flex-1'>
								{showNum && (
									<div
										className={`text-sm ${
											haveSubData ? 'text-[#9392AD4D]' : 'text-darkPurple'
										} h-[15px] mb-[10px] text-center`}
									>
										{heightData?.usedMap[option]}
									</div>
								)}
								<div
									className='progress-bar'
									style={{
										borderTopRightRadius: 10,
										borderTopLeftRadius: 10,
										background: haveSubData ? `#9392AD2E` : color,
										position: 'relative',
									}}
								>
									<div
										className='progress-bar-bg'
										style={{
											minWidth: `80px`,
											height: `${
												heightData?.maxCount == heightData?.minCount
													? heightData?.maxCount > 0
														? 31
														: 0
													: Math.round((62 * heightData?.usedMap[option]) / heightData?.maxCount)
											}px`,
											width: '100%',
											minHeight: 6,
											border: 1,
											borderColor: color,
											maxHeight: isShowNum(62),
										}}
										data-tip={`${option}: ${heightData?.usedMap[option]}`}
										data-for='barchart'
									>
										{showHover && (
											<ReactTooltip
												id='barchart'
												arrowColor='#5E639B'
												effect='solid'
												backgroundColor='#5E639B'
												className='tooltip-barchart'
											/>
										)}
									</div>
									{haveSubData && (
										<>
											<div
												style={{
													position: 'absolute',
													zIndex: 2,
													bottom: 0,
													minWidth: `80px`,
													height: `${
														heightData?.maxCount == heightData?.minCount
															? heightData?.maxCount > 0
																? 31
																: 0
															: Math.round((62 * additionalData[option]) / heightData?.maxCount)
													}px`,
													width: '100%',
													minHeight: 6,
													border: 1,
													borderTopRightRadius: 10,
													borderTopLeftRadius: 10,
													background: `${color}`,
													maxHeight: isShowNum(62),
												}}
												className={`${initialLoading ? '' : 'duration-300 ease-in-out transition-[height]'}`}
												data-tip={`${additionalData[option]}`}
												data-for='barchart'
											>
												<div className='text-sm text-darkPurple h-[15px] absolute self-center -mt-[25px] w-full text-center'>
													{additionalData[option]}
												</div>
												{haveSubData && (
													<ReactTooltip
														id='barchart'
														arrowColor='#5E639B'
														effect='solid'
														backgroundColor='#5E639B'
														className='tooltip-barchart'
													/>
												)}
											</div>
										</>
									)}
								</div>
								<div key={index}>
									<div className='text-[12px] text-center text-darkPurple ellipsis-two-lines' style={{ height: 36 }}>
										{option}
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className='bg-deselectPurple h-[1px] w-full absolute' style={{ top: maxUsedHeight }}></div>
			</div>
		</div>
	);
}

export default BarCharts;
