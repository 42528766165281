import React, { useState, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { useTranslation } from 'react-i18next';
import '../../styles/modals/StudentInfoModal.css';
import { formatDateOnly, notify } from '../../utils/util';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { ReactComponent as Save } from '../../assets/svg/Save.svg';
import { ReactComponent as AngleUp } from '../../assets/svg/AngleUp.svg';
import { create, email_exists, generate_new_password, get_by_institution, update } from '../../server/server';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TeacherRoleModal from './TeacherRoleModal';
import { Toaster } from 'react-hot-toast';
import { useUser } from '../../auth';
import { localeMap } from '../../constants/Constants';
import Loading from '../Loading';

function TeacherInfoModal({ teacherInfoOpen, closeTeacherInfo, teacher, reloadTeachersList, deleteTeacher }) {
	const { user, setUser } = useUser();
	const { t, locale } = useTranslation();
	const { register, handleSubmit, watch, formState, reset, setValue, getValues } = useForm();
	const { isDirty, isValid, errors } = formState;
	const [showDropdown, setShowDropdown] = useState(false);
	const [showRoleDropdown, setShowRoleDropdown] = useState(false);
	const [gender, setGender] = useState('Male');
	const [birthDate, setBirthDate] = useState(new Date());
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [roles, setRoles] = useState([]);
	const [roleData, setRoleData] = useState({});
	const [roleText, setRoleText] = useState('Select role');
	const timeout = useRef(null);
	const [emailExists, setEmailExists] = useState(false);
	const [emailValidText, setEmailValidText] = useState('');
	const [generatigPass, setGeneratigPass] = useState(false);

	const handleChange = (newValue) => {
		setBirthDate(newValue);
	};
	React.useEffect(() => {
		if (teacherInfoOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [teacherInfoOpen]);

	useEffect(() => {
		if (teacher != {}) {
			setValue('first_name', teacher.first_name);
			setValue('last_name', teacher.last_name);
			setValue('email', teacher.email);
			setGender(teacher.gender);
			setRoleData(teacher.role_coverage);
			setBirthDate(new Date(teacher.date_of_birth * 1000));
			setRoleText(teacher.role_text);
			// validate_email(teacher.email);
		}
	}, [teacher]);

	useEffect(() => {
		load_roles_model();
	}, []);

	const load_roles_model = async () => {
		const institution_id = localStorage.getItem('institution_id');
		const resp = await get_by_institution('institution_roles_model', institution_id);
		if (resp.status === 200) {
			const json_data = resp.data;
			setRoles(json_data[0]?.roles || []);
			console.log('loaded roles ', json_data[0]?.roles);
		} else {
			notify(t('rolemodelIsnotLoaded'));
		}
	};

	const validate_email = async (email) => {
		const valid = validEmail(email);
		if (!valid) {
			setEmailValidText(t('emailIsInvalid'));
			setEmailExists(true);
		} else {
			const resp = await email_exists(email);
			if (resp.status === 200) {
				const email_exists = resp.data;
				setEmailValidText(email_exists ? t('emailAlreadyExists') : t('emailIsAvailable'));
				setEmailExists(email_exists);
			}
		}
	};

	function validEmail(e) {
		var filter = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		return String(e).search(filter) != -1;
	}
	const handleChangeTeacherEmail = (e) => {
		if (timeout !== null) {
			clearTimeout(timeout.current);
		}
		timeout.current = setTimeout(() => {
			validate_email(e.target.value);
		}, 800);
	};

	const save = () => {
		if (emailExists) {
			notify(emailValidText);
			return;
		}
		const formData = getValues();
		teacher.first_name = formData.first_name;
		teacher.last_name = formData.last_name;
		teacher.email = formData.email.toLowerCase();
		teacher.gender = gender;
		teacher.date_of_birth = parseInt(birthDate.getTime() / 1000);
		if (!!roleText) teacher.role_text = roleText;
		else {
			teacher.role_text = 'unassigned';
		}
		if (!!roleData) {
			teacher.role_coverage = roleData;
			teacher.role = roleData?.role;
		} else {
			teacher.role_coverage = {
				role: 1,
				coverage: [],
				scope: [],
				field: '',
				target_text: '',
				unassigned: true,
			};
			teacher.role = 1;
		}

		teacher.validEmail = true;
		teacher.new_password_required = true;
		if (!!teacher.id) {
			setNewTeacher(teacher);
		} else {
			const institution_id = localStorage.getItem('institution_id');
			teacher.password = formData.password;
			teacher.institution_id = institution_id;
			teacher.new_password = true;
			setNewTeacher(teacher);
		}
	};

	const setNewTeacher = (newTeacher) => {
		if (newTeacher.id === undefined || newTeacher.id === '') {
			createTeacher(newTeacher);
		} else {
			updateTeacher(newTeacher);
		}
	};

	const createTeacher = async (temp) => {
		const resp = await create({ collection: 'users', data: temp });
		if (resp.status === 200) {
			notify(t('teacherIsCreatedAndPasswordHasBeenSent'));
			generatePassword(resp.data, false);
			reloadTeachersList();
			closeTeacherInfo();
		} else {
			notify(t('teacherIsNotCreated'));
		}
	};
	const updateTeacher = async (newTeacher) => {
		const resp = await update({ collection: 'users', data: newTeacher });
		if (resp.status === 200) {
			notify(t('yourDataIsUpdated'));
			closeTeacherInfo();
		} else {
			notify(t('yourDataIsNotUpdated'));
		}
	};

	const generatePassword = async (selectedUser, notification = true) => {
		setGeneratigPass(true);
		const resp = await generate_new_password(selectedUser);
		if (resp.status === 200) {
			if (notification) notify(t('The new password has been sent!'));
			setGeneratigPass(false);
		} else {
			notify(t('Password delivery failed!'));
			setGeneratigPass(false);
		}
	};

	const closeRoleModal = (roleModel) => {
		if (!!roleModel) {
			console.log('role model data ', roleModel);
			setRoleData(roleModel.data);
			setRoleText(roleModel.text);
		}
		setShowRoleDropdown(false);
	};

	const checkId = () => {
		return user.id === teacher.id ? true : false;
	};

	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={teacherInfoOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				reset();
				setEmailValidText('');
			}}
			onRequestClose={closeTeacherInfo}
			className='noScroll'
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 654,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'scroll',
					paddingTop: 35,
					paddingBottom: 35,
				},
			}}
		>
			<div className='student-info-modal'>
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<div className='close-modal-icon ' onClick={() => closeTeacherInfo()}>
						<CloseModalIcon />
					</div>
				</div>

				<div className='student-info-title'>
					<p>{!!teacher?.id ? t('teacherInfo') : t('addATeacher')} </p>
				</div>
				<div className='student-info-subtitle'>
					<p>{t('enterTeachersDetails')} </p>
				</div>
				<div style={{ paddingBottom: 20 }}>
					<form onSubmit={handleSubmit(save)} autoComplete='off'>
						<div className={`student-info-form`}>
							<div className={`student-input ${errors.first_name ? 'pink-border' : ''}`} style={{ position: 'relative' }}>
								<label style={{ color: errors.first_name ? '#FF747F' : '' }}>{t('firstName')}</label>
								<input
									type='text'
									placeholder={t('enterName')}
									autoComplete='off'
									{...register('first_name', { required: true })}
								/>
								{errors.first_name && <span className='field-validation-text'>{t('fieldIsRequired')}</span>}
							</div>
							<div className={`student-input ${errors.last_name ? 'pink-border' : ''}`} style={{ position: 'relative' }}>
								<label style={{ color: errors.last_name ? '#FF747F' : '' }}>{t('lastName')}</label>
								<input
									type='text'
									placeholder={t('enterSurname')}
									autoComplete='off'
									{...register('last_name', { required: true })}
								/>
								{errors.last_name && <span className='field-validation-text'>{t('fieldIsRequired')}</span>}
							</div>
							<div className='student-input ' style={{ position: 'relative' }}>
								<div
									className='student-info-select-button'
									onClick={(e) => {
										e.preventDefault();
										setIsDatePickerOpen(true);
									}}
									style={{ marginTop: 0, zIndex: 3 }}
								>
									<p>{t('dateOfBirth')} </p>
									<span style={{ flex: 1 }}></span>
									<p>{formatDateOnly(birthDate)}</p>
								</div>
								<div style={{ position: 'absolute', top: 0, zIndex: 2, visibility: 'hidden' }}>
									<LocalizationProvider adapterLocale={localeMap[i18n.language]} dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											open={isDatePickerOpen}
											onClose={() => setIsDatePickerOpen(false)}
											label='Date desktop'
											inputFormat='MM/dd/yyyy'
											value={birthDate}
											onChange={handleChange}
											maxDate={new Date()}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</div>
							</div>

							<div
								className='student-input '
								onMouseLeave={() => setShowDropdown(false)}
								style={{ position: 'relative', height: 86 }}
							>
								<div
									className='student-info-select-button'
									onClick={() => setShowDropdown(!showDropdown)}
									style={{ position: 'absolute', top: 0, zIndex: 5 }}
								>
									<p>{t('gender')}</p>
									<span style={{ flex: 1 }}></span>
									<p style={{ marginRight: 20 }}>{t(gender?.toLowerCase())}</p>
									<div style={{ transform: showDropdown ? 'rotate(180deg)' : 'none' }}>
										<AngleUp style={{ color: '#6B6C7E' }} />
									</div>
								</div>
								{showDropdown && (
									<div className='gender-drop-down' style={{ width: '100%', zIndex: 4 }}>
										<p style={{ height: 56 }}></p>
										{['Male', 'Female', 'Other'].map((item, index) => (
											<div
												onClick={(e) => {
													e.preventDefault();
													setGender(item);
													setShowDropdown(false);
												}}
												style={{ width: '100%' }}
												key={index}
											>
												<p>{t(item.toLowerCase())}</p>
											</div>
										))}
									</div>
								)}
							</div>
							<div className='student-input ' style={{ position: 'relative', height: 86 }}>
								<div
									className='student-info-select-button'
									onClick={() => setShowRoleDropdown(true)}
									style={{ position: 'absolute', top: 0, zIndex: 3 }}
								>
									<p>{t('role')} </p>
									<span style={{ flex: 1 }}></span>
									<p style={{ marginRight: 20 }}>{roleText}</p>
								</div>
							</div>

							<div
								className={`student-input ${errors.email ? 'pink-border' : ''}`}
								style={{ marginTop: 30, marginBottom: 0, position: 'relative' }}
							>
								<label style={{ color: errors.email ? '#FF747F' : '' }}>{t('email')}</label>
								<input
									type='text'
									placeholder={t('enterEmail')}
									autoComplete='off'
									{...register('email', {
										required: true,
										pattern: /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
									})}
									onChange={(e) => {
										e.preventDefault();
										e.stopPropagation();
										handleChangeTeacherEmail(e);
										setValue('email', e.target.value, { shouldValidate: true });
									}}
								/>

								<span className='field-validation-text'>{errors.email ? t('emailIsInvalid') : emailValidText}</span>
							</div>

							{/* <div
								className={`student-input ${errors.password ? 'pink-border' : ''} ${!teacher?.id ? '' : 'invisible h-0'}`}
								style={{ position: 'relative' }}
							>
								<label style={{ color: errors.password ? '#FF747F' : '' }}>{t('password')} </label>
								<input
									type='password'
									placeholder={t('password')}
									autoComplete='off'
									{...register('password', { required: !teacher?.id ? true : false })}
								/>
								{errors.password && <span className='field-validation-text'>{t('fieldIsRequired')}</span>}
							</div>

							<div
								className={`student-input ${errors.repeat_password ? 'pink-border' : ''} ${
									!teacher?.id ? '' : 'invisible h-0'
								}`}
								style={{ position: 'relative' }}
							>
								<label style={{ color: errors.repeat_password ? '#FF747F' : '' }}>{t('repeatPassword')}</label>
								<input
									type='password'
									placeholder={t('password')}
									autoComplete='off'
									{...register('repeat_password', { required: !teacher?.id ? true : false })}
								/>

								{errors.repeat_password && <span className='field-validation-text'>{t('fieldIsRequired')}</span>}
							</div> */}
						</div>

						{!!teacher?.id && (
							<div style={{ paddingRight: 5, marginTop: !!teacher?.id ? 50 : 0 }}>
								<button
									className='transparent-button'
									onClick={() => generatePassword(teacher)}
									style={{ fontSize: 18, width: '100%', height: 65, padding: 22 }}
									disabled={generatigPass}
								>
									{generatigPass ? (
										<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={40} stroke={4} />
									) : (
										<p>{t('generateNewPassword')} </p>
									)}
								</button>
							</div>
						)}
						<div style={{ display: 'flex', flexDirection: 'row', marginTop: !!teacher?.id ? 20 : 30 }}>
							{!teacher?.id && (
								<div
									className='transparent-button'
									onClick={closeTeacherInfo}
									style={{ fontSize: 18, margin: 'auto', width: 322, height: 65, padding: 22 }}
								>
									<p>{t('closePopUp')} </p>
								</div>
							)}

							{!!teacher?.id && (
								<div
									className='transparent-button'
									onClick={() => {
										if (checkId()) {
											notify(t("You can't delete yourself!"));
											return;
										}
										deleteTeacher();
									}}
									style={{
										backgroundColor: '#FF747F',
										color: 'white',
										border: 'none',
										fontSize: 18,
										margin: 'auto',
										width: 214,
										height: 65,
										padding: 22,
									}}
								>
									<p>{t('deleteTeacher')} </p>
								</div>
							)}
							<button className='save-submit-button' style={{ margin: 'auto', width: !!teacher?.id ? 347 : 192 }}>
								<p style={{ marginRight: 10 }}>{!!teacher?.id ? t('update') : t('save')} </p>
								<Save />
							</button>
						</div>
					</form>
				</div>
			</div>
			<TeacherRoleModal showModal={showRoleDropdown} closeModal={closeRoleModal} roles={roles} roleModel={roleData} />
			<Toaster position='top-right' reverseOrder={false} toastOptions={{ duration: 3000 }} />
		</ReactModal>
	);
}

export default TeacherInfoModal;
