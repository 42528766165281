import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';

export default function Login(props) {
	const [errorMessage, setErrorMessage] = useState('');
	const { t, locale } = useTranslation();
	const [progress, setProgress] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setProgress(true);
		const resp = await props.onSubmit({
			email: props.email,
			password: props.password,
		});
		if (!resp?.success) {
			setErrorMessage(resp.data);
		}

		setProgress(false);
	};

	return (
		<>
			<div className=''>
				<div className='font-bold text-white text-left text-3xl'>{t('staffLogin')}</div>
				<div className='text-mudPurple text-sm text-left mt-3'>
					<p>{t('enterCredentialsYouReceivedFromSchool')}</p>
				</div>
				<div className='sign-up-section'>
					<div className='login-forma flex flex-col justify-center'>
						<form onSubmit={handleSubmit}>
							<div className='my-5'>
								<span className='text-white text-lg font-semibold text-left flex justify-start'>{t('email')}</span>
								<input
									className='w-full text-white border border-b-deselectPurple py-3 border-x-0 border-t-0'
									placeholder={t('email')}
									style={{ backgroundColor: 'transparent' }}
									value={props.email}
									// type="email"
									name='email'
									onChange={(e) => props.setEmail(e.target.value)}
								/>
							</div>
							<div className='my-5'>
								<span className='text-white text-lg font-semibold text-left flex justify-start'>{t('password')}</span>
								<input
									className='w-full text-white border border-b-deselectPurple py-3 border-x-0 border-t-0'
									placeholder={t('password')}
									value={props.password}
									type='password'
									style={{ backgroundColor: 'transparent' }}
									onChange={(e) => props.setPassword(e.target.value)}
									name='passwordLogin'
								/>
							</div>
							<div className='error-message'>{errorMessage}</div>
							<div className='flex justify-center px-10 mt-8 mb-3'>
								<button
									className={`bg-highlightColor text-deepPurple py-3 text-lg font-semibold rounded-full w-full h-[50px] ${
										!progress ? 'hover:bg-darkPurple hover:text-white' : ''
									} duration-[250ms] ease-in-out transition-all flex items-center justify-center`}
									disabled={progress}
								>
									{progress ? <Loading primaryColor='#413F5E' /> : t('login')}
								</button>
							</div>
						</form>
					</div>
					<div>
						<p className='login-missing-text mb-4'>
							<span className='login-missing-text-q'>{t('forgotPassword')}</span>
							<span className='login-missing-text-action' onClick={() => props.setAuthSection('new-password')}>
								{t('requestNewPassword')}
							</span>
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
