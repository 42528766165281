import axios from 'axios';
import { baseUrl } from '../constants/Connections';
import { tryToJson } from '../utils/util';

export async function login({ email, password }) {
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/login`,
		data: {
			email: email,
			password: password,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getById({ collection, id }) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/by_id/${collection}/${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getStudentDailyQuestionUsage() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/student_daily_question_usage`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getDashboardStatisticsNew() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/dash_statistics_new`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAnalyticsDashboardStats(days = 7) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/analytics_dashboard_statistics?days=${days}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export const getNumberOfNegativeEmotions = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
			'Content-Type': 'application/json',
		},
		url: `${baseUrl}/api/number_of_negative_emotions`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export async function getWellbeingProfilesForReviewAsync() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/wellbeing_profile_for_review`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getActionRequiredStats() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/actions_required_stats`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMonitoringStats() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/monitoring_stats`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function createMonitoringGroup(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/monitoring_group`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function deleteMonitoringGroup(id) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'DELETE',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/monitoring_group?id=${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function removeContentFromTrendsAsync(id) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'DELETE',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/analytics_content?id=${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getYearsForTeacher() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/school_years_for_teachers`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getClassroomTrendReport(period) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/classroom_trend_report_advanced?period=${period}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function takeTutorial(currentStep) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/take_tutorial?device=desktop&step=${currentStep}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function ifUserNeedsTutorial() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/need_tutorial?device=desktop`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function ifUserHasPrimaryClasses() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/teacher_has_primary_classes`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function ifUserHasRegularClasses() {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/teacher_has_regular_classes`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMonitoringStatsForTargetGroup(id, days = 7, previousWeek = false) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api2/monitoring_stats_for_target_group?id=${id}&days=${days}&previous_week=${previousWeek}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getDefaultMonitoringStats() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api2/default_monitoring_stats`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMonitoringGroups() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/monitoring_groups`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getTeachersForAssignment() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/free_teachers_for_assignment?feed`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export const getSuperiorTeachers = async (user) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
			'Content-Type': 'application/json',
		},
		url: `${baseUrl}/api/student_superiors`,
		data: user,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export const getJournalForUser = async (id) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/journal_for_user/${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const updateJournalDailyQuestion = async (user) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
			'Content-Type': 'application/json',
		},
		url: `${baseUrl}/api/respond_to_journal_question`,
		data: user,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export async function request_new_password(data) {
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/reset_password`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function checkSolutionAccess(data) {
	const token = await localStorage.getItem('token');
	const options = {
		headers: {
			Authorization: `${token}`,
		},
		method: 'POST',
		url: `${baseUrl}/api/checkSolutionAccess`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function contactExpert(data) {
	const token = await localStorage.getItem('token');

	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/contactExpert`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getRunningPrograms(data) {
	const token = await localStorage.getItem('token');

	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/get_running_programs`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getResponseSuggestions(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/get_response_suggestions`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getSurveys() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/get_surveys`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getSurveysForUser() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/get_student_surveys`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function find_counsellor(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/find_counsellor`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function find_sos(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/find_sos`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getCheckUsersFeedback() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/check_if_users_feedback_is_needed`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function sendRejectReview() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/reject_review`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function collectUsersFeedback(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/collect_users_feedback`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function checkIfTakeDailyFeedback(user_id) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/last_daily_feedback/${user_id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getMostPopularEmotionForUser(user_id) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/most_popular_emotion/${user_id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getUserResponsePercentage(user_id) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/user_response_percentage/${user_id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getCatchUpsAsync(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/catch_ups`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function answer(data) {
	const token = await localStorage.getItem('token');
	console.log(data);
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/answer`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getFeeds(data, cancel) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/get_feed`,
		data,
		cancelToken: new axios.CancelToken((c) => (cancel = c)),
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => {
			if (axios.isCancel(error)) return;
			handelError(error);
		});
}

export async function deleteById({ collection, id }) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'DELETE',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/by_id/${collection}/${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function stopContent({ id }) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/stop_content?content_id=${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export const respondToDailyQuestion = async (data) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
			'Content-Type': 'application/json',
		},
		url: `${baseUrl}/api/respondToDailyQuestion`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export async function searchFeeds({ user, number_of_feeds, text }) {
	const token = await localStorage.getItem('token');
	const data = {
		user,
		number_of_feeds,
		text,
	};
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/get_feed`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getByToken() {
	const token = localStorage.getItem('token');
	if (!!token) {
		const options = {
			method: 'GET',
			headers: {
				Authorization: `${token}`,
			},
			url: `${baseUrl}/api/users/by_token/${token}`,
		};
		return axios(options)
			.then((response) => handleSuccess(response))
			.catch((error) => handelError(error));
	}
	return { status: 300, data: 'No token' };
}
export async function getContentByTopic(topic, locale = 'en') {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/ready_to_go_content?topic=${topic}&locale=${locale}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getTasks({ user_id, number_of_tasks }) {
	const token = await localStorage.getItem('token');
	const data = {
		user_id,
		number_of_tasks,
	};
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api_v2/get_task`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getArchive({ user_id, number_of_archives, collection }) {
	const token = await localStorage.getItem('token');
	const data = {
		user_id,
		number_of_archives,
		collection,
	};
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/get_archive`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function like({ user_id, feed_id, like }) {
	const token = await localStorage.getItem('token');
	const data = {
		user_id,
		feed_id,
		like,
	};
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/like`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export const getMessageList = async (data) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/message_list`,
		headers: { Authorization: `${token}` },
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const checkProgramOverlap = async (data) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/check_program_overlap`,
		headers: { Authorization: `${token}` },
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const runProgram = async (data) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/run_program`,
		headers: { Authorization: `${token}` },
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export async function getProgramHistory(data) {
	const token = await localStorage.getItem('token');

	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/get_program_history`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export const getMessages = async ({ members, loaded_messages, conversation_id }) => {
	const token = await localStorage.getItem('token');
	const data = {
		members,
		loaded_messages,
		conversation_id,
	};
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/get_messages`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const sendMessage = async (data) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/send_message`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export async function getBy({ collection = '', query = {}, page = -1, page_size = -1, sort_field = '', sort_direction = 1 }) {
	const token = await localStorage.getItem('token');
	const args = `?page=${page}&page_size=${page_size}&sort_field=${sort_field}&sort_direction=${sort_direction}&query=${JSON.stringify(
		query
	)}`;
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/filter_by/${collection}${args}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export const seenMessage = async (data) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/seen_message`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getTarget = async (data) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/get_target`,
		headers: { Authorization: `${token}` },
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getAllStudentsTarget = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/all_students_target`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getUsersLoginsInfo = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/user_logins_info`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const selectAllStaffAsync = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/all_staff_target`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export async function getSurveyResult(survey_id, sectionIndex, questionIndex) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/survey_result?survey_id=${survey_id}&section_index=${sectionIndex}&question_index=${questionIndex}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getSurveyResults(survey_id) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/survey_results?survey_id=${survey_id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export const getUsersLoginsInfoDetailed = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/user_logins_info`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export const getContentPrograms = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/all/programs`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getPrograms = async (locale = 'en') => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/get_programs?local=${locale}`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getAnalyticsHowDoYouFillStats = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/how_do_you_feel_stats`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export const getAnalyticsBasic = async (user, number_of_users = 0, searchText = '') => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/basic_login_stats?role=${user}&search=${searchText}&loaded=${number_of_users}`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export async function deleteStudentAsync(data = {}) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/delete_student`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function deleteClassAsync(data = {}) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/delete_class`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export const getUpstriveWeeklyUsage = async (user, number_of_users = 0, searchText = '') => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/upstrive_weekly_usage?search=${searchText}&loaded=${number_of_users}`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getUpstriveWeeklyUsagePercentage = async (user, number_of_users = 0, searchText = '') => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/upstrive_weekly_usage_percentage`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getContentResponseRate = async (number_of_users = 0, searchText = '') => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/content_response_rate?search=${searchText}&loaded=${number_of_users}`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getContentResponseRatePercentage = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/content_response_rate_percentage`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export const getUsersLoginStats = async (user) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/user_login_stats?role=${user}`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export const getContentSurveys = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/get_surveys`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getAdditionalTrends = async (days) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/additional_trends?days=${days}`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const sendMessageToStudentFromFeedback = async (data) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/send_message_to_student_from_feedback`,
		headers: { Authorization: `${token}` },
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getContentJournals = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/get_journals_model`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getUpcomingNum = async () => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/upcoming_content`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getUpcomingContent = async (data) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/upcoming_content`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getJournalById = async (journalId) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/journal_results?journal_id=${journalId}`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getJournalForDay = async (journalId, day) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/journal_results_for_day?journal_id=${journalId}&day=${day}`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const loadTargetItemsList = async (data) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/target_list`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const create = async ({ collection, data }) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
			'Content-Type': 'application/json',
		},
		url: `${baseUrl}/api/all/${collection}`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const createContent = async ({ data }) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
			'Content-Type': 'application/json',
		},
		url: `${baseUrl}/api/create_content`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const createTemplate = async ({ data }) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
			'Content-Type': 'application/json',
		},
		url: `${baseUrl}/api/all/template`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const hideWellbeingNote = async ({ data }) => {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/hide_wellbeing_message`,
		headers: {
			Authorization: `${token}`,
			'Content-Type': 'application/json',
		},
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
// export const upload = async ({ file, type = null }) => {
// 	const token = await localStorage.getItem('token');
// 	let formData = new FormData();
// 	formData.append('file', file);

// 	return axios
// 		.post(`${baseUrl}/api/upload?type=${type}`, formData, {
// 			headers: {
// 				Authorization: `${token}`,
// 				'Content-Type': 'multipart/form-data',
// 			},
// 		})
// 		.then((response) => handleSuccess(response))
// 		.catch((error) => handelError(error));
// };

export const upload = async (file) => {
	const token = await localStorage.getItem('token');
	// ImagePicker saves the taken photo to disk and returns a local URI to it
	console.log(file);
	let type = file.type.includes('video') ? 'video' : 'image';
	console.log(`File type: ${type}`);
	// Upload the image using the fetch and FormData APIs
	let formData = new FormData();
	// Assume "photo" is the name of the form field the server expects
	formData.append('file', file);

	return axios
		.post(`${baseUrl}/api/upload?type=${type}`, formData, {
			headers: {
				Authorization: `${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const uploadUserData = async (file, institutionId) => {
	const token = await localStorage.getItem('token');
	let formData = new FormData();
	formData.append('file', file);

	return axios
		.post(`${baseUrl}/api/uploadUsersData?institution_id=${institutionId}`, formData, {
			headers: {
				Authorization: `${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const removeUserDataFile = async (file) => {
	const token = localStorage.getItem('token');

	return axios
		.delete(`${baseUrl}/api/uploadUsersData?file_id=${file.id}`, {
			headers: {
				Authorization: `${token}`,
			},
		})
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export async function update({ collection, data }) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'PUT',
		headers: {
			Authorization: `${token}`,
		},
		data: data,
		url: `${baseUrl}/api/by_id/${collection}/${data?.id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function update_new_password({ email, password, token }) {
	const data = {
		email,
		password,
		token,
	};
	const options = {
		method: 'PUT',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/update_new_password`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function find_head_of_class(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/find_head_of_class`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function createUser(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/create_user`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function draftSurveyAsync(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/draft_survey_response`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function verify_reset_token({ email, token }) {
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/verify_reset_token/${email}/${token}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getFavoriteGroupsAsync() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/favorite_groups`,
		headers: {
			Authorization: `${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAll({ type }) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/all/${type}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function logUserTimeAsync(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/log_user_time`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function logLoginTimeAsync(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/log_login_time`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getUnseenConversations() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/unseen_conversations`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getNumberOfWellbeingProfilesForReviewAsync() {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/number_of_wellbeing_profile_for_review`,
		headers: {
			Authorization: `${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function sendNotification(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/sendNotification`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function changeProgramStatus(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/change_program_status`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function removeRunningProgram(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/remove_running_program`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getIssues({ issueType, loadedContent, searchText }) {
	const token = await localStorage.getItem('token');
	const data = {
		loadedContent: loadedContent,
		issueType: issueType,
		searchText: searchText || '',
	};

	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/get_issues`,
		data,
	};

	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function solutionLike(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/solutionLike`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function solutionDislike(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/solutionDislike`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getCounsellingProfile(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/counselling_profiles`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError('Error getting all terminals' + error));
}
export async function makeAssignment(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/make_assignment`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError('Error getting all terminals' + error));
}
export async function getNumberProfilesForReview() {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/number_of_wellbeing_profile_for_review`,
		headers: { Authorization: token },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError('Error getting all terminals' + error));
}
export async function getUploadsHistory() {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/users_data_history`,
		headers: { Authorization: token },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError('Error getting all terminals' + error));
}
export async function getReminders(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/find_reminders`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getWellbeingReview() {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/wellbeing_profile_for_review`,
		headers: { Authorization: token },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getWellbeingUsers(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/wellbeing_users`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function openWellbeingProfile(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/open_wellbeing_profile`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function viewFeedPost(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		url: `${baseUrl}/api/view_post`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getLiveResults(data) {
	const token = await localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		data,
		url: `${baseUrl}/api/live_results_advanced`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function get_teachers(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		data,
		url: `${baseUrl}/api/get_list/teachers`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function generate_new_password(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
		},
		data,
		url: `${baseUrl}/api/generate_password_send_mail`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function email_exists(email) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/email_exists/${email}`,
		headers: {
			Authorization: token,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getSchoolYears() {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/school_years_for_teacher_role`,
		headers: {
			Authorization: token,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getSchoolClasses() {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/school_classes_for_teacher_role`,
		headers: {
			Authorization: token,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function get_by_institution(collection, institution_id) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/filter_by_institution/${collection}/${institution_id}`,
		headers: { Authorization: token },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function get_my_class(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/my_class_report`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export const loadPrimaryStudents = async () => {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/primary_students`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export const getClasses = async () => {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/get_classes`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getAdditionalFields = async () => {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/additional_fields`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export const getReports = async () => {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/list_of_reports`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getThreeReports = async () => {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/list_of_reports_three`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};
export const getFilters = async () => {
	const token = localStorage.getItem('token');
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/filter_for_reports`,
		headers: { Authorization: `${token}` },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export const moveStudentToAnotherClass = async (data) => {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
			'Content-Type': 'application/json',
		},
		url: `${baseUrl}/api/move_student_to_another_class`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export const changeUserAnimal = async (data) => {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		headers: {
			Authorization: `${token}`,
			'Content-Type': 'application/json',
		},
		url: `${baseUrl}/api/change_user_avatar`,
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
};

export async function updateStudent(data = {}) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/update_student`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function updateClassAsync(data = {}) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/update_class`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function searchClasses(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/get_list/classes`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function get_students(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/get_list/students`,
		headers: { Authorization: token },
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getStudentsForClass({ institution_id, className }) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/get_list_admin/students`,
		headers: { Authorization: token },
		data: { institution_id, className },
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function getDayArchive(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/get_day_archive`,
		headers: {
			Authorization: token,
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function sendSeenFeedbackComment(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/seen_feedback_comment`,
		headers: {
			Authorization: token,
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function takeActionFeedbackComment(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/take_action_feedback_comment`,
		headers: {
			Authorization: token,
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

export async function addCounsellorNote(counsellingProfileId, data) {
	const token = localStorage.getItem('token');
	const args = `?counselling_profile_id=${counsellingProfileId}`;
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/add_counselling_note${args}`,
		headers: {
			Authorization: token,
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function get_by({ collection = '', query = {}, page = -1, page_size = -1, sort_field = '', sort_direction = 1 }) {
	const token = localStorage.getItem('token');
	const args = `?page=${page}&page_size=${page_size}&sort_field=${sort_field}&sort_direction=${sort_direction}&query=${JSON.stringify(
		query
	)}`;
	const options = {
		method: 'GET',
		url: `${baseUrl}/api/filter_by/${collection}${args}`,
		headers: {
			Authorization: token,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function getAnalyticsContent(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/get_analytics_content`,
		headers: { Authorization: token },
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function analyze(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/analyze`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}
export async function generateCustomReport(data) {
	const token = localStorage.getItem('token');
	const options = {
		method: 'POST',
		url: `${baseUrl}/api/generate_custom_report`,
		headers: { Authorization: token },
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handelError(error));
}

function handelError(error) {
	console.log(error);
	return { status: error?.response?.status, data: error?.response?.data };
}
function handleSuccess(response) {
	const responseData = tryToJson(response.data);
	return { status: response.status, data: responseData };
}
