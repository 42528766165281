import React, { useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function Token(props) {
    const [errorMessage, setErrorMessage] = useState("");
    const { t, locale } = useTranslation();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const resp = await props.onSubmit(props.email);
        if (resp.status !== 200) {
            setErrorMessage(resp.data);
        } else {
            props.setToken(resp.data);
            props.setAuthSection("enter-new-password");
        }
    };

    return (
        <div className="">
            <div className="modal-title">
                <p className="text-3xl text-white text-left font-bold">{t("verifyYourEmail")}</p>
            </div>
            <div className="text-sm text-deselectPurple text-left mt-2">
                <p>{t("weHaveSentYouALinkToGetBackIntoYourAccountAndCheckYourMailAndCopyYourTokenHere")}</p>
            </div>

            <div className="sign-up-section">
                <div className="">
                    <form onSubmit={handleSubmit}>
                        <div className="my-5">
                            <span className="text-white text-lg font-semibold text-left flex justify-start">{t("token")}</span>
                            <input
                                className="w-full text-white border border-b-deselectPurple py-3 border-x-0 border-t-0"
                                placeholder={t("token")}
                                style={{ backgroundColor: "transparent" }}
                                value={props.token}
                                // type="email"

                                onChange={(e) => props.setToken(e.target.value)}
                            />
                        </div>
                        <div className="error-message">{errorMessage}</div>
                        <div className="login-form-element-holder px-6 py-3">
                            <button className="auth-form-button text-deepPurple">{t("submit")}</button>
                        </div>
                    </form>
                </div>
                <div>
                    <p className="login-missing-text">
                        <span className="login-missing-text-q">{t("didntGetAMail")}</span>
                        <span className="login-missing-text-action" onClick={() => props.repeatRequest()}>
                            {t("resendARequest")}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}
