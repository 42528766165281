import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as PreviousIcon } from '../../assets/svg/PreviousIcon.svg';
import { ReactComponent as ReleaseByDaysLock } from '../../assets/svg/ReleaseByDaysLock.svg';
import { ReactComponent as SaveForLaterSurvey } from '../../assets/svg/SaveForLaterSurvey.svg';
import { useTranslation } from 'react-i18next';
import { create, draftSurveyAsync } from '../../server/server';
import { notify, formatDayCode } from '../../utils/util';
import Loading from '../Loading';
import '../../styles/modals/SurveyTask.css';

function SurveyTask({ modalIsOpen, closeModal, item, user, completed, checkUsersFeedbackNeeded }) {
	const [survey, setSurvey] = useState(item);
	const [currentStage, setCurrentStage] = useState('init');
	const [stageNumber, setStageNumber] = useState(0);
	const [selectedSection, setSelectedSection] = useState({});
	const [errorMessages, setErrorMessages] = useState([]);
	const [sendingRequest, setSendingRequest] = useState(false);
	const { t } = useTranslation();

	const releaseByDays = !!item?.releaseByDays;

	useEffect(() => {
		if (modalIsOpen && !!item?.sections) {
			const doneSections = item?.sections
				?.map((section) => section?.questions?.map((e) => !!e?.answer))
				?.filter((e) => e?.every((e) => !!e) && e?.length > 0)?.length;
			const currentSageNumber = doneSections ?? 0;
			if (item.releaseByDays) {
				setStageNumber(currentSageNumber);
				setSelectedSection(item?.sections[currentSageNumber]);
			} else {
				setCurrentStage(!!doneSections ? 'stage' : 'init');
				setStageNumber(currentSageNumber);
				setSelectedSection(item?.sections[currentSageNumber]);
			}
			setSurvey(item);
		}
	}, [modalIsOpen, item]);

	useEffect(() => {
		setErrorMessages([]);
	}, [selectedSection]);

	const isLastStage = () => {
		return stageNumber == survey?.sections?.length - 1;
	};
	const takeSurvey = () => {
		setCurrentStage('stage');
		setStageNumber(0);
		setSelectedSection(survey.sections[0]);
	};
	const previousStage = () => {
		if (stageNumber == 0) {
			setCurrentStage(`init`);
		} else {
			setStageNumber(stageNumber - 1);
			setSelectedSection(survey.sections[stageNumber - 1]);
		}
	};

	const selectDay = (index) => {
		setStageNumber(index);
		setSelectedSection(survey.sections[index]);
	};

	const selectOption = (question, option) => {
		question.answer = option;
		updateSurvey(selectedSection);
	};
	const updateSurvey = (section) => {
		let _ssurvey = { ...survey };
		_ssurvey.sections[stageNumber] = section;
		setSurvey(_ssurvey);
	};
	const checkAllResults = () => {
		const answers = selectedSection?.questions?.map((e) => !!e.answer);
		if (!answers) return;
		const indexOfSkipped = answers?.map((e, i) => (e ? null : i))?.filter((e) => e != null);
		if (indexOfSkipped.length > 0) {
			setErrorMessages(indexOfSkipped);
		} else {
			if (!isLastStage()) {
				const el = document.getElementById('survey-scrolled');
				el.scrollTo(0, 0);
			}
			nextStage();
		}
	};

	const finishLater = () => {
		draftSurvey();
		closeModal();
	};
	const nextStage = () => {
		if (isLastStage()) {
			finish();
		} else {
			draftSurvey();
			let tempStageNumber = stageNumber + 1;
			setSelectedSection(survey.sections[tempStageNumber]);
			setStageNumber(tempStageNumber);
			const elements = document.querySelectorAll('.survey-textarea');
			elements?.forEach((element) => {
				element.value = '';
			});
		}
	};

	const draftSurvey = async () => {
		const data = {
			user_id: user.id,
			survey_id: survey.id,
			sections: survey.sections,
			title: survey.title,
		};
		draftSurveyAsync(data);
	};

	const finish = async () => {
		setSendingRequest(true);
		const data = {
			user_id: user.id,
			survey_id: survey.id,
			sections: survey.sections,
			title: survey.title,
		};
		const resp = await create({ collection: 'surveyResponse', data });
		if (resp.status === 200) {
			notify(t('yourResultsAreSaved'));
			completed();
			checkUsersFeedbackNeeded();
			closeModal();
		} else {
			console.log('survey error ', resp);
		}
		setSendingRequest(false);
	};

	useEffect(() => {
		ReactModal.setAppElement('#home');
	}, []);
	const dayCode = formatDayCode(new Date());
	const isLockedDay = selectedSection?.releaseDate > dayCode;
	return (
		<ReactModal
			transparent
			isOpen={modalIsOpen}
			ariaHideApp={false}
			onAfterOpen={() => {}}
			onAfterClose={() => {}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 30,
					overflowY: 'scroll',
					height: '100vh',
				},
				content: {
					inset: 0,
					padding: 0,
					margin: 0,
					backgroundColor: 'transparent',
					// maxHeight: '90%',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: 30,
					marginBottom: 30,
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					className={`w-[60px] h-[60px] rounded-full cursor-pointer bg-deepPurple hover:bg-darkenPurple flex items-center justify-center z-[2] `}
					onClick={() => closeModal()}
				>
					<CloseModalIcon />
				</div>
			</div>
			{currentStage === 'init' && !releaseByDays && (
				<div className='bg-white w-[1000px] mt-[30px] rounded-[10px] '>
					<div className='pt-[52px] pb-[37px] bg-lightBlue flex flex-col gap-[10px] items-center rounded-[10px] '>
						<p className='text-[18px] text-mudPurple leading-[20px] font-medium '>{t('survey')} </p>
						<p className='text-[30px] text-deepPurple leading-[38px] font-bold '>{survey.title}</p>
					</div>
					<div className='flex flex-col pt-[35px] pb-[50px] px-[50px] '>
						<p className='text-[20px] text-deepPurple font-bold leading-[25px] '>{t('sections')}:</p>
						<div className='flex flex-col mt-[40px] pl-[15px] '>
							{survey?.sections?.map((section, index) => (
								<p className='mb-[50px] text-[16px] text-deepPurple font-bold leading-[20px]  ' key={index}>
									{index + 1}. {section.title}
								</p>
							))}
						</div>
						<div
							className={`mt-[10px] rounded-[10px] h-[60px] cursor-pointer flex items-center justify-center w-full bg-deepPurple`}
							onClick={takeSurvey}
						>
							<p className={`text-[16px] leading-[20px] font-semibold text-highlightColor`}>{t('takeIt')} </p>
						</div>
					</div>
				</div>
			)}
			{currentStage === 'stage' && !releaseByDays && (
				<div id='survey-scrolled' className='bg-white w-[1000px] mt-[30px] rounded-[10px] '>
					<div className='pt-[52px] pb-[36px] px-[50px] bg-lightBlue flex gap-[10px] items-center justify-between rounded-[10px] '>
						<div
							className='rounded-full w-[56px] h-[56px] border border-deselectPurple flex items-center justify-center cursor-pointer'
							onClick={() => previousStage()}
						>
							<PreviousIcon style={{ color: '#9392AD' }} />
						</div>
						<div className=' flex flex-col gap-[10px] items-center  '>
							<p className='text-[18px] text-mudPurple leading-[20px] font-medium '>{survey.title}</p>
							<p className='text-[30px] text-deepPurple leading-[38px] font-bold '>{selectedSection.title}</p>
						</div>
						<div className='rounded-full w-[56px] h-[56px] border border-deselectPurple flex items-center justify-ceter cursor-pointer invisible'>
							<PreviousIcon />
						</div>
					</div>
					<div className='flex flex-col pt-[35px] pb-[50px] px-[50px] '>
						<div>
							<SurveySections
								errorMessages={errorMessages}
								selectOption={selectOption}
								questions={selectedSection?.questions}
							/>
						</div>
						<button
							className={`mt-[35px] h-[60px] flex items-center justify-center cursor-pointer rounded-[10px] bg-deepPurple`}
							disabled={sendingRequest}
							onClick={checkAllResults}
						>
							{sendingRequest ? (
								<Loading primaryColor='#FDDF6D' secondaryColor='#5E639B' size={25} stroke={7} />
							) : (
								<p className='font-semibold text-[16px] text-highlightColor  leading-[20px]'>
									{isLastStage() ? t('finish') : t('nextSection')}
								</p>
							)}
						</button>
					</div>
				</div>
			)}
			{releaseByDays && (
				<div className='release-by-days-task-container' id='survey-scrolled'>
					<div className='release-by-days-task-header'>
						<p className='release-by-days-title'>{item.title}</p>
						{!!item.description && <p className='release-by-days-description'>{item.description}</p>}
					</div>
					<div className='release-by-days-body'>
						<div className='release-by-days-scroll'>
							{item?.sections.map((section, index) => {
								const isSelected = index == stageNumber;
								const isLocked = section?.releaseDate > dayCode;
								return (
									<div
										className={`release-day ${isSelected ? 'selected-release-day' : ''} ${
											isLocked ? 'release-day-locked' : ''
										}`}
										onClick={() => selectDay(index)}
										key={index}
									>
										{isLocked ? (
											<ReleaseByDaysLock fill={isSelected ? '#FFFFFF' : '#BEBECE'} />
										) : (
											`${t('day')} ${index + 1}`
										)}
									</div>
								);
							})}
						</div>
						{isLockedDay ? (
							<div className='locked-day-body'>
								<SaveForLaterSurvey />
								<p className='locked-day-text'>{t('This day is locked!')}</p>
							</div>
						) : (
							<div>
								<div>
									<p className='selected-section-title'>{selectedSection.title}</p>
									{!!selectedSection.description && (
										<p className='selected-section-description'>{selectedSection.description}</p>
									)}
								</div>
								<SurveySections
									errorMessages={errorMessages}
									selectOption={selectOption}
									questions={selectedSection?.questions}
								/>
							</div>
						)}
						{isLockedDay ? (
							<div className='release-by-days-footer'>
								<div className='finish-button' onClick={closeModal}>
									{t('close')}
								</div>
							</div>
						) : (
							<div className='release-by-days-footer'>
								<div className='finish-later-button' onClick={finishLater}>
									{t('Finish later')}
								</div>
								<div className='finish-button' disabled={sendingRequest} onClick={checkAllResults}>
									{isLastStage() ? t('Finish') : t('Next')}
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</ReactModal>
	);
}

const SurveySections = ({ errorMessages = [], questions = [], selectOption = () => {} }) => {
	const { t } = useTranslation();
	return (
		<div>
			{questions?.map((question, index) => {
				return (
					<div className='flex flex-col mb-[20px]' key={index}>
						<p className='font-bold text-[16px] leading-[20px] text-deepPurple '>
							{index + 1}. {question?.title}
						</p>
						{question.type == 'options' && (
							<div>
								<p
									className={`font-medium text-[14px] text-mudPurple mt-[15px] mb-[10px] leading-[20px]  ${
										errorMessages.includes(index) ? '!text-deleteRed !font-bold ' : ''
									}`}
								>
									{t('selectOneOption')}
								</p>
								<div className='flex flex-row items-center justify-between gap-2'>
									{question?.options?.map((option, i) => (
										<div
											className={`flex flex-col justify-center items-center  rounded-[10px] cursor-pointer p-[15px] noselect ${
												question.answer == option
													? 'bg-deepPurple text-highlightColor'
													: 'bg-containerGray text-mudPurple hover:bg-deselectPurple hover:text-white'
											} `}
											onClick={() => {
												selectOption(question, option);
											}}
											key={i}
											style={question?.options.length === 2 ? { width: '40%' } : {}}
										>
											<p className={`font-semibold text-[16px] leading-[20px] `}>{option}</p>
										</div>
									))}
								</div>
							</div>
						)}
						{question.type == 'yesNo' && (
							<div>
								<p
									className={`font-medium text-[14px] text-mudPurple mt-[15px] mb-[10px] leading-[20px] ${
										errorMessages.includes(index) ? '!text-deleteRed !font-bold ' : ''
									}`}
								>
									{t('selectOneOption')}
								</p>
								<div className='flex flex-row items-center justify-between gap-2'>
									{['Yes', 'No'].map((option, i) => (
										<div
											className={`flex flex-col justify-center items-center  rounded-[10px] cursor-pointer p-[15px] noselect w-[40%] ${
												question.answer == option
													? 'bg-deepPurple text-highlightColor'
													: 'bg-containerGray text-mudPurple hover:bg-deselectPurple hover:text-white'
											} `}
											onClick={() => {
												selectOption(question, option);
											}}
											key={i}
										>
											<p className={`font-semibold text-[16px]   leading-[20px]`}>{t(option)}</p>
										</div>
									))}
								</div>
							</div>
						)}
						{question.type == 'openAnswer' && (
							<div>
								{errorMessages.includes(index) && (
									<p className={`text-[14px]  mt-[15px] mb-[10px] leading-[20px] text-deleteRed font-bold  `}>
										{t('youHaveMissedThisQuestion')}
									</p>
								)}
								<div className='new-survey-textarea'>
									<textarea
										className='survey-textarea'
										placeholder={t('writeFullAnswerHere')}
										value={question.answer}
										onChange={(e) => {
											selectOption(question, e.target.value);
										}}
									/>
								</div>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default SurveyTask;
