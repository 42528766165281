import React, { useEffect, useState } from 'react';
import '../styles/SideBar.css';
//import Collapsedlogo from '../assets/svg/US-collapsed.svg';
//import Fulllogo from '../assets/svg/US-full.svg';
import { BrowserRouter as Router, Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getNumberProfilesForReview, getUnseenConversations, getById } from '../server/server';
import { useSocket } from '../context/socket';
// import i18n from 'i18next';
import { ReactComponent as Collapsedlogo } from '../assets/svg/US-collapsed.svg';
import { ReactComponent as FullLogo } from '../assets/svg/US-full.svg';
import { ReactComponent as Dashboard } from '../assets/svg/dashboard.svg';
import { ReactComponent as Content } from '../assets/svg/content.svg';
import { ReactComponent as Classroom } from '../assets/svg/classroom.svg';
import { ReactComponent as Analyze } from '../assets/svg/analyze.svg';
import { ReactComponent as Chat } from '../assets/svg/chat.svg';
import { ReactComponent as Management } from '../assets/svg/management.svg';
import { ReactComponent as Wellbeing } from '../assets/svg/wellbeing.svg';
import { ReactComponent as Videos } from '../assets/svg/videos.svg';
import { ReactComponent as Settings } from '../assets/svg/settings.svg';
import { ReactComponent as Logout } from '../assets/svg/logout.svg';
import { ReactComponent as CatchUp } from '../assets/svg/catch-up.svg';
import { ReactComponent as NavPin } from '../assets/svg/nav-pin.svg';
import { ReactComponent as ContentDrop } from '../assets/svg/contentDrop.svg';

import { useUser } from '../auth';
import { startOfWeek } from 'date-fns/esm';
import LogoutModal from './modals/LogoutModal';
import { useInstitution } from '../auth/InstitutionContext';
import i18n from '../i18n';
import { days } from '../constants/Constants';
function SideBar() {
	const [homestatus, setHomestatus] = useState('/dashboard');
	const location = useLocation();
	const { user, setUser } = useUser();
	let history = useHistory();
	const { t, locale } = useTranslation();
	const { socketStates, changeSocketState } = useSocket();
	const [messages, setMessages] = useState([]);
	const [profiles, setProfiles] = useState();
	const [collapsed, setCollapsed] = useState(true);
	const [selectedLang, setSelectedLanguage] = useState(i18n.language);
	const [isPinned, setIsPinned] = useState(false);
	const [showContent, setShowContent] = useState(false);
	const numberOfUnseenConversations = socketStates?.unseenConversations?.length || 0;
	const [logoutModalOpen, setLogoutModalOpen] = useState(false);
	const { institution, setInstitution } = useInstitution();

	useEffect(() => {
		const pathList = location.pathname.split('/');
		setHomestatus('/' + pathList[1]);
	}, [location.pathname]);

	const loadMessages = async () => {
		const response = await getUnseenConversations();
		if (response.status === 200) {
			changeSocketState({ unseenConversations: response?.data || [] });
		}
	};
	const loadProfiles = async () => {
		const profiles = await getNumberProfilesForReview();
		if (profiles.status === 200) {
			setProfiles(profiles?.data);
		}
	};

	const loadInstitution = async () => {
		const resp = await getById({ collection: 'institutions', id: user.institution_id });
		if (resp.status === 200) {
			setInstitution(resp?.data);
		}
	};

	useEffect(() => {
		loadMessages();
		loadProfiles();
		loadInstitution();
	}, []);

	useEffect(() => {
		const sum = socketStates?.unseenConversations?.length;
		document.title = `${sum ? `(${sum}) ` : ''}Upstrive Education - Teacher`;
	}, [socketStates]);

	useEffect(() => {
		document.getElementById('home-container').style.marginLeft = isPinned ? '280px' : '80px';
	}, [isPinned]);

	const renderIcon = (name, color) => {
		switch (name) {
			case 'dashboard':
				return <Dashboard fill={color} height={17} width={16} />;
			case 'content':
				return <Content fill={color} />;
			case 'classroom':
				return <Classroom fill={color} />;
			case 'analyze':
				return <Analyze fill={color} />;
			case 'chat':
				return <Chat fill={color} />;
			case 'management':
				return <Management fill={color} />;
			case 'wellbeing':
				return <Wellbeing fill={color} />;
			case 'videos':
				return <Videos fill={color} />;
			case 'settings':
				return <Settings fill={color} />;
			case 'logout':
				return <Logout fill={color} />;
			case 'catch-up':
				return <CatchUp fill={color} />;
			default:
				return <Dashboard fill={color} />;
		}
	};

	const sidebarData = [
		{
			name: 'dashboard',
			title: t('dashboard'),
			path: ['/dashboard'],
			className: 'nav-text',
		},
		{
			name: 'content',
			title: t('createContent'),
			disabled: true,
			path: ['/content', '/readytogo'],
			options: [
				{
					title: t('createYourOwn'),
					path: '/content',
				},
				{
					title: t('readyToGo'),
					path: '/readytogo',
				},
			],
			className: 'nav-text',
		},
		{
			name: 'classroom',
			title: t('classroom'),
			path: ['/classroom'],
			className: 'nav-text',
		},
		{
			name: 'analyze',
			title: t('Analytics'),
			path: ['/analytics'],
			className: 'nav-text',
		},
		{
			name: 'chat',
			title: t('chat'),
			path: ['/chat'],
			icon: <>{!!numberOfUnseenConversations && <span className='chat-badge'>{numberOfUnseenConversations}</span>}</>,
			className: 'nav-text',
		},
		{
			name: 'management',
			title: t('userManagement'),
			path: ['/management'],
			className: 'nav-text',
		},
		{
			name: 'wellbeing',
			title: t('wellbeingTracker'),
			path: ['/counseling'],
			// icon: <>{!!profiles && <span className="chat-badge">{profiles.length}</span>}</>,
			className: 'nav-text',
		},
		{
			name: 'catch-up',
			title: t('catchUp'),
			path: ['/catch-up'],
			className: 'nav-text',
		},
		{
			name: 'videos',
			title: t('instructionVideos'),
			path: ['/videos'],
			className: 'nav-text',
		},
	];
	const langs =
		user.institution_id === '5f25b9fc7fc9788dacc0ce7d'
			? [
					{ name: 'Eng', code: 'en' },
					{ name: 'De', code: 'de' },
					{ name: 'ESM', code: 'es' },
					// { name: 'Ar', code: 'ar' },
			  ]
			: [
					{ name: 'Eng', code: 'en' },
					{ name: 'De', code: 'de' },
					// { name: 'Ar', code: 'ar' },
			  ];
	const langMap = {
		en: 'Eng',
		// de: 'De',
		// ar: 'Ar',
	};
	const handleLangChange = (code) => {
		setSelectedLanguage(code);
		i18n.changeLanguage(code);
		localStorage.setItem('locale', code);
	};
	const closeModal = () => {
		setLogoutModalOpen(false);
	};

	const openLogoutModal = () => {
		setLogoutModalOpen(true);
	};

	const logout = () => {
		localStorage.clear();
		setUser({});
		history.push('/');
		setLogoutModalOpen(false);
	};
	useEffect(() => {
		setSelectedLanguage(i18n.language);
	}, [i18n.language]);

	useEffect(() => {
		if (isPinned) document.getElementById('root').classList.add('sidebarPinned');
		else document.getElementById('root').classList.remove('sidebarPinned');
	}, [isPinned]);

	return (
		<div
			id='sideBar'
			className={`side-bar bg-deepPurple ${collapsed ? 'side-bar-collapsed' : 'side-bar-open'}`}
			style={collapsed ? { width: 80 } : {}}
			onMouseLeave={() => {
				setShowContent(false);
			}}
		>
			<div
				className='flex flex-col items-center h-[100vh] w-full'
				onMouseLeave={() => {
					setCollapsed(isPinned ? false : true);
				}}
				onMouseEnter={() => setCollapsed(false)}
			>
				<div className='nav-pin bg-deepPurple' onClick={() => setIsPinned(!isPinned)}>
					<NavPin fill={isPinned ? '#FDDF6D' : '#F8F8FB'} style={{ display: 'block' }} />
				</div>
				<div
					className='flex flex-row items-center justify-center mt-6 min-h-[100px] relative cursor-pointer'
					onClick={() => {
						history.push(`/dashboard`);
					}}
				>
					<div className={`upstrive-logo-collapsed absolute ${collapsed ? 'opacity-100' : 'opacity-0'}`}>
						<Collapsedlogo />
					</div>
					<div className={`upstrive-logo-collapsed absolute ${!collapsed ? 'opacity-100' : 'opacity-0'}`}>
						<FullLogo />
					</div>
				</div>
				<div className='my-[5px]'>
					<span
						className={`px-[30px] text-[#F8F8FB] text-[16px] font-bold w-full ellipsis max-w-[270px] text-ellipsis ${
							collapsed ? 'opacity-0' : 'opacity-100'
						}`}
						style={{ display: 'block' }}
					>
						{institution.name}
					</span>
				</div>
				<div className='lang-changer-group mt-1 xl3:mt-6 border p-2 pointer font-bold text-lg border-mudPurple text-[#9392AD] rounded-full flex flex-row w-fit'>
					{langs.map((lang) => {
						if (collapsed && lang.code === selectedLang)
							return (
								<p className={`lang-item mx-2 ${lang.code === selectedLang ? 'text-white' : ''}`} key={lang.code}>
									{lang.name}
								</p>
							);
						else if (!collapsed)
							return (
								<p
									className={`lang-item mx-2 ${lang.code === selectedLang ? 'text-white' : ''}`}
									key={lang.code}
									onClick={(e) => {
										e.preventDefault();
										handleLangChange(lang.code);
									}}
								>
									{lang.name}
								</p>
							);
					})}
				</div>
				<div className='mt-1 xl3:mt-6 w-full flex flex-col pl-[30px] overflow-y-auto overflow-x-hidden'>
					<div className=' text-[#F8F8FB] font-bold text-lg flex flex-col justify-between h-[100vh]'>
						<div className='link-list'>
							{sidebarData.map((item, index) => {
								if (item.name == 'videos' && i18n.language != 'en') return null;
								if (user.hasOnlyPrimaryStudents && ['content', 'chat'].includes(item.name)) return null;
								return (
									<div
										key={item?.path[0]}
										data-title={item.title}
										// onClick={() => {
										// 	setHomestatus(item?.path[0]);
										// }}
										className={`my-0 xl3:my-3 ${item.className}`}
									>
										{item?.options && item?.options?.length > 0 ? (
											<div>
												<div onClick={() => setShowContent(!showContent)} className='pointer '>
													<div className={`h-[52px] flex flex-row  items-center relative`}>
														<div className='items-center'>
															{renderIcon(
																item?.name,
																item?.path?.includes(homestatus) ? '#FDDF6D' : '#F8F8FB'
															)}
														</div>
														{item?.icon}
														{!collapsed && (
															<span
																className={`px-3 flex justify-between items-center w-full ellipsis ${
																	item?.path?.includes(homestatus) ? ' text-highlightColor' : ''
																}`}
															>
																{item.title} <ContentDrop />
															</span>
														)}
													</div>
												</div>
												<div className={`items-start pl-10 ${showContent ? 'flex flex-col' : 'hidden'}`}>
													{item?.options?.map((option, index) => {
														return (
															<Link
																to={`${option.path}`}
																className='my-2'
																onClick={() => {
																	setHomestatus(option.path);
																}}
																key={index}
															>
																<div
																	className={`leading-[25px] ${
																		option?.path === homestatus ? 'text-highlightColor' : ''
																	}`}
																>
																	{option?.title}
																</div>
															</Link>
														);
													})}
												</div>
											</div>
										) : (
											<Link to={item?.path[0]}>
												<div className={`h-[52px] flex flex-row  items-center relative w-full`}>
													<div className='items-center'>
														{renderIcon(item?.name, item?.path?.includes(homestatus) ? '#FDDF6D' : '#F8F8FB')}
													</div>
													{!!item?.icon && <div className='chat-badge-wrapper'>{item?.icon}</div>}
													{!collapsed && (
														<span
															className={`px-3 w-full ellipsis ${
																item?.path?.includes(homestatus) ? ' text-highlightColor' : ''
															}`}
														>
															{item.title}
														</span>
													)}
												</div>
											</Link>
										)}
									</div>
								);
							})}
						</div>
						<div className='bottom-container'>
							<div
								className=' cursor-pointer nav-text'
								onClick={() => {
									setHomestatus('/settings');
								}}
							>
								<Link to='/settings'>
									<div className={`h-[52px] flex flex-row  items-center relative`}>
										<div className='items-center'>
											{renderIcon('settings', homestatus === '/settings' ? '#FDDF6D' : '#F8F8FB')}
										</div>
										{!collapsed && (
											<span className={`px-3 ${homestatus === '/settings' ? ' text-highlightColor' : ''}`}>
												{t('settings')}
											</span>
										)}
									</div>
								</Link>
							</div>
							<div className=' nav-text cursor-pointer'>
								<div className={`h-[52px] flex flex-row  items-center relative`} onClick={() => openLogoutModal()}>
									<div className='items-center'>{renderIcon('logout', '#F8F8FB')}</div>
									{!collapsed && <span className='px-3 cursor-pointer'>{t('logout')}</span>}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<LogoutModal modalIsOpen={logoutModalOpen} closeModal={closeModal} logout={logout} />
		</div>
	);
}

export default SideBar;
