import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import '../../styles/modals/AssignedActionToMeModal.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as PaperPlane } from '../../assets/svg/PaperPlane.svg';
import { getWellbeingProfilesForReviewAsync, takeActionFeedbackComment } from '../../server/server';
import { emotions_color, emotions_heads } from '../../constants/Constants';
import { TextareaAutosize } from '@mui/material';
import ReactTooltip from 'react-tooltip';
import { useUser } from '../../auth';
import { format_day_only } from '../../utils/util';

function AssignedActionsToMeModal({ modalIsOpen, afterOpenModal, closeModal, confirmation, message, subMessage = '' }) {
	const { t, locale } = useTranslation();
	const [assignments, setAssignments] = useState([]);
	const [loading, setLoading] = useState(true);
	const [activatedIndex, setActivatedIndex] = useState(null);

	useEffect(() => {
		if (modalIsOpen) {
			setActivatedIndex(null);
			setLoading(true);
			fetchAssignment();
		}
	}, [modalIsOpen]);

	const fetchAssignment = async () => {
		const resp = await getWellbeingProfilesForReviewAsync();
		if (resp.status == 200) {
			console.log(resp);
			const data = resp.data;
			setAssignments(data);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (modalIsOpen) {
			Modal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);

	const activateAssignment = (index) => {
		setActivatedIndex(index);
	};

	const actionTaken = () => {
		if (assignments.length != 1) {
			setActivatedIndex(null);
			setLoading(true);
			fetchAssignment();
		} else {
			closeModal();
		}
	};

	return (
		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			contentLabel='Example Modal'
			style={{
				overlay: {
					zIndex: 10,
				},
				content: {
					width: 600,
					padding: 0,
					opacity: 1,
					minWidth: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '90vh',
					border: 'none',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div className={`w-full justify-center flex h-full`}>
				<div
					className='hover:bg-deepPurple pointer absolute top-[10px] flex bg-darkenPurple rounded-full w-[60px] h-[60px] justify-center items-center'
					onClick={() => closeModal()}
				>
					<CloseModalIcon />
				</div>
				<div className='bg-containerGray rounded-xl mt-10 h-full w-full overflow-hidden min-h-[670px]'>
					<div className='bg-[#ECEBF8] p-8 rounded-xl'>
						<h2 className='text-deepPurple font-bold text-3xl mt-3 text-left'>{t('actionsAssignedToMe')} </h2>
						<p className='text-left text-mudPurple text-sm mt-2'>
							{assignments.length} {t('unreadMessage')}{' '}
						</p>
					</div>
					<div className=''>
						<div className='history-list-scroll gray-small-scroll p-8'>
							{assignments.map((assignment, index) => {
								return (
									<Assignment
										assignment={assignment}
										activated={index == activatedIndex}
										activate={activateAssignment}
										index={index}
										actionTaken={actionTaken}
										key={index}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export const Assignment = ({ assignment = {}, activated = false, activate = () => {}, index, actionTaken = () => {} }) => {
	const { user, setUser, isSignedIn } = useUser();
	const student = assignment.student;
	const className = student?.classesName?.replace('Class', '');
	const assignedBy = assignment?.assignedBy;
	const assignmentMessage = assignment?.actionMessage;
	const feedback = assignment?.feedback ?? {};
	const studentMessage = feedback?.message;
	const { t, locale } = useTranslation();

	const [message, setMessage] = useState('');

	const clickActivate = () => {
		activate(activated ? null : index);
	};

	const createNote = async () => {
		const data = {
			user_id: user['id'],
			feedback_id: feedback['id'],
			user_message: message,
			assignmentMessage: assignment.message,
			assignedBy: assignedBy,
			assignmentId: assignment?.id,
		};
		const resp = await takeActionFeedbackComment(data);
		if (resp.status === 200) {
			setMessage('');
			actionTaken();
		}
	};

	return (
		<div className={` rounded-xl my-2 ${activated ? 'assignment-container' : ''}`}>
			<div className='p-5  bg-[#ffc54533] rounded-xl'>
				<div className='flex flex-row mb-[20px]'>
					{!!feedback?.emotion && (
						<div className={`mr-[15px]`}>
							<img
								src={emotions_heads[feedback.emotion]}
								alt='emotion'
								width={64}
								height={64}
								className=' shadow-red rounded-full '
							/>
						</div>
					)}
					<div className='flex-1'>
						<p className='font-bold text-[#5E639B] text-[16px] mb-[0px]'>
							{student?.first_name} {student?.last_name}
						</p>
						<div className='flex flex-row justify-between mb-[10px]'>
							<span className={`text-[14px] text-[#9392AD] ${student?.role === 0 ? 'opacity-100' : 'opacity-0'}`}>
								{t('Class')} {className} ( {t('year')} {student?.year} )
							</span>
							<span className='text-[14px] text-[#9392AD]'>
								<span className='opacity-[0.6]'>{t('date')}:</span>
								<span className='capitalize'>{format_day_only(assignment.created_date)}</span>
							</span>
						</div>
						{!!studentMessage && <p className='text-[#5E639B] text-[14px] '>"{studentMessage}"</p>}
					</div>
				</div>
				<div>
					<p className='text-[#5E639B] text-[14px] mb-[15px]'>
						<span className='opacity-[0.6]'>{t('assignedBy')}:</span> {assignedBy?.first_name} {assignedBy?.last_name}
					</p>
					<p className='text-[#5E639B] text-[14px] mb-[15px]'>{assignmentMessage}</p>
				</div>
				<div className='action-buttons-row'>
					<div
						className={`transparent-button action-button pointer relative ${activated ? 'bg-[#9392AD]' : 'bg-white'}`}
						onClick={clickActivate}
					>
						<div className={`${activated ? 'text-white' : ''}`}>{t('iWillTakeAction')} </div>
						{activated && <div className={` w-[10px] h-[10px] absolute border-8 rotate-45 top-[32px] border-[#9392AD]`}></div>}
					</div>
					{/* <button className='transparent-button action-button'>Leave comment</button> */}
					{/* <button className='action-button'>Mark as completed</button> */}
				</div>
			</div>
			{activated && (
				<div className='assignment-comment-section'>
					<TextareaAutosize
						placeholder={t('enterYourNoteHere')}
						minRows={4}
						value={message}
						style={{ resize: 'none', color: '#5E639B', backgroundColor: 'transparent', width: '100%', padding: 10 }}
						autoFocus
						onChange={(e) => {
							e.preventDefault();
							setMessage(e.target.value);
						}}
					/>

					<button
						className='assignment-paperplane'
						onClick={(e) => {
							e.preventDefault();
							createNote();
						}}
						data-for='createNote'
						data-tip={t('addNote')}
						style={{ marginBottom: -10 }}
						disabled={message.length === 0}
					>
						<PaperPlane />
						{message.length === 0 && (
							<ReactTooltip
								backgroundColor='#5E639B'
								arrowColor='#5E639B'
								place='top'
								effect='solid'
								id='createNote'
								className='wellbeing-tooltip'
							/>
						)}
					</button>
				</div>
			)}
		</div>
	);
};

export default AssignedActionsToMeModal;
