import React, { useEffect, useState } from 'react';
import { ReactComponent as CsvIcon } from '../../../assets/svg/ReportIcons/CsvIcon.svg';
import { ReactComponent as ExcelIcon } from '../../../assets/svg/ReportIcons/ExcelIcon.svg';
import { ReactComponent as PdfIcon } from '../../../assets/svg/ReportIcons/PdfIcon.svg';
import { ReactComponent as Magnify } from '../../../assets/svg/magnify.svg';
import Loading from '../../Loading';
import { getReports } from '../../../server/server';
import { formatDate } from '../../../utils/util';
import { fileSystemUrl, reportSystemUrl } from '../../../constants/Connections';
import { useUser } from '../../../auth';
import { format } from 'date-fns';
import { localeMap } from '../../../constants/Constants';
// import i18n from '../../../i18n';

import { useTranslation } from 'react-i18next';

const FILE_ICON = {
	xls: <ExcelIcon />,
	csv: <CsvIcon />,
	pdf: <PdfIcon />,
};

function ListOfReports({ refreshList }) {
	const { t, locale, i18n } = useTranslation();
	const [searching, setSearching] = useState(false);
	const [loading, setLoading] = useState(true);
	const [reports, setReports] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [needToRefresh, setNeedToRefresh] = useState(false);
	const [timeoutTime, setTimeoutTime] = useState(10000);

	useEffect(() => {
		if (needToRefresh)
			setTimeout(() => {
				loadReports();
				setTimeoutTime(timeoutTime === 10000 ? 20000 : 10000);
			}, timeoutTime);
	}, [needToRefresh, timeoutTime]);

	useEffect(() => {
		loadReports();
	}, [refreshList]);

	const loadReports = async (text = '') => {
		const resp = await getReports(text);
		if (resp.status === 200) {
			setReports(resp.data);
			const needToRefresh = resp?.data?.filter((e) => !e.finished)?.length > 0;
			setNeedToRefresh(needToRefresh);

			setSearching(false);
			setLoading(false);
		}
	};
	const searchTextLower = searchText.toLowerCase();
	const filterReports = (report) => !!report?.title?.toLowerCase()?.includes(searchTextLower);

	return (
		<div className='rounded-[10px] bg-[white] flex-1 flex flex-col overflow-hidden'>
			<div className='mb-[36px] mt-[45px] px-[32px]'>
				<div className='flex flex-row h-[44px] rounded-full items-center gap-[10px] pl-[15px] w-[360px] border border-[#BEBECE]'>
					<input
						className='flex-1 pl-[5px] font-medium'
						placeholder={t('search')}
						onChange={(e) => setSearchText(e.target.value)}
					/>
					{searching ? (
						<div className='mr-[15px]'>
							<Loading primaryColor='#5E639B' />
						</div>
					) : (
						<Magnify fill='#5E639B' />
					)}
				</div>
			</div>
			<div className='flex-1 flex flex-col overflow-hidden'>
				<div className='flex flex-row h-[67px] items-center mx-[32px] gap-[20px] border-y border-y-[#ECEBF8]'>
					<div className='w-[120px] text-[#413F5E] font-medium text-[14px]'>#</div>
					<div className='text-[#413F5E] font-medium text-[14px] flex-1'>{t('name')}</div>
					<div className='text-[#413F5E] font-medium text-[14px] w-[100px] text-center'>{t('Date')}</div>
					<div className='text-[#413F5E] font-medium text-[14px] text-center w-[100px]'>{t('action')}</div>
				</div>
				<div className='overflow-auto light-small-scroll  flex-1 '>
					<div className=' flex flex-col  '>
						{reports.filter(filterReports).map((report, index) => (
							<ReportItem report={report} key={report.id} index={index} />
						))}
					</div>
					{loading && (
						<div className='flex-1 flex items-center justify-center h-full'>
							<Loading />
						</div>
					)}
					{!loading && reports.length == 0 && (
						<div className='flex-1 flex items-center justify-center h-full'>
							<p className='text-[#9392AD] text-[14px] font-medium'>{t('No reports have been created yet')}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

const getReportItemClass = (inProgress = false, isNew = false) => {
	if (inProgress) return 'in-progress-report';
	else if (isNew) return 'new-report';
	return 'classic-report';
};

export const ReportItem = ({ report = {}, index = 0 }) => {
	const { t, i18n } = useTranslation();
	const isNew = false; //!!report.new && !!report.finished;
	const inProgress = !report.finished;

	const reportClass = getReportItemClass(inProgress, isNew);
	return (
		<div className={`flex flex-row h-[50px] items-center gap-[20px] border-b  ${reportClass}`}>
			<div className='w-[120px]'>
				{inProgress && (
					<div className='flex flex-row items-center gap-[8px]'>
						<span className='text-[#5E639B] text-[14px] font-bold'>{t('In process')}</span>
						<Loading primaryColor='#5E639B' size={24} stroke={6} />
					</div>
				)}
				{!isNew && !inProgress && <div className='text-[#5E639B] text-[14px] font-bold'>{index + 1}</div>}
			</div>
			<div className='flex-1 text-[#413F5E] font-bold text-[14px]'>{report?.title ?? 'Custom name'}</div>
			<div className='w-[100px] text-center text-[#413F5E] font-bold text-[14px]'>
				{format(new Date(report?.created_date ?? report?.create_date), 'dd.MM.yyyy. HH:mm', { locale: localeMap[i18n.language] })}
			</div>
			<div className='flex flex-row gap-[10px] w-[100px] justify-center'>
				{report.formats.map((reportFormat) => (
					<ReportButton isNew={isNew} inProgress={inProgress} type={reportFormat} path={report.report_id} name={report.title} />
				))}
			</div>
		</div>
	);
};
export const SimpleReportItem = ({ report = {}, index = 0 }) => {
	const { i18n } = useTranslation();
	const isNew = false; //!!report.new && !!report.finished;
	const inProgress = !report.finished;

	const reportClass = getReportItemClass(inProgress, isNew);

	return (
		<div className={`flex flex-row h-[50px] items-center gap-[20px] rounded-[10px]  bg-[#F8F8FB] pl-[30px]`}>
			{/* <div className='w-[50px]'>
				{inProgress && (
					<div className='flex flex-row items-center gap-[8px]'>
						<span className='text-[#5E639B] text-[14px] font-bold'>In process</span>
						<Loading primaryColor='#5E639B' size={24} stroke={6} />
					</div>
				)}
			</div> */}
			<div className='flex-1 text-[#413F5E] font-bold text-[14px]'>{report?.title ?? 'Custom name'}</div>
			<div className='w-[200px] text-center text-[#413F5E] font-bold text-[14px]'>
				{format(new Date(report?.created_date ?? report?.create_date), 'HH:mm dd.MM.yyyy.', { locale: localeMap[i18n.language] })}
			</div>
			<div className='flex flex-row gap-[10px] w-[100px] justify-center'>
				<ReportButton isNew={isNew} inProgress={inProgress} type={report.formats[0]} path={report.report_id} name={report.title} />
			</div>
		</div>
	);
};

const getButtonClass = (inProgress = false, isNew = false) => {
	if (inProgress) return 'disabled-report-button';
	else if (isNew) return 'new-report-button';
	return 'classic-report-button';
};

const ReportButton = ({ type = 'csv', inProgress = false, isNew = false, path, name }) => {
	const buttonClass = getButtonClass(inProgress, isNew);
	const { user } = useUser();

	return (
		<a
			href={reportSystemUrl + '/' + path + '?token=' + user?.token}
			download={`${name}.${type}`}
			className={inProgress ? 'pointer-events-none cursor-default' : ''}
			target='_blank'
			rel='noreferrer'
		>
			<div
				className={`flex flex-row items-center h-[30px] px-[15px] gap-[10px] rounded-full transition-all duration-[230ms] border ease-in-out ${buttonClass}`}
			>
				<span className='font-bold text-[14px]'>.{type}</span>
				{FILE_ICON[type]}
			</div>
		</a>
	);
};

export default ListOfReports;
