import React, { useState } from 'react';
import ReactModal from 'react-modal';
import '../../../styles/modals/PollScheduleModal.css';
import { FaTimes, FaPause, FaTrash, FaPlay } from 'react-icons/fa';
import { getRunningPrograms } from '../../../server/server';
import { useUser } from '../../../auth';
import ReactTooltip from 'react-tooltip';
import { changeProgramStatus, removeRunningProgram } from '../../../server/server';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ReactComponent as RunningIcon } from '../../../assets/svg/RunningIcon.svg';
import { ReactComponent as DeleteContent } from '../../../assets/svg/DeleteContent.svg';
import { ReactComponent as NotFoundIcon } from '../../../assets/svg/NotFoundIcon.svg';

import { ReactComponent as StopRun } from '../../../assets/svg/StopRun.svg';
import { ReactComponent as StartRun } from '../../../assets/svg/StartRun.svg';
import { notify, translateTargetText } from '../../../utils/util';

function RunningProgram({ selectedRunningProgram }) {
	const { user, setUser } = useUser();
	const { t, locale } = useTranslation();
	const [elements, setElements] = useState([]);
	const [deleteModalOpen, setDelModalOpen] = useState({
		isOpen: false,
		type: '',
	});
	const [selProgram, setSelProgram] = useState({});
	const closeDelModal = () => {
		console.log('colose moda');
		setDelModalOpen({
			isOpen: false,
			type: '',
		});
	};
	const getPrograms = async () => {
		const response = await getRunningPrograms({ user });
		if (response.status === 200) {
			if (selectedRunningProgram.length !== 0) {
				let filteredData = response.data.filter((item) => selectedRunningProgram.includes(item.id));
				setElements(filteredData);
			} else {
				setElements(response.data);
			}
			console.log('reagfaeg', response.data);
		} else {
			setElements([]);
		}
	};

	React.useEffect(() => {
		getPrograms();
	}, [selectedRunningProgram]);
	const handleChange = async () => {
		closeDelModal();
		let tempProgram = selProgram;
		tempProgram.status = tempProgram.status == 'start' ? 'stop' : 'start';
		const response = await changeProgramStatus({
			program: tempProgram,
		});
		if (response.status === 200) {
			//handleSuccess
		}
	};
	const changeStatus = (program, type) => {
		setSelProgram(program);
		setDelModalOpen({ isOpen: true, type: type });
	};
	const handleDelete = async () => {
		closeDelModal();
		const response = await removeRunningProgram({
			program: selProgram,
		});
		if (response.status === 200) {
			console.log(elements.filter((item) => item.id !== selProgram.id));
			setElements(elements.filter((item) => item.id !== selProgram.id));
			notify(t('Program has been deleted!'));
		} else {
		}
	};
	const switchConfirm = () => {
		if (deleteModalOpen.type === 'del') {
			handleDelete();
		} else {
			handleChange();
		}
	};
	return (
		<div className='bg-white w-[1200px] min-h-[500px] rounded-xl flex flex-col h-max'>
			<div className='px-14 p-8 rounded-xl w-full'>
				<div className='flex justify-start items-center'>
					<RunningIcon />
					<span className='text-deepPurple font-bold text-lg max-w-[550px] text-left ml-5'>
						{t('thisToolHelpsYouToControlAllRunningProgramsAndTrackTheirInfluenceToStudents')}
					</span>
				</div>
				<div className=' mt-5' style={{ overflowY: 'auto' }}>
					{elements?.map((element, index) => {
						return (
							<div
								className='running-program-module flex items-center mb-3 overflow-hidden rounded-xl border border-containerGray hover:border-deepPurple pointer'
								key={index}
							>
								<div className='bg-containerGray rounded-xl w-full h-[80px] flex justify-between items-center px-4'>
									<p className='text-deepPurple text-xl font-bold text-left'>{element.program.name}</p>
									<div className='flex items-center'>
										<div className='flex gap-2 bg-lightBG rounded-full px-10  h-[40px] items-center text-base font-semibold text-deselectPurple mr-3'>
											{element.targetDays.map((day, index) => {
												return (
													<div
														key={index}
														className={
															day.selected ? 'text-deepPurple bg-white rounded-full p-1 px-2' : 'p-1 px-2'
														}
													>
														{t(day.name)}
													</div>
												);
											})}
										</div>

										<p className='h-[40px] px-3 flex items-center mr-5 text-deepPurple font-semibold bg-lightBG rounded-full'>
											{element.status == 'start' ? t('running') : t('paused')}
										</p>
										<p className='h-[40px] px-3 flex items-center text-deepPurple font-semibold bg-lightBG rounded-full'>
											{translateTargetText(element.target_text)}
										</p>
									</div>
								</div>
								<div className='running-period-module-buttons'>
									<button
										className='running-program-status-button'
										data-tip={`${element.status == 'start' ? t('pauseProgram') : t('playProgram')}`}
										data-for='PlayPausetip'
										onClick={() => changeStatus(element, 'cha')}
										style={i18n.language == 'ar' ? { marginRight: '10px' } : {}}
									>
										{element.status == 'start' ? (
											<StopRun className='fill-mudPurple hover:fill-deepPurple' />
										) : (
											<StartRun className='fill-mudPurple hover:fill-deepPurple' />
										)}
										<ReactTooltip
											id='PlayPausetip'
											arrowColor='transparent'
											effect='solid'
											backgroundColor='rgb(97,97,97)'
										/>
									</button>
									<button
										className='running-program-status-button'
										style={{ paddingLeft: '10px' }}
										data-tip={t('deleteProgram')}
										data-for='deleteProgram'
										onClick={() => changeStatus(element, 'del')}
									>
										<DeleteContent fill='#FF747F' className='fill-deleteRed hover:fill-[#FF6B3D]' />
										<ReactTooltip
											id='deleteProgram'
											arrowColor='transparent'
											effect='solid'
											backgroundColor='rgb(97,97,97)'
										/>
									</button>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{elements.length === 0 && (
				<div className='flex w-full h-full flex-col items-center justify-center text-deepPurple text-sm'>
					<NotFoundIcon />
					<div className='mt-3'>{t('yourRunningProgramListIsEmptyStartByAddingOneFromTheListOfPrograms')}</div>
				</div>
			)}
			<ConfirmationModal
				modalIsOpen={deleteModalOpen.isOpen}
				closeModal={closeDelModal}
				confirmation={switchConfirm}
				message={
					deleteModalOpen.type === 'del'
						? t('doYouWantToDeleteThisProgram')
						: selProgram.status === 'start'
						? t('doYouWantToStopThisProgram')
						: t('doYouWantToStartThisProgram')
				}
			/>
		</div>
	);
}

export default RunningProgram;
