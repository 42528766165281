import React, { useEffect, useState } from 'react';
import '../../styles/content/content.css';
import { useTranslation } from 'react-i18next';
function ScheduleSwitcher({ setSwitcher, switcher, customOnTextEnabled = false, customOnText = '' }) {
	const [counter, setCounter] = useState(0);
	const { t, locale } = useTranslation();
	const onClick = () => {
		setSwitcher(!switcher);
		setCounter(counter + 1);
	};

	const ON = customOnTextEnabled ? customOnText : t('on');

	useEffect(() => {
		if (switcher && counter == 0) setCounter(1);
	}, [switcher]);

	return (
		<div
			className='flex flex-row justify-between items-center pointer bg-containerGray h-[40px] px-[15px] rounded-full'
			onClick={onClick}
		>
			<div className={`font-semibold text-lg mr-[12px] ${switcher ? 'text-[#5E639B]' : 'text-mudPurple'}`}>
				{switcher ? ON : t('off')}
			</div>
			<div
				className={`rounded-full w-[40px] p-1 flex flex-row items-center switcher-animation ${
					switcher ? 'bg-deepPurple' : 'bg-deselectPurple'
				}`}
			>
				<div className={`${switcher ? 'flex-1' : 'flex-0'}`}></div>
				<div className={`min-w-[16px] w-[16px] h-[16px]  rounded-full ${switcher ? 'bg-highlightColor' : 'bg-white'}`}></div>
			</div>
		</div>
	);
}

export default ScheduleSwitcher;
