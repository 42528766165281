import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import TRANSLATION_DE from './translations/de';
import TRANSLATION_EN from './translations/en';
import TRANSLATION_AR from './translations/ar';
import TRANSLATION_ES from './translations/es';

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: localStorage.getItem('locale') || 'en',
		debug: false,
		resources: {
			en: {
				translation: TRANSLATION_EN,
			},
			de: {
				translation: TRANSLATION_DE,
			},
			ar: {
				translation: TRANSLATION_AR,
			},
			es: {
				translation: TRANSLATION_ES,
			},
		},
		detection: {
			order: ['queryString', 'cookie'],
			cache: ['cookie'],
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});
export default i18n;
