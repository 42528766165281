import React, { useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const ImageWithPlaceholder = ({ url, width = 125 }) => {
	const [loadingImage, setLoadingImage] = useState(false);
	return (
		<div className='simage-container-new' style={{ minWidth: width, maxWidth: width }}>
			{loadingImage && (
				<SkeletonTheme baseColor='#dadaff' highlightColor='#efefef'>
					<Skeleton />
				</SkeletonTheme>
			)}
			<img
				src={url.includes('width') ? url : url + '&width=' + width}
				style={{ width: '100%', borderRadius: 10, maxWidth: width }}
				alt='Task'
				className='task-image'
				onLoad={() => setLoadingImage(false)}
			/>
		</div>
	);
};

export default ImageWithPlaceholder;
