import React from 'react';
import AnalyticsBackground from '../../assets/svg/AnaliticsBackground.svg';
import { ReactComponent as AnalyticsStar } from '../../assets/svg/AnaliticsStar.svg';
import { useTranslation } from 'react-i18next';

function AnalyticsHeader() {
	const { t, locale } = useTranslation();
	return (
		<div
			style={{
				background: `url(${AnalyticsBackground})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
			className='w-full rounded-[10px] relative min-h-[120px] flex flex-row items-center px-10'
		>
			<div className='flex flex-col justify-center'>
				<p className='text-[31px] leading-10 text-white font-bold '>{t('analytics')} </p>
				<p className='text-[14px] leading-5 text-deselectPurple font-medium '>{t('getDeeperInsights')} </p>
			</div>
			<div className='flex-1 flex items-center justify-end pr-[100px]'>{<AnalyticsStar />}</div>
			<img
				src={require('../../assets/images/AnalyticsMagnifier.png')}
				alt='Analytics Magnifier'
				style={{ width: 76, marginRight: 10 }}
			/>
			<img
				src={require('../../assets/images/AnalyticsMascot.png')}
				alt='Analytics Mascot'
				style={{ height: 120, marginTop: -10, width: 293 }}
			/>
		</div>
	);
}

export default AnalyticsHeader;
