import React, { useEffect } from 'react';
import Modal from 'react-modal';
import '../../styles/modals/ConfirmationFeedDeleteModal.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConfirmImage } from '../../assets/svg/ConfirmImage.svg';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as MonitoringGroupMascot } from '../../assets/svg/MonitoringGroupMascot.svg';
import { ReactComponent as MonitoringGroupMascotHand } from '../../assets/svg/MonitoringGroupMascotHand.svg';
import { emotions_heads } from '../../constants/Constants';
import { useState } from 'react';
import { getMonitoringStatsForTargetGroup } from '../../server/server';
import Loading from '../Loading';

function MonitoringGroupModal({
	modalIsOpen,
	afterOpenModal = () => {},
	closeModal = () => {},
	groups = ['#1', '#2', '#3', '#4'],
	selectedGroup,
}) {
	const { t, locale } = useTranslation();

	const [selectedMonitoringGroup, setSelectedMonitoringGroup] = useState({});
	const [loading, setLoading] = useState(true);
	const [percentage, setPercentage] = useState({ total: 0, negativeEmotions: 0 });
	const [trend, setTrend] = useState(0);
	const [emotionsData, setEmotionsData] = useState([]);

	useEffect(() => {
		if (modalIsOpen) {
			Modal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
			setSelectedMonitoringGroup(selectedGroup);
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen, selectedGroup]);

	const submitConfirm = () => {};

	const selectMonitoringGroup = (monitoringGroup) => {
		setSelectedMonitoringGroup(monitoringGroup);
	};

	useEffect(() => {
		if (modalIsOpen) getStatisticsForSelectedGroup(selectedMonitoringGroup);
	}, [selectedMonitoringGroup]);

	const getStatisticsForSelectedGroup = async (targetGroup) => {
		setLoading(true);
		const resp = await getMonitoringStatsForTargetGroup(targetGroup.id, true);
		if (resp.status === 200) {
			const data = resp.data;
			setTrend(data.trend);
			setEmotionsData(data.emotionsData);
			setPercentage({ total: data.percentage });
			setLoading(false);
		}
	};

	const targetGroupName =
		selectedMonitoringGroup.year == 'all' ? t('all students') : `students from year ${selectedMonitoringGroup.year}`;

	return (
		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			contentLabel='Example Modal'
			style={{
				overlay: {
					zIndex: 10,
				},
				content: {
					width: 630,
					height: 630,
					padding: 0,
					opacity: 1,
					minWidth: 600,
					margin: 'auto',
					marginTop: '2%',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					margin: 'auto',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className={`feedback-modal-icon bg-[#5E639B] `} onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
			</div>
			<div className='bg-whiteContrast rounded-xl flex flex-col items-center  p-10 mt-[30px] flex-1'>
				<div className='mt-[30px] max-w-[500px] overflow-x-auto overflow-y-hidden whitespace-nowrap light-small-scroll horizontal-scroll'>
					{groups.map((group, index) => {
						const isSelected = selectedMonitoringGroup.id === group.id;
						return (
							<button
								className={`monitoring-group-button ${isSelected ? 'bg-[#5E639B]' : ''}`}
								onClick={() => {
									selectMonitoringGroup(group);
								}}
								key={index}
							>
								<p style={{ color: isSelected ? '#FDDF6D' : '#5E639B' }}>{t(group.name)}</p>
							</button>
						);
					})}
				</div>
				<div className='mt-[20px] relative -mr-[40px]'>
					<MonitoringGroupMascot />
					<div className='absolute top-[18px] left-[10px] z-[3]'>
						<MonitoringGroupMascotHand />
					</div>
				</div>
				<div className='relative min-h-[60px] px-[10px] bg-[#ECEBF8] flex flex-row rounded-[100px] -mt-[38px] z-[2] items-center'>
					{selectedMonitoringGroup?.emotions?.map((emotion) => (
						<img src={emotions_heads[emotion]} alt='emotion' className='w-[20px] h-[20px] mx-[5px] my-[1px]' />
					))}
				</div>
				{loading ? (
					<div className='min-h-[60px] mt-[25px] flex items-center'>
						<Loading />
					</div>
				) : (
					<div className='text-[#413F5E] text-[20px] font-bold max-w-[430px] text-center mt-[25px] min-h-[60px]'>
						{Math.round(percentage.total * 100)}
						{t('% of all students are with selected type of emotions')}
						{trend != 0 && (
							<>
								{' '}
								{t(`It is 71% ${trend > 0 ? 'more' : 'less'} then on previous week`, {
									num: Math.abs(trend),
								})}
							</>
						)}
					</div>
				)}
				<button
					className='w-[295px] bg-[#5E639B] rounded-[140px] h-[50px] items-center mt-[30px] text-[#FDDF6D] font-[600]'
					onClick={() => closeModal()}
				>
					{t('close')}
				</button>
			</div>
		</Modal>
	);
}

export default MonitoringGroupModal;
