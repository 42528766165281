import React from "react";
import ReactModal from "react-modal";
import "../../styles/modals/PollScheduleModal.css";
import { FaTimes } from "react-icons/fa";
function ImagePreviewModal({ showImg, closeImg, url }) {
    React.useEffect(() => {
        ReactModal.setAppElement("#root");
    }, [showImg]);
    return (
        <ReactModal
            transparent
            closeTimeoutMS={600}
            isOpen={showImg}
            onAfterOpen={() => {}}
            onRequestClose={closeImg}
            style={{
                overlay: {
                    zIndex: 10,
                    backgroundColor: "rgba(44,44,44,0.5)",
                    position: "absolute",
                    overflow: "auto",
                },
                content: {
                    padding: 0,
                    opacity: 1,
                    width: "80%",
                    maxWidth: 1300,
                    maxHeight: "90%",
                    height: "fit-content",
                    margin: "auto",
                    marginTop: -10,
                    backgroundColor: "#d7e2f8",
                    borderRadius: 22,
                    border: "none",
                },
            }}
        >
            <div onClick={(e) => e.stopPropagation()}>
                <span
                    className="cancel-question-btn"
                    onClick={() => closeImg()}
                    style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        color: "black",
                        transform: "scale(1.5)",
                    }}
                >
                    <FaTimes />
                </span>

                <img src={url} alt="Image" style={{ height: "100%", width: "100%" }} />
            </div>
        </ReactModal>
    );
}

export default ImagePreviewModal;
