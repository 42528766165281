import React, { useRef, useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as StudentFeedbackIcon } from '../../assets/svg/StudentFeedbackIcon.svg';
import { ReactComponent as FeedbackStar } from '../../assets/svg/FeedbackStar.svg';
import { ReactComponent as TargetUndo } from '../../assets/svg/undoTarget.svg';

import '../../styles/modals/TeacherFeedbackModal.css';
import { useTranslation } from 'react-i18next';
import { collectUsersFeedback } from '../../server/server';

function TeacherFeedbackModal({ modalIsOpen, closeModal, openSuccessModal, rejectReview }) {
	const { t, locale } = useTranslation();
	const [starNumber, setStarNumber] = useState(0);
	const [feedbackType, setFeedbackType] = useState('stars');
	const [answer_text, setAnswerText] = useState('');

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		} else {
		}
	}, [modalIsOpen]);

	const shareFeedback = async () => {
		const data = {
			score: starNumber,
			message: answer_text,
			created_date: Date.now(),
		};
		const resp = await collectUsersFeedback(data);
		if (resp.status === 200) {
			openSuccessModal();
			closeModal();
		}
	};

	const handleFeedbackType = () => {
		setFeedbackType('text');
	};

	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				setStarNumber(0);
				setFeedbackType('stars');
				setAnswerText('');
			}}
			onRequestClose={() => {
				rejectReview();
				closeModal();
			}}
			className='teacher-feedback-no-scroll'
			style={{
				overlay: {
					zIndex: 4,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'scroll',
					paddingTop: 35,
					paddingBottom: 15,
				},
			}}
		>
			<div className='teacher-feedback-container'>
				<div
					className='teacher-feedback-close-button'
					onClick={() => {
						closeModal();
						rejectReview();
					}}
				>
					<CloseModalIcon />
				</div>
				{feedbackType === 'text' && (
					<div className='teacher-feedback-back-button' onClick={() => setFeedbackType('stars')}>
						<TargetUndo style={{ fill: '#BEBECE', width: 23, height: 16 }} />
					</div>
				)}
				{feedbackType === 'stars' && (
					<div className='teacher-feedback-body'>
						<StudentFeedbackIcon />
						<p className='teacher-feedback-title'>{t('Your opinion matters to us!')}</p>
						<p className='teacher-feedback-subtitle'>
							{t('Your feedback helps us to improve. If you find our app helpful, please consider rating it.')}
							<br />
							{t('Thank you!')}
						</p>
						<div className='teacher-feedback-star-list'>
							{Array.from([1, 2, 3, 4, 5]).map((value, index) => {
								return (
									<div className='teacher-feedback-star' onClick={() => setStarNumber(value)} key={value}>
										<FeedbackStar fill={`${starNumber >= value ? '#FDDF6D' : '#ECEBF8'}`} />
									</div>
								);
							})}
						</div>
						{starNumber > 0 && (
							<button className='teacher-feedback-button' onClick={() => handleFeedbackType()}>
								{t('Share your feedback')}
							</button>
						)}
					</div>
				)}
				{feedbackType === 'text' && (
					<div className='teacher-feedback-body'>
						<StudentFeedbackIcon width={137} height={70} />
						<p className='teacher-feedback-title' style={{ marginTop: 20 }}>
							{t('Leave your feedback')}
						</p>
						<p className='teacher-feedback-subtitle'>{t('Tell us how we can improve Upstrive in the box below')}</p>
						<div className='teacher-feedback-textarea'>
							<textarea
								placeholder={t('Type your feedback here')}
								autoFocus={true}
								rows={5}
								value={answer_text}
								onChange={(e) => setAnswerText(e.target.value)}
							></textarea>
						</div>
						{starNumber > 0 && (
							<button className='teacher-feedback-button' onClick={() => shareFeedback()}>
								{t('Share your feedback')}
							</button>
						)}
					</div>
				)}
			</div>
		</ReactModal>
	);
}

export default TeacherFeedbackModal;
