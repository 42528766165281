export const Teachers = [
	{
		title: 'Dashboard update',
		description: 'An overview of everything in one place, giving you smart access to your favourite features.',
		image: require('../../assets/images/TeacherDesktop2.png'),
		imageHeight: 186.5,
		marginTop: 4,
		stepTitle: "What's New?",
	},
	{
		title: 'Three new emotions',
		description:
			'We’ve added Good, Numb & Worried to the current list of emotions giving you greater choice to express how you’re feeling.',
		image: require('../../assets/images/TeacherDesktop3.png'),
		imageHeight: 183.5,
		marginTop: 8.5,
		stepTitle: 'New Emotions',
	},

	{
		title: 'Deeper reflection',
		description: "We've created an additional layer of self-reflection to help you identify reasons behind your chosen emotion.",
		image: require('../../assets/images/TeacherDesktop4.png'),
		imageHeight: 183.5,
		marginTop: 8.5,
		stepTitle: 'Deeper Reflection',
	},

	{
		title: 'Stronger connections',
		description: 'You now have the option to respond directly to any comments from your students indicating the need for support.',
		image: require('../../assets/images/TeacherDesktop5.png'),
		imageHeight: 183.5,
		marginTop: 8.5,
		stepTitle: 'Stronger Connections',
	},

	{
		title: 'Deeper analytics',
		description: 'A greater range of data with fresh visuals, mean you you get a clearer picture of how your students are feeling.',
		image: require('../../assets/images/TeacherDesktop6.png'),
		imageHeight: 167,
		marginTop: 25,
		stepTitle: 'Deeper Analytics',
	},

	{
		title: 'New Statistics',
		description:
			'Your dashboard immediately gives you a snapshot of the percentages of students logged in, engaging with the app, and responding to questions and polls asked.',
		image: require('../../assets/images/TeacherDesktop7.png'),
		imageHeight: 167,
		marginTop: 25,
		stepTitle: 'New Statistics',
	},

	{
		title: 'Deeper insights',
		description: 'Your Classrooms now gives you even deeper insights into emerging emotional trends across your classes.',
		image: require('../../assets/images/TeacherDesktop8.png'),
		imageHeight: 183.5,
		marginTop: 8.5,
		stepTitle: 'Deeper Insights',
	},

	{
		title: 'Catch-up function',
		description: 'Find any student comments logged with an uncomfortable emotion that may have been missed along the way.',
		stepTitle: 'Catch-up Function',
		image: require('../../assets/images/TeacherDesktop9.png'),
		imageHeight: 183.5,
		marginTop: 8.5,
	},

	{
		title: 'My Analytics',
		description: "Get clear analytics, in different formats, to understand your students' experiences even better.",
		stepTitle: 'My Analytics',
		image: require('../../assets/images/TeacherDesktop10.png'),
		imageHeight: 183.5,
		marginTop: 8.5,
	},
];
