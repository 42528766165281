import React, { useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';

const BigImageModal = ({ modalIsOpen, closeModal, selectedImage }) => {
	useEffect(() => {
		ReactModal.setAppElement('#root');
	}, []);

	useEffect(() => {
		let element = document.getElementById('home');
		if (modalIsOpen) {
			if (element != null) {
				element.style.filter = 'blur(5px)';
			}
		}
		return () => {
			if (element != null) {
				element.style.filter = 'unset';
			}
		};
	}, [modalIsOpen]);

	return (
		<ReactModal
			transparent
			isOpen={modalIsOpen}
			ariaHideApp={false}
			onAfterOpen={() => {}}
			onAfterClose={() => {}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 54,
				},
				content: {
					inset: 0,
					padding: 0,
					opacity: 1,
					margin: 'auto',
					marginTop: 30,
					// width: 900,
					backgroundColor: 'transparent',
					maxHeight: '95%',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
				},
			}}
		>
			<div className='absolute top-0 w-full flex items-center justify-center '>
				<div
					className='w-[60px] h-[60px] bg-deepPurple rounded-full cursor-pointer flex items-center justify-center z-[2] hover:bg-darkenPurple '
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						closeModal();
					}}
				>
					<CloseModalIcon />
				</div>
			</div>
			<div className='mt-[30px] flex justify-center items-center'>
				<img className='max-h-[80vh] min-h-[50vh] object-contain grow max-w-[80vw] min-w-[10px]' src={selectedImage} alt='' />
			</div>
		</ReactModal>
	);
};

export default BigImageModal;
