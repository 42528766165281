import React from 'react';
import { ReactComponent as NoteLockIcon } from '../../../assets/howAreYouSvg/NoteLockIcon.svg';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TargetUndo } from '../../../assets/svg/undoTarget.svg';

const colors = ['#9BE4FA', '#FEDBB9', '#D7DBFF', '#BCC5F8', '#C1FCE7', '#FFC6F9'];

function CompareDetails({
	message,
	setMessage,
	selectedSuggestions,
	setSelectedSuggestions,
	numberOfSuggestions,
	pressSuggestions,
	setNumberOfSuggestions,
	questionCompareContinue,
	note,
	suggestions,
	setModalState,
}) {
	const { t } = useTranslation();
	return (
		<div className='w-[1000px] bg-white rounded-[10px] relative mt-[30px] py-[45px] px-[30px] flex flex-col items-center '>
			<div className='w-full flex items-start justify-between'>
				<div
					className='flex items-center w-[50px] cursor-pointer h-[50px]  border   justify-center rounded-full rounded-back-button'
					onClick={() => setModalState('compare')}
				>
					<TargetUndo />
				</div>
				<p className='how-details-title'>{t('compareToLastTime')} </p>
				<div className='w-[50px]'></div>
			</div>

			<div className='how-details-logo'>
				<NoteLockIcon />
				<p className='how-details-text'>{t('thisIsYourSafePlaceAndNothingWillBeShared')} </p>
			</div>
			<div className='how-details-textarea'>
				<p className='how-details-label'>
					{t('doYouThinkThingsAreForAnyReasonInParticular', {
						result: t(camelCase(note.compare)).toLowerCase(),
					})}
				</p>
				<div className='how-comment-textarea' style={{ backgroundColor: 'white' }}>
					<textarea
						placeholder={t('Write your personal notes here (only you can see this)')}
						autoFocus={true}
						rows={5}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					></textarea>
				</div>
			</div>
			<form className='how-details-textarea'>
				<p className='how-details-label'>{t('whatCouldYouDoToImproveThingsRightNow')} </p>
				{selectedSuggestions.map((suggestion, index) => {
					return (
						<div className='how-details-input' key={index}>
							<p style={{ color: suggestion ? '#5e639b' : '#bebece' }}>0{index + 1}</p>
							<input
								type='text'
								value={suggestion}
								onChange={(e) => {
									e.preventDefault();
									const text = e.target.value;
									const _suggestions = [...selectedSuggestions];
									_suggestions[index] = text;
									setSelectedSuggestions(_suggestions);
								}}
								name={`suggestion-${index}`}
								id={`suggestion-${index}`}
								autoComplete='off'
							/>
						</div>
					);
				})}
			</form>
			<div className='how-compare-suggestions'>
				<p className='how-details-label'>{t('suggestions')}:</p>
				<div className='how-details-suggestions-body'>
					{suggestions.slice(0, numberOfSuggestions).map((suggestion, index) => (
						<div
							className='how-suggestion-item'
							key={index}
							style={{
								backgroundColor: `${
									selectedSuggestions.includes(suggestion)
										? colors[index % colors.length]
										: colors[index % colors.length] + '88'
								}`,
								border: `1px solid ${colors[index % colors.length]}`,
							}}
							onClick={() => {
								pressSuggestions(suggestion);
							}}
						>
							<p>{suggestion}</p>
						</div>
					))}
				</div>
				{suggestions.length > numberOfSuggestions && (
					<div
						className='how-load-more-suggestions'
						onClick={() => {
							setNumberOfSuggestions((e) => e + 4);
						}}
					>
						<p>{t('loadMoreSuggestions')}</p>
					</div>
				)}
			</div>
			<div className='how-compare-submit-container'>
				<div className='how-compare-details-continue' onClick={() => questionCompareContinue()}>
					<p>{t('continue')} </p>
				</div>
			</div>
		</div>
	);
}

export default CompareDetails;
