import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as TargetUndo } from '../../../assets/svg/undoTarget.svg';
import { ReactComponent as AngleUp } from '../../../assets/svg/AngleUp.svg';
import { ReactComponent as Journal } from '../../../assets/svg/Journal.svg';
import { ReactComponent as JournalBookBorder } from '../../../assets/svg/JournalBookBorder.svg';
import { createContent } from '../../../server/server';
import { notify, translateTargetText } from '../../../utils/util';
import PollScheduleModal from '../../modals/PollScheduleModal';
import TargetGroupModal from '../../modals/TargetGroupModal';
import { fileSystemUrl } from '../../../constants/Connections';
import Switcher from '../Switcher';
import { useUser } from '../../../auth';

function SingleJournal({ journal, setScreen }) {
	const { t, locale } = useTranslation();
	const [hoveredButton, setHoveredButton] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [targetError, setTargetError] = useState(false);
	const [returnTarget, setReturnTarget] = useState({
		name: 'Select target',
		target: [],
		selection: [],
		type: 'root',
	});
	const [returnSchedule, setReturnSchedule] = useState({
		enabled: false,
		text: 'Off',
		recurrentText: 'Never',
		repeatEnabled: false,
		schedule_date: 0,
	});

	const setSchedule = (option) => {
		setReturnSchedule({
			enabled: false,
			text: 'Off',
			recurrentText: 'Never',
			repeatEnabled: false,
			schedule_date: 0,
		});
	};

	useEffect(() => {
		document.getElementById('journalHome')?.scrollIntoView({ behavior: 'smooth' });
	}, []);

	const validate = () => {
		let valid = true;
		if (returnTarget?.target?.length === 0) {
			setTargetError(true);
			valid = false;
		}
		setShowErrorMessage(!valid);
		return valid;
	};

	const [openSchedule, setOpenSchedule] = useState(false);
	const closeScheduleModal = () => {
		setOpenSchedule(false);
	};
	let history = useHistory();
	const [showTarget, setShowTarget] = useState(false);
	const closeTarget = () => {
		setShowTarget(false);
	};

	useEffect(() => {
		setTargetError(false);
		setShowErrorMessage(false);
	}, [returnTarget]);

	const handleRun = async () => {
		if (!validate()) return;
		const DEFAULT_DATA = {
			...journal,
			target_count: returnTarget?.target?.length,
			target_text: returnTarget?.name,
			type: 'journal',
			created_date: Date.now(),
			target: !!returnTarget?.target?.length ? returnTarget.target.map((a) => a.id) : [],
			schedule_date: returnSchedule?.schedule_date == 0 ? null : returnSchedule?.schedule_date,
			status: 'start',
			new: true,
			unfilteredTarget: returnTarget?.target,
			schedule: returnSchedule?.enabled,
			schedule_info: {
				enabled: returnSchedule?.enabled,
				recurrentText: returnSchedule?.recurrentText,
				repeatEnabled: returnSchedule?.repeatEnabled,
				text: returnSchedule?.text,
				schedule_date: returnSchedule?.schedule_date == 0 ? null : returnSchedule?.schedule_date,
			},
			status: 'start',
		};
		const resp = await createContent({ data: DEFAULT_DATA });
		if (resp?.status == 200) {
			history.push('/dashboard');
			notify(t('contentIsCreated'));
		} else {
			notify(t('contentIsNotCreated'));
		}
	};

	return (
		<div
			className='px-[50px] pt-[30px]'
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<div id='journalHome'>
				<div className='flex items-center justify-start'>
					<div
						className=' flex pointer mr-5 items-center justify-center bg-[#5E639B] hover:bg-[#413F5E]  min-w-[56px] w-[56px] h-[56px] rounded-full'
						onClick={() => setScreen('home')}
						onMouseEnter={() => setHoveredButton(true)}
						onMouseLeave={() => setHoveredButton(false)}
					>
						<TargetUndo fill={hoveredButton ? '#FDDF6D' : '#FFFFFF'} />
					</div>
					<h1 className='font-bold text-deepPurple text-[20px] leading-[25px]'>{journal?.name}</h1>
				</div>
				<p className='text-darkenPurple font-medium  text-[14px] leading-[20px] mt-2'>{journal?.description}</p>
			</div>
			<div className='mt-7'>
				{journal?.days?.map((day, index) => {
					return <SingleJournalItem day={day} key={index} index={index} />;
				})}
			</div>
			<div className='mt-2'>
				<p className={`text-[#FF747F] font-medium text-[14px] text-center ${showErrorMessage ? '' : 'opacity-0'}`}>
					*{t('youMustFillData')}
				</p>
			</div>
			<div className='flex flex-row items-center justify-between px-14 my-4'>
				<div className='flex flex-row justify-between items-center  h-[40px]'>
					<p className={`text-[#5E639B] font-medium text-lg mr-5  ${targetError ? 'text-[#FF747F]' : ''}`}>
						{t('targetGroup')}
						{targetError ? '*' : ''}
					</p>
					<div
						className='text-lg text-[#5E639B] hover:text-deepPurple font-semibold rounded-full py-1 px-4 bg-containerGray pointer'
						onClick={() => setShowTarget(true)}
					>
						{translateTargetText(returnTarget?.name)}
					</div>
				</div>
				<div className='flex flex-row justify-between items-center my-3 h-[40px]'>
					<p className='text-[#5E639B] font-medium text-lg mr-5'>{t('schedule')}</p>
					<div
						className='text-lg flex flex-row justify-between items-center
                            text-mudPurple rounded-full py-1 px-4 bg-containerGray pointer'
						onClick={() => setOpenSchedule(true)}
					>
						<Switcher setSwitcher={setSchedule} switcher={returnSchedule?.enabled} />
						<span className='ml-2 font-semibold text-lg text-mudPurple'>
							{returnSchedule?.enabled && new Date(returnSchedule?.schedule_date).toISOString().split('T', 2)[0]}
						</span>
					</div>
				</div>
				<div
					className='text-highlightColor hover:text-deepPurple bg-deepPurple hover:bg-highlightColor rounded-full text-lg font-semibold pointer w-[210px] h-[50px] flex items-center justify-center'
					onClick={() => handleRun()}
				>
					{t('runIt')}
				</div>
			</div>
			<PollScheduleModal showSchedule={openSchedule} closeSchedule={closeScheduleModal} setReturnSchedule={setReturnSchedule} />
			<TargetGroupModal showTarget={showTarget} closeTarget={closeTarget} setReturnTarget={setReturnTarget} />
		</div>
	);
}

export default SingleJournal;

const SingleJournalItem = ({ day, index }) => {
	const { t, locale } = useTranslation();
	const { user } = useUser();
	const [showSection, setShowSection] = useState(false);
	const [hasImage, setHasImage] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const [mediaUrl, setMediaUrl] = useState('');
	const [currentDay, setCurrentDay] = useState({});
	const openJournal = () => {
		if (!showSection) {
			console.log('selected day ', day);
			setCurrentDay({ ...day, orderNumber: index });
			setHasImage(!!day?.files);
			setIsVideo(day?.files?.type === 'video');
			setMediaUrl(!!day?.files ? `${isVideo ? '' : fileSystemUrl}/${day?.files.storage_name}?token=${user.token}` : '');
		}
		setShowSection((prev) => !prev);
	};
	return (
		<div className='mb-[10px] bg-lightBG rounded-[10px]'>
			<div
				className='px-[40px] py-[30px] bg-containerGray rounded-[10px] flex flex-row justify-between  items-center '
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				<div>
					<h4 className='font-bold text-deepPurple text-[20px] leading-[25px]'>{day?.title}</h4>
					<p className='text-mudPurple font-medium  text-[14px] leading-[20px]'>{day?.description}</p>
				</div>
				<button
					className={`flex items-center justify-center min-w-[55px] h-[55px] rounded-full ${
						!showSection ? 'bg-white ' : 'bg-mudPurple'
					} ml-[30px] cursor-pointer`}
					onClick={() => {
						openJournal();
					}}
					style={{ transform: showSection ? 'rotate(180deg)' : 'none' }}
				>
					<AngleUp color={!showSection ? '#9392AD' : '#ffffff'} />
				</button>
			</div>
			{showSection && (
				<div className=' p-10 flex flex-row gap-5 rounded-[10px]'>
					{hasImage &&
						(isVideo ? (
							<div className='feed-video'>
								<video controls controlsList='nodownload'>
									<source src={mediaUrl} />
									{t('yourBrowserDoesNotSupportHTMLVideo')}
								</video>
							</div>
						) : (
							<div>
								<img
									src={`${mediaUrl}&width=450`}
									style={{ minWidth: 280, maxHeight: 226, objectFit: 'cover', borderRadius: 10 }}
									alt={currentDay?.title}
									className='skeleton'
								/>
							</div>
						))}

					<div className='flex-1 flex flex-col '>
						<div className='flex flex-row items-center '>
							<Journal />
							<div className='-ml-[12px] -mt-[4px]'>
								{' '}
								<JournalBookBorder />{' '}
							</div>
							<p className='text-darkenPurple font-medium  text-[16px] leading-[20px] ml-[5px]'>{t('question')}</p>
						</div>
						<p className='text-darkenPurple font-bold  text-[16px] leading-[20px] mt-5'>{day.question}</p>
					</div>
				</div>
			)}
		</div>
	);
};
