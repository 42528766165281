import { months_list, days, localeMap, DAYS, getDays } from '../constants/Constants';
import { format, formatDistanceToNowStrict, isToday, isYesterday, isTomorrow } from 'date-fns';
import i18n from '../i18n';
import { ReactComponent as ToastBell } from '../assets/svg/toastBell.svg';

import toast from 'react-hot-toast';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export const truncate = (text, startNum = 0, endNum) => {
	if (text.length > endNum) {
		return {
			text: text.substring(startNum, endNum).concat('...'),
			isTruncated: true,
		};
	} else {
		return { text: text, isTruncated: false };
	}
};
export const capitaliseFirstLetter = (word = '') => {
	return word?.charAt(0).toUpperCase() + word?.slice(1);
};
export const notify = (message, color = '#fddf6d') => {
	// toast.info(message, {
	//     position: toast.POSITION.TOP_CENTER,
	//     style: { backgroundColor: color, color: "#5e639b" },
	//     autoClose: 3000,
	//     hideProgressBar: false,
	//     icon: <></>,
	//     progressStyle: { backgroundColor: "#5e639b" },
	// });

	toast.custom((t) => {
		return (
			<div
				className={`${t.visible ? 'animate-enter' : 'animate-leave'} max-w-md p-4 w-full rounded-xl pointer-events-auto flex`}
				style={{ background: 'linear-gradient(51.22deg, #7B7AC7 6.44%, #8B89D6 83.74%)' }}
			>
				<div className='flex flex-row justify-between items-center'>
					<div className='mr-4 rounded-full w-[57px] min-w-[57px] h-[57px] flex items-center justify-center border border-highlightColor'>
						<ToastBell />
					</div>
					<div className='flex flex-col text-left'>
						<div className='text-highlightColor font-bold text-xl'>{message}</div>
						{/* <div>
							<span className='text-lightBG text-sm'>This popup will be auto closed in </span>
							<span id={`toast-${t.id}`} className='text-base font-bold text-highlightColor'>
								3
							</span>
						</div> */}
					</div>
				</div>
			</div>
		);
	});
};
export function convertDateToFormatddMMyyy(milliseconds) {
	const newDate = new Date(milliseconds);
	const formatedDate = ' ' + newDate.getDate() + '.' + months_list[newDate.getMonth()] + '.' + newDate.getFullYear() + '.';
	return formatedDate;
}

export function convertDateToFormatddMMyyyyhhmm(milliseconds) {
	const newDate = new Date(milliseconds);
	const newHours = newDate.getHours();
	const newConvertDate = newDate.getDate();
	const convertDate = newConvertDate < 10 ? '0' + newConvertDate : newConvertDate;
	const newMonths = newDate.getMonth() + 1;
	const months = newMonths < 10 ? '0' + newMonths : newMonths;
	const hours = newHours < 10 ? '0' + newHours : newHours;
	const newMinutes = newDate.getMinutes();
	const minutes = newMinutes < 10 ? '0' + newMinutes : newMinutes;
	const formatedDate = convertDate + '/' + months + '/' + newDate.getFullYear() + ' ' + hours + ':' + minutes;
	return formatedDate;
}
const date_format = 'dd MMM yyyy';

export const format_day = (date, hours = true, locale = i18n.language) => {
	try {
		const formatted_date = formatDistanceToNowStrict(date, {
			includeSeconds: true,
			// addSuffix: 'ago',
			unit: 'day',
		});
		const duration = formatted_date.split(' ')[0];

		if (isToday(date)) {
			return i18n.t('today') + ` ${hours ? format(date, ' HH:mm') : ''}`;
		} else if (isTomorrow(date)) {
			return i18n.t('tomorrow') + ` ${hours ? format(date, ' HH:mm') : ''}`;
		} else if (isYesterday(date)) {
			return i18n.t('yesterday') + ` ${hours ? format(date, ' HH:mm') : ''}`;
		} else if (duration > 6) {
			return format(date, date_format + (hours ? ' HH:mm' : ''), { locale: localeMap[locale] });
		} else {
			const day = new Date(date).getDay();
			return `${getDays()[day]}${hours ? format(date, ' HH:mm') : ''}`;
		}
	} catch {
		return '';
	}
};

export const checkTextStrength = (text) => !!text.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_]).{8,}/)?.length;

export const generate_id = () => {
	const date = Date.now();
	return `_${date.toString(25)}${Math.random().toString(36).substr(2, 33)}`;
};
export const generateUUID = () => {
	let d = new Date().getTime(),
		d2 = (performance && performance.now && performance.now() * 1000) || 0;
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		let r = Math.random() * 16;
		if (d > 0) {
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
	});
};

export const format_month_year = (date, locale = i18n.language) => {
	return format(date, 'MMMM yyyy', { locale: localeMap[locale] });
};
export const format_day_only = (date) => {
	try {
		const formatted_date = formatDistanceToNowStrict(date, {
			includeSeconds: true,
			// addSuffix: 'ago',
			unit: 'day',
		});
		const duration = formatted_date.split(' ')[0];
		if (isToday(date)) {
			return i18n.t('today');
		} else if (isTomorrow(date)) {
			return i18n.t('tomorrow');
		} else if (isYesterday(date)) {
			return i18n.t('yesterday');
		} else if (duration > 6) {
			return format(date, 'dd MMM. yyyy', { locale: localeMap[i18n.language] });
		} else {
			const day = new Date(date).getDay();
			return i18n.t(`${getDays()[day]}`.toLowerCase());
		}
	} catch (e) {
		return '';
	}
};
export const format_day_numbers_month = (date) => {
	try {
		const formatted_date = formatDistanceToNowStrict(date, {
			includeSeconds: true,
			// addSuffix: 'ago',
			unit: 'day',
		});
		const duration = formatted_date.split(' ')[0];
		if (isToday(date)) {
			return i18n.t('today');
		} else if (isTomorrow(date)) {
			return i18n.t('tomorrow');
		} else if (isYesterday(date)) {
			return i18n.t('yesterday');
		} else if (duration > 6) {
			return format(date, 'MM:dd:yyyy');
		} else {
			const day = new Date(date).getDay();
			return `${getDays()[day]}`;
		}
	} catch (e) {
		return '';
	}
};

export const datesAreOnSameDay = (first, second) => {
	return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
};
export const calcReadingTime = (text) => {
	const wordsPerMinute = 200;
	const noOfWords = text.split(/\s/g).length;
	return Math.ceil(noOfWords / wordsPerMinute);
};

export const milisToMinutesOrSeconds = (millis) => {
	const mins = Math.floor(millis / 60000);
	return mins > 0 ? `${mins} min` : `${Math.floor(millis / 1000)} sec`;
};

export const pluralize = (text, count) => (count > 1 ? `${text}s` : text);

export const milisToMiliseconds = (millis) => {
	if (!millis) return '0:00';

	let minutes = Math.floor(millis / 60000);
	let seconds = Math.floor((millis % 60000) / 1000).toFixed(0);
	return seconds == 60 ? minutes + 1 + ':00' : minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

export const mmSSFormat = (timeInSec) => new Date(timeInSec * 1000).toISOString().substr(14, 5);

export const isJson = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export const tryToJson = (str) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
};

export const makeArray = (size, element) => {
	let arr = [];
	for (let i = 0; i < size; i++) arr.push(element);
	return arr;
};

export const nameCapitalized = (name) => name.charAt(0).toUpperCase() + name.slice(1);

export function timeSince(date) {
	var seconds = Math.floor((new Date() - new Date(date)) / 1000);

	var interval = seconds / 31536000;

	if (interval > 1) {
		return Math.floor(interval) + ' ' + (Math.floor(interval) === 1 ? i18n.t('year') : i18n.t('years'));
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + ' ' + (Math.floor(interval) === 1 ? i18n.t('month') : i18n.t('months'));
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + ' ' + (Math.floor(interval) === 1 ? i18n.t('day') : i18n.t('days'));
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + ' ' + (Math.floor(interval) === 1 ? i18n.t('hour') : i18n.t('hours'));
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + Math.floor(interval) === 1 ? i18n.t('minute') : i18n.t('minutes');
	}
	return Math.floor(seconds) + i18n.t('seconds');
}
export const useReactPath = () => {
	const [path, setPath] = React.useState(window.location.pathname);
	const [query, setQuery] = React.useState(new URLSearchParams(window.location.search));
	const listenToPopstate = () => {
		const winPath = window.location.pathname;
		const query = new URLSearchParams(window.location.search);
		setPath(winPath);
		setQuery(query);
	};
	React.useEffect(() => {
		window.addEventListener('popstate', listenToPopstate);
		return () => {
			window.removeEventListener('popstate', listenToPopstate);
		};
	}, []);
	return { path, query };
};

export const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

export const formatTwoDigitNumber = (number) => (number > 9 ? number : `0${number}`);

export const calculateDaysText = (selectedDays = []) => {
	const selectedDaysValues = selectedDays.map((e) => DAYS[e].name);
	if (
		isArraysEqual(
			selectedDaysValues,
			DAYS.slice(0, 5).map((e) => e.name)
		)
	)
		return 'weekday';
	else if (selectedDaysValues.length === DAYS.length) return 'day';
	else if (isArraysEqual(selectedDaysValues, ['Sat', 'Sun'])) return 'weekend';
	return selectedDaysValues.join(', ');
};

export const isArraysEqual = (a1, a2) => {
	return JSON.stringify(a1) == JSON.stringify(a2);
};

export const formatDateOnly = (date, locale = 'en') => {
	const format_locale = localeMap[locale];
	try {
		return format(date, 'dd/MM/yyyy', { locale: format_locale });
	} catch (e) {
		return '';
	}
};

export let getRoundedDate = (minutes, d = new Date()) => {
	let ms = 1000 * 60 * minutes; // convert minutes to ms
	let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

	return roundedDate;
};

export const analyzeMostReasons = (nums, k = 3) => {
	if (nums.length == 0) return [];
	const total = nums.length;
	// Frequency counter
	let freqs = {};
	for (let num of nums) {
		num = camelize(num);
		if (freqs[num] === undefined) {
			freqs[num] = 1;
		} else {
			freqs[num] = freqs[num] + 1;
		}
	}

	// Convert to array with [frequency, number] elements
	let frequencyArray = [];
	for (let key in freqs) {
		frequencyArray.push([freqs[key], key]);
	}

	// Sort in descending order with frequency as key
	frequencyArray.sort((a, b) => {
		return b[0] - a[0];
	});

	// Get most frequent element out of array
	let mostFreq = [];
	for (let i = 0; i < Math.min(frequencyArray.length, k); i++) {
		const item = {
			name: revertCamelize(frequencyArray[i][1]),
			code: frequencyArray[i][1],
			percentage: ((freqs[frequencyArray[i][1]] * 100) / total).toFixed(0),
		};
		mostFreq.push(item);
	}

	return mostFreq;
};

export function camelize(str) {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+/g, '');
}

export function revertCamelize(text) {
	const result = text.replace(/([A-Z])/g, ' $1');
	const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
	return finalResult;
}

export function isValidURL(str) {
	var pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // fragment locator
	return !!pattern.test(str);
}

export const getPercentage = (a, b) => {
	return Math.round((a / b) * 100);
};

export const translateTargetText = (text) => {
	let mapper = {
		'Multiple classes': i18n.t('Multiple classes'),
		'Multiple years': i18n.t('Multiple years'),
		years: i18n.t('years').toLowerCase(),
		classes: i18n.t('classes').toLowerCase(),
		Classes: i18n.t('classes'),
		Class: i18n.t('class'),
		Years: i18n.t('years'),
		Year: i18n.t('year'),
		year: i18n.t('year'),
		'All students': i18n.t('allStudents'),
		'My students': i18n.t('allStudents'),
		Staff: i18n.t('staff'),
		Custom: i18n.t('custom'),
		'all students': i18n.t('allStudents'),
		all: i18n.t('all'),
		All: i18n.t('all'),
		'Select target': i18n.t('Select target'),
		Filter: i18n.t('Filter'),
	};
	return text?.replace(
		/Multiple classes|Multiple years|years|Years|Year|All students|Staff|classes|Classes|Class|Custom|all|All|Select target|Filter|year|all students/,
		function (matched) {
			return mapper[matched];
		}
	);
};

export const translateReportFilterFields = (text) => {
	let mapper = {
		Gender: i18n.t('Gender'),
		Year: i18n.t('year'),
		'Class name': i18n.t('className'),
		Class: i18n.t('class'),
		Male: i18n.t('male'),
		Female: i18n.t('female'),
		Other: i18n.t('other'),
		'First Name': i18n.t('firstName'),
		'Last Name': i18n.t('firstName'),
		Grade: i18n.t('Grade'),
		"Student's feedback": i18n.t("Student's feedback"),
		"Student's comment": i18n.t("Student's comment"),
		'Feedback date': i18n.t('Feedback date'),
		'Action taken': i18n.t('actionTaken'),
		Student: i18n.t('student'),
		"Teachers' action": i18n.t("Teachers' action"),
		Reasons: i18n.t('Reasons'),
		'Students emotion': i18n.t("Student's emotion"),
	};
	return text?.replace(
		/Student's comment|Students emotion|Student's feedback|Teachers' action|Student|Action taken|Feedback date|Class name|Gender|Year|Class|Male|Female|Other|First Name|Last Name|Grade|Reasons/,
		function (matched) {
			return mapper[matched];
		}
	);
};

export const formatDayCode = (date) =>
	`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

export const sumArray = (arr) => arr?.reduce((partialSum, a) => partialSum + a, 0) ?? 0;

export const formatDate = (date) => `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
