import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

const AppContextProvider = (props) => {
	const [appState, setAppState] = useState({});
	return <AppContext.Provider value={{ appState, setAppState }}>{props.children}</AppContext.Provider>;
};

const useAppContext = () => {
	const context = useContext(AppContext);

	if (context === undefined) {
		throw new Error(`useAppContext must be used within a AppContextProvider`);
	}
	return context;
};

export { AppContextProvider, useAppContext };
