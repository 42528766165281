import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDashboardStatistics, getDashboardStatisticsNew } from '../../../../server/server';
import DonutChartDash from '../../components/DonutChartDash';
import { ReactComponent as InfoIcon } from '../../../../assets/svg/InfoIcon.svg';
import ReactTooltip from 'react-tooltip';
import { useUser } from '../../../../auth';

function Statistics() {
	const history = useHistory();
	const { t, locale } = useTranslation();
	const { user, setUser, isSignedIn } = useUser();
	const [loading, setLoading] = useState(true);
	const [statistics, setStatistics] = useState({
		studentUsage: 0,
		responses: 0,
		teachersLogins: 0,
		studentsLogins: 0,
	});

	useEffect(() => {
		getStatistics();
	}, []);

	const getStatistics = async () => {
		const resp = await getDashboardStatisticsNew();
		if (resp.status == 200) {
			const data = resp.data;
			setStatistics({
				studentsLogins: data.studentsLogin / 100,
				studentUsage: data.studentsUsage / 100,
				responses: data.contentResponse / 100,
				teachersLogins: data.teachersLogin / 100,
			});
			setLoading(false);
		}
	};

	const getColor = (value) => {
		return value > 0.66 ? '#6FCF97' : value > 0.3 ? '#F47D3A' : '#FF747F';
	};

	return (
		<div className='bg-white rounded-[10px] min-h-[170px] p-6'>
			<div className='flex items-center justify-between'>
				<p className='font-bold text-darkenPurple text-[16px] leading-5'>
					{t('Current Weekly Engagement Statistics')}
					<br />
					<span className='text-[#413F5E] text-[14px] font-[500]'>{t('trackEngagementResponsivenessLogins')} </span>
				</p>
				<button
					className='text-[#FDDF6D] pointer rounded-[100px] bg-[#5E639B] h-[50px] px-[15px] font-bold hover:bg-highlightColor hover:text-[#5E639B] duration-[230ms] ease-in-out transition-all'
					onClick={() => history.push('/analytics')}
				>
					{t('analytics')}
				</button>
			</div>
			<div className='grid grid-cols-4 gap-1 mt-[30px]'>
				<div className='flex gap-1 items-center'>
					<div className='cursor-help' data-tip={t(`Percentage of student logins this week`)} data-for={`studentsLogins`}>
						<DonutChartDash
							percentage={statistics.studentsLogins}
							outerRadius={getColor(statistics.studentsLogins)}
							innerRadius={'#5E639B'}
							text={'#FFFFFF'}
							loading={loading}
							loadingColor={'#5E639B'}
							loadingSecondColor={'#ECEBF8'}
							size={56}
							fontSize={14}
						/>
						{!loading && (
							<ReactTooltip
								id={`studentsLogins`}
								arrowColor='#5E639B'
								effect='solid'
								backgroundColor='#5E639B'
								className='wellbeing-tooltip'
								classNameArrow='tooltip-arrow'
							/>
						)}
					</div>
					<p className='text-sm font-semibold text-darkPurple '>{t('Student logins')}</p>
				</div>
				<div className='flex gap-1 items-center'>
					<div
						className='cursor-help'
						data-tip={t(`Percentage of students that submitted an emotion this week`)}
						data-for={`studentUsage`}
					>
						<DonutChartDash
							percentage={statistics.studentUsage}
							outerRadius={getColor(statistics.studentUsage)}
							innerRadius={'#5E639B'}
							text={'#FFFFFF'}
							loading={loading}
							loadingColor={'#5E639B'}
							loadingSecondColor={'#ECEBF8'}
							size={56}
							fontSize={14}
						/>
						{!loading && (
							<ReactTooltip
								id={`studentUsage`}
								arrowColor='#5E639B'
								effect='solid'
								backgroundColor='#5E639B'
								className='wellbeing-tooltip'
							/>
						)}
					</div>
					<p className='text-sm font-semibold text-darkPurple '>{t('Student Engagement on Emotion')}</p>
				</div>
				<div className='flex gap-1 items-center -mr-[10px]'>
					<div
						className='cursor-help'
						data-tip={t(`Percentage of students engaging with content this week`)}
						data-for={`responses`}
					>
						<DonutChartDash
							percentage={statistics.responses}
							outerRadius={getColor(statistics.responses)}
							innerRadius={'#5E639B'}
							text={'#FFFFFF'}
							loading={loading}
							loadingColor={'#5E639B'}
							loadingSecondColor={'#ECEBF8'}
							size={56}
							fontSize={14}
						/>
						{!loading && (
							<ReactTooltip
								id={`responses`}
								arrowColor='#5E639B'
								effect='solid'
								backgroundColor='#5E639B'
								className='wellbeing-tooltip'
							/>
						)}
					</div>
					<p className='text-sm font-semibold text-darkPurple'>{t('Student engagement with content')}</p>
				</div>
				{user?.role_coverage?.target_text == 'All students' && (
					<div className='flex gap-1 items-center'>
						<div className='cursor-help' data-tip={t(`Percentage of teacher logins this week`)} data-for={`teachersLogins`}>
							<DonutChartDash
								percentage={statistics.teachersLogins}
								outerRadius={getColor(statistics.teachersLogins)}
								innerRadius={'#5E639B'}
								text={'#FFFFFF'}
								loading={loading}
								loadingColor={'#5E639B'}
								loadingSecondColor={'#ECEBF8'}
								size={56}
								fontSize={14}
							/>
							{!loading && (
								<ReactTooltip
									id={`teachersLogins`}
									arrowColor='#5E639B'
									effect='solid'
									backgroundColor='#5E639B'
									className='wellbeing-tooltip'
								/>
							)}
						</div>
						<p className='text-sm font-semibold text-darkPurple'>{t('Teacher logins')}</p>
					</div>
				)}
			</div>
		</div>
	);
}

export default Statistics;
