import { format, formatDistanceToNowStrict, isTomorrow, isYesterday, parseISO, isToday } from 'date-fns';
import { getDays, localeMap } from '../constants/Constants';
import i18n from '../i18n';

const date_format = 'dd MMM yyyy';

export const format_day = (date, hours = true, locale = i18n.language) => {
	if (typeof date == 'string') date = parseISO(date);
	try {
		const formatted_date = formatDistanceToNowStrict(date, { includeSeconds: true, addSuffix: 'ago', unit: 'day' });
		const duration = formatted_date.split(' ')[0];
		if (isToday(date)) {
			return ' ' + i18n.t('today') + ` ${hours ? format(date, ' HH:mm') : ''}`;
		} else if (isTomorrow(date)) {
			return ' ' + i18n.t('tomorrow') + ` ${hours ? format(date, ' HH:mm') : ''}`;
		} else if (isYesterday(date)) {
			return ' ' + i18n.t('yesterday') + ` ${hours ? format(date, ' HH:mm') : ''}`;
		} else if (duration > 6) {
			return format(date, date_format + (hours ? ' HH:mm' : ''), { locale: localeMap[locale] });
		} else {
			const day = new Date(date).getDay();
			return `${getDays()[day]}${hours ? format(date, ' HH:mm') : ''}`;
		}
	} catch {
		return '';
	}
};
