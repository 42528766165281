import React from 'react';
import { useTranslation } from 'react-i18next';
function SliderLabelsCompare({ sliderValue }) {
	const { t } = useTranslation();
	return (
		<div className='how-slider-names'>
			<div className='how-slider-name'>
				<div className='flex flex-col items-start '>
					<div className='flex flex-col justify-center items-start gap-1 '>
						<div
							className='how-slider-name-text w-[34px] h-[30px] leading-[15px]'
							style={{ marginLeft: -16, color: sliderValue === 0 ? '#5E639B' : '#bebece' }}
						>
							{t('muchWorse').toLowerCase()}
						</div>
						<div
							className='how-slider-name-vertical-stick w-[3px]'
							style={sliderValue === 0 ? { backgroundColor: '#5E639B' } : {}}
						></div>
					</div>
				</div>
				<div className='how-slider-down'></div>
			</div>
			<div className='how-slider-name'>
				<div className=' flex  flex-col items-start'>
					<div className='flex items-start justify-center flex-col gap-1'>
						<div
							className='how-slider-name-text mt-[15px] leading-[15px]'
							style={{ marginLeft: -16, color: sliderValue === 1 ? '#5E639B' : '#bebece' }}
						>
							{t('worse').toLowerCase()}
						</div>
						<div
							className='how-slider-name-vertical-stick'
							style={sliderValue === 1 ? { backgroundColor: '#5E639B' } : {}}
						></div>
					</div>
				</div>

				<div className='how-slider-down'></div>
			</div>
			<div className='how-slider-name items-start'>
				<div className='flex flex-col items-start '>
					<div className='flex flex-col justify-center items-start gap-1 '>
						<div
							className='how-slider-name-text mt-[15px] leading-[15px] text-center w-full'
							style={{ marginLeft: -15, color: sliderValue === 2 ? '#5E639B' : '#bebece' }}
						>
							{t('same').toLowerCase()}
						</div>
						<div
							className='how-slider-name-vertical-stick -ml-[1px] '
							style={sliderValue === 2 ? { backgroundColor: '#5E639B' } : {}}
						></div>
					</div>
				</div>
				<div className='how-slider-down'>
					<div className='flex flex-col-reverse items-end justify-center'>
						<div
							className='how-slider-name-text w-full'
							style={{ marginRight: -7, color: sliderValue === 3 ? '#5E639B' : '#bebece' }}
						>
							{t('better').toLowerCase()}
						</div>
						<div
							className='how-slider-name-vertical-stick '
							style={sliderValue === 3 ? { backgroundColor: '#5E639B' } : {}}
						></div>
					</div>
				</div>
			</div>
			<div className='how-slider-name'>
				<div className='how-slider-up'></div>
				<div className='how-slider-down justify-end'>
					<div className='flex flex-col-reverse items-end justify-center'>
						<div
							className='how-slider-name-text w-[34px]'
							style={{ marginRight: -16, color: sliderValue === 4 ? '#5E639B' : '#bebece' }}
						>
							{t('muchBetter').toLowerCase()}
						</div>
						<div
							className='how-slider-name-vertical-stick'
							style={sliderValue === 4 ? { backgroundColor: '#5E639B' } : {}}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SliderLabelsCompare;
