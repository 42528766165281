import React, { useRef, useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/SearchIcon.svg';
import { ReactComponent as Import } from '../../assets/svg/Import.svg';
import { ReactComponent as AngleUp } from '../../assets/svg/AngleUp.svg';
import { useTranslation } from 'react-i18next';
import '../../styles/modals/StudentInfoModal.css';
import i18n from 'i18next';
import { baseUrl } from '../../constants/Connections';
import axios from 'axios';
import { getUsersLoginsInfoDetailed } from '../../server/server';

function LoginDataModal({ loginDataOpen, closeLoginData, unsignedStudents, unsignedTeachers }) {
	const { t, locale } = useTranslation();
	const searchRef = useRef();
	const [searchText, setSearchText] = useState('');
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedClass, setSelectedClass] = useState('');
	const [classes, setClasses] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [students, setStudents] = useState([]);
	const [allUsers, setAllUsers] = useState([]);

	const handleChange = (e) => {
		setSearchText(e.target.value);
	};
	React.useEffect(() => {
		if (loginDataOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
			loadUserLoginData();
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [loginDataOpen]);

	const loadUserLoginData = async () => {
		const resp = await getUsersLoginsInfoDetailed();
		if (resp.status === 200) {
			const data = resp?.data?.list ?? [];
			const students = data.filter((e) => e.role === 0);
			const teachers = data.filter((e) => e.role !== 0);
			const users = data;
			const classes = students
				.map((e) => e.classesName)
				.filter((e) => !!e)
				.filter(distinct)
				.sort((a, b) => {
					if (a < b) return -1;
					return 1;
				});
			setClasses([...classes, 'Staff']);
			setTeachers(teachers);
			setStudents(students);
			setAllUsers(users);
		}
	};

	const myFilter = (item) => {
		const text = searchText?.toLowerCase();
		return (
			(item?.first_name.toLowerCase()?.includes(text) ||
				item?.last_name?.toLowerCase()?.includes(text) ||
				item?.email?.toLowerCase()?.includes(text) ||
				item?.classesName?.toLowerCase()?.includes(text)) &&
			(selectedClass ? item?.classesName == selectedClass || (selectedClass == 'Staff' && item.role > 0) : true)
		);
	};
	const distinct = (value, index, self) => {
		return self.indexOf(value) === index;
	};
	const exportData = () => {
		const newStudentsList = allUsers.filter(myFilter);
		var req = {
			method: 'post',
			url: `${baseUrl}/api/exportStudentsLogin`,
			responseType: 'arraybuffer',
			data: newStudentsList,
		};
		var reader = new FileReader();

		reader.onload = function (e) {
			window.open(decodeURIComponent(reader.result), '_self', '', false);
		};

		axios(req).then(
			function (response) {
				var contentDisposition = response.headers['content-disposition'];
				var fileName = "Users' data.xlsx";
				fileName = fileName.replace(/\"/g, '');
				var contentType = response.headers['content-type'];
				var blob = new Blob([response.data], { type: contentType });

				var a = document.createElement('a');
				document.body.appendChild(a);
				a.style = 'display: none';

				var url = window.URL.createObjectURL(blob);
				a.href = url;

				a.download = fileName;
				a.click();
			},
			function (resp) {}
		);
	};

	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={loginDataOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				setSelectedClass('');
				setSearchText('');
			}}
			onRequestClose={closeLoginData}
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 1100,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '97%',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div className='student-info-modal'>
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<div className='close-modal-icon ' onClick={() => closeLoginData()}>
						<CloseModalIcon />
					</div>
				</div>

				<div className='login-data-header'>
					<div>
						<div className='student-info-title'>
							<p>{t('loginData')} </p>
						</div>
						<div className='student-info-subtitle'>
							<p>{t('listOfStudentsWhoHaventLoggedInYet')} </p>
						</div>
					</div>
					<div style={{ flex: 1 }}></div>
					<div
						className='stroke-deselectPurple hover:stroke-deepPurple flex flex-row items-center border-deselectPurple border py-[0.65rem] px-4 w-[18rem] rounded-full login-data-search'
						style={{ cursor: 'pointer', marginRight: 16, height: 56 }}
					>
						<SearchIcon
							className=''
							onClick={() => {
								searchRef.current.focus();
							}}
						/>
						<input
							type='text'
							value={searchText}
							onChange={handleChange}
							// onKeyUp={(e) => {
							// 	if (e.keyCode == 13) searchClass();
							// }}
							placeholder={t('search')}
							style={{ backgroundColor: '#fafaff', outlineStyle: 'none', paddingLeft: 8, color: '#5E639B' }}
							ref={searchRef}
						/>
					</div>
					<div
						className='student-input'
						onMouseLeave={(e) => {
							e.preventDefault();
							setShowDropdown(false);
						}}
						style={{ position: 'relative', width: 150, height: 56, marginRight: 20 }}
					>
						<div
							className='student-info-select-button'
							onClick={(e) => {
								e.preventDefault();
								setShowDropdown(!showDropdown);
							}}
							style={{ backgroundColor: '#F8F8FB', marginTop: 0, position: 'absolute', top: 0, zIndex: 3 }}
						>
							<p style={{ color: '#413f5e' }}>{selectedClass == '' ? t('selectAll') : selectedClass}</p>
							<span style={{ flex: 1 }}></span>

							<div style={{ transform: showDropdown ? 'rotate(180deg)' : 'none', marginLeft: 10 }}>
								<AngleUp style={{ color: '#6B6C7E' }} />
							</div>
						</div>
						{showDropdown && (
							<div className='gender-drop-down' style={{ zIndex: 2, top: 0, padding: 0, paddingRight: 15 }}>
								<p style={{ height: 56 }}></p>
								<div className='light-small-scroll' style={{ width: '100%', overflowY: 'auto', maxHeight: '45vh' }}>
									<div style={{ paddingRight: 5, width: '100%' }}>
										<div
											onClick={(e) => {
												e.preventDefault();
												setSelectedClass('');
											}}
											className='my-[10px]'
										>
											<p style={{ padding: 0, margin: 0 }}>{t('selectAll')} </p>
										</div>

										{classes?.map((item, index) => (
											<div
												onClick={(e) => {
													e.preventDefault();
													setSelectedClass(item);
													setShowDropdown(false);
												}}
												key={index}
												className='my-[10px]'
											>
												<p style={{ padding: 0, margin: 0 }}>{item}</p>
											</div>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
					<div className='transparent-button' style={{ width: 172 }} onClick={() => exportData()}>
						<p style={{ marginRight: 10 }}>{t('exportData')} </p>
						<Import style={{ color: '#5E639B' }} />
					</div>
				</div>
				<div className='login-data-list-container light-small-scroll' style={{ overflowY: 'auto', height: '65vh' }}>
					{teachers.length !== 0 && (
						<>
							<div>
								<p className='login-data-subtitle'>{t('teachers')} </p>
							</div>
							<div style={{ width: '100%', marginBottom: 34 }}>
								<table style={{ width: '100%', borderCollapse: 'collapse' }}>
									<thead>
										<tr className='management-header-row'>
											<th style={{ width: '40%' }}>{t('Full name')}</th>
											<th style={{ width: '50%' }}>{t('email')}</th>
											<th style={{ width: '10%', textAlign: 'right' }}>{t('role')}</th>

											{/* <th className="teacher-text-center">{t("action")}</th> */}
										</tr>
									</thead>
									<tbody>
										{teachers
											?.filter((item) => myFilter(item))
											?.map((item, index) => (
												<tr
													className='management-body-row'
													key={item.id}
													// onClick={(e) => {
													//     e.preventDefault();
													//     openTeacher(item);
													// }}
												>
													<td style={{ width: '40%' }}>
														{item.first_name} {item.last_name}{' '}
													</td>
													<td className='management-email-text' style={{ width: '50%' }}>
														{item.email}{' '}
													</td>
													<td style={{ width: '10%', textAlign: 'right' }}>{item.role_text} </td>
												</tr>
											))}
										{teachers.length === 0 && (
											<tr className='management-body-row'>
												<td colSpan='5' className='management-text-center no-teachers-yet'>
													{t('noUnsignedTeachers')}
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</>
					)}
					<div>
						<p className='login-data-subtitle'>{t('students')} </p>
					</div>
					<div style={{ width: '100%' }}>
						<table style={{ width: '100%', borderCollapse: 'collapse' }}>
							<thead>
								<tr className='management-header-row'>
									<th style={{ width: '40%' }}>{t('name')}</th>
									<th style={{ width: '50%' }}>{t('email')}</th>
									<th style={{ width: '10%', textAlign: 'right' }}>{t('Class')}</th>

									{/* <th className="teacher-text-center">{t("action")}</th> */}
								</tr>
							</thead>
							<tbody>
								{students
									?.filter((item) => myFilter(item))
									?.map((item, index) => (
										<tr
											className='management-body-row'
											key={item.id}
											// onClick={(e) => {
											//     e.preventDefault();
											//     openTeacher(item);
											// }}
										>
											<td style={{ width: '40%' }}>
												{item.first_name} {item.last_name}{' '}
											</td>
											<td className='management-email-text' style={{ width: '50%' }}>
												{item.email}{' '}
											</td>
											<td style={{ width: '10%', textAlign: 'right' }}>{item.classesName}</td>
										</tr>
									))}
								{students.length === 0 && (
									<tr className='management-body-row'>
										<td colSpan='5' className='management-text-center no-teachers-yet'>
											{t('noUnsignedStudents')}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default LoginDataModal;
