import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as ContentPostIcon } from '../../assets/svg/ContentPostIcon.svg';
import { ReactComponent as ContentQuestionIcon } from '../../assets/svg/ContentQuestionIcon.svg';
import { ReactComponent as ContentPollIcon } from '../../assets/svg/ContentPollIcon.svg';
import { ReactComponent as ContentSurveyIcon } from '../../assets/svg/ContentSurveyIcon.svg';
import ContentPost from './ContentPost';
import ContentQuestion from './ContentQuestion';
import ContentPoll from './ContentPoll';
import ContentSurvey from './ContentSurvey';
import { getById } from '../../server/server';
import { capitaliseFirstLetter, useQuery, useReactPath } from '../../utils/util';
import { ContentContext } from './ContentContext';
import CreateOwnContent from './CreateOwnContent';
import { useTranslation } from 'react-i18next';

function Content(props) {
	const query = useQuery();
	const initType = query?.get('type') ?? 'feed';
	const surveyId = query?.get('surveyId');
	const singleProgram = query?.get('singleProgram');
	const contentEdit = query?.get('contentEdit');
	const editId = query?.get('editId');
	const [type, setType] = useState(initType);
	const [editMode, setEditMode] = useState(!!editId);
	const typeList = ['feed', 'question', 'poll', 'survey']; //,
	const [hoveredButton, setHoveredButton] = useState(-1);
	const [refreshContent, setRefreshContent] = useState(Date.now());
	const [contentItem, setContentItem] = useContext(ContentContext);
	const [content, setContent] = useState(singleProgram ? contentItem : {});
	const { t, locale } = useTranslation();

	// const typeMap = {
	// 	feed: <ContentPost content={content} />,
	// 	question: <ContentQuestion content={content} />,
	// 	poll: <ContentPoll content={content} />,
	// 	survey: <ContentSurvey content={content} />,
	// };
	useEffect(() => {
		if (editId) getSingleContent();
		else if (surveyId) getSurvey();
		else if (contentEdit) {
			setContent(contentItem);
			setType(contentEdit);
		}
		// setContentItem({});
	}, []);

	const getSurvey = async () => {
		const resp = await getById({
			collection: 'surveys',
			id: surveyId,
		});
		if (resp.status === 200) {
			setContent(resp?.data);
			setType('survey');
		}
	};
	const getSingleContent = async () => {
		const resp = await getById({
			collection: 'content',
			id: editId,
		});
		if (resp.status === 200) {
			console.log('content to update ', resp.data);
			setContent(resp?.data);
			setType(resp?.data?.type);
		}
	};
	const iconColor = (type, color) => {
		switch (type) {
			case 'feed':
				return <ContentPostIcon fill={color} />;
			case 'question':
				return <ContentQuestionIcon fill={color} />;
			case 'poll':
				return <ContentPollIcon fill={color} />;
			case 'survey':
				return <ContentSurveyIcon fill={color} />;
			default:
				return;
		}
	};
	const handleTypeChange = (type) => {
		setRefreshContent(Date.now());
		setType(type);
		setContent({});
		setContentItem({});
		setEditMode(false);
		window.history.replaceState(null, null, '?type=' + type);
	};

	return (
		<div className='bg-[#ECEBF8] w-full h-full p-12 flex justify-center overflow-y-scroll'>
			<div className='bg-white w-[800px] rounded-xl flex flex-col px-14 h-max'>
				<div className='w-full flex flex-col'>
					<h1 className='font-bold text-darkPurple text-[40px] mt-6'>{t('createYourOwnContent')} </h1>
					<div className='flex flex-row w-full justify-between mt-6'>
						{typeList?.map((typeContent, index) => {
							return (
								<div
									className={`flex relative flex-row items-center justify-center rounded-full w-[160px] py-[5px] pointer ${
										typeContent === type
											? 'bg-deepPurple'
											: 'border border-deselectPurple  bg-whiteContrast hover:border-mudPurple'
									}`}
									key={index}
									onClick={() => handleTypeChange(typeContent)}
									onMouseEnter={() => setHoveredButton(index)}
									onMouseLeave={() => setHoveredButton(-1)}
								>
									{iconColor(
										typeContent,
										typeContent === type ? 'white' : hoveredButton === index ? '#5E639B' : '#9392AD'
									)}
									<span
										className={`text-lg font-semibold ml-2 ${
											typeContent === type
												? 'text-white'
												: hoveredButton === index
												? 'text-deepPurple'
												: 'text-mudPurple'
										}`}
									>
										{capitaliseFirstLetter(t(typeContent))}
									</span>
									{typeContent === type && (
										<div
											className='w-0 h-0 absolute'
											style={{
												borderRight: '10px solid transparent',
												borderLeft: '10px solid transparent',
												borderTop: '10px solid #5E639B',
												top: 40,
											}}
										></div>
									)}
								</div>
							);
						})}
					</div>
				</div>
				<div className='pt-6 pb-14'>
					{type !== 'survey' && (
						<CreateOwnContent type={type} content={content} editMode={!!editMode || !!singleProgram} key={refreshContent} />
					)}
					{type === 'survey' && <ContentSurvey selectedContent={content} />}
				</div>
			</div>
		</div>
	);
}

export default Content;
