import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { create } from '../../server/server';
import { useUser } from '../../auth';
import '../../styles/modals/CreateTargetFavoriteModal.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function CreateTargetFavoriteModal({ showFav, closeFav, checkedResults, type }) {
	useEffect(() => {
		ReactModal.setAppElement('#home');
	}, [showFav]);
	const [name, setName] = useState('');
	const closeModal = () => {
		closeFav();
	};
	const { user, setUser } = useUser();
	const { t, locale } = useTranslation();
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const handleSave = async () => {
		if (name === '') {
			setShowErrorMessage(true);
			return;
		}
		setShowErrorMessage(false);
		const response = await create({
			collection: 'favorite_target',
			data: {
				name: name,
				target: checkedResults,
				type: type,
				user_id: user.id,
			},
		});
		if (response.status) {
			closeModal();
		}
	};

	return (
		<ReactModal
			closeTimeoutMS={600}
			isOpen={showFav}
			onRequestClose={closeModal}
			shouldCloseOnOverlayClick={true}
			style={{
				overlay: {
					zIndex: 10,
					backgroundColor: 'rgba(44,44,44,0.5)',
				},
				content: {
					width: 400,
					padding: 0,
					margin: '0 auto',
					marginTop: 200,
					borderRadius: 16,
					border: 'none',
					backgroundColor: '#D7E2F8',
				},
			}}
		>
			<div className={`save_target-container ${i18n.language == 'ar' ? 'ar-lang' : ''}`}>
				<div className='target-header'>
					<h2>{t('saveYourFavoriteGroup')}</h2>
					<span className='close-modal-button' style={i18n.language == 'ar' ? { left: 15, right: 'auto' } : {}}>
						<span
							className='cancel-poll-btn'
							style={i18n.language == 'ar' ? { paddingLeft: 0, paddingRight: 10.5 } : {}}
							onClick={() => closeFav()}
						>
							<FaTimes />
						</span>
					</span>
				</div>
				<div className='target-body'>
					<div className='target-name-container'>
						<label htmlFor='name' className='target-name-label' style={i18n.language == 'ar' ? { textAlign: 'right' } : {}}>
							{t('name')}
						</label>
						<input
							type='text'
							className='target-name-input'
							placeholder={t('name')}
							value={name}
							onChange={(e) => {
								if (e.target.value !== '') setShowErrorMessage(false);
								setName(e.target.value);
							}}
						/>
					</div>
				</div>
				<div className='mt-1'>
					<p className={`text-[#FF747F] font-medium text-[14px] text-center ${showErrorMessage ? '' : 'opacity-0'}`}>
						{t('fieldIsRequired')}
					</p>
				</div>
				<div className='target-footer'>
					<button className='target-cancel-button' onClick={() => closeFav()}>
						{t('cancel')}
					</button>
					<button className='target-select-button' onClick={() => handleSave()}>
						{t('save')}
					</button>
				</div>
			</div>
		</ReactModal>
	);
}

export default CreateTargetFavoriteModal;
