import { useState } from 'react';
import { CONTENT_TOPICS } from '../../constants/Constants';
import '../../styles/content/readyToGoContent.css';
import { ReactComponent as PolygonLeft } from '../../assets/svg/PoligonLeft.svg';
import { useTranslation } from 'react-i18next';

const ContentTopicButtons = ({ selectedTopic = 'generalWellbeing', selectTopic = () => {} }) => {
	const [endContentIndex, setEndContentIndex] = useState(4);
	const { t, locale } = useTranslation();

	const goNext = () => {
		if (endContentIndex == CONTENT_TOPICS.length) return;
		setEndContentIndex(endContentIndex + 1);
	};
	const goPrevious = () => {
		if (endContentIndex - 4 == 0) return;
		setEndContentIndex(endContentIndex - 1);
	};

	const disableRight = endContentIndex === CONTENT_TOPICS.length;
	const disableLeft = endContentIndex - 4 === 0;

	return (
		<div className='flex flex-row items-center -mx-[50px] mt-[25px] px-[15px]'>
			<button className='left-button' onClick={goPrevious} disabled={disableLeft}>
				<PolygonLeft fill={disableLeft ? 'white' : '#9392AD'} />
			</button>
			<div className='flex flex-row gap-[10px] mx-[10px]'>
				{CONTENT_TOPICS.slice(endContentIndex - 4, endContentIndex).map((contentType) => {
					const isSelected = contentType.code === selectedTopic;
					return (
						<button
							className={`content-topic-button relative ${contentType.cssClass}`}
							style={{
								backgroundColor: isSelected ? contentType.color : 'white',
								borderColor: isSelected ? 'transparent' : contentType.color,
							}}
							onClick={() => {
								selectTopic(contentType.code);
							}}
						>
							<div className='mb-[8px] flex flex-row items-center'>
								{contentType.icon}
								<p className='content-topic-button-title'>{t(contentType?.name)}</p>
							</div>
							<p className='content-topic-button-description'>{t(contentType?.description)}</p>
							{isSelected && (
								<div
									className={`w-0 h-0 absolute selected-topic-arrow `}
									style={{ borderTopColor: contentType.color }}
								></div>
							)}
						</button>
					);
				})}
			</div>
			<button className='left-button rotate-180' onClick={goNext} disabled={disableRight}>
				<PolygonLeft fill={disableRight ? 'white' : '#9392AD'} />
			</button>
		</div>
	);
};

export default ContentTopicButtons;
