import React, { useEffect, useState } from 'react';
import FilterButton from './FilterButton';
import { ReactComponent as CirclePlus } from '../../../assets/svg/CirclePlus.svg';
import DatePicker from './DatePicker';
import '../../../styles/analytics/CreateReport.css';
import ReactTooltip from 'react-tooltip';

import { getFilters } from '../../../server/server';
import { formatDateOnly, formatDayCode } from '../../../utils/util';
import CreateReportModal from '../../modals/CreateReportModal';
import { useTranslation } from 'react-i18next';
function CreateReport({ updateReportList = () => {} }) {
	const { t, locale } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [reportFormat, setReportFormat] = useState('raw');
	const [filters, setFilters] = useState([]);
	const [outputFields, setOutputFields] = useState([]);
	const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 21)));
	const [endDate, setEndDate] = useState(new Date());
	const [customReportData, setCustomReportData] = useState({});
	const [createModalVisible, setCreateModalVisible] = useState(false);

	useEffect(() => {
		loadFilters();
	}, []);

	const loadFilters = async () => {
		const resp = await getFilters();
		if (resp.status === 200) {
			setFilters(resp.data.filters);
			setOutputFields(resp.data.output_fields);
		}
	};

	const parseFilters = () => {
		let parsedFilters = [];
		const newFilters = [...filters];
		for (let filter of newFilters) {
			const list = filter.list;
			let parsedSubFilter = [];
			for (let subFilter of list) {
				if (subFilter.checked) {
					parsedSubFilter.push({ code: subFilter['code'], field: subFilter['field'] });
				} else {
					for (let subSubFilter of subFilter?.list ?? []) {
						if (subSubFilter.checked) {
							parsedSubFilter.push({ code: subSubFilter['code'], field: subSubFilter['field'] });
						}
					}
				}
			}
			if (parsedSubFilter.length > 0) parsedFilters.push(parsedSubFilter);
		}
		if (parsedFilters.length === 0) {
			for (let filter of newFilters) {
				const list = filter.list;
				let parsedSubFilter = [];
				for (let subFilter of list) {
					parsedSubFilter.push({ code: subFilter['code'], field: subFilter['field'] });
				}
				if (parsedSubFilter.length > 0) parsedFilters.push(parsedSubFilter);
				break; // only take only targets...
			}
		}
		const data = {
			filters: parsedFilters,
			date: {
				startDate: formatDayCode(startDate),
				endDate: formatDayCode(endDate),
			},
			outputFields: outputFields.filter((e) => e.checked),
			reportFormat: reportFormat,
		};
		setCustomReportData(data);
		setCreateModalVisible(true);
	};

	const closeModal = () => {
		setCreateModalVisible(false);
		updateReportList();
	};

	const onSuccess = () => {
		loadFilters();
		setCreateModalVisible(false);
		setCustomReportData({});
		updateReportList();
	};

	return (
		<div className='w-[362px] bg-white rounded-[10px] flex flex-col overflow-hidden'>
			<div className='flex-1 px-[20px] pt-[30px] flex flex-col overflow-hidden'>
				<div className='mb-[30px]'>
					<p className='text-[#413F5E] text-[20px] font-bold'>{t('Create Reports')}</p>
				</div>
				<div className='overflow-auto light-small-scroll mb-[8px] -mr-[10px] pr-[10px]'>
					<div className='  flex flex-col gap-[10px]  '>
						<div className={`h-[49px] filter-button  bg-[#F8F8FB]`}>
							<p className='text-[#413F5E] text-[16px] font-semibold'>{t('Report format')}</p>
							<div className='flex flex-row -mr-[10px] gap-[5px] bg-white rounded-full'>
								<button
									className={`report-format-item ${reportFormat == 'raw' ? 'selected' : ''}`}
									onClick={() => setReportFormat('raw')}
								>
									{t('Raw')}
								</button>
								<button
									className={`report-format-item ${reportFormat == 'pdf' ? 'selected' : ''}`}
									onClick={() => setReportFormat('pdf')}
								>
									PDF
								</button>
							</div>
						</div>
						<DatePicker setEndDate={setEndDate} setStartDate={setStartDate} endDate={endDate} startDate={startDate} />
						{filters.map((filter, index) => {
							const updateFilter = (value) => {
								let newList = [...filters];
								newList[index] = { ...filter, list: value };
								setFilters(newList);
							};
							if ((reportFormat == 'pdf' && filter.title == 'Target users') || reportFormat != 'pdf')
								return (
									<FilterButton
										title={t(filter?.title)}
										key={index}
										filterList={filter.list}
										updateFilterList={updateFilter}
										initiallyOpened={false}
									/>
								);
						})}
						{reportFormat == 'raw' && (
							<FilterButton
								title={t('Output fields')}
								filterList={outputFields}
								updateFilterList={setOutputFields}
								initiallyOpened={false}
							/>
						)}
					</div>
				</div>
			</div>
			<button
				className='bg-highlightColor flex flex-row items-center justify-center create-report-button h-[60px] gap-[10px]'
				data-tip={t(`Remember to set up filters to get started!`)}
				data-for={`createReport`}
				onClick={parseFilters}
			>
				<ReactTooltip
					id={`createReport`}
					arrowColor='#5E639B'
					effect='solid'
					backgroundColor='#5E639B'
					className='wellbeing-tooltip text-left'
					classNameArrow='tooltip-arrow'
				/>
				<CirclePlus />
				<p className='text-[16px] font-bold'>{t('Create report')}</p>
			</button>
			<CreateReportModal data={customReportData} modalIsOpen={createModalVisible} closeModal={closeModal} onSuccess={onSuccess} />
		</div>
	);
}

export default CreateReport;
