import React from 'react';

function DonutChartLogin({ percentage = 0 }) {
	return (
		<div style={{ width: 106, height: 106 }}>
			<svg width="106px" height="106px" viewBox="0 0 106 106" className="donut" style={{ transform: 'scaleX(-1)' }}>
				<defs>
					<linearGradient id="gradient-blue">
						<stop offset="10.87%" stopColor="#595776" />
						<stop offset="88.17%" stopColor="#83819F" />
					</linearGradient>
					<linearGradient id="gradient-orange">
						<stop offset="4%" stopColor="#F1A716" />
						<stop offset="91%" stopColor="#FFC369" />
					</linearGradient>
				</defs>

				{/* {oreol && <circle className="donut-hole" cx="53" cy="53" r="73" fill="red"></circle>} */}
				<circle
					transform="rotate(-90,53,53)"
					cx="50%"
					cy="50%"
					r="40"
					fill="transparent"
					stroke="url(#gradient-blue)"
					strokeWidth="26"
					strokeDasharray={40 * 2 * Math.PI * percentage}
					strokeDashoffset="0"
				></circle>
				<circle
					transform="rotate(-90,53,53)"
					cx="50%"
					cy="50%"
					r="40"
					fill="transparent"
					stroke="url(#gradient-orange)"
					strokeWidth="26"
					strokeDasharray={`${40 * 2 * Math.PI * (1 - percentage)} ${40 * 2 * Math.PI * percentage}`}
					strokeDashoffset={-(40 * 2 * Math.PI) * percentage}
				></circle>
			</svg>
		</div>
	);
}

export default DonutChartLogin;
