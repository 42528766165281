import React, { useState, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as DropdownTransparent } from '../../assets/svg/DropdownTransparent.svg';
import { ReactComponent as SearchBlue } from '../../assets/svg/SearchBlue.svg';
import { ReactComponent as Save } from '../../assets/svg/Save.svg';
import { useTranslation } from 'react-i18next';
import '../../styles/modals/StudentInfoModal.css';
import '../../styles/modals/StudentModal.css';
import Loading from '../Loading';
import { getClasses, get_students, moveStudentToAnotherClass } from '../../server/server';
import { notify } from '../../utils/util';
import SuccessStudentTransferModal from './SuccessStudentTransferModal';
import { useUser } from '../../auth';

function TransferToAnotherClassModal({
	transferClassModalOpen,
	closeTransferClassModal,
	closeInfoModal,
	student,
	currentClass,
	updateClassStudents,
}) {
	const { t, locale } = useTranslation();
	const { user, setUser } = useUser();
	const [showDropdown, setShowDropdown] = useState(false);
	const [classes, setClasses] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedClass, setSelectedClass] = useState(null);
	const [processing, setProcessing] = useState(false);
	const [dataForTransfer, setDataForTransfer] = useState({});
	const [successTransferModalOpen, setSuccessTransferModalOpen] = useState(false);

	useEffect(() => {
		if (transferClassModalOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [transferClassModalOpen]);

	useEffect(() => {
		if (transferClassModalOpen) loadClasses();
	}, [transferClassModalOpen]);

	const loadClasses = async () => {
		const resp = await getClasses();
		if (resp.status === 200) {
			console.log('load classes success ', resp.data);
			if (currentClass.primary) {
				let primaryClasses = resp.data.filter((item) => item.primary && currentClass.id !== item.id);
				setClasses(primaryClasses);
			} else {
				let regularClasses = resp.data.filter((item) => !item.primary && currentClass.id !== item.id);
				setClasses(regularClasses);
			}
		}
	};

	const myFilter = (item) => {
		return item?.name?.toLowerCase().includes(searchTerm?.toLowerCase());
	};

	const closeSuccessTransferModal = () => {
		setSuccessTransferModalOpen(false);
		closeInfoModal();
	};

	const save = () => {
		if (selectedClass === null) {
			notify(t('You must select class'));
			return;
		}
		setProcessing(true);
		const data = {
			student: student,
			class: selectedClass,
			oldClass: currentClass,
		};
		moveStudent(data);
	};

	const moveStudent = async (data) => {
		const oldCurrentClass = data.oldClass;
		const resp = await moveStudentToAnotherClass({ student: data.student, class_id: data.class.id, oldClassId: currentClass.id });
		if (resp.status === 200) {
			setProcessing(false);
			updateClassStudents(oldCurrentClass, data.class);
			setDataForTransfer(data);
			setSuccessTransferModalOpen(true);
			setTimeout(() => {
				setSuccessTransferModalOpen(false);
				setDataForTransfer({});
				closeInfoModal();
			}, 3000);
		} else {
			setProcessing(false);
			updateClassStudents(oldCurrentClass, data.class);
			setDataForTransfer(data);
			setSuccessTransferModalOpen(true);
			setTimeout(() => {
				setSuccessTransferModalOpen(false);
				setDataForTransfer({});
				closeInfoModal();
			}, 3000);
		}
	};

	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={transferClassModalOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				setSelectedClass(null);
				setProcessing(false);
			}}
			onRequestClose={closeTransferClassModal}
			className='noScroll'
			style={{
				overlay: {
					zIndex: 1,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 654,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'scroll',
					paddingTop: 35,
					paddingBottom: 80,
				},
			}}
		>
			<div className='w-full p-9 pb-16 bg-whiteContrast relative rounded-[10px] mt-[30px]'>
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<div className='close-modal-icon ' onClick={() => closeTransferClassModal()}>
						<CloseModalIcon />
					</div>
				</div>

				<div className='w-full flex items-center justify-center '>
					<p className='w-[430px] text-center text-deepPurple text-[40px] leading-[48px] font-bold '>
						{t('Transfer to another class')}
					</p>
				</div>

				<div className='w-full p-10 rounded-[10px] bg-white mt-[15px] flex-1'>
					<p className='text-deepPurple text-[18px] leading-[22px] font-semibold '>
						{t('student')}:
						<span className='text-darkenPurple font-bold ml-[10px]'>
							{student?.first_name} {student?.last_name}
						</span>
					</p>
					<p className='text-deepPurple text-[18px] leading-[22px] font-semibold mt-[25px]'>
						{t('Current class')}:<span className='text-darkenPurple font-bold ml-[10px]'>{currentClass?.name}</span>
					</p>
					<div className='mt-[27px] border-b border-deselectPurple w-full'></div>
					<p className='text-deepPurple text-[18px] leading-[22px] font-semibold mt-[25px]'>{t('Select new class')}:</p>
					<div className='w-full h-[50px] relative  mt-5'>
						<div
							className='w-full h-[50px] bg-deepPurple rounded-full flex flex-row items-center justify-center cursor-pointer z-[3] absolute'
							onClick={(e) => {
								e.preventDefault();
								if (searchTerm != '') setSearchTerm('');
								setShowDropdown(!showDropdown);
							}}
							// onMouseLeave={(e) => {
							// 	e.preventDefault();
							// 	setShowDropdown(false);
							// }}
						>
							<p className='text-white text-[18px] leading-[22px] font-semibold'>
								{selectedClass != null ? `${selectedClass.name}` : t('Select class')}
							</p>

							<div style={{ transform: showDropdown ? 'scaleY(-1)' : 'none', marginLeft: 10 }}>
								<DropdownTransparent style={{ color: 'white' }} />
							</div>
						</div>
						{showDropdown && (
							<div
								className='w-full flex flex-col bg-lightBG rounded-[25px] cursor-pointer pl-3 pr-[6px] overflow-y-auto absolute'
								style={{ zIndex: 2, top: 0 }}
							>
								<p style={{ height: 56 }}></p>
								<div className='' style={{ width: '100%' }}>
									<div className='feedback-teachers-list' style={{ width: '100%' }}>
										<div className='w-full px-[14px]'>
											<div className='mt-[10px] flex flex-row justify-center items-center border border-deselectPurple rounded-full h-10 px-3 mb-3 '>
												<input
													type='text'
													className=' w-full border-none bg-lightBG'
													value={searchTerm}
													onChange={(e) => setSearchTerm(e.target.value)}
													autoComplete='off'
													placeholder={t('search')}
												/>
												<SearchBlue />
											</div>
										</div>
										<div className='blue-small-scroll overflow-y-auto max-h-[26vh] pr-[10px]'>
											{classes
												?.sort((a, b) => (a.year > b.year ? 1 : -1))
												.filter((item) => myFilter(item))
												.map((item, index) => {
													const previousClass = index > 0 && classes[index - 1];
													const sameYear = !!previousClass && previousClass.year === item.year;
													return (
														<div key={index}>
															{!sameYear && (
																<p className=' text-deepPurple text-[14px] leading-[20px] font-medium text-center'>
																	{t('year')} {item.year}
																</p>
															)}

															<div
																onClick={(e) => {
																	setSelectedClass(item);
																	setShowDropdown(false);
																	setSearchTerm('');
																}}
																className={`w-full h-[50px] rounded-[5px] flex flex-row items-center mb-2 px-5 bg-white hover:bg-highlightColor ${
																	selectedClass?.name === item.name ? 'bg-highlightColor' : ''
																}`}
															>
																<p className='text-darkenPurple text-[16px] leading-[20px] font-bold '>
																	{item?.name}
																</p>
															</div>
														</div>
													);
												})}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='flex flex-row mt-12 items-center gap-5 justify-between'>
					<button
						className=' flex flex-row items-center justify-center rounded-full cursor-pointer bg-lightBG px-5 h-[65px] border border-mudPurple w-[214px]'
						onClick={() => closeTransferClassModal()}
					>
						<p className='text-deepPurple text-[18px] leading-[22px] font-semibold '>{t('cancel')}</p>
					</button>

					<button
						className=' h-[65px] bg-deepPurple rounded-full px-5 flex flex-row items-center justify-center gap-[10px] cursor-pointer w-[265px] flex-1'
						onClick={() => save()}
						disabled={processing}
					>
						{processing ? (
							<Loading />
						) : (
							<>
								<p className={`text-highlightColor text-[18px] leading-[22px] font-semibold`}>{t('save')}</p>
								<Save color='#FDDF6D' />
							</>
						)}
					</button>
				</div>
			</div>
			<SuccessStudentTransferModal
				modalIsOpen={successTransferModalOpen}
				closeModal={closeSuccessTransferModal}
				confirmation={closeSuccessTransferModal}
				message={t('Success')}
				subMessage={t('Transfer to another class successfully finished')}
				data={dataForTransfer}
			/>
		</ReactModal>
	);
}

export default TransferToAnotherClassModal;
