import React, { useState } from 'react';
import { ReactComponent as AngleUp } from '../assets/svg/AngleUp.svg';
import { useTranslation } from 'react-i18next';
function SelectInput({ value, callbackClick, array = [], name = 'gender', translate = false, register = null }) {
	const { t, locale } = useTranslation();
	const [showDropdown, setShowDropdown] = useState(false);
	return (
		<div className='student-input ' onMouseLeave={() => setShowDropdown(false)} style={{ position: 'relative', height: 86 }}>
			<div
				className='student-info-select-button'
				onClick={() => setShowDropdown(!showDropdown)}
				style={{ position: 'absolute', top: 0, zIndex: 3 }}
			>
				<p>{name}</p>
				<span style={{ flex: 1 }}></span>
				<p style={{ marginRight: 20 }}>{translate ? t(value) : value}</p>
				<div style={{ transform: showDropdown ? 'rotate(180deg)' : 'none' }}>
					<AngleUp style={{ color: '#6B6C7E' }} />
				</div>
			</div>
			{showDropdown && (
				<div className='gender-drop-down' style={{ width: '100%', zIndex: 2 }}>
					<p style={{ height: 56 }}></p>
					{array.map((item, index) => (
						<div
							onClick={(e) => {
								e.preventDefault();
								callbackClick(item);
								setShowDropdown(false);
							}}
							style={{ width: '100%', textTransform: 'capitalize' }}
							key={index}
						>
							{translate ? <p>{t(item.toLowerCase())}</p> : <p>{item}</p>}
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default SelectInput;
