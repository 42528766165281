import React, { useState, useEffect, useRef, useReducer } from 'react';
import Message from './Message';
import { useUser } from '../../auth';
import conversationIcon from '../../assets/svg/ChatIcon.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import send from '../../assets/svg/ChatSend.svg';
import { getMessages, sendMessage, seenMessage } from '../../server/server';
import { ReactComponent as ChatIcon } from '../../assets/svg/ChatIcon.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { format_day, translateTargetText } from '../../utils/util';
function Conversation({ conversation, user }) {
	const msgInput = useRef('');
	const { t, locale } = useTranslation();
	const [dataMsg, setDataMsg] = useState([]);
	const [resultsNum, setResultsNum] = useState(0);
	const [hasMore, setHasMore] = useState(false);
	const [loading, setLoading] = useState(true);
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (msgInput.current.value) {
			const response = await sendMessage({
				conversation_id: conversation.conversation_id,
				created_date: Date.now(),
				message_body: msgInput.current.value,
				seen: [user.id],
				user_id: user.id,
			});
			if (response.status === 200) {
				setDataMsg([response.data, ...dataMsg]);
			}
			msgInput.current.value = '';
		}
	};
	useEffect(() => {
		loadMessages();
		setLoading(true);
	}, [resultsNum]);

	useEffect(() => {
		if (dataMsg.length !== 0) {
			loadMessages();
			setResultsNum(0);
			setDataMsg([]);
		}
	}, [conversation]);

	const loadMessages = async (
		label_for_teacher = conversation?.label_for_teacher,
		conversation_id = conversation?.conversation_id,
		members = conversation?.members,
		additional_info = conversation?.additional_info,
		color = conversation?.color,
		names = conversation?.names
	) => {
		const resp = await getMessages({
			conversation_id: conversation_id,
			loaded_messages: resultsNum,
			members: members,
		});
		const data = resp?.data?.data;
		const size = resp?.data?.size;
		if (resp.status === 200) {
			if (resultsNum === 0) {
				setLoading(true);
				setDataMsg(data);
			} else {
				setDataMsg((prev) => [...prev, ...data]);
			}
			setLoading(false);
			setHasMore(size > dataMsg.length);
		} else {
			setDataMsg([]);
			setResultsNum(0);
		}
	};

	console.log('Conversation: ', conversation);

	return (
		<div className='chat-conversations-container bg-[#ECEBF8]'>
			<div className='chat-conversation-section'>
				<div className='conversation-header px-6 py-3'>
					<div
						className={`chat-icon-circle conversation-header-icon ${i18n.language == 'ar' ? 'ml-[10px]' : ''}`}
						style={{
							backgroundColor:
								conversation.conversation_type === 'counselor'
									? '#F47D3A'
									: conversation.conversation_type === 'sos'
									? '#6693E4'
									: '#FDDF6D',
						}}
					>
						<ChatIcon
							color={
								conversation.conversation_type === 'counselor' || conversation.conversation_type === 'sos'
									? '#ffffff'
									: '#5E639B'
							}
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='flex flex-row justify-between items-center'>
							<div className='conversation-type text-deepPurple'>{conversation.label_for_teacher}</div>
							<div className='conversation-addition-info'>{translateTargetText(conversation.additional_info)}</div>
						</div>
						<div className='flex flex-row justify-between items-center'>
							<p className='text-sm text-mudPurple capitalize'>
								{i18n.language == 'en' ? conversation?.last_message?.status : ''}
							</p>
							<p className='text-sm text-mudPurple'>
								{format_day(conversation?.last_message?.created_date, true, i18n.language)}
							</p>
						</div>
					</div>
				</div>
				<div className='conversation-main px-3'>
					<div
						className='px-3 gray-small-scroll'
						id='scrollableDiv'
						style={{
							overflow: 'auto',
							display: 'flex',
							flexDirection: 'column-reverse',
						}}
					>
						<InfiniteScroll
							dataLength={dataMsg.length}
							next={() => setResultsNum(dataMsg.length)}
							style={{ display: 'flex', flexDirection: 'column-reverse' }}
							inverse={true} //
							hasMore={hasMore}
							scrollableTarget='scrollableDiv'
						>
							<div className='conversation-grow'></div>
							<div className='conversation-messages'>
								{dataMsg.map((msg, index) => {
									return (
										<Message
											key={msg.id}
											message={msg}
											user={user}
											messages={dataMsg}
											names={conversation?.names}
											members={conversation?.members}
											isGroupChat={conversation.members?.length > 2}
											index={index}
										/>
									);
								})}
							</div>
						</InfiniteScroll>
					</div>
				</div>

				<form onSubmit={handleSubmit}>
					<div className='new-message-input-box px-6'>
						<div className='new-message-input'>
							<input
								type='text'
								id='message_input'
								name='message_input'
								style={i18n.language == 'ar' ? { paddingRight: '10px', paddingLeft: '60px' } : { paddingRight: '60px' }}
								placeholder={t('writeYourMessageHere')}
								ref={msgInput}
								autoComplete='off'
							/>
							<button
								className='new-message-input-icon'
								onClick={handleSubmit}
								style={i18n.language == 'ar' ? { left: '6px', right: 'auto' } : { right: '6px' }}
							>
								<img src={send} alt='Send' style={{ transform: i18n.language == 'ar' ? 'scaleX(-1)' : 'none' }} />
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default Conversation;
