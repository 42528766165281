import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PrimaryClassHeaderBackground } from '../../assets/svg/PrimaryClassHeaderBackground.svg';
import primaryMascot from '../../assets/images/PrimaryHeaderMascot.png';
import { format_day_only } from '../../utils/util';
import { ReactComponent as LeftButton } from '../../assets/svg/LeftButton.svg';
import { ReactComponent as Calendar } from '../../assets/svg/Calendar.svg';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { localeMap } from '../../constants/Constants';
import i18n from '../../i18n';

function PrimaryClassesHeader({
	startDate = new Date(),
	showPreviousDay = () => {},
	setIsDatePickerOpen = () => {},
	showNextDay = () => {},
	isNextButtonDisabled = false,
	isDatePickerOpen = false,
	changeDate = () => {},
}) {
	const { t } = useTranslation();
	return (
		<div className='h-[120px] rounded-[20px] bg-[#5E639B] flex flex-row items-center px-[35px] overflow-hidden relative'>
			<PrimaryClassHeaderBackground style={{ position: 'absolute', top: 63 }} />
			<p className='text-[32px] font-bold text-white'>{t('Your Primary Classes')}</p>
			<div className='classroom-datepicker-select ml-[25px] gap-[5px]' style={{ position: 'relative' }}>
				<button className='left-button-picker bg-[#FFFFFF]' onClick={showPreviousDay}>
					<LeftButton />
				</button>
				<div
					className='classroom-datepicker-button not-selectable bg-[#FFFFFF] '
					style={{ backgroundColor: '#ffffff' }}
					onClick={() => setIsDatePickerOpen(true)}
				>
					<Calendar style={{ color: '#413F5E' }} />
					<p className='!text-[#413F5E] capitalize'>{format_day_only(startDate)}</p>
				</div>
				<button className='left-button-picker bg-[#FFFFFF]' onClick={showNextDay} disabled={isNextButtonDisabled}>
					<LeftButton style={{ transform: 'scaleX(-1)' }} />
				</button>
				<div style={{ position: 'absolute', top: 0, left: 0, visibility: 'hidden' }}>
					<LocalizationProvider adapterLocale={localeMap[i18n.language]} dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							maxDate={new Date()}
							open={isDatePickerOpen}
							onClose={() => setIsDatePickerOpen(false)}
							label='Date desktop'
							inputFormat='MM/dd/yyyy'
							value={startDate}
							onChange={changeDate}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
			</div>
			<img src={primaryMascot} alt='Primary mascot' height='120' className='absolute right-[61px] top-[12px]' />
		</div>
	);
}

export default PrimaryClassesHeader;
