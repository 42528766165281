import React, { useState } from 'react';
import { ReactComponent as DropdownTransparent } from '../../../../assets/svg/DropdownTransparent.svg';
// import { t } from 'i18next';
import ReactTooltip from 'react-tooltip';
import { filterPeriods } from '../../../../constants/Constants';
import { useTranslation } from 'react-i18next';
function TimePeriodDropDown({ timePeriod, setTimePeriod }) {
	const { t, locale } = useTranslation();
	const [hoverActive, setHoverActive] = useState(false);

	const selectTimePeriod = (period) => {
		setTimePeriod(period);
		setHoverActive(false);
	};

	return (
		<div
			className={`min-w-[64px] transition-all duration-[230ms] ease-in-out  rounded-[20px] ${
				hoverActive ? 'bg-[#ECEBF8] custom-shadow' : 'bg-[#FFFFFF]'
			}
                flex flex-col absolute top-[25px] right-[25px]
				z-[7]
            `}
			onMouseLeave={() => setHoverActive(false)}
		>
			<button
				className={`transition-all duration-[230ms] ease-in-out h-[40px] rounded-[20px] font-bold flex flex-row items-center justify-center gap-[6px] border ${
					hoverActive ? 'bg-[#413F5E] text-[white] border-[#413F5E]' : 'bg-[#FFFFFF] text-[#413F5E]  border-[#ECEBF8]'
				}`}
				onClick={() => setHoverActive(!hoverActive)}
			>
				{t(timePeriod.shortName)}
				<div style={{ transform: hoverActive ? 'scaleY(-1)' : 'none' }}>
					<DropdownTransparent style={{ color: hoverActive ? 'white' : '#413F5E' }} />
				</div>
			</button>
			{hoverActive && (
				<div className=' flex flex-col items-center gap-[5px] py-[10px]'>
					{filterPeriods.map((period) => (
						<button
							className='w-full text-center text-[16px] font-bold text-[#BEBECE] py-[5px]'
							data-tip={t(period.name)}
							data-for={period.shortName}
							onClick={() => selectTimePeriod(period)}
							key={period.shortName}
						>
							{t(period.shortName)}
							<ReactTooltip
								id={period.shortName}
								arrowColor='#5E639B'
								effect='solid'
								backgroundColor='#5E639B'
								className='wellbeing-tooltip'
								place='right'
							/>
						</button>
					))}
				</div>
			)}
		</div>
	);
}

export default TimePeriodDropDown;
