import React, { useState } from 'react';
import '../../styles/analytics/CatchUpHome.css';
import { ReactComponent as SettingsIcon } from '../../assets/svg/cong.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as DropdownTransparent } from '../../assets/svg/DropdownTransparent.svg';
import { ReactComponent as SeenFeedbackIcon } from '../../assets/svg/SeenFeedbackIcon.svg';
import { ReactComponent as Magnify } from '../../assets/svg/search-magnify-icon.svg';
import { notify, timeSince } from '../../utils/util';
import Loading from '../Loading';
import { ReactComponent as Filter } from '../../assets/svg/filter.svg';
import { emotions_heads } from '../../constants/Constants';
import { getCatchUpsAsync, sendSeenFeedbackComment } from '../../server/server';
import { useEffect } from 'react';
import FeedBackModal from '../modals/FeedBackModal';
import { useUser } from '../../auth';
import { useTranslation } from 'react-i18next';

const CatchUpHome = () => {
	const [showTargetGroup, setShowTargetGroup] = useState(false);
	const [selectedStudent, setSelectedSTudent] = useState({});
	const [selectedDay, setSelectedDay] = useState(new Date().getTime());
	const [openStudentFeedback, setOpenStudentFeedback] = useState(false);
	const [selectedYearFilter, setSelectedYearFilter] = useState('all');
	const [selectedClassFilter, setSelectedClassFilter] = useState('all');
	const [selectedMode, setSelectedMode] = useState('unseen');
	const [filterYears, setFilterYears] = useState([]);
	const [selectedYearList, setSelectedYearList] = useState(null);
	const [classesOfYear, setClassesOfYear] = useState({});
	const [loadingFilter, setLoadingFilter] = useState(false);
	const [loadingStaffFilter, setLoadingStaffFilter] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [catchUps, setCatchUps] = useState([]);
	const [loadingMore, setLoadingMore] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);
	const { t, locale } = useTranslation();

	const { user } = useUser();

	// useEffect(() => {
	// 	getCatchUps(0);
	// }, []);

	const getCatchUps = async (numberOfUsers, query = {}, searchText = '') => {
		const data = {
			number_of_students: numberOfUsers,
			query,
			search_text: searchText,
			mode: selectedMode,
		};
		const resp = await getCatchUpsAsync(data);
		if (resp.status === 200) {
			const data = resp.data;
			const previousList = numberOfUsers === 0 ? [] : catchUps;
			setCatchUps([...previousList, ...data.data]);
			setFilterYears(Object.keys(data['filter']).filter((e) => e !== 'all'));
			setClassesOfYear(data['filter']);
			setSelectedYearList(data['filter'][selectedYearFilter]);
			setInitialLoading(false);
			setTimeout(() => {
				setLoadingMore(false);
			}, 200);
		}
	};

	const selectYearFilter = (year) => {
		setSelectedClassFilter('all');
		setSelectedYearFilter(year);
		setSelectedYearList(classesOfYear[year]);
	};

	const selectAllClasses = () => {
		setSelectedClassFilter('all');
	};
	const applyFilter = async (text = '') => {
		let data = {};
		if (selectedYearFilter != 'all') {
			data['year'] = selectedYearFilter;
		} else {
			delete data['year'];
		}
		if (selectedClassFilter != 'all') {
			data['class'] = selectedClassFilter;
		} else {
			delete data['class'];
		}
		if (data['year'] === '' || data['class'] === '') {
			notify(t('filterIsNotSet'));
			return;
		}
		setLoadingFilter(true);
		await getCatchUps(0, data, text);
		setLoadingFilter(false);
	};

	const loadMore = () => {
		if (!loadingMore) {
			setLoadingMore(true);
			getCatchUps(catchUps.length, {}, searchText);
		}
	};

	const closeStudentFeedback = () => {
		if (selectedMode == 'unseen') setCatchUps(catchUps.filter((e) => e.id != selectedStudent?.feedback?.id));
		setOpenStudentFeedback(false);
	};

	const markAsSeen = async (feedback_id) => {
		const data = {
			user_id: user['id'],
			feedback_id: feedback_id,
		};
		const resp = await sendSeenFeedbackComment(data);
		if (resp.status === 200) {
			const data = resp.data;
			notify(t('commentHasBeenSeen'));
			setCatchUps(catchUps.filter((e) => e.id !== feedback_id));
		} else {
			console.log('Send Seen error: ', resp);
		}
	};

	const openFeedback = (feedback) => {
		setOpenStudentFeedback(true);
		setSelectedSTudent({ feedback: feedback, user: feedback.user });
		setSelectedDay(feedback.created_date);
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			applyFilter(searchText);
		}, 600);
		return () => clearInterval(timeout);
	}, [searchText, selectedMode]);

	const clickUnseen = () => {
		setInitialLoading(true);
		setSelectedMode('unseen');
	};
	const clickArchive = () => {
		setInitialLoading(true);
		setSelectedMode('archive');
	};

	return (
		<div className='catch-up-container bg-[#ECEBF8]' id='scroll-inf-target'>
			<div className='catch-up-inner-container'>
				<div className='catch-up-header-container'>
					<div className='catch-up-titles'>
						<h1 className='catch-up-title'>{t('catchUp')} </h1>
						<h2 className='catch-up-subtitle'>{t('catchUpOnAnyStudentCommentsAttachedTo')}</h2>
					</div>
					{/* <button
						className='settings-button'
						onClick={() => {
							getCatchUps(0);
						}}
					>
						<SettingsIcon />
					</button> */}
				</div>
				<div className='flex flex-row w-full mt-[20px]'>
					<button
						className={`catch-up-mode-button mr-[20px] ${
							selectedMode == 'unseen' ? 'bg-[#5E639B] text-[#FFFFFF]' : 'bg-[#FFFFFF] text-[#9392AD]'
						}`}
						onClick={clickUnseen}
					>
						{t('unseen')}
					</button>
					<button
						className={`catch-up-mode-button ${
							selectedMode == 'archive' ? 'bg-[#5E639B] text-[#FFFFFF]' : 'bg-[#FFFFFF] text-[#9392AD]'
						}`}
						onClick={clickArchive}
					>
						{t('archive')}
					</button>
				</div>
				<div className='flex flex-row items-center my-[20px] w-full'>
					<div className='catch-up-search-container'>
						<input
							type='text'
							className='search flex-1'
							placeholder={t('searchStudents')}
							onChange={(e) => setSearchText(e.target.value)}
						/>
						<Magnify />
					</div>
					<div className='flex flex-col relative noselect' onMouseLeave={() => setShowTargetGroup(false)}>
						<div
							className={`all-target-button catchup-filter-button  z-20 h-[50px] ${
								showTargetGroup ? 'bg-[#5E639B] catchup-filter-button-opened' : 'bg-[#FAFAFF] hover:bg-lightBG'
							}`}
							onClick={(e) => {
								e.preventDefault();
								setShowTargetGroup((prev) => !prev);
							}}
						>
							<Filter />
							<p>{t('filter')} </p>
							<div style={{ transform: showTargetGroup ? 'scaleY(-1)' : 'none' }}>
								<DropdownTransparent />
							</div>
						</div>
						{showTargetGroup && (
							<div className='absolute top-0 right-0 w-[330px] bg-[#FAFAFF] rounded-[29px] z-10 pt-[50px] px-5 pb-5'>
								<p className='text-[16px] leading-5 font-semibold text-deselectPurple  '>{t('years')} </p>
								<div className='overflow-auto flex flex-row gap-[10px] py-[10px] blue-small-scroll-horizontal'>
									<div
										className={`px-3 h-10 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
											selectedYearFilter === 'all' ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
										}`}
										onClick={() => selectYearFilter('all')}
									>
										{t('all')}
									</div>
									{filterYears?.map((year, index) => (
										<div
											key={index}
											className={`min-w-[40px] h-10 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
												selectedYearFilter === year ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
											}`}
											onClick={() => selectYearFilter(year)}
											style={{}}
										>
											<p className='whitespace-nowrap'>{year}</p>
										</div>
									))}
								</div>
								<p className='mt-5 text-[16px] leading-5 font-semibold text-deselectPurple '>{t('Classes')} </p>
								<div className='overflow-auto flex flex-row gap-[10px] py-[10px] blue-small-scroll-horizontal'>
									<div
										className={`px-3 h-10 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
											selectedClassFilter === 'all' ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
										}`}
										onClick={() => selectAllClasses()}
									>
										{t('all')}
									</div>
									{selectedYearList?.map((item, index) => (
										<div
											key={index}
											className={` h-10 px-3 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
												selectedClassFilter === item ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
											}`}
											onClick={() => setSelectedClassFilter(item)}
										>
											<p className='whitespace-nowrap'>{item}</p>
										</div>
									))}
								</div>
								<div className='flex flex-row gap-5 mt-5'>
									<button
										className='flex flex-row items-center justify-center rounded-full cursor-pointer h-10 flex-1 bg-highlightColor'
										onClick={() => applyFilter()}
										disabled={loadingFilter}
									>
										{loadingFilter ? (
											<Loading primaryColor='#9392AD' secondaryColor='#9392AD' size={25} stroke={7} />
										) : (
											<p className='text-darkenPurple text-[16px] leading-5 font-semibold'>{t('apply')}</p>
										)}
									</button>
								</div>
							</div>
						)}
					</div>
				</div>

				{!initialLoading && (
					<div className='catch-up-list'>
						<InfiniteScroll
							dataLength={catchUps?.length}
							next={loadMore}
							hasMore={true}
							scrollableTarget='scroll-inf-target'
							style={{ overflowX: 'hidden', overflowY: 'auto' }}
						>
							{catchUps.map((feedback, i) => {
								const timeMessage =
									selectedMode == 'unseen'
										? t('Left 6 days ago', { time: timeSince(feedback.created_date) })
										: !!feedback?.seenDate
										? t('Seen 6 days ago', { time: timeSince(feedback.seenDate) })
										: '';
								return (
									<button
										className='catch-up-button'
										key={feedback.id}
										onClick={() => {
											openFeedback(feedback);
										}}
									>
										<div className='flex flex-row items-center'>
											<img src={emotions_heads[feedback.emotion]} alt='emotion' width={35} height={35} />
											<p className='catch-up-student-name'>
												{feedback?.user?.first_name} {feedback?.user?.last_name}
											</p>
											<p className='catch-up-student-class'>{feedback?.user?.classesName}</p>
											<span className='flex-1'></span>
											<span className='catch-up-time'>{timeMessage}</span>
										</div>
										<div className='flex flex-row items-end'>
											<p className='catch-up-message'>“{feedback?.message}”</p>
											{selectedMode == 'unseen' ? (
												<button
													className='mark-as-seen-button'
													onClick={(e) => {
														e.stopPropagation();
														markAsSeen(feedback.id);
													}}
												>
													<SeenFeedbackIcon className='seen-feedback-icon' />
													<span className='ml-[7px]'>{t('markAsSeen')} </span>
												</button>
											) : (
												<div className='w-[30px] h-[30px] flex items-center justify-center bg-deepPurple rounded-full'>
													<SeenFeedbackIcon fill='#FDDF6D' />
												</div>
											)}
										</div>
									</button>
								);
							})}
						</InfiniteScroll>
					</div>
				)}
				{initialLoading && (
					<div className='w-full h-full flex items-center justify-center'>
						<Loading size={40} />
					</div>
				)}
			</div>
			<FeedBackModal
				modalIsOpen={openStudentFeedback}
				closeModal={closeStudentFeedback}
				student={selectedStudent}
				day={selectedDay}
			/>
		</div>
	);
};

export default CatchUpHome;
