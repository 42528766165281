import React, { useState, useEffect } from 'react';
import '../../styles/feedsAndDashboard/FeedsAndDashboard.css';
import '../../styles/feedsAndDashboard/MyTask.css';
import QuestionMessageModal from '../modals/QuestionMessageModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fileSystemUrl } from '../../constants/Connections';
import { format_day } from '../../utils/format_day';
import { getSurveys, getSurveysForUser, getTasks } from '../../server/server';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next';
import { datesAreOnSameDay, format_day_only, getPercentage, sumArray } from '../../utils/util';
import '../../styles/feedsAndDashboard/TaskFeed.css';
import { ReactComponent as SurveyIconNew } from '../../assets/svg/SurveyIconNew.svg';
import { ReactComponent as JournalBook } from '../../assets/svg/JournalBook.svg';
import { ReactComponent as NoTasksMascot } from '../../assets/svg/NoTasksMascot.svg';
import { format } from 'date-fns';
import SurveyTask from '../dashboard/SurveyTask';
import JournalTask from '../dashboard/JournalTask';
import Loading from '../Loading';
import QuestionTask from '../dashboard/QuestionTask';
import SingleQuestion from './SingleQuestion';
import SinglePoll from './SinglePoll';
import ColorfulProgressBar from '../ColorfulProgressBar';
import BigImageModal from '../modals/BigImageModal';
import i18n from '../../i18n';

function OpenTask({ user, closeTeacherFeedbackModal, checkUsersFeedbackNeeded }) {
	const { t, locale } = useTranslation();
	const [showQuestionModal, setShowQuestionModal] = useState(false);
	const [openTasks, setOpenTasks] = useState([]);
	const [surveys, setSurveys] = useState([]);
	const [resultsNum, setResultsNum] = useState(0);
	const [hasMore, setHasMore] = useState(false);
	const [currentTask, setCurrentTask] = useState({});
	const [loading, setLoading] = useState(true);

	const [loadingImage, setLoadingImage] = useState(true);
	const [surveyModalOpen, setSurveyModalOpen] = useState(false);
	const [selectedSurvey, setSelectedSurvey] = useState({});
	const [journalTaskModalOpen, setJournalTaskModalOpen] = useState(false);
	const [selectedJournalTask, setSelectedJournalTask] = useState({});
	const [imageModalOpen, setImageModalOpen] = useState(false);
	const [questionText, setQuestionText] = useState('');
	const [requesting, setRequesting] = useState(false);
	const [selectedImage, setSelectedImage] = useState('');

	const handleImageLoading = () => {
		setLoadingImage(false);
	};

	const openBigImage = (url) => {
		setSelectedImage(url);
		setImageModalOpen(true);
	};

	const closeImageModal = () => {
		setImageModalOpen(false);
		setSelectedImage('');
	};

	const closeSurveyModal = () => {
		setSelectedSurvey({});
		setSurveyModalOpen(false);
	};
	const selectSurvey = (item) => {
		setSelectedSurvey(item);
		setSurveyModalOpen(true);
	};

	const loadSurveys = async () => {
		const resp = await getSurveysForUser();
		if (resp.status === 200) {
			const data = resp.data['data'];
			console.log('survey  ', data);

			setSurveys(data);
		}
	};

	const openJournalTaskModal = (journalTask) => {
		setJournalTaskModalOpen(true);
		setSelectedJournalTask(journalTask);
	};
	const closeJournalTaskModal = () => {
		setJournalTaskModalOpen(false);
		setSelectedJournalTask({});
	};

	const loadTasks = async (number = resultsNum) => {
		const response = await getTasks({
			user_id: user?.id,
			number_of_tasks: number,
		});
		if (response.status === 200) {
			const { tasks, size } = response.data;
			console.log('open tasks ', response.data);
			if (resultsNum === 0) {
				setOpenTasks(tasks);
				setLoading(false);
				setHasMore(size > tasks.length);
			} else {
				setOpenTasks((prev) => [...prev, ...tasks]);
				setLoading(false);
				setHasMore(size > openTasks.length + tasks.length);
			}
		} else {
			setOpenTasks([]);
			setResultsNum(0);
		}
	};

	useEffect(() => {
		loadTasks();
	}, [resultsNum]);

	const isEmpty = !loading && openTasks.length === 0;

	return (
		<div
			id='scroll-inf-task'
			className='px-6 mb-5 grey-small-scroll overflow-y-scroll xxs:max-h-[70vh] xl:max-h-[100vh] xl:h-0 xl:flex-auto'
		>
			{loading && (
				<div className='loading-center'>
					<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={75} stroke={4} />
				</div>
			)}
			{isEmpty && (
				<div className='no-tasks-container'>
					<div className=' flex items-center justify-center pt-[60px] -mx-[12px]'>
						<NoTasksMascot />
					</div>
					<div className='flex-1'>
						<p className='no-tasks-title'>{t('congratulations')} !</p>
						<p className='no-tasks-subtitle'>{t('youHaveCompletedAllYourTasks')} </p>
					</div>
				</div>
			)}
			<InfiniteScroll
				dataLength={openTasks.length}
				next={() => {
					setResultsNum(openTasks.length);
				}}
				hasMore={hasMore}
				scrollableTarget='scroll-inf-task'
				inverse={false}
			>
				{openTasks.map((task, index) => {
					const time = format(new Date(task.publicationDate), 'HH:mm');
					const url = `${fileSystemUrl}/${task?.files?.storage_name}?token=${user?.token}`;
					const imgUrl = task.files ? url : false;
					const previousNote = index > 0 && openTasks[index - 1];
					const previousNoteDay = previousNote?.publicationDate;
					const sameDay = !!previousNoteDay && datesAreOnSameDay(new Date(task.publicationDate), new Date(previousNoteDay));
					// const totalSections = task?.sections?.length ?? 0;
					// const doneSections = task?.sections
					// 	?.map((section) => section?.questions?.filter((e) => !!e?.answer).map((e) => true))
					// 	.filter((e) => e.length > 0).length;
					const doneQuestions = sumArray(
						task?.sections?.map((section) => section?.questions?.filter((e) => !!e?.answer)?.length)
					);
					const totalQuestions = sumArray(task?.sections?.map((section) => section?.questions?.length));
					return (
						<div className='flex flex-col items-center ' key={task.id}>
							{!sameDay && (
								<div className='mt-[15px] ' style={{ height: 20 }}>
									<span className='text-[14px] leading-5 font-medium  text-darkPurple opacity-60'>{t('date')}: </span>
									<span className='text-[14px] leading-5 font-medium text-darkPurple'>
										{format_day(task.publicationDate, false, i18n.language)}
									</span>
								</div>
							)}
							{task.type === 'question' && (
								<SingleQuestion
									sameDay={sameDay}
									task={task}
									user={user}
									setResultsNum={setResultsNum}
									resultsNum={resultsNum}
									loadTasks={loadTasks}
									openBigImage={openBigImage}
									checkUsersFeedbackNeeded={checkUsersFeedbackNeeded}
								/>
							)}
							{task.type === 'poll' && (
								<SinglePoll
									sameDay={sameDay}
									task={task}
									user={user}
									setResultsNum={setResultsNum}
									resultsNum={resultsNum}
									loadTasks={loadTasks}
									openBigImage={openBigImage}
									checkUsersFeedbackNeeded={checkUsersFeedbackNeeded}
								/>
							)}
							{task.type === 'journal' && (
								<div
									className='w-full p-5 my-2 bg-[#F8F8FB] rounded-[10px] cursor-pointer hover:bg-[#ECEBF8]'
									key={index}
									onClick={() => openJournalTaskModal(task)}
								>
									<div className='flex flex-row items-center'>
										<JournalBook />
										<p className='ml-[10px] text-[16px] font-[500]  text-[#413F5E]'>{t('open_journal')}</p>
										<div className='flex-1'></div>
										<div className='text-[14px] font-[500] text-[#413F5E] opacity-60 mr-[5px]'>{t('time')}:</div>
										<div className='text-[14px] font-[500] text-[#413F5E]'>{time}</div>
									</div>
									<div className='mt-[15px] text-[18px] font-bold text-[#413F5E]'>{task.title}</div>
								</div>
							)}
							{task.type === 'survey' && (
								<div
									className='w-full p-5 my-2 bg-containerGray rounded-[10px] cursor-pointer hover:bg-[#ECEBF8]'
									key={index}
									onClick={() => selectSurvey(task)}
								>
									<div className='flex flex-row items-center'>
										<SurveyIconNew />
										<p className='ml-[10px] text-[16px] font-[500]  text-[#413F5E]'>
											{task.anonymous ? t('anonymous_survey') : t('open_survey')}
										</p>
										<div className='flex-1'></div>
										<div className='text-[14px] font-[500] text-[#413F5E] opacity-60 mr-[5px]'>{t('time')}:</div>
										<div className='text-[14px] font-[500] text-[#413F5E]'>{time}</div>
									</div>
									<div className='mt-[15px] text-[18px] font-bold text-[#413F5E]'>{task.title}</div>
									{doneQuestions > 0 && (
										<div className='flex flex-row items-center mt-[10px]'>
											<ColorfulProgressBar percentage={getPercentage(doneQuestions, totalQuestions)} />
											<label htmlFor='' className='text-[#FBA75A] font-bold text-[16px] ml-[9px]'>
												{getPercentage(doneQuestions, totalQuestions)}% {t('completed')}
											</label>
										</div>
									)}
								</div>
							)}
						</div>
					);
				})}
			</InfiniteScroll>
			<SurveyTask
				modalIsOpen={surveyModalOpen}
				closeModal={closeSurveyModal}
				item={selectedSurvey}
				completed={loadTasks}
				user={user}
				checkUsersFeedbackNeeded={checkUsersFeedbackNeeded}
			/>
			<JournalTask
				modalIsOpen={journalTaskModalOpen}
				closeModal={closeJournalTaskModal}
				task={selectedJournalTask}
				openImageBig={openBigImage}
				checkUsersFeedbackNeeded={checkUsersFeedbackNeeded}
			/>
			<BigImageModal modalIsOpen={imageModalOpen} closeModal={closeImageModal} selectedImage={selectedImage} />
		</div>
	);
}

export default OpenTask;
