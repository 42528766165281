import React, { useEffect, useState } from 'react';
import '../../styles/content/content.css';
import { useTranslation } from 'react-i18next';
function Switcher({ setSwitcher = () => {}, switcher = false, size = 16, showText = true, disabled = false }) {
	const [counter, setCounter] = useState(0);
	const { t, locale } = useTranslation();

	const onClick = () => {
		setSwitcher(!switcher);
		setCounter(counter + 1);
	};

	return (
		<button
			className={`flex flex-row justify-between items-center  ${!disabled ? 'pointer w-[75px]' : ''}`}
			onClick={onClick}
			disabled={disabled}
		>
			{showText && (
				<div className={`font-semibold text-lg ${switcher ? 'text-[#5E639B]' : 'text-mudPurple'}`}>
					{switcher ? t('on') : t('off')}
				</div>
			)}
			<div
				className={`rounded-full w-[40px] p-1 flex flex-row items-center switcher-animation ${
					switcher ? 'bg-deepPurple' : 'bg-deselectPurple'
				}`}
			>
				<div className={`${switcher ? 'flex-1' : 'flex-0'}`}></div>
				<div className={`min-w-[16px] w-[16px] h-[16px]  rounded-full ${switcher ? 'bg-highlightColor' : 'bg-white'}`}></div>
			</div>
		</button>
	);
}

export default Switcher;
