const TRANSLATION_AR = {
	confirmDeletion: 'تأكيد الحذف',
	deleteTheTemplateFor: 'احذف القالب الخاص بـي',
	cancel: 'إلغاء',
	delete: 'حذف',
	createContent: 'إنشاء المحتوى',
	askAQuestion: 'اسأل سؤال',
	createAPoll: 'إنشاء استطلاع',
	createAPost: 'أنشئ منشورًا',
	orChooseFromTemplatesBelow: 'أو اختر من القوالب أدناه',
	on: 'على',
	off: 'أبداً',
	never: 'أبداً',
	chooseTemplate: 'اختيار القالب',
	thereAreNoTemplatesCreated: ' لا توجد قوالب تم إنشاؤها',
	howAreYouToday: 'كيف حالك اليوم؟',
	selectEmotion: 'اختار العاطفة',
	question: 'سؤال',
	genderSelection: 'اختيار الجنس',
	all: 'الكل',
	male: 'ذكر',
	female: 'أنثى',
	settings: 'إعدادات',
	support: 'دعم',
	privacyPolicy: 'سياسة الخصوصية',
	about: 'عن',
	dayReview: 'مراجعة اليوم',
	dailyReflection: 'تفكير يومي',
	youLiked: 'انت أعجبك',
	like: 'اعجب',
	edit: 'تعديل',
	answered: 'أجاب',
	showLess: 'اظهار أقل',
	showMore: 'أظهر المزيد',
	search: 'بحث',
	targetGroup: 'المجموعة المستهدفة',
	quickAction: 'عمل سريع',
	select: 'اختار ',
	selectByGroup: 'اختر بالمجموعة',
	selectSchoolYearsThatYouLikeToTarget: 'حدد سنوات الدراسة التي ترغب في استهدافها',
	years: 'سنوات',
	year: 'Year',
	selectClassesThatYouLikeToTarget: 'حدد الفئات التي ترغب في استهدافها',
	classes: 'فئات',
	class: 'Class',
	selectACustomListThatYouLikeToTarget: 'حدد قائمة مخصصة ترغب في استهدافها',
	custom: 'مخصصة',
	selectTeachersYouWouldLikeToTarget: 'حدد المعلمين الذين ترغب في استهدافهم',
	staff: 'العاملين',
	selectYears: 'اختار السنوات',
	selectClasses: 'اختار الفئات',
	selectFromMyStudents: 'اختر من طلابي',
	multipleClasses: 'فصول متعددة',
	multipleYears: 'عدة سنوات',
	allStudents: 'كل الطلاب',
	done: 'تم',
	waitForTeachersToFeedYouWithSomeNewInformations: 'انتظر حتى يقوم المعلمون بإخبارك ببعض المعلومات الجديدة',
	feedIsEmpty: 'البث فارغ',
	thereIsNoFeedForThisSearch: 'لا توجد بث لهذا البحث',
	createNewPostsForYourStudentsAndHereIsAShortcutForYou: 'إنشاء مشاركات جديدة لطلابك. هنا اختصار لك.',
	waitForTeacherToFeedYouWithSomeNewInformation: 'انتظر حتى يقوم المعلمون بإخبارك ببعض المعلومات الجديدة',
	createFeedPost: 'انشاء منشورًا',
	targetList: 'قائمة الهدف',
	beAmazing: 'كن رائعا!',
	login: 'تسجيل الدخول',
	getStarted: 'البدء',
	enterYourSchoolEmail: 'أدخل البريد الإلكتروني لمدرستك',
	password: 'كلمة المرور',
	email: 'البريد الإلكتروني',
	requestNewPassword: 'طلب كلمة مرور جديدة',
	newPassword: 'كلمة السر الجديدة',
	enterYourSchoolEmailAndWeWillSendYouALinkToGetBackIntoYourAccount: 'أدخل البريد الإلكتروني لمدرستك وسنرسل لك رابطًا للعودةفي حسابك',
	sendRequest: 'ارسل طلب',
	verifyYourEmail: 'قم بتأكيد بريدك الألكتروني',
	weHaveSentYouALinkToGetBackIntoYourAccountAndCheckYourMailAndCopyYourTokenHere:
		'لقد أرسلنا لك رابطًا للعودة إلى حسابك. تحقق من بريدك وانسخ رمزك هنا.',
	resendARequest: 'إعادة إرسال طلب',
	didntGetAMail: 'ألم تصلك بريدًا؟',
	submit: 'قدم',
	enterYourNewPassword: 'أدخل كلمة المرور الجديدة',
	apply: 'طبق',
	howDoYouFeel: 'ما هو شعورك؟',
	poll: 'استطلاع',
	thereAreNoAnswersOrFeedbacksForThisDay: 'لا توجد إجابات أو ردود فعل لهذا اليوم',
	title: 'عنوان',
	message: 'رسالة',
	requested: 'المطلوب',
	anonymous: 'مجهول',
	url: 'عنوان Url',
	addPhoto: 'إضافة صورة',
	removePhoto: 'إزالة الصورة',
	youAreAllDone: 'لقد انتهيت!',
	congratsEverythingIsDoneForNowWaitForNewTasks: 'تهانينا ، تم إنجاز كل شيء الآن! انتظر المهام الجديدة ...',
	excellentWorkWeAreDelightedToHearThingsAreSoMuchBetter: 'عمل ممتاز! يسعدنا أن نسمع أن الأمور أفضل بكثير.',
	thisIsYourSafePlaceAndNothingWillBeShared: 'هذا هو مكانك الآمن. لن يتم مشاركة أي شيء.',
	enterANameForTodaysNotes: 'أدخل اسمًا لملاحظات اليوم.',
	iDontWantToSaveNotes: 'لا أريد حفظ الملاحظات',
	worse: 'أسوأ',
	sorryToHearThatThingsAreWorseWhichOfTheFollowingWouldHelpRightNow:
		'Sorry to hear that things are worse. Which of the following would help right now?',
	muchWorse: 'Much worse',
	sorryToHearThatThingsAreMuchWorseAndWhichOfTheFollowingWouldHelpRightNow: '"آسف لسماع أن الأمور أسوأ. أي مما يلي قد يساعدك الآن؟" ،',
	same: 'نفس',
	whatAreSomeIdeasToMakeThingsBetter: '"ما هي بعض الأفكار لتحسين الأمور؟" ،',
	better: 'أفضل',
	goodToHearThingsAreBetterAndWhatAreSomeIdeasToTakeItEvenFurther:
		'"من الجيد سماع الأشياء أفضل. ما هي بعض الأفكار التي يجب أن نأخذها إلى أبعد من ذلك ؟ "',
	muchBetter: '"أفضل بكثير"',
	greatToHearThingsAreMuchBetterAndThinkAboutHowToImproveEvenMoreOrKeepItOnAPositiveLevel:
		'"من الرائع سماع الأشياء أفضل بكثير. فكر في كيفية تحسينها أكثر أو الحفاظ عليها في مستوى إيجابي" ،',
	suggestions: 'اقتراحات',
	ImOkWithIt: 'أنا بخير معها',
	IdPreferIfTheyWereBetter: 'كنت أفضل إذا كان أفضل',
	loadMoreSuggestions: 'تحميل المزيد من الاقتراحات',
	continue: 'استمر',
	deleteNote: 'احذف الملاحظة',
	today: 'اليوم',
	comparedToLastTimeTodayIamFeeling: 'بالمقارنة مع آخر مرة ، أشعر اليوم ...',
	howDoYouFeelComparedToLastTimeYouDidThePoll: 'ما هو شعورك مقارنة بآخر مرة أجريت فيها الاستطلاع؟',
	thisInformationWillBeSharedWith: 'سيتم مشاركة هذه المعلومات مع المعلم.',
	feelFreeToShareAnyThoughtsHereWhyYouFeelThisWay: 'لا تتردد في مشاركة أي أفكار هنا عن سبب شعورك بهذه الطريقة',
	feedback: 'استجابة',
	choose: 'اختار',
	newFeeds: 'بث جديد',
	openTasks: 'فتح المهام',
	taskArchive: 'أرشيف المهام',
	congratsAndEverythingIsDoneForNowAndWaitForNewTasksFromYourTeacher: 'تهانينا ، تم إنجاز كل شيء الآن! انتظر المهام الجديدة من معلمك',
	newConversation: 'محادثة جديدة',
	iCareAndIListenChat: 'أنا أهتم / أستمع للمحادثة',
	teacher: 'معلم',
	teachers: 'Teachers',
	counselor: 'مستشار',
	secretKey: 'زر سري',
	advancedSettings: 'إعدادات متقدمة',
	showDailyReflectionScreen: 'إظهار شاشة الراجعة اليومي',
	termsAndConditions: 'الشروط والأحكام',
	appVersion: 'نسخة التطبيق:',
	surname: 'اسم العائلة',
	firstName: 'السم الأول',
	gender: 'جنس',
	other: 'اخر',
	thisIsYourSafePlace: 'هذا هو مكانك الآمن',
	thisIsYourPrivateRoom: 'هذه غرفتك الخاصة.',
	safePlaceWithDailyReflectionNotes: 'مكان آمن مع ملاحظات المراجعة يومية',
	personalInformation: 'معلومات شخصية',
	privacyAndSecurity: 'الخصوصية والأمن',
	dashboard: 'أداة متابعة',
	update: 'تحديث',
	unselect: 'Unselect',
	noResults: 'No results',
	open: 'Open',
	share: 'Share',
	back: 'Back',
	task: 'Task',
	tasks: 'Tasks',
	logout: 'Logout',
	save: 'Save',
	name: 'Name',
	profile: 'Profile',
	classroom: 'Classroom',
	feed: 'Feed',
	live: 'Live',
	archive: 'Archive',
	yesterday: 'Yesterday',
	tomorrow: 'Tomorrow',
	results: 'Results',
	required: 'Required',
	schedule: 'Schedule',
	happy: 'happy',
	ecstatic: 'ecstatic',
	inspired: 'inspired',
	calm: 'calm',
	lethargic: 'lethargic',
	grumpy: 'grumpy',
	sad: 'sad',
	stressed: 'stressed',
	angry: 'angry',
	sunday: 'Sunday',
	monday: 'Monday',
	tuesday: 'Tuesday',
	wednesday: 'Wednesday',
	thursday: 'Thursday',
	friday: 'Friday',
	saturday: 'Saturday',
	scheduled: 'Scheduled',
	delivered: 'Delivered',
	first: 'First',
	second: 'Second',
	third: 'Third',
	seen: 'Seen',
	selectStaff: 'اختار الموظفين',
	forgotPassword: 'Forgot password?',
	repeatPassword: 'اعد كلمة السر',
	retypePassword: 'Retype password',
	changePassword: 'غير كلمة السر',
	hidePassword: 'اخفاء كلمة المرور',
	dailyFeedback: 'ردود الفعل اليومية',
	createNew: 'Create new',
	selectionType: 'Selection type',
	enterSurname: 'أدخل اللقب',
	enterEmail: 'أدخل البريد الإلكتروني',
	newMessage: 'رسالة جديدة',
	myTasks: 'My Tasks',
	addOption: 'إضافة خيار',
	newQuestion: 'New Question',
	editQuestion: 'Edit Question',
	editPost: 'Edit post',
	editPoll: 'Edit poll',
	tomorrowPlans: 'Tomorrow plans',
	confirmStopping: 'Confirm stopping',
	newPoll: 'New poll',
	answer: 'Answer',
	post: 'Post',
	hi: 'Hi',
	stop: 'Stop',
	lastTime: 'Last time',
	sendAnswer: 'Send answer',
	comment: 'Comment',
	passwordMustBeLongerThan8CharactersAndMustContainsUppercaseLowercaseNumberAndSpecialSymbol:
		'يجب أن تكون كلمة المرور أطول من ٨ أحرف ، ويجب أن تحتوي على أحرف كبيرة وصغيرة ورقم ورمز خاص',
	enterYourWorkEmailAndPassword: 'أدخل البريد الإلكتروني الخاص بالعمل وكلمة المرور',
	submitNewPassword: 'إرسال كلمة المرور الجديدة',
	pleaseCheckYourMailToConfirmYourEmailAddress: 'يرجى التحقق من بريدك لتأكيد عنوان بريدك الإلكتروني!',
	howDoYouFeelAboutThingsBeingTheSameToday: 'ما هو شعورك بآن الأشياء هي نفسها اليوم؟',
	pollHasBeenUpdated: 'تم تحديث الاستطلاع',
	typeAPostTitle: 'اكتب عنوان المنشور',
	addAnOptionalMessage: 'أضف رسالة اختيارية',
	writeYourMessageHere: 'اكتب رسالتك هنا',
	enterUrlLink: 'أدخل رابط URL',
	saveAndContinue: 'احفظ وتابع',
	nameOfTodayNote: 'اسم مذكرة اليوم',
	whatAreJust3GoodThingsThatHappenedToday: 'ما هي أشياء ثلاثة جيدة التي حدثت اليوم؟',
	yourDirectManager: 'مديرك المباشر',
	secretKeyHasBeenSaved: 'تم حفظ المفتاح السري',
	enterYourSecretKeyToEncryptLllYourMessagesAndAnswers: 'أدخل مفتاحك السري لتشفير جميع رسائلك وإجاباتك',
	enterSecretKey: 'أدخل مفتاح السر',
	advancedSettingsAreSuccessfullySaved: 'تم حفظ الإعدادات المتقدمة بنجاح!',
	youCanUseTheButtonsAboveToContactYourTeacherOrCounselorForSupportAtAnyPoint:
		'يمكنك استخدام الأزرار أعلاه للاتصال بمعلمك أو مرشدك للحصول على الدعم في أي وقت',
	waitForStudentsToContactYouToHelpThem: 'انتظر حتى يتصل بك الطلاب لمساعدتهم',
	passwordsDoNotMatch: 'كلمة المرور غير مطابقة',
	startNewChat: 'ابدأ محادثة جديدة',
	shareWithTeacher: 'شارك مع المعلم',
	tasksForThisSection: 'Tasks for this section',
	howWasYourDay: 'كيف كان يومك؟',
	thisIsAutoReplyMessage: 'هذه رسالة رد تلقائي',
	thankYouForReachingOutWeWillReplyToYourMessageSoonIfYouOrSomeoneYouKnowIsInImmediateDangerPleaseCallEmergencyServices:
		'شكرا لك على التواصل. سوف نقوم بالرد على رسالتك قريبا. إذا كنت أنت أو أي شخص تعرفه في خطر ، يرجى الاتصال بخدمات الطوارئ.',
	doYouThinkThingsAreForAnyReasonInParticular: 'هل تعتقد أن الأمور ________ لأي سبب محدد؟',
	yourPrivateNotes: 'ملاحظاتك الخاصة',
	createNewPoll: 'إنشاء استطلاع جديد',
	saveAsTemplate: 'حفظ كقالب',
	addPostContentHere: 'أضف محتوى آخر هنا',
	typeAPollQuestion: 'اكتب سؤال استطلاع',
	questionHasBeenUpdated: 'اكتب سؤال استطلاع',
	questionHasBeenPosted: 'تم نشر السؤال',
	iDontWantToThinkAboutThisNow: 'لا أريد أن أفكر في هذا الآن',
	selectLanguage: 'Select language',
	userIsUpdated: 'User is updated',
	imageIsNotUploaded: 'Image is not uploaded',
	feedHasBeenUpdated: 'Feed has been updated',
	permissionIsNotAccepted: 'Permission is not accepted',
	feedHasBeenPosted: 'Feed has been posted',
	pollHasBeenPosted: 'Poll has been posted',
	templateHasBeenSaved: 'Template has been saved',
	privateNoteHasBeenSaved: 'Private note has been saved',
	yourEmotionHasBeenShared: 'Your emotion has been shared',
	writePersonalNotesHere: 'Write your personal notes here (only you can see this)',
	compareToLastTime: 'Compare to last time',
	yourAnswerIsSaved: 'Your answer is saved',
	staySecuredFirst: 'Stay secured first',
	day: 'Day',
	dateOfBirth: 'Day of birth',
	teacherInfo: 'معلومات المعلم',
	studentInfo: 'معلومات الطالب',
	manage: 'أدار',
	openListWithAllClassesFromThisSchool: 'قائمة مفتوحة مع جميع الفصول من هذه المدرسة',
	wizardToCreateNewClassAddStudentsAndHeaTteacher: 'مرشد لإنشاء فصل جديد وإضافة الطلاب ومدير المدرسة',
	openListWithAllTeachersThatGoToThisSchool: 'افتح القائمة مع جميع المعلمين الذين يذهبون إلى هذه المدرسة',
	wizardToCreateNewTeacherAndLinkItToProperClass: 'مرشد لإنشاء مدرس جديد وربطه بالفصل المناسب',
	chooseDataRange: 'اختر نطاق البيانات',
	thisListRepresentsAllContentFromThisTypeYouCanEditOrDeleteTt: 'هذه القائمة تمثل كل المحتوى من هذا النوع. يمكنك تعديله أو حذفه',
	lastCreatedpoll: 'آخر استطلاع تم إنشاؤه',
	lastCreatedfeed: 'آخر استطلاع تم البث',
	lastCreatedquestion: 'آخر استطلاع تم إنشاؤهسؤال',
	listOfDays: 'قائمة الأيام',
	selectThisquestion: 'حدد هذا السؤال',
	selectThispoll: 'حدد هذاالاستطلاع',
	selectThisfeed: 'حدد هذا السؤالالبث',
	createOwn: 'إنشاء الخاصة',
	showAllContent: 'عرض كل المحتوى',
	programDuration: 'مدة البرنامج',
	seeThisProgram: 'شاهد هذا البرنامج',
	programs: 'البرامج',
	hereYouCanCreateAPollToLearnMoreAboutYourStudents: 'هنا يمكنك إنشاء استطلاع لمعرفة المزيد عن طلابك',
	askAnOpenQuestionToGetDetailedFeedback: 'اطرح سؤالاً مفتوحًا للحصول على ملاحظات مفصلة',
	classManagement: 'الإدارة العليا',
	teacherManagement: 'إدارة المعلم',
	analyze: 'حلل',
	instructionVideos: 'فيديوهات تعليمية',
	created: 'Created',
	pickOneOption: 'Pick one option:',
	listOfClasses: 'List of classes',
	newClass: 'New class',
	action: 'Action',
	numberOfStudents: 'Number of students',
	listOfTeachers: 'List of teachers',
	newTeacher: 'New teacher',
	options: 'Options',
	send: 'Send',
	refresh: 'Refresh',
	selectDate: 'Select date',
	time: 'Time',
	date: 'Date',
	feedDescription: 'Here you can add text, pictures, videos or links',
	programsLabel: 'اختر من بين مجموعة البرامج الجاهزة للاستخدام المصممة لدعم طلابك وتوفير الوقت لك الآن',
	listOfStudentsWhoHaventLoggedInYet: 'قائمة الطلاب الذين لم يسجلوا الدخول بعد وهي موجودة',
	studentsLoginData: 'بيانات تسجيل دخول الطلاب',
	loadMoreClasses: 'Load more classes',
	loginInfo: 'معلومات تسجيل الدخول',
	role: 'Role',
	loadMore: 'Load more',
	noTeachersYet: 'No teachers yet',
	classInfo: 'معلومات الفصل',
	enterClassName: 'Enter class name',
	enterSchoolYear: 'Enter school year',
	schoolYear: 'School year',
	className: 'Class name',
	listOfStudents: 'List of students',
	doYouWantToRemoveStudent: 'Do you want to remove the student?',
	doYouWantToDeleteClass: 'Do you want to delete the class?',
	emailIsInvalid: 'Email is invalid.',
	emailIsAvailable: 'Email is available!',
	emailAlreadyExists: 'Email already exists!',
	fieldIsRequired: 'Field is required',
	showPassword: 'Show password',
	enterName: 'Enter name',
	selectRole: 'Select role',
	teachersRoleNotSetYet: 'Teaching role not yet selected',
	create: 'Create',
	doYouWantToDeleteTeacher: 'Do you want to delete the teacher?',
	classIsCreated: 'The class is made!',
	yourCounselingListIsEmpty: 'قائمة الاستشارات الخاصة بك فارغة ، ابدأ بإضافة ملف تعريف استشاري جديد لأحد طلابك',
	reminderForToday: 'تذكير لهذا اليوم',
	closed: 'Closed',
	youDontHaveAnyRemindersForToday: "You don't have any reminders for today",
	firstEnterANote: 'First enter a note',
	noteIsCreated: 'Note is created',
	reminderHasBeenChanged: 'Reminder has been changed!',
	status: 'Status',
	exportReport: 'تقرير التصدير',
	notes: 'Notes',
	editReminder: 'Edit reminder',
	removeReminder: 'Remove reminder',
	enterYourNoteHere: 'أدخل ملاحظتك هنا',
	createANote: 'Create a note',
	addReminder: 'إضافة تذكير',
	teacherLogin: 'Teacher Login',
	enterCredentialsYouReceivedFromSchool: 'Enter credentials you received from school',
	deletePost: 'Delete this post',
	doYouWantToDeletePost: 'Would you like to delete this post?',
	easilyManageYourStudentsAndTeachersWithSeparateFunctions: 'قم بإدارة الطلاب والمعلمين بسهولة باستخدام وظائف منفصلة',
	// user: "User",
	userManagement: 'User management',
	chat: 'Chat',
	trackCounseling: 'مسار الاستشارة',
	and: 'and',
	openLink: 'Open link',
	openListOfUsersWhoHaventLoggedIn: "Open list of users who haven't logged in",
	setDateForReminderOfStudent: "Set a date when you'd like to be reminded about this student",
	createReminder: 'Create reminder',
	writeFullAnswerHere: 'write the full answer here',
	answers: 'Answers',
	actionTaken: 'تم اتخاذ فعل',
	iWillTakeAction: 'I will take action',
	seenBy: 'تمت رؤيته بواسطة',
	thereAreNoAnswersForThatDay: 'There are no answers for that day ...',
	describeHowYouWillDealWithThisStudent: 'Describe how you will deal with this student',
	writeYourThoughtsHere: 'اكتب أفكارك هنا',
	studentData: 'Student data',
	student: 'Student',
	enterStudentUPN: 'Enter Student UPN',
	showAll: 'Show all',
	loginStatistic: 'Login statistics',
	hereYouCanAddTextImagesVideosOrLinks: 'Here you can add text, images, videos or links',
	typeAQuestionTitle: 'Enter a Question title...',
	typeYourHeadlineHere: 'Type your headline here ...',
	optional: ' (optional)',
	invalidLink: 'Invalid link',
	chooseTemplateFor: 'Choose template for ',
	doYouWantToDeleteThis: 'Do you want to delete this?',
	yourDataIsUpdated: 'Your data is updated!',
	yourDataIsNotUpdated: 'Your data is not updated!',
	contentIsCreated: 'Content is created!',
	contentIsNotCreated: 'Content is not created!',
	templateIsCreated: 'Template is created!',
	templateIsNotCreated: 'Template is not created!',
	allCreatedContent: 'All created content',
	runProgram: 'نفذ البرنامج',
	teachersOnly: 'Teachers only',
	programTitle: 'Program title',
	thisToolHelpsYouPlanTheEntireProgramOnce:
		'تساعدك هذه الأداة في جدولة البرنامج بالكامل مرة واحدة حتى لا تحتاج إلى التحقق منه طوال الوقت',
	startDate: 'تاريخ البدء',
	selectDays: 'حدد الأيام',
	pickTime: 'اختر وقت',
	thisProgaramIsAlreadyRunning: 'This program is already running, run again?',
	youAreRunningThisProgramSuccessfully: 'You successfully run this program',
	surveys: 'الدراسات الاستقصائية',
	survey: 'Survey',
	description: 'Description',
	typeASurveyTitle: 'اكتب عنوان الدراسات الاستقصائية',
	addASurveyDescription: 'أضف وصف الدراسات الاستقصائية',
	surveyTitle: 'Survey title',
	section: 'الجزء',
	sections: 'Sections',
	enterSectionTitleHere: 'Enter section title here...',
	enterSectionDescriptionHere: 'أدخل وصف القسم هنا',
	enterQuestionHere: 'أدخل سؤالا هنا',
	openAnswer: 'Open answer',
	addQuestion: 'Add question',
	addSection: 'إضاف جزء',
	deleteSurvey: 'حذف استقصاء',
	doYouWantToRemoveSurvey: 'Do you want to remove survey?',
	yesNo: 'Yes/No',
	confirmation: 'Confirmation',
	chooseFromOurRangeOfReadytogProgramsDesignedTouSpportYourStudents:
		'اختر من بين مجموعة البرامج الجاهزة للاستخدام المصممة لدعم طلابك وتوفير الوقت لك الآن',
	manageRunningPrograms: 'إدارة البرامج قيد التشغيل',
	createSurvey: 'قم بإنشاء دراسات استقصائية',
	numberOfSections: 'Number of sections',
	thisToolHelpsYouToControlAllRunningProgramsAndTrackTheirInfluenceToStudents:
		'تساعدك هذه الأداة في التحكم في جميع البرامج قيد التشغيل وتتبع تأثيرها على الطلاب',
	currentPrograms: 'البرامج الحالية',
	yourRunningProgramListIsEmptyStartByAddingOneFromTheListOfPrograms:
		'قائمة البرامج قيد التشغيل فارغة ، ابدأ بإضافة واحدة من قائمة البرامج',
	deleteProgram: 'Delete program',
	days: 'days',
	surveyIsCreated: 'Survey is created',
	thisToolHelpsYouToRunSurveyForStudents: 'تساعدك هذه الأداة في إجراء استقصاء لطلابك',
	yes: 'Yes',
	no: 'No',
	configureSurvey: 'تكوين الستقصاء',
	editSurvey: ' تعديل استقصاء',
	runSurvey: 'Run survey',
	selectMyStudents: 'Select my students',
	openFavorites: 'Open Favorites',
	thereIsNoDataToShow: 'There is no data to show',
	selectAll: 'Select all',
	deselectAll: 'Deselect all',
	saveAsFavorite: 'Save as favorite',
	doYouWantToDeleteFavorite: 'Do you want to delete Favorite group?',
	saveYourFavoriteGroup: 'Save your favorite group',
	deleteWasSuccessful: 'Delete was successful.',
	listOf: 'List of : ',
	deleteThis: 'Delete this ',
	editThis: 'Edit this ',
	youHaveNoChats: 'You have no chats',
	reminderSetFor: 'Reminder set for ',
	doYouWantToDeleteThisTask: 'Do you want to delete this task?',
	repeat: 'Repeat',
	addToWellbeingTracker: 'Add to Wellbeing tracker',
	addToAnalytics: 'Add to analytics',
	wellbeingTracker: 'Wellbeing tracker',
	assignedActions: 'Assigned actions',
	reminder: 'Reminder',
	youDontHaveAnyAssignedAction: "You don't have any assigned action",
	assignAction: 'Assign action',
	AssignActionToATeacherToCheckOnThisStudent: 'Assign action to a teacher to check on this student',
	assignToTeacher: 'Assign to teacher',
	chooseFromOurRangeOfReadyToGoProgramsDesignedToSupportYourStudentsAndSaveYouTimesNow:
		'Choose from our range of ready-to-go programs designed to support your students and save you times now',
	thisToolHelpsYouYoRunAsurveyForYourStudents: 'This tool helps you to run a survey for your students',
	pickAjournalThatWillKeepYourStudentsEngaged: 'Pick a journal that will keep your students engaged',
	journals: 'Journals',
	assignedTo: 'Assigned to:',
	selectTeacher: 'Select teacher',
	createAQuestion: 'Create a question',
	turnOff: 'Turn off',
	staffLogin: 'Staff login',
	token: 'Token',
	createYourOwn: 'Create your own',
	readyToGo: 'Ready to Go',
	favorites: 'Favorites',
	addToFavorites: 'Add to favorites',
	Attention: 'Attention',
	toEnsureYourPrivacyThisKeyIsNotRecoverableAllYourNotesWillBeLostIfYouForgetThisKey:
		'To ensure your privacy, this key is not recoverable. All your notes will be lost if you forget this key!',
	'Find the right solution': 'Find the right solution',
	changeSecurityKey: 'Change Security Key',
	passwordSetup: 'Password setup',
	'Take it': 'Take it',
	open_question: 'سؤال مفتوح',
	open_poll: 'فتح استطلاع',
};
export default TRANSLATION_AR;
