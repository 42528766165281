import React, { useState, useEffect } from 'react';
import { ReactComponent as PreviousIcon } from '../../assets/svg/PreviousIcon.svg';
import { ReactComponent as SearchBlue } from '../../assets/svg/SearchBlue.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/svg/NotFoundIcon.svg';
import { getAnalyticsBasic, getUsersLoginStats } from '../../server/server';
import DonutChartDash from '../dashboard/components/DonutChartDash';
import Loading from '../Loading';
import { format_day } from '../../utils/format_day';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ReactTooltip from 'react-tooltip';

function BasicStudents() {
	const { t, i18n } = useTranslation();
	const navigate = useHistory();
	const [searchText, setSearchText] = useState('');
	const [studentsList, setStudentsList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingInit, setLoadingInit] = useState(true);
	const [listSize, setListSize] = useState(0);
	const [percentage, setPercentage] = useState(-1);

	const [resultsNum, setResultsNum] = useState(0);
	const [hasMore, setHasMore] = useState(false);

	useEffect(() => {
		if (resultsNum != 0) loadStudents(resultsNum);
	}, [resultsNum]);

	useEffect(() => {
		loadLoginStats();
	}, []);

	const loadLoginStats = async () => {
		const resp = await getUsersLoginStats('student');
		console.log(resp);
		if (resp.status === 200) {
			setPercentage(resp.data);
		}
	};

	const loadStudents = async (number_of_students = 0, searchText = '') => {
		setLoading(true);
		const resp = await getAnalyticsBasic('student', number_of_students, searchText);
		if (resp.status === 200) {
			let data = resp.data;
			const prevArr = number_of_students == 0 ? [] : studentsList;
			setStudentsList([...prevArr, ...data.list]);
			setHasMore(data.size > data.list?.length + studentsList.length);
		} else {
		}
		setLoading(false);
		setLoadingInit(false);
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoadingInit(true);
			loadStudents(0, searchText);
		}, 700);
		return () => {
			clearTimeout(timeout);
		};
	}, [searchText]);

	const neverLoggedIn = studentsList.filter((e) => !e.lastLogin);
	const everLoggedIn = studentsList.filter((e) => !!e.lastLogin);

	const goBack = () => {
		navigate.goBack();
	};

	return (
		<div className='w-full bg-white rounded-[10px] p-[35px] mt-5  flex-1'>
			<div className='flex flex-row items-center justify-between '>
				<div className='flex flex-row items-center gap-[13px]  cursor-pointer ' onClick={goBack}>
					<div className='min-w-[46px] h-[46px] bg-lightBG rounded-full flex items-center justify-center '>
						<PreviousIcon style={{ color: '#413F5E' }} />
					</div>
					<p className='text-[14px] leading-5 text-mudPurple font-medium w-[110px]'>{t('getBackToMainAnalytics')} </p>
				</div>
				<div className='w-[360px] border border-deselectPurple h-[44px] flex flex-row items-center rounded-full px-4'>
					<input
						type='text'
						className='flex-1 text-mudPurple text-[16px] leading-5 '
						placeholder={t('searchStudents')}
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						autoComplete='off'
					/>
					<SearchBlue />
				</div>
				<div className=' flex flex-row items-center gap-[14px] '>
					<div
						className='cursor-help'
						data-tip={t(`Percentage of students who have registered to the app`)}
						data-for={`registeredStudents`}
					>
						<ReactTooltip
							id={`registeredStudents`}
							arrowColor='#5E639B'
							effect='solid'
							backgroundColor='#5E639B'
							className='wellbeing-tooltip'
							classNameArrow='tooltip-arrow'
						/>
						<DonutChartDash
							percentage={percentage / 100}
							size={51}
							outerRadius={'#FDDF6D'}
							innerRadius={'#5E639B'}
							text={'#FDDF6D'}
							showPercentage={false}
							loading={percentage === -1}
							loadingSecondColor={'#FAFAFF'}
							loadingColor={'#9392AD'}
						/>
					</div>
					<p className='text-[16px] leading-5 text-darkenPurple font-bold'>{t('registeredStudents')} </p>
				</div>
			</div>
			<div className='h-[1px] bg-lightBG mt-[21px] '></div>
			{!loadingInit && (
				<table style={{ width: '100%', borderCollapse: 'collapse' }}>
					{studentsList.length !== 0 && (
						<thead>
							<tr className='text-[14px] text-mudPurple leading-5 font-medium text-left break-words'>
								<th className='py-[12px] px-[7px] ' style={{ width: '5%' }}>
									#
								</th>
								<th className='py-[12px] px-[7px] ' style={{ width: '23%' }}>
									{t('name')}
								</th>
								<th className='py-[12px] px-[7px] ' style={{ width: '27%' }}>
									{t('email')}
								</th>
								<th className='py-[12px] px-[7px] ' style={{ width: '17%' }}>
									{t('Class')}
								</th>
								<th className='py-[12px] px-[7px] text-center' style={{ width: '15%' }}>
									{t('lastLogin')}
								</th>
							</tr>
						</thead>
					)}
					<tbody>
						{neverLoggedIn.length > 0 && (
							<tr>
								<td colSpan={5} className='bg-[#FAFAFF] h-[50px] px-[20px] text-[#9392AD] text-[14px] font-bold'>
									{t('neverLoggedIn')}
								</td>
							</tr>
						)}
						{neverLoggedIn?.map((item, index) => (
							<tr
								className={` border-lightBG text-[14px] text-darkenPurple leading-5 font-medium text-left break-words ${
									index != 0 ? 'border-t' : ''
								}`}
								key={index}
								onClick={(e) => {
									e.preventDefault();
								}}
							>
								<td className='py-[12px] px-[7px] ' style={{ width: '5%' }}>
									{index + 1}
								</td>
								<td className='py-[12px] px-[7px] ' style={{ width: '23%' }}>
									{item.first_name} {item.last_name}
								</td>
								<td className='py-[12px] px-[7px] ' style={{ width: '27%' }}>
									{item.email}
								</td>
								<td className='py-[12px] px-[7px] ' style={{ width: '17%' }}>
									{item.classesName}
								</td>
								<td className='py-[12px] px-[7px] text-center' style={{ width: '15%' }}>
									{!!item?.lastLogin ? format_day(item?.lastLogin, true, i18n.language) : '-'}
								</td>
							</tr>
						))}
						{everLoggedIn.length > 0 && (
							<tr>
								<td colSpan={5} className='bg-[#FAFAFF] h-[50px] px-[20px] text-[#9392AD] text-[14px] font-bold'>
									{t('loggedInRecently')}
								</td>
							</tr>
						)}
						{everLoggedIn?.map((item, index) => (
							<tr
								className={` border-lightBG text-[14px] text-darkenPurple leading-5 font-medium text-left break-words ${
									index != 0 ? 'border-t' : ''
								}`}
								key={index}
								onClick={(e) => {
									e.preventDefault();
								}}
							>
								<td className='py-[12px] px-[7px] ' style={{ width: '5%' }}>
									{index + 1}
								</td>
								<td className='py-[12px] px-[7px] ' style={{ width: '23%' }}>
									{item.first_name} {item.last_name}
								</td>
								<td className='py-[12px] px-[7px] ' style={{ width: '27%' }}>
									{item.email}
								</td>
								<td className='py-[12px] px-[7px] ' style={{ width: '17%' }}>
									{item.classesName}
								</td>
								<td className='py-[12px] px-[7px] text-center' style={{ width: '15%' }}>
									{!!item?.lastLogin ? format_day(item?.lastLogin, true, i18n.language) : '-'}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
			{!loading && studentsList.length === 0 && (
				<div className='flex w-full h-full flex-col items-center justify-center text-deepPurple text-sm'>
					<NotFoundIcon />
					<div className='mt-3'>{t('studentsNotFound')} </div>
				</div>
			)}
			{loading && !loadingInit && (
				<div className='w-full mt-4 flex flex-row items-center justify-center'>
					<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={40} stroke={4} />
				</div>
			)}
			{loadingInit && (
				<div className='w-full flex flex-row items-center justify-center h-full flex-1'>
					<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={75} stroke={4} />
				</div>
			)}
			{!loading && hasMore && (
				<div className='w-full mt-4 flex flex-row items-center justify-center'>
					<div
						onClick={() => {
							loadStudents(studentsList.length);
						}}
						className='px-4 w-[200px] h-[44px] flex flex-row items-center justify-center rounded-full bg-lightBG border border-lightPurpleGray hover:border-darkenPurple cursor-pointer'
					>
						<p className='text-mudPurple text-base '>{t('showMoreStudents')} </p>
					</div>
				</div>
			)}
		</div>
	);
}

export default BasicStudents;
