import React, { useState } from 'react';
import { ReactComponent as Calendar } from '../../../assets/svg/Calendar.svg';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { localeMap } from '../../../constants/Constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { formatDate } from '../../../utils/util';
// import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function DatePicker({ startDate = new Date(), endDate = new Date(), setStartDate, setEndDate }) {
	const { t, locale, i18n } = useTranslation();
	const [startDateIsOpen, setStartDateIsOpen] = useState(false);
	const [endDateIsOpen, setEndDateIsOpen] = useState(false);

	const selectEndDate = (date) => {
		setEndDate(date);
		setEndDateIsOpen(false);
	};
	const selectStartDate = (date) => {
		setStartDate(date);
		setStartDateIsOpen(false);
	};

	return (
		<div className='bg-[#F8F8FB] p-[15px] rounded-[5px] flex flex-col gap-[10px]'>
			<p className='text-[#18181A] font-semibold text-[14px]'>{t('Select report dates')}:</p>
			<div className='relative w-full'>
				<button
					className='bg-[white] rounded-full f p-[10px] flex flex-row w-full items-center gap-[10px] relative'
					onClick={() => {
						setStartDateIsOpen(true);
					}}
				>
					<Calendar />
					<p className='flex-1 text-left text-[#9392AD] text-[14px] font-normal'>{t('Start date:')}</p>
					<p className='text-[#5E639B] font-medium text-[14px]'>{formatDate(startDate)}</p>
				</button>
				<div style={{ position: 'absolute', top: 0, left: 90, right: 0, alignSelf: 'center', visibility: 'hidden' }}>
					<LocalizationProvider adapterLocale={localeMap[i18n.language]} dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							open={startDateIsOpen}
							onClose={() => setStartDateIsOpen(false)}
							label='Date desktop'
							inputFormat='MM/dd/yyyy'
							value={startDate}
							onChange={selectStartDate}
							maxDate={new Date()}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
			</div>
			<div className='relative w-full'>
				<button
					className='bg-[white] w-full rounded-full p-[10px] flex flex-row items-center gap-[10px] relative'
					onClick={() => setEndDateIsOpen(true)}
				>
					<Calendar />
					<p className='flex-1 text-left text-[#9392AD] text-[14px] font-normal'>{t('End date:')}</p>
					<p className='text-[#5E639B] font-medium text-[14px]'>{formatDate(endDate)}</p>
				</button>
				<div style={{ position: 'absolute', top: 0, left: 90, right: 0, alignSelf: 'center', visibility: 'hidden' }}>
					<LocalizationProvider adapterLocale={localeMap[i18n.language]} dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							open={endDateIsOpen}
							onClose={() => setEndDateIsOpen(false)}
							label='Date desktop'
							inputFormat='MM/dd/yyyy'
							value={endDate}
							onChange={selectEndDate}
							minDate={startDate}
							maxDate={new Date()}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
			</div>
		</div>
	);
}

export default DatePicker;
