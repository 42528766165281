import React, { useEffect, useState } from 'react';
import CustomReportsHeader from './CustomReportsHeader';
import CreateReport from './CreateReport';
import ListOfReports from './ListOfReports';

function ManageCustomReports() {
	const [updateList, setUpdateList] = useState(Date.now());

	useEffect(() => {
		console.log('Lastavica');
	}, [updateList]);
	return (
		<div id='basic-student' className='analytics-container  light-small-scroll bg-[#ECEBF8] max-h-[100vh] overflow-hidden'>
			<div className='analytics-inner-container mb-[30px]'>
				<CustomReportsHeader />
				<div className='flex flex-row gap-[20px] mt-[30px] flex-1 w-full' style={{ maxHeight: 'calc(100vh - 230px)' }}>
					<CreateReport
						updateReportList={() => {
							setUpdateList(Date.now());
						}}
					/>
					<ListOfReports refreshList={updateList} />
				</div>
			</div>
		</div>
	);
}

export default ManageCustomReports;
