import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import '../../../styles/modals/RunSurveyModal.css';
import { FaTimes } from 'react-icons/fa';
import TargetGroupModal from '../../modals/TargetGroupModal';
import { getById, create, sendNotification, createContent } from '../../../server/server';
import { ReactComponent as AngleUp } from '../../../assets/svg/AngleUp.svg';
import { useUser } from '../../../auth';
import { useHistory } from 'react-router-dom';
import { format_day, notify, translateTargetText } from '../../../utils/util';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TargetUndo } from '../../../assets/svg/undoTarget.svg';
import Switcher from '../Switcher';
import PollScheduleModal from '../../modals/PollScheduleModal';
import ScheduleSurveyModal from '../../modals/ScheduleSurveyModal';
import Loading from '../../Loading';

function SingleSurvey({ surveyID, setScreen }) {
	const { t, locale, i18n } = useTranslation();
	const [hoveredButton, setHoveredButton] = useState(false);
	const [releaseByDays, setReleaseByDays] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [targetError, setTargetError] = useState(false);

	const [returnTarget, setReturnTarget] = useState({
		name: 'Select target',
		target: [],
		selection: [],
		type: 'root',
	});
	const [returnSchedule, setReturnSchedule] = useState({
		enabled: false,
		text: 'Off',
		recurrentText: 'Never',
		repeatEnabled: false,
		schedule_date: 0,
	});
	const [openSchedule, setOpenSchedule] = useState(false);
	const closeScheduleModal = () => {
		setOpenSchedule(false);
	};
	let history = useHistory();
	const [showTarget, setShowTarget] = useState(false);
	const closeTarget = () => {
		setShowTarget(false);
	};
	const { user, setUser } = useUser();
	const [anonymous, setAnonymous] = useState(false);
	const [addToAnalytic, setAddToAnalytic] = useState(false);
	const [survey, setSurvey] = useState({});
	const [surveysSelections, setSurveysSelections] = useState([]);
	const [loading, setLoading] = useState(true);
	const [scheduleByDaysModalOpened, setScheduleByDaysModalOpened] = useState(false);

	useEffect(() => {
		loadSurveys();
	}, [surveyID]);

	useEffect(() => {
		if (returnTarget?.target?.length < 2 && anonymous) {
			notify(t(`Cannot create anonymous survey for 1 user`));
			setAnonymous(false);
		}
	}, [returnTarget?.target]);

	useEffect(() => {
		if (releaseByDays) setScheduleByDaysModalOpened(true);
	}, [releaseByDays]);

	const loadSurveys = async () => {
		if (surveyID) {
			const response = await getById({
				collection: 'surveys',
				id: surveyID,
			});
			if (response.status === 200) {
				setSurvey(response.data);
				setSurveysSelections(response.data.sections);
				setLoading(false);
				document.getElementById('surveyHome')?.scrollIntoView({ behavior: 'smooth' });
			} else {
				setSurvey({});
				setSurveysSelections([]);
			}
		}
	};

	const validate = () => {
		let valid = true;
		if (returnTarget?.target?.length === 0) {
			setTargetError(true);
			valid = false;
		}
		return valid;
	};

	const handleRun = async () => {
		if (!validate()) return;
		let data = {
			title: survey?.title,
			description: survey?.description,
			sections: survey?.sections,
			preCreated: survey?.preCreated,
			institution_id: user?.institution_id,
			targetSchools: [user.institution_id],
			schedule: returnSchedule?.enabled,
			schedule_info: {
				enabled: returnSchedule?.enabled,
				recurrentText: returnSchedule?.recurrentText,
				repeatEnabled: returnSchedule?.repeatEnabled,
				text: returnSchedule?.text,
				schedule_date: returnSchedule?.schedule_date == 0 ? null : returnSchedule?.schedule_date,
				days: returnSchedule?.days ?? [],
				scheduledDays: returnSchedule?.scheduledDays ?? [],
			},
			schedule_date: returnSchedule?.schedule_date == 0 ? null : returnSchedule?.schedule_date,
			device: 'desktop',
			target: returnTarget?.target ? returnTarget.target.map((a) => a.id) : [],
			target_text: returnTarget.name,
			user_id: user.id,
			status: 'start',
			survey_id: survey.id,
			hash: Date.now() + user.id.toString(36),
			type: 'survey',
			created_date: Date.now(),
			anonymous,
			addedToAnalytics: addToAnalytic,
			created_by: {
				first_name: user.first_name,
				last_name: user.last_name,
			},
		};
		data.publicationDate = data.schedule ? data.schedule_date : data.created_date;
		if (releaseByDays) {
			data['sections'] = survey.sections.map((section, index) => ({ ...section, releaseDate: returnSchedule?.scheduledDays[index] }));
			data['releaseByDays'] = true;
		}
		const response = await createContent({ data });
		if (response.status === 200) {
			notify(t('surveyIsCreated'));
			sendNotification({ content: response.data, type: 'survey' });
			history.push('/dashboard');
		}
	};

	const switchAnonymous = (state) => {
		const numberOfTarget = returnTarget?.target?.length ?? 0;
		if (numberOfTarget > 0 && numberOfTarget < 2) {
			notify(t(`Cannot create anonymous survey for 1 user`));
			return;
		}
		//setTrackWellbeingEnabled(false);

		setAnonymous(state);
	};
	const setSchedule = (option) => {
		setReturnSchedule({
			enabled: false,
			text: 'Off',
			recurrentText: 'Never',
			repeatEnabled: false,
			schedule_date: 0,
		});
	};

	const handleEdit = () => {
		history.push(`/content?surveyId=${survey?.id}`);
	};

	const closeScheduleByDaysModal = (state) => {
		if (!state) {
			setReleaseByDays(false);
		}
		setScheduleByDaysModalOpened(false);
	};

	return (
		<div id='surveyHome'>
			<div>
				<div className=''>
					<div className='bg-containerGray px-14 p-8 rounded-xl'>
						<div className='flex items-center justify-start'>
							<div
								className=' flex pointer mr-5 items-center justify-center bg-[#5E639B]  min-w-[56px] w-[56px] h-[56px] rounded-full'
								onClick={() => setScreen('home')}
								onMouseEnter={() => setHoveredButton(true)}
								onMouseLeave={() => setHoveredButton(false)}
							>
								<TargetUndo fill={hoveredButton ? '#FDDF6D' : '#FFFFFF'} />
							</div>
							<h1 className='font-bold text-deepPurple text-xl'>{survey.title}</h1>
						</div>
						<p className='text-[#413F5E] text-base font-medium text-left mt-2'>{survey.description}</p>
					</div>

					<div className='run-program-body px-14 p-2 mt-[20px]'>
						<div>
							{loading ? (
								<div className='w-full flex flex-row items-center justify-center min-h-[300px]'>
									<Loading size={32} />
								</div>
							) : (
								<div>
									{surveysSelections.length > 1 && (
										<div className='flex flex-row justify-end mb-[20px] items-center'>
											<p className='flex-1 text-[#5E639B] font-medium text-[15px]'>
												{/* This tool helps you to run a survey for your students */}
											</p>
											<p className='text-[#5E639B] text-[18px] font-medium mr-[20px]'>
												{t('Release survey by days')}
											</p>
											<Switcher setSwitcher={setReleaseByDays} switcher={releaseByDays} />
										</div>
									)}
									{releaseByDays ? (
										<SurveyViewByDays sections={surveysSelections} />
									) : (
										<DefaultSurveyView sections={surveysSelections} />
									)}
								</div>
							)}
							<div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<h1 className='text-lg text-[#5E639B] font-bold text-left my-4'>{t('configureSurvey')}</h1>
									<div className='mt-2'>
										<p
											className={`text-[#FF747F] font-medium text-[14px] text-center ${
												targetError ? '' : 'opacity-0'
											}`}
										>
											*{t('youMustFillData')}
										</p>
									</div>
									<div className='flex flex-row items-center justify-between'>
										<div className='flex flex-row justify-between items-center  h-[40px]'>
											<p
												className={`text-[#5E639B] font-medium text-lg mr-5  ${
													targetError ? 'text-[#FF747F]' : ''
												}`}
											>
												{t('targetGroup')}
												{targetError ? '*' : ''}
											</p>
											<div
												className={`${
													targetError ? 'text-deleteRed' : ' text-[#5E639B]'
												} text-lg hover:text-deepPurple font-semibold rounded-full py-1 px-4 bg-containerGray pointer `}
												onClick={() => {
													setShowTarget(true);
													setTargetError(false);
												}}
											>
												{translateTargetText(returnTarget?.name)}
											</div>
										</div>
										<div className='flex flex-row justify-between items-center my-3 h-[40px]'>
											<p className='text-[#5E639B] font-medium text-lg mr-5'>{t('schedule')} </p>
											<div
												className='text-lg flex flex-row justify-between items-center
                            text-mudPurple rounded-full py-1 px-4 bg-containerGray pointer'
												onClick={() => setScheduleByDaysModalOpened(true)}
											>
												<Switcher
													setSwitcher={() => {
														setScheduleByDaysModalOpened(true);
													}}
													switcher={returnSchedule?.enabled}
												/>
												<span className='ml-2 font-semibold text-lg text-mudPurple'>
													{returnSchedule?.enabled &&
														format_day(returnSchedule?.schedule_date, true, i18n.language)}
												</span>
											</div>
										</div>
										<div className='flex flex-row justify-between items-center my-3 h-[40px] '>
											<p className='text-[#5E639B] font-medium text-lg mr-5'>{t('anonymous')}:</p>

											<Switcher setSwitcher={switchAnonymous} switcher={anonymous} />
										</div>
										{/* <div className='flex flex-row items-center my-3 h-[40px]  '>
											<p className='text-[#5E639B] font-medium text-lg mr-5'>{t('addToAnalytics')}:</p>

											<Switcher setSwitcher={setAddToAnalytic} switcher={addToAnalytic} />
										</div> */}
									</div>
									<div className='flex flex-row items-center w-full justify-center my-3'>
										{!survey.preCreated && (
											<div
												className='mr-5 text-deepPurple bg-highlightColor rounded-full text-lg font-semibold pointer w-[300px] h-[50px] flex items-center justify-center'
												onClick={() => handleEdit()}
											>
												{t('editSurvey')}
											</div>
										)}
										<div
											className='text-highlightColor hover:text-deepPurple bg-deepPurple hover:bg-highlightColor rounded-full text-lg font-semibold pointer w-[300px] h-[50px] flex items-center justify-center'
											onClick={() => handleRun()}
										>
											{t('runSurvey')}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<PollScheduleModal showSchedule={openSchedule} closeSchedule={closeScheduleModal} setReturnSchedule={setReturnSchedule} />
			<ScheduleSurveyModal
				modalIsOpen={scheduleByDaysModalOpened}
				closeModal={closeScheduleByDaysModal}
				survey={survey}
				setReturnSchedule={setReturnSchedule}
				releaseByDays={releaseByDays}
			/>
			<TargetGroupModal showTarget={showTarget} closeTarget={closeTarget} setReturnTarget={setReturnTarget} />
		</div>
	);
}

const DefaultSurveyView = ({ sections }) => {
	const { t, locale } = useTranslation();
	return (
		<div className='survey-section'>
			{sections?.length > 1 ? (
				sections?.map((section, index) => {
					return <ExpandableSection section={section} index={index} key={index} />;
				})
			) : (
				<SingleSectionView section={sections[0]} />
			)}
		</div>
	);
};

const SurveyViewByDays = ({ sections }) => {
	const { t, locale } = useTranslation();
	const [selectedDay, setSelectedDay] = useState(0);
	const selectedDayData = sections[selectedDay];
	return (
		<div className='survey-section'>
			<div className='program_days' style={{ display: 'block' }}>
				<p className='text-left text-deepPurple font-bold text-lg mb-[11px]'>List of days</p>
				<div className='flex overflow-x-auto w-full space-x-2.5 pb-5 items-center horizontal-grey-small-scroll'>
					{sections.map((d, index) => {
						return (
							<div
								className={`rounded-[10px] pointer text-sm p-[10px] font-semibold flex items-center justify-center whitespace-nowrap ${
									index === selectedDay
										? 'bg-deepPurple text-highlightColor'
										: 'bg-lightBG hover:bg-deselectPurple text-deepPurple'
								}`}
								key={index}
								onClick={() => setSelectedDay(index)}
							>
								Day {(index + 1).toString()}
							</div>
						);
					})}
				</div>
			</div>
			<div style={{ paddingLeft: '15px' }} className={`transition-[max-height] duration-700 ease-out overflow-hidden`}>
				<div className='text-[#413F5E] text-[20px] font-bold text-left'>
					Section #{selectedDay + 1} {selectedDayData.title}
				</div>
				<div className='text-[#413F5E] text-base font-medium text-left mt-[20px]'>{selectedDayData.description}</div>
				<div className='mt-[20px]'>
					{selectedDayData?.questions?.map((q, indexQ) => {
						return (
							<div className='survey-question' key={indexQ}>
								<p htmlFor='' className='font-bold text-[#413F5E] text-sm text-left'>
									{indexQ + 1}. {q.title}
								</p>

								{q.type === 'options' && (
									<div className='section-question-options mt-[16px] mb-[20px]'>
										{q.options.map((option, oindex) => {
											return (
												<div
													className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'
													key={oindex}
												>
													{option}
												</div>
											);
										})}
									</div>
								)}
								{q.type === 'yesNo' && (
									<div className='section-question-options mt-[16px] mb-[30px]'>
										<div className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'>
											{t('yes')}
										</div>
										<div className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'>
											{t('no')}
										</div>
									</div>
								)}
								{q.type === 'openAnswer' && (
									<div className='section-question-options mt-[16px] mb-[30px]'>
										<div className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'>
											{t('openAnswer')}
										</div>
									</div>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const ExpandableSection = ({ section, index }) => {
	const { t, locale } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	return (
		<div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
			<div className='bg-[#ECEBF8] px-[30px] rounded-[10px] flex flex-row items-center justify-between h-[73px]'>
				<p className='font-bold text-[#5E639B] text-[20px] text-left'>
					{index + 1}. Section #{index + 1} Questions 1-{section?.questions?.length}
				</p>
				<div
					className={` h-[55px] w-[55px] rounded-full flex items-center justify-center -mr-[21px] cursor-pointer transition-all 
						${expanded ? 'bg-[#5E639B] rotate-180 ' : 'bg-[#FFFFFF]'}
				`}
					onClick={() => setExpanded(!expanded)}
				>
					<AngleUp color={!expanded ? '#9392AD' : '#ffffff'} />
				</div>
			</div>
			<div
				style={{ paddingLeft: '15px' }}
				className={`mt-[30px] transition-[max-height] duration-700 ease-out overflow-hidden ${expanded ? 'h-auto' : 'h-0'}`}
			>
				<div className='text-[#413F5E] text-[20px] font-bold text-left'>{section?.title}</div>
				<div className='text-[#413F5E] text-base font-medium text-left mt-[20px]'>{section?.description}</div>
				<div className='mt-[20px]'>
					{section?.questions?.map((q, indexQ) => {
						return (
							<div className='survey-question' key={indexQ}>
								<p htmlFor='' className='font-bold text-[#413F5E] text-sm text-left'>
									{indexQ + 1}. {q.title}
								</p>

								{q.type === 'options' && (
									<div className='section-question-options mt-[16px] mb-[20px]'>
										{q.options.map((option, oindex) => {
											return (
												<div
													className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'
													key={oindex}
												>
													{option}
												</div>
											);
										})}
									</div>
								)}
								{q.type === 'yesNo' && (
									<div className='section-question-options mt-[16px] mb-[30px]'>
										<div className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'>
											{t('yes')}
										</div>
										<div className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'>
											{t('no')}
										</div>
									</div>
								)}
								{q.type === 'openAnswer' && (
									<div className='section-question-options mt-[16px] mb-[30px]'>
										<div className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'>
											{t('openAnswer')}
										</div>
									</div>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const SingleSectionView = ({ section }) => {
	const { t, locale } = useTranslation();
	return (
		<div style={{ paddingLeft: '15px' }} className={` transition-[max-height] duration-700 ease-out overflow-hidden`}>
			<div className='text-[#413F5E] text-[20px] font-bold text-left'>{section?.title}</div>
			<div className='text-[#413F5E] text-base font-medium text-left mt-[20px]'>{section?.description}</div>
			<div className='mt-[20px]'>
				{section?.questions?.map((q, indexQ) => {
					return (
						<div className='survey-question' key={indexQ}>
							<p htmlFor='' className='font-bold text-[#413F5E] text-sm text-left '>
								{indexQ + 1}. {q.title}
							</p>

							{q.type === 'options' && (
								<div className='section-question-options mt-[16px] mb-[20px]'>
									{q.options.map((option, oindex) => {
										return (
											<div
												className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'
												key={oindex}
											>
												{option}
											</div>
										);
									})}
								</div>
							)}
							{q.type === 'yesNo' && (
								<div className='section-question-options mt-[16px] mb-[30px]'>
									<div className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'>
										{t('yes')}
									</div>
									<div className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'>
										{t('no')}
									</div>
								</div>
							)}
							{q.type === 'openAnswer' && (
								<div className='section-question-options mt-[16px] mb-[30px]'>
									<div className='rounded-[10px] bg-[#F8F8FB] h-[40px] ml-3 px-5 font-semibold text-sm flex items-center justify-center text-[#5E639B]'>
										{t('openAnswer')}
									</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SingleSurvey;
