import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../../assets/svg/CloseModalIcon.svg';
import { getResponseSuggestions, respondToDailyQuestion } from '../../../server/server';
import * as moment from 'moment';
import { useSocket } from '../../../context/socket';
import { encrypt } from '../../../utils/crypto';
import HowAreYouFinal from './HowAreYouFinal';
import NewSecretKeyModal from '../NewSecretKeyModal';
import SelectReason from './SelectReason';
import CompareEmotions from './CompareEmotions';
import CompareDetails from './CompareDetails';
import { useTranslation } from 'react-i18next';

function HowAreYouModal({ modalIsOpen, closeModal, user, lastEmotion, setLastEmotion, emotionDef, checkUsersFeedbackNeeded = () => {} }) {
	const [modalState, setModalState] = useState('reason');
	const [answer_text, setAnswerText] = useState('');
	const [reasonsList, setReasonsList] = useState([]);
	const [sendingEmotion, setSendingEmotion] = useState(false);
	const [selectedEmotion, setSelectedEmotion] = useState(emotionDef);
	const [dailyEmotion, setDailyEmotion] = useState({});
	const { socket } = useSocket();
	const [sliderValue, setSliderValue] = useState(3);
	const [note, setNote] = useState({});
	const [message, setMessage] = useState('');
	const [suggestions, setSuggestions] = useState(['1', '2', '3', '4', '5', '6']);
	const [selectedSuggestions, setSelectedSuggestions] = useState(['', '', '']);
	const [numberOfSuggestions, setNumberOfSuggestions] = useState(4);
	const [secretKeyModalIsOpen, setSecretKeyModalOpen] = useState(false);
	const [howAreYouFinalOpen, setHowAreYouFinalOpen] = useState(false);
	const [finalData, setFinalData] = useState({});
	const { t, i18n } = useTranslation();
	const locale = i18n.language;

	const submitEmotion = async () => {
		setSendingEmotion(true);
		const data = {
			emotion: selectedEmotion,
			created_date: Date.now(),
			user_id: user.id,
			type: 'daily',
			day_code: moment().format('YYYY-MM-DD'),
			message: answer_text,
			reasonsList,
		};
		const resp = await respondToDailyQuestion(data);
		if (resp.status === 200) {
			console.log('Message is: ', resp.data);
			// socket.emit('share_emotion', {
			// 	user_id: user.id,
			// 	data: resp.data,
			// });
			setDailyEmotion(resp.data);
			setAnswerText('');
			setModalState('compare');
		}
		setSendingEmotion(false);
	};

	const changeReasonsList = (item) => {
		if (reasonsList.length < 3 && !reasonsList.includes(item)) {
			setReasonsList([...reasonsList, item]);
			return;
		}
		if (reasonsList.length < 3 && reasonsList.includes(item)) {
			let newList = reasonsList.filter((reason) => reason !== item);
			setReasonsList(newList);
			return;
		}
		if (reasonsList.length === 3) {
			if (reasonsList.includes(item)) {
				let newList = reasonsList.filter((reason) => reason !== item);
				setReasonsList(newList);
				return;
			} else {
				return;
			}
		}
	};
	const shareAndContinue = async () => {
		const compare = () => {
			switch (sliderValue) {
				case 0:
					return 'Much worse';
				case 1:
					return 'Worse';
				case 2:
					return 'Same';
				case 3:
					return 'Better';
				default:
					return 'Much better';
			}
		};
		setNote({ compare: compare(), dailyEmotion, lastEmotion });
		setModalState('compareDetails');
	};

	useEffect(() => {
		if (modalState === 'compareDetails') {
			getSuggestions();
			let secretKey = localStorage.getItem('secret_key');
			if (secretKey === null) setSecretKeyModalOpen(true);
		}
	}, [modalState]);

	const getSuggestions = async () => {
		const data = { response: note.compare, locale: locale };
		console.log(data);
		const resp = await getResponseSuggestions(data);
		if (resp.status === 200) {
			console.log('suggestions ', resp.data);
			setSuggestions(resp.data);
		} else {
			console.log('error ', resp);
		}
	};

	const pressSuggestions = (item) => {
		var _selected_array = [...selectedSuggestions];
		const _index = selectedSuggestions.findIndex((e) => e === item);
		if (_index !== -1) {
			_selected_array[_index] = '';
			setSelectedSuggestions(_selected_array);
			return;
		}
		for (var i = 0; i < selectedSuggestions.length; i++) {
			if (_selected_array[i] === '') {
				_selected_array[i] = item;
				break;
			}
		}
		setSelectedSuggestions(_selected_array);
	};

	const questionCompareContinue = async () => {
		const secretKey = localStorage.getItem('secret_key');
		const encrypted_suggestions = selectedSuggestions.map((e) => encrypt(e, secretKey));
		const data = {
			message: encrypt(message, secretKey),
			user_id: user.id,
			compare: note.compare,
			suggestions: encrypted_suggestions,
			last_daily_feedback: note?.lastEmotion,
			today_daily_feedback: note?.dailyEmotion,
			type: 'daily_feedback',
		};
		setFinalData(data);
		setHowAreYouFinalOpen(true);
	};

	const closeHowAreYouFinal = () => {
		setHowAreYouFinalOpen(false);
		closeModal();
	};
	const closeSecretKeyModal = () => {
		setSecretKeyModalOpen(false);
		const secret_key = localStorage.getItem('secret_key');
		if (secret_key == null) closeModal();
	};

	const resetModal = () => {
		setModalState('reason');
		setReasonsList([]);
		setAnswerText('');
		setSliderValue(3);
		setNote({});
		setMessage('');
		setSelectedSuggestions(['', '', '']);
		setSuggestions(['1', '2', '3', '4', '5', '6']);
		setNumberOfSuggestions(4);
		setDailyEmotion({});
	};

	React.useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);
	return (
		<ReactModal
			transparent
			isOpen={modalIsOpen}
			ariaHideApp={false}
			onAfterOpen={() => setSelectedEmotion(emotionDef)}
			onAfterClose={() => resetModal()}
			className='teacher-feedback-no-scroll'
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 99,
				},
				content: {
					inset: 0,
					padding: 0,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'scroll',
					paddingTop: 35,
					paddingBottom: 15,
				},
			}}
		>
			<div className='w-fit bg-white m-auto mt-[30px] rounded-[20px]  relative '>
				<div className='teacher-feedback-close-button' onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
				{modalState === 'reason' && (
					<SelectReason
						answer_text={answer_text}
						setAnswerText={setAnswerText}
						selectedEmotion={selectedEmotion}
						reasonsList={reasonsList}
						changeReasonsList={changeReasonsList}
						sendingEmotion={sendingEmotion}
						submitEmotion={submitEmotion}
					/>
				)}
				{modalState === 'compare' && (
					<CompareEmotions
						closeModal={closeModal}
						dailyEmotion={dailyEmotion}
						lastEmotion={lastEmotion}
						setSliderValue={setSliderValue}
						shareAndContinue={shareAndContinue}
						sliderValue={sliderValue}
					/>
				)}
				{modalState === 'compareDetails' && (
					<CompareDetails
						message={message}
						setMessage={setMessage}
						selectedSuggestions={selectedSuggestions}
						setSelectedSuggestions={setSelectedSuggestions}
						numberOfSuggestions={numberOfSuggestions}
						pressSuggestions={pressSuggestions}
						setNumberOfSuggestions={setNumberOfSuggestions}
						questionCompareContinue={questionCompareContinue}
						note={note}
						suggestions={suggestions}
						setModalState={setModalState}
					/>
				)}
				<NewSecretKeyModal closeModal={closeSecretKeyModal} modalIsOpen={secretKeyModalIsOpen} />
				<HowAreYouFinal
					modalIsOpen={howAreYouFinalOpen}
					closeModal={closeHowAreYouFinal}
					finalData={finalData}
					checkUsersFeedbackNeeded={checkUsersFeedbackNeeded}
				/>
			</div>
		</ReactModal>
	);
}

export default HowAreYouModal;
