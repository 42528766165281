import React from 'react';
import { emotions_heads } from '../../../constants/Constants';
import { ReactComponent as CompareArrowIcon } from '../../../assets/howAreYouSvg/CompareArrowIcon.svg';
import { useTranslation } from 'react-i18next';
import SliderLabelsCompare from './SliderLabelsCompare';
import { ReactComponent as SliderThumbIcon } from '../../../assets/howAreYouSvg/SliderThumbIcon1.svg';
import { ReactComponent as CompareGradientScale } from '../../../assets/howAreYouSvg/CompareGradientScale1.svg';
import ReactSlider from 'react-slider';
import { notify } from '../../../utils/util';

function CompareEmotions({ lastEmotion, dailyEmotion, shareAndContinue, sliderValue, closeModal, setSliderValue }) {
	const { t } = useTranslation();
	return (
		<div className='bg-whiteContrast mt-[30px] w-[620px] rounded-[10px] '>
			<div className='bg-lightBG pt-[49px] px-[00px] flex flex-col items-center gap-5 rounded-[10px] relative'>
				<p className='text-[20px] leading-[25px] text-darkenPurple font-bold'>{t('comparedToLastTimeTodayIamFeeling')}</p>

				<div className='flex flex-row items-center justify-center gap-1 -mb-[35px]'>
					{!!lastEmotion && <img src={emotions_heads[lastEmotion.emotion]} alt='emotion' className={`w-[50px] h-[50px] `} />}
					{!!lastEmotion && <CompareArrowIcon />}
					{!!dailyEmotion && (
						<img src={emotions_heads[dailyEmotion.emotion]} alt={dailyEmotion} className={`w-[70px] h-[70px] `} />
					)}
				</div>
			</div>
			<div className='pt-[60px] pb-[50px] px-[40px] rounded-[10px] flex flex-col items-center '>
				<p className='text-[18px] leading-[20px] text-deepPurple font-bold '>{t('howDoYouFeelComparedToLastTimeYouLoggedIn')}</p>
				<div className='mt-[17px] relative flex flex-col items-center justify-center h-[160px] '>
					<SliderLabelsCompare sliderValue={sliderValue} />
					<div style={{ position: 'relative' }}>
						<CompareGradientScale style={{ marginTop: 3 }} />
						<div className='px-[30px]'>
							{' '}
							<ReactSlider
								className='-mt-[90px] w-[100%] h-[60px] flex items-center justify-between'
								thumbClassName='how-slider-thumb'
								max={4}
								min={0}
								defaultValue={3}
								snapDragDisabled={false}
								value={sliderValue}
								onChange={(e) => setSliderValue(e)}
								renderThumb={(props, state) => (
									<div {...props}>
										<div style={{ margin: 'auto' }}>
											<SliderThumbIcon />
										</div>
									</div>
								)}
							/>
						</div>
					</div>
				</div>
				<div className='mt-[40px] w-[100%] flex flex-row items-center gap-[12px]  '>
					<div
						className='flex-1 flex flex-row items-center justify-center cursor-pointer  rounded-[48px] h-[55px] border hover:border-lightBG border-deselectPurple hover:bg-lightBG bg-whiteContrast '
						onClick={() => {
							closeModal();
						}}
					>
						<p className='text-[18px] leading-[22px] font-semibold text-deepPurple '>{t('iDontWantToThinkAboutThisNow')}</p>
					</div>
					<div
						className='group flex flex-row items-center justify-center cursor-pointer bg-deepPurple rounded-[48px] w-[124px] h-[55px] hover:bg-highlightColor  '
						onClick={shareAndContinue}
					>
						<p className='text-[18px] leading-[22px] font-semibold text-highlightColor group-hover:text-deepPurple  '>
							{t('continue')}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CompareEmotions;
