import React from 'react';

import AnalyticsBackground from '../../../assets/svg/AnaliticsBackground.svg';
import { ReactComponent as AnalyticsStar } from '../../../assets/svg/AnaliticsStar.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as Undo } from '../../../assets/svg/Undo.svg';
import { useTranslation } from 'react-i18next';

function CustomReportsHeader() {
	const { t, locale } = useTranslation();
	const history = useHistory();
	const goBack = () => history.goBack();

	return (
		<div
			style={{
				background: `url(${AnalyticsBackground})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
			className='w-full rounded-[10px] relative min-h-[120px] flex flex-row items-center px-10  justify-between'
		>
			{/* <div className='flex-1 flex items-center justify-end pr-[100px]'>{<AnalyticsStar />}</div> */}
			<div className='flex flex-row items-center gap-[15px]'>
				<button className='return-back-button' onClick={goBack}>
					<Undo height={20} width={18} />
				</button>
				<p htmlFor='Get Back to Main Analytics' className='max-w-[120px] text-[white] text-[14px] font-medium'>
					{t('Return to Analytics')}
				</p>
			</div>
			<img
				src={require('../../../assets/images/AnalyticsMascot.png')}
				alt='Analytics Mascot'
				style={{ height: 120, marginTop: -10, width: 293 }}
			/>
			<div>
				<label className='text-[32px] font-bold text-[white]'>{t('Create & Manage Reports')}</label>
			</div>
		</div>
	);
}

export default CustomReportsHeader;
