import { width } from '@mui/system';
import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Oval } from 'react-loader-spinner';
import Loading from '../../Loading';

function DonutChartDash({
	percentage = 0,
	outerRadius = '#FDDF6D',
	innerRadius = '#7b7ac3',
	text = '#FDDF6D',
	betweenRadius = '',
	loading = false,
	loadingColor = '#FDDF6D',
	loadingSecondColor = '#FDDF6D',
	fontSize = 12,
	size = 48,
	showPercentage = true,
}) {
	return (
		<div style={{ width: size, height: size, position: 'relative' }}>
			{loading ? (
				<div
					style={{
						height: size,
						width: size,
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<div>
						<Loading primaryColor={loadingColor} secondaryColor={loadingSecondColor} size={36} stroke={7} />
					</div>
				</div>
			) : (
				<>
					<svg width={`${size}px`} height={`${size}px`} viewBox='0 0 90 90' className='donut' style={{ transform: 'scaleX(-1)' }}>
						<defs>
							<linearGradient id='gradient-blue'>
								<stop offset='10.87%' stopColor='#7b7ac3' />
								<stop offset='88.17%' stopColor='#83819F' />
							</linearGradient>
							<linearGradient id='gradient-orange'>
								<stop offset='4%' stopColor='#F1A716' />
								<stop offset='91%' stopColor='#FFC369' />
							</linearGradient>
						</defs>

						<circle cx='50%' cy='50%' r='30' fill={innerRadius} stroke={betweenRadius} strokeWidth='5'></circle>
						<circle
							transform='rotate(-90,45,45)'
							cx='50%'
							cy='50%'
							r='40'
							fill='transparent'
							stroke={outerRadius}
							strokeWidth='10'
							strokeDasharray={`${40 * 2 * Math.PI * percentage} ${40 * 2 * Math.PI * (1 - percentage)}`}
							strokeDashoffset='0'
						></circle>
					</svg>
					<div
						style={{ color: text, fontSize: fontSize, height: size, width: size }}
						className={`absolute font-semibold text-sm  text-highlightColor top-0 items-center flex justify-center`}
					>
						{Math.floor(percentage * 100)}
						{showPercentage ? '%' : ''}
					</div>
				</>
			)}
		</div>
	);
}

export default DonutChartDash;
