import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import '../../styles/modals/ConfirmationFeedDeleteModal.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConfirmImage } from '../../assets/svg/ConfirmImage.svg';
import { ReactComponent as TutorialImage } from '../../assets/svg/TutorialModalImage.svg';
import { ReactComponent as ExitCross } from '../../assets/svg/ExitCross.svg';
import '../../styles/TutorialModal.css';

import RocketImage from '../../assets/images/RocketImage.png';
import { useUser } from '../../auth';
import { formatTwoDigitNumber } from '../../utils/util';
import { takeTutorial } from '../../server/server';
import { Teachers } from './TeacherSteps';
import { TeacherReportSteps } from './TeacherReportsSteps';
// images/RocketImage.png

function TutorialModal({ modalIsOpen, afterOpenModal, closeModal, confirmation, message, subMessage = '' }) {
	const { t, locale } = useTranslation();
	const { user } = useUser();
	const [currentStep, setCurrentStep] = useState(0);
	const totalSteps = TeacherReportSteps.length - 1;
	const currentStepData = TeacherReportSteps[currentStep];

	useEffect(() => {
		if (modalIsOpen) {
			Modal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);

	const goNextStep = () => {
		if (currentStep < totalSteps) setCurrentStep(currentStep + 1);
		else _closeModal();
	};
	const goPreviousStep = () => {
		if (currentStep > 0) setCurrentStep(currentStep - 1);
	};

	const saveTutorialDesktop = async (currentStep) => {
		takeTutorial(currentStep);
	};

	const _closeModal = () => {
		saveTutorialDesktop(currentStep);
		closeModal();
	};

	const isLast = currentStep == totalSteps;

	return (
		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={_closeModal}
			contentLabel='Tutorial Modal'
			style={{
				overlay: {
					zIndex: 10,
				},
				content: {
					width: 800,
					minHeight: 620,
					padding: 0,
					opacity: 1,
					minWidth: 800,
					backgroundColor: 'transparent',
					border: 'none',
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'absolute',
					left: '50%',
					marginLeft: '-400px',
					top: '50%',
					marginTop: '-360px',
				},
			}}
		>
			<div className='tutorial-modal'>
				<div className='tutorial-left-side'>
					<TutorialImage />
					<img
						src={RocketImage}
						alt=''
						style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: 39 }}
						width={278.38}
						height={420.08}
					/>
					{/* <p className='major-update'>Major Update</p> */}
					<p className='major-update-values'>
						{formatTwoDigitNumber(currentStep + 1)}/{formatTwoDigitNumber(totalSteps + 1)}
					</p>
				</div>
				<div className='tutorial-right-side'>
					<button className='tutorial-exit-button' onClick={_closeModal}>
						<ExitCross />
					</button>
					<p className='tutorial-modal-title'>What's New?</p>
					<p className='tutorial-modal-subtitle'>{currentStepData.subtitle}</p>
					<div className='tutorial-card'>
						<div className='tutorial-image' style={{ marginTop: 4 }}>
							<img src={currentStepData.image} alt={'Tutorial'} width={400} height={183} key={currentStep} />
						</div>
						<p className='tutorial-title'>{currentStepData.title}</p>
						<p className='tutorial-description'>{currentStepData.description}</p>
					</div>
					<div style={{ flex: 1 }}></div>
					<div className='tutorial-footer'>
						<button
							onClick={goPreviousStep}
							className={`tutorial-previous-button ${currentStep === 0 ? 'opacity-0 !cursor-default' : ''}`}
						>
							Previous
						</button>
						<button onClick={goNextStep} className={`tutorial-next-button`}>
							{isLast ? 'Close pop-up' : 'Next'}
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default TutorialModal;
