import React from 'react';
import { emotions_heads } from '../../constants/Constants';
import ReactTooltip from 'react-tooltip';
import Loading from '../Loading';
import { ReactComponent as UpGreenIcon } from '../../assets/svg/UpGreenIcon.svg';
import { ReactComponent as DownGrayIcon } from '../../assets/svg/DownGrayIcon.svg';
import { useTranslation } from 'react-i18next';

function ClassroomEmotionSection({ emotions = [], percentage = 0, trend = 0, loading = false, title, emotionsType = 'comfortable' }) {
	const { t, locale } = useTranslation();
	return (
		<div className='positive-emotion-container relative items-center'>
			<div className='w-full'>
				<div className=' mb-[4px] flex flex-row gap-1 items-center'>
					<span className='text-[#5E639B] text-[16px] font-medium'>{title}</span>
				</div>
				<div className='positive-list justify-between gap-[10px]'>
					<div className='flex flex-row flex-wrap gap-[5px] ' style={{ maxWidth: Math.ceil(emotions.length / 2) * 35 }}>
						{emotions?.map((emotion, index) => {
							return <img src={emotions_heads[emotion]} alt={emotion} className='w-[30px] h-[30px]' key={emotion} />;
						})}
					</div>
					<div
						className='justify-end items-end cursor-help'
						data-tip={t(`Percentage of students with ${emotionsType} during the selected time period`)}
						data-for={title.replace(' ', '')}
					>
						<ReactTooltip
							id={title.replace(' ', '')}
							arrowColor='#5E639B'
							effect='solid'
							backgroundColor='#5E639B'
							className='wellbeing-tooltip'
						/>
						<p className='font-medium text-[14px] text-deepPurple'>{percentage}%</p>
					</div>
				</div>
			</div>
			<div
				className='green-button-perc cursor-help mt-[20px]'
				data-tip={t(`Relative change to the same selected previous period`)}
				data-for={`comfortableEmotionsTrend`}
			>
				{loading ? (
					<div className=' -mb-[27px]'>
						<Loading size={39} />
					</div>
				) : (
					<div className='flex flex-col items-center gap-1'>
						<ReactTooltip
							id={`comfortableEmotionsTrend`}
							arrowColor='#5E639B'
							effect='solid'
							backgroundColor='#5E639B'
							className='wellbeing-tooltip'
						/>
						<div className='flex flex-row items-center gap-1'>
							<div>
								<div className='inline-flex flex-row gap-1 text-deepPurple font-medium'>
									{trend}
									<span style={{ color: '#5e639b', opacity: 1, fontWeight: 500 }}>%</span>
								</div>
							</div>
						</div>
						{trend > 0 ? <UpGreenIcon /> : <DownGrayIcon />}
					</div>
				)}
			</div>
		</div>
	);
}

export default ClassroomEmotionSection;
