import React, { useState, useEffect, useContext } from "react";
import { FaChevronLeft, FaChevronRight, FaHistory } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import "../../styles/content/ContentProgram.css";
import { fileSystemUrl } from "../../constants/Connections";
import { useUser } from "../../auth";
import { getById, getProgramHistory } from "../../server/server";
import { ContentContext } from "./ContentContext";
import RunProgramModal from "../modals/RunProgramModal";
import UrlPreview from "../feedsAndDashboard/UrlPreview";
import ImagePreviewModal from "../modals/ImagePreviewModal";
import ProgramHistoryModal from "../modals/ProgramHistoryModal";
import ReactTooltip from "react-tooltip";
import { format } from "date-fns";
import ReadMoreText from "../ReadMoreText";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function ContentProgram() {
    let history = useHistory();
    const { t, locale } = useTranslation();
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    let query = useQuery();

    const [contentItem, setContentItem] = useContext(ContentContext);
    const { user, setUser } = useUser();
    const [program, setProgram] = useState({ name: "", description: "" });
    const [currentDay, setCurrentDay] = useState(0);
    const [loading, setLoading] = useState(false);
    const [days, setDays] = useState([{ poll: {}, question: {}, feed: {} }]);
    const [showRun, setShowRun] = useState(false);
    const [showImg, setShowImg] = useState({ show: false, url: "" });
    const [programHistory, setProgramHistory] = useState([]);
    const [historyID, setHistoryID] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const [currentHistory, setCurrentHistory] = useState([]);
    const [selectedContent, setSelectedContent] = useState({
        msg: "",
        type: "",
        day: 0,
    });
    const DEFAULT_STATE = {
        edit: "",
        type: "",
        title: "",
        msg: "",
        options: ["", "", ""],
        link: "",
        imgUrl: "",
        returnID: "",
        files: {
            old_name: "",
            storage_name: "",
            size: null,
            type: null,
        },
        editTarget: {
            name: "My students",
            target: [],
            selection: [],
            type: "root",
        },
        editschedule: {
            schedule: false,
            schedule_info: { enabled: false },
            status: "start",
        },
        likes: 0,
        views: 0,
        isReq: false,
        isAnon: false,
        editID: null,
        programDetails: {
            programId: "",
            dayIndex: 0,
        },
    };
    const handleSelect = (type, title, msg, options, link, imgUrl, returnID, files, dayIndex) => {
        setContentItem({
            ...DEFAULT_STATE,
            edit: "program",
            type: type,
            title: title,
            msg: msg,
            options: options,
            link: link,
            imgUrl: imgUrl,
            returnID: returnID,
            files: files,
            programDetails: {
                programId: returnID,
                dayIndex: dayIndex,
            },
        });
        history.push(`/content/${type}`);
    };
    const getProgram = async (ProgramID) => {
        const response = await getById({ collection: "programs", id: ProgramID });
        if (response.status === 200) {
            const { name, description, days, id } = response.data;
            setProgram({ name, description, id });
            setDays(days);
            console.log("program", response.data);
        } else {
            setProgram({});
            setDays([]);
            console.log("n e radi");
        }
    };
    useEffect(() => {
        getProgram(query.get("id"));
        setLoading(true);
    }, [loading]);
    useEffect(() => {
        console.log(currentDay, days[currentDay]);
    }, [currentDay]);
    const closeRun = () => {
        setShowRun(false);
    };
    const closeImg = () => {
        setShowImg({ show: false, url: "" });
    };
    const getHistory = async () => {
        const response = await getProgramHistory({
            programId: query.get("id"),
            institution_id: user?.institution_id,
        });
        console.log("aaaaaaaaaa", {
            programId: query.get("id"),
            institution_id: user?.institution_id,
        });
        if (response.status === 200) {
            console.log("ttt", response.data);
            setHistoryID(response.data);
            let tempHistory = response.data;
            setProgramHistory(
                tempHistory
                    .reverse()
                    .filter(
                        (thing, index, self) =>
                            index ===
                            self.findIndex((t) => t.programDetails?.dayIndex === thing.programDetails?.dayIndex && t?.type === thing?.type)
                    )
            );
        } else {
            setProgramHistory([]);
            setHistoryID([]);
        }
    };
    useEffect(() => {
        getHistory();
    }, []);
    const closeHistory = () => {
        setShowHistory(false);
    };
    const handleHistory = (msg, type, day) => {
        setCurrentHistory(historyID);
        setShowHistory(true);
        setSelectedContent({ msg: msg, type: type, day: day });
    };

    return (
        <div className={`content ${i18n.language == "ar" ? "ar-lang" : ""}`}>
            <div className="row">
                <div className="col-program">
                    <div className="card-program" style={{ marginBottom: "unset" }}>
                        <div className="program_header_full">
                            <div className="program_header_title_container">
                                <span
                                    className="back_button_program"
                                    onClick={() => {
                                        history.push("/content/");
                                    }}
                                >
                                    {i18n.language == "ar" ? (
                                        <FaChevronRight id="back-program-arrow-white" />
                                    ) : (
                                        <FaChevronLeft id="back-program-arrow-white" />
                                    )}

                                    <span>{t("back")}</span>
                                </span>
                                <h1 className={`program-title ${i18n.language == "ar" ? "text-right" : ""}`}>{program?.name}</h1>
                                <button className="run-program-button" onClick={() => setShowRun(true)}>
                                    {t("runProgram")}
                                </button>
                            </div>
                            <p style={{ padding: "10px 25px" }} className={`${i18n.language == "ar" ? "text-right" : ""}`}>
                                {program?.description}
                            </p>
                            <div className="program_body_full" style={{ "&::-webkit-scrollbar": { height: "10px" } }}>
                                <div className="program_days" style={{ display: "block" }}>
                                    <p id="lod-program">List of days:</p>
                                    <div className="program_days_list">
                                        {days.map((d, index) => {
                                            return (
                                                <div
                                                    className={`program_day ${index === currentDay ? "selected_day" : ""}`}
                                                    key={index}
                                                    onClick={() => setCurrentDay(index)}
                                                >
                                                    {(index + 1).toString()}
                                                    {console.log("t", index, currentDay, index === currentDay)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="selected_program_day">
                                    {Object.keys(
                                        Object.assign(
                                            {
                                                poll: null,
                                                question: null,
                                                feed: null,
                                            },
                                            days[currentDay]
                                        )
                                    ).map((type, index) => {
                                        return (
                                            <div className={`widget_card ${i18n.language == "ar" ? "text-right" : ""}`} key={index}>
                                                <div className="widget_content">
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() =>
                                                            handleSelect(
                                                                type,
                                                                days[currentDay][type].title,
                                                                days[currentDay][type].message,
                                                                days[currentDay][type].options,
                                                                days[currentDay][type].url ? days[currentDay][type].url : "",
                                                                days[currentDay][type].files
                                                                    ? `${fileSystemUrl}/${days[currentDay][type].files.storage_name}?token=${user.token}`
                                                                    : undefined,
                                                                program.id,
                                                                days[currentDay][type].files ? days[currentDay][type].files : undefined,
                                                                currentDay
                                                            )
                                                        }
                                                    >
                                                        {t("select")}: {type}
                                                    </button>

                                                    {programHistory.map((program, index) => {
                                                        if (program.programDetails.dayIndex === +currentDay && program.type === type) {
                                                            console.log(
                                                                "radi",
                                                                program.programDetails.dayIndex,
                                                                +currentDay,
                                                                program.type,
                                                                type
                                                            );
                                                            return (
                                                                <button
                                                                    className="btn btn-primary history ng-animate-disabled"
                                                                    style={{ flex: "unset" }}
                                                                    data-tip={`Last Created ${type}: ${format(
                                                                        program.created_date,
                                                                        "MM/dd/yyyy"
                                                                    )}`}
                                                                    data-for="historyModal"
                                                                    onClick={() => {
                                                                        handleHistory(days[currentDay][type].message, type, +currentDay);
                                                                    }}
                                                                >
                                                                    <FaHistory />
                                                                    <ReactTooltip
                                                                        id="historyModal"
                                                                        arrowColor="transparent"
                                                                        effect="solid"
                                                                        backgroundColor="rgb(97,97,97)"
                                                                        className="tooltip-small"
                                                                    />
                                                                </button>
                                                            );
                                                        } else {
                                                            console.log(
                                                                "neradi",
                                                                program.programDetails.dayIndex,
                                                                +currentDay,
                                                                program.type,
                                                                type
                                                            );
                                                            return "";
                                                        }
                                                    })}
                                                </div>
                                                <div
                                                    className="widget_header"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 0,
                                                    }}
                                                >
                                                    <h4 style={{ fontWeight: "600" }}>{days[currentDay][type].title}</h4>
                                                    <div id="dot-line-program"></div>
                                                </div>
                                                <div className="program_widget_body">
                                                    <div
                                                        className={`program_widget_content ${
                                                            !(days[currentDay][type].message || days[currentDay][type].options)
                                                                ? "hidden"
                                                                : ""
                                                        }`}
                                                    >
                                                        <div>
                                                            {days[currentDay][type]?.message && (
                                                                <pre>
                                                                    {days[currentDay][type]?.message !== "" && (
                                                                        <ReadMoreText
                                                                            message={days[currentDay][type]?.message}
                                                                            lines={10}
                                                                        />
                                                                    )}
                                                                </pre>
                                                            )}
                                                            {days[currentDay][type].options && (
                                                                <div>
                                                                    <p
                                                                        style={{
                                                                            fontWeight: "600",
                                                                            padding: "10px 0px",
                                                                        }}
                                                                    >
                                                                        {t("pickOneOption")}:
                                                                    </p>
                                                                    {days[currentDay][type].options.map((option, index) => {
                                                                        return (
                                                                            <p key={index} style={{ padding: "3px 0px" }}>
                                                                                <span
                                                                                    style={{
                                                                                        paddingRight: "10px",
                                                                                    }}
                                                                                >
                                                                                    🔘
                                                                                </span>
                                                                                {option}
                                                                            </p>
                                                                        );
                                                                    })}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {days[currentDay][type].files && (
                                                        <div
                                                            className="program_widget_picture"
                                                            style={{
                                                                width: `${
                                                                    !(days[currentDay][type].message || days[currentDay][type].options)
                                                                        ? "100%"
                                                                        : "180px"
                                                                }`,
                                                            }}
                                                            onClick={() =>
                                                                setShowImg({
                                                                    show: true,
                                                                    url: `${fileSystemUrl}/${days[currentDay][type].files.storage_name}?token=${user.token}`,
                                                                })
                                                            }
                                                        >
                                                            <div
                                                                className={`program_image ${
                                                                    !(days[currentDay][type].message || days[currentDay][type].options)
                                                                        ? "image_full_width"
                                                                        : ""
                                                                }`}
                                                                style={{
                                                                    backgroundImage: `url(
                                   ${fileSystemUrl}/${days[currentDay][type].files.storage_name}?token=${user.token}
                                )`,
                                                                    backgroundSize: "contain",
                                                                    backgroundPosition: "center",
                                                                    backgroundRepeat: "no-repeat",
                                                                }}
                                                            ></div>
                                                        </div>
                                                    )}
                                                </div>

                                                {days[currentDay][type]?.teacherMessage && (
                                                    <pre>
                                                        {days[currentDay][type]?.teacherMessage !== "" && (
                                                            <div className="teacherMessage">
                                                                <p
                                                                    style={{
                                                                        fontWeight: "600",
                                                                        fontStyle: "italic",
                                                                        marginBottom: "0",
                                                                    }}
                                                                >
                                                                    {t("teachersOnly")}:
                                                                </p>
                                                                <ReadMoreText message={days[currentDay][type]?.teacherMessage} lines={10} />
                                                            </div>
                                                        )}
                                                    </pre>
                                                )}
                                                {days[currentDay][type].url && <UrlPreview url={days[currentDay][type].url} />}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RunProgramModal
                showRun={showRun}
                closeRun={closeRun}
                programDaysLength={days.length}
                user={user}
                programID={query.get("id")}
            />
            <ImagePreviewModal showImg={showImg.show} closeImg={closeImg} url={showImg.url} />
            <ProgramHistoryModal
                showHistory={showHistory}
                closeHistory={closeHistory}
                history={currentHistory}
                program={program}
                message={selectedContent.msg}
                type={selectedContent.type}
                day={selectedContent.day}
                setContentItem={setContentItem}
                programHistory={programHistory}
                setProgramHistory={setProgramHistory}
            />
        </div>
    );
}

export default ContentProgram;
