import React, { useEffect } from 'react';
import '../../styles/CreateOwnContent.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { ReactComponent as ContentTemplateIcon } from '../../assets/svg/ContentTemplateIcon.svg';
import { ReactComponent as OptionRemove } from '../../assets/svg/DeleteContent.svg';
import { ReactComponent as ImageAddIcon } from '../../assets/svg/ImageAddIcon.svg';
import Switcher from './Switcher';
import ReactTooltip from 'react-tooltip';
import TargetGroupModal from '../modals/TargetGroupModal';
import PollScheduleModal from '../modals/PollScheduleModal';
import ContentTemplate from './ContentTemplate';
import { createContent, createTemplate, getAllStudentsTarget, upload, update, sendNotification } from '../../server/server';
import { fileSystemUrl, videoUrl } from '../../constants/Connections';
import { useUser } from '../../auth';
import { format_day, isValidURL, notify, translateTargetText } from '../../utils/util';
import { useHistory } from 'react-router-dom';
import RepeatModal from '../modals/RepeatModal';
import { useSocket } from '../../context/socket';
import {
	MINIMAL_NUMBER_OF_POLL_OPTIONS,
	OPTION_TYPE_DEFAULT,
	REPEAT_CONTENT_INIT,
	SCHEDULE_CONTENT_INIT,
	TARGET_GROUP_INIT,
} from '../../constants/Constants';
import Loading from '../Loading';
import ScheduleExpandedModal from '../modals/ScheduleExpandendModal';
import ScheduleSwitcher from './ScheduleSwitcher';
import i18n from '../../i18n';
import ImageWithPlaceholder from '../dashboard/components/ImageWithPlaceholder';

function CreateOwnContent({ content, type = 'feed', editMode = false }) {
	const { t, locale } = useTranslation();
	const [isTemplateListOpened, setTemplateListOpened] = useState(false);
	const [hoverTemplateButton, setHoverTemplateButton] = useState(false);
	const [editTemplateMode, setEditTemplateMode] = useState(false);
	const [isTemplate, setIsTemplate] = useState(false);
	const [selectedContent, setSelectedContent] = useState(content);
	const clickOnUseTemplateButton = () => {
		setTemplateListOpened(!isTemplateListOpened);
	};

	const selectTemplateToEdit = (content) => {
		setSelectedContent(content);
		setEditTemplateMode(true);
		setTemplateListOpened(false);
	};

	const selectContent = (content) => {
		setIsTemplate(true);
		setSelectedContent(content);
		setTemplateListOpened(false);
		setEditTemplateMode(false);
	};
	useEffect(() => {
		setSelectedContent(content);
	}, [content]);

	const openTemplateList = () => {
		setTemplateListOpened(true);
	};

	return (
		<div>
			{type != 'survey' && (
				<div
					className={`rounded-full py-2 flex justify-center items-center pointer${
						isTemplateListOpened ? ' bg-deepPurple' : ' bg-containerGray'
					}`}
					onClick={clickOnUseTemplateButton}
					onMouseEnter={() => setHoverTemplateButton(true)}
					onMouseLeave={() => setHoverTemplateButton(false)}
				>
					<span
						className={`mr-2 text-base font-semibold ${
							isTemplateListOpened ? 'text-[#FDDF6D]' : hoverTemplateButton === true ? 'text-deepPurple' : 'text-mudPurple'
						}`}
					>
						{t('useTemplate')}
					</span>
					<ContentTemplateIcon fill={isTemplateListOpened ? '#FDDF6D' : hoverTemplateButton === true ? '#5E639B' : '#9392AD'} />
				</div>
			)}
			{isTemplateListOpened ? (
				<ContentTemplate setSelTemplate={selectContent} type={type} selectTemplateToEdit={selectTemplateToEdit} />
			) : (
				<ContentForm
					selectedContent={selectedContent}
					editMode={editMode}
					editTemplateMode={editTemplateMode}
					isTemplate={isTemplate}
					type={type}
					openTemplateList={openTemplateList}
				/>
			)}
		</div>
	);
}

const ContentForm = ({
	selectedContent = {},
	editMode = false,
	isTemplate = false,
	editTemplateMode = false,
	type = 'feed',
	openTemplateList = () => {},
}) => {
	const { t, locale } = useTranslation();
	const { user } = useUser();
	let history = useHistory();
	const { socketStates, socket, changeSocketState } = useSocket();
	const [progress, setProgress] = useState(false);
	const [titleError, setTitleError] = useState(false);
	const [targetError, setTargetError] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const [templateSaved, setTemplateSaved] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [optionsError, setOptionsError] = useState(false);
	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');
	const [link, setLink] = useState('');
	const [image, setImage] = useState(undefined);
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [openTarget, setOpenTarget] = useState(false);
	const [requiredEnabled, setRequiredEnabled] = useState(false);
	const [anonymousEnabled, setAnonymousEnabled] = useState(false);
	const [trackWellbeingEnabled, setTrackWellbeingEnabled] = useState(false);
	const [addedToAnalytics, setAddedToAnalytics] = useState(false);
	const [returnTarget, setReturnTarget] = useState(TARGET_GROUP_INIT);
	const [openSchedule, setOpenSchedule] = useState(false);
	const [returnSchedule, setReturnSchedule] = useState(SCHEDULE_CONTENT_INIT);
	const [returnRepeat, setReturnRepeat] = useState(REPEAT_CONTENT_INIT);
	const [options, setOptions] = useState(['', '', '']);

	useEffect(() => {
		if (!!selectedContent.type && (editMode || isTemplate || editTemplateMode)) {
			setTitle(selectedContent.title);
			setMessage(selectedContent.message);
			setOptions(selectedContent.options);
			setLink(selectedContent.url);
			setRequiredEnabled(!!selectedContent?.required);
			setAnonymousEnabled(!!selectedContent?.anonymous);
			setTrackWellbeingEnabled(!!selectedContent?.trackWellbeing);
			setReturnTarget({
				selection: selectedContent?.targetOptions?.selection ?? [],
				type: selectedContent?.targetOptions?.type ?? 'root',
				target: selectedContent?.target ?? [],
				name: selectedContent?.target?.length > 0 ? selectedContent?.target_text : 'Select target',
			});
			setReturnSchedule({
				enabled: selectedContent?.schedule,
				text: 'On',
				recurrentText: selectedContent?.repeatData?.recurrentText ?? 'Never',
				repeatPeriodText: selectedContent?.repeatData?.repeatPeriodText ?? 'Never',
				repeatEnabled: !!selectedContent?.repeatData?.repeatEnabled,
				schedule_date: selectedContent?.schedule_date ?? 0,
				repeatType: selectedContent?.repeatData?.repeatType ?? 'weekly',
				selectedDays: selectedContent?.repeatData?.selectedDays ?? [],
				selectedDay: selectedContent?.repeatData?.selectedDay ?? 1,
				endOfRecurrenceDate: selectedContent?.repeatData?.endOfRecurrenceDate ?? 0,
			});
			setReturnRepeat(selectedContent?.repeatData ?? REPEAT_CONTENT_INIT);
			if (!!selectedContent?.files) {
				const medialUrl = selectedContent?.files?.type === 'video' ? videoUrl : fileSystemUrl;
				setImage(`${medialUrl}/${selectedContent?.files?.storage_name}?token=${user.token}&width=334`);
			}
		}
	}, [selectedContent]);

	useEffect(() => {
		if (returnTarget?.target?.length < 3) {
			setAnonymousEnabled(false);
		}
	}, [returnTarget?.target]);

	useEffect(() => {
		if (!selectedFile) {
			setImage('');
			return;
		}
		const objectUrl = URL.createObjectURL(selectedFile);
		setImage(objectUrl);
	}, [selectedFile]);

	useEffect(() => {
		setTargetError(false);
	}, [returnTarget]);

	const pressUploadPicture = () => {
		document.getElementById('photo_upload_modal')?.click();
	};
	const closeImage = () => {
		setImage(undefined);
		setSelectedFile(undefined);
	};
	const onImageChange = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		let file = e.target.files[0];
		const type = file.type;
		setIsVideo(type.includes('video'));
		if (file.size > 15728640) {
			alert(t('fileIsToBig'));
			setSelectedFile(undefined);
			return;
		}
		setSelectedFile(file);
	};
	const resetInputValue = (e) => {
		e.target.value = null;
	};
	const setSchedule = () => {
		setReturnSchedule(returnSchedule.enabled ? returnSchedule : SCHEDULE_CONTENT_INIT);
		setOpenSchedule(true);
	};
	const closeTargetModal = () => {
		setOpenTarget(false);
	};
	const closeScheduleModal = () => {
		setOpenSchedule(false);
	};
	const handleOptionInput = (e, index) => {
		const value = e.target.value;
		const newOptions = [...options];
		newOptions.splice(index, 1, value);
		setOptions(newOptions);
	};
	const handleOptionRemove = (index) => {
		const newOptions = [...options];
		newOptions.splice(index, 1);
		setOptions(newOptions?.length > 0 ? newOptions : options);
	};

	const switchAnonymous = (state) => {
		const numberOfTarget = returnTarget?.target?.length ?? 0;
		if (numberOfTarget === 1) {
			notify(t('Cannot create anonymous poll for 1 user'));
			return;
		}
		setTrackWellbeingEnabled(false);
		setAnonymousEnabled(state);
	};

	const switchAddWellbeingTracker = (state) => {
		setTrackWellbeingEnabled(state);
		setAnonymousEnabled(false);
	};

	const prepareData = () => {
		let content = {
			...(selectedContent || {}),
			title,
			message,
			type,
			status: 'start',
			target: returnTarget?.target?.map((e) => e.id ?? e) ?? [],
			target_text: returnTarget?.name,
			institution_id: user.institution_id,
			unfilteredTarget: returnTarget?.target,
			targetOptions: {
				selection: returnTarget?.selection,
				type: returnTarget?.type,
			},
			schedule: returnSchedule?.enabled,
			schedule_date: returnSchedule?.schedule_date === 0 ? null : returnSchedule?.schedule_date,
			required: requiredEnabled,
			anonymous: anonymousEnabled,
			user_id: editMode && selectedContent?.id ? selectedContent?.user_id : user.id,
			target_count: returnTarget.target?.length ?? 0,
			created_date: !editMode ? Date.now() : selectedContent?.created_date,
			trackWellbeing: trackWellbeingEnabled,
			repeatData: {
				repeat: returnRepeat?.repeat,
				recurrentType: returnRepeat?.recurrentType === '' ? null : returnRepeat?.recurrentType,
				selectedDay: returnRepeat?.selectedDay,
				selectedDays: returnRepeat?.selectedDays?.length === 0 ? null : returnRepeat?.selectedDays,
				endOfRecurrenceDate: returnRepeat?.endOfRecurrenceDate === 0 ? null : returnRepeat?.endOfRecurrenceDate,
				repeatPeriodText: returnRepeat?.repeatPeriodText ?? 'Never',
			},
			repeat: returnRepeat?.repeat,
			repeatPeriodText: returnRepeat?.repeatPeriodText ?? 'Never',
			url: isValidURL(link) ? link : '',
		};
		if (type === 'poll') {
			content = {
				...content,
				options: options?.filter((e) => !!e),
				option_type: OPTION_TYPE_DEFAULT,
				addedToAnalytics: addedToAnalytics,
			};
		} else if (type === 'feed') content = { ...content, likes: selectedContent?.likes || 0, views: selectedContent?.views || 0 };
		content.publicationDate = content.schedule ? content.schedule_date : content.created_date;
		if (isTemplate) delete content['id'];
		return content;
	};
	const validate = () => {
		let valid = true;
		if (title === '') {
			setTitleError(true);
			valid = false;
		}
		if (returnTarget?.target?.length === 0) {
			setTargetError(true);
			valid = false;
		}
		if (type === 'poll' && options?.filter((e) => !!e).length < MINIMAL_NUMBER_OF_POLL_OPTIONS) {
			if (options.length < MINIMAL_NUMBER_OF_POLL_OPTIONS) {
				let newArr = [];
				for (let i = 0; i < MINIMAL_NUMBER_OF_POLL_OPTIONS - options.length; i++) {
					newArr.push('');
				}
				setOptions([...options, ...newArr]);
			}
			setOptionsError(true);
		}
		if (type === 'poll' && options?.filter((e) => !!e).length < MINIMAL_NUMBER_OF_POLL_OPTIONS) {
			valid = false;
		}
		setShowErrorMessage(!valid);
		return valid;
	};
	const saveTemplate = async (data) => {
		const resp = await createTemplate({ data: data });
		if (resp.status === 200) {
			setTemplateSaved(true);
			notify(t('templateIsCreated'));
		} else {
			notify(t('templateIsNotCreated'));
		}
	};
	const updateTemplate = async (data) => {
		const resp = await update({ collection: 'template', data: { ...data, id: selectedContent.id } });
		if (resp.status === 200) {
			openTemplateList();
			// notify(t('Template is updated'));
			setTimeout(() => {
				document.getElementById(selectedContent.id)?.scrollIntoView({ behavior: 'smooth' });
			}, 100);
		} else {
			notify(t('templateIsUpdated'));
		}
	};

	const handleSaveTemplate = async () => {
		if (!validate()) return;
		// setProgress(true);
		const content = prepareData();
		var respImage = content?.files;
		if (selectedFile) {
			const req = await upload(selectedFile);
			if (req) respImage = req.data;
			else {
				notify(t('imageIsNotUploaded'));
				return;
			}
		}

		if (editTemplateMode) updateTemplate({ ...content, files: respImage });
		else saveTemplate({ ...content, files: respImage });
	};

	const handlePublish = async () => {
		if (!validate()) return;
		setProgress(true);
		const content = prepareData();
		var respImage = content?.files;
		if (selectedFile) {
			const req = await upload(selectedFile);
			if (req) respImage = req.data;
			else {
				notify(t('imageIsNotUploaded'));
				return;
			}
		}
		if (editMode && content.id) {
			const resp = await createContent({ data: { ...content, files: respImage, id: selectedContent.id } });
			if (resp.status === 200) {
				history.push('/dashboard');
				const data = resp.data;
				if (resp?.data?.status != 'pending') {
					notify(t('yourDataIsUpdated'));
				} else {
					socket?.emit('new_content_reviewing', data);
					sendNotification({ content: data, type: 'toReview' });
					notify(t('contentNeedsToBeReviewedByTheScoolAdmin'));
				}
			} else {
				notify(t('yourDataIsNotUpdated'));
			}
		} else {
			const resp = await createContent({ data: { ...content, files: respImage } });
			if (resp.status === 200) {
				const data = resp.data;
				if (resp?.data?.status !== 'pending') {
					socket?.emit('new_content', data);
					history?.push('/dashboard');
					notify(t('contentIsCreated'));
				} else {
					socket?.emit('new_content_reviewing', data);
					sendNotification({ content: data, type: 'toReview' });
					notify(t('contentNeedsToBeReviewedByTheScoolAdmin'));
				}
			} else {
				notify(t('contentIsNotCreated'));
			}
		}
	};

	const setReturnDataPreStep = (data) => {
		setReturnSchedule(data);
		setReturnRepeat({
			repeatText: data?.recurrentText ?? 'Never',
			repeat: !!data?.repeatEnabled,
			type: data?.repeatType ?? 'weekly',
			selectedDays: data?.selectedDays ?? [],
			selectedDay: data?.selectedDay ?? 1,
			endOfRecurrenceDate: data?.endOfRecurrenceDate ?? 0,
		});
	};

	return (
		<div>
			<div>
				<div className='mt-5'>
					<p className='text-deepPurple font-semibold text-lg text-left' id='headline'>
						{t('headline')}
					</p>
					<input
						className={`w-full text-deepPurple  border py-3 border-x-0 border-t-0 ${
							titleError ? 'border-b-deleteRed placeholder-deleteRed' : 'border-b-deselectPurple '
						}`}
						placeholder={`${titleError ? t('typeHeadlineHere') + '*' : t('typeHeadlineHere')}`}
						style={{ backgroundColor: 'transparent' }}
						onFocus={() => setTitleError(false)}
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
				</div>
				<div className='mt-6'>
					<p className='text-deepPurple font-semibold text-lg text-left'>{t('supportiveText')} </p>
					<textarea
						className='w-full text-deepPurple mt-2  border border-deselectPurple hover:border-deepPurple focus:border-deepPurple p-2 rounded-xl resize-none'
						placeholder={t('typeAnyAdditionalTextHere')}
						rows='3'
						style={{ backgroundColor: 'transparent' }}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
				</div>

				<div className='mt-6'>
					<p className='text-deepPurple font-semibold text-lg text-left'>{t('enterLinkOptional')} </p>
					<input
						className='w-full text-deepPurple mt-2  border border-deselectPurple p-3 rounded-xl resize-none'
						placeholder={t('typeAnyAdditionalTextHere')}
						style={{ backgroundColor: 'transparent' }}
						value={link}
						onChange={(e) => setLink(e.target.value)}
					/>
				</div>
			</div>
			{type === 'poll' && (
				<div>
					<div className='bg-whiteContrast rounded-xl px-4 mt-5 pb-5'>
						{options.map((_, i) => {
							const showError = optionsError && options[i] === '';
							return (
								<div key={i}>
									<p className={`text-mudPurple font-semibold text-sm text-left py-3`}>
										{t('addOption')} {i + 1}
									</p>
									<div
										className={`flex flex-row items-center justify-between border border-deselectPurple hover:border-mudPurple rounded-xl py-3 px-4 ${
											showError && 'border-[#FF747F]'
										}`}
										key={i}
									>
										<input
											type='text'
											style={{ backgroundColor: 'transparent' }}
											className={`w-full text-deepPurple text-base ${showError && 'placeholder-deleteRed'}`}
											value={options[i]}
											onInput={(e) => handleOptionInput(e, i)}
											// placeholder={showError ? `Write option ${i + 1} here*` : `Write option ${i + 1} here`}
											placeholder={showError ? t('writeOption1Here') + '*' : t('writeOption1Here')}
											onFocus={() => {
												setOptionsError(false);
											}}
										/>
										{options.length > MINIMAL_NUMBER_OF_POLL_OPTIONS && (
											<div
												onClick={() => handleOptionRemove(i)}
												className='pointer'
												data-tip={t('deleteOption')}
												data-for='poll-option-remove'
											>
												<OptionRemove fill='#FFA28D' />
												<ReactTooltip
													id='poll-option-remove'
													arrowColor='#5E639B'
													effect='solid'
													backgroundColor='#5E639B'
													className='tooltip-poll'
												/>
											</div>
										)}
									</div>
								</div>
							);
						})}
						<div
							className='mt-5 bg-mudPurple hover:bg-deepPurple pointer rounded-xl flex justify-center text-white font-semibold text-base items-center py-3 px-4'
							style={{ width: '100%' }}
							onClick={() => setOptions([...options, ''])}
						>
							{t('addOption')}
						</div>
					</div>
				</div>
			)}
			<div className='mt-6 flex flex-row justify-between gap-[20px]'>
				<div className='course-media-container' style={{ alignItems: 'center' }}>
					{!image && (
						<div className='flex flex-col items-center justify-center bg-containerGray rounded-xl py-8 w-[334px]'>
							<ImageAddIcon style={{ width: 42, height: 42, color: '#8484b1' }} />
							<p className='text-mudPurple text-base w-[160px] font-[500] text-center'>
								{t('The maximum image size for download is 1400px x 1400px')}
							</p>
							<button
								className='text-deepPurple hover:text-darkenPurple rounded-full bg-lightBG py-1 font-semibold text-lg px-5 mt-3'
								onClick={(e) => {
									e.preventDefault();
									pressUploadPicture();
								}}
							>
								{t('addPhotoVideo')}
							</button>
						</div>
					)}
					{image && (
						<div className='flex relative max-h-[244px] flex-col items-center justify-center bg-containerGray rounded-xl py-8 w-[334px]'>
							<div
								onClick={closeImage}
								style={{
									padding: 10,
									borderRadius: 20,
									backgroundColor: 'white',
									opacity: 0.4,
									cursor: 'pointer',
								}}
								className='absolute top-0 right-0'
							>
								<FaTimes
									style={{
										width: 20,
										height: 20,
										stroke: 'white',
										display: 'block',
									}}
								/>
							</div>
							{isVideo ? (
								<div className='simage-container-new'>
									<video controls controlsList='nodownload'>
										<source src={image} />
										{t('yourBrowserDoesNotSupportHTMLVideo')}
									</video>
								</div>
							) : (
								<img
									style={{
										objectFit: 'contain',
										width: '100%',
										minHeight: 170,
										marginTop: 10,
									}}
									src={image}
									alt=''
								/>
							)}
						</div>
					)}
					<input
						title='Add Video/Photo'
						style={{ display: 'none' }}
						accept='video/*, image/*'
						type='file'
						id='photo_upload_modal'
						onChange={onImageChange}
						onClick={resetInputValue}
					/>
				</div>
				<div className='flex flex-col min-w-[334px]'>
					<div className='flex flex-row justify-between items-center mb-3 h-[40px]'>
						<p className={`text-mudPurple text-lg font-medium ${targetError ? 'text-[#FF747F]' : ''}`}>
							{t('targetGroup')}
							{targetError ? '*' : ''}
						</p>
						<div
							className={`text-lg text-mudPurple hover:text-deepPurple font-semibold rounded-full py-1 px-4 bg-containerGray pointer ${
								targetError ? 'text-[#FF747F]' : ''
							}
							${returnTarget?.target?.length > 0 ? 'text-deepPurple' : ''}
							
							`}
							onClick={() => setOpenTarget(true)}
						>
							{translateTargetText(returnTarget?.name)}
						</div>
					</div>
					<div className='flex flex-row justify-between items-center my-[7px] h-[40px]'>
						<p className='text-mudPurple text-lg font-medium'>{t('schedule')}</p>
						<div className='flex flex-row justify-between items-center my-3 h-[40px]'>
							<ScheduleSwitcher
								customOnTextEnabled={returnSchedule?.enabled}
								customOnText={format_day(returnSchedule?.schedule_date, true, i18n.language)}
								setSwitcher={setSchedule}
								switcher={returnSchedule?.enabled}
							/>
						</div>
					</div>
					{returnRepeat?.repeat && (
						<div className={`flex flex-row justify-between items-center my-3 h-[40px]`}>
							<p className='text-mudPurple text-lg font-medium'>{t('repeat')}</p>
							<div
								className='pointer flex flex-row justify-between items-center my-[7px] min-h-[40px] max-w-[210px]  px-[15px] bg-containerGray rounded-full'
								onClick={setSchedule}
							>
								<span className='font-semibold text-lg text-[#5E639B] leading-[20px] text-center'>
									{returnRepeat?.repeatText}
								</span>
							</div>
						</div>
					)}
					{type !== 'feed' && (
						<div className='flex flex-row justify-between items-center my-[7px] h-[40px]'>
							<p className='text-mudPurple  text-lg font-medium'>{t('addToWellbeingTracker')} </p>

							<Switcher setSwitcher={switchAddWellbeingTracker} switcher={trackWellbeingEnabled} />
						</div>
					)}
					{type !== 'feed' && (
						<div className='flex flex-row justify-between items-center my-[7px] h-[40px]'>
							<p className='text-mudPurple text-lg font-medium'>{t('anonymous')} </p>

							<Switcher setSwitcher={switchAnonymous} switcher={anonymousEnabled} />
						</div>
					)}
					{type === 'poll' && !!returnSchedule?.repeatEnabled && (
						<div className='flex flex-row justify-between items-center my-[7px] h-[40px]'>
							<p className='text-mudPurple text-lg font-medium'>{t('addToAnalytics')}</p>

							<Switcher setSwitcher={setAddedToAnalytics} switcher={addedToAnalytics} />
						</div>
					)}
				</div>
			</div>
			<div className='mt-2'>
				<p className={`text-[#FF747F] font-medium text-[14px] text-center ${showErrorMessage ? '' : 'opacity-0'}`}>
					*{t('pleaseFillRequiredFields')}
				</p>
			</div>
			<div className={`flex flex-row ${editTemplateMode ? 'justify-center' : 'justify-between'} mt-2`}>
				<button
					className={`${
						!templateSaved ? 'hover:bg-lightBG pointer ' : 'text-[#BEBECE] border-[#BEBECE]'
					} !border !border-solid border-mudPurple  rounded-full py-3 flex min-w-[334px] justify-center text-deepPurple deepPurple font-semibold text-lg`}
					disabled={templateSaved}
					onClick={handleSaveTemplate}
				>
					{editTemplateMode ? t('updateTemplate') : templateSaved ? t('templateIsSaved') : t('saveAsTemplate')}
				</button>
				{!editTemplateMode && (
					<button
						className='pointer rounded-full py-3 flex justify-center min-w-[334px] bg-deepPurple text-highlightColor hover:bg-highlightColor hover:text-deepPurple  deepPurple font-semibold text-lg'
						onClick={handlePublish}
						disabled={progress}
					>
						{progress ? <Loading /> : editMode && selectedContent.id ? t('update') : t('publish')}
					</button>
				)}
			</div>
			<ScheduleExpandedModal
				showSchedule={openSchedule}
				closeSchedule={closeScheduleModal}
				data={returnSchedule}
				setReturnSchedule={setReturnDataPreStep}
			/>
			<TargetGroupModal showTarget={openTarget} closeTarget={closeTargetModal} setReturnTarget={setReturnTarget} />
		</div>
	);
};

export default CreateOwnContent;
const l_I_t = [{ test: 'test' }];
