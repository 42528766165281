import React, { useState, useEffect, useContext } from 'react';
import { FaChevronLeft, FaChevronRight, FaHistory } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import '../../../styles/content/ContentProgram.css';
import { fileSystemUrl } from '../../../constants/Connections';
import { useUser } from '../../../auth';
import { getById, getProgramHistory } from '../../../server/server';
import RunProgramModal from '../../modals/RunProgramModal';
import UrlPreview from '../../feedsAndDashboard/UrlPreview';
import ImagePreviewModal from '../../modals/ImagePreviewModal';
import ProgramHistoryModal from '../../modals/ProgramHistoryModal';
import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';
import ReadMoreText from '../../ReadMoreText';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ReactComponent as TargetUndo } from '../../../assets/svg/undoTarget.svg';
import { ReactComponent as LastCreated } from '../../../assets/svg/lastCreated.svg';
import { ContentContext } from '../ContentContext';
import ShowMoreText from 'react-show-more-text';

function SingleProgram({ programID, setScreen }) {
	let history = useHistory();
	const { t, locale } = useTranslation();
	const [contentItem, setContentItem] = useContext(ContentContext);
	const [hoveredButton, setHoveredButton] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const { user, setUser } = useUser();
	const [program, setProgram] = useState({ name: '', description: '' });
	const [currentDay, setCurrentDay] = useState(0);
	const [loading, setLoading] = useState(false);
	const [days, setDays] = useState([{ poll: {}, question: {}, feed: {} }]);
	const [showRun, setShowRun] = useState(false);
	const [showImg, setShowImg] = useState({ show: false, url: '' });
	const [programHistory, setProgramHistory] = useState([]);
	const [historyID, setHistoryID] = useState([]);
	const [showHistory, setShowHistory] = useState(false);
	const [currentHistory, setCurrentHistory] = useState([]);
	const [selectedContent, setSelectedContent] = useState({
		msg: '',
		type: '',
		day: 0,
	});

	const getProgram = async (ProgramID) => {
		const response = await getById({ collection: 'programs', id: ProgramID });
		if (response.status === 200) {
			const { name, description, days, id } = response.data;
			setProgram({ name, description, id });
			setDays(days);
			document.getElementById('programName')?.scrollIntoView({ behavior: 'smooth' });
		} else {
			setProgram({});
			setDays([]);
		}
	};
	useEffect(() => {
		setLoading(true);
		getProgram(programID);
	}, [loading]);
	useEffect(() => {
		console.log(currentDay, days[currentDay]);
	}, [currentDay]);
	const closeRun = () => {
		setShowRun(false);
	};
	const closeImg = () => {
		setShowImg({ show: false, url: '' });
	};
	const getHistory = async () => {
		const response = await getProgramHistory({
			programId: programID,
			institution_id: user?.institution_id,
		});
		console.log('aaaaaaaaaa', {
			programId: programID,
			institution_id: user?.institution_id,
		});
		if (response.status === 200) {
			console.log('ttt', response.data);
			setHistoryID(response.data);
			let tempHistory = response.data;
			setProgramHistory(
				tempHistory
					.reverse()
					.filter(
						(thing, index, self) =>
							index ===
							self.findIndex((t) => t.programDetails?.dayIndex === thing.programDetails?.dayIndex && t?.type === thing?.type)
					)
			);
		} else {
			setProgramHistory([]);
			setHistoryID([]);
		}
	};
	useEffect(() => {
		getHistory();
	}, []);
	const closeHistory = () => {
		setShowHistory(false);
	};
	const handleHistory = (msg, type, day) => {
		setCurrentHistory(historyID);
		setShowHistory(true);
		setSelectedContent({ msg: msg, type: type, day: day });
	};
	const handleSelect = (content, day) => {
		setContentItem({ ...content, programDetails: { dayIndex: day, programId: programID } });
		history.push(`/content?type=${content?.type}&singleProgram=true`);
	};
	return (
		<div className='px-14 p-8' id='programName'>
			<div>
				<div className='flex items-center justify-between'>
					<div className='flex '>
						<div
							className=' flex pointer mr-5 items-center justify-center bg-[#5E639B] hover:bg-[#413F5E]  min-w-[56px] w-[56px] h-[56px] rounded-full'
							onClick={() => setScreen('home')}
							onMouseEnter={() => setHoveredButton(true)}
							onMouseLeave={() => setHoveredButton(false)}
						>
							<TargetUndo fill={hoveredButton ? '#FDDF6D' : '#FFFFFF'} />
						</div>
						<h1 className='font-bold text-deepPurple text-[40px]'>{program?.name}</h1>
					</div>

					<div
						className='text-highlightColor hover:text-deepPurple bg-deepPurple hover:bg-highlightColor rounded-full text-lg font-semibold pointer w-[300px] h-[65px] flex items-center justify-center'
						onClick={() => setShowRun(true)}
					>
						{t('runProgram')}
					</div>
				</div>
				<div>
					<pre className='text-[#413F5E] text-base text-left mt-5 mb-5 whitespace-pre-wrap'>{program?.description}</pre>
				</div>
			</div>
			<div className='program_body_full' style={{ '&::-webkit-scrollbar': { height: '10px' } }}>
				<div className='program_days' style={{ display: 'block' }}>
					<p className='text-left text-deepPurple font-bold text-lg mb-[11px]'>{t('listOfDays')} </p>
					<div className='flex overflow-x-auto w-full justify-between space-x-2.5 pb-5 items-center horizontal-grey-small-scroll'>
						{days.map((d, index) => {
							return (
								<div
									className={`rounded-[10px] pointer text-sm p-[10px] font-semibold flex-1 flex items-center justify-center whitespace-nowrap ${
										index === currentDay
											? 'bg-deepPurple text-highlightColor'
											: 'bg-lightBG hover:bg-deselectPurple text-deepPurple'
									}`}
									key={index}
									onClick={() => setCurrentDay(index)}
								>
									{t('day')} {(index + 1).toString()}
								</div>
							);
						})}
					</div>
				</div>
				<div className='selected_program_day'>
					{Object.keys(
						Object.assign(
							{
								poll: null,
								question: null,
								feed: null,
							},
							days[currentDay]
						)
					).map((type, index) => {
						return (
							<div
								className={`bg-containerGray rounded-xl p-8 flex justify-between relative w-[32%] flex-col ${
									i18n.language == 'ar' ? 'text-right' : ''
								}`}
								key={index}
							>
								<div className='widget_content mb-3'>
									<div
										className='w-full h-[40px] pointer border flex items-center justify-center border-deselectPurple hover:border-deepPurple rounded-full text-deepPurple text-sm font-semibold'
										onClick={() => {
											handleSelect(days[currentDay][type], currentDay);
										}}
									>
										{console.log(days[currentDay][type])}
										{t('postIndividually')}
									</div>

									{programHistory.map((program, index) => {
										if (program.programDetails.dayIndex === +currentDay && program.type === type) {
											console.log('radi', program.programDetails.dayIndex, +currentDay, program.type, type);
											return (
												<div
													className='ml-2  pointer min-w-[40px] h-[40px] flex justify-center items-center rounded-full bg-mudPurple'
													data-tip={`${t('lastCreated')} ${type}: ${format(program.created_date, 'MM/dd/yyyy')}`}
													data-for='historyModal'
													onClick={() => {
														handleHistory(days[currentDay][type].message, type, +currentDay);
													}}
													key={index}
												>
													<LastCreated />
													<ReactTooltip
														id='historyModal'
														arrowColor='transparent'
														effect='solid'
														backgroundColor='rgb(97,97,97)'
														className='tooltip-small'
													/>
												</div>
											);
										} else {
											console.log('neradi', program.programDetails.dayIndex, +currentDay, program.type, type);
											return '';
										}
									})}
								</div>
								{days[currentDay][type].files && (
									<div
										className='w-full mb-3 rounded-xl'
										onClick={() =>
											setShowImg({
												show: true,
												url: `${fileSystemUrl}/${days[currentDay][type].files.storage_name}?token=${user.token}`,
											})
										}
									>
										<div
											className='w-full rounded-xl h-[226px] pointer'
											style={{
												backgroundImage: `url(
                                   ${fileSystemUrl}/${days[currentDay][type].files.storage_name}?token=${user.token}
                                )`,
												backgroundSize: 'contain',
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
											}}
										></div>
									</div>
								)}
								<div
									className='widget_header '
									style={{
										display: 'flex',
										flexDirection: 'column',
										flex: 0,
									}}
								>
									<h4 className='text-left text-deepPurple text-xl font-bold mb-3'>{days[currentDay][type]?.title}</h4>
									<div id='dot-line-program'></div>
								</div>
								<div className='flex flex-1'>
									<div
										className={`w-full ${
											!(days[currentDay][type].message || days[currentDay][type].options) ? 'hidden' : ''
										}`}
									>
										<div className='w-full'>
											{days[currentDay][type]?.message && (
												<pre className='w-full text-left text-mudPurple text-sm mb-3 whitespace-pre-wrap'>
													{days[currentDay][type]?.message !== '' && (
														<ReadMoreText
															message={days[currentDay][type]?.message}
															lines={10}
															className='w-full'
														/>
													)}
												</pre>
											)}
											{days[currentDay][type].options && (
												<div>
													<p className='text-left font-bold text-deepPurple text-base flex justify-start'>
														{t('pickOneOption')}
													</p>
													{days[currentDay][type].options.map((option, index) => {
														return (
															<div
																key={index}
																style={{ padding: '3px 0px' }}
																className='text-left flex items-center text-mudPurple text-sm'
															>
																<span
																	style={{
																		paddingRight: '10px',
																	}}
																>
																	<div className='w-[12px] h-[12px] rounded-full border-2 border-deepPurple'></div>
																</span>
																{option}
															</div>
														);
													})}
												</div>
											)}
										</div>
									</div>
								</div>

								{days[currentDay][type]?.teacherMessage && (
									<pre>
										{days[currentDay][type]?.teacherMessage !== '' && (
											<div className='teacherMessage text-left text-sm text-mudPurple'>
												<p className='text-base font-bold text-deepPurple text-left'>{t('teachersOnly')}:</p>
												<ReadMoreText message={days[currentDay][type]?.teacherMessage} lines={10} />
											</div>
										)}
									</pre>
								)}
								{days[currentDay][type].url && <UrlPreview url={days[currentDay][type].url} />}
							</div>
						);
					})}
				</div>
			</div>
			<RunProgramModal
				showRun={showRun}
				closeRun={closeRun}
				programDaysLength={days.length}
				programName={program?.name}
				user={user}
				programID={programID}
			/>
			<ImagePreviewModal showImg={showImg.show} closeImg={closeImg} url={showImg.url} />
			<ProgramHistoryModal
				showHistory={showHistory}
				closeHistory={closeHistory}
				history={currentHistory}
				program={program}
				message={selectedContent.msg}
				type={selectedContent.type}
				day={selectedContent.day}
				programHistory={programHistory}
				setContentItem={setContentItem}
				setProgramHistory={setProgramHistory}
			/>
		</div>
	);
}

export default SingleProgram;
