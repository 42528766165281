import React, { useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/FeedBackModal.css';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { emotions_heads, emotions_list } from '../../constants/Constants';
import { createMonitoringGroup, getYearsForTeacher } from '../../server/server';
import Loading from '../Loading';
import { notify } from '../../utils/util';
import { useTranslation } from 'react-i18next';

function MonitoringCreateGroupModal({ modalIsOpen, closeModal, targetGroup = {}, selectTargetGroup = () => {} }) {
	const [text, setText] = useState('');
	const [selectedEmotionsList, setSelectedEmotionsList] = useState([]);
	const [selectedYearFilter, setSelectedYearFilter] = useState('all');
	const [textError, setTextError] = useState(false);
	const [emotionsError, setEmotionsError] = useState(false);
	const [process, setProcess] = useState(false);
	const [years, setYears] = useState([]);
	const { t } = useTranslation();

	const isNew = !targetGroup.id;
	const selectYearFilter = (year) => {
		setSelectedYearFilter(year);
	};

	const getYears = async () => {
		const resp = await getYearsForTeacher();
		if (resp.status == 200) {
			const data = resp.data;
			setYears(Array.isArray(data) ? data : [data]);
		}
	};

	const selectEmotion = (emotion) => {
		setEmotionsError(false);
		if (selectedEmotionsList.includes(emotion)) {
			let newArray = selectedEmotionsList.filter((e) => e !== emotion);
			setSelectedEmotionsList(newArray);
			return;
		}
		if (!selectedEmotionsList.includes(emotion)) {
			setSelectedEmotionsList((prev) => [...prev, emotion]);
		}
	};

	const validate = () => {
		setTextError(text == '');
		setEmotionsError(selectedEmotionsList.length == 0);
		return text != '' && selectedEmotionsList.length !== 0;
	};

	const submit = async () => {
		if (!validate()) return;
		setProcess(true);
		const data = {
			...targetGroup,
			emotions: selectedEmotionsList,
			years: selectedYearFilter,
			name: text,
		};

		const resp = await createMonitoringGroup(data);
		if (resp.status === 200) {
			setProcess(false);
			selectTargetGroup(resp.data);
			notify(`${isNew ? t('createdNewMonitoringGroup') : t('updatedMonitoringGroup')} `);
			closeModal();
		}
	};
	useEffect(() => {
		getYears();
	}, []);

	React.useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
			setText(targetGroup?.name ?? '');
			setSelectedEmotionsList(targetGroup?.emotions ?? []);
			setSelectedYearFilter(targetGroup?.year ?? 'all');
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen, targetGroup]);
	return (
		<ReactModal
			transparent
			isOpen={modalIsOpen}
			ariaHideApp={false}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				setSelectedEmotionsList([]);
				setSelectedYearFilter('all');
				setText('');
				setTextError(false);
				setEmotionsError(false);
			}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 99,
				},
				content: {
					inset: 0,
					padding: 0,
					margin: 0,
					marginTop: 30,
					opacity: 1,
					width: 620,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '92vh',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className='day-reflection-modal-icon' onClick={closeModal}>
					<CloseModalIcon />
				</div>
			</div>
			<div className='mt-[30px] rounded-[10px] bg-whiteContrast '>
				<div className='pt-[49px] pb-[22px] rounded-[10px] bg-lightBG'>
					<p className='text-[20px] font-bold leading-[25px] text-deepPurple text-center'>
						{isNew ? t('createGroup') : t('editGroup')}
					</p>
				</div>
				<div className='max-h-[calc(92vh-126px)] overflow-auto grey-small-scroll'>
					<div className='p-10 w-full flex flex-col'>
						<p className='text-[16px] font-semibold leading-[20px] text-deepPurple'>{t('name')} </p>
						<div className={`border-b ${textError ? 'border-b-deleteRed' : 'border-b-deselectPurple'}`}>
							<input
								placeholder={t('nameANewGroup')}
								value={text}
								onChange={(e) => {
									setText(e.target.value);
									setTextError(false);
								}}
								className={`text-[16px] font-medium leading-[20px] text-darkenPurple ${
									textError ? 'placeholder-deleteRed' : 'placeholder-mudPurple'
								} pt-[18px] pb-[14px] w-full bg-whiteContrast`}
								type='text'
							/>
						</div>
						<p
							className={`text-[16px] font-semibold leading-[20px] ${
								emotionsError ? 'text-deleteRed' : 'text-deepPurple'
							} mt-10 `}
						>
							{t('chooseEmotions')}
						</p>
						<div className='grid grid-rows-4 grid-flow-col gap-4 mt-5'>
							{emotions_list.map((emotion, index) => (
								<div className=' flex ' key={index}>
									<div
										className='flex flex-row items-center gap-[10px]  cursor-pointer'
										onClick={() => selectEmotion(emotion)}
									>
										<div className='w-[14px] h-[14px] border-[2px] border-deselectPurple  rounded-full flex items-center justify-center'>
											{selectedEmotionsList.includes(emotion) && (
												<div className='w-[6px] h-[6px] rounded-full bg-deepPurple '></div>
											)}
										</div>
										<img src={emotions_heads[emotion]} width={35} height={35} alt='' />
										<p
											className={`text-darkenPurple text-[16px] leading-[20px] font-semibold capitalize noselect ${
												selectedEmotionsList.includes(emotion) ? 'opacity-100' : 'opacity-50 hover:opacity-100'
											}`}
										>
											{t(emotion)}
										</p>
									</div>
								</div>
							))}
						</div>
						<div className='mt-10'>
							<p className='text-deselectPurple text-[16px] leading-[20px] font-semibold  noselect'>{t('years')} </p>
							<div className='overflow-auto flex flex-row gap-[10px] py-[10px] blue-small-scroll-horizontal noselect'>
								{[t('all'), ...years]?.map((item, index) => (
									<div
										key={index}
										className={`min-w-[40px] h-10 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
											selectedYearFilter === item ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
										}`}
										onClick={() => selectYearFilter(item)}
										style={{}}
									>
										<p className='text-[16px] leading-[20px] font-semibold whitespace-nowrap'>{item}</p>
									</div>
								))}
							</div>
						</div>
						<div className='w-full mt-[60px]'>
							<div
								className='flex items-center justify-center bg-deepPurple h-[60px] w-[280px] m-auto cursor-pointer rounded-full'
								onClick={submit}
							>
								{process ? (
									<Loading />
								) : (
									<p className='text-[18px] leading-[22px] font-semibold text-highlightColor'>{t('continue')} </p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default MonitoringCreateGroupModal;
