import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { emotions_heads } from '../../constants/Constants';

function BarChartClassroom({
	resultDataChart = [],
	// maxValue,
	handleSelectedLabel = () => {},
	height = 248,
	showStripes = true,
	showCursor = true,
	showMash = true,
	showEmotions = false,
	emotionSize = 30,
}) {
	const { t, locale } = useTranslation();
	const [hoverNegativeEmotions, setHoverNegativeEmotions] = useState(-1);
	const allValues = resultDataChart?.map((e) => e?.value ?? 0);
	const maxValue = Math.ceil(Math.max(...allValues, 1));
	const horizontalLines = Array(Math.min(10, maxValue + 1))?.fill('1');

	return (
		<div style={{ width: '100%' }}>
			<div
				className='classroom-barchart-container'
				style={{ position: 'relative', height, borderWidth: showMash ? 1 : 0, borderBottomWidth: 1 }}
			>
				{showMash && (
					<>
						<div className='classroom-barchart-grid'>
							{resultDataChart?.map((item, index) => (
								<div
									className='classroom-barchart-grid-item '
									style={{ width: !!resultDataChart?.length ? `${(1 / resultDataChart?.length) * 100}%` : '11.11%' }}
									key={index}
								>
									<div style={{ height, width: 1, backgroundColor: '#ECEBF8' }}></div>
								</div>
							))}
						</div>
						<div className='barchart-grid-horizontal' style={{ position: 'absolute', top: 0, left: 0, zindex: 2 }}>
							{horizontalLines?.map((item, index) => (
								<div
									className='barchart-grid-horizontal-items'
									style={{ height: (1 / Math.min(10, maxValue + 1)) * height }}
									key={index}
								>
									<div style={{ height: 1, width: '100%', backgroundColor: '#ECEBF8' }}></div>
								</div>
							))}
						</div>
					</>
				)}
				<div className='classroom-barchart-grid' style={{ position: 'absolute', top: 0, left: 0, zIndex: 3 }}>
					{resultDataChart?.map((item, index) => {
						const emotionColor = item.color.substring(0, 7);
						return (
							<div
								className='classroom-barchart-grid-item '
								style={{
									width: !!resultDataChart?.length ? `${(1 / resultDataChart?.length) * 100}%` : '11.11%',
									height,
								}}
								key={index}
							>
								<div className='barchart-item-value'>
									<p>{item.value}</p>
								</div>
								<div
									onClick={() => {
										if (item.value == 0) return;
										handleSelectedLabel(item.label);
									}}
									onMouseEnter={() => {
										setHoverNegativeEmotions(index);
									}}
									onMouseLeave={() => {
										setHoverNegativeEmotions(-1);
									}}
									style={{
										height: (height * item.value) / (maxValue + 1),
										width: '85%',
										borderTopRightRadius: 6,
										borderTopLeftRadius: 6,
										background:
											index > 4 && showStripes
												? `repeating-linear-gradient(
													-45deg,
													${item.color.length === 7 ? emotionColor + 'ff' : hoverNegativeEmotions === index ? emotionColor + 'df' : emotionColor + 'b0'},
													${item.color.length === 7 ? `${emotionColor}FF 7px` : hoverNegativeEmotions === index ? `${emotionColor}DF 7px` : `${emotionColor}B0 7px`},
													${emotionColor}90 2px,
													${emotionColor}90 13px
									  )`
												: item.color,
										border: `3px solid ${hoverNegativeEmotions === index ? emotionColor : item.color}`,
										cursor: showCursor ? 'pointer' : 'default',
									}}
								></div>
							</div>
						);
					})}
				</div>
			</div>
			<div className='classroom-barchart-grid' style={{ marginTop: 9, display: 'flex', flexDirection: 'row', flex: 1 }}>
				{resultDataChart?.map((item, index) => {
					const opacity = item.selected ? 1 : hoverNegativeEmotions === index ? 0.87 : 0.4;
					return (
						<div
							// className='classroom-barchart-grid-item '
							// style={{ width: !!resultDataChart.length ? `${(1 / resultDataChart.length) * 100}%` : '11.11%' }}
							className={showCursor ? 'pointer' : ''}
							key={index}
							style={{ flex: 1, overflow: 'hidden' }}
							onClick={() => {
								if (item.value === 0) return;
								handleSelectedLabel(item.label);
							}}
						>
							<div
								className='classroom-barchart-label'
								style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
							>
								{t(item.label)}
							</div>
							{showEmotions && (
								<div className='items-center justify-center flex mt-[5px]' style={{ opacity: showCursor ? opacity : 1 }}>
									<img
										src={emotions_heads[item.label]}
										alt='emotion'
										width={emotionSize}
										height={emotionSize}
										className='self-center'
									/>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default BarChartClassroom;
