import React from 'react';
import { emotions_color, emotions_heads } from '../../constants/Constants';
import '../../styles/analytics/GenderBarChart.css';
import { useTranslation } from 'react-i18next';

function GenderBarChart({ resultDataChart = [], height = 248, presetMaxValue = false, showEmotions = false }) {
	const boysValues = resultDataChart.map((e) => e?.boysValue) ?? 1;
	const girlsValues = resultDataChart.map((e) => e?.girlsValue) ?? 1;
	const maxResult = Math.max(Math.max(...boysValues), Math.max(...girlsValues), 1) ?? 1;
	const HORIZONTAL_STEP = 10;
	const mathRemain = maxResult % HORIZONTAL_STEP;
	const maxValue = !!presetMaxValue ? presetMaxValue : maxResult + HORIZONTAL_STEP - mathRemain; // Round to next decade
	const horizontalLines = Math.floor(Math.max(Math.min(maxValue, 100), 1) / HORIZONTAL_STEP);
	const { t } = useTranslation();
	return (
		<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height, marginBottom: showEmotions ? 20 : 0 }}>
			<div
				style={{
					width: 44,
					minWidth: 35,
					display: 'flex',
					flexDirection: 'column-reverse',
					alignItems: 'center',
				}}
			>
				{Array(horizontalLines)
					.fill()
					?.map((item, index) => (
						<div
							className='gender-grid-horizontal-items'
							style={{
								height: (1 / horizontalLines) * height,
							}}
							key={index}
						>
							<p className='gender-value-text'>{`${(index + 1) * HORIZONTAL_STEP}%`}</p>
						</div>
					))}
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', flex: 1, height }}>
				<div className='gender-barchart-container' style={{ position: 'relative', height, width: '100%' }}>
					<div className='gender-barchart-grid'>
						{resultDataChart?.map((item, index) => (
							<div
								className='gender-barchart-grid-item '
								style={{
									width: !!resultDataChart.length ? `${(1 / resultDataChart.length) * 100}%` : '11.11%',
									alignItems: 'center',
								}}
								key={index}
							>
								<div
									style={{ height, width: resultDataChart.length === index + 1 ? 0 : 2, backgroundColor: '#ECEBF8' }}
								></div>
							</div>
						))}
					</div>
					<div className='gender-grid-horizontal' style={{ position: 'absolute', top: 0, left: 0, zindex: 2 }}>
						{Array(horizontalLines)
							.fill()
							?.map((item, index) => (
								<div
									className='gender-grid-horizontal-items'
									style={{
										height: (1 / horizontalLines) * height,
									}}
									key={index}
								>
									<div
										style={{
											height:
												maxValue > 90 && index === 0 ? 0 : maxValue > 80 && index === 0 ? 0 : index === 0 ? 0 : 1,
											width: '100%',
											backgroundColor: '#ECEBF8',
										}}
									></div>
								</div>
							))}
					</div>
					<div className='gender-barchart-grid' style={{ position: 'absolute', top: -1, left: 0, zIndex: 3 }}>
						{resultDataChart?.map((item, index) => (
							<div
								className='gender-barchart-item '
								style={{
									width: !!resultDataChart.length ? `${(1 / resultDataChart.length) * 100}%` : '11.11%',
									height,
								}}
								key={index}
							>
								<div className='flex flex-col-reverse'>
									<div
										className='gender-boy-item'
										style={{ width: 24, height: (height * item.boysValue) / (maxValue + 1) }}
									>
										{item.boysValue >= 90 && (
											<div className='text-[#FFFFFF] text-[12px] font-medium text-center mt-[3px]'>
												{item.boysValue}
											</div>
										)}
									</div>
									{item.boysValue < 90 && (
										<div className='text-[#413F5E] text-[12px] font-medium text-center mb-[3px]'>{item.boysValue}</div>
									)}
								</div>
								<div style={{ width: 4 }}></div>
								<div className='flex flex-col-reverse'>
									<div
										className='gender-girl-item'
										style={{ width: 24, height: (height * item.girlsValue) / (maxValue + 1) }}
									>
										{item.girlsValue >= 90 && (
											<div className='text-[#FFFFFF] text-[12px] font-medium text-center mt-[3px]'>
												{item.girlsValue}
											</div>
										)}
									</div>
									{item.girlsValue < 90 && (
										<div className='text-[#413F5E] text-[12px] font-medium text-center mb-[3px]'>{item.girlsValue}</div>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className='classroom-barchart-grid' style={{ marginTop: 9 }}>
					{resultDataChart?.map((item, index) => (
						<div
							className='classroom-barchart-grid-item '
							style={{ width: !!resultDataChart.length ? `${(1 / resultDataChart.length) * 100}%` : '11.11%' }}
							key={index}
						>
							<div className='classroom-barchart-label'>{t(item.label)}</div>
							{showEmotions && (
								<div className='items-center justify-center flex mt-[5px]'>
									<img src={emotions_heads[item.label]} alt='emotion' width={30} height={30} className='self-center' />
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default GenderBarChart;
