import React from 'react';
import { emotions_color, emotions_heads, mainReasonList, mainReasonListIcons } from '../../../constants/Constants';
import { Oval } from 'react-loader-spinner';
import Loading from '../../Loading';
import { useTranslation } from 'react-i18next';

function SelectReason({ selectedEmotion, submitEmotion, answer_text, setAnswerText, reasonsList, changeReasonsList, sendingEmotion }) {
	const { t, locale } = useTranslation();
	return (
		<div className='bg-whiteContrast mt-[30px]  w-[620px] rounded-[10px]'>
			<div>
				<div
					className='flex flex-row-reverse items-center justify-between gap-[14px] py-[27px] px-[40px] rounded-[10px] '
					style={{ backgroundColor: `${emotions_color[selectedEmotion]}CC` }}
				>
					<div>
						{!!selectedEmotion && <img src={emotions_heads[selectedEmotion]} alt='emotion' className={`w-[70px] h-[70px] `} />}
					</div>
					<p className='text-[18px] text-center leading-[20px] font-bold  text-darkenPurple capitalize'>{t(selectedEmotion)}</p>
					<div className='w-[70px]'></div>
				</div>
				<div className='pt-[19px] pb-[33px] px-[40px] '>
					<p className='text-[18px] leading-[20px] font-bold  text-darkenPurple'>{t('whatIsTheMainReason')} </p>
					<p className='text-[16px] leading-[20px] font-bold  text-deepPurple mt-[10px]'>{t('pickUpTo3')}</p>
					<div className='mt-[15px] flex flex-row items-center justify-between flex-wrap '>
						{mainReasonList.map((item, index) => {
							const isSelected = reasonsList.includes(item);
							return (
								<div
									className='w-[166px] h-[40px] flex flex-row items-center justify-center gap-[10px] mb-[15px] cursor-pointer bg-lightBG rounded-[48px]  '
									style={{ backgroundColor: isSelected ? '#5E639B' : '#ECEBF8' }}
									onClick={() => changeReasonsList(item)}
									key={index}
								>
									{React.cloneElement(mainReasonListIcons[index], {
										fill: isSelected ? '#ECEBF8' : '#5E639B',
									})}
									<p
										className='text-[16px] leading-[20px] font-semibold  text-deepPurple uppercase noselect'
										style={{ color: isSelected ? '#ECEBF8' : '#5E639B' }}
									>
										{t(item.toLowerCase())}
									</p>
								</div>
							);
						})}
					</div>

					<div className='bg-whiteContrast flex-1 rounded-[10px] border border-deselectPurple p-[19px] '>
						<textarea
							className='w-[100%] bg-whiteContrast text-[16px] leading-[20px] font-medium resize-none rounded-[10px]  text-deepPurple'
							placeholder={t('typeAnyAdditionalTextHere')}
							autoFocus={true}
							rows={5}
							value={answer_text}
							onChange={(e) => setAnswerText(e.target.value)}
						></textarea>
					</div>
					<div className='flex items-center justify-center'>
						<button
							className={`mt-[49px] rounded-[48px] min-h-[60px] w-[280px] cursor-pointer flex items-center justify-center  ${'bg-deepPurple'}`}
							onClick={submitEmotion}
							disabled={selectedEmotion === '' || sendingEmotion}
						>
							{sendingEmotion ? (
								<Loading primaryColor='#FDDF6D' secondaryColor='#FDDF6D' size={25} stroke={7} />
							) : (
								<p className={`text-[18px] font-semibold ${'text-highlightColor'}`}>{t('continue')} </p>
							)}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SelectReason;
