import React, { useEffect, useState } from 'react';
import i18n from '../../../i18n';
import { ReactComponent as Filter } from '../../../assets/svg/filter.svg';
import { ReactComponent as DropdownTransparent } from '../../../assets/svg/DropdownTransparent.svg';
import { useTranslation } from 'react-i18next';
import Loading from '../../Loading';

function ContentResultFilter({
	data = {},
	setSearchText = () => {},
	searchText = '',
	submitClassFilter = () => {},
	resetTrigger = new Date(),
}) {
	const { t } = useTranslation();
	const [showTargetGroup, setShowTargetGroup] = useState(false);
	const [selectedYearFilter, setSelectedYearFilter] = useState('all');
	const [selectedClassFilter, setSelectedClassFilter] = useState('all');
	const [loadingFilter, setLoadingFilter] = useState(false);
	const [loadingStaffFilter, setLoadingStaffFilter] = useState(false);
	const [selectedYearList, setSelectedYearList] = useState([]);
	const [filterYears, setFilterYears] = useState([]);
	const [classesOfYear, setClassesOfYear] = useState({});
	const [initialLoading, setInitialLoading] = useState(true);

	useEffect(() => {
		if (!initialLoading) {
			setSelectedYearFilter('all');
			setSelectedClassFilter('all');
			setShowTargetGroup(false);
		}
	}, [resetTrigger]);

	const selectYearFilter = (year) => {
		setSelectedClassFilter('all');
		setSelectedYearFilter(year);
		if (year === 'all') {
			const keys = Object.keys(data);
			setSelectedYearList(keys.map((key) => data[key]).flat());
		} else setSelectedYearList(classesOfYear[year]);
	};

	const selectAllClasses = () => {
		setSelectedClassFilter('all');
	};

	useEffect(() => {
		const keys = Object.keys(data);
		setFilterYears(keys);
		setSelectedYearList(keys.map((key) => data[key]).flat());
		setClassesOfYear(data);
		setInitialLoading(false);
	}, [data]);

	const submit = () => {
		submitClassFilter({ class: selectedClassFilter, year: selectedYearFilter });
		setShowTargetGroup(false);
	};
	const reset = () => {
		submitClassFilter({ class: 'all', year: 'all' });
		setSelectedYearFilter('all');
		setSelectedClassFilter('all');
		setShowTargetGroup(false);
	};

	return (
		<div>
			<div className='border-t border-dashed border-[#BEBECE] mt-[24px]'></div>
			<div className='flex flex-row items-center justify-between mt-[15px]'>
				<div className='bg-[#FFFFFF] py-[10px] px-[15px] rounded-full'>
					<input
						placeholder={t('search')}
						className='min-w-[270px]'
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
					/>
				</div>
				<div className='flex flex-col relative noselect' onMouseLeave={() => setShowTargetGroup(false)}>
					<div
						className='flex flex-row h-[40px] cursor-pointer px-[15px] items-center border rounded-full gap-[10px] justify-center bg-[#5E639B] border-[#5E639B] z-20 hover:border-[#9392AD]'
						style={{ height: 40 }}
						onClick={(e) => {
							e.preventDefault();
							setShowTargetGroup((prev) => !prev);
						}}
					>
						<Filter fill='#FFFFFF' />
						<p className='text-[#FFFFFF]'>{t('filterResults')} </p>
						<div style={{ transform: showTargetGroup ? 'scaleY(-1)' : 'none' }}>
							<DropdownTransparent style={{ color: '#FFFFFF' }} />
						</div>
					</div>
					{showTargetGroup && (
						<div className='absolute top-0 right-0 w-[330px] bg-[#ECEBF8] rounded-[20px] z-10 pt-10 px-5 pb-5'>
							<p className='text-[16px] leading-5 font-semibold text-[#BEBECE]  '>{t('years')} </p>
							<div className='overflow-auto flex flex-row gap-[10px] py-[10px] blue-small-scroll-horizontal'>
								<div
									className={`px-3 h-10 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
										selectedYearFilter === 'all' ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
									}`}
									onClick={() => selectYearFilter('all')}
								>
									{t('all')}
								</div>
								{filterYears?.map((year, index) => (
									<div
										key={index}
										className={`min-w-[40px] px-3 h-10 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
											selectedYearFilter === year ? 'bg-[#9392AD] text-white ' : 'bg-lightBG text-mudPurple '
										}`}
										onClick={() => selectYearFilter(year)}
										style={{ minWidth: year.length > 2 ? 'unset' : '40px' }}
									>
										<p className='whitespace-nowrap'>{year}</p>
									</div>
								))}
							</div>
							<p className='mt-5 text-[16px] leading-5 font-semibold text-deselectPurple '>{t('classes')}</p>
							<div className='overflow-auto flex flex-row gap-[10px] py-[10px] blue-small-scroll-horizontal'>
								<div
									className={`px-3 h-10 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
										selectedClassFilter === 'all' ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
									}`}
									onClick={() => selectAllClasses()}
								>
									{t('all')}
								</div>
								{selectedYearList?.map((item, index) => (
									<div
										key={index}
										className={` h-10 px-3 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
											selectedClassFilter === item ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
										}`}
										onClick={() => setSelectedClassFilter(item)}
									>
										<p className='whitespace-nowrap'>{item}</p>
									</div>
								))}
							</div>
							<div className='flex flex-row gap-5 mt-5'>
								<button
									className='flex flex-row items-center justify-center rounded-full cursor-pointer h-10 flex-1 bg-white'
									// onClick={() => applyStaffFilter()}
									onClick={reset}
									disabled={loadingStaffFilter}
								>
									{loadingStaffFilter ? (
										<Loading primaryColor='#9392AD' secondaryColor='#9392AD' size={25} stroke={7} />
									) : (
										<p className='text-darkenPurple text-[16px] leading-5 font-semibold'>{t('Reset')}</p>
									)}
								</button>
								<button
									className='flex flex-row items-center justify-center rounded-full cursor-pointer h-10 flex-1 bg-highlightColor'
									onClick={submit}
									disabled={loadingFilter}
								>
									{loadingFilter ? (
										<Loading primaryColor='#9392AD' secondaryColor='#9392AD' size={25} stroke={7} />
									) : (
										<p className='text-darkenPurple text-[16px] leading-5 font-semibold'> {t('apply')}</p>
									)}
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default ContentResultFilter;
