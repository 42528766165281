import React from 'react';
import { UserProvider } from '../auth';
import { InstitutionProvider } from '../auth/InstitutionContext';
import { SocketProvider } from '../context/socket';
import { AppContextProvider } from '../context/AppContext';

const AppProviders = ({ children }) => {
	return (
		<UserProvider>
			<InstitutionProvider>
				<SocketProvider>
					<AppContextProvider>{children}</AppContextProvider>
				</SocketProvider>
			</InstitutionProvider>
		</UserProvider>
	);
};

export default AppProviders;
