import React, { useEffect, useState } from 'react';
import { format_day, datesAreOnSameDay, notify } from '../../utils/util';

import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { decrypt } from '../../utils/crypto';
import { useUser } from '../../auth';
import { deleteById, getBy } from '../../server/server';
import i18n from '../../i18n';
import { ReactComponent as PrivateIcon } from '../../assets/svg/PrivateIcon.svg';
import NewSecretKeyModal from '../modals/NewSecretKeyModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import '../../styles/NewPrivateNotes.css';

function PrivateNotes() {
	const secretKey = localStorage.getItem('secret_key');
	const { user } = useUser();
	const { t } = useTranslation();
	const [notes, setNotes] = useState([]);
	const [secretKeyModalIsOpen, setSecretKeyModalOpen] = useState(!secretKey);
	const [selectedIndex, setSelectedIndex] = useState([]);
	const [deleteNoteConfirm, setDeleteNoteConfirm] = useState(false);
	const [noteId, setNoteId] = useState('');
	const [noteIndex, setNoteIndex] = useState(-1);
	const closeModal = () => setSecretKeyModalOpen(false);
	const [deleteInfo, setDeleteInfo] = useState({ id: 0, index: 0 });
	const loadNotes = async () => {
		const resp = await getBy({
			collection: 'private_notes',
			query: { user_id: user.id },
			page: -1,
			page_size: -1,
			sort_field: 'created_date',
			sort_direction: -1,
		});
		if (resp.status === 200) {
			const results = resp.data;
			setNotes(results.data);
		}
	};

	useEffect(() => {
		loadNotes();
	}, []);

	const openNote = (index) => {
		let newArray = selectedIndex;
		if (!selectedIndex.includes(index)) {
			setSelectedIndex([...selectedIndex, index]);
			return;
		} else {
			let newIndex = selectedIndex.indexOf(index);
			if (newIndex > -1) {
				newArray.splice(newIndex, 1);
			}
			setSelectedIndex([...newArray]);
		}
	};
	const deleteNote = (id, index) => {
		setDeleteNoteConfirm(true);
		setNoteId(id);
		setNoteIndex(index);
	};
	const execute_deletion = async () => {
		const resp = await deleteById({ collection: 'private_notes', id: noteId });
		if (resp.status == 200) {
			openNote(noteIndex);
			loadNotes();
			setDeleteNoteConfirm(false);
			notify(t('noteHasBeenDeleted'));
		}
	};
	const closeConfirmModal = () => {
		setDeleteNoteConfirm(false);
	};
	const NotePreview = ({ note, index }) => {
		const yesterdayEmotion = note.last_daily_feedback?.emotion;
		const todayEmotion = note.today_daily_feedback?.emotion;

		return (
			<div className='new-note-preview'>
				<div
					className='new-note-item'
					onClick={() => openNote(index)}
					style={{ backgroundColor: selectedIndex.includes(index) ? '#ECEBF8' : '#f8f8fb' }}
				>
					<p className='new-note-item-type'>{t('Day review')}</p>
					<div style={{ flex: 1 }}></div>
					<p className='new-note-item-date'>{format_day(note.created_date, true, i18n.language)}</p>
					<div className='new-note-select' style={{ transform: selectedIndex.includes(index) ? 'rotate(180deg)' : 'none' }}>
						{/* <AngleUpIcon /> */}
					</div>
				</div>
				{selectedIndex.length !== 0 && selectedIndex.includes(index) && note.type === 'daily_feedback' && (
					<div className='new-note-opened'>
						<p className='new-note-opened-subtitle'>
							{t('doYouThinkThingsAreForAnyReasonInParticular', { result: t(camelCase(note.compare)).toLowerCase() })}
						</p>
						<div className='new-note-compare-answer'>
							<p className='new-note-compare-message'>{decrypt(note.message, secretKey)}</p>
						</div>
						<p className='new-note-opened-subtitle' style={{ marginTop: 21 }}></p>
						<div className='note-suggestions'>
							{note.suggestions.map((suggestion, index) => {
								return (
									<div className='new-note-suggestion-item w-full flex justify-start items-start' key={index}>
										<p className='new-note-suggestion-text text-left'>
											0{index + 1} {decrypt(suggestion, secretKey)}
										</p>
									</div>
								);
							})}
						</div>
						<div className='new-note-delete' onClick={() => deleteNote(note.id, index)}>
							<p>{t('deleteNote')}</p>
						</div>
					</div>
				)}
				{selectedIndex.includes(index) && note.type === 'day_reflection' && (
					<div className='new-note-opened'>
						<p className='new-note-opened-subtitle'>{t('whatAreJust3GoodThingsThatHappenedToday')}</p>
						<div className='note-suggestions' style={{ marginTop: 5 }}>
							{note.things_happened_today.map((suggestion, index) => {
								return (
									<div className='new-note-suggestion-item w-full flex justify-start items-start' key={index}>
										<p className='new-note-suggestion-text text-left'>
											0{index + 1} {decrypt(suggestion, secretKey)}
										</p>
									</div>
								);
							})}
						</div>
						<p className='new-note-opened-subtitle' style={{ marginTop: 20 }}>
							{t('What are 3 things that are a bit challenging at the moment?')}
						</p>
						<div className='note-suggestions' style={{ marginTop: 5 }}>
							{note.challenging_things.map((suggestion, index) => {
								return (
									<div className='new-note-suggestion-item w-full flex justify-start items-start' key={index}>
										<p className='new-note-suggestion-text text-left'>
											0{index + 1} {decrypt(suggestion, secretKey)}
										</p>
									</div>
								);
							})}
						</div>
						<p className='new-note-opened-subtitle' style={{ marginTop: 20 }}>
							{t('What’s just one thing you will do differently tomorrow?')}
						</p>
						<div className='new-note-compare-answer' style={{ marginTop: 10 }}>
							<p className='new-note-compare-message'>{decrypt(note.tomorrow_plan, secretKey)}</p>
						</div>
						<div className='new-note-delete' onClick={() => deleteNote(note.id, index)}>
							<p>{t('deleteNote')}</p>
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className='new-notes-container'>
			<p className='new-notes-title text-xl text-deepPurple font-bold mb-6'>{t('yourPrivateNotes')}</p>
			<div className='new-notes-subtitle flex items-center justify-center bg-containerGray rounded-xl mx-8 p-2'>
				<PrivateIcon />
				<p className='ml-2 new-notes-text text-mudPurple'> {t('thisIsYourSafePlaceAndNothingWillBeShared')}</p>
			</div>
			{notes.map((note, index) => {
				const previousNote = index > 0 && notes[index - 1];
				const previousNoteDay = previousNote?.created_date;
				const sameDay = !!previousNoteDay && datesAreOnSameDay(new Date(note.created_date), new Date(previousNoteDay));

				return (
					<div key={index} className='new-notes-group'>
						{!sameDay && (
							<div className='new-notes-dates' style={{ direction: 'ltr' }}>
								{format_day(note.created_date, true, i18n.language)}
							</div>
						)}
						<NotePreview note={note} index={index} />
					</div>
				);
			})}

			<NewSecretKeyModal modalIsOpen={secretKeyModalIsOpen} closeModal={closeModal} />
			<ConfirmationModal
				modalIsOpen={deleteNoteConfirm}
				closeModal={closeConfirmModal}
				message={t('confirmDeletion')}
				subMessage={t('deleteYourPrivateNote')}
				confirmation={execute_deletion}
			/>
		</div>
	);
}

export default PrivateNotes;
