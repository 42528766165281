import React from 'react';
import { emotions_color, emotions_heads } from '../../constants/Constants';
import { ReactComponent as CommentImage } from '../../assets/svg/CommentImage.svg';
import '../../styles/classroom/DailyResponse.css';
import { format_day } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useState } from 'react';

function DailyResponse({ item }) {
	const { t, locale } = useTranslation();
	const [commentVisible, setCommentVisible] = useState(false);

	const openComment = () => {
		setCommentVisible((prev) => !prev);
	};
	return (
		<div className='daily-reponse-container'>
			<div className='daily-response-header'>
				<div className='daily-response-image-container'>
					<img
						src={emotions_heads[item.emotion]}
						width='50'
						height='50'
						alt='Emotion'
						className={i18n.language == 'ar' ? 'ar-transform' : ''}
					/>
				</div>
				<div className='daily-response-title-container'>
					<p className='daily-response-title'>{t('howAreYouToday')}</p>
					<label className='daily-response-date'>{format_day(item.created_date, true, i18n.language)}</label>
				</div>
				{!!item?.message && (
					<div className='daily-comment-icon' onClick={openComment}>
						<CommentImage />
					</div>
				)}
				<div className='daily-response-emotion' style={{ background: emotions_color[item.emotion] }}>
					<p style={{ color: item.emotion?.toLowerCase() == 'happy' ? '#5E639B' : 'white' }}>{t(item.emotion?.toLowerCase())}</p>
				</div>
			</div>
			{item.message && commentVisible && (
				<div className='daily-response-comment-container'>
					<p>{t('comment')}</p>
					<p>{item.message}</p>
				</div>
			)}
		</div>
	);
}

export default DailyResponse;
