import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as Calendar } from '../../assets/svg/CalendarIcon.svg';
import '../../styles/modals/PollScheduleModal.css';
import { FaTimes } from 'react-icons/fa';
import i18n from 'i18next';
import TextField from '@mui/material/TextField';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import arLocale from 'date-fns/locale/ar-SA';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PickTimeModule from './PickTimeModule';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as ScheduleIcon } from '../../assets/svg/ScheduleIcon.svg';
import { ReactComponent as OpenCalendarIcon } from '../../assets/svg/OpenCalendarIcon.svg';
import CalendarIcon from '../../assets/svg/CalendarIcon.svg';
import PickTimeHorizontalModule from './PickTimeHorizontalModule';
import Switcher from '../content/Switcher';
import { INIT_REPEAT_DAYS, localeMap } from '../../constants/Constants';
import RepeatContentContainer from './RepeatContentContainer';
import { calculateDaysText, formatDateOnly, getRoundedDate } from '../../utils/util';

function ScheduleExpandedModal({ showSchedule, closeSchedule, data, setReturnSchedule }) {
	const { t, locale } = useTranslation();

	const [repeat, setRepeat] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [returnHour, setReturnHour] = useState(8);
	const [returnMinute, setReturnMinute] = useState(0);
	const [repeatType, setRepeatType] = useState('weekly');
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [selectedDay, setSelectedDay] = useState(1);
	const [selectedDays, setSelectedDays] = useState([]);
	const [endOfRecurrenceDate, setEndOfRecurrenceDate] = useState(new Date());

	useEffect(() => {
		if (showSchedule) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
			parseData();
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [showSchedule]);

	const changeDate = (date) => {
		if (date === null && date === undefined) return;
		setStartDate(date);
		setIsDatePickerOpen(false);
	};
	const calculateText = () => {
		if (selectedDays.length === 0) return '';
		const daysText = calculateDaysText(selectedDays.sort());
		return `Occurs every ${daysText} until ${formatDateOnly(endOfRecurrenceDate)}`;
	};

	const parseData = () => {
		setStartDate(new Date(!!data.enabled ? data.schedule_date : new Date()));
		setRepeat(!!data?.repeatEnabled);
		const tempDate = new Date(data.enabled ? data.schedule_date : new Date());
		const date = getRoundedDate(5, tempDate);

		setReturnHour(date.getHours());
		setReturnMinute(date.getMinutes());
		setRepeatType(data?.repeatType ?? 'weekly');
		setSelectedDay(data?.selectedDay ?? 1);
		setSelectedDays(data?.selectedDays ?? []);
		setEndOfRecurrenceDate(new Date(data?.endOfRecurrenceDate ?? new Date()));
	};

	const handleContinue = () => {
		let schedule_date = new Date();
		schedule_date.setUTCDate(startDate.getDate());
		schedule_date.setHours(returnHour);
		schedule_date.setMinutes(returnMinute);
		schedule_date.setSeconds(0);
		let returnData = {
			enabled: true,
			text: 'On',
			recurrentText: 'Never',
			repeatEnabled: repeat,
			schedule_date: schedule_date.getTime(),
			repeatType: repeatType,
		};
		if (repeat) {
			returnData['repeatType'] = repeatType;
			returnData['recurrentText'] = repeatType === 'weekly' ? calculateText() : `${selectedDay} of every month`;
			if (repeatType === 'weekly') {
				returnData['selectedDays'] = selectedDays;
			} else {
				returnData['selectedDay'] = selectedDay;
			}
			returnData['endOfRecurrenceDate'] = endOfRecurrenceDate.getTime();
		}
		setReturnSchedule(returnData);
		closeSchedule();
	};

	const handleOff = () => {
		setReturnSchedule({ enabled: false, text: 'Off', recurrentText: 'Never', repeatEnabled: false });
		closeSchedule();
	};
	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={showSchedule}
			onRequestClose={closeSchedule}
			shouldCloseOnOverlayClick={true}
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					minWidth: 334,
					margin: 'auto',
					marginTop: '2%',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					margin: 'auto',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div className='w-full justify-center flex'>
				<div
					className='hover:bg-darkenPurple pointer absolute top-[10px] flex bg-deepPurple rounded-full w-[60px] h-[60px] justify-center items-center'
					onClick={() => closeSchedule()}
				>
					<CloseModalIcon />
				</div>
				<div className='bg-containerGray rounded-[10px] mt-10 h-full w-full overflow-hidden'>
					<div className='h-[110px] bg-[#ECEBF8] w-full pl-[35px] pt-[48px]'>
						<div className='flex flex-row items-center'>
							<ScheduleIcon />
							<span className='text-deepPurple font-bold ml-[5px] text-[25px]'>{t('schedule')}</span>
						</div>
					</div>
					<div className='p-[35px] pt-[25px] '>
						<div>
							<p className='text-[#413F5E] text-[16px] font-bold'>{t('setupADateAndTime')} </p>
						</div>
						<div className='flex flex-row items-center mt-[25px] mb-[30px]'>
							<div className='flex flex-col relative'>
								<div
									className='rounded-full pointer gap-[4px] flex flex-row bg-lightBG text-deselectPurple font-semibold text-base items-center justify-center h-[41px] px-[20px]'
									onClick={() => setIsDatePickerOpen(true)}
								>
									<Calendar />
									<p className='text-[#413F5E]'>{t('startDate')}:</p>
									<p className='text-base text-deepPurple font-semibold'> {startDate?.toLocaleDateString()}</p>
								</div>
								<div style={{ position: 'absolute', top: 0, left: 65, visibility: 'hidden' }}>
									<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[i18n.language]}>
										<DatePicker
											open={isDatePickerOpen}
											onClose={() => setIsDatePickerOpen(false)}
											label='Date desktop'
											inputFormat='MM/dd/yyyy'
											value={startDate}
											onChange={changeDate}
											minDate={new Date()}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</div>
							</div>
							<div className='flex-1'></div>
							<div>
								<PickTimeHorizontalModule
									hour={returnHour}
									minute={returnMinute}
									setReturnMinute={setReturnMinute}
									setReturnHour={setReturnHour}
								/>
							</div>
						</div>
						<div className='w-full border-t border-b pt-[25px] pb-[30px] border-[#BEBECE] border-dashed'>
							<div className='flex flex-row items-center justify-between'>
								<p className='text-[#413F5E] font-bold text-[16px] '>{t('repeat')} </p>
								<Switcher switcher={repeat} setSwitcher={setRepeat} />
							</div>

							<RepeatContentContainer
								enabled={repeat}
								selectedType={repeatType}
								changeType={setRepeatType}
								setEndOfRecurrenceDate={setEndOfRecurrenceDate}
								endOfRecurrenceDate={endOfRecurrenceDate}
								days={selectedDays}
								setDays={setSelectedDays}
								selectedDay={selectedDay}
								setSelectedDay={setSelectedDay}
								setType={setRepeatType}
							/>
						</div>

						<div className='flex mt-[35px] flex-row justify-center items-center w-full '>
							<button
								className='bg-lightBG hover:bg-deselectPurple transition-all duration-300 ease-in-out rounded-full h-[50px] px-7 text-deepPurple font-semibold text-lg mr-[10px]'
								onClick={handleOff}
							>
								{t('turnOff')}
							</button>
							<button
								className='bg-deepPurple hover:bg-highlightColor transition-all duration-300 ease-in-out rounded-full h-[50px] px-7 text-highlightColor hover:text-deepPurple font-semibold text-lg'
								onClick={handleContinue}
							>
								{t('continue')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default ScheduleExpandedModal;
