import React, { useEffect } from 'react';
import { useState } from 'react';
import Content from './Content';
import MyTask from './MyTask';
import { useUser } from '../../auth';
import { ReactComponent as UpcomingArrows } from '../../assets/svg/upcomingArrows.svg';
import UpcomingContentModal from '../modals/UpcomingContentModal';
import { getUpcomingNum } from '../../server/server';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';

function Dashboard() {
	const { t, locale } = useTranslation();
	const { user, setUser } = useUser();
	const [showUpcomingContentModal, setShowUpcomingContentModal] = useState(false);
	const [numUpcomingContent, setNumUpcomingContent] = useState(3);
	const [dashboardMode, setDashboardMode] = useState('Results');

	const [loadingNum, setLoadingNum] = useState(true);
	const [contentState, setContentState] = useState('');
	const [refresh, setRefresh] = useState('');
	const [refreshNum, setRefreshNum] = useState('');
	const closeModal = () => {
		setShowUpcomingContentModal(false);
	};

	const openMyUpstrive = () => {
		setDashboardMode('MyUpstrive');
	};
	const loadUpcomingNum = async () => {
		console.log('Here111');
		const resp = await getUpcomingNum();
		if (resp?.status === 200) {
			setLoadingNum(false);
			setNumUpcomingContent(resp?.data);
		}
	};
	useEffect(() => {
		loadUpcomingNum();
	}, [refreshNum]);

	return (
		<div className='flex justify-center bg-[#ECEBF8] h-full overflow-y-scroll px-2 pb-2'>
			<div className='dashboard-layout flex flex-row gap-[35px]  py-10 w-full  max-w-[1380px] mx-auto h-fit min-h-full'>
				<div className='dashboard-left-side flex-1 xxs:max-w-[96%] xl:max-w-[865px] mx-auto '>
					<Content refresh={refresh} setRefresh={setRefresh} setContentState={setContentState} />
				</div>
				<div className='xxs:hidden xl:block'>
					<MyTask user={user} />
				</div>
			</div>

			<div
				className={`${
					contentState === 'myTask' ? 'invisible' : ''
				} absolute bottom-6 right-6 font-bold pointer bg-deepPurple rounded-3xl text-white py-[0.8rem] px-5 flex items-center z-10`}
				onClick={() => setShowUpcomingContentModal(true)}
				id='upcoming-content-modal-trigger'
			>
				<div className='relative flex items-center  '>
					<UpcomingArrows />
					<span className='ml-3'>{t('upcomingContent')} </span>
					<div className='w-[30px] h-[30px] rounded-full bg-highlightColor border text-deepPurple bottom-5 -right-5 border-white absolute flex items-center justify-center'>
						{loadingNum ? <Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={18} stroke={8} /> : numUpcomingContent}
					</div>
				</div>
			</div>

			<UpcomingContentModal
				showRun={showUpcomingContentModal}
				closeRun={closeModal}
				num={numUpcomingContent}
				setRefreshNum={setRefreshNum}
			/>
		</div>
	);
}

export default Dashboard;
