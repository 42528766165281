import React, { useEffect, useState } from 'react';
import { useUser } from '../auth';
import beAmazing from '../be_amazing.png';
import LoginBG from '../assets/svg/LoginBG.svg';
import UpstriveLoginLogo from '../assets/svg/UpstriveLoginLogo.svg';
import EnterNewPassword from '../components/auth/EnterNewPassword';
import Login from '../components/auth/Login';
import NewPassword from '../components/auth/NewPassword';
import Token from '../components/auth/Token';
import { getById, login, request_new_password, update_new_password, verify_reset_token } from '../server/server';
import '../styles/UnauthenticatedApp.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalIcon } from '../assets/svg/CloseModalIcon.svg';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';
import { checkIfTeacherHasPrimaryClasses, checkIfTeacherHasRegularClasses, logLoginTime } from '../server/usersData';

const LoginScreen = () => {
	let history = useHistory();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [retypedPassword, setRetypedPassword] = useState('');
	const [token, setToken] = useState('');
	const [authSection, setAuthSection] = useState('login');
	const { t, locale } = useTranslation();
	const { setUser } = useUser();

	const onRequestNewPassword = async () => {
		try {
			const response = await request_new_password({ email });
			return response;
		} catch (e) {
			console.log(e);
			return { data: 'Error', success: false };
		}
	};

	const onTokenSubmit = async () => {
		try {
			const response = await verify_reset_token({ email, token });
			return response;
		} catch (e) {
			console.log(e);
			return { data: 'Error', success: false };
		}
	};

	const onNewPasswordSubmit = async (password) => {
		try {
			const resp = await update_new_password({
				email,
				password,
				token,
			});
			return resp;
		} catch (e) {
			console.log(e);
			return { data: 'Error', success: false };
		}
	};

	const loginUser = async ({ email, password }) => {
		const startTime = Date.now();
		const resp = await login({ email, password });
		const endTime = Date.now();

		const logLoginTimeData = {
			start_time: startTime,
			end_time: endTime,
			device: 'desktop',
			success: resp.status === 200,
			user_id: resp?.data?.id,
			email: email,
			serverError: 500 <= resp.status && resp.status < 599,
		};
		logLoginTime(logLoginTimeData);

		if (resp.status == 200) {
			const data = resp.data;
			if (data.role == 0) {
				return { data: 'This is only for teachers...', success: false };
			}
			localStorage.setItem('token', data['token']);
			localStorage.setItem('loginDate', Date.now());
			localStorage.setItem('user', JSON.stringify(data));
			localStorage.setItem('institution_id', data['institution_id']);
			const user = resp.data;
			if (user.new_password_required) {
				setPassword('');
				setToken(user.token);
				setAuthSection('enter-new-password');
			} else {
				const hasPrimaryClasses = await checkIfTeacherHasPrimaryClasses();
				if (hasPrimaryClasses) {
					const hasRegularClasses = await checkIfTeacherHasRegularClasses();
					setUser({ ...user, hasPrimaryClasses, hasRegularClasses, hasOnlyPrimaryStudents: !hasRegularClasses });
				} else setUser(user);
				await loadInstitution(data);
				history.push('/dashboard');
			}

			return { success: true };
		} else {
			return { data: resp.data, success: false };
		}
	};

	const loadInstitution = async (user) => {
		const resp = await getById({ collection: 'institutions', id: user.institution_id });
		if (resp.status === 200) {
			console.log(resp.data);
			if (!!resp?.data?.locale) {
				i18n.changeLanguage(resp?.data?.locale);
				localStorage.setItem('locale', resp?.data?.locale);
			}
		}
	};

	const renderAuthSection = () => {
		if (authSection === 'login') {
			return (
				<Login
					password={password}
					setPassword={setPassword}
					email={email}
					setToken={setToken}
					setEmail={setEmail}
					onSubmit={loginUser}
					setAuthSection={setAuthSection}
				/>
			);
		} else if (authSection === 'new-password') {
			return <NewPassword email={email} setEmail={setEmail} onSubmit={onRequestNewPassword} setAuthSection={setAuthSection} />;
		} else if (authSection === 'token') {
			return (
				<Token
					token={token}
					setToken={setToken}
					onSubmit={onTokenSubmit}
					setAuthSection={setAuthSection}
					repeatRequest={onRequestNewPassword}
				/>
			);
		} else if (authSection === 'enter-new-password') {
			return (
				<EnterNewPassword
					token={token}
					setToken={setToken}
					password={password}
					setPassword={setPassword}
					setRetypedPassword={setRetypedPassword}
					retypedPassword={retypedPassword}
					onSubmit={onNewPasswordSubmit}
					setAuthSection={setAuthSection}
					repeatRequest={onRequestNewPassword}
				/>
			);
		}
	};

	useEffect(() => {
		document.getElementById('home-container').style.transitionDuration = '0';
		document.getElementById('home-container').style.marginLeft = '0px';
	}, []);

	const getBackTo = () => {
		const backMap = {
			login: 'login',
			'new-password': 'login',
			token: 'new-password',
			'enter-new-password': 'login',
		};
		setAuthSection(backMap[authSection]);
	};
	return (
		<div className='w-full flex-auto pb-10 ' style={{ backgroundImage: `url(${LoginBG})` }} id='login-back'>
			<div className='relative  h-[100dvh] flex items-center justify-center'>
				<div className='w-full  justify-center flex'>
					<div className='bg-deepPurple mt-[30px] w-[490px] flex justify-center px-10 flex-col py-5 rounded-xl'>
						<div className='login-image-container flex justify-center mb-4'>
							<img src={UpstriveLoginLogo} alt='' className='w-[200px] mt-6'></img>
						</div>
						{renderAuthSection()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginScreen;
