import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/PollScheduleModal.css';
import { FaTimes } from 'react-icons/fa';
import i18n from 'i18next';
import TextField from '@mui/material/TextField';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import arLocale from 'date-fns/locale/ar-SA';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PickTimeModule from './PickTimeModule';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as ScheduleIcon } from '../../assets/svg/ScheduleIcon.svg';
import { ReactComponent as Calendar } from '../../assets/svg/Calendar.svg';
import { format_day_only } from '../../utils/util';

function PollScheduleModal({ showSchedule, closeSchedule, setReturnSchedule }) {
	useEffect(() => {
		if (showSchedule) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [showSchedule]);

	const { t, locale } = useTranslation();
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [returnHour, setReturnHour] = useState(8);
	const [returnMinute, setReturnMinute] = useState(0);
	const changeDate = (date) => {
		if (date === null && date === undefined) return;
		setStartDate(date);
	};
	const localeMap = {
		ar: arLocale,
		en: enLocale,
		de: deLocale,
	};
	const handleContinue = () => {
		let schedule_date = new Date();
		schedule_date.setUTCDate(startDate.getUTCDate());
		schedule_date.setHours(returnHour);
		schedule_date.setMinutes(returnMinute);
		schedule_date.setSeconds(0);
		setReturnSchedule({
			enabled: true,
			text: 'Off',
			recurrentText: 'Never',
			repeatEnabled: false,
			schedule_date: schedule_date.getTime(),
		});
		closeSchedule();
	};
	const handleOff = () => {
		setReturnSchedule({ enabled: false, text: 'Off', recurrentText: 'Never', repeatEnabled: false });
		closeSchedule();
	};
	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={showSchedule}
			onRequestClose={closeSchedule}
			shouldCloseOnOverlayClick={true}
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 334,
					minWidth: 334,
					margin: 'auto',
					marginTop: '2%',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					margin: 'auto',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div className='w-full justify-center flex'>
				<div
					className='hover:bg-darkenPurple pointer absolute top-[10px] flex bg-deepPurple rounded-full w-[60px] h-[60px] justify-center items-center'
					onClick={() => closeSchedule()}
				>
					<CloseModalIcon />
				</div>
				<div className='bg-containerGray rounded-xl mt-10 h-full w-full p-8'>
					<div className='flex items-center justify-center w-full mt-8'>
						<ScheduleIcon />
						<span className='text-deepPurple font-bold ml-2 text-[25px]'>{t('schedule')}</span>
					</div>
					<div className='rounded-full flex flex-row items-center my-3 bg-lightBG text-mudPurple font-semibold text-base justify-center relative'>
						<p className=' ml-3'>{t('startDate')}: </p>
						<div
							className='h-[50px] ml-2 flex flex-row items-center justify-center cursor-pointer gap-2 not-selectable'
							onClick={() => setIsDatePickerOpen(true)}
						>
							<Calendar style={{ color: '#9392AD' }} />
							<p>{format_day_only(startDate)}</p>
						</div>
						<div style={{ position: 'absolute', top: 0, left: 0, visibility: 'hidden' }}>
							<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[i18n.language]}>
								<DatePicker
									value={startDate}
									onChange={changeDate}
									open={isDatePickerOpen}
									onClose={() => setIsDatePickerOpen(false)}
									minDate={new Date()}
									renderInput={(params) => (
										<TextField
											inputProps={{ ...params.inputProps, readOnly: true }}
											onKeyDown={(e) => {
												e.preventDefault();
											}}
											{...params}
										/>
									)}
									inputFormat={'dd/MM/yyyy'}
									// disableMaskedInput={true}
								/>
							</LocalizationProvider>
						</div>
					</div>
					<div className='flex flex-row items-center justify-between w-full my-3'>
						<p className='text-base font-bold text-mudPurple'>{t('setATime')} </p>
						<div>
							<PickTimeModule
								hour={returnHour}
								minute={returnMinute}
								setReturnMinute={setReturnMinute}
								setReturnHour={setReturnHour}
							/>
						</div>
					</div>

					<div className='flex mt-2 flex-row justify-between items-center w-full gap-2'>
						<button
							className='bg-lightBG hover:bg-deselectPurple rounded-full py-3 px-7 text-deepPurple font-semibold text-lg'
							onClick={handleOff}
						>
							{t('turnOff')}
						</button>
						<button
							className='bg-deepPurple hover:bg-highlightColor rounded-full py-3 px-7 text-highlightColor hover:text-deepPurple font-semibold text-lg'
							onClick={handleContinue}
						>
							{t('continue')}
						</button>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default PollScheduleModal;
