import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams, generatePath } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useUser } from '../../auth';
import {
	addCounsellorNote,
	checkIfTakeDailyFeedback,
	getById,
	getMostPopularEmotionForUser,
	getUserResponsePercentage,
	hideWellbeingNote,
	update,
} from '../../server/server';
import '../../styles/counseling/StudentWellbeingProfile.css';
import { emotions_heads } from '../../constants/Constants';
import { convertDateToFormatddMMyyy, format_day, format_day_numbers_month, format_day_only, notify } from '../../utils/util';
import TextareaAutosize from 'react-textarea-autosize';
import { baseUrl } from '../../constants/Connections';
import axios from 'axios';
import ReminderModal from '../modals/ReminderModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import DonutChartWellbeing from './DonutChartWellbeing';
import { ReactComponent as Import } from '../../assets/svg/Import.svg';
import { ReactComponent as AngleUp } from '../../assets/svg/AngleUp.svg';
import { ReactComponent as SeenTransparent } from '../../assets/svg/SeenTransparent.svg';
import { ReactComponent as CloseAye } from '../../assets/svg/CloseAye.svg';
import { ReactComponent as Bell } from '../../assets/svg/Bell.svg';
import { ReactComponent as PaperPlane } from '../../assets/svg/PaperPlane.svg';
import { ReactComponent as AssignAction } from '../../assets/svg/AssignAction.svg';
import { ReactComponent as Undo } from '../../assets/svg/Undo.svg';
import { ReactComponent as EditTransparent } from '../../assets/svg/EditTransparent.svg';
import { ReactComponent as DeleteTransparent } from '../../assets/svg/DeleteTransparent.svg';
import { ReactComponent as PollIconBlue } from '../../assets/svg/PollIconBlue.svg';
import { ReactComponent as QuestionIconBlue } from '../../assets/svg/QuestionIconBlue.svg';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIcon.svg';
import WellBeingAsignModal from '../modals/WellBeingAsignModal';
import Loading from '../Loading';

const filterList = ['Display all', 'Notes', 'Questions', 'Polls'];

function StudentWellbeingProfile() {
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};
	let query = useQuery();
	let id = query.get('id');
	const [profile, setProfile] = useState({});
	const [reminderOverDue, setReminderOverDue] = useState(false);
	const [exporting, setExporting] = useState(false);
	const [message, setMessage] = useState('');
	const [selectedNote, setSelectedNote] = useState(null);
	const [reminderModal, setReminderModal] = useState(false);
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
	const [modalMessage, setModalMessage] = useState('');
	const [openWellbeingAsignModal, setOpenWellbeingAsignModal] = useState(false);
	const [noteForDelete, setNoteForDelete] = useState(-1);
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState('Display all');
	const [mostPopularEmotion, setMostPopularEmotion] = useState(null);
	const [currentEmotion, setCurrentEmotion] = useState(null);
	const [userResponsePercentage, setUserResponsePercentage] = useState(null);
	const [hoveredButton, setHoveredButton] = useState(false);

	const [asignNote, setAsignNote] = useState({});

	let history = useHistory();
	const { user, setUser } = useUser();
	const { t, locale } = useTranslation();
	useEffect(() => {
		loadCounsellingProfile();
	}, []);

	useEffect(() => {
		if (!!profile?.student) {
			loadLastEmotion();
			loadMostPopularEmotion();
			loadResponsePercentage();
		}
	}, [profile]);

	const loadLastEmotion = async () => {
		const resp = await checkIfTakeDailyFeedback(profile?.student?.id);
		if (resp.status === 200) {
			const data = resp.data;
			setCurrentEmotion(data?.emotion);
		}
	};
	const loadResponsePercentage = async () => {
		const resp = await getUserResponsePercentage(profile?.student?.id);
		if (resp.status === 200) {
			const data = resp.data;
			setUserResponsePercentage(data / 100);
		}
	};
	const loadMostPopularEmotion = async () => {
		const resp = await getMostPopularEmotionForUser(profile?.student?.id);
		if (resp.status === 200) {
			const data = resp.data;
			setMostPopularEmotion(data);
		}
	};

	const loadCounsellingProfile = async () => {
		const student_id = id;
		const resp = await getById({ collection: 'counselling_profiles', id: student_id });
		if (resp.status === 200) {
			console.log('student wellbeing profile list of notes ', resp.data);
			setProfile(resp.data);
			checkReminderOverdue(resp.data);
			scrollToBottom();
		}
	};

	const scrollToBottom = () => {
		var objDiv = document.getElementById('counselling-notes-wrapper');
		objDiv.scrollTop = objDiv?.scrollHeight;
	};
	const checkReminderOverdue = (profileTemp) => {
		const today = new Date();
		today.setHours(0);
		if (profileTemp?.reminder < today.getTime()) {
			setReminderOverDue(true);
			console.log('Reminder overdue...');
		}
	};
	const goBack = () => {
		history.goBack();
	};
	const createNote = async () => {
		if (!!selectedNote?.type) {
			updateReminder();
			return;
		}
		//vm.message = vm.message.trim();
		if (message == '') {
			notify(t('firstEnterANote'));
			return;
		}
		const note = {
			message: message,
			user: user,
			created_date: Date.now(),
			type: 'message',
		};
		const resp = await addCounsellorNote(profile.id, note);
		if (resp.status === 200) {
			setProfile(resp.data);
			setMessage('');
			notify(t('noteIsCreated'));
			scrollToBottom();
		}
	};
	const updateReminder = async (date = new Date(selectedNote.reminder)) => {
		profile?.notes?.map((note) => {
			if (note === selectedNote) {
				note.reminder = date.getTime();
				note.message = message.trim();
			}
		});
		const resp = await update({ collection: 'counselling_profiles', data: profile });
		if (resp.status === 200) {
			notify(t('reminderHasBeenChanged'));
			checkReminderOverdue(profile);
			setSelectedNote(null);
			setMessage('');
			scrollToBottom();
		}
	};
	const openDeleteConfirmModal = (index) => {
		setNoteForDelete(index);
		setModalMessage(t('doYouWantToDeleteThis'));
		setOpenDeleteConfirm(true);
	};
	const closeDeleteConfirm = () => {
		setNoteForDelete(-1);
		setOpenDeleteConfirm(false);
	};
	const changeStatus = async () => {
		removeReminder(noteForDelete);
		setNoteForDelete(-1);
		setOpenDeleteConfirm(false);
	};

	const print = () => {
		setExporting(true);
		const token = localStorage.getItem('token');
		const data = {
			user: profile.student,
			teacher: profile.teacher,
			notes: [...profile.notes].reverse(),
		};
		var req = {
			method: 'POST',
			url: baseUrl + '/api/generate_counseling_report',
			responseType: 'arraybuffer',
			headers: { Authorization: token },
			data: data,
		};
		var reader = new FileReader();

		reader.onload = function (e) {
			window.open(decodeURIComponent(reader.result), '_self', '', false);
		};

		axios(req).then(
			function (response) {
				var contentType = response.headers['content-type'];
				var blob = new Blob([response.data], { type: contentType });

				var a = document.createElement('a');
				document.body.appendChild(a);
				a.style = 'display: none';

				var url = window.URL.createObjectURL(blob);
				a.href = url;

				a.download = 'Counseling report.pdf';
				a.click();
				setExporting(false);
			},
			function (resp) {}
		);
	};
	const openReminderModal = () => {
		if (message === '') {
			notify(t('firstEnterANote'));
			return;
		}

		setReminderModal(true);
	};
	const closeReminderModal = () => {
		setMessage('');
		setSelectedNote(null);
		setReminderModal(false);
	};
	const createReminder = async (date) => {
		if (!!selectedNote?.type) {
			updateReminder(date);
			return;
		}
		const note = {
			message: message,
			user: user,
			created_date: Date.now(),
			type: 'message',
			reminder: date.getTime(),
		};
		const resp = await addCounsellorNote(profile.id, note);
		if (resp.status === 200) {
			setProfile(resp.data);
			setMessage('');
			notify(t('noteIsCreated'));
			scrollToBottom();
		}
	};
	const editReminder = (note) => {
		setSelectedNote(note);
		setMessage(note.message);
	};
	const removeReminder = async (index) => {
		let newNotes = [];
		profile.notes.map((note, i) => {
			if (note !== null && note !== undefined && index !== i) newNotes.push(note);
		});
		profile.notes = newNotes;
		const resp = await update({ collection: 'counselling_profiles', data: profile });
		if (resp.status === 200) {
			notify(t('Reminder has been deleted!'));
			setProfile({ ...profile, notes: newNotes });
			checkReminderOverdue(profile);
		}
	};
	const openAsignModal = (note) => {
		setAsignNote(note);
		setOpenWellbeingAsignModal(true);
	};
	const closeAsignModal = (data) => {
		setOpenWellbeingAsignModal(false);
		setMessage('');
		if (data) loadCounsellingProfile();
	};

	const showHideButton = (note) => {
		const sameUser = note.user?.id == user?.id;
		return user.role_coverage.target_text == 'All students' || sameUser;
	};

	const hideMessage = async (message, i) => {
		const data = {
			wellbeingId: profile.id,
			noteId: i,
		};
		const resp = await hideWellbeingNote({ data });
		if (resp.status === 200) {
			let newProfile = profile?.notes?.map((note, index) => {
				if (index == i) note.hidden = !note.hidden;
				return note;
			});
			profile.notes = newProfile;
			console.log('new profile', profile);
			setProfile({ ...profile, notes: newProfile });
		}
	};
	const myFilter = (item) => {
		if (selectedFilter === 'Display all') return true;
		if (selectedFilter === 'Notes') return item.type === 'message' || !!item.emotion || !!item.reminder;
		if (selectedFilter === 'Questions') return item.content_type === 'question';
		if (selectedFilter === 'Polls') return item.content_type === 'poll';
	};

	useEffect(() => {
		scrollToBottom();
	}, [selectedFilter]);

	return (
		<div className='wellprofile-container'>
			<div className='wellprofile-inner-cont'>
				<div className='wellprofile-header'>
					<div
						className='wellprofile-back-button'
						onClick={goBack}
						onMouseEnter={() => setHoveredButton(true)}
						onMouseLeave={() => setHoveredButton(false)}
					>
						<Undo style={{ color: hoveredButton ? '#5e639b' : '#9392AD' }} />
					</div>
					<div className='wellprofile-title'>
						<p>
							{profile?.student?.first_name} {profile?.student?.last_name}
						</p>
						<p>{profile?.student?.email}</p>
					</div>

					<div className='wellprofile-data'>
						<div className='wellprofile-data-left'>
							<div className='wellprofile-emotion  w-full justify-between'>
								<p>{t('currentEmotion')} </p>
								<div style={{ width: 24, height: 24 }}>
									{!currentEmotion ? <Loading /> : <img src={emotions_heads[currentEmotion]} alt={currentEmotion} />}
								</div>
							</div>

							<div className='wellprofile-negative-emotion'>
								<p>{t('mostPopularEmotion')} </p>
								<div style={{ width: 24, height: 24 }}>
									{!mostPopularEmotion ? (
										<Loading />
									) : (
										<img src={emotions_heads[mostPopularEmotion]} alt={mostPopularEmotion} />
									)}
								</div>
							</div>
						</div>
						<div className='wellprofile-separator'></div>
						<div className='wellprofile-data-right'>
							<div className='relative w-[70px] h-[70px] flex justify-items-center justify-center items-center '>
								{userResponsePercentage == null ? (
									<div>
										<Loading size={50} />
									</div>
								) : (
									<DonutChartWellbeing width={70} height={70} percentage={Math.min(userResponsePercentage, 1)} />
								)}
							</div>
							<p> {t('ofResponsesToActivePollsQuestion')}</p>
						</div>
					</div>

					<div className='wellprofile-reg-data '>
						<div className='wellprofile-regdata-one'>
							<p>
								{t('class')}: <span>{profile?.student?.classesName}</span>
							</p>
							<p>
								{t('status')}: <span className='capitalize'>{profile?.status}</span>
							</p>
						</div>
						<div className='wellprofile-regdata-one'>
							{/* <p>
								{t('dateOfBirth')}:
								<span>
									{!!profile?.student?.date_of_birth
										? convertDateToFormatddMMyyy(profile?.student?.date_of_birth)
										: '   -'}
								</span>
							</p>
							<p>
								{t('teacher')}:{' '}
								<span>
									{profile?.teacher?.first_name} {profile?.teacher?.last_name}
								</span>
							</p> */}
						</div>
					</div>
					<button
						className='flex flex-row items-center justify-center rounded-full cursor-pointer w-[180px] -mt-10 h-[56px] bg-deepPurple'
						onClick={() => print()}
						disabled={exporting}
					>
						{exporting ? (
							<Loading primaryColor='#FDDF6D' secondaryColor='#FDDF6D' size={25} stroke={7} />
						) : (
							<div className='flex flex-row items-center justify-center gap-2'>
								<p className='text-highlightColor text-[16px] leading-5 font-semibold'>{t('exportData')}</p>
								<Import color='#fddf6d' />
							</div>
						)}
					</button>
				</div>
				<div className='w-full flex flex-row items-center gap-[15px]'>
					<p className='text-darkenPurple text-[20px] leading-[25px] font-bold'>{t('notes')} </p>
					<div className='w-[152px] h-[42px] relative  '>
						<div
							className='absolute top-0 left-0 w-full h-[42px] rounded-[21px] z-[2] bg-lightBG hover:border-deepPurple flex flex-row items-center justify-center gap-[5px] border border-deselectPurple cursor-pointer'
							onClick={(e) => {
								e.preventDefault();
								setShowDropdown(!showDropdown);
							}}
						>
							<p className='text-deepPurple text-[16px] leading-5 font-semibold'>{t(selectedFilter)}</p>
							<div style={{ transform: showDropdown ? 'scaleY(-1)' : 'none' }}>
								<AngleUp style={{ color: '#9392AD' }} />
							</div>
						</div>
						{showDropdown && (
							<div
								className='absolute bg-lightBG border border-deselectPurple top-0 left-0 rounded-[21px] w-full pt-[42px]  flex flex-col z-[5] '
								onMouseLeave={() => setShowDropdown(false)}
							>
								{filterList?.map((item, index) => (
									<div
										key={index}
										className='text-center text-mudPurple hover:text-deepPurple text-[16px] leading-5 h-[42px] flex items-center justify-center font-semibold cursor-pointer'
										onClick={(e) => {
											setSelectedFilter(item);
											setShowDropdown(false);
										}}
									>
										{t(item)}
									</div>
								))}
							</div>
						)}
					</div>
				</div>
				<div className='wellprofile-notes-container'>
					<div className='wellprofile-notes-wrapper blue-small-scroll' id='counselling-notes-wrapper'>
						<div className='wellprofile-notes-list'>
							{profile?.notes
								?.filter((item) => myFilter(item))
								.map((item, i) => {
									if (item?.type === 'feedback')
										return <FeedbackNote note={item} user={profile?.student} openFeedback={openAsignModal} />;
									if (item?.type == undefined)
										return <FeedbackNote note={item} user={profile?.student} openFeedback={openAsignModal} />;
									if (item?.type === 'assignment') return <WellbeingAssignment note={item} user={profile?.student} />;
									if (item?.type === 'message' && !item?.reminder)
										return <WellbeingMessage note={item} hideMessage={hideMessage} i={i} />;
									if (item?.type === 'answer') return <FeedbackAnswer note={item} user={profile?.student} />;
									if (item?.reminder)
										return (
											<div
												className='wellbeing-reminder-card'
												style={{ background: reminderOverDue ? '#FF6B3D54' : '#bebece85' }}
												key={i}
											>
												<div className='wellbeing-reminder-left'>
													<p>{item.message}</p>

													<p>
														<span style={{ marginRight: 10 }}>
															<Bell style={{ color: '#5e639b' }} />
														</span>
														{t('reminderSetFor')} {convertDateToFormatddMMyyy(item.reminder)}
														<span style={{ marginLeft: 10 }}>-</span> {item.user.first_name}{' '}
														{item.user.last_name}
													</p>
												</div>

												<div className='wellbeing-reminder-right'>
													<div className='wellbeing-right-top'>
														<div
															style={{ cursor: 'pointer' }}
															onClick={(e) => {
																e.preventDefault();
																if (!!selectedNote) {
																	setSelectedNote(null);
																	setMessage('');
																	return;
																} else editReminder(item);
															}}
															data-tip={!!selectedNote ? t('Stop editing') : t('editReminder')}
															data-for='editReminder'
														>
															<EditTransparent
																style={{ cursor: 'pointer', color: !!selectedNote ? '#5E639B' : '#BEBECE' }}
															/>
															<ReactTooltip
																backgroundColor='#5E639B'
																arrowColor='#5E639B'
																place='top'
																effect='solid'
																id='editReminder'
																className='wellbeing-tooltip'
															/>
														</div>
														<div
															style={{ cursor: 'pointer', marginLeft: 21 }}
															data-tip={t('removeReminder')}
															data-for='removeReminder'
															onClick={(e) => {
																e.preventDefault();
																openDeleteConfirmModal(i);
															}}
														>
															<DeleteTransparent style={{ color: '#FF747F' }} />
															<ReactTooltip
																backgroundColor='#5E639B'
																arrowColor='#5E639B'
																place='top'
																effect='solid'
																id='removeReminder'
																className='wellbeing-tooltip'
															/>
														</div>
													</div>
													<div className='wellbeing-right-down'>
														<p>{format_day_numbers_month(item.created_date)}</p>
														{showHideButton(item) && !item.hidden && (
															<span
																style={{ cursor: 'pointer', marginLeft: 21 }}
																onClick={() => hideMessage(item, i)}
																data-tip={t('messageIsVisibleToAll')}
																data-for='unhideReminder'
															>
																<SeenTransparent style={{ color: '#9392AD' }} />
																<ReactTooltip
																	backgroundColor='#5E639B'
																	arrowColor='#5E639B'
																	place='top'
																	effect='solid'
																	id='unhideReminder'
																	className='wellbeing-tooltip'
																/>
															</span>
														)}
														{showHideButton(item) && item.hidden && (
															<span
																style={{ cursor: 'pointer', marginLeft: 21 }}
																onClick={() => hideMessage(item, i)}
																data-tip={t('messageOnlyVisibleToStaffWithSpecialAccess')}
																data-for='hideReminder'
															>
																<CloseAye />
																<ReactTooltip
																	backgroundColor='#5E639B'
																	arrowColor='#5E639B'
																	place='top'
																	effect='solid'
																	id='hideReminder'
																	className='wellbeing-tooltip'
																/>
															</span>
														)}
													</div>
												</div>
											</div>
										);
								})}
						</div>
					</div>
				</div>
				<div className='wellprofile-footer'>
					<TextareaAutosize
						placeholder={t('enterYourNoteHere')}
						minRows={1}
						maxRows={5}
						value={message}
						style={{ resize: 'none', height: 'unset', color: '#5E639B', fontWeight: 600, fontSize: 16 }}
						autoFocus
						onChange={(e) => {
							e.preventDefault();
							setMessage(e.target.value);
						}}
					/>
					<div
						className='wellprofile-paperplane'
						onClick={(e) => {
							e.preventDefault();
							openAsignModal({});
						}}
					>
						<div>
							<AssignAction />
						</div>
						<div style={{ marginLeft: 5 }}>{t('assignAction')} </div>
					</div>
					<div
						className='wellprofile-paperplane'
						onClick={(e) => {
							e.preventDefault();
							createNote();
						}}
					>
						<div>
							<PaperPlane />
						</div>
						<div style={{ marginLeft: 5 }}>{t('addNote')} </div>
					</div>
					<div
						className='wellprofile-bell'
						onClick={(e) => {
							e.preventDefault();
							openReminderModal();
						}}
						data-tip={!!selectedNote ? t('editReminder') : t('addReminder')}
						data-for='addReminder'
					>
						<Bell />
						<ReactTooltip
							backgroundColor='#5E639B'
							arrowColor='#5E639B'
							place='top'
							effect='solid'
							id='addReminder'
							className='wellbeing-tooltip'
						/>
					</div>
				</div>
			</div>
			<ReminderModal
				modalIsOpen={reminderModal}
				closeModal={closeReminderModal}
				profile={profile}
				selectedNote={selectedNote}
				createReminder={createReminder}
			/>
			<WellBeingAsignModal
				modalIsOpen={openWellbeingAsignModal}
				closeModal={closeAsignModal}
				student={profile?.student}
				note={asignNote}
				assignMessage={message}
			/>
			<ConfirmationModal
				message={modalMessage}
				modalIsOpen={openDeleteConfirm}
				closeModal={closeDeleteConfirm}
				confirmation={changeStatus}
			/>
		</div>
	);
}

export const WellbeingMessage = ({ note, user, hideMessage, i }) => {
	const { t, locale } = useTranslation();
	return (
		<div className='wellprofile-card-bottom' key={i}>
			<p>
				{note.user.first_name} {note.user.last_name}
			</p>
			<p className='wellprofile-bottom-message'>{note.message}</p>
			<div style={{ flex: 1 }}></div>
			<span className='wellprofile-bottom-time'>{format_day_numbers_month(note.created_date)}</span>
			{!note.hidden ? (
				<div
					style={{ cursor: 'pointer' }}
					onClick={() => hideMessage(note, i)}
					data-tip={t('messageIsVisibleToAll')}
					data-for='unhideReminder'
				>
					<SeenTransparent style={{ color: '#9392AD' }} />
				</div>
			) : (
				<div
					style={{ cursor: 'pointer' }}
					onClick={() => hideMessage(note, i)}
					data-tip={t('messageOnlyVisibleToStaffWithSpecialAccess')}
					data-for='hideReminder'
				>
					<CloseAye />
				</div>
			)}
		</div>
	);
};
const FeedbackNote = ({ note, user, openFeedback = () => {} }) => {
	const { t, locale } = useTranslation();
	return (
		<>
			{note?.list?.map((nextNote) => {
				if (nextNote.type === 'assignment') return <NextNoteAssignment note={nextNote} />;
				if (nextNote.type === 'teacherAction') return <NextNoteComment note={nextNote} />;
				return <div>test</div>;
			})}
			<div className='wellprofile-card-top'>
				<img src={emotions_heads[note.emotion]} alt='Users' height='50' className={i18n.language == 'ar' ? 'ar-transform' : ''} />
				<p>
					{!!note.message ? `"${note.message}"` : 'No message'} - {user.first_name} {user.last_name}
				</p>
				<div style={{ flex: 1 }}></div>
				{note.feedback_id && (
					<div className='wellprofile-assign-button' onClick={() => openFeedback(note)}>
						<p>{t('assignAction')} </p>
					</div>
				)}
			</div>
		</>
	);
};
export const FeedbackAnswer = ({ note, user }) => {
	const { t, locale } = useTranslation();
	return (
		<div className='w-full py-[35px] px-[40px] mt-[20px] rounded-[5px] bg-white flex flex-row items-center justify-between'>
			<div className='flex flex-col justify-center gap-[25px] max-w-[65%]'>
				<p className='text-deepPurple text-[16px] font-bold leading-5 '>{note.title}</p>
				<div className='flex flex-row items-center'>
					{note.answers?.map((answer, index) => (
						<span className='text-deepPurple text-[16px] font-medium leading-5 ' key={index}>
							{index === 0 ? `"${answer}"` : `,"${answer}"`}
						</span>
					))}
					<span className='text-deepPurple text-[16px] font-bold leading-5 ml-[15px] whitespace-nowrap'>
						{`- ${user?.first_name} ${user?.last_name}`}
					</span>
				</div>
			</div>
			<div className=' flex flex-col items-end justify-center gap-[25px] '>
				<div className='flex flex-row items-center justify-center gap-[5px]'>
					{note.content_type === 'poll' ? <PollIconBlue /> : <QuestionIconBlue />}
					<p className='text-mudPurple text-[14px] font-medium leading-5 '>
						{note.content_type === 'poll' ? t('open_poll') : t('openQuestion')}
					</p>
				</div>
				<p className='text-mudPurple text-[16px] font-medium leading-5 '>{format_day_numbers_month(note.created_date)}</p>
			</div>
		</div>
	);
};

const NextNoteAssignment = ({ note, hideMessage = () => {}, i = 0 }) => {
	const { t, locale } = useTranslation();
	return (
		<div className='wellprofile-card-bottom -mt-[10px] pt-[40px]'>
			<div>
				<p className='mb-[10px]'>
					{t('Assignment for')}: {note?.assignedTo?.first_name} {note?.assignedTo?.last_name}
				</p>
				<p className='wellprofile-bottom-message'>
					"{note.actionMessage}" - {note?.assignedBy?.first_name} {note?.assignedBy?.first_name}
				</p>
			</div>
			<div style={{ flex: 1 }}></div>
			<span className='wellprofile-bottom-time'>{format_day_numbers_month(note.created_date)}</span>
			{!note.hidden ? (
				<div
					style={{ cursor: 'pointer' }}
					onClick={() => hideMessage(note, i)}
					data-tip={t('messageIsVisibleToAll')}
					data-for='unhideReminder'
				>
					<SeenTransparent style={{ color: '#9392AD' }} />
				</div>
			) : (
				<div
					style={{ cursor: 'pointer' }}
					onClick={() => hideMessage(note, i)}
					data-tip={t('messageOnlyVisibleToStaffWithSpecialAccess')}
					data-for='hideReminder'
				>
					<CloseAye />
				</div>
			)}
		</div>
	);
};
export const WellbeingAssignment = ({ note }) => {
	const { t, locale } = useTranslation();
	// console.log(note.list);
	return (
		<>
			{note?.list?.map((nextNote) => {
				if (nextNote.type === 'assignment') return <NextNoteAssignment note={nextNote} />;
				if (nextNote.type === 'teacherAction') return <NextNoteComment note={nextNote} />;
				return <div>test</div>;
			})}
			<div className='wellprofile-card-bottom bg-[white]'>
				<div>
					<p className='mb-[10px]'>
						{t('Assignment for')}: {note?.assignedTo?.first_name} {note?.assignedTo?.last_name}
					</p>
					<p className='wellprofile-bottom-message'>
						"{note.actionMessage}" - {note?.assignedBy?.first_name} {note?.assignedBy?.first_name}
					</p>
				</div>
				<div style={{ flex: 1 }}></div>
				<span className='wellprofile-bottom-time'>{format_day_numbers_month(note.created_date)}</span>
			</div>
		</>
	);
};

const NextNoteComment = ({ note, hideMessage = () => {}, i = 0 }) => {
	const { t, locale } = useTranslation();
	return (
		<div className='wellprofile-card-bottom -mt-[10px] pt-[40px]'>
			<p>
				{note?.user?.first_name} {note?.user?.last_name}
			</p>
			<p className='wellprofile-bottom-message'>{note.message}</p>
			<div style={{ flex: 1 }}></div>
			<span className='wellprofile-bottom-time'>{format_day_numbers_month(note.created_date)}</span>
			{/* {!note.hidden ? (
				<div
					style={{ cursor: 'pointer' }}
					onClick={() => hideMessage(note, i)}
					data-tip={'Message is visible to all'}
					data-for='unhideReminder'
				>
					<SeenTransparent style={{ color: '#9392AD' }} />
				</div>
			) : (
				<div
					style={{ cursor: 'pointer' }}
					onClick={() => hideMessage(note, i)}
					data-tip={'Message only visible to staff with special access'}
					data-for='hideReminder'
				>
					<CloseAye />
				</div>
			)} */}
		</div>
	);
};

export default StudentWellbeingProfile;
