import React, { useRef } from 'react';

import { ReactComponent as Filter } from '../../../assets/svg/filter.svg';
import { ReactComponent as Archive } from '../../../assets/svg/archive.svg';
import { ReactComponent as DropdownTransparent } from '../../../assets/svg/DropdownTransparent.svg';
import { useState } from 'react';
import { ReactComponent as Search } from '../../../assets/svg/Search.svg';
import SingleContent from './classroom/SingleContent';
import { useEffect } from 'react';
import { deleteById, getLiveResults } from '../../../server/server';
import { useUser } from '../../../auth';
import ShowContentResultsModal from '../../modals/ShowContentResultsModal';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { datesAreOnSameDay, format_day, notify } from '../../../utils/util';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ReactComponent as Calendar } from '../../../assets/svg/Calendar.svg';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactComponent as AngleUp } from '../../../assets/svg/AngleUp.svg';
import { useHistory } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../Loading';
import { localeMap } from '../../../constants/Constants';
import i18n from '../../../i18n';
import DatePickerCustom from '../../DatePickerCustom';
// to be added to date filter if need so?
const day = 60 * 60 * 24 * 1000;

function Classroom({ handleDelete, refresh, setRefresh }) {
	const { t, locale } = useTranslation();
	const history = useHistory();
	const { user } = useUser();
	const [searchText, setSearchText] = useState('');
	const [contents, setContents] = useState([]);
	const [selContent, setSelContent] = useState({});
	const [loadingMore, setLoadingMore] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showResultsModal, setShowResultsModal] = useState(false);
	const [contentSize, setContentSize] = useState(-1);
	const [expandButton, setExpandButton] = useState(false);
	const [filterOptions, setFilterOptions] = useState(false);
	const [filterType, setFilterType] = useState('all');
	const [showFilterType, setShowFilterType] = useState(false);
	const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 20 * day));
	const [endDate, setEndDate] = useState(new Date());
	const [startDatePicker, setStartDatePicker] = useState(false);
	const [endDatePicker, setEndDatePicker] = useState(false);
	const [archive, setArchive] = useState(false);
	const [resultNum, setResultNum] = useState(0);
	const [hasMore, setHasMore] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedFilterLabel, setSelectedFilterLabel] = useState('Display all');
	const [showOnlyMineContent, setShowOnlyMineContent] = useState(false);
	const timeout = useRef(null);
	const [rangeChanged, setRangeChanged] = useState(new Date());

	const [deleteModalOpen, setDelModalOpen] = useState(false);

	const openResult = (content) => {
		setSelContent(content);
		setShowResultsModal(true);
	};

	const openDeleteForSingle = (content) => {
		setSelContent(content);
		setDelModalOpen(true);
	};
	const closeDelModal = () => {
		setDelModalOpen(false);
	};
	const deleteCont = async () => {
		const resp = await deleteById({ collection: 'content', id: selContent?.id });
		if (resp.status === 200) {
			setShowResultsModal(false);
			setDelModalOpen(false);
			loadLiveResults();
			notify(t('contentDeleted'));
		}
	};

	const loadLiveResults = async (
		refresh_data = false,
		numbers = 0,
		contentType = ['poll', 'question', 'survey', 'journal'],
		text = searchText,
		showOnlyMine = false
	) => {
		const startFunctionTime = Date.now();
		let start = startDate;
		start.setHours(0);
		start.setMinutes(0);
		let end = endDate;
		end.setHours(0);
		end.setMinutes(0);
		const data = {
			user_id: user.id,
			status: archive ? 'stop' : 'live',
			number_of_data: numbers,
			refresh_data: refresh_data,
			contentType,
			searchText: text,
			showUserDataOnly: showOnlyMine,
		};
		if (filterOptions) {
			data.startDate = start.getTime();
			data.endDate = end.getTime();
		}

		const resp = await getLiveResults(data);
		if (resp?.status === 200) {
			const data = resp?.data;
			if (numbers === 0) {
				console.log('classroom list ', resp.data);
				setLoadingMore(true);
				setContents(data?.results);
				setHasMore(data.size > data.results?.length);
				console.log('has more ', data.size > data.results?.length);
			} else {
				setContents((prev) => [...prev, ...data?.results]);
				setHasMore(data?.size > contents.length + data?.results?.length);
				console.log('has more add', data?.size > contents.length + data?.results?.length);
			}
			setLoadingMore(false);
			const endFunctionTime = Date.now();
			const timeSpent = endFunctionTime - startFunctionTime;
			setTimeout(() => setLoading(false), Math.max(timeSpent, 400));
		} else {
			setContents([]);
			setResultNum(0);
			setLoading(false);
		}
	};
	const closeResultsModal = () => {
		setShowResultsModal(false);
	};

	useEffect(() => {
		let contentType = [];
		if (filterOptions) {
			console.log('filterType', filterType);
			contentType = filterType == 'all' ? ['poll', 'question', 'survey', 'journal'] : [filterType];
		} else {
			contentType = ['poll', 'question', 'survey', 'journal'];
		}
		var myDiv = document.getElementById('c-scroll-inf-task');
		myDiv?.scrollTo(0, 0);
		loadLiveResults(false, 0, contentType, '', showOnlyMineContent);
		setSearchText('');
		setLoading(true);
	}, [archive, rangeChanged, refresh]);

	const loadNext = (size) => {
		const contentType = filterType == 'all' ? ['poll', 'question', 'survey', 'journal'] : [filterType];
		setLoadingMore(true);
		loadLiveResults(false, size, contentType, searchText);
	};

	const changeStartDate = (date) => {
		if (date === null && date === undefined) return;
		setStartDate(date);
	};
	const changeEndDate = (date) => {
		if (date === null && date === undefined) return;
		setEndDate(date);
	};
	const filterTypeMap = {
		all: t('displayAll'),
		question: t('questions'),
		poll: t('polls'),
		survey: t('surveys'),
		journal: t('journals'),
	};
	const filterTypes = ['all', 'question', 'poll', 'survey', 'journal'];
	const openPage = (page) => {
		history.push(page);
	};

	const changeFilterType = (type) => {
		const contentType = type == 'all' ? ['poll', 'question', 'survey', 'journal'] : [type];
		setFilterType(type);
		var myDiv = document.getElementById('c-scroll-inf-task');
		myDiv?.scrollTo(0, 0);
		loadLiveResults(true, 0, contentType, searchText, showOnlyMineContent);
	};

	const doSearch = (text) => {
		const contentType = filterType == 'all' ? ['poll', 'question', 'survey', 'journal'] : [filterType];
		loadLiveResults(true, 0, contentType, text, showOnlyMineContent);
	};

	const changeSearchText = async (text) => {
		setSearchText(text);
		if (timeout.current != null) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			doSearch(text);
		}, 700);
	};

	// useEffect(() => {
	// 	loadLiveResults();
	// }, [refresh]);

	const changeDisplayFilterByOwner = (type, showOnlyMine = false) => {
		setLoading(true);
		const contentType = filterType == 'all' ? ['poll', 'question', 'survey', 'journal'] : [filterType];
		setSelectedFilterLabel(type);
		setShowOnlyMineContent(showOnlyMine);
		setShowDropdown(false);
		loadLiveResults(false, 0, contentType, searchText, showOnlyMine);
	};

	const handleDateSelect = (date) => {
		if (startDate !== null && endDate !== null) {
			setStartDate(date);
			setEndDate(null);
			return;
		}
		if (startDate !== null && endDate === null && startDate < date) {
			setEndDate(date);
			return;
		}
		if (startDate !== null && endDate === null && startDate > date) {
			setStartDate(date);
			return;
		}
	};

	const updateAnalytics = () => {
		if (startDate !== null && endDate !== null) {
			setRangeChanged(new Date());
			return;
		}
	};
	const handleLastMonth = (start, end) => {
		setStartDate(start);
		setEndDate(end);
	};

	return (
		<div className='relative flex flex-col h-full'>
			<div
				className={`rounded-[10px] px-4 bg-white w-full flex flex-col items-center  ${
					filterOptions ? 'h-[136px] justify-between py-2' : 'h-[68px] justify-center'
				}`}
			>
				<div className='flex items-center w-full'>
					<div
						className='emotions-dropdown-container not-selectable '
						style={{ width: 142, height: 40, marginRight: 10, zIndex: 6 }}
						onMouseLeave={() => setShowDropdown(false)}
					>
						<div
							className='classroom-emotions-dropdown bg-[#FAFAFF] hover:bg-lightBG border-[#FAFAFF]'
							onClick={(e) => {
								e.preventDefault();
								setShowDropdown(!showDropdown);
							}}
						>
							<p style={{}}>{t(selectedFilterLabel)}</p>
							<div style={{ transform: showDropdown ? 'scaleY(-1)' : 'none' }}>
								<DropdownTransparent style={{ color: '#5E639B' }} />
							</div>
						</div>
						{showDropdown && (
							<div className='flex flex-col top-[40px] px-3 justify-between items-center w-full text-darkPurple absolute rounded-[8px] border border-lightBG bg-white'>
								<div
									onClick={(e) => {
										e.preventDefault();
										changeDisplayFilterByOwner('Display all', false);
									}}
									className='w-full flex items-center cursor-pointer'
								>
									<div className='w-[13px] h-[13px] rounded-full border border-mudPurple flex items-center justify-center'>
										{selectedFilterLabel === 'Display all' && (
											<div className='bg-darkPurple w-[5px] h-[5px]  min-w-[5px] min-h-[5px] rounded-full'></div>
										)}
									</div>
									<p className='text-darkPurple font-semibold text-sm my-2 ml-2'>{t('displayAll')} </p>
								</div>
								<div
									onClick={(e) => {
										e.preventDefault();
										changeDisplayFilterByOwner('Only mine', true);
									}}
									className='w-full flex items-center cursor-pointer'
								>
									<div className='w-[13px] h-[13px] rounded-full border border-mudPurple flex items-center justify-center'>
										{selectedFilterLabel === 'Only mine' && (
											<div className='bg-darkPurple w-[5px] h-[5px]  min-w-[5px] min-h-[5px] rounded-full'></div>
										)}
									</div>
									<p className='text-darkPurple font-semibold text-sm my-2 ml-2'>{t('onlyMine')} </p>
								</div>
							</div>
						)}
					</div>
					<div className='flex items-center flex-1'>
						<div className='stroke-deselectPurple  bg-containerGray flex flex-row items-center h-[41px] px-4 w-[270px] rounded-full'>
							<input
								type='text'
								placeholder={t('searchResults')}
								className='w-full h-full text-deepPurple font-medium text-[16px] leading-5 bg-containerGray focus:font-normal   placeholder:text-mudPurple placeholder:font-normal'
								onChange={(e) => changeSearchText(e.target.value)}
								value={searchText}
							/>
							<Search />
						</div>
						<div
							className={`h-[40px] ml-2 flex pointer items-center justify-center  font-semibold px-3 py-1 rounded-full ${
								filterOptions ? 'bg-lightBG text-deepPurple' : 'bg-containerGray text-mudPurple'
							}`}
							onClick={() => setFilterOptions(!filterOptions)}
						>
							<Filter fill={filterOptions ? '#5E639B' : '#9392AD'} />
							<p className='ml-2'>{t('filter')} </p>
						</div>
						{loading && (
							<div className='ml-[10px]'>
								<Loading />
							</div>
						)}
					</div>

					<div className='flex items-center pointer' onClick={() => setArchive(!archive)}>
						<Archive fill={archive ? '#413F5E' : '#9392AD'} />
						<span className={`text-[16px] font-bold ml-2 ${archive ? 'text-darkPurple' : 'text-mudPurple'}`}>
							{t('archive')}{' '}
						</span>
					</div>
				</div>
				{filterOptions && <div style={{ borderTop: '1px dashed #BEBECE', height: 1 }} className='w-full my-1'></div>}
				{filterOptions && (
					<div className='w-full relative'>
						<div className='flex items-center justify-start'>
							<DatePickerCustom
								endDate={endDate}
								startDate={startDate}
								handleDateSelect={handleDateSelect}
								handleLastMonth={handleLastMonth}
								handleOkButton={updateAnalytics}
							/>
							<div>
								<div
									className='ml-3 pointer flex flex-col relative min-w-[140px] '
									onMouseLeave={() => setShowFilterType(false)}
								>
									<div
										className={`rounded-full flex items-center justify-center gap-3 w-full h-[40px] px-2 ${
											showFilterType ? 'bg-lightBG' : 'bg-containerGray'
										}`}
										onClick={() => setShowFilterType(!showFilterType)}
									>
										<p className='text-darkPurple font-semibold'>
											{filterType == null ? t('notSelected') : filterTypeMap[filterType]}
										</p>
										<div style={{ transform: showFilterType ? 'rotate(180deg)' : 'none' }}>
											<AngleUp style={{ color: '#6B6C7E' }} />
										</div>
									</div>
									{showFilterType && (
										<div
											className='flex flex-col top-[40px] px-3 justify-between items-center w-full text-darkPurple absolute rounded-[8px] border border-lightBG bg-white'
											style={{ zIndex: 2 }}
										>
											{filterTypes.map((item, index) => (
												<div
													onClick={(e) => {
														e.preventDefault();
														changeFilterType(item);
													}}
													className='w-full flex items-center'
													key={index}
												>
													<div className='min-w-[13px] h-[13px] rounded-full border border-mudPurple flex items-center justify-center'>
														{filterType === item && (
															<div className='bg-darkPurple w-[5px] h-[5px]  min-w-[5px] min-h-[5px] rounded-full'></div>
														)}
													</div>
													<p className='text-darkPurple font-semibold text-sm my-2 ml-2 ellipsis'>
														{filterTypeMap[item]}
													</p>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className='p-2 rounded-[10px] bg-white mt-4 h-full grow flex flex-col'>
				<div className=' px-6 mb-5 gray-small-scroll overflow-y-scroll h-0 flex-auto' id='c-scroll-inf-task'>
					{!loading && (
						<InfiniteScroll
							dataLength={contents?.length}
							next={() => {
								loadNext(contents?.length);
							}}
							hasMore={hasMore}
							scrollableTarget='c-scroll-inf-task'
							inverse={false}
							// style={{ visibility: loading ? 'hidden' : 'visible' }}
						>
							{contents?.map((content, index) => {
								const previousNote = index > 0 && contents[index - 1];
								const previousNoteDay = +previousNote?.publicationDate;
								const sameDay =
									!!previousNoteDay && datesAreOnSameDay(new Date(+content?.publicationDate), new Date(previousNoteDay));

								return (
									<div key={index}>
										{!sameDay && (
											<div className='w-full mt-[15px] flex justify-center gap-1'>
												<span className='text-sm font-medium  text-mudPurple'>{t('date')}: </span>
												<span className='text-sm font-medium  text-darkenPurple'>
													{format_day(+content?.publicationDate, false, i18n.language)}
												</span>
											</div>
										)}
										<SingleContent content={content} openResult={openResult} sameDay={sameDay} />
									</div>
								);
							})}
						</InfiniteScroll>
					)}
					{loadingMore && (
						<div className='loading-center pt-[10px]'>
							<Loading primaryColor='#5E639B' secondaryColor='#5E639B' />
						</div>
					)}
					<div
						className={`loading-center h-full ${
							loading ? 'visible opacity-100' : 'hidden opacity-0'
						} transition-all duration-200 ease-in-out`}
					>
						<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={50} stroke={3} />
					</div>
				</div>
			</div>
			<div
				className={`absolute bottom-[10px] right-[10px]  ${
					expandButton ? 'h-[185px] w-[340px] no-delay' : 'h-[70px] w-[70px] create-button-container'
				}`}
				onMouseLeave={() => {
					setExpandButton(false);
				}}
			>
				<div>
					<div>
						<div
							className={`absolute right-[10px] font-bold pointer bg-deepPurple rounded-3xl text-white py-[0.8rem] px-5 flex items-center
								${expandButton ? 'show-create-post-button' : 'hide-create-post-button'} create-post-button`}
							onClick={() => openPage('/readytogo')}
							id='upcoming-content-modal-trigger'
						>
							<div className='relative flex items-center  '>
								<div className='relative flex items-center p-1  bg-highlightColor rounded-full'>
									<FaPlus fill='#5E639B' />
								</div>
								<span className='ml-3'>{t('runReadyToGo')} </span>
							</div>
						</div>
					</div>
					<div>
						<div
							className={`absolute right-[10px] font-bold pointer bg-deepPurple rounded-3xl text-white py-[0.8rem] px-5 flex items-center
							${expandButton ? 'show-create-post-button-higher' : 'hide-create-post-button'} create-post-button`}
							onClick={() => openPage('/content')}
							id='upcoming-content-modal-trigger'
						>
							<div className='relative flex items-center  '>
								<div className='relative flex items-center p-1  bg-highlightColor rounded-full'>
									<FaPlus fill='#5E639B' />
								</div>
								<span className='ml-3'>{t('createContent')} </span>
							</div>
						</div>
					</div>
				</div>

				<div
					className=' absolute bottom-[10px] right-[10px] font-bold pointer bg-deepPurple rounded-3xl text-white h-[50px] w-[50px] justify-center flex items-center'
					id='upcoming-content-modal-trigger'
					onMouseEnter={() => setExpandButton(true)}
				>
					<div
						className={`relative flex items-center p-1 feed-create-button   rounded-full ${
							expandButton ? 'bg-white feed-button-x' : ' feed-button-times'
						}`}
					>
						<FaPlus fill={'#5E639B'} />
					</div>
				</div>
			</div>
			<ShowContentResultsModal
				content={selContent}
				showRun={showResultsModal}
				closeRun={closeResultsModal}
				setRefresh={setRefresh}
				deleteContent={openDeleteForSingle}
			/>
			<ConfirmationModal
				modalIsOpen={deleteModalOpen}
				closeModal={closeDelModal}
				confirmation={deleteCont}
				message={t('doYouWantToDeleteThis')}
				// subMessage={`Delete ${selContent?.type} content?`}
				subMessage={i18n.language == 'en' ? `Delete ${selContent?.type} content?` : ''}
			/>
		</div>
	);
}

export default Classroom;
