import React from 'react';
import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import '../styles/DatePickerCustom.css';
import { ReactComponent as ArrowLeft } from '../assets/svg/ArrowLeft.svg';
import { ReactComponent as Calendar } from '../assets/svg/Calendar.svg';
import { format_month_year } from '../utils/util';
import { useTranslation } from 'react-i18next';

function DatePickerCustom({ startDate, endDate, handleDateSelect, handleOkButton, handleLastMonth }) {
	const { t, locale } = useTranslation();
	const [selectedDate, setSelectedDate] = useState(dayjs());
	const [selectedButton, setSelectedButton] = useState('');
	const [pickerOpen, setPickerOpen] = useState(false);

	const currentDay = useMemo(() => dayjs().toDate(), []);

	const firstDayOfTheMonth = useMemo(() => selectedDate.clone().startOf('month'), [selectedDate]);

	const firstDayOfFirstWeekOfMonth = useMemo(() => dayjs(firstDayOfTheMonth).startOf('week'), [firstDayOfTheMonth]);

	const generateFirstDayOfEachWeek = useCallback((day) => {
		const dates = [day];
		for (let i = 1; i < 6; i++) {
			const date = day.clone().add(i, 'week');
			dates.push(date);
		}
		return dates;
	}, []);

	const generateWeek = useCallback((day) => {
		const dates = [];
		for (let i = 0; i < 7; i++) {
			const date = day.clone().add(i, 'day').toDate();
			dates.push(date);
		}
		return dates;
	}, []);

	const generateWeeksOfTheMonth = useMemo(() => {
		const firstDayOfEachWeek = generateFirstDayOfEachWeek(firstDayOfFirstWeekOfMonth);
		return firstDayOfEachWeek
			.map((date) => generateWeek(date))
			.filter((week) => dayjs(week[0]).toDate().getMonth() <= selectedDate.toDate().getMonth());
	}, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

	const handleButtonClick = (button) => {
		if (selectedButton !== button) {
			setSelectedButton(button);
			switch (button) {
				case 'lastWeek':
					handleLastMonth(dayjs(currentDay).subtract(7, 'day').toDate(), currentDay);
					break;
				case 'lastMonth':
					handleLastMonth(dayjs(currentDay).subtract(1, 'month').toDate(), currentDay);
					break;
				case 'last3Months':
					handleLastMonth(dayjs(currentDay).subtract(3, 'month').toDate(), currentDay);
					break;
				default: //lastYear
					handleLastMonth(dayjs(currentDay).subtract(1, 'year').toDate(), currentDay);
					break;
			}
			confirmChange();
			// if (button === 'lastMonth') {
			// 	handleLastMonth(dayjs(currentDay).subtract(1, 'month').toDate(), currentDay);
			// } else if (button === 'lastQuater') handleLastMonth(dayjs(currentDay).subtract(3, 'month').toDate(), currentDay);
			// else if (button === 'lastYear') handleLastMonth(dayjs(currentDay).subtract(1, 'year').toDate(), currentDay);
		}
	};

	const confirmChange = () => {
		handleOkButton();
		setPickerOpen(false);
	};

	const disableNextButton = () => {
		return (
			selectedDate.clone().toDate().getMonth() === currentDay.getMonth() &&
			selectedDate.clone().toDate().getFullYear() === currentDay.getFullYear()
		);
	};

	const disableOkButton = () => {
		return startDate === null || endDate === null;
	};

	return (
		<div style={{ position: 'relative', zIndex: 2 }}>
			<div className='calendar-date-range-button' style={{ zIndex: 3 }} onClick={() => setPickerOpen((prev) => !prev)}>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Calendar style={{ color: '#5E639B' }} />
					<p className='calendar-range-button-text' style={{ marginLeft: 10 }} onClick={() => {}}>
						{t('from')} <span>{startDate?.toLocaleDateString()}</span>
					</p>
				</div>
				<p className='calendar-range-button-text' onClick={() => {}}>
					{t('to')} <span> {endDate?.toLocaleDateString() ? endDate?.toLocaleDateString() : t('Not selected')} </span>
				</p>
			</div>
			{pickerOpen && (
				<div
					className='calendar-main-wrapper'
					style={{ position: 'absolute', top: 0, left: 0 }}
					onMouseLeave={() => setPickerOpen(false)}
				>
					<div className='calendar-header-label'>{t('Preset Ranges')}</div>
					<div className='calendar-header-buttons'>
						<button
							onClick={() => handleButtonClick('lastWeek')}
							className='calendar-header-single-button'
							style={{
								backgroundColor: selectedButton === 'lastWeek' ? '#5E639B' : '#ECEBF8',
								color: selectedButton === 'lastWeek' ? '#ffffff' : '#5E639B',
							}}
						>
							{t('7 Days')}
						</button>
						<button
							onClick={() => handleButtonClick('lastMonth')}
							className='calendar-header-single-button'
							style={{
								backgroundColor: selectedButton === 'lastMonth' ? '#5E639B' : '#ECEBF8',
								color: selectedButton === 'lastMonth' ? '#ffffff' : '#5E639B',
							}}
						>
							{t('1 Month')}
						</button>
						<button
							onClick={() => handleButtonClick('last3Months')}
							className='calendar-header-single-button'
							style={{
								backgroundColor: selectedButton === 'last3Months' ? '#5E639B' : '#ECEBF8',
								color: selectedButton === 'last3Months' ? '#ffffff' : '#5E639B',
							}}
						>
							{t('3 Months')}
						</button>
						<button
							onClick={() => handleButtonClick('lastYear')}
							className='calendar-header-single-button'
							style={{
								backgroundColor: selectedButton === 'lastYear' ? '#5E639B' : '#ECEBF8',
								color: selectedButton === 'lastYear' ? '#ffffff' : '#5E639B',
							}}
						>
							{t('1 Year')}
						</button>
					</div>
					<div className='calendar-header-label mt-[20px]'>{t('Select time range')}</div>
					<div className='calendar-header-wrapper'>
						<button className='calendar-header-arrow' onClick={() => setSelectedDate((date) => date.subtract(1, 'month'))}>
							<ArrowLeft />
						</button>
						<div className='calendar-header-month-text'>
							{/* {selectedDate.clone().format('MMMM YYYY', { locale: localeMap[i18n.language] })} */}
							{format_month_year(selectedDate.clone().toDate())}
						</div>
						<button
							className={`calendar-header-arrow ${disableNextButton() ? 'calendar-header-arrow-disabled' : ''}`}
							style={{ transform: 'scaleX(-1)' }}
							onClick={() => setSelectedDate((date) => date.add(1, 'month'))}
							disabled={disableNextButton()}
						>
							<ArrowLeft />
						</button>
					</div>
					<div className='calendar-days-wrapper'>
						{generateWeeksOfTheMonth[0].map((day, index) => (
							<div className='week-day-cell' key={`week-day-${index}`}>
								{t(dayjs(day).format('ddd'))}
							</div>
						))}
					</div>
					{generateWeeksOfTheMonth.map((week, weekIndex) => (
						<div
							className='calendar-content-wrapper'
							style={{ marginTop: weekIndex === 0 ? 20 : 10 }}
							key={`week-${weekIndex}`}
						>
							{week.map((day, dayIndex) => (
								<div className='calendar-content-cell' style={{ flex: dayIndex < 6 ? 1 : 0 }} key={`day-${dayIndex}`}>
									<button
										onClick={() => {
											setSelectedButton('');
											handleDateSelect(day);
										}}
										disabled={day > dayjs(currentDay)}
										className='calendar-day-cell'
										style={{
											color:
												selectedDate.clone().toDate().getMonth() !== day.getMonth()
													? '#BEBECE'
													: dayjs(currentDay).isSame(day, 'date')
													? '#E43F5A'
													: day > dayjs(currentDay)
													? '#BEBECE'
													: '#5E639B',
											border:
												dayjs(startDate).isSame(day, 'date') || dayjs(endDate).isSame(day, 'date')
													? '1px solid #5E639B'
													: '',
											background:
												((dayjs(startDate) < day || dayjs(startDate).isSame(day, 'date')) &&
													dayjs(endDate) > day) ||
												dayjs(endDate).isSame(day, 'date')
													? '#ECEBF8'
													: 'white',
											borderRadius: dayjs(startDate).isSame(day, 'date')
												? '6px 0px 0px 6px'
												: dayjs(endDate).isSame(day, 'date')
												? '0px 6px 6px 0px'
												: '0px 0px 0px 0px',
											cursor: day > dayjs(currentDay) ? 'default' : 'pointer',
										}}
									>
										{day.getDate()}
									</button>
									{dayIndex < 6 && (
										<div
											style={{
												height: 32,
												flex: 1,
												background:
													(dayjs(startDate) < day || dayjs(startDate).isSame(day, 'date')) &&
													dayjs(endDate) > day &&
													!dayjs(endDate).isSame(day, 'date')
														? '#ECEBF8'
														: 'white',
											}}
										></div>
									)}
								</div>
							))}
						</div>
					))}
					<button
						onClick={confirmChange}
						className='calendar-ok-button'
						style={{ cursor: disableOkButton() ? 'default' : 'pointer' }}
						disabled={disableOkButton()}
					>
						Ok
					</button>
				</div>
			)}
		</div>
	);
}

export default DatePickerCustom;
