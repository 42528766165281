import React from 'react';

function DonutChartWellbeing({
	percentage = 0,
	width = 70,
	height = 70,
	strokeColor = '#FDDF6D',
	circleColor = '#5E639B',
	textColor = '#fddf6d',
	fontSize = 18,
	lineHeight = 20,
}) {
	return (
		<div style={{ width, height, position: 'relative' }}>
			<svg
				width={`${width}`}
				height={`${height}`}
				viewBox={`0 0 ${width} ${height}`}
				className='donut'
				style={{ transform: 'scaleX(-1)' }}
			>
				<circle cx='50%' cy='50%' r='35%' fill={circleColor}></circle>
				<circle
					transform={`rotate(-90,${width / 2},${height / 2})`}
					cx='50%'
					cy='50%'
					r='45%'
					fill='transparent'
					stroke={strokeColor}
					strokeWidth='10%'
					strokeDasharray={`${width * 0.45 * 2 * Math.PI * percentage} ${width * 0.45 * 2 * Math.PI * (1 - percentage)}`}
					strokeDashoffset='0'
				></circle>
			</svg>
			<div
				style={{
					width,
					height,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'absolute',
					top: 0,
					fontFamily: 'Quicksand',
					fontStyle: 'normal',
					fontWeight: 600,
					fontSize,
					lineHeight,
					color: textColor,
				}}
			>
				{(percentage * 100).toFixed(0)}%
			</div>
		</div>
	);
}

export default DonutChartWellbeing;
