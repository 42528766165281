import React from "react";
import { truncate } from "../../utils/util";
function UrlPreview({ url }) {
    return (
        <div className="link-containter" onClick={() => window.open(url, "_blank", "noopener,noreferrer")}>
            <div className="link-img">
                <img height="55" width="55" src={`https://www.google.com/s2/favicons?sz=64&domain_url=${url}`} />
            </div>
            <div className="link-warp">
                <div className="link-title">
                    <h3>
                        {url.replace(
                            /.+\/\/|www.|\..+|\ben.\b|\bmail.\b|\files.|\bforum.\b|\bblog.\b|\bweb.\b|\bstore.\b|\bgov.\b|\bcdn.\b|\bcloud.\b|\bemail.\b|\bsupport.\b|\bns.\b|\bhost.\b|\bde.\b|\bae.\b|/g,
                            ""
                        )}
                    </h3>
                </div>
                <div className="link-url">
                    <p>{truncate(url, 0, 24).text}</p>
                </div>
            </div>
        </div>
    );
}

export default UrlPreview;
