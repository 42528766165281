import React, { useState, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { useTranslation } from 'react-i18next';
import '../../styles/modals/StudentInfoModal.css';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { ReactComponent as Calendar } from '../../assets/svg/Calendar.svg';
import { ReactComponent as Save } from '../../assets/svg/Save.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/DeleteIcon.svg';
import { Toaster } from 'react-hot-toast';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { formatDateOnly, notify } from '../../utils/util';
import { changeUserAnimal, deleteStudentAsync, email_exists, updateStudent } from '../../server/server';
import { useUser } from '../../auth';
import { localeMap } from '../../constants/Constants';
import { ANIMALS_ARRAY, BACKDROP_OPACITY, MODAL_ANIMATION_TIME } from '../../constants/Constants';
import { ReactComponent as AnimalFootprint } from '../../assets/svg/AnimalFootprint.svg';
import { lazy } from 'react';
import Loading from '../Loading';
import LazyLoad from 'react-lazy-load';
import { animalImages } from '../../assets/animals/AnimalImages';
import ConfirmationModal from './ConfirmationModal';

function StudentPrimaryInfoModal({ studentInfoOpen, closeStudentInfo, student, currentClass, reloadStudentsList }) {
	const { t, locale } = useTranslation();
	const { register, handleSubmit, watch, formState, reset, setValue, getValues, setError } = useForm();
	const { isDirty, isValid, errors } = formState;
	const { user } = useUser();
	const [gender, setGender] = useState('');
	const [birthDate, setBirthDate] = useState(new Date());
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [step, setStep] = useState(1);
	const [processing, setProcessing] = useState(false);
	const [freeAnimals, setFreeAnimals] = useState([]);
	const [usedAnimals, setUsedAnimals] = useState([]);
	const [usedUsersAnimal, setUsedUserAnimal] = useState({});
	const [selectedAnimal, setSelectedAnimal] = useState('');
	const [confirmDeleteStudentModal, setConfirmDeleteStudentModal] = useState(false);
	const handleChange = (newValue) => {
		setBirthDate(newValue);
	};
	useEffect(() => {
		if (studentInfoOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('managementId').style.filter = 'blur(5px)';
			document.getElementById('sideBar').style.filter = 'blur(5px)';
		} else {
			document.getElementById('managementId').style.filter = 'unset';
			document.getElementById('sideBar').style.filter = 'unset';
		}
	}, [studentInfoOpen]);

	useEffect(() => {
		if (student != {}) {
			setValue('first_name', student?.first_name);
			setValue('last_name', student?.last_name);
			setValue('email', student?.email);
			setValue('gender', student?.gender?.toLowerCase());
			setGender(student?.gender?.toLowerCase());
			setBirthDate(new Date(student?.date_of_birth));
			setValue('animal', student?.animal ?? null);
			setValue('student_id', student?.id ?? null);
			//validate_email(student.email);
		}
	}, [student]);

	const createUser = async () => {
		setProcessing(true);
		const formData = getValues();
		const data = {
			first_name: formData.first_name,
			last_name: formData.last_name,
			institution_id: currentClass.institution_id,
			year: currentClass.year,
			classes: [
				{
					id: currentClass.id,
					name: currentClass.name,
					year: currentClass.year,
				},
			],
			primary: true,
			gender: formData.gender,
			date_of_birth: birthDate,
			id: formData.student_id,
			animal: formData.animal,
			role: 0,
		};
		const resp = await updateStudent(data);
		if (resp.status == 200) {
			setProcessing(false);
			setValue('student_id', resp.data?.id);
			//setShowToastModal(true);
			reloadStudentsList();
			if (!student?.id) openAnimals();
			else closeStudentInfo();
		}
	};

	const submitAnimalChange = async () => {
		if (!!selectedAnimal) {
			setProcessing(true);
			const data = {
				studentId: getValues('student_id'),
				animal: selectedAnimal,
				previousAnimalOwnerId: usedUsersAnimal?.id,
			};
			const resp = await changeUserAnimal(data);
			if (resp.status == 200) {
				setProcessing(false);
				reloadStudentsList();
				closeStudentInfo();
			}
		}
	};

	const selectAnimal = (animal) => {
		setSelectedAnimal(animal);
		setUsedUserAnimal({});
	};

	const selectAnimalUser = (user) => {
		setSelectedAnimal(user.animal);
		setUsedUserAnimal(user);
	};

	const openAnimals = () => {
		const studentAnimal = getValues('animal') ?? null;
		const usedAnimals = currentClass?.students?.filter((e) => !!e?.animal && e?.animal != studentAnimal);
		const usedOnlyAnimals = usedAnimals.map((e) => e.animal);
		const freeAnimals = ANIMALS_ARRAY.filter((e) => !usedOnlyAnimals.includes(e) && e != studentAnimal);
		setFreeAnimals(freeAnimals);
		setUsedAnimals(usedAnimals);
		setUsedUserAnimal({});
		setSelectedAnimal('');

		setTimeout(() => {
			setStep(2);
		}, 100);
	};

	const handleGender = (selectedGender) => {
		if (gender !== selectedGender) {
			setGender(selectedGender);
			setValue('gender', selectedGender);
		}
	};

	const closeConfirmDeleteStudentModal = () => {
		setConfirmDeleteStudentModal(false);
	};

	const openConfirmDeleteStudentModal = () => {
		setConfirmDeleteStudentModal(true);
	};

	const removeStudent = async () => {
		if (student !== null || student !== undefined) {
			const resp = await deleteStudentAsync(student);
			if (resp.status === 200) {
				notify(t('studentRemoved'));
				closeConfirmDeleteStudentModal();
				closeStudentInfo();
				reloadStudentsList();
			} else {
			}
		} else {
		}
	};

	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={studentInfoOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				reset();
				setProcessing(false);
				setStep(1);
			}}
			onRequestClose={closeStudentInfo}
			className='noScroll'
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 800,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'scroll',
					paddingTop: 35,
					paddingBottom: 35,
				},
			}}
		>
			<div
				className={`w-full p-[70px] pt-[35px] m-auto bg-whiteContrast relative rounded-[10px] mt-[30px] ${
					step === 2 ? 'pb-[40px]' : ''
				}`}
			>
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<div className='close-modal-icon ' onClick={() => closeStudentInfo()}>
						<CloseModalIcon />
					</div>
				</div>
				<div className='w-full flex flex-row items-center justify-between mt-8'>
					<div className='flex flex-col gap-[10px] '>
						<p className='text-darkenPurple text-[32px] leading-[42px] font-bold '>
							{!student?.id ? t('Add student') : step === 1 ? t('Edit Student') : t('Change avatar')}{' '}
						</p>

						<p className='text-deepPurple text-[18px] leading-[23px] font-medium '>
							{t('Class')}: <span className='text-darkenPurple'>{currentClass?.name}</span>
						</p>
					</div>
					<p className='text-darkenPurple text-[16px] leading-[20px] font-bold '>{t(`Step ${step}/2`)}</p>
				</div>

				{step === 1 && (
					<div className=' mt-[32px]'>
						<form onSubmit={handleSubmit(createUser)}>
							<div className='bg-white rounded-[10px] p-10  '>
								<div className={`student-input ${errors.first_name ? 'pink-border' : ''}`} style={{ position: 'relative' }}>
									<label style={{ color: errors.first_name ? '#FF747F' : '' }}>{t('firstName')} </label>
									<input
										type='text'
										placeholder={t('enterName')}
										autoComplete='off'
										{...register('first_name', { required: true })}
									/>
									{errors.first_name && <span className='field-validation-text'>{t('fieldIsRequired')}</span>}
								</div>
								<div className={`student-input ${errors.last_name ? 'pink-border' : ''}`} style={{ position: 'relative' }}>
									<label style={{ color: errors.last_name ? '#FF747F' : '' }}>{t('lastName')} </label>
									<input
										type='text'
										placeholder={t('enterSurname')}
										autoComplete='off'
										{...register('last_name', { required: true })}
									/>
									{errors.last_name && <span className='field-validation-text'>{t('fieldIsRequired')}</span>}
								</div>
								<div className='student-input ' style={{ position: 'relative' }}>
									<div
										className='w-full h-[56px] border border-deselectPurple rounded-full flex flex-row items-center px-5 cursor-pointer bg-white hover:border-mudPurple hover:bg-whiteContrast'
										onClick={(e) => {
											e.preventDefault();
											setIsDatePickerOpen(true);
										}}
										style={{ marginTop: 0, zIndex: 3 }}
									>
										<Calendar style={{ color: '#5E639B', marginRight: 10 }} />
										<p className='text-deepPurple text-[16px] leading-[20px] font-semibold '>{t('dateOfBirth')} </p>
										<span style={{ flex: 1 }}></span>
										<p className='text-darkenPurple text-[16px] leading-[20px] font-semibold '>
											{formatDateOnly(birthDate)}
										</p>
									</div>
									<div style={{ position: 'absolute', top: 0, zIndex: 2, visibility: 'hidden' }}>
										<LocalizationProvider adapterLocale={localeMap[i18n.language]} dateAdapter={AdapterDateFns}>
											<DesktopDatePicker
												open={isDatePickerOpen}
												onClose={() => setIsDatePickerOpen(false)}
												label='Date desktop'
												inputFormat='MM/dd/yyyy'
												value={birthDate}
												onChange={handleChange}
												maxDate={new Date()}
												renderInput={(params) => <TextField {...params} />}
											/>
										</LocalizationProvider>
									</div>
								</div>

								<div className='mt-[25px]' {...register('gender')}>
									<p className='text-deepPurple text-[16px] leading-[20px] font-semibold '>{t('Choose Gender')}</p>
									<div className=' flex flex-row items-center gap-[15px] mt-[15px]'>
										<div
											className=' flex flex-row items-center gap-[10px] cursor-pointer '
											onClick={() => handleGender('male')}
										>
											<div
												className={`w-[14px] h-[14px] flex items-center justify-center rounded-full border-[2px]  ${
													gender === 'male' ? 'border-deepPurple' : 'border-mudPurple'
												}`}
											>
												{gender === 'male' && <div className='w-[6px] h-[6px] bg-deepPurple rounded-full'></div>}
											</div>
											<p
												className={` text-[16px] leading-[20px] font-semibold ${
													gender === 'male' ? 'text-darkenPurple' : 'text-mudPurple'
												}`}
											>
												{t('male')}
											</p>
										</div>
										<div
											className=' flex flex-row items-center gap-[10px] cursor-pointer '
											onClick={() => handleGender('female')}
										>
											<div
												className={`w-[14px] h-[14px] flex items-center justify-center rounded-full border-[2px]  ${
													gender === 'female' ? 'border-deepPurple' : 'border-mudPurple'
												}`}
											>
												{gender === 'female' && <div className='w-[6px] h-[6px] bg-deepPurple rounded-full'></div>}
											</div>
											<p
												className={` text-[16px] leading-[20px] font-semibold ${
													gender === 'Female' ? 'text-darkenPurple' : 'text-mudPurple'
												}`}
											>
												{t('female')}
											</p>
										</div>
									</div>
								</div>
							</div>
							{!!student.id && (
								<div className='flex-1 flex items-center justify-center mt-5'>
									<div
										className=' flex flex-row items-center justify-center gap-[10px] rounded-full cursor-pointer bg-lightBG px-5 h-[50px] '
										onClick={() => openAnimals()}
									>
										<AnimalFootprint fill='#5E639B' />
										<p className='text-deepPurple text-[18px] leading-[23px] font-semibold '>{t('Choose animal')}</p>
									</div>
								</div>
							)}
							<div className='flex flex-row mt-5 gap-5 justify-center'>
								{!!student?.id && (
									<div
										className='h-[50px] border border-[#FC4C59] rounded-full px-5 flex flex-row items-center justify-center gap-[10px] cursor-pointer '
										onClick={() => {
											openConfirmDeleteStudentModal();
										}}
									>
										<DeleteIcon style={{ color: '#FC4C59' }} />
										<p className={`text-[#FC4C59] text-[18px] leading-[23px] font-semibold`}>{t('deleteStudent')} </p>
									</div>
								)}
								<button
									className='min-w-[210px] h-[50px] bg-deepPurple rounded-full px-5 flex flex-row items-center justify-center  cursor-pointer'
									disabled={processing}
								>
									{processing ? (
										<Loading />
									) : (
										<div className='flex flex-row justify-center gap-[10px]'>
											<p className={`text-white text-[18px] leading-[23px] font-semibold`}>
												{!!student?.id ? t('edit') : t('Create Student')}
											</p>
											<Save color='#ffffff' />
										</div>
									)}
								</button>
							</div>
						</form>
					</div>
				)}
				{step === 2 && (
					<div className=' mt-[25px]'>
						<div className='bg-white rounded-[20px] py-[35px] pl-[65px] pr-[20px]  '>
							<div className='h-[calc(100vh-450px)] overflow-auto pr-[30px] yellow-small-scroll'>
								<p className='text-deepPurple text-[24px] leading-[31px] font-bold text-center'>{t('Available')}</p>
								<div className=' grid grid-cols-2 gap-[20px] mt-[50px]  '>
									{freeAnimals?.map((animal) => (
										<AnimalButton
											animal={animal}
											key={animal}
											selectAnimal={selectAnimal}
											selected={animal == selectedAnimal}
										/>
									))}
								</div>
								<p className='text-darkenPurple text-[24px] leading-[31px] font-bold text-center mt-[30px]'>{t('Taken')}</p>
								<div className='flex flex-row items-center justify-center flex-1'>
									<p className='text-darkenPurple text-[14px] leading-[17px] font-medium text-center mt-[10px] max-w-[350px]'>
										{t('If you will choose already taken character it will auto unpin from existing child')}
									</p>
								</div>
								<div className=' grid grid-cols-2 gap-[20px] mt-[30px]  '>
									{usedAnimals?.map((user) => (
										<AnimalButton
											animal={user.animal}
											selectAnimal={selectAnimalUser}
											selected={user?.animal == selectedAnimal}
											showTakenUser={true}
											student={user}
											key={user.animal}
										/>
									))}
								</div>
							</div>
						</div>
						<div className='flex flex-row mt-5 items-center gap-10 justify-center'>
							<div
								className=' flex flex-row items-center justify-center rounded-full cursor-pointer bg-lightBG px-5 h-[50px] '
								onClick={() => closeStudentInfo()}
							>
								<p className='text-deepPurple text-[16px] leading-[20px] font-semibold '>{t('Choose later')}</p>
							</div>

							<button
								className=' h-[50px] bg-deepPurple rounded-full px-5 flex flex-row items-center justify-center gap-[10px] cursor-pointer w-[265px]'
								onClick={submitAnimalChange}
								disabled={processing}
							>
								{processing ? (
									<Loading />
								) : (
									<>
										<p className={`text-white text-[16px] leading-[20px] font-semibold`}>
											{t('Save with selected avatar')}
										</p>
										<Save color='#ffffff' />
									</>
								)}
							</button>
						</div>
					</div>
				)}
				<Toaster position='top-right' reverseOrder={false} toastOptions={{ duration: 3000 }} />
			</div>
			<ConfirmationModal
				modalIsOpen={confirmDeleteStudentModal}
				closeModal={closeConfirmDeleteStudentModal}
				confirmation={removeStudent}
				message={t('doYouWantToDeleteThis')}
			/>
		</ReactModal>
	);
}

export default StudentPrimaryInfoModal;

export const AnimalButton = ({ animal, selected, selectAnimal, showTakenUser = false, student }) => {
	return (
		<div>
			<div
				className={` rounded-[25px] cursor-pointer p-[10px] ${selected ? 'bg-deepPurple' : 'bg-[#ffffff00]'}`}
				onClick={() => selectAnimal(!!student ? student : animal)}
			>
				<div className='bg-[#83CDF5] rounded-[20px]'>
					<LazyLoad>{animalImages[animal].ok}</LazyLoad>
				</div>
			</div>
			{showTakenUser && (
				<p className='text-darkenPurple text-[14px] leading-[17px] font-medium text-center mt-[10px]'>
					{student?.first_name} {student?.last_name}
				</p>
			)}
		</div>
	);
};
