import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useUser } from '../auth';
const PublicRoute = ({ component: Component, ...rest }) => {
	const { isSignedIn } = useUser();
	return (
		<Route
			{...rest}
			render={(props) => {
				if (isSignedIn) return <Redirect to='/dashboard' />;
				return <Component {...props} />;
			}}
		/>
	);
};

export default PublicRoute;
