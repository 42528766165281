import React, { useState } from 'react';
import { useUser } from '../../../../auth';
import { fileSystemUrl, videoUrl } from '../../../../constants/Connections';
import { ReactComponent as SeenIcon } from '../../../../assets/svg/SeenIcon.svg';
import { ReactComponent as LikeIcon } from '../../../../assets/svg/LikeIcon.svg';

import { ReactComponent as EditContent } from '../../../../assets/svg/EditContent.svg';
import { ReactComponent as DeleteContent } from '../../../../assets/svg/DeleteContent.svg';
import { Link } from 'react-router-dom';
import { translateTargetText, truncate } from '../../../../utils/util';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import '../../../../styles/dashboard/SingleFeed.css';
import { like } from '../../../../server/server';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { localeMap } from '../../../../constants/Constants';
import i18n from '../../../../i18n';
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder';
function SingleFeed({ feed, deleteFeed, openBigImage, sameDay, index }) {
	const { user, setUser } = useUser();
	const [loadingImage, setLoadingImage] = useState(true);
	const url = `${fileSystemUrl}/${feed?.files?.storage_name}?token=${user.token}`;
	const imgUrl = feed?.files ? url : false;
	const { t, locale } = useTranslation();
	const [liked, setLiked] = useState(!!feed?.like);
	const [showMore, setShowMore] = useState(false);
	const showImage = (files, width) => {
		return (
			<div className='simage-container-new'>
				{loadingImage && (
					<SkeletonTheme baseColor='#C9D8FF' highlightColor='#D1DEFF'>
						<Skeleton />
					</SkeletonTheme>
				)}
				<img
					src={url + '&width=' + width}
					style={{ width: '100%', borderRadius: 10, minHeight: 170 }}
					alt='Task'
					className='task-image'
					onLoad={() => setLoadingImage(false)}
				/>
			</div>
		);
	};
	const openInNewTab = (url) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	const pressLike = async () => {
		feed.likes = liked ? feed?.likes - 1 : feed?.likes + 1;
		setLiked(!liked);
		const resp = await like({
			user_id: user?.id,
			feed_id: feed?.id,
			like: !liked,
		});
		if (resp.status == 200) {
		}
	};

	const hasImage = !!feed.files;
	const isVideo = feed?.files?.type === 'video';
	const mediaUrl = hasImage ? `${isVideo ? videoUrl : fileSystemUrl}/${feed.files.storage_name}?token=${user.token}` : '';

	return (
		<div
			key={feed?.id}
			className={`feed-background p-5 w-full flex flex-row gap-[15px] rounded-xl ${
				sameDay ? 'mt-[10px]' : 'mt-[15px]'
			}  border   border-containerGray overflow-hidden`}
		>
			{hasImage &&
				(isVideo ? (
					<div className='simage-container-new'>
						<video controls controlsList='nodownload'>
							<source src={mediaUrl} />
							{t('yourBrowserDoesNotSupportHTMLVideo')}
						</video>
					</div>
				) : (
					<div
						className='simage-container-new pointer'
						style={{
							marginRight: i18n.language == 'ar' ? 0 : 20,
							marginLeft: i18n.language == 'ar' ? '20' : 0,
						}}
						onClick={() => openBigImage(imgUrl)}
					>
						<ImageWithPlaceholder url={mediaUrl} altText={feed?.title} width={273} />
					</div>
				))}
			<div className='  flex flex-col justify-start  flex-1 '>
				{!feed?.welcomeContent && (
					<div className='header-single-content flex justify-between'>
						<p className='text-sm text-[#413F5E] font-[500]'>
							<span className='text-[#413F5E99] font-[500]'>{t('by')}:</span> {feed?.created_by?.first_name}{' '}
							{feed?.created_by?.last_name}
						</p>
						<div className='text-sm text-[#413F5E] font-[500]'>
							<span className='text-[#413F5E99]'>{t('target')}:</span> {translateTargetText(feed?.target_text)}
						</div>
						<div className='pointer'>
							<Link to={`/content?editId=${feed?.id}`}>
								<EditContent fill='#BEBECE' />
							</Link>
							<DeleteContent
								className='ml-5'
								fill='#FF747F'
								onClick={() => deleteFeed(feed?.id)}
								id={`delete-icon-${index}`}
							/>
						</div>
					</div>
				)}
				<div className='flex flex-col  flex-1 mt-[20px]'>
					<div className='text-[#413F5E] font-bold text-[17px] text-left  leading-[20px]'>{feed?.title}</div>
					<div className='text-sm text-[#413F5E] text-left mt-[10px] w-full font-[500] flex-1'>
						<pre className='whitespace-pre-wrap'>
							{showMore ? feed?.message : feed?.message?.substring(0, 85)}
							{!showMore && feed?.message?.length > 85 && '...'}
						</pre>
						{feed?.message?.length > 85 && (
							<span className='font-bold text-[#5E639B] pointer' onClick={() => setShowMore(!showMore)}>
								<br />
								{!showMore ? t('showMore') : t('showLess')}
							</span>
						)}
					</div>

					{!feed?.welcomeContent && (
						<div className='mt-4 text-sm text-mudPurple flex justify-between items-center w-full'>
							<div className='flex items-center gap-4'>
								{/* <div>
									<SeenIcon fill={'#413F5E'} />
									<span className='text-sm text-[#413F5E] ml-2 font-[500]'>{feed?.views}</span>
								</div> */}
								{/* TODO: FIX THIS ASAP */}
								<div
									className={`flex flex-row items-center py-1 px-2 rounded-3xl pointer w-fit ${
										liked ? 'bg-[#5E639B]' : 'bg-white'
									}`}
									onClick={(e) => {
										e.preventDefault();
										pressLike();
									}}
								>
									<LikeIcon fill='#FDDF6D' />
									<span className={`text-[12px]  font-semibold ml-1 ${liked ? 'text-[#FDDF6D]' : 'text-[#AFAEC6]'}`}>
										{feed?.likes || 0}
									</span>
								</div>
							</div>

							<p>
								<span className='text-[#413F5E99] font-[500]'>{t('time')}: </span>
								<span className='text-[#413F5E] font-[500]'>
									{format(new Date(feed.publicationDate), 'HH:mm', { locale: localeMap[i18n.language] })}
								</span>
							</p>
						</div>
					)}
					{feed?.url && (
						<div
							className='bg-white w-full h-[40px] flex items-center justify-center text-mudPurple font-bold pointer text-[12px] rounded-[10px] mt-3'
							onClick={() => openInNewTab(feed?.url)}
						>
							{t('openLink')}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default SingleFeed;
