const TRANSLATION_ES = {
	analytics: 'Analytics',
	getDeeperInsights: 'Get deeper insights',
	thisCanTakeAwhile: 'This can take a while',
	Display: 'Display',
	Regular: 'Regular',
	Primary: 'Primary',
	chooseDataRange: 'Seleccionar rango de datos',
	barView: 'Bar view',
	'Emotion distribution for selected period': 'Emotion distribution for selected period',
	gender: 'Género',
	male: 'Hombre',
	female: 'Mujer',
	percentageOfResponseDistributionByGender: 'Porcentaje de distribución de respuesta por género',
	general: 'General',
	'Total response percentage': '',
	'Engagement with emotions': 'Engagement with emotions',
	'Total emotions submitted': 'Total emotions ',
	emotion: 'Emotion',
	'No. of emotions': 'No. of emotions',
	mostCommonReasons: 'Most common reasons',
	noResults: 'Sin resultados',
	'Track trends': '',
	'Add a new trend': 'Add a new trend',
	searchQuestion: 'Search question',
	clickToSeeQuestionInDetails: 'Click to see question in details',
	'Remove from trends': 'Remove from trends',
	schedule: 'Calendario',
	on: 'Encender / Prender',
	On: 'Encender / Prender',
	Off: 'Off',
	doYouWantToDeleteThis: '¿Quieres borrar esto?',
	'Time frame': 'Time frame',
	howAreYouToday: '¿Cómo te encuentras hoy?',
	getDeeperInsightsIntoYourStudentsEmotionalTrends: 'Get deeper insights into your students emotional trends',
	getInsights: 'Get insights',
	'General Engagement': '',
	'Overall engagement': 'Overall engagement',
	'Engagement with content': 'Engagement with content',
	Registration: '',
	'Registered teachers': 'Registered teachers',
	registeredStudents: 'Registered students',
	'Content has been removed from trends': '',
	By: 'By',
	Target: 'Target',
	delete: 'Borrar',
	download: 'Descargar',
	searchStudents: 'Search students',
	'Select surveys': 'Select surveys',
	answers: 'Respuestas',
	getBackToMainAnalytics: 'Get Back to Main Analytics',
	'Student engagement with content': 'Student engagement with content',
	name: 'Nombre',
	email: 'Correo electrónico',
	class: 'Clase / Grupo',
	Class: 'Clase / Grupo',
	studentsWhoDidntRespondToAnyQuestionInPastTwoWeeks: 'Students who didn`t respond to any question in past two weeks',
	studentsNotFound: 'Students not found',
	showMoreStudents: 'Show more students',
	lastLogin: 'Last login',
	neverLoggedIn: 'Never logged in',
	loggedInRecently: 'Logged in recently',
	searchTeachers: 'Search Teachers',
	position: 'Position',
	teachersNotfound: 'Teachers not found',
	showMoreTeachers: 'Show more teachers',
	'Student check in': 'Student check in',
	'Create Reports': 'Create Reports',
	'Report format': 'Report format',
	Raw: 'Raw',
	'Output fields': 'Output fields',
	'Create report': 'Create report',
	'Return to Analytics': 'Return to Analytics',
	'Create & Manage Reports': 'Create & Manage Reports',
	'Select report dates': 'Select report dates',
	'Start date:': 'Fecha de inicio:',
	endDate: 'End date',
	'End date:': 'End date',
	search: 'Buscar',
	Date: 'Fecha',
	action: 'Acción',
	'No reports have been created yet': '',
	'In process': '',
	'Welcome! Create your first report here': 'Welcome! Create your first report here',
	'Latest reports': 'Latest reports',
	'View More': 'View More',
	newPassword: 'Nueva contraseña',
	passwordMustBeLongerThan8CharactersAndMustContainsUppercaseLowercaseNumberAndSpecialSymbol:
		'La contraseña debe contener más de 8 caracteres, debe contener mayúsculas, minúsculas y caracteres especiales',
	password: 'Contraseña',
	youMustFillData: 'You must fill data',
	repeatPassword: 'Repite la contraseña',
	passwordsDoNotMatch: 'La cotraseña no coincide',
	submitNewPassword: 'Ingresa la nueva contraseña',
	staffLogin: 'Staff login',
	enterCredentialsYouReceivedFromSchool: 'Ingresa las credenciales que reciviste de la escuela',
	login: 'Ingresa',
	forgotPassword: 'Olvidar contraseña',
	requestNewPassword: 'Solicitar nueva contraseña',
	enterYourSchoolEmailAndWeWillSendYouALinkToGetBackIntoYourAccount:
		"Enter your school email and we'll send you a validation token to get back into your account",
	sendRequest: 'envía solicitud',
	verifyYourEmail: 'verifica tu correo',
	weHaveSentYouALinkToGetBackIntoYourAccountAndCheckYourMailAndCopyYourTokenHere:
		"We've sent you a validation token to get back into your account. Check your mail and copy your token here.",
	token: 'Token',
	submit: 'Envíar',
	didntGetAMail: '¿No reciviste un correo?',
	resendARequest: 'Reenvíar solicitud',
	filterIsNotSet: 'Filter is not set!',
	commentHasBeenSeen: 'Comment has been seen!',
	catchUp: 'Catch-up',
	catchUpOnAnyStudentCommentsAttachedTo:
		'Catch up on any student comments attached to an uncomfortable emotion that may have been missed along the way',
	unseen: 'Unseen',
	archive: 'Archivo / Historial',
	filter: 'Filter',
	years: 'Años',
	all: 'Todos',
	classes: 'Clases / Grupos',
	Classes: 'Clases / Grupos',
	apply: 'Aplicar',
	markAsSeen: 'Mark as seen',
	newMessage: 'nuevo mensaje',
	received: 'received',
	delivered: 'Entregado',
	counselor: 'consejero',
	sos: 'AUXILIO',
	SOS: 'AUXILIO',
	youHaveNoChats: 'No tienes conversaciones',
	thereIsnotAnyConversationYet: 'There isn`t any conversation yet',
	writeYourMessageHere: 'Escribe tu mensaje aquí',
	myMessage: 'My message',
	replyOn: 'Reply on',
	'More details': 'More details',
	searchForStudent: 'Search for student',
	searchForTeacher: 'Search for teacher',
	'All emotions': 'All emotions',
	uncomfortable: 'Uncomfortable',
	filterClassroom: 'Filter Classroom',
	staff: 'Equipo de trabajo',
	'Number of student who answered "How are you today?" on this date': '',
	yourIndividualStudentsResponses: 'Your individual students’ responses',
	uncomfortableEmontionsWithComments: 'Uncomfortable emotions with comments',
	year: 'Año',
	showMore: 'Mostrar más',
	showLess: 'Mostrar menos',
	wellbeingOfTeachers: 'Wellbeing of teachers',
	classroomTrends: 'Classroom Trends',
	'Comfortable Emotions': 'Comfortable Emotions',
	'Uncomfortable Emotions': 'Uncomfortable Emotions',
	comment: 'comentario',
	'Your Primary Classes': 'Your Primary Classes',
	createYourOwnContent: 'Create Your Own Content',
	contentIsCreated: '¡El contenido ha sido creado!',
	contentIsNotCreated: '¡El contenido no ha sido creado!',
	yourDataIsUpdated: '¡Tus datos han sido actualizados!',
	yourDataIsNotUpdated: '¡Tus datos no han sido actualizados!',
	templateIsCreated: '¡Se ha creado la plantilla!',
	templateIsNotCreated: '¡No se ha creado la plantilla!',
	back: 'Regresar',
	createAPost: 'Crear una publicación',
	typeYourHeadlineHere: 'Escribe tu título aquí',
	addAnOptionalMessage: 'Añade un mensaje opcional',
	enterUrlLink: 'Ingresa un enlace',
	optional: '(opcional)',
	invalidLink: 'Enlace inválido',
	addPhoto: 'Añadir fotografía',
	targetGroup: 'Grupo a revisar',
	off: 'Off',
	saveAsTemplate: 'Guardar como plantilla',
	post: 'Publicación',
	allCreatedContent: 'Todo el conntenido creado',
	options: 'Opciones',
	edit: 'Editar',
	templateIsUpdated: 'Template is updated',
	'Template is updated': 'Template is updated',
	addOption: 'Añadir opción',
	repeat: 'Repetir',
	addToAnalytics: 'Añadir a analíticas',
	runProgram: 'Ejecutar programa',
	select: 'Seleccionar',
	pickOneOption: 'Seleccionar una opción',
	teachersOnly: 'Solo profesores',
	contentNeedsToBeReviewedByTheScoolAdmin: 'El contenido necesita ser revisado por la Escuela',
	surveyIsCreatedAsTemplate: 'Survey is created as template!',
	surveyIsDeleted: 'Survey is deleted!',
	headline: ' título',
	typeHeadlineHere: 'Type headline here',
	supportiveText: 'Supportive Text',
	typeAnyAdditionalTextHere: 'Type any additional text here',
	addSection: 'Añadir selección',
	required: 'Requerido',
	anonymous: 'Anónimo',
	pleaseFillRequiredFields: 'Please fill required fields',
	templateIsSaved: 'Template is saved!',
	update: 'Actualización',
	publish: 'Publish',
	section: 'Sección',
	headlineForSection: 'Headline for section',
	descriptionForSection: 'Description for section',
	addQuestion: 'Añadir pregunta',
	question: 'Pregunta',
	moveQuestionUp: 'Move question up',
	moveQuestionDown: 'Move question down',
	enterQuestionHere: 'Añadir una pregunta aquí',
	selectTypeOfQuestion: 'Select type of question',
	yesNo: 'Si/No',
	openAnswer: 'Abrir respuesta',
	Yes: 'Si',
	No: 'No',
	deleteOption: 'Delete option',
	'Options must differ ': '',
	templateDeleted: 'Template deleted',
	'No items found': 'No items found',
	useTemplate: 'Use Template',
	fileIsToBig: 'File is too big!',
	'Cannot create anonymous poll for 1 user': 'Cannot create anonymous poll for 1 user',
	imageIsNotUploaded: 'La imagen no se cargó',
	enterLinkOptional: 'Ingresa el enlace aquí (opcional)',
	writeOption1Here: 'Write option 1 here',
	'The maximum image size for download is 1400px x 1400px': 'The maximum image size for download is 1400px x 1400px',
	addPhotoVideo: 'añadir foto / video',
	yourBrowserDoesNotSupportHTMLVideo: 'Your browser does not support HTML video.',
	addToWellbeingTracker: 'Añadir al monitor de bienestar',
	updateTemplate: 'Update template',
	survey: 'Encuesta',
	typeASurveyTitle: 'Título de la encuesta',
	addASurveyDescription: 'Agregar una descripción a la encuesta',
	title: 'Título',
	enterSectionTitleHere: 'Ingresa el titulo de la sección aquí...',
	description: 'Descripción',
	enterSectionDescriptionHere: 'Añadir descripción de la sección aquí',
	deleteSurvey: 'Borrar Encuesta',
	save: 'Guardar',
	doYouWantToRemoveSurvey: '¿Quieres eliminar esta encuesta?',
	createAPoll: 'Crear encuesta',
	typeAPollQuestion: 'Type a poll question ...',
	cancel: 'Cancelar',
	createAQuestion: 'Crear una pregunta',
	typeAQuestionTitle: 'Ingresa un titulo para la pregunta',
	readyToGoContent: 'Contenido listo para comenzar',
	sortBy: 'Sort by',
	type: 'Type',
	topic: 'Tema',
	evidenceBasedContentPreparedForYouByOurTeamOfExperts: 'Contenido basado en evidencia preparado para ti por nuestro equipo de expertos',
	manageRunningPrograms: 'Gestionar programas en ejecución',
	'Program has been deleted!': '',
	thisToolHelpsYouToControlAllRunningProgramsAndTrackTheirInfluenceToStudents:
		'Esta herramienta te ayuda a gestionar todos los programas en ejecución y a seguir su influencia en los estudiantes',
	running: 'Ejecutando',
	paused: 'Paused',
	pauseProgram: 'Pause program',
	playProgram: 'Play program',
	deleteProgram: 'Eliminar programa',
	yourRunningProgramListIsEmptyStartByAddingOneFromTheListOfPrograms:
		'Tu lista de programas en ejecución está vacía, comienza agregando uno de la lista de programas',
	doYouWantToDeleteThisProgram: 'Do you want to delete this program?',
	doYouWantToStopThisProgram: '¿Quieres detener este programa?',
	doYouWantToStartThisProgram: 'Do you want to start this program?',
	runIt: 'Implementar',
	listOfDays: 'Lista de días',
	day: 'Día',
	postIndividually: 'Post individually',
	lastCreated: 'Last created',
	surveyIsCreated: 'Se ha creado la encuesta',
	'Release survey by days': 'Release survey by days',
	configureSurvey: 'Configura encuesta',
	editSurvey: 'Editar Encuesta',
	runSurvey: 'Implementar encuesta',
	yes: 'Si',
	no: 'No',
	currentlyRunning: 'Currently running',
	other: 'Otro',
	wellbeingTracker: 'Monitor de bienestar',
	'Your counseling list is empty, start with adding a new counseling profile for one of your students':
		'Your counseling list is empty, start with adding a new counseling profile for one of your students.',
	reminder: 'Recordatorio',
	assignedActions: 'Asignar acciones',
	youDontHaveAnyAssignedAction: 'You have no assigned actions yet',
	lastNote: 'Last note',
	Overdue: 'Overdue',
	youDontHaveAnyRemindersForToday: 'No tienes ningun recordatorio hoy',
	firstEnterANote: 'Primero ingresa una nota',
	noteIsCreated: 'La nota ha sido creada',
	reminderHasBeenChanged: '¡El recordatorio ha cambiado!',
	'Reminder has been deleted!': '',
	currentEmotion: 'Current Emotion',
	mostPopularEmotion: 'Most Popular Emotion',
	ofResponsesToActivePollsQuestion: '% of responses to active polls questions',
	status: 'Estatus',
	dateOfBirth: 'Cumpleaños',
	teacher: 'Profesor',
	exportData: 'Export data',
	notes: 'Notas',
	reminderSetFor: 'Recordatorio establecido para',
	'Stop editing': 'Stop editing',
	editReminder: 'Editar recordatorio',
	removeReminder: 'Eliminar recordatorio',
	messageIsVisibleToAll: 'El mensaje es visible para todos',
	messageOnlyVisibleToStaffWithSpecialAccess: 'EL mensaje es visible únicamente para colaboradores con acceso especial',
	enterYourNoteHere: 'Agregar una nota',
	assignAction: 'Asignar acción al profesor para revisar a este estudiante',
	addNote: 'Add note',
	addReminder: 'Agregar recordatorio',
	open_poll: 'Abrir encuesta',
	openQuestion: 'Abrir pregunta',
	'Assignment for': 'Assignment for',
	overview: 'Overview',
	results: 'Resultados',
	feedPosts: 'Feed Posts',
	myTasks: 'Mis tareas',
	contentDeleted: 'Content deleted',
	displayAll: 'Display All',
	questions: 'Questions',
	polls: 'Polls',
	surveys: 'Encuestas',
	journals: 'Diarios',
	onlyMine: 'Only mine',
	searchResults: 'Search results',
	notSelected: 'Not selected',
	date: 'Fecha',
	runReadyToGo: 'Run ready-to-go',
	createContent: 'Crear contenido',
	open: 'Abrir',
	Open: 'Abrir',
	target: 'Target',
	clickToBrowseResults: 'Click to browse results',
	by: 'By',
	time: 'Hora',
	from: 'From',
	to: 'To',
	createPosts: 'Create Posts',
	deleteFeedContent: 'Delete feed content?',
	openLink: 'Abrir enlace',
	actionRequired: 'acción requerida',
	stayUpToDateWithStudentSupport: 'Stay up to date with student support',
	'Comments for review': 'Comments for review',
	actionsAssignedToMe: 'Actions assigned to me',
	studentChat: 'Student chat',
	youDeletedTargetGroup: 'You deleted target group',
	deeperInsights: 'Deeper insights',
	effortlesslyMonitorYourStudentsEmotionalExperiencesByCreatingYourOwnCustomisedGroupsHere:
		"Effortlessly monitor your students' emotional experiences by creating your own customised groups here.",
	'Sample Group': 'Sample Group',
	createGroup: 'Create Group',
	'Current Weekly Engagement Statistics': 'Current Weekly Engagement Statistics',
	trackEngagementResponsivenessLogins: 'Track engagement, responsiveness & logins',
	'Student logins': 'Student logins',
	'Student Engagement on Emotion': 'Student Engagement on Emotion',
	'Teacher logins': 'Teacher logins',
	yourClassroom: 'Your Classroom',
	checkWhatsGoingOn: 'Check what’s going on',
	myClassroom: 'My Classroom',
	upcomingContent: 'Próximo contenido',
	yourAnswersAreSaved: 'Your answers are saved!',
	open_journal: 'Abrir diario',
	writeFullAnswerHere: 'Escribe la respuesta completa aquí',
	takeIt: 'Tómalo',
	selectEmotionThatBestDescribesYourMoodToday: 'Select an emotion that best describes your mood today',
	yourTasks: 'Your tasks',
	activitiesForCompletion: 'Activities for completion',
	Success: 'Success',
	'Thank you for helping us to make Upstrive better.': '',
	yourAnswerIsSaved: 'Tu respuesta a sido guardada',
	'Click to get back to all Tasks': 'Click to get back to all Tasks',
	chooseTheAnswer: 'Choose the answer',
	'Choose the answer': 'Choose the answer',
	'Take it': 'Tómalo',
	open_question: 'Abrir pregunta',
	'Write the answer': 'Write the answer',
	'Type any additional text here': 'Type any additional text here',
	yourResultsAreSaved: '¡Su resultado se ha guardado!',
	sections: 'Secciones',
	finish: 'Terminar',
	nextSection: 'Siguiente sección',
	'This day is locked!': 'This day is locked!',
	close: 'Close',
	'Finish later': 'Finish later',
	Finish: 'Terminar',
	Next: 'Siguiente',
	selectOneOption: 'Seleccionar una opción',
	youHaveMissedThisQuestion: 'Has olvidado esta pregunta',
	welcomeBack: 'Welcome back',
	'Here’s what happened while you were away': 'Esto es lo que ha pasado en tu ausencia',
	ofStudentsUsingHowAreYouTodayAtLeastOncePerWeek: '% of students using “How are you today?” at least once per week',
	'Not selected': 'Not selected',
	'Preset Ranges': 'Preset Ranges',
	'7 Days': '7 Days',
	'1 Month': '1 Month',
	'3 Months': '3 Months',
	'1 Year': '1 Year',
	'Select time range': '',
	congratsEverythingIsDoneForNowWaitForNewTasks: 'Felicidades, has terminado por ahora! Espera a recibir nuevas tareas',
	doYouWantToDeleteThisTask: '¿Quieres eliminar esta tarea?',
	doYouWantToDeletePost: '¿Quieres eliminar esta publicación?',
	dashboard: 'Panel de control',
	live: 'En vivo / Vivir',
	openTasks: 'Abrir tareas',
	taskArchive: 'Archivo de tareas',
	congratulations: 'Congratulations',
	youHaveCompletedAllYourTasks: 'You have completed all your tasks.',
	anonymous_survey: 'Encuesta anónima',
	open_survey: 'Abrir Abrir encuesta',
	completed: 'completed',
	deletePost: 'Eliminar esta publicación',
	editPost: 'Editar publicación',
	submitAnswer: 'Submit answer',
	classIsNotUpdated: 'Class is not updated!',
	'Registered users': 'Registered users',
	'Unregistered users': 'Unregistered users',
	numberOfStudents: 'Numero de estudiantes',
	listOfStudents: 'Lista de estudiantes',
	addAStudent: 'Add a student',
	loadMoreClasses: 'Cargar más clases / Cargar más grupos',
	'Are you sure you want to delete class?': 'Are you sure you want to delete class?',
	'All Student in this class will be deleted': 'All Student in this class will be deleted',
	'Class successfully deleted': 'Class successfully deleted',
	fieldIsRequired: 'Este campo es requerido',
	classIsCreated: '¡El grupo esta creado! / ¡La clase esta hecha!',
	'Type of class to create': 'Type of class to create',
	unregisteredStudents: 'Unregistered students',
	'Primary class': 'Primary class',
	cantCreateStudentWithoutCreateClass: 'Can`t create student without create class',
	loggedIn: 'Logged in',
	Avatar: 'Avatar',
	manage: 'Gestionar',
	'Not Setuped': 'Not Setuped',
	noStudentsYet: 'No students yet',
	loginStatistic: 'Ingresar estadísticas',
	reviewYourLoginStatistics: 'Review your login statistics',
	students: 'Alumnos',
	'Logged in': 'Logged in',
	'Not logged in': 'Not logged in',
	teachers: 'Profesores',
	'Login data': 'Datos de ingreso',
	uploadUserData: 'Cargar datos de usuario',
	classManagement: 'Gestión de grupos',
	teacherManagement: 'Gestión de profesores',
	addATeacher: 'Add a teacher',
	addANewClass: 'Add a new class',
	'Teacher is removed!': '¡El profesor se ha eliminado!',
	'Teacher is not removed!': 'Teacher is not removed!',
	listOfTeachers: 'Lista de profesores',
	role: 'Rol',
	noTeachersYet: 'No hay profesores aún',
	loadMore: 'Cargar más',
	fileHasBeenUploadedDataWillBeUpdatedWithin24Hours: 'El archivo se ha cargado. La información se actualizará',
	'File has been removed': 'File has been removed',
	downloadTemplateFile: 'Download Template File',
	previousUploads: 'Cargas anteriores',
	fileLink: 'Enlace del archivo',
	attachFile: 'Adjuntar archivo',
	uploadData: 'Upload data',
	iWillTakeAction: 'Tomaré acción',
	describeHowYouWillDealWithThisStudent: 'Describe como lidiarías con este estudiante',
	writeYourThoughtsHere: 'Escribe lo que piensas',
	continue: 'Continuar',
	AssignActionToATeacherToCheckOnThisStudent: 'Asignar acción al profesor para revisar a este estudiante',
	assignedTo: 'Assigned to:',
	selectTeacher: 'Seleccionar profesor',
	unreadMessage: 'unread message',
	assignedBy: 'Assigned by',
	className: 'Nombre del grupo / Nombre de la clase',
	'Yes, Delete': 'Yes, Delete',
	'No, cancel': 'No, cancel',
	stop: 'Alto',
	archived: 'Archived',
	thereIsntAnyAnswerYet: 'There isn`t any answer yet',
	filterResults: 'Filter results',
	Reset: '',
	'Last step': 'Last step',
	'Setup report name as your last step': 'Setup report name as your last step',
	'Report name': 'Report name',
	'Enter file name': 'Enter file name',
	'You will be notified once your report is ready': 'You will be notified once your report is ready',
	saveYourFavoriteGroup: 'Guarda tu grupo favorito',
	createWellbeingProfile: 'Crea un perfil de bienestar',
	yourStudentListIsEmpty: 'Your students list is empty',
	dailyFeedback: 'Retroalimentación diaria',
	howWasYourDay: '¿Cómo estuvo tu día?',
	'We hope you’ve had a good day!': '¡Esperamos que hayas tenido un día bueno!',
	safePlaceWithDailyReflectionNotes: 'Espacio seguro con reflexiones diarias',
	whatAreJust3GoodThingsThatHappenedToday: '¿Cuáles son 3 cosas buenas que ocurrieron hoy?',
	'What are 3 things that are a bit challenging at the moment?': 'Nombra 3 cosas que son algo retadoras en el',
	'What’s just one thing you will do differently tomorrow?': 'Nombra una cosa que harías diferete',
	saveAndContinue: 'Guardar y contiuar',
	iDontWantToThinkAboutThisNow: 'No quiero pensar en esto ahora',
	'Select teacher': 'Seleccionar profesor',
	actionHasBeenAssignedToTeacher: 'Action has been assigned to teacher',
	assignActionFailed: '',
	'User activity': 'User activity',
	mood: 'Mood',
	sendMessage: 'Envíar mensaje',
	actionTaken: 'Acción tomada',
	seenBy: 'Visto por:',
	thereAreNoAnswersForThatDay: 'No hay respuestas para ese día',
	tasks: 'Tareas',
	classroomTeacher: 'Classroom Teacher',
	headOfYear: 'Head of Year',
	otherTeachers: 'Other Teachers',
	typeAnAction: 'Ingresa una acción',
	'Write your personal notes here (only you can see this)': '',
	messageHasBeenSent: 'Message has been sent',
	sendPrivateMessageToStudent: 'Send Private message to student',
	'Write personal message for the student here': 'Write personal message for the student here',
	compareToLastTime: 'Comparado con la última vez',
	thisIsYourSafePlaceAndNothingWillBeShared: 'Este es tu espacio seguro. Nada será compartido',
	whatCouldYouDoToImproveThingsRightNow: 'What could you do to improve things right now?',
	suggestions: 'Sugerencias',
	loadMoreSuggestions: 'Cargar más sugerencias',
	comparedToLastTimeTodayIamFeeling: "Compared to last time, today I'm feeling",
	howDoYouFeelComparedToLastTimeYouLoggedIn: 'How do you feel compared to the last time you logged in?',
	privateNoteHasBeenSaved: 'La nota privada ha sido guardada',
	howDoYouFeelComparedToLastTimeYouDidThePoll: '¿Cómo te sientes en comparación a la última vez que realizaste la encuesta?',
	enterANameForTodaysNotes: 'Elige un nombre para las notas de hoy',
	nameOfTodayNote: 'Nombre de la nota de hoy',
	yourFeedBackHasBeenSubmited: 'Your feedback has been submitted!',
	iDontWantToThinkAboutItNow: 'I don’t want to think about it now',
	whatIsTheMainReason: 'What’s the main reason?',
	pickUpTo3: 'Pick up to 3',
	muchWorse: 'Mucho peor',
	worse: 'Peor',
	same: 'Igual',
	better: 'Mejor',
	muchBetter: 'Mucho mejor',
	loginData: 'Datos de ingreso',
	listOfStudentsWhoHaventLoggedInYet: "List of students who haven't logged in yet",
	selectAll: 'Seleccionar todo',
	'Full name': 'Full name',
	noUnsignedTeachers: 'No unsigned teachers',
	noUnsignedStudents: 'No unsigned students',
	logout: 'Salir',
	currentPrograms: 'Current programs',
	createdNewMonitoringGroup: 'Created new monitoring group!',
	updatedMonitoringGroup: 'Updated monitoring group!',
	editGroup: 'Edit Group',
	nameANewGroup: 'Name a new group',
	chooseEmotions: 'Choose emotions',
	allStudents: 'Todos los alumnos',
	'all students': 'Todos los alumnos',
	'% of all students are with selected type of emotions': '% of all students are with selected type of emotions.',
	secretKeyHasBeenSaved: 'El código secreto ha sido guardado',
	staySecuredFirst: 'Primero mantente a salvo',
	Attention: 'Atención',
	toEnsureYourPrivacyThisKeyIsNotRecoverableAllYourNotesWillBeLostIfYouForgetThisKey:
		'Para asegurar tu privacidad, este código no se puede recuperar',
	secretKey: 'Llave secreta',
	enterSecretKey: 'Enter secret keys',
	startDate: 'Fecha de inicio:',
	setATime: 'Esablece una hora',
	turnOff: 'Apagar',
	deleteWasSuccessful: 'Eliminación exitosa',
	listOf: 'Lista de ',
	thisListRepresentsAllContentFromThisTypeYouCanEditOrDeleteTt:
		'Esta lista muestra todo el contenido de este tipo. Puedes editarlo o eliminarlo',
	editThis: 'Editar esta',
	deleteThis: 'Eliminar esta',
	created: 'Created',
	createReminder: 'Crear recordatorio',
	'Set a date when you would like to be reminded about this student': 'Selecciona la fecha de cuando quieres que se te recuerde',
	'Choose a date': 'Elegir fecha',
	done: 'Hecho',
	weekly: 'Weekly',
	monthly: 'Monthly',
	selectDays: 'Seleccionar dias:',
	everyMonthOn: 'Activado cada mes',
	youAreRunningThisProgramSuccessfully: 'Has implementado correctamente este programa',
	youMustSelectTargetGroup: 'You must select target group!',
	youMustSelectDaysOfWeek: 'You must select days of week!',
	thisToolHelpsYouPlanTheEntireProgramOnce:
		'Esta herramienta te ayuda a crear todo el programa de una vez para que no necesites revisarlo todo el tiempo',
	pickTime: 'Seleccionar hora:',
	thisProgaramIsAlreadyRunning: 'Este programa ya se esta implementando, ¿Quieres implementarlo de nuevo?',
	poll: 'Encuesta',
	feed: 'Entrada / Muro',
	'This tool helps you to run a survey for your students': 'Esta herramienta te ayuda a aplicar una encuesta por ti',
	setupADateAndTime: 'Setup a Date & Time',
	'Schedule Survey': 'Schedule Survey',
	"This tool helps you to schedule whole survey once so you don't need to check it all the time":
		"This tool helps you to schedule whole survey once so you don't need to check it all the time",
	assignToTeacher: 'Asignar a profesor',
	'Can`t create student without create class': '',
	'Data is saved': 'Data is saved',
	emailIsInvalid: 'Correo electrónico no válido',
	emailAlreadyExists: '¡Este correo ya existe!',
	emailIsAvailable: '¡El correo esta disponible!',
	'New password is generated': '',
	studentRemoved: 'Student removed',
	studentInfo: 'Información de estudiante',
	'Transfer to another class': 'Transfer to another class',
	student: 'Estudiante',
	enterStudentUPN: 'Ingresa el UPN del estudiante',
	firstName: 'Nombre',
	enterName: 'Ingresar nombre',
	lastName: 'Last name',
	enterSurname: 'Ingresa apellido',
	enterEmail: 'Ingresa correo electrónico',
	letThemSetTheirOwnPasswordsAfterLogin: 'Let them set their own passwords after login',
	keepThisPassword: 'Keep this password',
	hidePasswordmanager: 'Hide password manager',
	setStudentsPasswordManually: "Set student's password manually",
	generateNewPassword: 'Genera nuea contraseña',
	closePopUp: 'Close popup',
	deleteStudent: 'Delete student',
	newPasswordHasAlreadyBeenGeneratedTodayForThisUserDoYouWantToGenerateItAgain:
		'New password has already been generated today for this user. Do you want to generate it again?',
	'New password has already been generated today for this user! Do you want to generate it again?':
		'New password has already been generated today for this user. Do you want to generate it again?',
	'You are going to generate new password for this student?': '',
	'Add student': 'Add student',
	'Edit Student': 'Edit Student',
	'Change avatar': 'Change avatar',
	'Choose Gender': 'Choose Gender',
	'Choose animal': 'Choose animal',
	'Create Student': 'Create Student',
	Available: 'Available',
	Taken: 'Taken',
	'If you will choose already taken character it will auto unpin from existing child':
		'If you will choose already taken character it will auto unpin from existing child',
	'Choose later': 'Choose later',
	'Save with selected avatar': 'Save with selected avatar',
	'Old class': 'Old class',
	newClass: 'Nueva clase / Nuevo grupo',
	'New class': 'Nueva clase / Nuevo grupo',
	selectACategory: 'Select a category',
	favorites: 'Favorito',
	selectSpecificYearGroupsToGatherMoreInformationFrom: 'Select specific year groups to gather more information from',
	'Select specific classes to gather more information from': 'Select specific classes to gather more information from',
	customize: 'Customize',
	'Create your own customized group to gather information from': 'Create your own customized group to gather information from',
	'Select specific teachers you would like to get information from': 'Select specific teachers you would like to get information from',
	thereIsNoDataToShow: 'No hay información para mostrar',
	deselectAll: 'Deseleccionar',
	addToFavorites: 'Add to favorites',
	ok: 'OK',
	doYouWantToDeleteFavorite: '¿Quires eliminar este grupo de favoritos?',
	'Your opinion matters to us!': 'Your opinion matters to us!',
	'Your feedback helps us to improve. If you find our app helpful, please consider rating it.':
		'Your feedback helps us to improve. If you find our app helpful, please consider rating it.',
	'Thank you!': 'Thank you!',
	'Share your feedback': 'Share your feedback',
	'Leave your feedback': 'Leave your feedback',
	'Tell us how we can improve Upstrive in the box below': 'Tell us how we can improve Upstrive in the box below.',
	'Type your feedback here': 'Type your feedback here',
	rolemodelIsnotLoaded: '¡El modelo de rol no esta cargado... por favor refresca la página!',
	teacherIsCreatedAndPasswordHasBeenSent: 'Teacher is created and password has been sent.',
	teacherIsNotCreated: '¡El profesor no se ha creado!',
	'The new password has been sent!': 'The new password has been sent!',
	'Password delivery failed!': 'Password delivery failed!',
	teacherInfo: 'Información de profesor',
	enterTeachersDetails: "Enter teacher's details",
	"You can't delete yourself!": "You can't delete yourself!",
	deleteTeacher: 'Eliminar este profesor',
	teacherRole: 'Rol de profesor',
	selectTeacherRole: 'Select teacher role',
	thisTeacherWillbe: 'This teacher will be',
	'You must select class': 'You must select class',
	'Current class': 'Current class',
	'Select new class': 'Select new class',
	'Select class': 'Select class',
	'Transfer to another class successfully finished': 'Transfer to another class successfully finished',
	runReadyToGoContent: 'Run ready-to-go content',
	assignActionSuccess: 'Assign action success',
	userIsUpdated: 'El usuario ha sido actualizado',
	changePassword: 'Cambiar contraseña',
	enterYourNewPassword: 'Ingresa tu nueva contraseña',
	retypePassword: 'reintroducir contraseña',
	noteHasBeenDeleted: 'Note has been deleted',
	dayReview: 'Reseña del día',
	'Day review': 'Reseña del día',
	deleteNote: 'BORRAR NOTA',
	yourPrivateNotes: 'Tus notas privadas',
	confirmDeletion: 'Confirmar eliminación',
	deleteYourPrivateNote: 'Delete your private note?',
	'Language is changed': 'Language is changed',
	yourSecretKeyIsSaved: 'Your secret key is saved',
	selectLanguage: 'Seleccionar idioma',
	termsAndConditions: 'Términos y condiciones',
	privacyPolicy: 'Política de privacidad',
	changeSecurityKey: 'Change security key',
	createYourOwn: 'Crea tu propio contenido',
	readyToGo: 'Contenido listo para comenzar',
	classroom: 'Salón de clases',
	Analytics: 'Analytics',
	chat: 'Conversación',
	userManagement: 'Administración de usuario',
	instructionVideos: 'Videos instructivos',
	settings: 'Ajustes',
	'Full Desktop version': '',
	'Full Mobile version': '',
	individualFeaturesTraining: 'Individual Features Training',
	instructionVideosForStaff: 'Video de instrucciones para colaboradores',
	sunday: 'Domingo',
	monday: 'Lunes',
	tuesday: 'Martes',
	wednesday: 'Miércoles',
	thursday: 'Jueves',
	friday: 'Viernes',
	saturday: 'Sabado',
	today: 'Hoy',
	tomorrow: 'Mañana',
	yesterday: 'Ayer',
	month: '',
	months: '',
	days: 'días',
	hour: '',
	hours: '',
	minute: '',
	minutes: '',
	seconds: '',
	'Multiple classes': 'Multiple Classes',
	'Multiple years': 'Múltiples años',
	custom: 'Personalizar',
	'Select target': 'Select target',
	Filter: 'Filter',
	Gender: 'Género',
	Grade: '',
	"Student's feedback": '',
	"Student's comment": "Student's comment",
	'Feedback date': '',
	"Teachers' action": '',
	Reasons: '',
	"Student's emotion": '',
	Frequency: 'Frequency',
};
export default TRANSLATION_ES;
