import React, { useState } from 'react';
import ContentBG from '../../assets/svg/ContentBG.svg';
import DonutChartDash from './components/DonutChartDash';
//import { ReactComponent as Icon } from "../../assets/svg/ContentImage.svg";
import Icon from '../../assets/svg/ContentImage.png';
import WelcomeBanner from './WelcomeBanner';
import { ReactComponent as DoubleArrows } from '../../assets/svg/DoubleArrows.svg';
import TestP from '../../assets/svg/test.png';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../auth';
import Overview from './contents/Overview';
import Classroom from './contents/Classroom';
import Feed from './contents/Feed';
import { useEffect } from 'react';
import { getStudentDailyQuestionUsage, getTasks } from '../../server/server';
import MyTask from './MyTask';

function Content({ refresh, setRefresh, setContentState }) {
	const { t } = useTranslation();
	const { user } = useUser();
	const [expanded, setExpanded] = useState(true);
	const [loadingStudentPercentage, setLoadingStudentPercentage] = useState(true);
	const [studentUsagePercentage, setStudentUsagePercentage] = useState(0.3);
	const [selContent, setSelContent] = useState('overview');
	const [notifications, setNotifications] = useState({ overview: 0, classroom: 0, feed: 0 });
	const [openTasksNumber, setOpenTasksNumber] = useState(0);
	const [refreshTasks, setRefreshTasks] = useState(0);

	const contentListMap = {
		overview: t('overview'),
		classroom: t('results'),
		feed: t('feedPosts'),
		myTask: t('myTasks'),
	};

	const renderContentMap = {
		overview: <Overview />,
		classroom: <Classroom refresh={refresh} setRefresh={setRefresh} />,
		feed: <Feed />,
		myTask: <MyTask user={user} scrollId={'small-screen-scroll'} setRefreshTasks={setRefreshTasks} />,
	};

	useEffect(() => {
		setNotifications({ ...notifications, [selContent]: 0 });
		fetchStudentPercentage();
	}, [selContent]);

	const fetchStudentPercentage = async () => {
		const resp = await getStudentDailyQuestionUsage();
		if (resp.status == 200) {
			setLoadingStudentPercentage(false);
			setStudentUsagePercentage(resp.data);
		} else {
			setLoadingStudentPercentage(false);
		}
	};

	const contentList = user.hasOnlyPrimaryStudents ? ['overview', 'feed'] : ['overview', 'classroom', 'feed', 'myTask'];
	return (
		<div className=' rounded-xl w-full flex flex-col h-full justify-center '>
			<WelcomeBanner />

			<div className='flex flex-col grow bg-[#ECEBF8] z-[1] mt-[15px]'>
				<div className='flex flex-row gap-[15px]'>
					{contentList.map((button, index) => {
						const isSelected = button === selContent;
						return (
							<button
								className={`rounded-[10px] px-[30px] h-[60px] bg-[#5E639B] relative xxs:flex flex-row items-center justify-center gap-1 hover:!bg-highlightColor hover:!text-deepPurple transition-all duration-[230ms] ease-in-out  ${
									button === 'myTask' ? 'xl:hidden' : ''
								} `}
								onClick={() => {
									setSelContent(button);
									setContentState(button);
								}}
								style={{ backgroundColor: isSelected ? '#FDDF6D' : '#5E639B' }}
								key={index}
							>
								<div className={`dashboard-welcome-button ${isSelected ? 'text-deepPurple' : 'text-highlightColor'}`}>
									{contentListMap[button]}
								</div>
								{isSelected && (
									<div
										className={`w-[22px] h-[22px] absolute rotate-45 -bottom-[9px] bg-[#FDDF6D] rounded-[2px] left-[50%] -translate-x-[50%]`}
									></div>
								)}
							</button>
						);
					})}
				</div>
				<div className={`w-full mt-6 min-h-0 grow ${selContent === 'myTask' ? 'xl:hidden' : ''}`}>
					{renderContentMap[selContent]}
				</div>
			</div>
		</div>
	);
}

export default Content;
