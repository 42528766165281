import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import '../../styles/modals/ConfirmationFeedDeleteModal.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIconFrame } from '../../assets/svg/SuccessIconFrame.svg';
import { ReactComponent as SuccessStudentTransferIcon } from '../../assets/svg/SuccessStudentTransferIcon.svg';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import Loading from '../Loading';

function SuccessModal({ modalIsOpen, afterOpenModal, closeModal, blur = 'management', confirmation, message, subMessage = '' }) {
	const { t, locale } = useTranslation();

	const [progress, setProgress] = useState(false);

	useEffect(() => {
		if (modalIsOpen) {
			Modal.setAppElement('#root');
			if (blur == 'management') {
				document.getElementById('managementId').style.filter = 'blur(5px)';
				document.getElementById('sideBar').style.filter = 'blur(5px)';
			}
		} else {
			if (blur == 'management') {
				document.getElementById('managementId').style.filter = 'unset';
				document.getElementById('sideBar').style.filter = 'unset';
			}
		}
	}, [modalIsOpen]);

	const submitConfirm = () => {
		confirmation();
		// closeModal();
	};

	return (
		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			contentLabel='Example Modal'
			onAfterClose={() => {
				setProgress(false);
			}}
			style={{
				overlay: {
					zIndex: 12,
				},
				content: {
					width: 650,
					padding: 0,
					opacity: 1,
					minWidth: 650,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					margin: 'auto',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div className='absolute top-0 w-full flex items-center justify-center '>
				<div
					className='w-[60px] h-[60px] bg-deepPurple rounded-full cursor-pointer flex items-center justify-center z-[2] hover:bg-darkenPurple '
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						closeModal();
					}}
				>
					<CloseModalIcon />
				</div>
			</div>
			<div className='bg-whiteContrast rounded-xl flex flex-col  items-center justify-between p-10 mt-[30px]'>
				<p className='text-deepPurple text-[32px] leading-[42px] font-bold text-center max-w-[360px] flex-wrap'>{message}</p>
				<p className='text-deepPurple text-[14px] leading-[17px] font-medium -mb-10'>{subMessage}</p>
				<SuccessStudentTransferIcon />

				<div className='flex flex-row justify-center  items-center w-full mt-10'>
					<div
						className='bg-deepPurple pointer flex flex-row items-center justify-center   rounded-full  px-[25px] min-w-[250px] h-[50px]'
						onClick={() => closeModal()}
					>
						<p className='text-white text-[18px] leading-[23px] font-semibold'>{t('close')}</p>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default SuccessModal;
