import React, { useState } from 'react';
import { ReactComponent as QuestionIconNew } from '../../assets/svg/QuestionIconNew.svg';
import { format } from 'date-fns';
import '../../styles/feedsAndDashboard/TaskFeed.css';
import { fileSystemUrl, videoUrl } from '../../constants/Connections';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { answer } from '../../server/server';
import { notify } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';

function SingleQuestion({ task, user, loadTasks, setResultsNum, resultsNum, sameDay, openBigImage, checkUsersFeedbackNeeded }) {
	const { t, locale } = useTranslation();
	const url = `${fileSystemUrl}/${task?.files?.storage_name}?token=${user.token}`;
	const [loadingImage, setLoadingImage] = useState(true);
	const imgUrl = task.files ? url : false;
	const [questionText, setQuestionText] = useState('');
	const [requesting, setRequesting] = useState(false);
	const [showMore, setShowMore] = useState(false);

	const handleImageLoading = () => {
		setLoadingImage(false);
	};

	const handleSubmitQuestion = async (e) => {
		e.preventDefault();
		setRequesting(true);
		const response = await answer({
			answers: [questionText],
			user_id: user.id,
			content_id: task.id,
			created_date: Date.now(),
			trackWellbeing: !!task?.trackWellbeing,
		});
		if (response.status === 200) {
			notify(t('yourAnswerIsSaved'));
			resultsNum !== 0 ? setResultsNum(0) : loadTasks();
			checkUsersFeedbackNeeded();
		} else {
			console.log('error');
		}
		setRequesting(false);
	};

	const openInNewTab = (url) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	const hasImage = !!task.files;
	const isVideo = task?.files?.type === 'video';
	const mediaUrl = hasImage ? `${isVideo ? videoUrl : fileSystemUrl}/${task.files.storage_name}?token=${user.token}` : '';
	return (
		<div className={`bg-white ${sameDay ? 'mt-[10px]' : 'mt-[15px]'} rounded-xl w-full`}>
			<div className=' rounded-[10px] bg-containerGray p-[20px] flex flex-col w-full '>
				<div className={`w-full flex  gap-[10px] bg-containerGray  rounded-[10px] ${isVideo ? 'flex-col' : 'flex-row'}`}>
					{hasImage &&
						(isVideo ? (
							<div className='image-container-new w-full max-w-none max-h-[300px]'>
								<video controls controlsList='nodownload' className='max-h-[300px]'>
									<source src={mediaUrl} />
									{t('yourBrowserDoesNotSupportHTMLVideo')}
								</video>
							</div>
						) : (
							<div className='image-container-new cursor-pointer' onClick={() => openBigImage(imgUrl)}>
								{loadingImage && (
									<SkeletonTheme baseColor='#dadaff' highlightColor='#efefef'>
										<Skeleton />
									</SkeletonTheme>
								)}
								<img
									src={`${url}${url.includes('https') ? '&width=450' : ''}`}
									alt='postfeed'
									width={40}
									onLoad={handleImageLoading}
								/>
							</div>
						))}
					<div className='w-full'>
						<div className='flex flex-row items-center '>
							<QuestionIconNew />
							<p className='ml-[10px] text-[16px] font-[500]  text-[#413F5E]'>{t('open_question')}</p>
							<div className='flex-1'></div>
							<div className='text-[14px] leading-5 font-medium text-[#413F5E] opacity-60 mr-[5px]'>{t('time')}: </div>
							<div className='text-[14px] leading-5 font-medium text-[#413F5E]'>
								{format(new Date(task.publicationDate), 'HH:mm')}
							</div>
						</div>
						<div className='mt-[15px] text-[18px] leading-5 font-bold text-darkPurple'>{task.title}</div>
						{!!task.message && (
							<div className='mt-[10px]'>
								<pre className='text-[#9392AD] font-medium text-[14px] whitespace-pre-wrap'>
									{showMore ? task?.message : task?.message?.substring(0, 160)}
									{!showMore && task.message?.length > 160 && '...'}
									{task?.message?.length > 85 && (
										<span
											className='font-bold text-[#5E639B] pointer text-[14px]'
											onClick={() => setShowMore(!showMore)}
										>
											<br />
											{!showMore ? t('showMore') : t('showLess')}
										</span>
									)}
								</pre>
							</div>
						)}
						{task?.url && (
							<div
								className='bg-white w-full h-[40px] flex items-center justify-center text-mudPurple font-bold pointer text-[12px] rounded-[10px] mt-3'
								onClick={() => openInNewTab(task?.url)}
							>
								{t('openLink')}
							</div>
						)}
					</div>
				</div>
				<div className='mt-[20px] rounded-[10px] p-[20px] flex flex-col w-full border border-deselectPurple '>
					<textarea
						className='text-[16px] leading-5 font-medium text-deepPurple placeholder:text-deselectPurple resize-none bg-containerGray'
						type={'text'}
						placeholder={t('typeAnyAdditionalTextHere')}
						rows={5}
						value={questionText}
						onChange={(e) => setQuestionText(e.target.value)}
					/>
				</div>
				<button
					className={`mt-[12px] rounded-[10px] h-[50px] flex items-center justify-center w-full ${
						questionText === ''
							? 'bg-containerGray text-mudPurple '
							: 'bg-deepPurple text-white hover:text-highlightColor cursor-pointer'
					}`}
					onClick={(e) => handleSubmitQuestion(e)}
					disabled={questionText === '' || requesting}
					style={{ border: '1px solid #BEBECE' }}
				>
					{requesting ? (
						<Loading primaryColor='#ffffff' secondaryColor='#9392AD' size={25} stroke={7} />
					) : (
						<p className={`text-[14px] font-semibold leading-5`}>{t('submitAnswer')} </p>
					)}
				</button>
			</div>
		</div>
	);
}

export default SingleQuestion;
