import React, { useState, useEffect } from 'react';
import '../../styles/analytics/AnalyticsHome.css';

import { ReactComponent as Filter } from '../../assets/svg/filter.svg';
import 'react-datepicker/dist/react-datepicker.css';
import {
	getAllStudentsTarget,
	getAnalyticsContent,
	getAnalyticsHowDoYouFillStats,
	getById,
	loadPrimaryStudents,
} from '../../server/server';
import { useUser } from '../../auth/index';
import TargetGroupModal from '../modals/TargetGroupModal';
import AnalyticsDataRight from './AnalyticsDataRight';
import AnalyticsDataLeft from './AnalyticsDataLeft';
import BasicStudent from './BasicStudent';
import BasicTeacher from './BasicTeacher';
import BasicResponsive from './BasicResponsive';
import BasicUpstrive from './BasicUpstrive';
import AnalyticsDataContainer from './AnalyticsDataContainer';

import { translateTargetText, useQuery } from '../../utils/util';
import AnalyticsSurveyContainer from './AnalyticsSurveyContainer';
import DatePickerCustom from '../DatePickerCustom';
import AnalyticsHeader from './AnalyticsHeader';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

const day = 60 * 60 * 24 * 1000;

const defaultTargetOptions = {
	selection: [],
	type: 'root',
};

function AnalyzeContent() {
	const { t, locale } = useTranslation();
	const query = useQuery();
	const history = useHistory();
	const initType = query?.get('type') ?? 'home';
	const contentId = query?.get('content') ?? 'how-do-you-feel';
	const primary = !!query?.get('primary');
	const [loading, setLoading] = useState(true);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [selectedContent, setSelectedContent] = useState({});
	const { user } = useUser();
	const [displayMode, setDisplayMode] = useState(primary ? 'primary' : 'regular');
	const isRegularDisplayMode = displayMode === 'regular';
	const [rangeChanged, setRangeChanged] = useState(new Date());

	const [showTargetModal, setShowTargetModal] = useState(false);
	const [selectedMode, setSelectedMode] = useState(initType);
	const [primaryStudents, setPrimaryStudents] = useState({});
	const [regularTarget, setRegularTarget] = useState({});
	const [targetGroup, setTargetGroup] = useState({
		text: 'All target groups',
		target: [],
		unfilteredTarget: [],
	});

	useEffect(() => {
		if (initType == 'home') history.push('/analytics');
		let d = new Date();
		d.setDate(d.getDate() - 19);
		setStartDate(d);
		initData();
	}, []);

	const initData = async () => {
		const loadTarget = primary ? loadPrimaryTarget : loadUserTarget;
		await Promise.all([loadTarget(), loadContentData()]);
		setLoading(false);
	};

	const closeTargetModal = () => {
		setShowTargetModal(false);
	};

	const handleDateSelect = (date) => {
		if (startDate !== null && endDate !== null) {
			setStartDate(date);
			setEndDate(null);
			return;
		}
		if (startDate !== null && endDate === null && startDate < date) {
			setEndDate(date);
			return;
		}
		if (startDate !== null && endDate === null && startDate > date) {
			setStartDate(date);
			return;
		}
	};
	const updateAnalytics = () => {
		if (startDate !== null && endDate !== null) {
			setRangeChanged(new Date());
			return;
		}
	};
	const handleLastMonth = (start, end) => {
		setStartDate(start);
		setEndDate(end);
	};

	const loadContentData = async () => {
		if (contentId == 'how-do-you-feel') await loadDailyStats();
		else await loadContentById();
	};

	const loadContentById = async () => {
		const resp = await getById({ collection: 'content', id: contentId });
		if (resp.status == 200) {
			const data = resp.data;
			setSelectedContent(data);
		}
	};

	const loadDailyStats = async () => {
		const resp = await getAnalyticsHowDoYouFillStats();
		if (resp.status == 200) {
			const data = resp.data;
			setSelectedContent(data.content);
		}
	};

	const loadUserTarget = async () => {
		const resp = await getAllStudentsTarget();
		if (resp.status === 200) {
			const data = resp.data;
			const targetData = {
				target: data.target?.map((e) => e?.id),
				unfilteredTarget: data.target,
				targetOptions: defaultTargetOptions,
				text: user?.role_coverage?.target_text || 'My students',
			};
			setRegularTarget(targetData);
			setTargetGroup(targetData);
		}
	};

	const loadPrimaryTarget = async () => {
		const resp = await loadPrimaryStudents();
		if (resp.status == 200) {
			const targetData = {
				target: resp.data,
				unfilteredTarget: resp.data,
				targetOptions: defaultTargetOptions,
				text: user?.role_coverage?.target_text || 'My students',
			};

			setPrimaryStudents(targetData);
			setTargetGroup(targetData);
		}
	};

	const setReturnTarget = (item) => {
		const { name, selection, target, type } = item;
		const targetOptions = {
			selection: selection,
			type: type,
		};
		setTargetGroup({
			text: name || 'My students',
			target: target?.map((e) => e?.id),
			unfilteredTarget: target,
			targetOptions: targetOptions,
		});
	};

	const goBack = () => history.goBack();

	return (
		<div id='basic-student' className='analytics-container  light-small-scroll bg-[#ECEBF8] '>
			<div className='analytics-inner-container mb-[40px]'>
				{!!user?.hasPrimaryClasses && !user.hasOnlyPrimaryStudents && (
					<div className='flex flex-row gap-[20px] mb-[38px] items-center'>
						<p className='text-[#9392AD] font-semibold text-[18xp]'>{t('Display')}: </p>
						<div className='flex flex-row bg-[#FFFFFF] rounded-full'>
							<Link to={`/analytics`}>
								<button
									className={`h-[50px] px-[25px] rounded-full justify-center items-center font-bold ${
										isRegularDisplayMode ? 'bg-[#5E639B] text-[#FDDF6D]' : 'text-[#9392AD]'
									}`}
									disabled={isRegularDisplayMode}
								>
									{t('Regular')}
								</button>
							</Link>
							<button
								className={`h-[50px] px-[25px] rounded-full justify-center items-center font-bold ${
									!isRegularDisplayMode ? 'bg-[#5E639B] text-[#FDDF6D]' : 'text-[#9392AD]'
								}`}
								disabled={!isRegularDisplayMode}
							>
								{t('Primary')}
							</button>
						</div>
					</div>
				)}
				<div className='analytics-header'>
					<AnalyticsHeader />
					{(selectedMode === 'data' || selectedMode === 'survey') && (
						<div className='w-full flex flex-row items-center px-[30px] py-[20px] '>
							<div className='w-full flex flex-row items-center justify-between '>
								<div className='analytics-date-range'>
									<p style={{ paddingRight: 20 }}>{t('chooseDataRange')}:</p>
									<DatePickerCustom
										startDate={startDate}
										endDate={endDate}
										handleDateSelect={handleDateSelect}
										handleOkButton={updateAnalytics}
										handleLastMonth={handleLastMonth}
									/>
								</div>

								{isRegularDisplayMode && (
									<div
										className='analytics-target-button'
										style={{ height: 40 }}
										onClick={() => setShowTargetModal(true)}
									>
										<Filter fill='#413F5E' />
										<p>{translateTargetText(targetGroup.text)}</p>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
				{selectedMode === 'basicStudents' && <BasicStudent />}
				{selectedMode === 'basicTeachers' && <BasicTeacher />}
				{selectedMode === 'basicResponsive' && <BasicResponsive />}
				{selectedMode === 'basicUpstrive' && <BasicUpstrive />}
				{selectedMode === 'data' && (
					<AnalyticsDataContainer
						initLoading={loading}
						goBack={goBack}
						content={selectedContent}
						target={targetGroup.target}
						startDate={startDate}
						endDate={endDate}
						displayMode={displayMode}
						rangeChanged={rangeChanged}
					/>
				)}
				{selectedMode === 'survey' && <AnalyticsSurveyContainer goBack={goBack} content={selectedContent} />}
			</div>
			<TargetGroupModal showTarget={showTargetModal} closeTarget={closeTargetModal} setReturnTarget={setReturnTarget} />
		</div>
	);
}

export default AnalyzeContent;
