import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FaTimes, FaChevronLeft, FaChevronRight, FaCamera } from 'react-icons/fa';
import '../../styles/content/poll.css';
import TargetGroupModal from '../modals/TargetGroupModal';
import PollScheduleModal from '../modals/PollScheduleModal';
import { ContentContext } from './ContentContext';
import { useUser } from '../../auth';
import { getAllStudentsTarget, create, upload, update, createContent, createTemplate } from '../../server/server';
import { notify, translateTargetText } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { format_day } from '../../utils/format_day';

function ContentFeed() {
	let history = useHistory();
	const { t, locale } = useTranslation();
	const [contentItem, setContentItem] = useContext(ContentContext);

	const titleRef = useRef(null);
	const descRef = useRef(null);
	const [titleValue, setTitleValue] = useState('');
	const [descValue, setDescValue] = useState('');
	const [link, setLink] = useState({ valid: true, value: '' });

	const [showTargetGroupModal, setShowTargetGroupModal] = useState(false);
	const [showScheduleModal, setShowScheduleModal] = useState(false);

	const [image, setImage] = useState(undefined);
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [isError, setIsError] = useState(false);
	const { user, setUser } = useUser();
	const [editFile, setEditFile] = useState({});
	const [returnSchedule, setReturnSchedule] = useState({
		enabled: false,
		text: 'Off',
		recurrentText: 'Never',
		repeatEnabled: false,
		schedule_date: 0,
	});
	const [returnTarget, setReturnTarget] = useState({
		name: 'My students',
		target: [],
		selection: [],
		type: 'root',
	});
	const loadAllTarget = async () => {
		const response = await getAllStudentsTarget();
		if (response.status === 200) {
			setReturnTarget(response.data);
		}
	};
	useEffect(() => {
		if (contentItem.edit) {
			console.log('returnTArget', contentItem.editTarget);
			setReturnTarget(contentItem.editTarget);
			setReturnSchedule(contentItem.editschedule);
			if (contentItem.files?.storage_name) {
				setEditFile(contentItem.files);
			}
			setTitleValue(contentItem.title);
			setDescValue(contentItem.msg);
			setLink({ valid: true, value: contentItem.link });
			if (contentItem.imgUrl) {
				fetchImg();
			}
			console.log(contentItem?.editTarget?.target === undefined, contentItem?.editTarget?.target);
			if (contentItem?.editTarget?.name === 'My students' || contentItem?.editTarget?.target === undefined) {
				loadAllTarget();
			}
		} else {
			loadAllTarget();
		}
	}, []);
	const fetchImg = async () => {
		await fetch(contentItem.imgUrl)
			.then((res) => res.blob())
			.then((blob) => {
				const objectUrl = URL.createObjectURL(blob);
				setImage(objectUrl);
				return () => {
					console.log('remove img');
					URL.revokeObjectURL(objectUrl);
				};
			});
	};
	const cleanContext = () => {
		setContentItem({
			edit: false,
			type: 'feed',
			title: '',
			msg: '',
			options: ['', '', ''],
			link: '',
			imgUrl: '',
			returnID: '',
			files: {
				old_name: '',
				storage_name: '',
				size: null,
				type: 'image',
			},
			editTarget: {
				name: 'My students',
				target: [],
				selection: [],
				type: 'root',
			},
			editschedule: {
				enabled: false,
				text: 'Off',
				recurrentText: 'Never',
				repeatEnabled: false,
				schedule_date: 0,
			},
			editRepeat: {
				repeat: false,
				repeatPeriodText: 'Never',
				recurrentType: '',
				selectedDay: 0,
				selectedDays: [],
				endOfRecurrenceDate: 0,
			},
			likes: 0,
			like: false,
			views: 0,
			isReq: false,
			trackWellbeing: false,
			addedToAnalytics: false,
			repeat: false,
			isAnon: false,
			editID: null,
			programDetails: {
				programId: '',
				dayIndex: 0,
			},
		});
	};

	const handleBack = () => {
		cleanContext();
		switch (contentItem.edit) {
			case 'template':
				history.push('/content/templates');
				break;
			case 'list':
				history.push('/content/list');
				break;
			case 'program':
				history.push(`/content/program?id=${contentItem.returnID}`);
				break;
			case 'feed':
				history.push(`/feeds`);
				break;
			case '':
				history.push('/content/');
				break;
			default:
				history.push('/content/');
				break;
		}
	};

	const closeImage = () => {
		setImage(undefined);
		setSelectedFile(undefined);
	};

	useEffect(() => {
		if (!selectedFile) {
			setImage(undefined);
			console.log('undefined img');
			return;
		}
		console.log('set img');
		const objectUrl = URL.createObjectURL(selectedFile);
		setImage(objectUrl);
		return () => {
			console.log('remove img');
			URL.revokeObjectURL(objectUrl);
		};
	}, [selectedFile]);

	const DEFAULT_DATA = {
		created_date: Date.now(),
		device: 'desktop',
		gender_filter: 'all',
		institution_id: user?.institution_id,
		likes: 0,
		like: false,
		message: descValue,
		required: false,
		schedule: returnSchedule?.enabled,
		schedule_info: {
			enabled: returnSchedule?.enabled,
			recurrentText: returnSchedule?.recurrentText,
			repeatEnabled: returnSchedule?.repeatEnabled,
			text: returnSchedule?.text,
			schedule_date: returnSchedule?.schedule_date == 0 ? null : returnSchedule?.schedule_date,
		},
		status: 'start',
		target: returnTarget?.target ? returnTarget.target.map((a) => a?.id) : [],
		targetOptions: {
			selection: returnTarget?.selection,
			type: returnTarget?.type,
		},
		target_count: returnTarget?.target?.length,
		target_text: returnTarget?.name,
		title: titleValue,
		type: 'feed',
		unfilteredTarget: returnTarget?.target,
		user_id: user?.id,
		views: 0,
		url: link?.value,
		programDetails: contentItem?.programDetails?.programId ? contentItem?.programDetails : undefined,
		schedule_date: returnSchedule?.schedule_date == 0 ? null : returnSchedule?.schedule_date,
	};

	const uploadFile = async (postType) => {
		const req = await upload({ file: selectedFile, type: 'image' });
		if (req) {
			console.log('tteatea', req);

			switch (postType) {
				case 'update':
					updateContent(req.data);
					break;
				case 'post':
					postFeed(req.data);
					break;
				case 'template':
					saveTemplate(req.data);
					break;
				default:
					console.log('error');
					break;
			}
		}
	};

	const postFeed = async (propFiles) => {
		const PostData = { ...DEFAULT_DATA, files: propFiles };

		const resp = await createContent({
			data: PostData,
		});
		if (resp.status === 200) {
			handleBack();
			notify(t('contentIsCreated'));
		} else {
			notify(t('contentIsNotCreated'));
		}
	};
	const updateContent = async (propFiles) => {
		const updateFiles = propFiles ? propFiles : image ? contentItem.files : undefined;
		const updateData = {
			...DEFAULT_DATA,
			likes: contentItem?.likes,
			like: contentItem?.like,
			views: contentItem?.views,
			files: updateFiles,
			id: contentItem.editID,
		};
		const resp = await createContent({
			data: updateData,
		});
		if (resp.status === 200) {
			handleBack();
			notify(t('yourDataIsUpdated'));
		} else {
			notify(t('yourDataIsNotUpdated'));
		}
	};
	const saveTemplate = async (propFiles) => {
		const updateFiles = propFiles ? propFiles : image ? contentItem.files : undefined;
		const PostData = { ...DEFAULT_DATA, files: updateFiles };
		const resp = await createTemplate({ data: PostData });
		if (resp.status === 200) {
			// handleBack();
			notify(t('templateIsCreated'));
		} else {
			notify(t('templateIsNotCreated'));
		}
	};

	const handlePost = () => {
		let valid = checkLink(link.value);
		setLink({ valid: valid });
		if (titleValue === '') {
			setIsError(true);
		} else {
			if (valid) {
				setLink({ valid: true, value: link.value });
				if (contentItem.editID) {
					if (selectedFile) {
						uploadFile('update');
					} else {
						updateContent();
					}
				} else {
					if (selectedFile) {
						uploadFile('post');
					} else {
						postFeed();
					}
				}
			} else {
				console.log('not valid url', link.value);
				setLink({ valid: false, value: link.value });
			}
		}
	};
	const checkLink = (linkPass) => {
		console.log('valid check', linkPass, linkPass === undefined, linkPass === '');
		const linkRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
		if (linkPass === undefined || linkPass === '') {
			console.log('valid');
			return true;
		} else {
			console.log('not valid');
			return linkRegex.test(linkPass);
		}
	};

	const handleTemplateSave = () => {
		console.log('handleTemplateSave');
		let valid = checkLink(link.value);
		setLink({ valid: valid });
		if (titleValue === '') {
			setIsError(true);
		} else {
			if (valid) {
				setLink({ valid: true, value: link.value });
				if (selectedFile) {
					uploadFile('template');
				} else {
					saveTemplate();
				}
			} else {
				console.log('not valid url', link.value);
				setLink({ valid: false, value: link.value });
			}
		}
	};

	const onImageChange = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		setSelectedFile(e.target.files[0]);
	};

	const openTargetGroupModal = () => {
		setShowTargetGroupModal(true);
	};
	const closeTargetGroupModal = () => {
		setShowTargetGroupModal(false);
	};

	const openScheduleModal = () => {
		setShowScheduleModal(true);
	};
	const closeScheduleModal = () => {
		setShowScheduleModal(false);
	};
	const press_upload = () => {
		document.getElementById('photo_upload_modal')?.click();
	};
	const resetInputValue = (e) => {
		e.target.value = null;
	};
	useEffect(() => {
		titleRef.current.style.height = '80px';
		const scrollHeight = titleRef.current.scrollHeight;
		titleRef.current.style.height = scrollHeight - 30 + 'px';
	}, [titleValue]);
	useEffect(() => {
		descRef.current.style.height = '100px';
		const scrollHeight = descRef.current.scrollHeight;
		descRef.current.style.height = scrollHeight - 10 + 'px';
	}, [descValue]);
	return (
		<div className='content'>
			<div className='row'>
				<div className='col-lg-8 col-xl-6' id='col8'>
					<div className={`poll-card ${i18n.language == 'ar' ? 'ar-lang' : ''}`}>
						<div className='poll-card-header'>
							<div className='poll-card-title'>
								<span
									className='back_button_poll content-back'
									onClick={() => {
										handleBack();
									}}
								>
									{i18n.language == 'ar' ? (
										<FaChevronRight id='back-program-arrow' />
									) : (
										<FaChevronLeft id='back-program-arrow' />
									)}
									{t('back')}
								</span>
								<h1>{t('createAPost')}</h1>
							</div>
							<textarea
								placeholder={isError ? "The Title can't be empty!" : t('typeYourHeadlineHere')}
								id='autoExpand_title'
								ref={titleRef}
								value={titleValue}
								rows='1'
								className={isError ? 'error-title' : ''}
								onFocus={() => setIsError(false)}
								onChange={(e) => {
									setTitleValue(e.target.value);
								}}
							></textarea>
						</div>
						<div className='card_description'>
							<textarea
								placeholder={t('addAnOptionalMessage')}
								id='autoExpand_desc'
								rows='2'
								ref={descRef}
								value={descValue}
								onChange={(e) => {
									setDescValue(e.target.value);
								}}
							></textarea>
						</div>
						<div className='card_body'>
							<input
								type='text'
								placeholder={t('enterUrlLink') + t('optional')}
								aria-invalid='false'
								style={{ backgroundColor: 'rgba(66, 64, 95, 0.15)' }}
								className={`link-option ${link.valid ? '' : 'not-valid'}`}
								value={link?.value}
								onChange={(e) => {
									setLink({ valid: true, value: e.target.value });
								}}
							/>
							{!link.valid && (
								<label
									style={{
										display: 'block',
										color: '#FC4C59',
										fontWeight: '700',
									}}
								>
									{t('invalidLink')}
								</label>
							)}
						</div>
						<div className='card_footer'>
							<div className='course-media-container' style={{ alignItems: 'center' }}>
								{!image && (
									<div className='upload-image-container'>
										<div className='upload-image-container-icon'>
											<FaCamera style={{ width: 42, height: 42, color: '#8484b1' }} />
										</div>
										<button
											className='upload-image-container-button mt-2'
											onClick={(e) => {
												e.preventDefault();
												press_upload();
											}}
										>
											{t('addPhoto')}
										</button>
									</div>
								)}
								{image && (
									<div className='upload-image-container'>
										<div
											style={{
												width: 340,
												maxHeight: 280,
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'space-between',
												alignItems: 'flex-end',
												backgroundColor: ' rgb(161, 184, 230)',
											}}
										>
											<div
												onClick={() => closeImage()}
												style={{
													padding: 10,
													borderRadius: 20,
													backgroundColor: 'white',
													opacity: 0.4,
													cursor: 'pointer',
												}}
											>
												<FaTimes
													style={{
														width: 20,
														height: 20,
														stroke: 'white',
														display: 'block',
													}}
												/>
											</div>

											<img
												style={{
													objectFit: 'contain',
													width: '100%',
													minHeight: 170,
													marginTop: 10,
												}}
												src={image}
												alt=''
											/>
										</div>
									</div>
								)}
								<input
									title='Add Video / Photo'
									style={{ display: 'none' }}
									accept='video/*, image/*'
									type='file'
									id='photo_upload_modal'
									onChange={onImageChange}
									onClick={resetInputValue}
								/>
							</div>

							<div
								className='feed-settings-container'
								style={i18n.language == 'ar' ? { marginLeft: 'auto', marginRight: '5px' } : {}}
							>
								<div className='feed-setting-item' onClick={() => openTargetGroupModal()}>
									<label className='feed-setting-item-title'>{t('targetGroup')}</label>
									<label className='feed-setting-item-value'>
										<b>{translateTargetText(returnTarget?.name)}</b>
									</label>
								</div>
								<div className='feed-setting-item' onClick={() => openScheduleModal()}>
									<label className='feed-setting-item-title'>{t('schedule')}</label>
									<label className='feed-setting-item-value'>
										<b>
											{returnSchedule?.enabled
												? format_day(returnSchedule?.schedule_date, true, i18n.language)
												: t('off')}
										</b>
									</label>
								</div>

								<div className='feed-setting-spacer'></div>
								<div className='feed-submit-container'>
									<button className='feed-button-save-template' onClick={() => handleTemplateSave()}>
										{t('saveAsTemplate')}
									</button>
									<button
										className='feed-button-submit'
										style={i18n.language == 'ar' ? { marginLeft: 'auto', marginRight: '5px' } : {}}
										onClick={() => handlePost()}
									>
										{t('post')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<TargetGroupModal showTarget={showTargetGroupModal} closeTarget={closeTargetGroupModal} setReturnTarget={setReturnTarget} />
			<PollScheduleModal showSchedule={showScheduleModal} closeSchedule={closeScheduleModal} setReturnSchedule={setReturnSchedule} />
		</div>
	);
}

export default ContentFeed;
