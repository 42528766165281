import React, { useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/FeedBackModal.css';
import { ReactComponent as SearchBlue } from '../../assets/svg/SearchBlue.svg';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import '../../styles/modals/WellbeingProfileModal.css';
import { getWellbeingUsers, openWellbeingProfile } from '../../server/server';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../Loading';

function CreateWellbeingProfileModal({ modalIsOpen, closeModal }) {
	let history = useHistory();
	const { t, locale } = useTranslation();
	const [searchText, setSearchText] = useState('');
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(true);
	const [userSize, setUserSize] = useState(0);
	const [users, setUsers] = useState([]);
	const timeout = useRef(null);

	const searchCounsellingProfiles = (e) => {
		setLoading(true);
		setHasMore(true);
		setSearchText(e.target.value);
		if (timeout !== null) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			loadUsers();
		}, 700);
	};

	const loadUsers = async (number = 0) => {
		const response = await getWellbeingUsers({
			text: searchText,
			classGroup: 'allUsers',
			refresh: true,
			loaded: number,
		});
		if (response.status === 200) {
			console.log('create wellbeing profile modal list of students', response?.data);
			setLoading(false);
			if (number === 0) setUsers([...response?.data?.list]);
			else setUsers([...users, ...response?.data?.list]);
			setUserSize(response?.data?.size);
		} else {
			setLoading(false);
			setUsers([]);
			setUserSize(0);
			setHasMore(true);
		}
	};

	const redirectToProfile = async (user) => {
		const resp = await openWellbeingProfile(user);
		if (resp.status === 200) {
			history.push(`counseling/student_profile?id=${resp?.data?.track?.id}`);
		}
	};
	const loadNext = () => {
		if (userSize <= users.length) {
			setHasMore(false);
			return;
		}
		loadUsers(users.length);
	};

	React.useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);
	return (
		<ReactModal
			transparent
			ariaHideApp={false}
			closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {
				loadUsers();
			}}
			onAfterClose={() => {
				setUsers([]);
				setUserSize(0);
				setHasMore(true);
				setSearchText('');
			}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '80%',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className='feedback-modal-icon' onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
			</div>
			<div className='create-feedback-modal'>
				<div className='create-feedback-header' style={{ background: '#ECEBF8' }}>
					<p>{t('createWellbeingProfile')} </p>
				</div>
				<div className='create-feedback-body'>
					<div className='create-feedback-inner'>
						<div className='create-feedback-input'>
							<input
								type='text'
								placeholder={t('search')}
								value={searchText}
								onChange={(e) => searchCounsellingProfiles(e)}
								autoComplete='off'
							/>
							<SearchBlue />
						</div>
						<div id='scrollableDivWellbeing' className='create-feedback-scroll light-small-scroll'>
							<div className=' w-full' style={{ paddingRight: 12 }}>
								{!loading && users.length !== 0 && (
									<InfiniteScroll
										dataLength={users.length}
										next={loadNext}
										hasMore={hasMore}
										scrollableTarget='scrollableDivWellbeing'
									>
										{users.map((user) => {
											return (
												<div
													className='create-feedback-card'
													key={user?.id}
													onClick={() => redirectToProfile(user)}
												>
													<p>
														{user?.first_name} {user?.last_name}
													</p>
													<p>{user?.classesName}</p>
												</div>
											);
										})}
									</InfiniteScroll>
								)}
							</div>
							{!loading && users.length == 0 && (
								<div className='counseling-list-empty'>
									<div>{t('yourStudentListIsEmpty')} </div>
								</div>
							)}
							{loading && (
								<div className='loading-center counseling-loader'>
									<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={75} stroke={4} />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default CreateWellbeingProfileModal;
