import React, { useEffect, useState } from 'react';
import DonutChartDash from '../dashboard/components/DonutChartDash';
import ReactTooltip from 'react-tooltip';
// import { t } from 'i18next';
import { ReactComponent as GreenArrowUp } from '../../assets/svg/GreenArrowUp.svg';
import { ReactComponent as GreyArrowDown } from '../../assets/svg/GreyArrowDown.svg';
import { getAdditionalTrends } from '../../server/server';
import Loading from '../Loading';
import ClassroomEngagementTrendItem from './ClassroomEngagementTrendItem';
import { useTranslation } from 'react-i18next';

function ClassroomEngagementTrend({ className = '', period = 7, smallTitleText = false, hideOverall }) {
	const { t, locale } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});

	useEffect(() => {
		fetchData(period);
	}, [period]);

	const fetchData = async (period) => {
		setLoading(true);
		const resp = await getAdditionalTrends(period);
		if (resp.status === 200) {
			setLoading(false);
			setData(resp.data);
		}
	};

	return (
		<div className={`positive-emotion-container flex-1 ${className}`}>
			{!hideOverall && (
				<ClassroomEngagementTrendItem
					title={t('Overall engagement')}
					trendPlaceholder={t(`Relative change to the same selected previous period`)}
					hoverPlaceholder={t(`Percentage of students that used Upstrive in the period (any function, just opened it)`)}
					smallTitleText={smallTitleText}
					loading={loading}
					percentage={data?.overallEngagement?.percentage ?? 0}
					trend={data?.overallEngagement?.trend ?? 0}
				/>
			)}

			<ClassroomEngagementTrendItem
				title={t('Engagement with emotions')}
				trendPlaceholder={t(`Relative change to the same selected previous period`)}
				hoverPlaceholder={t(`Percentage of students that submitted an emotion in the selected time frame`)}
				smallTitleText={smallTitleText}
				loading={loading}
				percentage={data?.emotionsEngagement?.percentage ?? 0}
				trend={data?.emotionsEngagement?.trend ?? 0}
			/>

			<div className='flex gap-[8px] items-center flex-1'>
				<div
					className='cursor-help'
					data-tip={t(`Total number of emotions submitted in the time frame`)}
					data-for={`totalNumberOfEmotions`}
				>
					<div className='h-[45px] min-w-[45px] px-[7px] bg-[#7b7ac3] rounded-full flex justify-center items-center text-[#FDDF6D] font-semibold transition-all ease-in-out'>
						{loading ? <Loading /> : data?.totalEmotions ?? 0}
					</div>
					{!loading && (
						<ReactTooltip
							id={`totalNumberOfEmotions`}
							arrowColor='#5E639B'
							effect='solid'
							backgroundColor='#5E639B'
							className='wellbeing-tooltip'
							classNameArrow='tooltip-arrow'
						/>
					)}
				</div>
				<p className={` !font-medium text-[#5E639B]  flex-1 ${smallTitleText ? 'text-sm' : 'text-[16px]'}`}>
					{t('Total emotions submitted')}
				</p>
			</div>
		</div>
	);
}

export default ClassroomEngagementTrend;
