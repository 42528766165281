import React, { useEffect, useState } from 'react';
import { CONTENT_TOPICS } from '../../constants/Constants';
import '../../styles/content/readyToGoContent.css';
import { ReactComponent as RunningIcon } from '../../assets/svg/RunningIcon.svg';
import { ReactComponent as ShowMoreLessIcon } from '../../assets/svg/ShowMoreLessIcon.svg';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as ProgramIcon } from '../../assets/svg/ProgramIcon.svg';
import { ReactComponent as SurveyContentIcon } from '../../assets/svg/SurveyContentIcon.svg';
import { ReactComponent as JournalContentIcon } from '../../assets/svg/JournalContentIcon.svg';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';
import { translateTargetText } from '../../utils/util';

const CONTENT_TYPES = [
	{
		code: 'programs',
		title: 'Programs',
		icon: <ProgramIcon />,
	},
	{
		code: 'surveys',
		title: 'Surveys',
		icon: <SurveyContentIcon />,
	},
	{
		code: 'journals',
		title: 'Journals',
		icon: <JournalContentIcon />,
	},
];

const TopicHomeList = ({
	content = { programs: [], surveys: [], journals: [] },
	onClick = () => {},
	openCurrentlyRunningPrograms = () => {},
	loading = true,
}) => {
	return (
		<div className='type-home-container'>
			{loading && (
				<div className='h-full w-full  flex items-center justify-center'>
					<Loading size={40} />
				</div>
			)}
			{!loading &&
				CONTENT_TYPES.filter((e) => !!content[e.code]?.length)?.map((type) => {
					return (
						<ContentType
							type={type}
							content={content[type.code]}
							onClick={(data) => {
								onClick(data, type.title);
							}}
							openCurrentlyRunningPrograms={openCurrentlyRunningPrograms}
							key={type.code}
						/>
					);
				})}
		</div>
	);
};

const ContentType = ({ type, content, onClick, openCurrentlyRunningPrograms }) => {
	const [showMore, setShowMore] = useState(content.length <= 2);
	const needShowMoreButton = content.length > 2;
	const { t, locale } = useTranslation();

	const realContent = needShowMoreButton && !showMore ? content.slice(0, 2) : content;
	return (
		<div className='single-type-section' key={type.code}>
			<div className='single-type-section-header'>
				{React.cloneElement(type.icon, { fill: '#413F5E' })}
				<p className='single-type-section-title' style={{ color: '#413F5E', marginRight: 25 }}>
					{t(type.title)}
				</p>
				<div className='single-type-dash' style={{ borderTopColor: '#BEBECE' }}></div>
			</div>
			<div className='single-type-section-items'>
				{realContent.map((item, index) => {
					const backgroundColor = `bg-[#F8F8FB]`;
					const hoverColor = `hover:bg-[#ECEBF8]`;
					const message = item?.runningPrograms?.map((target, index) => translateTargetText(target.target_text)).join(', ') ?? '';
					return (
						<button
							className={`single-type-section-item  ${backgroundColor} ${hoverColor}`}
							onClick={() => {
								onClick(item);
							}}
							key={index}
						>
							<div className='flex flex-row items-center justify-between mb-[10px] w-full'>
								<p className='single-type-item-title text-left'>{item?.name ?? item?.title}</p>
								{!!item.runningPrograms && (
									<div
										data-tip={t('currentlyRunning') + `: ${message}`}
										data-for='running-tooltip'
										onClick={(e) => {
											e.stopPropagation();
											openCurrentlyRunningPrograms(item.runningPrograms);
										}}
									>
										<RunningIcon width={27} height={25} />
										<ReactTooltip
											id='running-tooltip'
											arrowColor='#5E639B'
											effect='solid'
											backgroundColor='#5E639B'
											className='wellbeing-tooltip'
										/>
									</div>
								)}
							</div>
							<p className='single-type-item-description'>{item?.description}</p>
						</button>
					);
				})}
			</div>
			<div className='w-full  justify-center'>
				{needShowMoreButton && (
					<button
						className='showMoreLessButton'
						onClick={() => {
							setShowMore(!showMore);
						}}
					>
						<div>
							<ShowMoreLessIcon className={` ${showMore ? 'mb-[3px] rotate-180' : ' mb-[3px]'}`} />
						</div>
						<span className='ml-[8px]'>{showMore ? t('showLess') : t('showMore')}</span>
					</button>
				)}
			</div>
		</div>
	);
};

export default TopicHomeList;
