import React from 'react';

import { ReactComponent as Filter } from '../../../assets/svg/filter.svg';
import { ReactComponent as Archive } from '../../../assets/svg/archive.svg';
import { useState } from 'react';
import { ReactComponent as Search } from '../../../assets/svg/Search.svg';
import SingleContent from './classroom/SingleContent';
import { useEffect } from 'react';
import { deleteById, getFeeds, getLiveResults } from '../../../server/server';
import { useUser } from '../../../auth';
import ShowContentResultsModal from '../../modals/ShowContentResultsModal';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { datesAreOnSameDay, format_day, notify } from '../../../utils/util';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ReactComponent as Calendar } from '../../../assets/svg/Calendar.svg';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactComponent as AngleUp } from '../../../assets/svg/AngleUp.svg';
import SingleFeed from './feed/SingleFeed';
import useIntersection from '../../../utils/useIntersection';
import { useReducer } from 'react';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import BigImageModal from '../../modals/BigImageModal';
import Loading from '../../Loading';
import '../../../styles/dashboard/Feeds.css';
import i18n from '../../../i18n';
import { localeMap } from '../../../constants/Constants';

const day = 60 * 60 * 24 * 1000;

function Feed() {
	const history = useHistory();
	const [expandButton, setExpandButton] = useState(false);
	const [filterOptions, setFilterOptions] = useState(false);
	const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 1 * day));
	const [endDate, setEndDate] = useState(new Date());
	const [startDatePicker, setStartDatePicker] = useState(false);
	const [endDatePicker, setEndDatePicker] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [isEmpty, setIsEmpty] = useState(false);
	const [feeds, setFeeds] = useState([]);
	const [archive, setArchive] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [resultNum, setResultNum] = useState(0);
	const [loading, setLoading] = useState(true);
	const ref = useRef();
	const { t, locale } = useTranslation();
	const { user } = useUser();
	const [deleteModalOpen, setDelModalOpen] = useState(false);
	const [imageModalOpen, setImageModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState('');

	const [selId, setSelId] = useState('');

	const loadFeeds = async () => {
		const startFunctionTime = Date.now();
		let start = startDate;
		start.setHours(0);
		start.setMinutes(0);
		let end = endDate;
		end.setHours(0);
		end.setMinutes(0);
		const data = {
			user: user,
			number_of_feeds: resultNum,
			refreshing: false,
			scroll_enabled: true,
			text: searchText,
		};

		data.startDate = start.getTime();
		data.endDate = end.getTime();

		const resp = await getFeeds(data);
		if (resp?.status === 200) {
			const size = resp?.data?.size;
			const data = resp?.data?.data;
			if (resultNum === 0) {
				setFeeds(data);
				setHasMore(size > data?.length);
			} else if (data?.length > 0) {
				setFeeds((prev) => [...prev, ...data]);
				setHasMore(size > feeds?.length + data?.length);
			}
			const endFunctionTime = Date.now();
			const timeSpent = endFunctionTime - startFunctionTime;
			setTimeout(() => setLoading(false), Math.max(timeSpent, 400));
		} else {
			setFeeds([]);
			setResultNum(0);
		}
	};
	const handleDelete = async () => {
		const resp = await deleteById({ collection: 'content', id: selId });
		if (resp.status === 200) {
			notify(t('contentDeleted'));
			if (resultNum === 0) loadFeeds();
			else setResultNum(0);
			setDelModalOpen(false);
		}
	};
	useEffect(() => {
		setLoading(true);
		loadFeeds();
	}, [resultNum, searchText]);

	const openBigImage = (url) => {
		setSelectedImage(url);
		setImageModalOpen(true);
	};
	const closeImageModal = () => {
		setImageModalOpen(false);
		setSelectedImage('');
	};

	const closeDelModal = () => {
		setDelModalOpen(false);
	};
	const openDeleteForSingle = (id) => {
		setSelId(id);
		setDelModalOpen(true);
	};

	const changeStartDate = (date) => {
		if (date === null && date === undefined) return;
		setStartDate(date);
	};
	const changeEndDate = (date) => {
		if (date === null && date === undefined) return;
		setEndDate(date);
	};
	const openPage = (page) => {
		history.push(page);
	};

	return (
		<div className='relative flex flex-col h-full'>
			<div
				className={`rounded-[10px] px-4 bg-white w-full flex flex-col items-center  ${
					filterOptions ? 'h-[136px] justify-between py-2' : 'h-[68px] justify-center'
				}`}
			>
				<div className='flex items-center justify-between w-full'>
					<div className='flex items-center'>
						<div className='stroke-deselectPurple  bg-containerGray flex flex-row items-center h-[41px] px-4 w-[270px] rounded-full'>
							<input
								type='text'
								placeholder={t('searchResults')}
								className='w-full h-full text-deepPurple font-medium text-[16px] leading-5 bg-containerGray focus:font-normal   placeholder:text-mudPurple placeholder:font-normal'
								onChange={(e) => setSearchText(e.target.value)}
								value={searchText}
							/>
							<Search />
						</div>
						<div
							className={`h-[40px] ml-2 flex pointer items-center justify-center  font-semibold px-3 py-1 rounded-full ${
								filterOptions ? 'bg-lightBG text-deepPurple' : 'bg-containerGray text-mudPurple'
							}`}
							onClick={() => setFilterOptions(!filterOptions)}
						>
							<Filter fill={filterOptions ? '#5E639B' : '#9392AD'} />
							<p className='ml-2'>{t('filter')} </p>
						</div>
					</div>

					{/* <div className='flex items-center pointer' onClick={() => setArchive(!archive)}>
						<Archive fill={archive ? '#413F5E' : '#9392AD'} />
						<span className={`text-[16px] font-bold ml-2 ${archive ? 'text-darkPurple' : 'text-mudPurple'}`}>Archive</span>
					</div> */}
				</div>
				{filterOptions && <div style={{ borderTop: '1px dashed #BEBECE', height: 1 }} className='w-full'></div>}
				{filterOptions && (
					<div className='w-full relative'>
						<div className='flex items-center justify-start'>
							<div className='flex items-center justify-center bg-containerGray h-[40px] rounded-full px-5 pointer'>
								<Calendar style={{ color: '#5E639B' }} />
								<p
									className='font-semibold text-deselectPurple'
									style={{ paddingLeft: 10, paddingRight: 18 }}
									onClick={() => setStartDatePicker(true)}
								>
									{t('from')} <span className='text-darkPurple'>{startDate?.toLocaleDateString()}</span>
								</p>
								<p className='font-semibold text-deselectPurple' onClick={() => setEndDatePicker(true)}>
									{t('to')} <span className='text-darkPurple'> {endDate?.toLocaleDateString()} </span>
								</p>
								<div style={{ position: 'absolute', top: 0, left: 0, visibility: 'hidden' }}>
									<LocalizationProvider adapterLocale={localeMap[i18n.language]} dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											maxDate={new Date(endDate?.getTime())}
											open={startDatePicker}
											onClose={() => setStartDatePicker(false)}
											inputFormat='MM/dd/yyyy'
											value={startDate}
											onChange={changeStartDate}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</div>
								<div style={{ position: 'absolute', top: 0, left: 0, visibility: 'hidden' }}>
									<LocalizationProvider adapterLocale={localeMap[i18n.language]} dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											maxDate={new Date()}
											minDate={new Date(startDate?.getTime())}
											open={endDatePicker}
											onClose={() => setEndDatePicker(false)}
											inputFormat='MM/dd/yyyy'
											value={endDate}
											onChange={changeEndDate}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className='p-2 w-full rounded-[10px] bg-white mt-4 h-full grow flex flex-col'>
				<div className=' w-full  px-6 mb-5 gray-small-scroll overflow-y-scroll h-0 flex-auto' id='scroll-inf-feed'>
					<div
						className={`loading-center h-full ${
							loading ? 'visible opacity-100' : 'hidden opacity-0'
						} transition-all duration-200 ease-in-out`}
					>
						<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={50} stroke={3} />
					</div>

					<InfiniteScroll
						dataLength={feeds?.length}
						next={() => setResultNum(feeds?.length)}
						hasMore={hasMore}
						scrollableTarget='scroll-inf-feed'
						scrollThreshold={0.8}
						inverse={false}
					>
						{feeds?.map((feed, index) => {
							const previousNote = index > 0 && feeds[index - 1];
							const previousNoteDay = +previousNote?.publicationDate;
							const sameDay =
								!!previousNoteDay && datesAreOnSameDay(new Date(+feed?.publicationDate), new Date(previousNoteDay));

							return (
								<div key={feed.id}>
									{!sameDay && (
										<div className='w-full mt-[15px] flex justify-center gap-1'>
											<span className='text-sm font-medium  text-mudPurple'>{t('date')}: </span>
											<span className='text-sm font-medium  text-darkenPurple'>
												{t(format_day(+feed?.publicationDate, false, i18n.language))}
											</span>
										</div>
									)}
									<SingleFeed
										feed={feed}
										deleteFeed={openDeleteForSingle}
										openBigImage={openBigImage}
										sameDay={sameDay}
										index={index}
									/>
								</div>
							);
						})}
					</InfiniteScroll>
				</div>
			</div>
			<div
				className={`absolute bottom-[10px] right-[10px]  ${
					expandButton ? 'h-[120px] w-[190px] no-delay' : 'h-[70px] w-[70px] create-button-container'
				}`}
				onMouseLeave={() => {
					setExpandButton(false);
				}}
			>
				{
					<div>
						<div>
							<div
								className={`absolute right-[10px] font-bold pointer bg-deepPurple rounded-3xl text-white py-[0.8rem] px-5 flex items-center
								${expandButton ? 'show-create-post-button' : 'hide-create-post-button'} create-post-button`}
								id='upcoming-content-modal-trigger'
								onClick={() => openPage('/content?type=feed')}
							>
								<div className='relative flex items-center  '>
									<div className='relative flex items-center p-1  bg-highlightColor rounded-full'>
										<FaPlus fill='#5E639B' />
									</div>
									<span className='ml-3'>{t('createPosts')} </span>
								</div>
							</div>
						</div>
					</div>
				}
				<div
					className=' absolute bottom-[10px] right-[10px] font-bold pointer bg-deepPurple rounded-3xl text-white h-[50px] w-[50px] justify-center flex items-center'
					id='upcoming-content-modal-trigger'
					onMouseEnter={() => setExpandButton(true)}
				>
					{/* {expandButton ? (
						<div className='relative flex items-center p-1  bg-white rounded-full'>
							<FaTimes fill='#5E639B' />
						</div>
					) :  */}
					<div
						className={`relative flex items-center p-1 feed-create-button   rounded-full ${
							expandButton ? 'bg-white feed-button-x' : ' feed-button-times'
						}`}
					>
						<FaPlus fill={'#5E639B'} />
					</div>
					{/* )} */}
				</div>
			</div>
			<ConfirmationModal
				modalIsOpen={deleteModalOpen}
				closeModal={closeDelModal}
				confirmation={handleDelete}
				message={t('doYouWantToDeleteThis')}
				subMessage={t('deleteFeedContent')}
			/>
			<BigImageModal modalIsOpen={imageModalOpen} closeModal={closeImageModal} selectedImage={selectedImage} />
		</div>
	);
}

export default Feed;
