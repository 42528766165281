import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as UpIcon } from '../../../../assets/svg/MonitoringUp.svg';
import { ReactComponent as DownIcon } from '../../../../assets/svg/MonitoringDown.svg';
import { ReactComponent as Plus } from '../../../../assets/svg/Plus.svg';
import { ReactComponent as UpGreenIcon } from '../../../../assets/svg/UpGreenIcon.svg';
import { ReactComponent as DownGrayIcon } from '../../../../assets/svg/DownGrayIcon.svg';
import { emotions_heads, filterPeriods, targetGroupList } from '../../../../constants/Constants';
import { ReactComponent as DropdownTransparent } from '../../../../assets/svg/DropdownTransparent.svg';
import { ReactComponent as EditMonitoring } from '../../../../assets/svg/EditMonitoring.svg';
import { ReactComponent as DeleteMonitoring } from '../../../../assets/svg/DeleteMonitoring.svg';
import { ReactComponent as Info } from '../../../../assets/svg/InfoIcon.svg';

import {
	deleteMonitoringGroup,
	getDefaultMonitoringStats,
	getMonitoringGroups,
	getMonitoringStats,
	getMonitoringStatsForTargetGroup,
} from '../../../../server/server';
import '../../../../styles/dashboard/MonitoringWidget.css';
import DonutChartDash from '../../components/DonutChartDash';
import MonitoringCreateGroupModal from '../../../modals/MonitoringCreateGroupModal';
import NewSecretKeyModal from '../../../modals/NewSecretKeyModal';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import MonitoringGroupModal from '../../../modals/MonitoringGroupModal';
import { useTranslation } from 'react-i18next';
import { notify } from '../../../../utils/util';
import TimePeriodDropDown from './TimePeriodDropDown';

function Monitoring() {
	let history = useHistory();
	const { t, locale } = useTranslation();
	const [selectedTargetGroup, setSelectedTargetGroup] = useState();
	const [showDropdown, setShowDropdown] = useState(false);
	const [loading, setLoading] = useState(true);

	const [trend, setTrend] = useState(0);
	const [createGroupModal, setCreateGroupModal] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [monitoringGroupModalOpened, setMonitoringGroupModalOpened] = useState(false);
	const [emotionsData, setEmotionsData] = useState([]);
	const [targetGroups, setTargetGroups] = useState([]);
	const [timePeriod, setTimePeriod] = useState(filterPeriods[0]);
	const [selectedTargetGroupToEdit, setSelectedTargetGroupToEdit] = useState({});

	const [percentage, setPercentage] = useState({ total: 0, negativeEmotions: 0 });

	useEffect(() => {
		getDefaultData();
		loadGroups();
	}, []);

	const loadGroups = async () => {
		const resp = await getMonitoringGroups();
		if (resp.status === 200) {
			setTargetGroups(resp.data);
			// setSelectedTargetGroup(resp.data[0]);
			// setLoading(false);
		}
	};

	const getDefaultData = async () => {
		const resp = await getDefaultMonitoringStats();
		if (resp.status === 200) {
			const data = resp.data;
			setSelectedTargetGroup(data.monitoringGroup);
			setEmotionsData(data.emotionsData);
			setPercentage({ total: data.percentage });
			setLoading(false);
			setTrend(data.trend);
		}
	};
	const getStatisticsForSelectedGroup = async (targetGroup, period) => {
		const resp = await getMonitoringStatsForTargetGroup(targetGroup.id, period.code, true);
		if (resp.status === 200) {
			const data = resp.data;
			setPercentage({ total: data.percentage });
			setLoading(false);
			setTrend(data.trend);
		}
	};

	const closeCreateGroupModal = () => {
		setSelectedTargetGroupToEdit({});
		setCreateGroupModal(false);
	};
	const closeDelModal = () => {
		setSelectedTargetGroupToEdit({});
		setDeleteModalOpen(false);
	};

	const selectTargetGroup = (item) => {
		setSelectedTargetGroup(item);
		setShowDropdown(false);
		getStatisticsForSelectedGroup(item, timePeriod);
	};

	const openEditTargetGroup = (item) => {
		setSelectedTargetGroupToEdit(item);
		setShowDropdown(false);
		setCreateGroupModal(true);
	};

	const updateAndSelectTarget = (item) => {
		loadGroups();
		selectTargetGroup(item);
		setSelectedTargetGroupToEdit({});
	};

	const deleteTargetGroup = async () => {
		const resp = await deleteMonitoringGroup(selectedTargetGroupToEdit.id);
		if (resp.status === 200) {
			if (selectedTargetGroupToEdit.id === selectedTargetGroup.id) {
				selectTargetGroup(targetGroups[0]);
			}
			setTargetGroups(targetGroups.filter((e) => e.id !== selectedTargetGroupToEdit.id));
			setSelectedTargetGroupToEdit({});
			notify(t('youDeletedTargetGroup'));
			closeDelModal();
		}
	};

	const pressDeleteTargetGroup = (item) => {
		setDeleteModalOpen(true);
		setSelectedTargetGroupToEdit(item);
		setShowDropdown(false);
	};

	const openMonitoringGroupModal = () => {
		setMonitoringGroupModalOpened(true);
	};
	const closeMonitoringGroupModal = () => {
		setMonitoringGroupModalOpened(false);
	};

	const chooseTimePeriod = (period) => {
		setTimePeriod(period);
		getStatisticsForSelectedGroup(selectedTargetGroup, period);
	};

	return (
		<div className='bg-white rounded-[10px] w-full h-full p-[35px] action-widget-card relative'>
			<div>
				<h1 className='monitoring-widget-title'>{t('deeperInsights')} </h1>
				<TimePeriodDropDown timePeriod={timePeriod} setTimePeriod={chooseTimePeriod} />
				<p className='monitoring-widget-subtitle whitespace-pre-line'>
					{t('effortlesslyMonitorYourStudentsEmotionalExperiencesByCreatingYourOwnCustomisedGroupsHere')}
				</p>
			</div>
			<div className='flex items-center justify-between mt-[25px]'>
				<div
					className='flex flex-col bg-lightBG min-w-[180px] rounded-3xl relative mr-[5px] w-full max-w-[220px]'
					onMouseLeave={() => {
						setShowDropdown(false);
					}}
				>
					<div
						className={`px-[15px] flex flex-row items-center justify-between w-full h-[40px] border transition-all duration-[230ms] ease-in-out overflow-hidden ${
							showDropdown ? 'bg-darkenPurple border-darkenPurple' : 'bg-white border-lightBG'
						}  rounded-full   gap-[6px] cursor-pointer z-[6]`}
						onClick={(e) => {
							e.preventDefault();
							setShowDropdown(!showDropdown);
						}}
					>
						<div className='flex flex-row items-center gap-[6px] flex-1'>
							<p
								className={`text-[16px]  font-bold leading-[20px] text-ellipsis ${
									showDropdown ? 'text-white' : 'text-darkenPurple'
								} truncate `}
							>
								{selectedTargetGroup?.name ?? t('Sample Group')}
								{/* {selectedTargetGroup?.name ?? t('Sample Group')} */}
							</p>
							{!!selectedTargetGroup?.sampleGroup && (
								<div
									data-tip={t(`Monitor student unease with this pre-set Sample Group`)}
									data-for={`sampleData`}
									className='cursor-help'
								>
									{!loading && (
										<ReactTooltip
											id={`sampleData`}
											arrowColor='#5E639B'
											effect='solid'
											backgroundColor='#5E639B'
											className='wellbeing-tooltip'
										/>
									)}
									<Info style={{ color: showDropdown ? '#ffffff' : '#5E639B' }} />
								</div>
							)}
						</div>
						<div style={{ transform: showDropdown ? 'scaleY(-1)' : 'none' }}>
							<DropdownTransparent style={{ color: showDropdown ? '#ffffff' : '#5E639B' }} />
						</div>
					</div>
					{showDropdown && (
						<div className='absolute top-0 pt-[50px] left-0 px-[15px] pb-[15px] w-full bg-lightBG rounded-3xl flex flex-col cursor-pointer z-[5] '>
							{targetGroups?.map((item, index) => (
								<div className='h-10 relative' key={index}>
									<div
										className='absolute h-10 left-0 bg-lightBG pr-[11px]  flex flex-row items-center gap-3  whitespace-nowrap rounded-r-3xl monitoring-item'
										onClick={(e) => {
											selectTargetGroup(item);
										}}
									>
										<p
											className={`text-[16px] max-w-[280px] min-w-[130px]  ${
												selectedTargetGroup?.id === item.id
													? 'text-darkenPurple'
													: 'text-deselectPurple hover:text-mudPurple'
											}   font-bold leading-[20px]  text-left truncate `}
										>
											{item.name}
										</p>
										{!item.default && (
											<>
												<EditMonitoring
													className='monitoring-icon'
													onClick={(e) => {
														e.stopPropagation();
														openEditTargetGroup(item);
													}}
												/>
												<DeleteMonitoring
													className='monitoring-icon'
													onClick={(e) => {
														e.stopPropagation();
														pressDeleteTargetGroup(item);
													}}
												/>
											</>
										)}
									</div>
								</div>
							))}
							<div
								onClick={() => {
									setCreateGroupModal(true);
								}}
								className='w-full flex flex-row items-center justify-center gap-2 bg-white h-[40px] mt-[15px] rounded-[47px] cursor-pointer'
							>
								<Plus width={11} height={11} style={{ color: '#413F5E', transform: 'rotate(45deg)' }} />
								<p className='text-[14px] font-semibold leading-[20px] text-darkenPurple'>{t('createGroup')} </p>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className='flex flex-row items-center  mt-8 '>
				<div className='flex flex-row bg-containerGray rounded-full  justify-between items-center flex-1'>
					<div className='flex items-center ml-[10px] flex-wrap gap-[5px] flex-1'>
						{selectedTargetGroup?.emotions?.map((emotion, index) => {
							return (
								<div key={index} data-for={`m-${emotion}-${index}`}>
									<img src={emotions_heads[emotion]} alt='emotion' className='w-[20px] h-[20px]  my-[1px]' />
								</div>
							);
						})}
					</div>
					<div
						className='rounded-full cursor-help'
						data-tip={t(`Percentage of students who have experienced these emotions in selected period`)}
						data-for={`chart`}
					>
						<ReactTooltip
							id={`chart`}
							arrowColor='#5E639B'
							effect='solid'
							backgroundColor='#5E639B'
							className='wellbeing-tooltip'
						/>
						<DonutChartDash
							percentage={Math.min(percentage.total, 1)}
							outerRadius={'#FDDF6D'}
							text={'#413F5E'}
							innerRadius={'#BEBECE33'}
							betweenRadius={'#F8F8FB'}
							loading={loading}
							loadingColor={'#5e639b'}
							loadingSecondColor={'#ECEBF8'}
							size={60}
						/>
					</div>

					<div
						className='mr-[6px] flex flex-col items-center cursor-help'
						data-tip={t(`Relative change to the same selected previous period`)}
						data-for={`chartTrend`}
					>
						<ReactTooltip
							id={`chartTrend`}
							arrowColor='#5E639B'
							effect='solid'
							backgroundColor='#5E639B'
							className='wellbeing-tooltip'
						/>
						{trend > 0 ? (
							<UpGreenIcon style={{ width: 27, height: 27 }} />
						) : (
							<DownGrayIcon style={{ width: 27, height: 27, opacity: trend == 0 ? 0 : 1 }} />
						)}
						<p className='text-[12px] font-semibold text-deepPurple w-[30px] text-center'>{trend}%</p>
					</div>
				</div>
			</div>
			<MonitoringCreateGroupModal
				modalIsOpen={createGroupModal}
				closeModal={closeCreateGroupModal}
				targetGroup={selectedTargetGroupToEdit}
				selectTargetGroup={updateAndSelectTarget}
			/>
			<ConfirmationModal
				modalIsOpen={deleteModalOpen}
				closeModal={closeDelModal}
				confirmation={deleteTargetGroup}
				message={t('doYouWantToDeleteThis')}
			/>
			<MonitoringGroupModal
				modalIsOpen={monitoringGroupModalOpened}
				closeModal={closeMonitoringGroupModal}
				selectedGroup={selectedTargetGroup}
				groups={targetGroups}
			/>
		</div>
	);
}

export default Monitoring;
