import React, { useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import { fileSystemUrl } from '../../constants/Connections';
import '../../styles/dashboard/JournalTask.css';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as JournalBook } from '../../assets/svg/JournalBook.svg';
import { ReactComponent as LockIcon } from '../../assets/svg/LockIcon.svg';
import { useUser } from '../../auth';
import { makeArray, notify } from '../../utils/util';

import { getJournalForUser, updateJournalDailyQuestion } from '../../server/server';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';

function JournalTask({ modalIsOpen, closeModal, task, openImageBig = () => {} }) {
	const { t, locale } = useTranslation();
	const { user } = useUser();
	const [journal, setJournal] = useState({});
	const [selectedDay, setSelectedDay] = useState(-1);
	const [bookmark, setBookmark] = useState(false);
	const [loading, setLoading] = useState(true);
	const [sendingRequest, setSendingRequest] = useState(false);
	const [currentDay, setCurrentDay] = useState({});
	const [hasImage, setHasImage] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const [mediaUrl, setMediaUrl] = useState('');
	const [openDayIndex, setOpenDayIndex] = useState(-1);

	const [answers, setAnswers] = useState([]);
	const numbsers = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th'];

	useEffect(() => {
		if (!!task.id) loadJournal();
	}, [task]);

	useEffect(() => {
		scrollInto();
	}, [journal]);

	const loadJournal = async () => {
		const resp = await getJournalForUser(task.id);
		if (resp.status == 200) {
			const data = resp.data;
			const selectedIndex = data?.days.findIndex((e) => e.status == 'opened');
			const doneIndex = data?.days?.filter((e) => e.status == 'done');
			if (selectedIndex != -1) openDay(data?.days[selectedIndex], selectedIndex);
			else {
				openDay(data?.days[doneIndex.length - 1], doneIndex.length - 1);
			}
			console.log('journal ', data, selectedIndex);
			setJournal(data);
			setLoading(false);
			setBookmark(!!data?.bookmarked);
		}
	};

	const updateAnswers = async () => {
		setSendingRequest(true);
		const data = {
			journal_id: journal.id,
			day_number: currentDay.orderNumber,
			answers,
		};
		const resp = await updateJournalDailyQuestion(data);
		if (resp.status == 200) {
			setCurrentDay({ ...currentDay, answers: answers });
			let newDays = journal?.days?.map((day, index) => {
				if (index === currentDay?.orderNumber) day.answers = answers;
				return day;
			});
			setJournal({ ...journal, days: newDays });
			notify(t('yourAnswersAreSaved'));
		}
		setSendingRequest(false);
	};

	const changeAnswer = (text, index) => {
		let updatedAnswers = [...answers];
		updatedAnswers[index] = text;
		setAnswers(updatedAnswers);
	};

	const openDay = (day, index) => {
		setCurrentDay({ ...day, orderNumber: index });
		setOpenDayIndex(index);
		console.log('current day ', { ...day, orderNumber: index });
		setAnswers(!!day?.answers ? day.answers : makeArray(day?.numberOfAnswers ?? 3, ''));
		setHasImage(!!day?.files);
		setIsVideo(day?.files?.type === 'video');
		setMediaUrl(!!day?.files ? `${isVideo ? '' : fileSystemUrl}/${day?.files.storage_name}?token=${user.token}` : '');
	};

	const scrollInto = () => {
		let selTaskIndex = journal?.days?.findIndex((e) => e?.status === 'lock');
		let element = document.getElementById(`taskDay${selTaskIndex}`);
		if (element) {
			console.log('nisam null');
		}
		element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
	};

	useEffect(() => {
		ReactModal.setAppElement('#root');
	}, []);
	useEffect(() => {
		let element = document.getElementById('feedid');
		if (modalIsOpen) {
			if (element != null) {
				element.style.filter = 'blur(5px)';
			}
		}
		return () => {
			if (element != null) {
				element.style.filter = 'unset';
			}
		};
	}, [modalIsOpen]);
	return (
		<ReactModal
			transparent
			ariaHideApp={false}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				setCurrentDay({});
			}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 21,
				},
				content: {
					inset: 0,
					padding: 0,
					margin: 0,
					marginTop: 30,
					opacity: 1,
					width: 1000,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className='journal-modal-icon' onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
			</div>
			<div className='journal-modal-container grey-small-scroll'>
				<div className='journal-modal-header'>
					<div className='journal-modal-header-top'>
						<p className='journal-top-name'>{journal?.name}</p>
						<div style={{ flex: 1 }}></div>
						<JournalBook />
						<p className='journal-top-directive' style={{ marginLeft: 5 }}>
							{t('open_journal')}
						</p>
					</div>
					<div className='journal-modal-header-down'>{journal?.description}</div>
				</div>
				<div className='journal-days-scroll'>
					<div className='journal-days-list blue-small-scroll'>
						{journal?.days?.map((day, index) => {
							if (day?.status === 'done')
								return (
									<div
										className='journal-days-item noselect'
										style={{ cursor: 'pointer', backgroundColor: openDayIndex === index ? '#6FCC87' : '#ecebf8' }}
										id={`taskDay${index}`}
										key={index}
										onClick={() => openDay(day, index)}
									>
										<p style={{ color: openDayIndex === index ? 'white' : '#5e639b' }}>{t('day')}</p>
										<p style={{ color: openDayIndex === index ? 'white' : '#5e639b' }}>{index + 1}</p>
									</div>
								);
							if (day?.status === 'opened')
								return (
									<div
										className='journal-days-item noselect'
										style={{ cursor: 'pointer', backgroundColor: openDayIndex === index ? '#6FCC87' : '#ecebf8' }}
										id={`taskDay${index}`}
										key={index}
										onClick={() => openDay(day, index)}
									>
										<p style={{ color: openDayIndex === index ? 'white' : '#5e639b' }}>{t('day')}</p>
										<p style={{ color: openDayIndex === index ? 'white' : '#5e639b' }}>{index + 1}</p>
									</div>
								);
							if (day?.status === 'lock')
								return (
									<div className='journal-days-item noselect' id={`taskDay${index}`} key={index}>
										<LockIcon style={{ height: 20 }} />
									</div>
								);
						})}
					</div>
				</div>
				{!!currentDay.title && (
					<div className='journal-current-day'>
						{hasImage &&
							(isVideo ? (
								<div className='feed-video'>
									<video controls controlsList='nodownload'>
										<source src={mediaUrl} />
										{t('yourBrowserDoesNotSupportHTMLVideo')}
									</video>
								</div>
							) : (
								<div
									className='journal-image'
									//style={{ marginRight: i18n.language == "ar" ? 0 : 20, marginLeft: i18n.language == "ar" ? "20" : 0 }}
									onClick={() => openImageBig(mediaUrl)}
								>
									<img src={`${mediaUrl}&width=450`} alt={currentDay?.title} className='skeleton' />
								</div>
							))}

						<div className='journal-day-data'>
							<p className='journal-day-data-title'>{currentDay?.title}</p>
							<p className='journal-day-data-description'>{currentDay?.description}</p>
							<div className='journal-day-form'>
								<p className='journal-day-data-title'>{t('question')}:</p>
								<p className='journal-day-form-desc'>{currentDay.question}</p>
								<div style={{ marginTop: 25 }}>
									{answers.map((answer, i) => (
										<div className='journal-day-answer' key={i}>
											<p className='journal-answer-number'>{numbsers[i]}</p>
											<div className='journal-answer-input'>
												<input
													type='text'
													placeholder={t('writeFullAnswerHere')}
													value={answer}
													onChange={(e) => changeAnswer(e.target.value, i)}
													autoComplete='off'
												/>
											</div>
										</div>
									))}
								</div>
								<button className='journal-takeit-button' disabled={sendingRequest} onClick={() => updateAnswers()}>
									{sendingRequest ? (
										<Loading primaryColor='#FDDF6D' secondaryColor='#FDDF6D' size={25} stroke={7} />
									) : (
										<p className='jounal-takeit-text'>{currentDay.status === 'opened' ? t('takeIt') : t('save')}</p>
									)}
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</ReactModal>
	);
}

export default JournalTask;
