export const TeacherReportSteps = [
	{
		title: 'PDF Reports',
		subtitle: 'Craft detailed reports with just a few clicks! ',
		description: 'Summarise key insights, and trends, and gain deeper insights into student wellbeing effortlessly.',
		image: require('../../assets/images/PDFReport.png'),
	},
	{
		title: 'Raw Data in CSV',
		subtitle: 'Stay empowered with data-driven insights!',
		description: 'Export raw data directly from the system, customising your reports with applied filters.',
		image: require('../../assets/images/CSVReport.png'),
	},
];
