import React from "react";

function DonutChartAureole({ percentage = 0 }) {
	return (
		<div style={{ width: 154, height: 154, marginTop: -24, marginBottom: -24 }}>
			<svg width="154px" height="154px" viewBox="0 0 154 154" className="donut" style={{ transform: "scaleX(-1)" }}>
				<defs>
					<linearGradient id="gradient-blue">
						<stop offset="10.87%" stopColor="#595776" />
						<stop offset="88.17%" stopColor="#83819F" />
					</linearGradient>
					<linearGradient id="gradient-orange">
						<stop offset="4%" stopColor="#F1A716" />
						<stop offset="91%" stopColor="#FFC369" />
					</linearGradient>
				</defs>

				<circle cx="50%" cy="50%" r="73" fill="white"></circle>
				<circle
					transform="rotate(-90,77,77)"
					cx="50%"
					cy="50%"
					r="40"
					fill="transparent"
					stroke="url(#gradient-blue)"
					strokeWidth="26"
					strokeDasharray={40 * 2 * Math.PI * percentage}
					strokeDashoffset="0"
				></circle>
				<circle
					transform="rotate(-90,77,77)"
					cx="50%"
					cy="50%"
					r="40"
					fill="transparent"
					stroke="url(#gradient-orange)"
					strokeWidth="26"
					strokeDasharray={`${40 * 2 * Math.PI * (1 - percentage)} ${40 * 2 * Math.PI * percentage}`}
					strokeDashoffset={-(40 * 2 * Math.PI) * percentage}
				></circle>
			</svg>
		</div>
	);
}

export default DonutChartAureole;
