import React, { useState, useEffect } from 'react';
import '../../styles/classroom/ClassroomHome.css';
import { ReactComponent as UpGreenIcon } from '../../assets/svg/UpGreenIcon.svg';
import { ReactComponent as DownGrayIcon } from '../../assets/svg/DownGrayIcon.svg';
import { getClassroomTrendReport } from '../../server/server';
import 'react-datepicker/dist/react-datepicker.css';
import { emotions_list, emotions_heads, filterPeriods } from '../../constants/Constants';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIcon.svg';
import { useTranslation } from 'react-i18next';
import DonutChartDash from '../dashboard/components/DonutChartDash';
import Loading from '../Loading';
import ReactTooltip from 'react-tooltip';
import ClassroomEngagementTrend from './ClassroomEngagementTrend';
import ClassroomEmotionSection from './ClassroomEmotionSection';

const ClassroomTrend = ({ ...props }) => {
	const { t, locale } = useTranslation();
	const [trendData, setTrendData] = useState({
		positive: { percentage: '+8', emotions: emotions_list.slice(0, 5), trend: '68' },
		negative: { percentage: '-2', emotions: emotions_list.slice(5), trend: '32' },
		neutral: { percentage: '+6', emotions: ['default'], trend: '4' },
		studentsResponse: 0,
	});
	const [selectedPeriod, setSelectedPeriod] = useState(7);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getData(7);
	}, []);

	const getData = async (period) => {
		const resp = await getClassroomTrendReport(period);
		if (resp.status === 200) {
			const data = resp.data;
			setTrendData({
				positive: {
					...trendData.positive,
					percentage: data?.comfortableEmotions?.percentage,
					trend: data?.comfortableEmotions?.trend,
				},
				negative: {
					...trendData.negative,
					percentage: data?.uncomfortableEmotions?.percentage,
					trend: data?.uncomfortableEmotions?.trend,
				},
			});
			setLoading(false);
		}
	};

	const changePeriod = (period) => {
		setLoading(true);
		setSelectedPeriod(period);
		getData(period);
	};

	return (
		<div className='classroom-dashwidget-container'>
			<div className='classroom-widget-left flex-2'>
				<div className='widget-left-top'>
					<p>{t('classroomTrends')}</p>
					<div className='period-buttons-container flex-1 justify-center'>
						<span></span>
						{filterPeriods?.map((period, index) => {
							return (
								<div
									key={period.code}
									className={`period-items ${selectedPeriod === period.code ? 'selected' : ''}`}
									onClick={() => changePeriod(period.code)}
								>
									{t(period.name)}
								</div>
							);
						})}
					</div>
					<p className='title opacity-0'>{t('classroomTrends')}</p>
				</div>
				<div className='widget-left-down'>
					<ClassroomEmotionSection
						title={t('Comfortable Emotions')}
						emotions={trendData?.positive?.emotions}
						percentage={trendData?.positive?.percentage}
						trend={trendData?.positive?.trend}
						loading={loading}
						emotionsType='comfortable'
					/>
					<ClassroomEmotionSection
						title={t('Uncomfortable Emotions')}
						emotions={trendData?.negative?.emotions}
						percentage={trendData?.negative?.percentage}
						trend={trendData?.negative?.trend}
						loading={loading}
						emotionsType='uncomfortable'
					/>
					<ClassroomEngagementTrend period={selectedPeriod} />
				</div>
			</div>
		</div>
	);
};

export default ClassroomTrend;
