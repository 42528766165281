import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../auth';
import i18n from '../../i18n';
import '../../styles/settings/settings.css';
import { notify } from '../../utils/util';
import ChangePassword from './ChangePassword';
import PrivateNotes from './PrivateNotes';
function Settings() {
	const { user, setUser } = useUser();
	const [secretKey, setSecretKey] = useState('');
	const [language, setStateLanguage] = useState(i18n.language);
	const { t, locale } = useTranslation();
	const handleSubmit = async (e) => {
		e.preventDefault();
		notify(t('Language is changed'));
		localStorage.setItem('locale', language);
		i18n.changeLanguage(language);
	};
	const setLanguage = (code) => {
		setStateLanguage(code);
		i18n.changeLanguage(code);
	};
	useEffect(() => {
		setSecretKey(localStorage.getItem('secret_key') || '');
	}, []);

	const handleSecret = async (e) => {
		e.preventDefault();
		if (secretKey === '') {
			notify(t('fieldIsRequired'));
			return;
		}
		notify(t('yourSecretKeyIsSaved'));
		localStorage.setItem('secret_key', secretKey);
	};

	return (
		<div className='bg-lightBG w-full h-full overflow-y-scroll'>
			<div className='py-14  flex gap-4 justify-center '>
				<div className='flex gap-4 flex-col'>
					<div className='col-span-1 bg-white p-6 rounded-xl'>
						<div className='personal-info-container'>
							<div className='notes-title text-3xl text-deepPurple font-bold text-left w-[600px]'>{t('selectLanguage')}</div>

							<div className='profile-form'>
								<form onSubmit={handleSubmit}>
									<fieldset>
										<label className='text-left flex justify-start mt-3 text-mudPurple text-base'>
											{t('selectLanguage')}
										</label>

										<div className='language-settings-lang-section'>
											<div
												className={`language-settings-lang ${
													language == 'en' ? 'language-settings-lang-selected' : ''
												}`}
												onClick={() => setLanguage('en')}
											>
												English
											</div>
											<div
												className={`language-settings-lang ${
													language == 'de' ? 'language-settings-lang-selected' : ''
												}`}
												onClick={() => setLanguage('de')}
											>
												German
											</div>
											<div
												className={`language-settings-lang ${
													language == 'es' ? 'language-settings-lang-selected' : ''
												}`}
												onClick={() => setLanguage('es')}
											>
												Spanish
											</div>
										</div>
									</fieldset>

									<button className='w-full uppercase font-bold mt-5 h-[55px] text-center justify-center items-center flex rounded-full bg-deepPurple text-white'>
										{t('save')}
									</button>
								</form>
							</div>
						</div>
					</div>
					<div className='rounded-xl bg-white grow w-[650px]'>
						<PrivateNotes />
					</div>
					<div className='flex items-center gap-3 h-[20px]'>
						<p className='text-mudPurple font-bold pointer'>{t('termsAndConditions')} </p>
						<p className='text-mudPurple font-bold pointer'>{t('privacyPolicy')}</p>
					</div>
				</div>
				<div className='flex gap-4 flex-col w-[570px]'>
					<div
						className='rounded-xl bg-white
                 p-8'
					>
						<div className='bg-lightBG text-mudPurple text-left p-2 rounded-xl font-bold text-[18px]'>{user.email}</div>
					</div>
					<div className='rounded-xl bg-white'>
						<div className='personal-info-container p-10 '>
							<div className='text-3xl text-deepPurple font-bold text-left'>{t('changeSecurityKey')}</div>

							<div className='profile-form'>
								<form onSubmit={handleSecret}>
									<fieldset>
										<label
											className='text-left flex justify-start mt-3 text-mudPurple text-base'
											style={{ marginBottom: '20px' }}
										>
											{t('secretKey')}
										</label>
										<label className='attention'>
											<p className='text-left w-[400px] mt-3 text-mudPurple text-lg font-bold'>
												<b className='text-deepPurple'>{t('Attention')}</b>:{' '}
												{t('toEnsureYourPrivacyThisKeyIsNotRecoverableAllYourNotesWillBeLostIfYouForgetThisKey')}
											</p>{' '}
										</label>
										<input
											className='w-full text-black border border-b-deselectPurple py-3 border-x-0 border-t-0'
											style={{ backgroundColor: 'transparent' }}
											type='password'
											name='user_secret_key'
											placeholder={t('enterSecretKey')}
											value={secretKey}
											onChange={(e) => setSecretKey(e.target.value)}
										/>
									</fieldset>

									<button className='w-full uppercase font-bold mt-5 h-[55px] text-center justify-center items-center flex rounded-full bg-deepPurple text-white'>
										{t('save')}
									</button>
								</form>
							</div>
						</div>
					</div>

					<div className='rounded-xl bg-white'>
						<ChangePassword />
					</div>
					<div className='h-[20px]'></div>
				</div>
			</div>
		</div>
	);
}

export default Settings;
