import React, { useRef, useState, useEffect } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/svg/SearchIcon.svg';
import { notify } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { format_day } from '../../utils/format_day';
import Loading from '../Loading';
import { deleteById, get_teachers } from '../../server/server';
import TeacherInfoModal from '../modals/TeacherInfoModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import { useAppContext } from '../../context/AppContext';
import i18n from '../../i18n';

function TeacherManagement({ newTeacherOpen, setNewTeacherOpen }) {
	const { t, locale } = useTranslation();
	const [searchTeacherText, setSearchTeacherText] = useState('');
	const [loading, setLoading] = useState(false);
	const [loadingMore, setLoadingMore] = useState(false);
	const timeout = useRef(null);
	const searchRef = useRef();
	const { appState } = useAppContext();
	const [teachers, setTeachers] = useState([]);
	const [listSize, setListSize] = useState(0);
	const [teacherInfoOpen, setTeacherInfoOpen] = useState(false);
	const [deleteTeacherConfirm, setDeleteTeacherConfirm] = useState(false);
	const [teacher, setTeacher] = useState({});

	useEffect(() => {
		loadTeachers(0, searchTeacherText);
	}, []);

	useEffect(() => {
		if (newTeacherOpen === true) openTeacherInfo();
	}, [newTeacherOpen]);

	const loadTeachers = async (number = teachers.length, searchText) => {
		if (number === 0) setLoading(true);
		else setLoadingMore(true);
		const institution_id = localStorage.getItem('institution_id');
		const data = {
			institution_id: institution_id,
			text: !!searchText ? searchText : '',
			loaded: number,
		};
		const resp = await get_teachers(data);
		if (resp.status === 200) {
			const resp_data = resp.data;
			const prev_list = number === 0 ? [] : teachers;
			setListSize(resp_data.size);
			setTeachers([...prev_list, ...resp_data['list']]);
		} else {
		}
		setLoading(false);
		setLoadingMore(false);
	};

	const closeTeacherInfo = () => {
		setTeacher({});
		setTeacherInfoOpen(false);
		setNewTeacherOpen(false);
	};
	const openTeacherInfo = () => {
		setTeacher({});
		setTeacherInfoOpen(true);
	};
	const openTeacher = (teacher) => {
		setTeacher(teacher);
		setTeacherInfoOpen(true);
	};

	const closeDelTeacherModal = () => {
		setDeleteTeacherConfirm(false);
	};
	const openDelTeacherModal = () => {
		setDeleteTeacherConfirm(true);
	};

	const handleChangeTeacherList = (e) => {
		setSearchTeacherText(e.target.value);
		if (timeout !== null) {
			clearTimeout(timeout.current);
		}
		timeout.current = setTimeout(() => {
			loadTeachers(0, e.target.value);
		}, 800);
	};

	const deleteTeacher = async () => {
		const resp = await deleteById({ collection: 'users', id: teacher.id });
		if (resp.status === 200) {
			const newTeachers = teachers.filter((item) => item.id != teacher.id);
			setListSize((prev) => prev - 1);
			setTeachers(newTeachers);
			closeDelTeacherModal();
			closeTeacherInfo();
			notify(t('Teacher is removed!'));
		} else {
			notify(t('Teacher is not removed!'));
		}
	};

	return (
		<div>
			<div className='management-frame'>
				<div className='management-lists-container'>
					<div className='management-lists-search'>
						<p>{t('listOfTeachers')} </p>
						<div
							className='stroke-deselectPurple hover:stroke-deepPurple flex flex-row items-center border-deselectPurple border py-[0.65rem] px-4 w-[18rem] rounded-full'
							style={{ cursor: 'pointer' }}
						>
							<input
								type='text'
								value={searchTeacherText}
								className='w-full h-full text-mudPurple font-semibold text-sm'
								style={{ outlineStyle: 'none' }}
								onChange={handleChangeTeacherList}
								ref={searchRef}
								placeholder={t('search')}
							/>
							<SearchIcon
								className=''
								onClick={() => {
									searchRef.current.focus();
								}}
							/>
						</div>
					</div>
					<div className='mt-3 '>
						<table style={{ width: '100%', borderCollapse: 'collapse' }}>
							<thead>
								<tr className='teachers-header-row'>
									<th className='teacher-text-center' style={{ width: '5%' }}>
										#
									</th>
									<th style={{ width: '23%' }}>{t('name')}</th>
									<th style={{ width: '27%' }}>{t('email')}</th>
									<th style={{ width: '17%' }}>{t('role')}</th>
									<th style={{ width: '15%', textAlign: 'center' }}>{t('lastLogin')}</th>
									<th className='teacher-text-center'>{t('action')}</th>
								</tr>
							</thead>
							<tbody>
								{!loading &&
									teachers.map((item, index) => (
										<tr
											className='teachers-body-row'
											key={item.id}
											onClick={(e) => {
												e.preventDefault();
												openTeacher(item);
											}}
										>
											<td className='teacher-text-center' style={{ width: '5%' }}>
												{index + 1}
											</td>
											<td style={{ width: '23%' }}>
												{item.first_name} {item.last_name}
											</td>
											<td className='teacher-email-text' style={{ width: '27%' }}>
												{item.email}{' '}
											</td>
											<td style={{ width: '17%' }}>{item.role_text} </td>
											<td style={{ width: '15%', textAlign: 'center' }}>
												{!!item?.lastLogin ? format_day(item?.lastLogin, false, i18n.language) : '-'}
											</td>
											<td className='teacher-text-center underline-text'>
												<u>{t('manage')}</u>
											</td>
										</tr>
									))}

								{teachers.length === 0 && !loading && (
									<tr className='teachers-body-row-empty'>
										<td colSpan='6' className='teacher-text-center no-teachers-yet'>
											{t('noTeachersYet')}
										</td>
									</tr>
								)}
								{(loading || loadingMore) && (
									<tr className='bg-white border-t border-[#e3e3e3] '>
										<td colSpan='6'>
											<div className='loading-center teacher-list-loader'>
												<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={75} stroke={4} />
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			{listSize > teachers.length && (
				<div style={{ marginTop: 20 }}>
					<div className='transparent-button' onClick={() => loadTeachers(teachers.length)} style={{ margin: 'auto' }}>
						{t('loadMore')}
					</div>
				</div>
			)}
			<TeacherInfoModal
				teacherInfoOpen={teacherInfoOpen}
				reloadTeachersList={() => loadTeachers(0, searchTeacherText)}
				closeTeacherInfo={closeTeacherInfo}
				teacher={teacher}
				deleteTeacher={openDelTeacherModal}
			/>
			<ConfirmationModal
				modalIsOpen={deleteTeacherConfirm}
				closeModal={closeDelTeacherModal}
				confirmation={deleteTeacher}
				message={t('doYouWantToDeleteThis')}
			/>
		</div>
	);
}

export default TeacherManagement;
