import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/ConfirmationFeedDeleteModal.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConfirmImage } from '../../assets/svg/ConfirmImage.svg';
import Loading from '../Loading';

function ConfirmationModal({ modalIsOpen, afterOpenModal, closeModal, confirmation, message, subMessage = '' }) {
	const { t, locale } = useTranslation();

	const [progress, setProgress] = useState(false);

	React.useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);

	const submitConfirm = () => {
		confirmation();
		// closeModal();
	};

	return (
		<ReactModal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			contentLabel='Example Modal'
			onAfterClose={() => {
				setProgress(false);
			}}
			style={{
				overlay: {
					zIndex: 12,
				},
				content: {
					width: 600,
					height: 450,
					padding: 0,
					opacity: 1,
					minWidth: 600,
					margin: 'auto',
					marginTop: '2%',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					margin: 'auto',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div className='bg-whiteContrast rounded-xl flex flex-col h-full items-center justify-between p-10'>
				<ConfirmImage />
				<p className='text-deepPurple text-xl font-bold text-center'>{message}</p>
				{!!subMessage && <p className='text-mudPurple text-sm mt-2 text-center'>{subMessage}</p>}

				<div className='flex flex-row justify-between items-center w-full mt-6'>
					<div
						className='bg-deepPurple pointer flex items-center justify-center text-highlightColor text-base font-semibold rounded-xl w-[250px] h-[55px] transition-all !duration-[250ms] ease-in-out hover:bg-highlightColor hover:text-deepPurple'
						onClick={() => closeModal()}
					>
						{t('cancel')}
					</div>
					<button
						className='border pointer flex items-center justify-center border-deleteRed text-deleteRed text-base font-semibold rounded-xl w-[250px] h-[55px] transition-all !duration-[250ms] ease-in-out hover:bg-deleteRed hover:text-white'
						disabled={progress}
						onClick={() => {
							setProgress(true);
							submitConfirm();
						}}
					>
						{progress ? <Loading /> : 'Ok'}
					</button>
				</div>
			</div>
		</ReactModal>
	);
}

export default ConfirmationModal;
