import { useState, useEffect } from 'react';

const element2 = document.getElementById('zamajac');
const useIntersection = (element, rootMargin) => {
	const [isVisible, setState] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setState(entry?.isIntersecting);
			},
			{ rootMargin }
		);

		element?.current && observer?.observe(element?.current);

		return () => observer?.unobserve(!!element?.current ? element?.current : element2);
		// return () => observer?.unobserve(element?.current);
	}, []);

	return isVisible;
};

export default useIntersection;
