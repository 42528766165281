import React, { useState, useEffect, useContext } from 'react';
import { useUser } from './auth';
import './styles/App.css';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import PublicRoute from './router/PublicRoute';
import PrivateRoute from './router/PrivateRoute';
import LoginScreen from './screens/LoginScreen';
import SideBar from './components/SideBar';
import NavBar from './components/NavBar';
import Content from './components/content/Content';
import ManagementHome from './components/management/ManagementHome';
import Poll from './components/content/Poll';
import Question from './components/content/Question';
import ContentFeed from './components/content/ContentFeed';
import ContentList from './components/content/ContentList';
import CreateSurvey from './components/content/CreateSurvey';
import Chat from './components/chat/Chat';
// import ContentTemplates from './components/content/ContentTemplates';
// import data from './assets/files/apple-app-side-association';
import { ContentContextProvider } from './components/content/ContentContext';
import ContentProgram from './components/content/ContentProgram';
import Dashboard from './components/dashboard/Dashboard';
import InstructionVideos from './components/videos/InstructionVideos';
import ClassroomHome from './components/classroom/ClassroomHome';
import CounselingHome from './components/counseling/CounselingHome';
import StudentWellbeingProfile from './components/counseling/StudentWellbeingProfile';
import ReadyToGo from './components/content/ReadyToGo';
import { Toaster } from 'react-hot-toast';
import AnalyticsHome from './components/analytics/AnalyticsHome';
import Settings from './components/settings/Settings';
import * as Sentry from '@sentry/react';
import { getAdditionalFields, getByToken, logUserTimeAsync } from './server/server';
import Loading from './components/Loading';
import Fulllogo from './assets/svg/US-full.svg';
import { useSocket } from './context/socket';
import {
	checkIfTeacherHasPrimaryClasses,
	checkIfTeacherHasRegularClasses,
	checkIfUserNeedsTutorial,
	loadAdditionalFields,
	loadAssignedFeedbacks,
	loadUndoneSurveys,
	loadUndoneTasks,
	loadUnseenConversations,
	logLoginTime,
} from './server/usersData';
import CatchUpHome from './components/catchUp/CatchUpHome';
import TutorialModal from './components/modals/TutorialModal';
import UploadUserDataScreen from './components/management/UploadUserDataScreen';
import usePageVisibility from './utils/usePageVisibility';
import { useAppContext } from './context/AppContext';
import AnalyticsBasicStudents from './components/analytics/AnalyticsBasicStudents';
import AnalyzeContent from './components/analytics/AnalyticsContent';
import ManageCustomReports from './components/analytics/CustomReports/ManageCustomReports';
import AASA from './components/AASA';

function App() {
	let history = useHistory();
	const { user, setUser, isSignedIn } = useUser();
	const { socketStates, changeSocketState } = useSocket();
	const { appState, setAppState } = useAppContext();
	const [authLoading, setAuthLoading] = useState(true);
	const [showTutorial, setTutorial] = useState(false);
	const pageIsNotVisible = usePageVisibility();
	const [activeTime, setActiveTime] = useState(Date.now());
	const [sidebarAnimation, setSidebarAnimation] = useState(false);
	const searchParams = new URLSearchParams(window.location.search);
	const authToken = searchParams.get('token') ?? null;

	const logout = () => {
		setUser({});
		localStorage.clear();
		setTimeout(() => {
			history?.push('/');
		}, 200);
	};

	const checkUserAuth = async () => {
		if (authToken) {
			localStorage.setItem('token', authToken);
			searchParams.delete('token');
			const newUrl = `${window.location.pathname}`;
			window.history.replaceState(null, '', newUrl);
		}
		const resp = await getByToken();
		if (resp?.status === 200) {
			const data = resp.data;
			const user = data;
			if (user.role == 0) {
				logout();
				setAuthLoading(false);
			}
			localStorage.setItem('token', data['token']);
			setUser(user);
			// setAuthLoading(false);
		} else if (resp?.status === 406) {
			logout();
			setAuthLoading(false);
		} else {
			logout();
			setAuthLoading(false);
		}
	};

	useEffect(() => {
		if (isSignedIn) {
			initUserData();
			setTimeout(() => setSidebarAnimation(true), 200);
		} else setSidebarAnimation(false);
	}, [isSignedIn]);

	const initUserData = async () => {
		const unseenConversations = await loadUnseenConversations();
		const assignedFeedbacks = await loadAssignedFeedbacks();
		const showTutorial = await checkIfUserNeedsTutorial();
		if (showTutorial) setTutorial(true);
		const hasPrimaryClasses = await checkIfTeacherHasPrimaryClasses();
		const additionalFields = await loadAdditionalFields();
		setAppState({ additionalFields });
		changeSocketState({ ...socketStates, unseenConversations, assignedFeedbacks });
		setUser({ ...user, hasPrimaryClasses, hasRegularClasses: true, hasOnlyPrimaryStudents: false });
		if (hasPrimaryClasses) {
			console.log('Ovde sam.... i ima sa1mo primary student...');
			const hasRegularClasses = await checkIfTeacherHasRegularClasses();
			setUser({ ...user, hasPrimaryClasses, hasRegularClasses, hasOnlyPrimaryStudents: !hasRegularClasses });
		}

		setAuthLoading(false);
	};

	useEffect(() => {
		if (user) {
			checkUserAuth();
		}
		if (window.location.pathname === '/login') {
			setAuthLoading(false);
		}
	}, []);

	useEffect(() => {
		if (isSignedIn) {
			if (!pageIsNotVisible) setActiveTime(Date.now());
			else logUserTime();
		}
	}, [pageIsNotVisible, isSignedIn]);

	const logUserTime = () => {
		const data = {
			start_time: activeTime,
			end_time: Date.now(),
			device: 'desktop',
		};
		logUserTimeAsync(data);
		setActiveTime(Date.now());
		uploadLoginLogs();
	};
	const uploadLoginLogs = async () => {
		let restLogins = JSON.parse(localStorage.getItem('loginsLogs') || '[]');
		while (restLogins.length > 0) {
			const data = restLogins.pop();
			const status = await logLoginTime(data, false);
			if (!status) {
				return;
			}
		}
		localStorage.setItem('loginsLogs', '[]');
	};

	useEffect(() => {
		uploadLoginLogs();
		if (isSignedIn) window.addEventListener('beforeunload', logUserTime);
		return () => {
			if (isSignedIn) window.removeEventListener('beforeunload', logUserTime);
		};
	}, [isSignedIn]);

	return (
		<ContentContextProvider>
			<div id='home' className='app'>
				<Router>
					{isSignedIn && !authLoading && <SideBar />}
					{authLoading && (
						<div className='flex justify-center items-center w-full h-screen bg-deepPurple flex-col absolute z-50'>
							<img src={Fulllogo} alt='logo' className='mb-8' />
							<Loading primaryColor='#FFF' secondaryColor='#FFF' size={75} stroke={4} />
						</div>
					)}
					{!authLoading && (
						<div
							className={`home-container ${sidebarAnimation ? 'logged-in' : ''}`}
							onMouseEnter={(e) => {
								e.preventDefault();
							}}
							id='home-container'
						>
							{/* {isSignedIn && <NavBar openSideBar={openSideBar} />} */}
							<Switch>
								<Route exact path='/'>
									<Redirect to='/login' />
								</Route>
								<PublicRoute path='/login' component={LoginScreen} />
								<PrivateRoute path='/classroom/:pageID(\\?emotion=.*)?' component={ClassroomHome} />
								<PrivateRoute path='/analytics/custom-report' component={ManageCustomReports} />
								<PrivateRoute path='/analytics/data' component={AnalyzeContent} />
								<PrivateRoute path='/analytics' component={AnalyticsHome} />
								<PrivateRoute path='/chat' component={Chat} />
								<PrivateRoute path='/management/upload_user_data' component={UploadUserDataScreen} />
								<PrivateRoute path='/management' component={ManagementHome} />
								<PrivateRoute path='/catch-up' component={CatchUpHome} />
								<PrivateRoute path='/counseling/student_profile/:pageID(\\?id=.*)?' component={StudentWellbeingProfile} />
								<PrivateRoute path='/counseling' component={CounselingHome} />
								<PrivateRoute path='/settings' component={Settings} />
								<PrivateRoute path='/videos' component={InstructionVideos} />
								<PrivateRoute path='/dashboard' component={Dashboard} />
								<PrivateRoute path='/content/poll' component={Poll} />
								<PrivateRoute path='/content/question' component={Question} />
								<PrivateRoute path='/content/feed' component={ContentFeed} />
								<PrivateRoute path='/content/list' component={ContentList} />
								<PrivateRoute path='/content/surveys/new_survey' component={CreateSurvey} />
								<PrivateRoute exact path='/content/surveys/:pageID(\\?id=.*)?' component={CreateSurvey} />
								<PrivateRoute path='/content/program/:pageID(\\?id=.*)?' component={ContentProgram} />
								<PrivateRoute exact path='/content' component={Content} />
								<PrivateRoute exact path='/readytogo' component={ReadyToGo} />

								<Route path='*'>{isSignedIn ? <Redirect to='/dashboard' /> : <Redirect to='/' />}</Route>
							</Switch>
						</div>
					)}
				</Router>
				{isSignedIn && (
					<TutorialModal
						modalIsOpen={showTutorial}
						// modalIsOpen={true}
						closeModal={() => {
							setTutorial(false);
						}}
					/>
				)}
				<Toaster position='top-right' reverseOrder={false} toastOptions={{ duration: 3000 }} />
			</div>
		</ContentContextProvider>
	);
}

export default Sentry.withProfiler(App);
// export default App;
