import React, { useState } from 'react';
import { ReactComponent as PollIcon } from '../../../../assets/svg/PollIcon.svg';
import { ReactComponent as QuestionIcon } from '../../../../assets/svg/QuestionIcon.svg';
import { ReactComponent as NotFoundIcon } from '../../../../assets/svg/NotFoundIcon.svg';
import { ReactComponent as SurveyIcon } from '../../../../assets/svg/SurveyIcon.svg';
import { ReactComponent as JournalIcon } from '../../../../assets/svg/JournalIcon.svg';

import { ReactComponent as EditContent } from '../../../../assets/svg/EditContent.svg';
import { ReactComponent as DeleteContent } from '../../../../assets/svg/DeleteContent.svg';
import { ReactComponent as VideoSettings } from '../../../../assets/svg/video_settings.svg';
import ProgressBar from '../../../ProgressBar';
import { fileSystemUrl, videoUrl } from '../../../../constants/Connections';
import { useUser } from '../../../../auth';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { BarColors } from '../../../../constants/BarColors';
import BarCharts from '../../BarCharts';
import { format } from 'date-fns';
import { contentBackgrounds } from '../../../../constants/Constants';
import '../../../../styles/content/content.css';
import { useTranslation } from 'react-i18next';
import { translateTargetText } from '../../../../utils/util';
import i18n from '../../../../i18n';
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder';

function SingleContent({ content, openResult, sameDay }) {
	const { t, locale } = useTranslation();
	const { user, setUser } = useUser();
	const [loadingImage, setLoadingImage] = useState(true);
	const [additionalText, setAdditionalText] = useState(false);

	const hasImage = !!content.files;
	const isVideo = content?.files?.type === 'video';
	const mediaUrl = hasImage ? `${isVideo ? videoUrl : fileSystemUrl}/${content.files.storage_name}?token=${user.token}` : '';

	return (
		<div onClick={() => openResult(content)} onMouseEnter={() => setAdditionalText(true)} onMouseLeave={() => setAdditionalText(false)}>
			{(content?.type === 'poll') | (content?.type === 'question') ? (
				<div
					className={` w-full p-5 rounded-xl ${
						sameDay ? 'mt-[10px]' : 'mt-[15px]'
					} hover:border-darkenPurple border flex flex-col gap-[20px] border-containerGray pointer ${
						contentBackgrounds[content?.type]
					}`}
				>
					<div className='w-full flex flex-row gap-[15px]'>
						{/* {content?.files && <div>{showImage(content?.files, 125)}</div>} */}
						{hasImage &&
							(isVideo ? (
								<div className='simage-container-new min-w-[200px] max-w-[200px]'>
									<video controlsList='nodownload' className='pointer-events-none'>
										<source src={`${mediaUrl}#t=0.1`} />
										{t('yourBrowserDoesNotSupportHTMLVideo')}
									</video>
								</div>
							) : (
								<div className=' min-w-[200px] max-w-[200px]'>
									<ImageWithPlaceholder url={mediaUrl} altText={content?.title} width={200} />
								</div>
							))}
						<div className='flex-1 h-full min-h-[70px] flex flex-col justify-center '>
							<div className='header-single-content flex justify-between w-full'>
								<div>
									{content?.type === 'poll' ? <PollIcon /> : <QuestionIcon />}{' '}
									<span className='text-[#413F5E] font-medium text-[16px] mr-[15px]'>
										{!!content?.anonymous ? t('anonymous') : t('Open')} {t(content?.type)}
									</span>
								</div>
								<div className='flex flex-row items-center'>
									<span className='text-darkenPurple font-medium text-[16px] leading-5'>
										<span className='text-[#413F5E99]'>{t('target')}:</span> {translateTargetText(content?.target_text)}
									</span>
								</div>
								<div className='pointer flex flex-row items-center font-medium'>
									<p
										className={`text-darkenPurple font-medium text-xs mr-2 max-w-[130px] ${
											additionalText ? 'visible' : 'invisible'
										}`}
									>
										{t('clickToBrowseResults')}
									</p>
									<VideoSettings fill={additionalText ? '#9392AD' : '#BEBECE'} />
								</div>
							</div>
							<div className=' flex flex-row items-center'>
								<div className='w-full'>
									<div className='single-content-body items-end mt-2'>
										<h2 className='text-darkPurple font-bold text-base text-left max-w-xl'>{content?.title}</h2>
										{!!content?.message && (
											<p className='text-[#9392AD] text-[14px] font-medium mt-[5px]'>{content?.message}</p>
										)}
									</div>
									<div>
										{content?.type === 'poll' && (
											<div className='mt-6'>
												<BarCharts
													options={content?.options}
													answers={content?.answers}
													showNum={true}
													showHover={true}
												/>
											</div>
										)}
									</div>
									<div className=' flex items-end justify-between'>
										<ProgressBar
											bgcolor={'#5E639B'}
											backgroundColor={'white'}
											completed={Math.round((content?.answeredUsers?.length / content?.target_count) * 100)}
										/>
										<p className='text-right text-sm text-darkenPurple font-medium ml-2 h-[16px]'>
											{content?.answeredUsers?.length}/{content?.target_count}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='w-full flex flex-row items-center justify-between'>
						<p className='text-sm text-darkenPurple font-medium'>
							<span className='text-[#413F5E99]'>{t('by')}:</span> {content?.created_by?.first_name}{' '}
							{content?.created_by?.last_name}
						</p>
						<p className='text-sm text-darkenPurple font-medium'>
							<span className='text-[#413F5E99]'>{t('time')}: </span>
							{format(new Date(content.publicationDate), 'HH:mm')}
						</p>
					</div>
				</div>
			) : (
				<div style={{ backgroundColor: 'white' }}>
					<div
						className={` pointer hover:border-darkenPurple border flex items-center ${
							sameDay ? 'mt-[10px]' : 'mt-[15px]'
						} border-containerGray w-full rounded-xl ${contentBackgrounds[content?.type]} `}
						onClick={() => openResult(content)}
					>
						<div className='p-4 w-full  flex flex-col justify-center'>
							<div className='header-single-content flex justify-between w-full'>
								<div>
									{content?.type === 'survey' ? <SurveyIcon /> : <JournalIcon />}{' '}
									<span className='text-darkenPurple font-medium text-sm mr-[50px]'>
										{!!content?.anonymous ? t('anonymous') : t('Open')} {t(content?.type)}
									</span>
								</div>
								<div>
									<span className='text-darkenPurple font-medium text-sm  '>
										<span className='text-[#413F5E99]'>{t('target')}:</span>{' '}
										{!!content?.anonymous ? t('anonymous') : translateTargetText(content?.target_text)}
									</span>
								</div>
								<div className='pointer flex flex-row items-center '>
									<p className={`text-darkenPurple font-medium text-xs mr-2 ${additionalText ? 'visible' : 'invisible'}`}>
										{t('clickToBrowseResults')}
									</p>
									<VideoSettings fill={additionalText ? '#9392AD' : '#BEBECE'} />
								</div>
							</div>
							<div className=' flex flex-row items-center'>
								<div className='w-full'>
									<div className='single-content-body flex justify-between items-end mt-2'>
										<h2 className='text-[#413F5E] font-bold text-base text-left max-w-xl'>{content?.title}</h2>
									</div>
									<div className=' flex items-end justify-between'>
										<ProgressBar
											bgcolor={'#5E639B'}
											backgroundColor={'white'}
											completed={Math.round((content?.answers?.length / content?.target_count) * 100)}
										/>
										<p className='text-right text-sm text-darkenPurple font-medium ml-2 h-[16px]'>
											{content?.answers?.length}/{content?.target_count}
										</p>
									</div>
								</div>
							</div>
							<div className='w-full flex flex-row items-center justify-between mt-5'>
								<p className='text-sm text-darkenPurple font-medium'>
									<span className='opacity-[60%] '>{t('by')}:</span> {content?.created_by?.first_name}{' '}
									{content?.created_by?.last_name}
								</p>
								<p className='text-sm text-[#413F5E] font-medium'>
									<span className='opacity-[60%]'>Time: </span>
									{format(new Date(content.publicationDate), 'HH:mm')}
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default SingleContent;
