import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DAYS, INIT_REPEAT_DAYS, localeMap } from '../../constants/Constants';
import DayPickModule from './DayPickModule';
import { ReactComponent as Calendar } from '../../assets/svg/CalendarIcon.svg';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18n from 'i18next';
import { TextField } from '@mui/material';

const RepeatContentContainer = ({
	enabled = false,
	selectedType = 'weekly',
	setType = () => {},
	selectedDay = 1,
	setSelectedDay = () => {},
	days = [],
	setDays = () => {},
	endOfRecurrenceDate = new Date(),
	setEndOfRecurrenceDate,
}) => {
	const { t, locale } = useTranslation();

	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

	const changeDate = (date) => {
		if (date === null && date === undefined) return;
		setEndOfRecurrenceDate(date);
	};

	const selectMonthly = () => {
		setType('monthly');
		setSelectedDay(1);
	};
	const selectWeekly = () => {
		setType('weekly');
		setDays([]);
	};

	const selectDay = (day) => {
		const index = days.findIndex((e) => e === day);
		if (index > -1) setDays(days.filter((e) => e !== day));
		else setDays([...days, day]);
	};

	return (
		<div className={`${enabled ? '' : 'opacity-[0.38] !cursor-default'}`}>
			<div className='mt-[10px] '>
				<button
					disabled={!enabled}
					className={`px-[40px] h-[44px] rounded-[100px]  mr-[15px] text-[18px] font-semibold transition-all duration-300 ease-in-out 
                    ${selectedType === 'weekly' && enabled ? 'bg-[#5E639B] text-white' : 'text-[#5E639B] bg-[#ECEBF8]'}
                `}
					onClick={selectWeekly}
				>
					{t('weekly')}
				</button>
				<button
					disabled={!enabled}
					className={`px-[40px] h-[44px] rounded-[100px]  mr-[15px] text-[18px] font-semibold transition-all duration-300 ease-in-out 
                    ${selectedType === 'monthly' && enabled ? 'bg-[#5E639B] text-white' : 'text-[#5E639B] bg-[#ECEBF8]'}
                `}
					onClick={selectMonthly}
				>
					{t('monthly')}
				</button>
			</div>
			{selectedType === 'weekly' && (
				<div className='bg-lightBG rounded-full flex mt-[30px] items-center justify-start px-4 h-[40px] w-[540px]'>
					<p className='text-[#5E639B] font-semibold text-base mr-16'>{t('selectDays')}:</p>
					<div className='flex flex-row items-center '>
						{DAYS.map((day, index) => {
							const selected = days?.includes(day.value);
							return (
								<button
									disabled={!enabled}
									className={`mx-[2px] py-[5px] px-[7px] text-[16px] leading-[20px] font-semibold transition-all duration-300 ease-in-out ${
										selected ? 'text-[#5E639B] bg-white rounded-full ' : 'text-[#413F5E]'
									}`}
									onClick={() => {
										selectDay(day.value);
									}}
									key={index}
								>
									{t(day.name)}
								</button>
							);
						})}
					</div>
				</div>
			)}
			{selectedType === 'monthly' && (
				<div className='flex items-center mt-[30px]'>
					<p className='text-base font-semibold text-left text-[#413F5E] mr-5'>{t('everyMonthOn')}:</p>
					<div className='day-picker flex flex-col items-center justify-center'>
						<DayPickModule setDay={setSelectedDay} day={selectedDay} disabled={!enabled} />
					</div>
				</div>
			)}
			<div className='relative bg-lightBG rounded-full flex mt-5 items-center justify-center px-4 h-[40px] w-[540px]'>
				<button
					className='flex items-center gap-2 pointer not-selectable'
					onClick={() => setIsDatePickerOpen(true)}
					disabled={!enabled}
				>
					<Calendar />
					<p className='text-base text-[#413F5E] font-semibold'> {t('endDate')}: </p>
					<p className='text-base text-deepPurple font-semibold'>{endOfRecurrenceDate?.toLocaleDateString()}</p>
				</button>
				<div style={{ position: 'absolute', top: 0, left: 270, visibility: 'hidden' }}>
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[i18n.language]}>
						<DatePicker
							open={isDatePickerOpen}
							onClose={() => setIsDatePickerOpen(false)}
							label='Date desktop'
							inputFormat='MM/dd/yyyy'
							value={endOfRecurrenceDate}
							onChange={changeDate}
							minDate={new Date()}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
			</div>
		</div>
	);
};

export default RepeatContentContainer;
