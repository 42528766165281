import { useEffect, useState } from 'react';
import { CONTENT_TOPICS } from '../../constants/Constants';
import '../../styles/content/readyToGoContent.css';
import { ReactComponent as RunningIcon } from '../../assets/svg/RunningIcon.svg';
import { ReactComponent as LockIconTrial } from '../../assets/svg/LockIconTrial.svg';
import { ReactComponent as ShowMoreLessIcon } from '../../assets/svg/ShowMoreLessIcon.svg';
import ReactTooltip from 'react-tooltip';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';
import { notify, translateTargetText } from '../../utils/util';

const TypeHomeList = ({ content = [], type = 'programs', onClick = () => {}, openCurrentlyRunningPrograms = () => {}, loading = true }) => {
	const topics_code = CONTENT_TOPICS.map((e) => e.code);
	const [visibleContent, setVisibleContent] = useState(
		CONTENT_TOPICS.map((topic) => content?.some((e) => e?.topics?.includes(topic.code)))
	);
	const { t, locale } = useTranslation();
	const [visibleOther, setVisibleOther] = useState(content?.some((e) => topics_code.some((t) => e?.topics?.includes(t))));

	useEffect(() => {
		setVisibleContent(CONTENT_TOPICS.map((topic) => content?.some((e) => e?.topics?.includes(topic.code))));
		setVisibleOther(content?.some((e) => !topics_code?.some((t) => e?.topics?.includes(t))));
	}, [content]);

	return (
		<div className='type-home-container'>
			{loading && (
				<div className='h-full w-full  flex items-center justify-center'>
					<Loading size={40} />
				</div>
			)}
			{!loading &&
				CONTENT_TOPICS.filter((_, index) => visibleContent[index]).map((contentType) => {
					return (
						<ContentType
							contentType={contentType}
							onClick={(data) => {
								onClick(data, type);
							}}
							openCurrentlyRunningPrograms={openCurrentlyRunningPrograms}
							content={content.filter((e) => e?.topics?.includes(contentType.code))}
							key={contentType.code}
						/>
					);
				})}
			{!loading && visibleOther && (
				<div className='single-type-section'>
					<div className='single-type-section-header'>
						{/* {contentType.icon} */}
						<p className='single-type-section-title' style={{ color: '#BEBECE' }}>
							{t('other')}
						</p>
						<div className='single-type-dash' style={{ borderTopColor: '#BEBECE' }}></div>
					</div>
					<div className='single-type-section-items'>
						{content
							.filter((e) => !topics_code.some((t) => e?.topics?.includes(t)))
							.map((item, index) => {
								const backgroundColor = `bg-[#F8F8FB]`;
								const hoverColor = `hover:bg-[#ECEBF8]`;
								let message = '';
								message =
									item?.runningPrograms?.map((target, index) => translateTargetText(target.target_text)).join(', ') ?? '';
								return (
									<button
										className={`single-type-section-item  ${backgroundColor} ${hoverColor}`}
										onClick={() => {
											if (item.locked) notify('This content is locked due to Trial Version');
											else onClick(item, type);
										}}
										key={index}
									>
										<div className='flex flex-row items-center justify-between mb-[10px] w-full'>
											<div className='flex-1 flex flex-row items-center'>
												<p className='single-type-item-title text-left'>{item?.name ?? item?.title}</p>
												{item.locked && (
													<span className='mx-[11px]'>
														<LockIconTrial />
													</span>
												)}
											</div>
											{!!item.runningPrograms && (
												<div
													data-tip={t('currentlyRunning') + `: ${message}`}
													data-for='running-tooltip'
													onClick={(e) => {
														e.stopPropagation();
														openCurrentlyRunningPrograms(item.runningPrograms);
													}}
												>
													<RunningIcon width={27} height={25} />
													<ReactTooltip
														id='running-tooltip'
														arrowColor='#5E639B'
														effect='solid'
														backgroundColor='#5E639B'
														className='wellbeing-tooltip'
													/>
												</div>
											)}
										</div>
										<p className='single-type-item-description'>{item?.description}</p>
									</button>
								);
							})}
					</div>
				</div>
			)}
		</div>
	);
};

const ContentType = ({ contentType, onClick, openCurrentlyRunningPrograms, content }) => {
	const [showMore, setShowMore] = useState(content.length <= 2);
	const needShowMoreButton = content.length > 2;
	const realContent = needShowMoreButton && !showMore ? content.slice(0, 2) : content;
	const { t, locale } = useTranslation();

	return (
		<div className='single-type-section'>
			<div className='single-type-section-header'>
				{contentType.icon}
				<p className='single-type-section-title' style={{ color: contentType.titleColor }}>
					{t(contentType.name)}
				</p>
				<div className='single-type-dash' style={{ borderTopColor: contentType.titleColor }}></div>
			</div>
			<div className='single-type-section-items'>
				{realContent
					.sort((a, b) => (a.name > b.name ? 1 : -1))
					.map((item) => {
						const backgroundColor = `${contentType.cssClass}-background`;
						const hoverColor = `${contentType.cssClass}-hover`;
						let message = '';
						message = item?.runningPrograms?.map((target, index) => translateTargetText(target.target_text)).join(', ') ?? '';
						return (
							<button
								className={`single-type-section-item  ${backgroundColor} ${hoverColor}`}
								onClick={() => {
									if (item.locked) notify('This content is locked due to Trial Version');
									else onClick(item);
								}}
							>
								<div className='flex flex-row items-center mb-[10px] w-full'>
									<div className='flex-1 flex flex-row items-center'>
										<p className='single-type-item-title text-left'>{item?.name ?? item?.title}</p>
										{item.locked && (
											<span className='mx-[11px]'>
												<LockIconTrial />
											</span>
										)}
									</div>

									{!!item.runningPrograms && (
										<div
											data-tip={t('currentlyRunning') + `: ${translateTargetText(message)}`}
											data-for='running-tooltip'
											onClick={(e) => {
												e.stopPropagation();
												openCurrentlyRunningPrograms(item.runningPrograms);
											}}
										>
											<RunningIcon width={27} height={25} fill='#413f5e' />
											<ReactTooltip
												id='running-tooltip'
												arrowColor='#5E639B'
												effect='solid'
												backgroundColor='#5E639B'
												className='wellbeing-tooltip'
											/>
										</div>
									)}
								</div>
								<p className='single-type-item-description'>{item?.description}</p>
							</button>
						);
					})}
			</div>
			<div className='w-full  justify-center'>
				{needShowMoreButton && (
					<button
						className='showMoreLessButton'
						onClick={() => {
							setShowMore(!showMore);
						}}
					>
						<div>
							<ShowMoreLessIcon className={` ${showMore ? 'mb-[3px] rotate-180' : ' mb-[3px]'}`} />
						</div>
						<span className='ml-[8px]'>{showMore ? t('showLess') : t('showMore')}</span>
					</button>
				)}
			</div>
		</div>
	);
};

export default TypeHomeList;
