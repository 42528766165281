import React, { useState } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/RunProgramModal.css';
import { FaTimes } from 'react-icons/fa';
import RunProgramTimePick from './RunProgramTimePick';
import TargetGroupModal from './TargetGroupModal';
import i18n from 'i18next';
import TextField from '@mui/material/TextField';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import arLocale from 'date-fns/locale/ar-SA';
import { format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { checkProgramOverlap, runProgram } from '../../server/server';
import { notify, translateTargetText } from '../../utils/util';
import ConfirmationModal from '../modals/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as Calendar } from '../../assets/svg/CalendarIcon.svg';
import Loading from '../Loading';

function RunProgramModal({ showRun, closeRun, programDaysLength, user, programID, programName }) {
	const { t, locale } = useTranslation();
	const [processing, setProcessing] = useState(false);
	React.useEffect(() => {
		if (showRun) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [showRun]);
	const [returnTarget, setReturnTarget] = useState({
		name: 'Select target',
		target: [],
		selection: [],
		type: 'root',
	});
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [overlapModalOpen, setOverlapModalOpen] = useState(false);
	const [targetDays, setTargetDays] = useState([
		{
			name: 'Sun',
			selected: false,
		},
		{
			name: 'Mon',
			selected: false,
		},
		{
			name: 'Tue',
			selected: false,
		},
		{
			name: 'Wed',
			selected: false,
		},
		{
			name: 'Thu',
			selected: false,
		},
		{
			name: 'Fri',
			selected: false,
		},
		{
			name: 'Sat',
			selected: false,
		},
	]);
	const [startDate, setStartDate] = useState(new Date());
	const [showTarget, setShowTarget] = useState(false);
	const [pickDate, setPickDate] = useState(format(Date.now(), "yyyy-MM-dd'T'HH:mm:ss"));

	const [pickedDate, setPickedDate] = useState({
		poll: { hours: 8, minutes: 0 },
		question: { hours: 8, minutes: 0 },
		feed: { hours: 8, minutes: 0 },
	});
	const handlePickedDateChange = (newDate) => {
		setStartDate(newDate);
	};
	const getDeltaDays = () => {
		let days = programDaysLength;
		let currentDayDiff = 0;
		let currentDayWeek = startDate.getDay();
		let diffs = [];
		while (days) {
			if (targetDays[currentDayWeek % 7].selected) {
				diffs.push(currentDayDiff);
				--days;
			}
			++currentDayDiff;
			++currentDayWeek;
		}
		return diffs;
	};
	const closeOverlapModal = () => {
		setOverlapModalOpen(false);
	};

	const closeTarget = () => {
		setShowTarget(false);
	};
	const checkOverlap = async () => {
		const response = await checkProgramOverlap({
			program_id: programID,
			target: returnTarget?.target ? returnTarget.target.map((a) => a.id) : [],
			selected_classes: [],
			institution_id: user.institution_id,
		});
		if (response.status === 200) {
			if (response.data.isOverlapping) {
				setOverlapModalOpen(true);
			} else {
				runProg(startDate);
			}
		}
	};
	const runProg = async () => {
		setProcessing(true);
		const response = await runProgram({
			createdDate: Date.now(),
			targetDays: targetDays,
			target: returnTarget?.target ? returnTarget.target.map((a) => a.id) : [],
			deltaDays: getDeltaDays(),
			time: pickedDate,
			programId: programID,
			hash: Date.now() + user.id.toString(36),
			startDate: startDate,
			scheduleInfo: {},
			targetText: returnTarget.name,
			selectedClasses: [],
			selectedYears: [],
		});
		if (response.status === 200) {
			notify(t('youAreRunningThisProgramSuccessfully'));
			closeRun();
			setProcessing(false);
		} else {
			setProcessing(false);
			const message = response.message ?? 'There is an error...';
			notify(message);
		}
	};

	const handleRun = () => {
		console.log(pickedDate);
		if (targetDays.some((td) => td.selected === true)) {
			if (returnTarget?.target?.length === 0) {
				notify(t('youMustSelectTargetGroup'));
			} else {
				console.log('radi');
				checkOverlap(startDate);
			}
		} else {
			notify(t('youMustSelectDaysOfWeek'));
		}
	};
	const handleSelectedDay = (name, selected, id) => {
		const newDays = [...targetDays];
		newDays[id] = { name: name, selected: !selected };
		setTargetDays(newDays);
	};
	const handleOverlapConfirmation = () => {
		setOverlapModalOpen(false);
		runProg(startDate);
	};
	const localeMap = {
		ar: arLocale,
		en: enLocale,
		de: deLocale,
	};
	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={showRun}
			onAfterOpen={() => {}}
			onRequestClose={closeRun}
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					minWidth: 600,
					margin: 'auto',
					marginTop: '2%',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					margin: 'auto',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div className='w-full justify-center flex'>
				<div
					className='hover:bg-darkenPurple pointer absolute top-[10px] flex bg-deepPurple rounded-full w-[60px] h-[60px] justify-center items-center'
					onClick={() => closeRun()}
				>
					<CloseModalIcon />
				</div>
				<div className={`bg-white rounded-xl mt-10 h-full w-full ${i18n.language == 'ar' ? 'ar-lang' : ''}`}>
					<div className='pt-10 pb-5 bg-lightBG rounded-xl'>
						<p className='text-deepPurple font-bold text-[25px] text-center'>{t('runProgram')}</p>
						<p className='text-sm text-mudPurple text-center'>{programName}</p>
					</div>
					<div className='flex justify-center items-center flex-col p-6 w-full'>
						<div className='w-full flex justify-center mb-4'>
							<p className='text-sm text-mudPurple text-center w-[430px]'>{t('thisToolHelpsYouPlanTheEntireProgramOnce')}</p>
						</div>
						<div className='w-[500px] flex justify-between items-center my-4'>
							<div className='bg-lightBG rounded-full flex items-center px-4 h-[40px]'>
								<p className='text-base text-deselectPurple font-semibold mr-2'>{t('targetGroup')}:</p>
								<div className='' onClick={() => setShowTarget(true)}>
									<p className='text-base text-deepPurple font-semibold pointer'>
										{translateTargetText(returnTarget.name)}
									</p>
								</div>
							</div>
							<div className='bg-lightBG relative rounded-full flex items-center px-4 h-[40px]'>
								<div className='flex items-center gap-2 pointer not-selectable' onClick={() => setIsDatePickerOpen(true)}>
									<Calendar />
									<p className='text-base text-deselectPurple font-semibold'> {t('startDate')}: </p>
									<p className='text-base text-deepPurple font-semibold'>{startDate.toLocaleDateString()}</p>
								</div>
								<div style={{ position: 'absolute', top: 0, left: 0, visibility: 'hidden' }}>
									<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[i18n.language]}>
										<DatePicker
											open={isDatePickerOpen}
											onClose={() => setIsDatePickerOpen(false)}
											label='Date desktop'
											inputFormat='MM/dd/yyyy'
											value={startDate}
											onChange={handlePickedDateChange}
											renderInput={(params) => <TextField {...params} />}
											minDate={new Date()}
										/>
									</LocalizationProvider>
								</div>
							</div>
						</div>

						<div className='bg-lightBG rounded-full flex items-center justify-start px-4 h-[40px] w-[500px]'>
							<p className='text-deselectPurple font-semibold text-base mr-12'> {t('selectDays')}: </p>
							<div className='flex flex-row items-center '>
								{targetDays.map(({ name, selected }, id) => {
									return (
										<div
											className={`text-deselectPurple mx-1 pointer p-1 text-base font-semibold ${
												selected ? 'text-deepPurple bg-white rounded-full ' : ''
											}`}
											key={id}
											onClick={() => handleSelectedDay(name, selected, id)}
										>
											{t(name)}
										</div>
									);
								})}
							</div>
						</div>
						<div className='flex w-full justify-center items-center'>
							<p className='text-deselectPurple font-semibold text-base mr-5 mt-10'>{t('pickTime')}: </p>
							<div className='mt-4'>
								<RunProgramTimePick pickedDate={pickedDate} setPickedDate={setPickedDate} />
							</div>
						</div>
						<div className='w-full flex justify-center my-4'>
							<button
								className='h-[50px] w-[200px] pointer flex items-center justify-center bg-deepPurple rounded-full'
								onClick={() => handleRun()}
								id='cy-program-run-modal'
								disabled={processing}
							>
								{processing ? (
									<Loading primaryColor='#9392AD' secondaryColor='#9392AD' size={25} stroke={7} />
								) : (
									<p className='text-highlightColor text-[18px] leading-7 font-semibold'>{t('runProgram')}</p>
								)}
							</button>
						</div>
					</div>
				</div>
				<ConfirmationModal
					modalIsOpen={overlapModalOpen}
					closeModal={closeOverlapModal}
					confirmation={handleOverlapConfirmation}
					message={t('thisProgaramIsAlreadyRunning')}
				/>
				<TargetGroupModal showTarget={showTarget} closeTarget={closeTarget} setReturnTarget={setReturnTarget} />
			</div>
		</ReactModal>
	);
}

export default RunProgramModal;
