var CryptoJS = require("crypto-js");

export const decrypt = (text, key) => {
    if(key !== '' && key != null){
        try{
            const bytes  = CryptoJS.AES.decrypt(text, key);
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            return originalText
        }catch{
            return text
        }
    }else{
        return text
    }
    
}

export const encrypt = (text, key) =>{
    return !!key ? CryptoJS.AES.encrypt(text, key).toString() : text;
}