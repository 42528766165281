import Empty from '../assets/emotions/00-Empty.png';
import Happy from '../assets/emotions/01-Happy.svg';
import Ecstatic from '../assets/emotions/02-Ecstatic.svg';
import Inspired from '../assets/emotions/03-Inspired.svg';
import Calm from '../assets/emotions/04-Calm.svg';
import Lethargic from '../assets/emotions/05-Lethargic.svg';
import Grumpy from '../assets/emotions/06-Grumpy.svg';
import Sad from '../assets/emotions/07-Sad.svg';
import Stressed from '../assets/emotions/08-Stressed.svg';
import Angry from '../assets/emotions/09-Angry.svg';
import i18n from '../i18n';
import Good from '../assets/emotions/10-Good.svg';
import Numb from '../assets/emotions/11-Numb.svg';
import Worried from '../assets/emotions/12-Worried.svg';
import Afraid from '../assets/emotions/Afraid.svg';
import { ReactComponent as MyselfIcon } from '../assets/howAreYouSvg/MyselfIcon.svg';
import { ReactComponent as FamilyIcon } from '../assets/howAreYouSvg/FamilyIcon.svg';
import { ReactComponent as FriendsIcon } from '../assets/howAreYouSvg/FriendsIcon.svg';
import { ReactComponent as SchoolIcon } from '../assets/howAreYouSvg/SchoolIcon.svg';
import { ReactComponent as SocialMediaIcon } from '../assets/howAreYouSvg/SocialMediaIcon.svg';
import { ReactComponent as WellbeingIcon } from '../assets/howAreYouSvg/WellbeingIcon.svg';
import { ReactComponent as HealthIcon } from '../assets/howAreYouSvg/HealthIcon.svg';
import { ReactComponent as AchievementIcon } from '../assets/howAreYouSvg/AchievementIcon.svg';
import { ReactComponent as SleepIcon } from '../assets/howAreYouSvg/SleepIcon.svg';
import { ReactComponent as PersonalWellbeingIcon } from '../assets/svg/PersonalWellbeingIcon.svg';
import { ReactComponent as GeneralWellbeingIcon } from '../assets/svg/GeneralWellbeingIcon.svg';
import { ReactComponent as SchoolContentIcon } from '../assets/svg/SchoolIcon.svg';
import { ReactComponent as StressManagementIcon } from '../assets/svg/StressManagementIcon.svg';
import { ReactComponent as SELIcon } from '../assets/svg/SELIcon.svg';
import { ReactComponent as SelfManagementIcon } from '../assets/svg/SelfManagementIcon.svg';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import arLocale from 'date-fns/locale/ar-SA';

export const getDays = () => [
	i18n.t('sunday'),
	i18n.t('monday'),
	i18n.t('tuesday'),
	i18n.t('wednesday'),
	i18n.t('thursday'),
	i18n.t('friday'),
	i18n.t('saturday'),
];
export const days = [
	i18n.t('sunday'),
	i18n.t('monday'),
	i18n.t('tuesday'),
	i18n.t('wednesday'),
	i18n.t('thursday'),
	i18n.t('friday'),
	i18n.t('saturday'),
];
export const months_list = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const emotions_list = [
	'happy',
	'ecstatic',
	'inspired',
	'calm',
	'good',
	'numb',
	'worried',
	'lethargic',
	'grumpy',
	'sad',
	'stressed',
	'angry',
];
export const primary_emotions_list = ['happy', 'excited', 'ok', 'sad', 'afraid', 'angry'];
export const negativeEmotions = ['grumpy', 'sad', 'stressed', 'angry'];
export const emotionColorsArray = [
	'#FDDF6D',
	'#F47D3A',
	'#ED68A0',
	'#83CDF5',
	'#8BCF55',
	'#6693E4',
	'#D28B98',
	'#C1A7ED',
	'#6FCC87',
	'#6693E4',
	'#DC8CDD',
	'#FC4C59',
];
export const primaryEmotionColorsArray = ['#FDDF6D', '#ED68A0', '#83CDF5', '#4F6288', '#BC8ABD', '#FC4C59'];
export const emotions_color = {
	happy: '#FAE480',
	calm: '#83CDF5',
	ecstatic: '#F47C39',
	grumpy: '#6FCC87',
	angry: '#FC4C59',
	sad: '#689CFA',
	stressed: '#DC8CDD',
	lethargic: '#C1A7ED',
	inspired: '#ED68A0',
	false: ' rgba(31, 31, 67, 0.05)',
	good: '#BAEF90',
	worried: '#E4A6B1',
	numb: '#399AF4',
	ok: '#83CDF5',
	afraid: '#BC8ABD',
	excited: '#ED68A0',
};

export const emotions_color_dark = {
	happy: '#9D4F20',
	calm: '#3A79B9',
	ecstatic: '#891A1C',
	grumpy: '#186C2D',
	angry: '#82133A',
	sad: '#1F4B99',
	stressed: '#862D98',
	lethargic: '#65318C',
	inspired: '#95225B',
	good: '#BAEF90',
	worried: '#E4A6B1',
	numb: '#399AF4',
};

export const emotions_color_light = {
	happy: '#FAE480',
	calm: '#83CDF5',
	ecstatic: '#F47C39',
	grumpy: '#6FCC87',
	angry: '#FC4C59',
	sad: '#689CFA',
	stressed: '#DC8CDD',
	lethargic: '#C1A7ED',
	inspired: '#ED68A0',
	good: '#BAEF90',
	worried: '#E4A6B1',
	numb: '#399AF4',
};

export const emotions_color_transparent = {
	happy: '#FDDF6DCC',
	calm: '#83CDF5CC',
	ecstatic: '#F47C39CC',
	grumpy: '#6FCC87CC',
	angry: '#FC4C59CC',
	sad: '#689CFACC',
	stressed: '#DC8CDDCC',
	lethargic: '#C1A7EDCC',
	inspired: '#ED68A0CC',
	good: '#BAEF90CC',
	worried: '#E4A6B1CC',
	numb: '#399AF4CC',
	false: '#1f1f430D',
	ok: '#83CDF5CC',
	afraid: '#BC8ABDCC',
	excited: '#ED68A0CC',
};
export const emotions_color_opacity_03 = {
	happy: '#FDDF6D50',
	calm: '#83CDF550',
	ecstatic: '#F47C3950',
	grumpy: '#6FCC8750',
	angry: '#FC4C5950',
	sad: '#689CFA50',
	stressed: '#DC8CDD50',
	lethargic: '#C1A7ED50',
	inspired: '#ED68A050',
	good: '#BAEF9050',
	worried: '#E4A6B150',
	numb: '#399AF450',
	false: '#1f1f430D',
	ok: '#83CDF550',
	afraid: '#BC8ABD50',
	excited: '#ED68A050',
};

export const emotions_heads = {
	happy: Happy,
	calm: Calm,
	ecstatic: Ecstatic,
	grumpy: Grumpy,
	angry: Angry,
	sad: Sad,
	stressed: Stressed,
	lethargic: Lethargic,
	inspired: Inspired,
	default: Empty,
	motivated: Empty,
	false: Empty,
	good: Good,
	numb: Numb,
	worried: Worried,
	ok: Calm,
	afraid: Afraid,
	excited: Inspired,
};
export const mainReasonListIcons = [
	<MyselfIcon />,
	<FamilyIcon />,
	<FriendsIcon />,
	<SchoolIcon />,
	<SocialMediaIcon />,
	<WellbeingIcon />,
	<HealthIcon />,
	<AchievementIcon />,
	<SleepIcon />,
];

export const reasonsJson = {
	myself: <MyselfIcon />,
	family: <FamilyIcon />,
	friends: <FriendsIcon />,
	school: <SchoolIcon />,
	socialMedia: <SocialMediaIcon />,
	'social media': <SocialMediaIcon />,
	wellbeing: <WellbeingIcon />,
	health: <HealthIcon />,
	achievement: <AchievementIcon />,
	sleep: <SleepIcon />,
};

export const mainReasonList = ['Myself', 'Family', 'Friends', 'School', 'Social media', 'Wellbeing', 'Health', 'Achievement', 'Sleep'];

export const filterPeriods = [
	{
		code: 7,
		name: '7 Days',
		shortName: '7d',
	},
	{
		code: 30,
		name: '1 Month',
		shortName: '1m',
	},
	{
		code: 91,
		name: '3 Months',
		shortName: '3m',
	},
	{
		code: 365,
		name: '1 Year',
		shortName: '1y',
	},
];
export const filterList = ['By class', 'By emotion', 'By comment'];

export const targetGroupList = ['Target group #1', 'Target group #2 long text', 'Target group #3'];

export const teachersList = [
	{
		name: 'zoran',
		mail: 'zorancar@hotmail.com',
		reg: 'a-7',
		lastLogin: '12/12/1212',
	},
	{
		name: 'zoran',
		mail: 'zorancar@hotmail.com',
		reg: 'a-7',
		lastLogin: '12/12/1212',
	},
	{
		name: 'zoran',
		mail: 'zorancar@hotmail.com',
		reg: 'a-7',
		lastLogin: '12/12/1212',
	},
	{
		name: 'zoran',
		mail: 'zorancar@hotmail.com',
		reg: 'a-7',
		lastLogin: '12/12/1212',
	},
];

export const contentBackgrounds = {
	poll: 'poll-background',
	question: 'question-background',
	survey: 'survey-background',
	journal: 'journal-background',
};

export const TARGET_GROUP_INIT = {
	name: 'Select target',
	target: [],
	selection: [],
	type: 'root',
};
export const SCHEDULE_CONTENT_INIT = {
	enabled: false,
	text: 'Off',
	recurrentText: 'Never',
	repeatEnabled: false,
	schedule_date: 0,
};

export const REPEAT_CONTENT_INIT = {
	repeat: false,
	repeatPeriodText: 'Never',
	recurrentType: '',
	selectedDay: 0,
	selectedDays: [],
	endOfRecurrenceDate: 0,
};
export const OPTION_TYPE_DEFAULT = {
	name: 'Pick one option',
	desc: 'Select one item from the list',
};

export const CONTENT_TOPICS = [
	{
		name: 'General Wellbeing',
		description:
			'This is a great place to start if you’re looking for some general wellbeing content before diving into some of the more specific topics.',
		code: 'generalWellbeing',
		icon: <GeneralWellbeingIcon />,
		cssClass: 'general-wellbeing-category',
		color: '#32929B80',
		titleColor: '#32929B',
	},
	{
		name: 'Personal Wellbeing',
		description:
			'Here you’ll find more personal wellbeing content designed to help you and your students learn more about specific challenges and ways to overcome them.',
		code: 'personalWellbeing',
		icon: <PersonalWellbeingIcon />,
		cssClass: 'personal-wellbeing-category',
		color: '#68b8e280',
		titleColor: '#68b8e2',
	},
	{
		name: 'School',
		description:
			'If it’s a school-related topic such as getting organised, excelling in exams, or being productive, it’s right here for you!',
		code: 'school',
		icon: <SchoolContentIcon />,
		cssClass: 'school-category',
		color: '#ed9c6f80',
		titleColor: '#ed9c6f',
	},
	{
		name: 'Stress Management',
		description:
			'Find out more about your students’ & colleagues’ experiences of stress with a survey and support them with a range of stress management tools.',
		code: 'stressManagement',
		icon: <StressManagementIcon />,
		cssClass: 'stress-management-category',
		color: '#d99c3e80',
		titleColor: '#d99c3e',
	},
	{
		name: 'Self-management',
		description:
			'From creating confidence to goal setting, you’ll find a great range of skill-based surveys, programs, and journals here.',
		code: 'selfManagement',
		icon: <SelfManagementIcon />,
		cssClass: 'self-management-category',
		color: '#C681CB80',
		titleColor: '#C681CB',
	},
	{
		name: 'SEL',
		description:
			'Our range of social and emotional learning (SEL) content is all designed to help you & your students enhance self-awareness and develop greater self-management skills.',
		code: 'sel',
		icon: <SELIcon />,
		cssClass: 'sel-category',
		color: '#ff806080',
		titleColor: '#ff8060',
	},
];

export const localeMap = {
	ar: arLocale,
	en: enLocale,
	de: deLocale,
};

export const INIT_REPEAT_DAYS = {
	Sun: false,
	Mon: false,
	Tue: false,
	Wed: false,
	Thu: false,
	Fri: false,
	Sat: false,
};

export const DAYS = [
	{
		name: 'Mon',
		value: 0,
	},
	{
		name: 'Tue',
		value: 1,
	},
	{
		name: 'Wed',
		value: 2,
	},
	{
		name: 'Thu',
		value: 3,
	},
	{
		name: 'Fri',
		value: 4,
	},
	{
		name: 'Sat',
		value: 5,
	},
	{
		name: 'Sun',
		value: 6,
	},
];

export const MINIMAL_NUMBER_OF_POLL_OPTIONS = 2;
export const MODAL_ANIMATION_TIME = 600;
export const BACKDROP_OPACITY = 0.4;
export const ANIMALS_ARRAY = [
	'fox',
	'panda',
	'tiger',
	'raccoon',
	'shark',
	'hamster',
	'penguin',
	'bear',
	// 'octopus',
	'dog',
	'bird',
	'koala',
	'giraffe',
	'otter',
	'deer',
	'bunny',
	'elephant',
	'zebra',
	'sugarGlider',
	'leopard',
	'fish',
	'darkBear',
	'kitty',
	'beaver',
	'frog',
	'hedgeHog',
	'lion',
	'lama',
	'owl',
	'rabbit',
];

export const TITLE = {
	year: 'selectYears',
	class: 'selectClasses',
	custom: 'selectCustom',
	staff: 'selectStaff',
	favorite: 'Select from favorites',
};
export const RESULT_PREFIX = {
	year: 'Year',
	class: 'Class',
	custom: '',
	staff: '',
	favorite: '',
};
export const SUBTITLE = {
	year: 'selectSpecificYearGroupsToGatherMoreInformationFrom',
	class: 'Select specific classes to gather more information from',
	custom: 'Create your own customized group to gather information from',
	staff: 'Select specific teachers you would like to get information from',
	favorite: 'selectYourFavoriteGroup',
};
