import React from 'react';
import { useState, useEffect } from 'react';
import { ReactComponent as DoubleArrows } from '../../assets/svg/DoubleArrows.svg';
import { ReactComponent as ArchiveTransparent } from '../../assets/svg/ArchiveTransparent.svg';
import { ReactComponent as QuestionMarkIcon } from '../../assets/svg/QuestionMarkIcon.svg';
import { ReactComponent as UnknownEmotionIcon } from '../../assets/svg/UnknownEmotionIcon.svg';
import EmojiHalo from '../../assets/svg/EmojiHalo.svg';

import { ReactComponent as LeftIcon } from '../../assets/svg/MyTaskLeft.svg';
import { emotions_heads } from '../../constants/Constants';
import { fileSystemUrl } from '../../constants/Connections';
import PollTask from './PollTask';
import OpenTask from '../feedsAndDashboard/OpenTasks';
import ArchiveTasks from '../feedsAndDashboard/ArchiveTask';
import TeacherFeedbackModal from '../modals/TeacherFeedbackModal';
import QuestionTask from './QuestionTask';
import HowAreYouModal from '../modals/HowAreYou/HowAreYouModal';
import { checkIfTakeDailyFeedback, getCheckUsersFeedback, sendRejectReview } from '../../server/server';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { width } from '@mui/system';
import SuccessModal from '../modals/SuccessModal';

const formatDate = (date) => `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

function MyTask({ user, scrollId = 'horizontal-scroll-id', setRefreshTasks = () => {} }) {
	const [expanded, setExpanded] = useState(true);
	const [selectedState, setSelectedState] = useState('openTasks');
	const [selectedItem, setSelectedItem] = useState({});
	const [howAreYouModalOpen, setHowAreYouModalOpen] = useState(false);
	const [openTasksNumber, setOpenTasksNumber] = useState(0);
	const [lastEmotion, setLastEmotion] = useState('sad');
	const [todayEmotion, setTodayEmotion] = useState('sad');
	const [arrowColor, setArrowColor] = useState({ left: '#555BA7', right: '#FFF' });
	const [menuIndex, setMenuIndex] = useState(0);
	const [clickableArrow, setClickableArrow] = useState({ left: true, right: true });
	const { t, locale } = useTranslation();
	const [selectedEmotion, setSelectedEmotion] = useState('');
	const [hoveredEmotion, setHoveredEmotion] = useState('');
	const url = `${fileSystemUrl}/${selectedItem?.files?.storage_name}?token=${user?.token}`;
	const [loadingImage, setLoadingImage] = useState(true);
	const imgUrl = selectedItem.files ? url : false;
	const left = document.getElementById(scrollId);
	const [scrollLeft, setScrollLeft] = useState(0);

	const [teacherModalOpen, setTeacherModalOpen] = useState(false);
	const [successModalOpen, setSuccessModalOpen] = useState(false);

	const handleArchiveButton = (state) => {
		if (selectedState === 'openTasks' && selectedState === state) return;
		if (selectedState === 'archiveTasks' && state === 'archiveTasks') {
			setSelectedState('openTasks');
			return;
		}
		setSelectedState(state);
	};

	const refreshTasksNumber = (size) => {
		setOpenTasksNumber(size);
		setRefreshTasks(new Date());
	};

	const openHowAreYouModal = (emotion) => {
		setSelectedEmotion(emotion);
		setHowAreYouModalOpen(true);
	};
	const closeHowAreYouModal = () => {
		loadDailyFeedbackResults();
		setHowAreYouModalOpen(false);
	};
	const loadDailyFeedbackResults = async () => {
		const resp = await checkIfTakeDailyFeedback(user?.id);
		if (resp.status === 200) {
			const data = resp.data;
			const day_code = formatDate(new Date());
			setLastEmotion(data);
			setSelectedEmotion('');
			if (data.day_code == day_code) setTodayEmotion(data);
		}
	};

	useEffect(() => {
		loadDailyFeedbackResults();
	}, []);

	const emotions_list = [
		'happy',
		'ecstatic',
		'inspired',
		'calm',
		'good',
		'numb',
		'worried',
		'lethargic',
		'grumpy',
		'sad',
		'stressed',
		'angry',
	];

	useEffect(() => {
		const endIndex = menuIndex + 4;
		setClickableArrow({ left: menuIndex === 0 ? false : true, right: endIndex === emotions_list.length ? false : true });
	}, [menuIndex]);

	const handleScroll = (event) => {
		if (left.scrollWidth - left.scrollLeft === left.clientWidth) {
			console.log('header bottom reached');
			setArrowColor({ ...arrowColor, right: '#555BA7' });
		} else if (left.scrollLeft === 0) {
			setArrowColor({ ...arrowColor, left: '#555BA7' });
		} else {
			setArrowColor({ left: '#ffffff', right: '#ffffff' });
		}
	};

	const checkUsersFeedbackNeeded = async () => {
		const resp = await getCheckUsersFeedback();
		if (resp.status === 200) {
			if (resp.data) setTeacherModalOpen(true);
		}
	};

	const closeTeacherFeedbackModal = () => {
		setTeacherModalOpen(false);
	};

	const rejectReview = async () => {
		const resp = await sendRejectReview();
	};

	const openSuccessModal = () => {
		setSuccessModalOpen(true);
		setTimeout(() => {
			setSuccessModalOpen(false);
		}, 3500);
	};

	const closeSuccessModal = () => {
		setSuccessModalOpen(false);
	};

	return (
		<div className={`my-tasks-widget relative xxs:w-full xl:w-[480px] flex justify-center  ${expanded ? ' h-full  ' : ''} `}>
			<div className='bg-white h-full rounded-xl w-full flex flex-col'>
				<div className='w-full flex justify-between  items-center'>
					<div
						className={`rounded-xl flex-1 flex flex-col  items-center justify-between min-h-[185px]`}
						style={{ background: 'linear-gradient(152.02deg, #716EBF 16.55%, #5259A4 81.86%), #413F5E' }}
					>
						<div className='how-do-you-feel-container w-full px-[35px] pt-5'>
							<div className='flex flex-col justify-center gap-1'>
								<p className='font-bold leading-[20px]  text-[16px] text-left text-white'>{t('howAreYouToday')}</p>
								<p className=' text-[#BEBECE]  text-sm font-medium'>{t('selectEmotionThatBestDescribesYourMoodToday')}</p>
							</div>
						</div>
						<div className={` w-full justify-start items-center relative h-[115px]`}>
							<div className='how-do-you-feel-container-emotions absolute top-[7px] left-0 right-0 m-[0_auto] w-[90%] flex items-start justify-center'>
								<div
									className='mt-4 cursor-pointer w-[50px] flex items-center justify-center h-[50px] rounded-full min-w-[50px] border-[5px] border-[#6364B3] '
									style={{ background: 'linear-gradient(180deg, #7172BA 0%, #7D7EC2 100%)', paddingRight: 4 }}
									onClick={() =>
										left.scrollBy({
											left: -150,
											behavior: 'smooth',
										})
									}
								>
									<LeftIcon fill={arrowColor.left} />
								</div>
								<div
									id={scrollId}
									className='px-4 h-24 noScroll flex  gap-6 overflow-y-hidden overflow-x-auto  flex-nowrap mx-3'
									onScroll={handleScroll}
								>
									{emotions_list?.map((e) => {
										return (
											<div
												className={`flex flex-col items-center min-w-[50px]  not-selectable cursor-pointer relative emotion-head`}
												key={e}
												onClick={() => openHowAreYouModal(e)}
												onMouseEnter={() => setHoveredEmotion(e)}
												onMouseLeave={() => setHoveredEmotion('')}
											>
												<div className='absolute z-10 flex flex-col items-center gap-1 '>
													<img
														src={emotions_heads[e]}
														alt={e}
														className={`${
															hoveredEmotion === e
																? 'min-w-[54px] !h-[54px] mt-3 mb-2'
																: 'w-[50px] min-w-[50px] h-[50px] mt-4'
														} transition-all ease-in-out duration-[230ms]`}
													/>
													<p
														className={`capitalize text-sm transition-all ease-in-out duration-[230ms]  ${
															hoveredEmotion === e ? 'text-white' : 'text-deselectPurple'
														}`}
													>
														{t(e)}
													</p>
												</div>

												<div
													style={{
														backgroundImage: `url(${EmojiHalo})`,
														height: 78,
														width: 78,
														backgroundRepeat: 'no-repeat',
														opacity: hoveredEmotion === e ? 1 : 0,
														top: hoveredEmotion === e ? 0 : 2,
														bottom: 0,
														transform: hoveredEmotion === e ? 'scale(1)' : 'scale(0.7)',
														position: 'absolute',
														transition: 'all 230ms ease-in-out',
													}}
												></div>
											</div>
										);
									})}
								</div>
								<div
									className='mt-4 cursor-pointer w-[50px] flex items-center justify-center h-[50px] rounded-full min-w-[50px] border-[5px] border-[#555BA7] '
									style={{ background: 'radial-gradient(82% 82% at 50% 9%, #646AAE 0%, #6F75B7 100%)', paddingLeft: 4 }}
									onClick={() =>
										left.scrollBy({
											left: 150,
											behavior: 'smooth',
										})
									}
								>
									<LeftIcon fill={arrowColor.right} style={{ transform: 'scaleX(-1)' }} />
								</div>
							</div>
							<div className='h-[50px]'></div>
							<div
								className={` w-full rounded-xl flex justify-center h-[70%]`}
								style={{
									background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0) 100%)',
								}}
							></div>
						</div>
					</div>
				</div>
				<div className=' flex items-center mt-5 mx-[35px]'>
					<div
						className={` font-bold text-[20px] mr-[5px] cursor-pointer ${
							selectedState === 'openTasks' ? 'text-darkPurple' : 'text-mudPurple'
						}`}
						onClick={() => handleArchiveButton('openTasks')}
					>
						{t('yourTasks')}
					</div>
					{!!openTasksNumber && (
						<div className='h-[31px] w-[31px] font-[600] rounded-full flex justify-center items-center border border-deselectPurple text-[14px] text-deepPurple    '>
							{openTasksNumber}
						</div>
					)}
					<div className='flex-1'></div>
					<ArchiveTransparent style={{ color: selectedState === 'archiveTasks' ? '#413F5E' : '#9392AD' }} />
					<div
						className={`ml-[10px]  font-bold text-[16px] cursor-pointer ${
							selectedState === 'archiveTasks' ? 'text-darkPurple' : 'text-mudPurple'
						}`}
						onClick={() => handleArchiveButton('archiveTasks')}
					>
						{t('archive')}
					</div>
				</div>
				<div className='mt-[10px] mx-[35px]'>
					<p className='text-[14px]  text-[#413F5E] font-[500]'>{t('activitiesForCompletion')}</p>
				</div>
				<div className='mt-5 mx-[35px] h-[1px] border-b border-dashed border-b-lightBG'></div>

				<div className={`flex flex-col flex-auto  mx-[10px] bg-white  ${expanded ? 'h-full' : 'closes-tasks'} `}>
					{selectedState === 'openTasks' ? (
						<OpenTask user={user} setOpenTasksNumber={refreshTasksNumber} checkUsersFeedbackNeeded={checkUsersFeedbackNeeded} />
					) : (
						<ArchiveTasks user={user} />
					)}
				</div>
			</div>

			<div
				className={`rounded-full absolute -bottom-[20px] w-[40px] h-[40px] flex items-center justify-center pointer  ${
					expanded ? 'bg-white border border-lightBG hover:border-deselectPurple' : 'bg-deepPurple'
				}`}
				onClick={() => setExpanded(!expanded)}
			>
				<DoubleArrows fill={`${expanded ? '#5e639b' : '#fddf6d'}`} style={expanded ? {} : { transform: 'scaleY(-1)' }} />
			</div>

			<HowAreYouModal
				modalIsOpen={howAreYouModalOpen}
				closeModal={closeHowAreYouModal}
				user={user}
				lastEmotion={lastEmotion}
				setLastEmotion={setLastEmotion}
				emotionDef={selectedEmotion}
				checkUsersFeedbackNeeded={checkUsersFeedbackNeeded}
			/>
			<TeacherFeedbackModal
				modalIsOpen={teacherModalOpen}
				closeModal={closeTeacherFeedbackModal}
				openSuccessModal={openSuccessModal}
				rejectReview={rejectReview}
			/>
			<SuccessModal
				modalIsOpen={successModalOpen}
				closeModal={closeSuccessModal}
				message={t('Success')}
				subMessage={t('Thank you for helping us to make Upstrive better.')}
				blur='teacherFeedback'
			/>
		</div>
	);
}

export default MyTask;
