import React, { useEffect, useState } from 'react';
import { ReactComponent as CirclePlus } from '../../assets/svg/CirclePlus.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getReports, getThreeReports } from '../../server/server';
import { ReportItem, SimpleReportItem } from './CustomReports/ListOfReports';
import Loading from '../Loading';
// import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
function CustomReportsWidget() {
	const { t, locale } = useTranslation();
	const [searching, setSearching] = useState(false);
	const [loading, setLoading] = useState(true);
	const [reports, setReports] = useState([]);
	const [searchText, setSearchText] = useState([]);
	const [needToRefresh, setNeedToRefresh] = useState(false);
	const [timeoutTime, setTimeoutTime] = useState(10000);

	useEffect(() => {
		loadReports();
	}, []);

	const loadReports = async (text = '') => {
		const resp = await getThreeReports(text);
		if (resp.status === 200) {
			setReports(resp.data);
			console.log(resp.data);
			setSearching(false);
			setLoading(false);
		}
	};
	return (
		<div className='rounded-[10px] bg-[white] px-[35px] py-[25px]'>
			<div className='flex flex-row items-center justify-between gap-[10px]'>
				<p className=' text-[20px] font-bold text-[#413F5E]'>{t('Create & Manage Reports')}</p>
				<Link to={`/analytics/custom-report`}>
					<button className='create-report-button-reversed'>
						<CirclePlus />
						<span>{t('Create report')}</span>
					</button>
				</Link>
			</div>
			{loading && (
				<div className='my-[20px] relative h-[90px] flex items-center justify-center'>
					<Loading />
				</div>
			)}
			{!loading && (
				<>
					{reports.length == 0 && (
						<div className='my-[20px] relative'>
							<p className='text-[#5E639B] text-[16px] font-bold mb-[30px]'>{t('Welcome! Create your first report here')}</p>
							<div className='bg-[#F8F8FB] h-[90px] rounded-[10px]'></div>
							<img
								src={require('../../assets/images/ReportImage.png')}
								alt='Analytics Magnifier'
								style={{
									width: 432,
									position: 'absolute',
									bottom: -14,
									left: 0,
									right: 0,
									alignSelf: 'center',
									margin: 'auto',
								}}
							/>
						</div>
					)}
					{reports.length !== 0 && (
						<div className='my-[20px] relative'>
							<p className='text-[#5E639B] text-[14px] font-medium mb-[13px]'>{t('Latest reports')}</p>
							<div className='flex flex-col gap-[5px]'>
								{reports.map((report) => (
									<SimpleReportItem report={report} />
								))}
							</div>
						</div>
					)}
					{reports.length !== 0 && (
						<Link to={`/analytics/custom-report`}>
							<button className='h-[40px] flex flex-row items-center justify-center bg-[#ECEBF8] rounded-full px-[20px] text-[#9392AD] font-bold text-[14px] hover:text-[white] transition-all ease-in-out duration-[230ms] hover:bg-[#9392AD]'>
								{t('View More')}
							</button>
						</Link>
					)}
				</>
			)}
		</div>
	);
}

export default CustomReportsWidget;
