import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as Calendar } from '../../assets/svg/Calendar.svg';
import { useTranslation } from 'react-i18next';
import { create, draftSurveyAsync } from '../../server/server';
import { formatDayCode, format_day_only, notify } from '../../utils/util';
import { Oval } from 'react-loader-spinner';
import Loading from '../Loading';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import arLocale from 'date-fns/locale/ar-SA';
import TextField from '@mui/material/TextField';
import i18n from '../../i18n';
import { DAYS } from '../../constants/Constants';
import PickTimeModule from './PickTimeModule';

const localeMap = {
	ar: arLocale,
	en: enLocale,
	de: deLocale,
};

function ScheduleSurveyModal({ modalIsOpen, closeModal, survey, selectedDays, releaseByDays = false, setReturnSchedule }) {
	const { t } = useTranslation();

	const [startDate, setStartDate] = useState(new Date());
	const [days, setDays] = useState([]);
	const [error, setError] = useState(false);
	const changeDate = (date) => {
		if (date === null && date === undefined) return;
		setStartDate(date);
	};
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

	const [returnHour, setReturnHour] = useState(8);
	const [returnMinute, setReturnMinute] = useState(0);

	const selectDay = (day) => {
		setError(false);
		const index = days.findIndex((e) => e === day);
		if (index > -1) setDays(days.filter((e) => e !== day));
		else setDays([...days, day]);
	};

	useEffect(() => {
		ReactModal.setAppElement('#home');
	}, []);

	const getScheduledDays = () => {
		let _startDate = new Date(startDate);
		let scheduleDates = [];
		let sectionId = 0;
		const selectedDays = days;
		while (sectionId <= survey?.sections?.length) {
			if (selectedDays.includes(_startDate.getDay())) {
				scheduleDates.push(formatDayCode(_startDate));
				sectionId++;
			}
			_startDate.setDate(_startDate.getDate() + 1);
			console.log(_startDate.getDay(), selectedDays);
		}
		return scheduleDates;
	};

	const handleContinue = () => {
		if (releaseByDays && days.length == 0) {
			setError(true);
			return;
		}
		let schedule_date = new Date(startDate);
		// schedule_date.setUTCDate(startDate.getUTCDate());
		schedule_date.setHours(returnHour);
		schedule_date.setMinutes(returnMinute);
		schedule_date.setSeconds(0);
		let data = {
			enabled: true,
			text: 'Off',
			recurrentText: 'Never',
			repeatEnabled: false,
			schedule_date: schedule_date.getTime(),
		};
		if (releaseByDays) {
			data['scheduledDays'] = getScheduledDays();
			data['days'] = days;
		}
		setReturnSchedule(data);
		closeModal(true);
	};
	const handleOff = () => {
		setReturnSchedule({ enabled: false, text: 'Off', recurrentText: 'Never', repeatEnabled: false });
		closeModal();
	};

	return (
		<ReactModal
			transparent
			isOpen={modalIsOpen}
			ariaHideApp={false}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				// setDays([]);
			}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 30,
					overflowY: 'auto',
					height: '100vh',
				},
				content: {
					inset: 0,
					padding: 0,
					margin: 0,
					backgroundColor: 'transparent',
					// maxHeight: '90%',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: 30,
					marginBottom: 30,
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					className={`w-[60px] h-[60px] rounded-full cursor-pointer bg-deepPurple hover:bg-darkenPurple flex items-center justify-center z-[2] `}
					onClick={() => closeModal()}
				>
					<CloseModalIcon />
				</div>
			</div>

			<div className='bg-[#F8F8FB] w-[600px] mt-[30px] rounded-[10px] '>
				<div className='pt-[52px] pb-[37px] bg-[#ECEBF8] flex flex-col gap-[10px] items-center rounded-[10px] '>
					<p className='text-[30px] text-[#5E639B] leading-[38px] font-bold '>{t('Schedule Survey')}</p>
					<p className='text-[18px] text-mudPurple leading-[20px] font-medium '>{survey?.title}</p>
				</div>
				<div className='flex flex-col pt-[30px] pb-[50px] px-[50px] '>
					<p className='text-[14px] text-[#9392AD] font-medium leading-[20px] text-center'>
						{t("This tool helps you to schedule whole survey once so you don't need to check it all the time")}:
					</p>
					<div className='flex flex-col items-center'>
						<div className='rounded-full flex flex-row my-3 bg-[#ECEBF8] text-mudPurple font-semibold text-base justify-center items-center px-[25px] relative'>
							<p>{t('startDate')}: </p>
							<div
								className='h-[50px] ml-2 flex flex-row items-center justify-center cursor-pointer gap-2 not-selectable'
								onClick={() => setIsDatePickerOpen(true)}
							>
								<Calendar style={{ color: '#9392AD' }} />
								<p>{format_day_only(startDate)}</p>
							</div>
							<div style={{ position: 'absolute', top: 0, left: 0, visibility: 'hidden' }}>
								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[i18n.language]}>
									<DatePicker
										value={startDate}
										onChange={changeDate}
										open={isDatePickerOpen}
										onClose={() => setIsDatePickerOpen(false)}
										minDate={new Date()}
										renderInput={(params) => (
											<TextField
												inputProps={{ ...params.inputProps, readOnly: true }}
												onKeyDown={(e) => {
													e.preventDefault();
												}}
												{...params}
											/>
										)}
										inputFormat={'dd/MM/yyyy'}
										disableMaskedInput={true}
									/>
								</LocalizationProvider>
							</div>
						</div>
						{releaseByDays && (
							<div className='bg-lightBG rounded-full flex mt-[12px] items-center justify-between px-4 h-[40px] w-full'>
								<p className={` font-semibold text-base flex-1 ${error ? 'text-deleteRed' : 'text-[#9392AD]'}`}>
									{t('selectDays')}:
								</p>
								<div className='flex flex-row items-center '>
									{DAYS.map((day, index) => {
										const selected = days?.includes(day.value);
										return (
											<div
												className={`mx-[2px] pointer py-[5px] px-[7px] text-[16px] leading-[20px] font-semibold transition-all duration-300 ease-in-out ${
													selected ? 'text-[#5E639B] bg-white rounded-full ' : 'text-[#BEBECE]'
												}`}
												onClick={() => {
													selectDay(day.value);
												}}
												key={index}
											>
												{t(day.name)}
											</div>
										);
									})}
								</div>
							</div>
						)}
						<div className='flex flex-row items-center justify-between mt-[40px]'>
							<p className='text-base font-bold text-mudPurple mr-[80px]'>{t('setATime')} </p>
							<div>
								<PickTimeModule
									hour={returnHour}
									minute={returnMinute}
									setReturnMinute={setReturnMinute}
									setReturnHour={setReturnHour}
								/>
							</div>
						</div>
					</div>
					<div className='flex mt-2 flex-row justify-between items-center w-full '>
						<button
							className='bg-lightBG hover:bg-deselectPurple rounded-full py-3 px-7 text-deepPurple font-semibold text-lg'
							onClick={handleOff}
						>
							{t('turnOff')}
						</button>
						<button
							className='bg-deepPurple hover:bg-highlightColor rounded-full py-3 px-7 text-highlightColor hover:text-deepPurple font-semibold text-lg'
							onClick={handleContinue}
						>
							{t('continue')}
						</button>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default ScheduleSurveyModal;
