import React, { useState, useEffect } from 'react';
import '../../styles/feedsAndDashboard/FeedsAndDashboard.css';
import '../../styles/feedsAndDashboard/MyTask.css';
import { fileSystemUrl } from '../../constants/Connections';
import { format_day } from '../../utils/format_day';
import { getArchive } from '../../server/server';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QuestionIconNew } from '../../assets/svg/QuestionIconNew.svg';
import { ReactComponent as PollIconNew } from '../../assets/svg/PollIconNew.svg';
import { Oval } from 'react-loader-spinner';
import { format } from 'date-fns';
import { datesAreOnSameDay } from '../../utils/util';
import { emotions_heads } from '../../constants/Constants';
import BigImageModal from '../modals/BigImageModal';
import Loading from '../Loading';
import i18n from '../../i18n';
function ArchiveTasks({ user }) {
	const { t, locale } = useTranslation();
	const [archives, setArchives] = useState([]);
	const [resultsNum, setResultsNum] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [loading, setLoading] = useState(true);
	const [isEmpty, setIsEmpty] = useState(false);
	const [loadingImage, setLoadingImage] = useState(true);
	const [selectedImage, setSelectedImage] = useState('');
	const [imageModalOpen, setImageModalOpen] = useState(false);

	const closeImageModal = () => {
		setImageModalOpen(false);
		setSelectedImage('');
	};

	const openBigImage = (url) => {
		setSelectedImage(url);
		setImageModalOpen(true);
	};

	const handleImageLoading = () => {
		setLoadingImage(false);
	};

	const loadArchive = async () => {
		const r = await getArchive({
			user_id: user.id,
			number_of_archives: resultsNum,
			collection: 'answers',
		});
		console.log('archives', r.data);
		const { data, size } = r.data;
		if (data) {
			if (resultsNum === 0) {
				setLoading(true);
				if (data.length === 0) {
					setIsEmpty(true);
				}
				setArchives(data);
				setHasMore(size > archives.length);
			} else {
				setArchives((prev) => [...prev, ...data]);
				setHasMore(size > archives.length + data.length);
			}
			setLoading(false);
		} else {
			setArchives([]);
			setResultsNum(0);
		}
	};
	useEffect(() => {
		loadArchive();
		setLoading(true);
	}, [resultsNum]);
	return (
		<div
			id='scroll-inf-task'
			className='px-6 mb-5 grey-small-scroll overflow-y-scroll  xxs:max-h-[70vh] xl:max-h-[100vh] xl:h-0 xl:flex-auto'
		>
			{loading && (
				<div className='loading-center'>
					<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={75} stroke={4} />
				</div>
			)}
			{isEmpty && (
				<div className='empty-tasks'>
					<div>{t('congratsEverythingIsDoneForNowWaitForNewTasks')}</div>
				</div>
			)}
			<InfiniteScroll
				dataLength={archives.length}
				next={() => {
					setResultsNum(archives.length);
				}}
				hasMore={hasMore}
				scrollableTarget='scroll-inf-task'
				inverse={false}
			>
				{archives.map((archive, index) => {
					const time = format(new Date(archive.created_date), 'HH:mm');
					const url = `${fileSystemUrl}/${archive?.content?.files?.storage_name}?token=${user.token}`;
					const imgUrl = archive.content?.files ? url : false;
					const previousNote = index > 0 && archives[index - 1];
					const previousNoteDay = previousNote?.created_date;
					const sameDay = !!previousNoteDay && datesAreOnSameDay(new Date(archive.created_date), new Date(previousNoteDay));
					return (
						<div className='flex flex-col items-center ' key={archive.id}>
							{!sameDay && (
								<div className='mt-[15px] h-5 leading-5'>
									<span className='text-[14px] leading-5 font-medium text-mudPurple'>{t('date')}: </span>
									<span className='text-[14px] leading-5 font-medium text-darkPurple'>
										{format_day(archive.created_date, false, i18n.language)}
									</span>
								</div>
							)}
							{archive.content?.type === 'question' && (
								<div
									className={`w-full p-5 ${
										!sameDay ? 'mt-[15px]' : 'mt-[10px]'
									}  flex flex-col gap-[10px] bg-containerGray rounded-[10px]`}
									//onClick={() => changeSelectedMode('questionMode', task)}
								>
									<div className='w-full flex flex-row gap-[10px] '>
										{imgUrl != '' && (
											<div className='image-container-new cursor-pointer' onClick={() => openBigImage(imgUrl)}>
												{loadingImage && (
													<SkeletonTheme baseColor='#dadaff' highlightColor='#efefef'>
														<Skeleton />
													</SkeletonTheme>
												)}
												<img
													src={`${url}${url.includes('https') ? '&width=450' : ''}`}
													alt='postfeed'
													width={40}
													onLoad={handleImageLoading}
												/>
											</div>
										)}
										<div className='w-full'>
											<div className='flex flex-row items-center '>
												<QuestionIconNew />
												<p className='ml-[5px] text-[14px]  text-mudPurple'>{t('open_question')}</p>
												<div className='flex-1'></div>
												<div className='text-[14px]  text-mudPurple opacity-60 mr-[5px]'>{t('time')}: </div>
												<div className='text-[14px]  text-mudPurple'>{time}</div>
											</div>
											<div className='mt-[10px] text-[16px] font-bold text-darkPurple'>{archive.content?.title}</div>
										</div>
									</div>
									<div className='mt-[15px]'>
										{archive.answers?.map((option, index) => {
											return (
												<div
													className={`mb-[6px] rounded-[10px] flex items-center min-h-[40px] py-[10px] px-[20px] bg-lightBG`}
													key={index}
												>
													<p className={`text-[16px] font-medium  text-darkenPurple`}>{option}</p>
												</div>
											);
										})}
									</div>
								</div>
							)}
							{archive.content?.type === 'daily' && (
								<div
									className={`w-full p-5 ${
										!sameDay ? 'mt-[15px]' : 'mt-[10px]'
									}  flex flex-col gap-[10px] bg-containerGray rounded-[10px]`}
									//onClick={() => changeSelectedMode('questionMode', task)}
								>
									<div className='w-full flex flex-row gap-[10px] '>
										{archive.content?.emotion != '' && (
											<div>
												<img
													src={emotions_heads[archive.emotion]}
													alt='postfeed'
													className='min-w-[50px] h-[50px] '
												/>
											</div>
										)}
										<div className='w-full'>
											<div className='flex flex-row items-center '>
												<p className=' text-[14px]  text-mudPurple'>{t(archive.content?.title)}</p>
												<div className='flex-1'></div>
												<div className='text-[14px]  text-mudPurple opacity-60 mr-[5px]'>{t('time')}: </div>
												<div className='text-[14px]  text-mudPurple'>{time}</div>
											</div>
											<div className='mt-[10px] text-[16px] font-semibold text-darkPurple'>{archive.message}</div>
										</div>
									</div>
								</div>
							)}
							{archive.content?.type === 'poll' && (
								<div
									className={`w-full p-5 ${
										!sameDay ? 'mt-[15px]' : 'mt-[10px]'
									}  flex flex-col gap-[10px] bg-containerGray rounded-[10px]`}
									//onClick={() => changeSelectedMode('pollMode', task)}
								>
									<div className='w-full flex flex-row gap-[10px] '>
										{imgUrl != '' && (
											<div className='image-container-new cursor-pointer' onClick={() => openBigImage(imgUrl)}>
												{loadingImage && (
													<SkeletonTheme baseColor='#dadaff' highlightColor='#efefef'>
														<Skeleton />
													</SkeletonTheme>
												)}
												<img
													src={`${url}${url.includes('https') ? '&width=450' : ''}`}
													alt='postfeed'
													width={40}
													onLoad={handleImageLoading}
												/>
											</div>
										)}
										<div className='w-full'>
											<div className='flex flex-row items-center'>
												<PollIconNew />
												<p className='ml-[5px] text-[14px]  text-mudPurple'>{t('open_poll')}</p>
												<div className='flex-1'></div>
												<div className='text-[14px]  text-mudPurple opacity-60 mr-[5px]'>{t('time')}: </div>
												<div className='text-[14px]  text-mudPurple'>{time}</div>
											</div>
											<div className='mt-[10px] text-[16px] font-bold text-darkPurple'>{archive.content?.title}</div>
										</div>
									</div>
									<div className='mt-[15px]'>
										{archive.answers?.map((option, index) => {
											return (
												<div
													className={`mb-[6px] rounded-[10px] flex items-center h-[40px] pl-[20px] bg-deepPurple`}
													key={index}
												>
													<p className={`text-[16px] font-medium  text-highlightColor`}>{option}</p>
												</div>
											);
										})}
									</div>
								</div>
							)}
						</div>
					);
				})}
			</InfiniteScroll>
			<BigImageModal modalIsOpen={imageModalOpen} closeModal={closeImageModal} selectedImage={selectedImage} />
		</div>
	);
}

export default ArchiveTasks;
