export const env = process.env.REACT_APP_ENV || 'dev';

const urls = {
	local: {
		baseUrl: 'http://localhost:8080',
		socketUrl: 'http://localhost:5000',
		fileSystemUrl: 'https://files.upstrivesystem.com',
		reportSystemUrl: 'https://reports.upstrivesystem.com',
		videoUrl: 'https://videos.upstrivesystem.com',
	},
	dev: {
		baseUrl: 'https://api-dev.upstrivesystem.com',
		socketUrl: 'https://ws.upstrivesystem.com',
		fileSystemUrl: 'https://files.upstrivesystem.com',
		reportSystemUrl: 'https://reports.upstrivesystem.com',
		videoUrl: 'https://videos.upstrivesystem.com',
	},
	prod: {
		baseUrl: 'https://api.upstrivesystem.com',
		socketUrl: 'https://ws.upstrivesystem.com',
		fileSystemUrl: 'https://files.upstrivesystem.com',
		reportSystemUrl: 'https://reports.upstrivesystem.com',
		videoUrl: 'https://videos.upstrivesystem.com',
	},
	staging: {
		baseUrl: 'https://web-api-staging.upstrivesystem.com',
		socketUrl: 'https://ws-dev.upstrivesystem.com',
		fileSystemUrl: 'https://files.upstrivesystem.com',
		reportSystemUrl: 'https://reports.upstrivesystem.com',
		videoUrl: 'https://videos.upstrivesystem.com',
	},
};

export const baseUrl = urls[env].baseUrl;
export const socketUrl = urls[env].socketUrl;
export const fileSystemUrl = urls[env].fileSystemUrl;
export const reportSystemUrl = urls[env].reportSystemUrl;
export const videoUrl = urls[env].videoUrl;
