import React, { useEffect, useState, useRef } from 'react';
import '../../styles/settings/settings.css';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as HappyHandsLogo } from '../../assets/svg/HappyHandsLogo.svg';
import i18n from '../../i18n';

function LogoutModal({ modalIsOpen, closeModal, logout }) {
	const { t } = useTranslation();

	React.useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);
	return (
		<ReactModal
			transparent
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				document.getElementById('home').style.filter = 'unset';
			}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 3,
				},
				content: {
					inset: 0,
					padding: 0,
					margin: 0,
					marginTop: 50,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className='logout-modal-icon' onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
			</div>
			<div className='logout-modal-container'>
				<div className='logout-modal-body'>
					<div className='logout-modal-header'>
						<p>{t('logout')}?</p>
					</div>
					<div className='logout-modal-content'>
						<HappyHandsLogo />
						<div className='logout-buttons'>
							<div className='logout-cancel-button' onClick={() => closeModal()}>
								<p className='logout-buttons-text'>{t('cancel')}</p>
							</div>
							<div className='logout-ok-button' onClick={logout}>
								<p className='logout-buttons-text'>{t('logout')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default LogoutModal;
