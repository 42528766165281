import React, { useState } from 'react';
import '../../styles/videos/InstructionVideos.css';
// import MobileFeed1 from '../../assets/videos/MobileFeed1.png';
// import MobileCreate2 from '../../assets/videos/MobileCreateContent2.png';
// import DesktopFeed3 from '../../assets/videos/DesktopFeed3.png';
// import DesktopCreate4 from '../../assets/videos/DesktopCreateContent4.png';
// import DesktopClassroom5 from '../../assets/videos/DesktopClassroom5.png';
// import DesktopChat6 from '../../assets/videos/DesktopChat6.png';
// import InfoStaff7 from '../../assets/videos/InfoStaff7.png';
// import FullVideo from '../../assets/videos/FullTraining.png';
import VideoModal from '../modals/VideoModal';
import { ReactComponent as VideoIcon } from '../../assets/svg/PlayVideoIcon.svg';
import { useTranslation } from 'react-i18next';
function InstructionVideos() {
	const [selectedVideo, setSelectedVideo] = useState({});
	const [selectedIndex, setSelectedIndex] = useState();
	const [videoModalOpen, setVideoModalOpen] = useState(false);
	const { t, locale } = useTranslation();
	const videos = [
		{
			src: 'https://videos.upstrivesystem.com/NavigatingtheDashboard.mp4',
			// placeholder: DesktopChat6,
			title: 'Navigating the Dashboard',
		},
		{
			src: 'https://videos.upstrivesystem.com/NavigatingYourClassroom.mp4',
			// placeholder: DesktopClassroom5,
			title: 'Navigating Your Classroom',
		},
		{
			src: 'https://videos.upstrivesystem.com/Accessing&CreatingContent.mp4',
			// placeholder: DesktopChat6,
			title: 'Accessing & Creating Content',
		},
		{
			src: 'https://videos.upstrivesystem.com/UsingtheAnalyticsFunction.mp4',
			// placeholder: DesktopCreate4,
			title: 'Using the Analytics Function',
		},
		{
			src: 'https://videos.upstrivesystem.com/UsingtheWellbeingTracker.mp4',
			// placeholder: MobileFeed1,
			title: 'Using the Wellbeing Tracker',
		},
		{
			src: 'https://videos.upstrivesystem.com/UsingtheChatFunction.mp4',
			//placeholder: MobileCreate2,
			title: 'Using the Chat Function',
		},
		{
			src: 'https://videos.upstrivesystem.com/UsingtheCatch-upFunction.mp4',
			// placeholder: DesktopFeed3,
			title: 'Using the Catch-up Function',
		},
		{
			src: 'https://videos.upstrivesystem.com/StudentFullDesktopInstructionVideo.mp4',
			// placeholder: DesktopFeed3,
			title: 'Student Sign-In video',
		},
	];
	const openVideo = (video, index) => {
		setSelectedVideo(video);
		setSelectedIndex(index);
		setVideoModalOpen(true);
	};
	const closeVideo = () => {
		setVideoModalOpen(false);
	};
	const FullVideo = 'https://videos.upstrivesystem.com/StaffFullDesktopInstructionVideo.mp4';
	const FullMobileVideo = 'https://videos.upstrivesystem.com/40fc08af-9808-47fa-883d-76b60d5ef636.mp4';
	const VideoPlaceholder = 'https://files.upstrivesystem.com/public/VideoPlaceholder.png';

	return (
		<div className='w-full h-full p-10 bg-[#ECEBF8] overflow-y-scroll'>
			<h1 className='text-left my-10 text-darkPurple font-bold text-3xl'>{t('instructionVideos')} </h1>
			<div className='w-full flex flex-row gap-[50px]'>
				<div className='bg-white rounded-xl p-10 max-w-[500px] min-w-[500px]'>
					<div className='flex flex-row items-baseline mb-10 w-full'>
						<h2 className='text-left text-[#5E639B] font-bold text-xl leading-[25px] w-full' style={{ whiteSpace: 'pre-line' }}>
							{`Full Training Video (30-min)`}
						</h2>
					</div>
					<div
						className='p-8 pointer col-span-1 bg-containerGray rounded-xl flex items-center justify-center flex-col instruction-video-widget'
						onClick={(e) => {
							e.preventDefault();
							openVideo({ src: FullVideo, placeholder: FullVideo, title: 'Full Training' }, 8);
						}}
					>
						<div className='relative mt-2 mb-[20px] w-full'>
							<div className='instruction-image-placeholder absolute flex items-center justify-center rounded-xl w-full h-full t-0 l-0'>
								<VideoIcon />
							</div>
							<img width='400' src={VideoPlaceholder} className='rounded-xl w-full min-h-[230px]' alt='' />
						</div>
						<div className='text-left flex flex-col w-full'>
							<p className='font-bold text-darkPurple text-xl'>{t('Full Desktop version')} </p>
						</div>
					</div>
					<div
						className='instruction-video-widget p-8 pointer  col-span-1 bg-containerGray rounded-xl flex items-center justify-center flex-col mt-[35px]'
						onClick={(e) => {
							e.preventDefault();
							openVideo({ src: FullMobileVideo, placeholder: FullMobileVideo, title: 'Full Training' }, 8);
						}}
					>
						<div className='relative mt-2 mb-[20px] w-full'>
							<div className='instruction-image-placeholder absolute flex items-center justify-center rounded-xl w-full h-full t-0 l-0'>
								<VideoIcon />
							</div>
							<img width='400' src={VideoPlaceholder} className='rounded-xl w-full min-h-[230px]' alt='' />
						</div>
						<div className='text-left flex flex-col w-full'>
							<p className='font-bold text-darkPurple text-xl'>{t('Full Mobile version')} </p>
						</div>
					</div>
				</div>
				<div className='flex-1 bg-white rounded-xl p-10 flex justify-center flex-col items-center '>
					<div className='flex flex-row justify-center items-center mb-10 w-full'>
						<h2 className='text-left ml-2 text-deepPurple font-bold text-xl  w-full'>{t('individualFeaturesTraining')} </h2>
					</div>
					<div className='grid gap-4 w-full individual-video-grid'>
						{videos.map((video, index) => (
							<div
								className='instruction-video-widget p-[30px] pointer col-span-1 bg-containerGray rounded-[10px] flex items-center  flex-col'
								onClick={(e) => {
									e.preventDefault();
									openVideo(video, index);
								}}
								key={index}
							>
								<div className='relative mt-2 w-full'>
									<div className='instruction-image-placeholder absolute flex items-center justify-center rounded-xl w-full h-full t-0 l-0'>
										<VideoIcon />
									</div>
									<img
										width='400'
										src={VideoPlaceholder}
										className='rounded-xl w-full min-h-[230px] object-cover'
										alt=''
									/>
								</div>
								<div className='text-left flex flex-col w-full'>
									<p className='font-bold text-darkPurple text-xl mb-[16px] mt-[20px]'>{video.title}</p>
									<p className='text-left text-[#9392AD] text-sm'>
										{t('instructionVideosForStaff')} {index + 1}:
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<VideoModal video={selectedVideo} index={selectedIndex} modalIsOpen={videoModalOpen} closeModal={closeVideo} />
		</div>
	);
}

export default InstructionVideos;
