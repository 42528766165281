import React, { useEffect, useState } from 'react';
import DonutChartDash from '../dashboard/components/DonutChartDash';
import { ReactComponent as UpstriveEducationLogo } from '../../assets/svg/UpstriveEducationLogo.svg';
import Loading from '../Loading';
import { getAnalyticsDashboardStats } from '../../server/server';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIcon.svg';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ClassroomEngagementTrend from '../classroom/ClassroomEngagementTrend';
import { filterPeriods } from '../../constants/Constants';
import ClassroomEngagementTrendItem from '../classroom/ClassroomEngagementTrendItem';
import CustomReportsWidget from './CustomReportsWidget';
import { useUser } from '../../auth';

function AnalyticsDataRight({ setSelectedMode, selectContent = () => {} }) {
	const history = useHistory();
	const { user } = useUser();
	const [selectedPeriod, setSelectedPeriod] = useState(filterPeriods[0]);
	const [loading, setLoading] = useState(true);
	const { t } = useTranslation();
	const [statistics, setStatistics] = useState({
		contentResponse: 0,
		overall: 0,
		teachersLogins: 0,
		studentsLogins: 0,
	});
	useEffect(() => {
		// loadDailyStats();
		loadDashboardStats(selectedPeriod);
	}, [selectedPeriod]);

	const loadDashboardStats = async (selectedPeriod) => {
		setLoading(true);
		const resp = await getAnalyticsDashboardStats(selectedPeriod.code);
		if (resp.status === 200) {
			const data = resp.data;
			setStatistics({
				overall: data.overall,
				contentResponse: data.contentResponse,
				studentsLogins: data.studentsLogin,
				teachersLogins: data.teachersLogin,
			});
			setLoading(false);
		}
	};

	const navigateBasics = (type) => history.push(`/analytics/data?type=${type}`);

	return (
		<div className='analytics-data-right'>
			{
				<div className='mb-[30px]'>
					<CustomReportsWidget />
				</div>
			}
			<div className='flex flex-row justify-between items-center mb-[30px]'>
				<span className=' text-darkenPurple font-bold text-[20px]'>{t('Time frame')}: </span>
				<div className='period-buttons-container flex-1 justify-end '>
					{filterPeriods?.map((period, index) => {
						return (
							<div
								key={period.code}
								className={`period-items ${selectedPeriod.code === period.code ? 'selected' : ''}`}
								onClick={() => setSelectedPeriod(period)}
							>
								{t(period.name)}
							</div>
						);
					})}
				</div>
			</div>
			<div className='flex flex-col p-[35px] rounded-[10px] bg-white '>
				<div className=' flex flex-row items-center justify-between'>
					<div className='flex flex-col justify-center'>
						<div className='flex flex-row items-center '>
							<p className='text-[20px] leading-5 text-darkenPurple font-bold'>{t('howAreYouToday')} </p>
						</div>
						<p className='text-[12px] leading-5 text-deselectPurple font-medium mt-[5px]'>
							{t('getDeeperInsightsIntoYourStudentsEmotionalTrends')}
						</p>
					</div>

					<div className='flex-1'></div>
					<Link to={`analytics/data?type=data&content=how-do-you-feel`}>
						<div className='h-[50px] px-[20px]  bg-deepPurple hover:bg-highlightColor text-highlightColor hover:text-deepPurple rounded-full flex items-center justify-center cursor-pointer ease-in-out duration-300'>
							<p className='text-[16px] leading-5 font-bold'>{t('getInsights')} </p>
						</div>
					</Link>
				</div>
				<div className='h-[1px] bg-darkenPurple my-[14px] '></div>
				<ClassroomEngagementTrend
					className='p-0 -mb-[20px] bg-white gap-6'
					period={selectedPeriod.code}
					smallTitleText
					hideOverall
				/>
			</div>

			<div className='flex flex-col p-[35px] rounded-[10px] bg-white mt-[30px] '>
				<p className='text-[20px] leading-5 text-darkenPurple font-bold'>{t('General Engagement')} </p>
				<div className='grid grid-cols-2 gap-6 mt-[25px]'>
					<ClassroomEngagementTrendItem
						title={t('Overall engagement')}
						trendPlaceholder={t(`Relative change to the same selected previous period`)}
						hoverPlaceholder={t(`Percentage of students that used Upstrive in the period (any function, just opened it)`)}
						smallTitleText={true}
						loading={loading}
						percentage={statistics?.overall?.percentage ?? -1}
						trend={statistics?.overall?.trend ?? -1}
					/>
					<ClassroomEngagementTrendItem
						title={t('Engagement with content')}
						trendPlaceholder={t(`Relative change to the same selected previous period`)}
						hoverPlaceholder={t(`Percentage of students engaging with content in the selected time frame`)}
						smallTitleText={true}
						loading={loading}
						percentage={statistics.contentResponse}
						showTrend={false}
					/>
				</div>
			</div>
			<div className='flex flex-col p-[35px] rounded-[10px] bg-white mt-[30px] '>
				<p className='text-[20px] leading-5 text-darkenPurple font-bold'>{t('Registration')} </p>
				<div className='grid grid-cols-2 gap-6 mt-[25px]'>
					<ClassroomEngagementTrendItem
						title={t('Registered teachers')}
						trendPlaceholder={t(`Relative change to the same selected previous period`)}
						hoverPlaceholder={t(`Percentage of teachers in your group who have registered in Upstrive`)}
						smallTitleText={true}
						loading={loading}
						percentage={statistics.teachersLogins}
						showTrend={false}
					/>
					<ClassroomEngagementTrendItem
						title={t('registeredStudents')}
						trendPlaceholder={t(`Relative change to the same selected previous period`)}
						hoverPlaceholder={t(`Percentage of students in your group who have registered in Upstrive`)}
						smallTitleText={true}
						loading={loading}
						percentage={statistics.studentsLogins}
						showTrend={false}
					/>
				</div>
			</div>
		</div>
	);
}

export default AnalyticsDataRight;
