import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { emotions_heads } from '../../../../constants/Constants';
import { getActionRequiredStats, getNumberOfNegativeEmotions } from '../../../../server/server';
import { Oval } from 'react-loader-spinner';
import Loading from '../../../Loading';
import AssignedActionsToMeModal from '../../../modals/AssignedActionsToMeModal';
import { useSocket } from '../../../../context/socket';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

function Actions() {
	const { t, locale } = useTranslation();
	let history = useHistory();
	const [emotions, setEmotions] = useState(['grumpy', 'stressed', 'angry', 'sad', 'worried', 'lethargic', 'numb']);
	const [loading, setLoading] = useState(true);
	const { socketStates } = useSocket();
	const [assignedActionsModalVisible, setAssignedActionModalVisible] = useState(false);
	const [stats, setStates] = useState({
		assignedActions: 0,
		studentChat: 0,
		negativeStudentComments: 0,
	});
	const [numberOfNegativeEmotions, setNegativeEmotions] = useState(0);

	useEffect(() => {
		getRequiredActionsStats();
		loadNegativeEmotions();
	}, []);

	const getRequiredActionsStats = async () => {
		const resp = await getActionRequiredStats();
		if (resp.status == 200) {
			setStates(resp.data);
			setLoading(false);
		}
	};
	const loadNegativeEmotions = async () => {
		const resp = await getNumberOfNegativeEmotions();
		if (resp.status == 200) {
			setNegativeEmotions(resp.data);
			console.log(resp.data);
		}
	};

	const closeModal = () => {
		setAssignedActionModalVisible(false);
	};
	const openModal = () => {
		setAssignedActionModalVisible(true);
	};

	return (
		<div className='action-widget-card bg-white rounded-[10px] w-full h-full p-[35px]'>
			<p className='text-[#413F5E] font-bold text-[16px] mb-5px]'>{t('actionRequired')} </p>
			<p className='text-[#413F5E] font-[500] text-[14px] '>{t('stayUpToDateWithStudentSupport')} </p>
			<div className='flex w-full gap-4 h-[162px] mt-5'>
				<div
					className='rounded-[10px] h-full w-[190px] flex flex-col justify-between pointer p-[20px]'
					style={{ background: '#5E639B' }}
					onClick={() => history.push('/classroom?emotion=comments')}
				>
					<div className='flex flex-row justify-around items-start'>
						<div
							className='rounded-full bg-containerGray !w-[40px] h-[40px] flex items-center justify-center text-sm font-bold text-deepPurple mr-[10px] !cursor-help'
							data-tip={t(`Number of students that left a comment combined with uncomfortable emotion`)}
							data-for={`comments`}
						>
							<ReactTooltip
								id={`comments`}
								arrowColor='#5E639B'
								effect='solid'
								backgroundColor='#5E639B'
								className='wellbeing-tooltip'
							/>
							{loading ? <Loading primaryColor='#545887' /> : numberOfNegativeEmotions}
						</div>
						<div
							className={`text-white text-[16px]  leading-[19px] font-bold text-left flex-1 justify-start ${
								i18n.language == 'de' ? '-mt-[10px]' : ''
							}`}
						>
							{t('Comments for review')}{' '}
						</div>
					</div>
					<div className={`rounded-[10px] flex flex-col justify-center items-center ${i18n.language == 'de' ? '-mb-2' : ''}`}>
						<div className='flex flex-row justify-center items-center gap-2'>
							{emotions?.slice(3)?.map((emotion, index) => {
								return (
									<div
										data-tip={`Well done, % of students with \n positive emotion increased by 3%`}
										data-for='positiveEmotion'
										key={index}
									>
										<img src={emotions_heads[emotion]} alt='emotion' className='w-[30px] h-[30px] mx-1' />
									</div>
								);
							})}
						</div>
						<div className='flex flex-row justify-center items-center gap-2 mt-2'>
							{emotions?.slice(0, 3)?.map((emotion, index) => {
								return (
									<div
										data-tip={`Well done, % of students with \n positive emotion increased by 3%`}
										data-for='positiveEmotion'
										key={index}
									>
										<img src={emotions_heads[emotion]} alt='emotion' className='w-[30px] h-[30px] mx-1 ' />
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div className='w-[52%] flex flex-col gap-[14px] h-full'>
					<button
						className='pointer flex flex-row justify-between items-center hover:bg-lightBG bg-containerGray rounded-xl pr-[25px] pl-[17px] py-[17px] h-[50%] duration-[230ms] ease-in-out transition-all'
						onClick={openModal}
					>
						<span className=' text-deepPurple font-bold text-[16px] leading-5 text-left'>
							{/* Actions <br /> assigned to me */}
							{t('actionsAssignedToMe')}
						</span>
						<div className=' text-highlightColor font-bold text-sm bg-deepPurple border border-white p-4 rounded-[50%] w-[14px] h-[14px] justify-center items-center flex'>
							{loading ? <Loading /> : socketStates?.assignedFeedbacks?.length || 0}
						</div>
					</button>
					<button
						onClick={() => history.push('/chat')}
						className='pointer flex flex-row justify-between items-center hover:bg-lightBG bg-containerGray rounded-xl pr-[25px] pl-[17px] py-[17px] h-[50%] duration-[230ms] ease-in-out transition-all'
					>
						<span className=' text-deepPurple font-bold text-[16px]'>{t('studentChat')} </span>
						<div className=' text-highlightColor font-bold text-sm bg-deepPurple border border-white p-4 rounded-[50%] w-[14px] h-[14px] justify-center items-center flex'>
							{loading ? <Loading /> : socketStates?.unseenConversations?.length || 0}
						</div>
					</button>
				</div>
			</div>
			<AssignedActionsToMeModal modalIsOpen={assignedActionsModalVisible} closeModal={closeModal} />
		</div>
	);
}

export default Actions;
