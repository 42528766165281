import React, { useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useUser } from '../../auth';
import { useTranslation } from 'react-i18next';
import { checkTextStrength, notify } from '../../utils/util';
import { useForm } from 'react-hook-form';

export default function EnterNewPassword(props) {
	const [errorMessage, setErrorMessage] = useState('');
	const { setUser } = useUser();
	const password = props.password;
	const retypedPassword = props.retypedPassword;
	const { t, locale } = useTranslation();

	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm({
		defaultValues: {
			password: '',
			repeatPassword: '',
		},
	});

	const onSubmit = async () => {
		const resp = await props.onSubmit(getValues('password'));
		if (resp.status === 200) {
			const user = resp.data;
			setUser(user);
			console.log('success enter new password');
		} else {
			const error = resp?.data?.message || resp.data;
			setErrorMessage(error);
		}
	};

	const handleBack = () => {
		props.setPassword('');
		props.setRetypedPassword('');
		props.setAuthSection('login');
	};

	return (
		<div className='new-password-container'>
			<div className='modal-title'>
				<p className='text-3xl text-white text-left font-bold'>{t('newPassword')}</p>
			</div>
			<div className='text-left text-deselectPurple text-sm mt-2' style={{ width: 'unset' }}>
				<p>{t('passwordMustBeLongerThan8CharactersAndMustContainsUppercaseLowercaseNumberAndSpecialSymbol')}</p>
			</div>
			<div className='sign-up-section' style={{ marginTop: '15px' }}>
				<div className=''>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className=''>
							<span className='text-white text-lg font-semibold text-left flex justify-start'>{t('password')}</span>
							<input
								className='w-full text-white border border-b-deselectPurple py-3 border-x-0 border-t-0'
								placeholder={t('password')}
								style={{ backgroundColor: 'transparent' }}
								// type="email"
								type='password'
								{...register('password', {
									required: true,
									pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_]).{8,}/,
								})}
								onChange={(e) => {
									setValue('password', e.target.value.toString(), { shouldValidate: true });
									//props.setPassword(e.target.value);
									if (getValues('repeatPassword') !== '') trigger('repeatPassword');
								}}
							/>
							<p className='error-message text-[12px] leading-5 min-h-[20px] '>
								{errors.password?.type === 'required' ? t('youMustFillData') : ''}
								{errors.password?.type === 'pattern'
									? t('passwordMustBeLongerThan8CharactersAndMustContainsUppercaseLowercaseNumberAndSpecialSymbol')
									: ''}
							</p>
						</div>
						<div className=''>
							<span className='text-white text-lg font-semibold text-left flex justify-start'>{t('repeatPassword')}</span>
							<input
								className='w-full text-white border border-b-deselectPurple py-3 border-x-0 border-t-0'
								placeholder={t('repeatPassword')}
								style={{ backgroundColor: 'transparent' }}
								// type="email"
								type='password'
								{...register('repeatPassword', {
									validate: {
										obavezno: (value) => value.length > 0,
										corect: (val) => getValues('password') === val,
									},
								})}
								onChange={(e) => setValue('repeatPassword', e.target.value.toString(), { shouldValidate: true })}
							/>
							<p className='error-message text-[12px] leading-5 h-5 '>
								{errors.repeatPassword?.type === 'obavezno' ? t('youMustFillData') : ''}
								{errors.repeatPassword?.type === 'corect' ? t('passwordsDoNotMatch') : ''}
							</p>
						</div>
						{}

						<div className='error-message'>{errorMessage}</div>
						<div className='login-form-element-holder px-6 py-3'>
							<button className='auth-form-button text-deepPurple'>{t('submitNewPassword')}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
