import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ReactComponent as Filter } from '../../assets/svg/filter.svg';
import ClassroomTrend from './ClassroomTrend';
import PrimaryClassesHeader from './PrimaryClassesHeader';
import '../../styles/classroom/ClassroomHome.css';
import { ReactComponent as UpGreenIcon } from '../../assets/svg/UpGreenIcon.svg';
import { ReactComponent as DownGrayIcon } from '../../assets/svg/DownGrayIcon.svg';
import { ReactComponent as LeftButton } from '../../assets/svg/LeftButton.svg';
import { ReactComponent as Calendar } from '../../assets/svg/Calendar.svg';
import { ReactComponent as Dropdown } from '../../assets/svg/dropdown.svg';
import { ReactComponent as DropdownTransparent } from '../../assets/svg/DropdownTransparent.svg';
import { ReactComponent as AngleUp } from '../../assets/svg/AngleUp.svg';
import { ReactComponent as CommentIcon } from '../../assets/svg/CommentIcon.svg';
import DonutChartClassroom from './DonutChartClassroom';
import { useUser } from '../../auth';
import { getFavoriteGroupsAsync, getStudentDailyQuestionUsage, get_my_class } from '../../server/server';
import 'react-datepicker/dist/react-datepicker.css';
import {
	emotions_color,
	emotions_list,
	emotions_heads,
	emotions_color_opacity_03,
	emotions_color_dark,
	filterPeriods,
	filterList,
	localeMap,
	primary_emotions_list,
} from '../../constants/Constants';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FeedBackModal from '../modals/FeedBackModal';
import BarChartClassroom from './BarChartClassroom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { formatDate, format_day, format_day_only, notify } from '../../utils/util';
import ReactTooltip from 'react-tooltip';
import DonutChartDash from '../dashboard/components/DonutChartDash';
import Loading from '../Loading';
import { isSameDay } from 'date-fns';
import { ViewportList } from 'react-viewport-list';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function ClassroomHome({ ...props }) {
	const scrollRef = useRef();
	const { t } = useTranslation();
	const ref = useRef(null);
	const query = new URLSearchParams(props.location.search);
	const emotionParam = query.get('emotion');
	const { user } = useUser();
	const [loading, setLoading] = useState(true);
	const [initLoading, setInitLoading] = useState(true);
	const [myStudents, setMyStudents] = useState([]);
	const [myVisibleStudents, setMyVisibleStudents] = useState([]);
	const [answers, setAnswers] = useState([]);
	const [resultDataChart, setResultDataChart] = useState([]);
	const [maxDate, setMaxDate] = useState(new Date());
	const [displayMode, setDisplayMode] = useState(user.hasOnlyPrimaryStudents ? 'primary' : 'regular');
	const [startDate, setStartDate] = useState(new Date());
	const [selectedEmotion, setSelectedEmotion] = useState(!!emotionParam ? emotionParam : 'Emotions');
	const [selectedLabels, setSelectedLabels] = useState(
		!!emotionParam
			? emotionParam === 'comments'
				? emotions_list.slice(5)
				: [emotionParam]
			: user.hasOnlyPrimaryStudents
			? primary_emotions_list
			: emotions_list
	);
	const [showCommentLabel, setShowContentLabel] = useState(emotionParam === 'comments');
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [opacity, setOpacity] = useState('66');
	const [maxValue, setMaxValue] = useState(0);
	const [selectedStudent, setSelectedStudent] = useState({});
	const [openStudentFeedback, setOpenStudentFeedback] = useState(false);

	const [showDropdown, setShowDropdown] = useState(false);
	const [searchText, setSearchText] = useState('');
	const searchRef = useRef();
	const [showTargetGroup, setShowTargetGroup] = useState(false);

	const [filterYears, setFilterYears] = useState([]);
	const [classesOfYear, setClassesOfYear] = useState({});

	const [selectedYearList, setSelectedYearList] = useState(null);
	const [favoritesList, setFavoritesList] = useState([]);
	const [selectedYearFilter, setSelectedYearFilter] = useState('all');
	const [selectedClassFilter, setSelectedClassFilter] = useState('all');
	const [loadingFilter, setLoadingFilter] = useState(false);
	const [loadingStaffFilter, setLoadingStaffFilter] = useState(false);
	const [numberOfStudentsWhoAnswered, setNumberOfStudentsWhoAnswered] = useState(0);

	const [showStudents, setShowStudents] = useState(true);
	const [teachersList, setTeachersList] = useState([]);
	const [myVisibleTeachersList, setMyVisibleTeachersList] = useState([]);
	const isRegularDisplayMode = displayMode === 'regular';

	const listOfEmotions = isRegularDisplayMode ? emotions_list : primary_emotions_list;

	useEffect(() => {
		loadMyClass(Date.now());
	}, [displayMode]);

	const numberOfEmotionsInRow = useMemo(() => Math.floor((ref?.current?.clientWidth ?? 10000) / 220) ?? 6, [ref?.current?.clientWidth]);
	const reduce = numberOfEmotionsInRow ?? 6;

	const loadMyClass = async (display_day, query = {}, userTargetType = 'student', isPrimary = !isRegularDisplayMode) => {
		let data = {
			user: user,
			number_of_students: 0,
			day: Date.parse(display_day),
			day_code: formatDate(new Date(display_day)),
			query,
		};
		if (isPrimary) data['primaryClassesOnly'] = true;
		const resp = await get_my_class(data);
		if (resp.status === 200) {
			const data = resp.data;
			if (userTargetType !== 'student') {
				let _teachersList = data.my_teachers;
				setTeachersList(data?.my_teachers || []);
				setShowStudents(false);
				let _answers = _teachersList.map((teacher) => teacher.feedback.emotion);
				const result_data = await Promise.all(
					emotions_list.map(async (e, index) => {
						return {
							label: e,
							value: _answers.filter((k) => k == e).length,
							color:
								!!emotionParam && emotionParam === 'comments' && index >= 4
									? emotions_color[e] + opacity
									: emotions_color[e],
							selected: !!emotionParam && emotionParam === 'comments' && index >= 4,
						};
					})
				);
				filterTeacherData(selectedLabels, result_data, _teachersList);
			} else {
				setFilterYears(data['filter_years']);
				setClassesOfYear(data['filter_data']);
				if (selectedYearList === null) setSelectedYearList(data['filter_data']['all']);
				let _my_students = data['my_class'];
				let _answers = _my_students
					.map((_class) => _class['data']?.map((e) => e.feedback.emotion))
					.flat()
					.filter((e) => e);
				setNumberOfStudentsWhoAnswered(_answers?.length);
				const result_data = await Promise.all(
					listOfEmotions.map(async (e, index) => {
						return {
							label: e,
							value: _answers.filter((k) => k == e).length,
							color:
								!!emotionParam && emotionParam === 'comments' && index >= 4
									? emotions_color[e] + opacity
									: emotions_color[e],
							selected: !!emotionParam && emotionParam === 'comments' && index >= 4,
						};
					})
				);
				filterData(selectedLabels, result_data, _my_students);
				setMyStudents(_my_students);
				setAnswers(_answers);
				setShowStudents(true);
				let tempValues = result_data.map((item) => item.value);
				let tempMax = Math.max(...tempValues);
				setMaxValue(tempMax);
			}
			setLoadingStaffFilter(false);
			setLoading(false);
			setInitLoading(false);
		}
	};

	const changeDate = (date) => {
		setLoading(true);
		if (date === null && date === undefined) return;
		setStartDate(date);
		let query = {};
		if (selectedYearFilter !== '' && selectedClassFilter !== '') {
			if (selectedYearFilter != 'all') {
				query['year'] = selectedYearFilter;
			}
			if (selectedClassFilter != 'all') {
				query['class'] = selectedClassFilter;
			}
		}
		loadMyClass(date, query);
	};
	const showPreviousDay = () => {
		setLoading(true);
		if (startDate === null && startDate === undefined) return;
		let day = 60 * 60 * 24 * 1000;

		let endDate = new Date(startDate.getTime() - day);
		setStartDate(endDate);
		let query = {};
		if (selectedYearFilter !== '' && selectedClassFilter !== '') {
			if (selectedYearFilter != 'all') {
				query['year'] = selectedYearFilter;
			}
			if (selectedClassFilter != 'all') {
				query['class'] = selectedClassFilter;
			}
		}
		loadMyClass(endDate, query);
	};
	const showNextDay = () => {
		setLoading(true);
		let day = 60 * 60 * 24 * 1000;
		if (startDate === null || startDate === undefined || startDate.getTime() + day - 10000 >= maxDate.getTime()) return;
		let endDate = new Date(startDate.getTime() + day);
		setStartDate(endDate);
		let query = {};
		if (selectedYearFilter !== '' && selectedClassFilter !== '') {
			if (selectedYearFilter != 'all') {
				query['year'] = selectedYearFilter;
			}
			if (selectedClassFilter != 'all') {
				query['class'] = selectedClassFilter;
			}
		}
		loadMyClass(endDate, query);
	};
	const handleSelectedLabel = (label) => {
		if (selectedEmotion != 'Emotions') setSelectedEmotion('Emotions');
		if (showStudents) {
			let newClasses = myVisibleStudents.map((_class) => {
				_class.collapse = false;
				return _class;
			});
			setMyVisibleStudents(newClasses);
		}
		let selectedEmotions = selectedLabels;
		if (selectedLabels.length === listOfEmotions.length) {
			selectedEmotions = [];
		}
		if (selectedEmotions.includes(label)) {
			//remove it
			var index = selectedEmotions.indexOf(label);
			if (index !== -1) {
				selectedEmotions.splice(index, 1);
			}
			if (selectedEmotions.length == 0) selectedEmotions = listOfEmotions;
		} else {
			selectedEmotions.push(label);
		}
		setSelectedLabels(selectedEmotions);
		setShowContentLabel(false);
		if (showStudents) filterData(selectedEmotions, resultDataChart, myStudents, 'Emotions');
		else filterTeacherData(selectedEmotions);
	};

	const filterTeacherData = (selectedEmotions, data = resultDataChart, teachers = teachersList) => {
		const _resultDataChart = data.map((e) => {
			const _opacity = selectedEmotions.includes(e.label.toLowerCase()) ? '' : opacity;
			return { ...e, color: emotions_color[e.label.toLowerCase()] + _opacity };
		});
		setResultDataChart(_resultDataChart);
		if (selectedEmotions.length == listOfEmotions.length) {
			setMyVisibleTeachersList(teachers);
		} else {
			const my_teachers = teachers
				?.map((teacher) => {
					if (selectedEmotions.includes(teacher?.feedback?.emotion)) return teacher;
				})
				?.filter((e) => !!e);
			setMyVisibleTeachersList(my_teachers);
		}
	};

	const filterData = (selectedEmotions, data = resultDataChart, students = myStudents, selectedLabel = selectedEmotion) => {
		const _resultDataChart = data.map((e) => {
			const _opacity = selectedEmotions.includes(e.label.toLowerCase()) ? '' : opacity;
			return { ...e, color: emotions_color[e.label.toLowerCase()] + _opacity, selected: _opacity === '' };
		});
		setResultDataChart(_resultDataChart);
		if (selectedEmotions.length == listOfEmotions.length) {
			setMyVisibleStudents(students);
		} else if (selectedLabel === 'comments') {
			const my_students = students
				?.map((_class) => {
					const emptyClass =
						_class.data?.filter(
							(student) =>
								student.feedback?.message !== '' &&
								!student?.feedback?.empty_data &&
								selectedEmotions.includes(student.feedback?.emotion)
						).length === 0;
					if (emptyClass) return null;
					const ret = {
						..._class,
						data: _class.data?.filter(
							(student) =>
								student.feedback?.message !== '' &&
								!student?.feedback?.empty_data &&
								selectedEmotions.includes(student.feedback?.emotion)
						),
					};
					return ret;
				})
				?.filter((e) => !!e);
			setMyVisibleStudents(my_students);
		} else {
			const my_student = students
				?.map((_class) => {
					const emptyClass = _class.data?.filter((s) => selectedEmotions.includes(s.feedback?.emotion)).length == 0;
					if (emptyClass) return null;
					const ret = {
						..._class,
						data: _class.data?.filter((s) => selectedEmotions.includes(s.feedback?.emotion)),
					};
					return ret;
				})
				?.filter((e) => !!e);
			setMyVisibleStudents(my_student);
		}
	};

	const handleSelectedLabelDropdown = (label) => {
		// let newClasses = myVisibleStudents.map((_class, i) => {
		// 	_class.collapse = false;
		// 	return _class;
		// });
		// setMyVisibleStudents(newClasses);
		setSelectedLabels(label);
		const _resultDataChart = resultDataChart.map((e) => {
			const _opacity = label.includes(e.label.toLowerCase()) ? '' : opacity;
			return { ...e, color: emotions_color[e.label.toLowerCase()] + _opacity };
		});
		setResultDataChart(_resultDataChart);
		if (showStudents) {
			const my_student = myStudents
				.map((_class) => {
					const emptyClass = _class.data.filter((s) => label.includes(s.feedback.emotion)).length == 0;
					if (emptyClass) return null;
					const ret = {
						..._class,
						data: _class.data.filter((s) => label.includes(s.feedback.emotion)),
					};
					return ret;
				})
				.filter((e) => !!e);
			setMyVisibleStudents(my_student);
		} else {
			const my_teachers = teachersList
				?.map((teacher) => {
					if (label.includes(teacher?.feedback?.emotion)) return teacher;
				})
				?.filter((e) => !!e);
			setMyVisibleTeachersList(my_teachers);
		}
	};

	const handleSelectedEmotions = (label) => {
		if (label == 'All emotions') {
			showStudents ? setMyVisibleStudents(myStudents) : setMyVisibleTeachersList(teachersList);
			const _resultDataChart = resultDataChart.map((e) => {
				return { ...e, color: emotions_color[e.label.toLowerCase()] };
			});
			setResultDataChart(_resultDataChart);
			setSelectedLabels(listOfEmotions);
			setSelectedEmotion(label);
		} else if (label === 'Uncomfortable') {
			handleSelectedLabelDropdown(listOfEmotions.slice(4, 12));
			setSelectedEmotion(label);
		} else {
			handleSelectedLabelDropdown([label]);
			setSelectedEmotion(label);
		}
		setShowContentLabel(false);
	};

	const openStudent = (student) => {
		setSelectedStudent(student);
		setOpenStudentFeedback(true);
	};
	const closeStudentFeedback = () => {
		setOpenStudentFeedback(false);
	};

	const myFilter = (item, text) => {
		return (
			item?.user?.first_name?.toLowerCase().includes(text.toLowerCase()) ||
			item?.user?.last_name?.toLowerCase().includes(text.toLowerCase())
		);
	};

	const searchStudents = (e) => {
		e.preventDefault();
		setSearchText(e.target.value);
		if (showStudents) {
			let newStudentsList = [];
			myStudents.forEach((item) => {
				let newData = item.data.filter((element) => myFilter(element, e.target.value));
				newStudentsList.push({ ...item, data: newData });
			});
			filterData(selectedLabels, resultDataChart, newStudentsList);
		} else {
			let newTeacherList = [];
			teachersList.forEach((teacher) => {
				if (myFilter(teacher, e.target.value)) newTeacherList.push(teacher);
			});
			filterTeacherData(selectedLabels, resultDataChart, newTeacherList);
		}
	};

	const selectYearFilter = (year) => {
		setSelectedClassFilter('all');
		setSelectedYearFilter(year);
		setSelectedYearList(classesOfYear[year]);
	};

	const selectAllClasses = () => {
		setSelectedClassFilter('all');
	};

	const applyFilter = async () => {
		let data = {};
		if (selectedYearFilter != 'all') {
			data['year'] = selectedYearFilter;
		} else {
			delete data['year'];
		}
		if (selectedClassFilter != 'all') {
			data['class'] = selectedClassFilter;
		} else {
			delete data['class'];
		}
		if (data['year'] === '' || data['class'] === '') {
			notify('Filter is not set!');
			return;
		}
		setLoadingFilter(true);
		await loadMyClass(startDate, data);
		setLoadingFilter(false);
	};

	const applyStaffFilter = async () => {
		let query = {
			class: 'staff',
		};
		setLoadingStaffFilter(true);
		await loadMyClass(startDate, query, 'teacher');
		setLoadingStaffFilter(false);
	};

	const isNextButtonDisabled = isSameDay(startDate, Date.now());

	const openRegularMode = () => {
		setSelectedLabels(emotions_list);
		setLoading(true);
		setSelectedYearList(null);
		setDisplayMode('regular');
	};
	const openPrimaryMode = () => {
		setSelectedLabels(primary_emotions_list);
		setLoading(true);
		setSelectedYearList(null);
		setDisplayMode('primary');
	};

	return (
		<div className='classroom-home-container' ref={scrollRef}>
			<div className='max-w-[1522px] m-auto'>
				{!!user?.hasPrimaryClasses && !user.hasOnlyPrimaryStudents && (
					<div className='flex flex-row gap-[20px] mb-[38px] items-center'>
						<p className='text-[#9392AD] font-semibold text-[18xp]'>{t('Display')}: </p>
						<div className='flex flex-row bg-[#FFFFFF] rounded-full'>
							<button
								className={`h-[50px] px-[25px] rounded-full justify-center items-center font-bold ${
									isRegularDisplayMode ? 'bg-[#5E639B] text-[#FDDF6D]' : 'text-[#9392AD]'
								}`}
								onClick={openRegularMode}
								disabled={isRegularDisplayMode}
							>
								{t('Regular')}
							</button>
							<button
								className={`h-[50px] px-[25px] rounded-full justify-center items-center font-bold ${
									!isRegularDisplayMode ? 'bg-[#5E639B] text-[#FDDF6D]' : 'text-[#9392AD]'
								}`}
								onClick={openPrimaryMode}
								disabled={!isRegularDisplayMode}
							>
								{t('Primary')}
							</button>
						</div>
					</div>
				)}
				{isRegularDisplayMode ? (
					<ClassroomTrend />
				) : (
					<PrimaryClassesHeader
						changeDate={changeDate}
						isDatePickerOpen={isDatePickerOpen}
						isNextButtonDisabled={isNextButtonDisabled}
						setIsDatePickerOpen={setIsDatePickerOpen}
						showNextDay={showNextDay}
						showPreviousDay={showPreviousDay}
						startDate={startDate}
					/>
				)}
				<div className='classroom-chart-container'>
					<div className='classroom-chart-header'>
						<p className='classroom-chart-header-p'>{t('howAreYouToday')}</p>
						{isRegularDisplayMode && (
							<div className='classroom-datepicker-select' style={{ position: 'relative' }}>
								<button className='left-button-picker' onClick={showPreviousDay}>
									<LeftButton />
								</button>
								<div className='classroom-datepicker-button not-selectable' onClick={() => setIsDatePickerOpen(true)}>
									<Calendar style={{ color: '#ffffff' }} />
									<p>{format_day_only(startDate)}</p>
								</div>
								<button className='left-button-picker' onClick={showNextDay} disabled={isNextButtonDisabled}>
									<LeftButton style={{ transform: 'scaleX(-1)' }} />
								</button>
								<div style={{ position: 'absolute', top: 0, left: 0, visibility: 'hidden' }}>
									<LocalizationProvider adapterLocale={localeMap[i18n.language]} dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											maxDate={maxDate}
											open={isDatePickerOpen}
											onClose={() => setIsDatePickerOpen(false)}
											label='Date desktop'
											inputFormat='MM/dd/yyyy'
											value={startDate}
											onChange={changeDate}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</div>
							</div>
						)}
						<div style={{ flex: 1 }}>
							<Link to={`analytics/data?type=data&content=how-do-you-feel`}>
								<div className='font-semibold text-[#5e639b] ease-out transition-all duration-200 bg-[#FAFAFF] min-h-[40px] px-[20px] rounded-full items-center justify-center flex border-[#bebece] border hover:border-[#5e639b] hover:bg-lightBG text-center'>
									{t('More details')}
								</div>
							</Link>
						</div>
						<div className='classroom-student-search'>
							<input
								type='text'
								placeholder={showStudents ? t('searchForStudent') : t('searchForTeacher')}
								value={searchText}
								onChange={searchStudents}
								autoComplete='off'
								ref={searchRef}
							/>
						</div>
						<div
							className='emotions-dropdown-container not-selectable'
							style={{ height: 40 }}
							onMouseLeave={() => setShowDropdown(false)}
						>
							<div
								className='classroom-emotions-dropdown bg-[#FAFAFF] hover:bg-lightBG'
								onClick={(e) => {
									e.preventDefault();
									setShowDropdown(!showDropdown);
								}}
								style={{
									zIndex: 6,
								}}
							>
								<p style={{ textTransform: 'capitalize' }}>{t(selectedEmotion)}</p>
								<div style={{ transform: showDropdown ? 'scaleY(-1)' : 'none' }}>
									<DropdownTransparent style={{ color: '#5E639B' }} />
								</div>
							</div>
							{showDropdown && (
								<div className='absolute top-0 flex flex-col w-full bg-lightBG rounded-[28px] cursor-pointer z-[5] pr-1'>
									<div className='w-full overflow-auto h-[330px] mt-10 mb-5 blue-small-scroll'>
										<p
											className='text-[16px] leading-[20px] font-semibold text-mudPurple hover:text-darkenPurple pl-[15px] pt-[15px] '
											onClick={() => {
												handleSelectedEmotions('All emotions');
												setShowDropdown(false);
											}}
										>
											{t('All emotions')}
										</p>
										{isRegularDisplayMode && (
											<p
												className='text-[16px] leading-[20px] font-semibold text-mudPurple hover:text-darkenPurple pl-[15px] pt-[15px] '
												onClick={() => {
													handleSelectedEmotions('Uncomfortable');
													setShowDropdown(false);
												}}
											>
												{t('uncomfortable')}
											</p>
										)}
										{resultDataChart?.map((item, index) => (
											<p
												className='text-[16px] leading-[20px] font-semibold text-mudPurple hover:text-darkenPurple pl-[15px] pt-[15px] capitalize '
												onClick={() => {
													handleSelectedEmotions(item.label);
													setShowDropdown(false);
												}}
												key={index}
											>
												{t(item.label)}
											</p>
										))}
									</div>
								</div>
							)}
						</div>
						<div className='flex flex-col relative noselect' onMouseLeave={() => setShowTargetGroup(false)}>
							<div
								className='all-target-button bg-[#FAFAFF] z-20 hover:bg-lightBG'
								style={{ height: 40 }}
								onClick={(e) => {
									e.preventDefault();
									setShowTargetGroup((prev) => !prev);
								}}
							>
								<Filter fill='#5E639B' />
								<p className='text-center'>{t('filterClassroom')} </p>
								<div style={{ transform: showTargetGroup ? 'scaleY(-1)' : 'none' }}>
									<DropdownTransparent style={{ color: '#5E639B' }} />
								</div>
							</div>
							{showTargetGroup && (
								<div className='absolute top-0 right-0 w-[330px] bg-lightBG rounded-[29px] z-10 pt-10 px-5 pb-5'>
									<p className='text-[16px] leading-5 font-semibold text-deselectPurple  '>{t('years')} </p>
									<div className='overflow-auto flex flex-row gap-[10px] py-[10px] blue-small-scroll-horizontal'>
										<div
											className={`px-3 h-10 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
												selectedYearFilter === 'all' ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
											}`}
											onClick={() => selectYearFilter('all')}
										>
											{t('all')}
										</div>
										{filterYears?.map((year, index) => (
											<div
												key={index}
												className={`min-w-[40px] px-3 h-10 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
													selectedYearFilter === year ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
												}`}
												onClick={() => selectYearFilter(year)}
												style={{ minWidth: year.length > 2 ? 'unset' : '40px' }}
											>
												<p className='whitespace-nowrap'>{year}</p>
											</div>
										))}
									</div>
									<p className='mt-5 text-[16px] leading-5 font-semibold text-deselectPurple '>{t('classes')}</p>
									<div className='overflow-auto flex flex-row gap-[10px] py-[10px] blue-small-scroll-horizontal'>
										<div
											className={`px-3 h-10 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
												selectedClassFilter === 'all' ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
											}`}
											onClick={() => selectAllClasses()}
										>
											{t('all')}
										</div>
										{selectedYearList?.map((item, index) => (
											<div
												key={index}
												className={` h-10 px-3 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
													selectedClassFilter === item ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
												}`}
												onClick={() => setSelectedClassFilter(item)}
											>
												<p className='whitespace-nowrap'>{item}</p>
											</div>
										))}
									</div>
									{/* <p className='mt-5 text-[16px] leading-5 font-semibold text-deselectPurple '>Favorites</p>
									<div className='overflow-auto flex flex-row gap-[10px] py-[10px] blue-small-scroll-horizontal'>
										{favoritesList?.map((item, index) => (
											<div
												key={index}
												className={` h-10 px-3 rounded-full border border-deselectPurple flex items-center justify-center cursor-pointer  ${
													selectedClassFilter === item ? 'bg-mudPurple text-white ' : 'bg-lightBG text-mudPurple '
												}`}
												onClick={() => setSelectedClassFilter(item)}
											>
												<p className='whitespace-nowrap'>{item?.name}</p>
											</div>
										))}
									</div> */}
									<div className='flex flex-row gap-5 mt-5'>
										<button
											className='flex flex-row items-center justify-center rounded-full cursor-pointer h-10 flex-1 bg-highlightColor'
											onClick={() => applyFilter()}
											disabled={loadingFilter}
										>
											{loadingFilter ? (
												<Loading primaryColor='#9392AD' secondaryColor='#9392AD' size={25} stroke={7} />
											) : (
												<p className='text-darkenPurple text-[16px] leading-5 font-semibold'> {t('apply')}</p>
											)}
										</button>
										<button
											className='flex flex-row items-center justify-center rounded-full cursor-pointer h-10 flex-1 bg-white'
											onClick={() => applyStaffFilter()}
											disabled={loadingStaffFilter}
										>
											{loadingStaffFilter ? (
												<Loading primaryColor='#9392AD' secondaryColor='#9392AD' size={25} stroke={7} />
											) : (
												<p className='text-darkenPurple text-[16px] leading-5 font-semibold'>{t('staff')}</p>
											)}
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className='w-full mt-8 '>
						<p className='text-darkenPurple text-[16px] leading-5 font-bold'>
							{t('Number of student who answered "How are you today?" on this date')}
							{': '}
							<span className='text-deepPurple text-[18px] leading-5 font-bold'>{numberOfStudentsWhoAnswered}</span>
						</p>
					</div>
					<div style={{ width: '100%', marginTop: 16, position: 'relative' }}>
						<BarChartClassroom
							resultDataChart={resultDataChart}
							maxValue={maxValue}
							handleSelectedLabel={handleSelectedLabel}
							showStripes={true}
							showCursor={!loading}
							showEmotions={true}
						/>
						{loading && (
							<div className='absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center z-[1]'>
								<Loading size={32} />
							</div>
						)}
					</div>

					{showStudents && (
						<div className='classroom-wellbeing-title justify-start' ref={ref}>
							<p className='mr-[10px]'>
								{t('yourIndividualStudentsResponses')}
								{showCommentLabel && (
									<span className='text-[16px] text-[#9392AD] font-semibold'>
										{' '}
										{t('uncomfortableEmontionsWithComments')}
									</span>
								)}
							</p>
							{loading && <Loading />}
							{/* <div className='emotions-dropdown-container' style={{ minWidth: 180, height: 40 }}>
								<div
									className='classroom-emotions-dropdown'
									onClick={(e) => {
										e.preventDefault();
										setShowClassDropdown(!showClassDropdown);
									}}
									style={{
										zIndex: 6,
										background: '#ecebf8',
										minWidth: 180,
										border: '1px solid #BEBECE',
									}}
								>
									<p className='dropdown-label-style'>{selectedFilter}</p>
									<div style={{ transform: showClassDropdown ? 'scaleY(-1)' : 'none' }}>
										<DropdownTransparent style={{ color: '#5E639B' }} />
									</div>
								</div>
								{showClassDropdown && (
									<div className='feedback-drop-down' style={{ zIndex: 5, top: 0, padding: 0, paddingRight: 5 }}>
										<div className='blue-small-scroll' style={{ width: '100%', overflowY: 'auto', marginTop: 30 }}>
											<div style={{ paddingBottom: 20, width: '100%' }}>
												<p
													onClick={(e) => {
														handleSelectedFilter('With dropdown A-Z');
														setShowClassDropdown(false);
													}}
													className='dropdown-items-style'
													style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'left' }}
												>
													With dropdown A-Z
												</p>
												{filterList?.map((item, index) => (
													<div
														onClick={(e) => {
															handleSelectedFilter(item);
															setShowClassDropdown(false);
														}}
														key={index}
													>
														<p
															className='dropdown-items-style'
															style={{
																paddingLeft: 15,
																paddingRight: 15,
																paddingTop: 10,
																textAlign: 'left',
															}}
														>
															{item}
														</p>
													</div>
												))}
											</div>
										</div>
									</div>
								)}
							</div> */}
						</div>
					)}
					{!initLoading && showStudents && (
						<ViewportList viewportRef={scrollRef} items={myVisibleStudents} itemSize={myVisibleStudents.length}>
							{(item, index) => (
								<div className='classroom-wellbeing-body' key={index}>
									{item?.data?.length !== 0 && (
										<div className='classroom-wellbeing-header' style={{ marginBottom: item.collapse ? 0 : 20 }}>
											<div className='classroom-year-text'>
												<p>
													{t('year')} {item.year}
												</p>
												<p>
													{t('Class')} {item.class_name}
												</p>
											</div>
											<div className='classroom-spacer'></div>
											<div
												className='classroom-year-button'
												style={{ paddingLeft: 5 }}
												onClick={(e) => {
													e.preventDefault();
													let newClasses = myVisibleStudents.map((_class, i) => {
														if (index == i) _class.collapse = !_class.collapse;
														return _class;
													});
													setMyVisibleStudents(newClasses);
												}}
											>
												<div style={{ transform: item.collapse ? 'rotate(180deg)' : 'none' }}>
													<AngleUp style={{ color: '#6B6C7E' }} />
												</div>
											</div>
										</div>
									)}
									{!item.collapse && (
										<div className='classroom-wellbeing-row'>
											{item.data.slice(0, item.reduce).map((student, index) => (
												<div
													className='classroom-wellbeing-student'
													style={{
														border: `1px solid ${emotions_color[student.feedback.emotion]}`,
														background: emotions_color_opacity_03[student.feedback.emotion],
														position: 'relative',
														cursor: 'pointer',
													}}
													onClick={(e) => {
														e.preventDefault();
														openStudent(student);
													}}
													key={index}
												>
													<img
														src={emotions_heads[student.feedback.emotion]}
														alt='emotion'
														width={48}
														height={48}
													/>
													<p>
														{student.user.first_name} {student.user.last_name}
													</p>
													{student?.feedback?.message && (
														<i
															style={{ position: 'absolute', top: 10, right: 10 }}
															data-tip={student.feedback.message}
															data-for='editReminder'
														>
															<CommentIcon
																style={{
																	color: emotions_color_dark[student.feedback.emotion],
																}}
															/>
															<ReactTooltip
																backgroundColor='#FAFAFF'
																arrowColor='#FAFAFF'
																border={true}
																borderColor='#BEBECE'
																place='top'
																effect='solid'
																id='editReminder'
																className='classroom-tooltip'
															/>
														</i>
													)}
												</div>
											))}
										</div>
									)}
									{item?.data.length > numberOfEmotionsInRow && !item.collapse && item.reduce == reduce && (
										<div
											className='classroom-showmore'
											onClick={() => {
												let newClasses = myVisibleStudents.map((_class, i) => {
													if (index == i) _class.reduce = 100;
													return _class;
												});
												setMyVisibleStudents(newClasses);
											}}
										>
											<div>
												<AngleUp style={{ color: '#6B6C7E' }} />
											</div>
											<p>{t('showMore')}</p>
										</div>
									)}
									{item?.data.length > reduce && !item.collapse && item.reduce != reduce && (
										<div
											className='classroom-showmore'
											onClick={() => {
												let newClasses = myVisibleStudents.map((_class, i) => {
													if (index == i) _class.reduce = reduce;
													return _class;
												});
												setMyVisibleStudents(newClasses);
											}}
										>
											<div style={{ transform: 'rotate(180deg)' }}>
												<AngleUp style={{ color: '#6B6C7E' }} />
											</div>
											<p>{t('showLess')}</p>
										</div>
									)}
								</div>
							)}
						</ViewportList>
					)}

					{!loadingStaffFilter && !showStudents && (
						<>
							<div className='classroom-wellbeing-title' ref={ref}>
								<p>{t('wellbeingOfTeachers')}</p>
							</div>
							<div className='classroom-wellbeing-row mt-5'>
								{myVisibleTeachersList.map((item, index) => {
									return (
										<div
											className='classroom-wellbeing-student'
											style={{
												border: `1px solid ${emotions_color[item.feedback?.emotion]}`,
												background: emotions_color_opacity_03[item.feedback?.emotion],
												position: 'relative',
												cursor: 'pointer',
											}}
											onClick={(e) => {
												e.preventDefault();
												openStudent(item);
											}}
											key={index}
										>
											<img src={emotions_heads[item.feedback?.emotion]} alt='emotion' width={48} height={48} />
											<p>
												{item.user.first_name} {item.user.last_name}
											</p>
											{item?.feedback?.message && (
												<i
													style={{ position: 'absolute', top: 10, right: 10 }}
													data-tip={item.feedback?.message}
													data-for='editReminder'
												>
													{' '}
													<CommentIcon
														style={{
															color: emotions_color_dark[item.feedback?.emotion],
														}}
													/>{' '}
													<ReactTooltip
														backgroundColor='#FAFAFF'
														arrowColor='#FAFAFF'
														border={true}
														borderColor='#BEBECE'
														place='top'
														effect='solid'
														id='editReminder'
														className='classroom-tooltip'
													/>
												</i>
											)}
										</div>
									);
								})}
							</div>
						</>
					)}
				</div>
			</div>
			<FeedBackModal
				modalIsOpen={openStudentFeedback}
				closeModal={closeStudentFeedback}
				student={selectedStudent}
				day={startDate === null ? new Date().getTime() : startDate.getTime()}
			/>
		</div>
	);
}
export default ClassroomHome;
