import React, { useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import '../../../styles/modals/DayReflectionModal.css';
import { ReactComponent as CloseModalIcon } from '../../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as NoteLockIcon } from '../../../assets/svg/NoteLockIcon.svg';
import { create } from '../../../server/server';
import { encrypt } from '../../../utils/crypto';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import i18n from '../../../i18n';
import { notify } from '../../../utils/util';
import Loading from '../../Loading';

function HowAreYouFinal({ modalIsOpen, closeModal, finalData, checkUsersFeedbackNeeded = () => {} }) {
	const { t } = useTranslation();
	const [sendingRequest, setSendingRequest] = useState(false);
	const [name, setName] = useState('');

	const save_and_continue = async () => {
		setSendingRequest(true);
		const secretKey = localStorage.getItem('secret_key');
		const note_name = name !== '' ? name : 'New note';
		const private_note = {
			...finalData,
			//type: 'daily_feedback',
			name: encrypt(note_name, secretKey),
			created_date: Date.now(),
		};
		console.log('private note', private_note);
		const resp = await create({ collection: 'private_notes', data: private_note });
		if (resp.status === 200) {
			notify(t('privateNoteHasBeenSaved'));
			checkUsersFeedbackNeeded();
			closeModal();
		}
		setSendingRequest(false);
	};
	React.useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);
	return (
		<ReactModal
			transparent
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				setName('');
			}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 99,
				},
				content: {
					inset: 0,
					padding: 0,
					margin: 0,
					marginTop: 30,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className='day-reflection-modal-icon' onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
			</div>
			<div className='how-are-you-final'>
				<div className='how-are-you-header'>
					<p className='how-are-you-title'>{t('comparedToLastTimeTodayIamFeeling')} </p>
					<p className='how-are-you-subtitle'>{t('howDoYouFeelComparedToLastTimeYouDidThePoll')}</p>
				</div>
				<div className='how-are-you-body'>
					<div className='how-are-you-logo'>
						<NoteLockIcon />
						<p className='how-are-you-logo-text'>{t('thisIsYourSafePlaceAndNothingWillBeShared')} </p>
					</div>
					<div style={{ width: '100%' }}>
						<p className='how-are-you-label'>{t('enterANameForTodaysNotes')} </p>
						<input
							className='how-are-you-input'
							type='text'
							placeholder={t('nameOfTodayNote')}
							value={name}
							onChange={(e) => setName(e.target.value)}
							autoComplete='off'
						/>
					</div>
					<div className='how-are-you-buttons'>
						<div
							className='how-are-you-cancel-button'
							onClick={() => {
								closeModal();
								notify(t('yourFeedBackHasBeenSubmited'));
							}}
						>
							<p className='how-are-you-cancel-text'>{t('iDontWantToThinkAboutItNow')} </p>
						</div>
						<button className='how-are-you-save-button' disabled={sendingRequest} onClick={() => save_and_continue()}>
							{sendingRequest ? (
								<Loading primaryColor='#FDDF6D' secondaryColor='#FDDF6D' size={25} stroke={7} />
							) : (
								<p className='how-are-you-save-text'>{t('continue')}</p>
							)}
						</button>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default HowAreYouFinal;
