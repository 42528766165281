import React, { useEffect, useState } from 'react';
import { ReactComponent as DropDownSurvey } from '../../../assets/svg/dropdownSurvey.svg';
import { ReactComponent as DropUpSurvey } from '../../../assets/svg/dropupSurvey.svg';
import { ReactComponent as JournalCheck } from '../../../assets/svg/JournalCheck.svg';
import { useUser } from '../../../auth';
import { fileSystemUrl } from '../../../constants/Connections';
import ProgressBar from '../../../components/ProgressBar';
import { useTranslation } from 'react-i18next';
import { getJournalForDay } from '../../../server/server';
import Loading from '../../Loading';

function ContentJournalSection({ section, index, targetCount, journalId }) {
	const { t, locale } = useTranslation();
	const { user, setUser } = useUser();
	const [isExpanded, setIsExpanded] = useState(false);
	const [answers, setAnswers] = useState([]);
	const [loading, setLoading] = useState(true);

	const showImage = (files, width) => {
		if (files) {
			return (
				<div className='image-container'>
					<img
						src={fileSystemUrl + '/' + files?.storage_name + '?token=' + user?.token + '&width=' + width}
						width='100%'
						alt='Task '
						className='task-image'
					/>
				</div>
			);
		}
	};

	useEffect(() => {
		if (isExpanded) loadData();
	}, [isExpanded]);

	const loadData = async () => {
		const resp = await getJournalForDay(journalId, index);
		if (resp.status == 200) {
			setAnswers(resp.data);
			setLoading(false);
		}
	};

	return (
		<div key={index} className={`p-4 rounded-xl my-5 ${isExpanded ? 'bg-lightBG' : 'bg-[#f4f4fc]'}`}>
			<div className='flex flex-row justify-between items-start'>
				<div>{showImage(section?.files, 200)}</div>
				<div className='flex flex-col w-[660px]'>
					<div className='flex flex-row justify-between gap-[5px]'>
						<div className='max-w-[38rem]'>
							<h3 className='font-bold text-[#439C5A] text-lg max-w-[40rem] text-left'>{section?.name}</h3>
							<p className='text-left text-sm mt-2 text-mudPurple'>{section?.description}</p>
						</div>
						{section?.numberOfAnswers > 0 && (
							<div
								className={`pointer rounded-full w-[56px] h-[56px] min-w-[56px] flex items-center justify-center ${
									isExpanded ? 'bg-deselectPurple' : 'border border-deselectPurple'
								}`}
								onClick={() => setIsExpanded(!isExpanded)}
							>
								{isExpanded ? <DropUpSurvey /> : <DropDownSurvey />}
							</div>
						)}
					</div>
					<div className='mt-1'>
						<div className='single-content-body my-2 flex justify-between items-start'>
							<h2 className='text-deepPurple font-bold text-xl text-left max-w-xl'>{section?.title}</h2>
							<p className='text-right text-sm text-mudPurple font-medium'>
								{section?.numberOfAnswers}/{targetCount}
							</p>
						</div>
						<ProgressBar bgcolor={'#5E639B'} completed={Math.round((section?.numberOfAnswers / targetCount) * 100)} />
					</div>
					{isExpanded && (
						<div className='mt-4'>
							{loading ? (
								<div className='h-[100px] flex items-center justify-center'>
									<Loading />
								</div>
							) : (
								<>
									<h2 className='font-bold text-xl text-mudPurple text-left mt-4'>{t('answers')}</h2>
									<div>
										{answers?.map((data, index) => {
											const user = data.user;
											return (
												<div
													className='pointer bg-containerGray w-full py-3 px-4 flex items-center justify-start text-left my-2 rounded-xl'
													key={index}
												>
													<JournalCheck />
													<p className='ml-3 text-base text-deepPurple font-bold'>
														{user.first_name} {user.last_name}
													</p>
												</div>
											);
										})}
									</div>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default ContentJournalSection;
