import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/build.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProviders from './components/AppProviders';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './styles/main.css';
//use build.css for prod
import './i18n';
import { env } from './constants/Connections';

Sentry.init({
	dsn: 'https://484cdb10de5b449ab12c5a34c5ea901b@o4504117825896449.ingest.sentry.io/4504117832777729',
	integrations: [new BrowserTracing(), new Sentry.Replay()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	replaysSessionSampleRate: 0.1,
	tracesSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0,
	enabled: process.env.REACT_APP_ENV === 'prod',
});

// replace console.* for disable log on production
if (process.env.REACT_APP_ENV === 'prod') {
	// console.log = () => {};
	// console.error = () => {};
	// console.debug = () => {};
}

ReactDOM.render(
	<AppProviders>
		<App />
	</AppProviders>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
