import React, { useEffect, useState } from 'react';
import { ReactComponent as PollIcon } from '../../assets/svg/PollIcon.svg';
import { ReactComponent as QuestionIcon } from '../../assets/svg/QuestionIcon.svg';
import { ReactComponent as PostIcon } from '../../assets/svg/PostIcon.svg';
import { ReactComponent as EditContent } from '../../assets/svg/EditContent.svg';
import { ReactComponent as DeleteContent } from '../../assets/svg/DeleteContent.svg';
import { format_day, translateTargetText } from '../../utils/util';
import { fileSystemUrl } from '../../constants/Connections';
import { useUser } from '../../auth';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const IconMap = {
	poll: <PollIcon />,
	question: <QuestionIcon />,
	feed: <PostIcon />,
};

function UpcomingSingleContent({ content, handleDelete, openBigImage }) {
	const { user, setUser } = useUser();
	const history = useHistory();
	const { t, locale, i18n } = useTranslation();

	const showImage = (files, width) => {
		if (files) {
			return (
				<div className='image-container'>
					<img
						src={fileSystemUrl + '/' + files?.storage_name + '?token=' + user?.token + '&width=' + width}
						alt='Task'
						className='task-image'
						style={{ width: `${width}px` }}
					/>
				</div>
			);
		}
	};

	const handleEdit = () => {
		document.getElementById('home').style.filter = 'none';
		document.getElementById('upcoming-content-modal-trigger').style.display = 'flex';
		history.push(`/content?editId=${content?.id}`);
	};

	return (
		<div className='bg-containerGray w-100% rounded-xl mb-4 p-3 mx-8' key={content?.id}>
			<div className='header-single-content flex justify-between items-center'>
				<div>
					{IconMap[content?.type]}{' '}
					<span className='text-mudPurple font-medium text-sm'>
						{t('Open')} {t(content?.type)}
					</span>
				</div>

				<div className='pointer'>
					{content?.type !== 'journal' && (
						<EditContent
							fill='#BEBECE'
							onClick={(e) => {
								e.stopPropagation();

								handleEdit();
							}}
						/>
					)}

					<DeleteContent
						className='ml-5'
						fill='#FF747F'
						onClick={(e) => {
							e.stopPropagation();

							handleDelete(content);
						}}
					/>
				</div>
			</div>
			{content?.files && (
				<div
					className='mr-3 my-2 cursor-pointer'
					onClick={() => openBigImage(fileSystemUrl + '/' + content?.files?.storage_name + '?token=' + user?.token)}
				>
					{showImage(content?.files, 180)}
				</div>
			)}
			<div className='text-deepPurple mt-3 font-bold text-lg text-left max-w-xs'>{content?.title}</div>
			<p className='text-sm text-deepPurple text-left mt-3'>{content?.description}</p>
			<div>
				{content?.type === 'poll' && (
					<div className='mt-3 text-left'>
						<span className='text-[12px] text-mudPurple  mb-2'>{t('options')} </span>
						{content?.options.map((option, index) => {
							return (
								<div
									key={index}
									className='text-[16px] text-mudPurple bg-white rounded-xl w-full py-[0.6rem] mb-2 text-left px-3'
								>
									{option}
								</div>
							);
						})}
					</div>
				)}
			</div>
			<div className='flex justify-between items-center mt-3'>
				<span className='text-mudPurple font-medium text-sm'>
					<span className='text-[#9392ad99] text-sm'>{t('target')} :</span> {translateTargetText(content?.target_text)}
				</span>
				<p>
					<span className='text-[#9392ad99]  text-sm'>
						{t('date')} & {t('time')}:{' '}
					</span>
					<span className='text-mudPurple text-sm '>{format_day(content?.publicationDate, true, i18n.language)}</span>
				</p>
			</div>
		</div>
	);
}

export default UpcomingSingleContent;
