import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useUser } from '../auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const { isSignedIn } = useUser();
	return (
		<Route
			{...rest}
			render={(props) => {
				if (isSignedIn) return <Component {...props} />;
				return <Redirect to='/login' />;
			}}
		/>
	);
};

export default PrivateRoute;
