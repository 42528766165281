import React, { useEffect, useState } from 'react';
import { ReactComponent as AngleUp } from '../../../assets/svg/AngleUp.svg';
import { ReactComponent as Checkbox } from '../../../assets/svg/Checkbox.svg';
// import { t } from 'i18next';
import { translateReportFilterFields } from '../../../utils/util';
import { useTranslation } from 'react-i18next';

function FilterButton({ title = 'Filter item', filterList = [], updateFilterList = () => {}, initiallyOpened = true }) {
	const { t, locale } = useTranslation();
	const [opened, setOpened] = useState(initiallyOpened);

	return (
		<>
			<button className={`h-[49px] filter-button hover:bg-[#ECEBF8] bg-[#F8F8FB]`} onClick={() => setOpened(!opened)}>
				<p className='text-[#413F5E] text-[16px] font-semibold'>{translateReportFilterFields(title)}</p>
				<AngleUp height={7} width={11} className={opened ? 'rotate-0' : '-rotate-90'} />
			</button>
			{opened && (
				<div className='mt-[10px] mb-[10px] pl-[20px] flex flex-col gap-[10px]'>
					{filterList.map((filter, index) => {
						const updateFilterItem = (value) => {
							let newList = [...filterList];
							newList[index] = value;
							updateFilterList(newList);
						};
						return (
							<FilterItem item={filter} key={filter.code} filterCode={title} updateItem={updateFilterItem} topLevel={true} />
						);
					})}
				</div>
			)}
		</>
	);
}

const FilterItem = ({ item = {}, updateItem = () => {}, topLevel = false, filterCode }) => {
	const haveChildren = !!item.list;
	const [opened, setOpened] = useState(false);

	const checked = !!item.checked;

	const checkFilter = (value) => {
		let newItem = { ...item, checked: value };
		if (haveChildren) {
			newItem.list = newItem.list.map((e) => ({ ...e, checked: value }));
		}
		updateItem(newItem);
	};

	return (
		<div>
			<div className='flex flex-row items-center '>
				{haveChildren && (
					<button
						className={`flex items-center justify-center mr-[5px]`}
						onClick={() => setOpened(!opened)}
						disabled={!haveChildren}
					>
						<AngleUp color='#9392AD' height={7} width={11} className={opened ? 'rotate-0' : '-rotate-90'} />
					</button>
				)}
				{!topLevel && !haveChildren && <div className='w-[15px]' />}
				<button className='flex flex-row items-center' onClick={() => checkFilter(!checked)}>
					<div className='w-[24px] h-[24px] relative mr-[11px] flex items-center justify-center'>
						<div
							className={`w-[18px] h-[18px] border-2 rounded-[3px] border-[#9392AD] transition-all duration-[230ms] ease-in-out ${
								!checked ? 'opacity-100' : 'opacity-0'
							}`}
						/>
						<Checkbox
							className={`absolute top-0 transition-all duration-[230ms] ease-in-out ${
								checked ? 'opacity-100' : 'opacity-0'
							}`}
						/>
					</div>
					<div>
						<p className='text-[#413F5E] font-semibold text-[16px]'>{translateReportFilterFields(item.title)}</p>
					</div>
				</button>
			</div>
			{haveChildren && opened && (
				<div className='border-l border-l-[#9392AD] ml-[5px]'>
					<div className='flex flex-col gap-[10px] pl-[15px] pt-[10px] '>
						{item.list.map((subItem, index) => {
							const updateItemList = (value) => {
								let newList = [...item.list];
								newList[index] = value;
								let newItem = { ...item, list: newList };
								const allSubSelected = newList.filter((e) => !!e.checked).length === item.list.length;
								newItem.checked = allSubSelected;
								updateItem(newItem);
							};
							return (
								<FilterItem item={subItem} key={subItem.code} initChecked={subItem.checked} updateItem={updateItemList} />
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

export default FilterButton;
