import React, { useContext, useState } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/PollScheduleModal.css';
import { FaTimes, FaPen, FaTrashAlt } from 'react-icons/fa';
import { format_day } from '../../utils/format_day';
import ReactTooltip from 'react-tooltip';
import { fileSystemUrl } from '../../constants/Connections';
import { useUser } from '../../auth';
import { deleteById } from '../../server/server';
import ConfirmationModal from '../modals/ConfirmationModal';
import { useHistory } from 'react-router-dom';
import { notify, translateTargetText } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { truncate } from '../../utils/util';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as EditContent } from '../../assets/svg/EditContent.svg';
import { ReactComponent as DeleteContent } from '../../assets/svg/DeleteContent.svg';
import { ContentContext } from '../content/ContentContext';
import i18n from '../../i18n';
function ProgramHistoryModal({
	showHistory,
	closeHistory,
	history,
	program,
	message,
	setContentItem,
	type,
	day,
	programHistory,
	setProgramHistory,
}) {
	React.useEffect(() => {
		if (showHistory) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [showHistory]);
	const { user, setUser } = useUser();
	let historyl = useHistory();
	const { t, locale } = useTranslation();
	const [deleteModalOpen, setDelModalOpen] = useState(false);
	const [selDelete, setSelDelete] = useState('');
	const [deleted, setDeleted] = useState([]);

	const closeDelModal = () => {
		setDelModalOpen(false);
	};
	const handleSelect = (history) => {
		setContentItem(history);
		historyl.push(`/content?contentEdit=${history?.type}`);
	};
	const deleteContent = async () => {
		console.log('deleted:', selDelete.id);
		const resp = await deleteById({ collection: 'content', id: selDelete.id });
		if (resp.status) {
			setDelModalOpen(false);
			setDeleted([...deleted, selDelete.id]);
			notify(t('deleteWasSuccessful'));
			setProgramHistory(programHistory.filter((program) => program.id !== selDelete.id));
		}
	};
	const handleDelete = (history) => {
		setDelModalOpen(true);
		setSelDelete(history);
	};

	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={showHistory}
			onAfterOpen={() => {}}
			onRequestClose={closeHistory}
			style={{
				overlay: {
					zIndex: 10,
					backgroundColor: 'rgba(44,44,44,0.5)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 1000,
					minWidth: 1000,
					margin: 'auto',
					marginTop: '2%',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					margin: 'auto',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div onClick={(e) => e.stopPropagation()}>
				<div className='w-full justify-center flex'>
					<div
						className='hover:bg-darkenPurple pointer absolute top-[10px] flex bg-deepPurple rounded-full w-[60px] h-[60px] justify-center items-center'
						onClick={() => closeHistory()}
					>
						<CloseModalIcon />
					</div>

					<div className='bg-white rounded-xl mt-10 h-full w-full'>
						<div className='pt-10 pb-5 bg-lightBG rounded-xl px-6'>
							<p className='font-bold text-deepPurple text-[25px]'>{program.name}</p>
							<p className='text-sm text-mudPurple'>{truncate(message ? message : '', 0, 350).text}</p>
						</div>
						<div className='history-body-chapter p-6'>
							<h4 className='font-bold text-deepPurple text-[25px] text-left'>
								{t('listOf')} <span className='capitalize'>{t(type)}</span>
							</h4>
							<p className='text-sm text-mudPurple text-left'>
								{t('thisListRepresentsAllContentFromThisTypeYouCanEditOrDeleteTt')}
							</p>
						</div>
						<div className='history-list px-6'>
							{history.map((history, index) => {
								if (history.programDetails.dayIndex === day && history.type === type) {
									return (
										<div
											className={`bg-lightBG rounded-xl my-3 p-3 ${deleted.includes(history.id) ? 'hidden' : ''}`}
											key={index}
										>
											<div className='flex justify-between items-center' style={{ padding: '0 0 8px 0' }}>
												<p className='text-deepPurple font-bold text-lg text-left'>{program.name}</p>
												<div className='flex items-center'>
													<p
														className='trash pointer'
														style={{ marginRight: '10px' }}
														data-tip={t('editThis') + `: ${type}`}
														data-for='editThis'
														onClick={() => handleSelect(history)}
													>
														<EditContent fill='#BEBECE' />

														<ReactTooltip
															id='editThis'
															arrowColor='transparent'
															effect='solid'
															backgroundColor='rgb(97,97,97)'
															className='tooltip-small'
															place='bottom'
														/>
													</p>
													<p
														className='trash pointer'
														data-tip={t('deleteThis') + ` ${type}`}
														data-for='deleteThis'
														onClick={() => handleDelete({ id: history.id, type: history.type })}
													>
														<DeleteContent className='ml-1' fill='#FF747F' />
														<ReactTooltip
															id='deleteThis'
															arrowColor='transparent'
															effect='solid'
															backgroundColor='rgb(97,97,97)'
															className='tooltip-small'
															place='bottom'
														/>
													</p>
												</div>
											</div>
											<div className='flex justify-between items-center mt-3' style={{ fontSize: '14px' }}>
												<div className='flex items-center'>
													<div className='flex flex-row items-center'>
														<p className='text-mudPurple font-semibold text-base'>{t('targetGroup')}</p>

														<div className='px-3 mx-4 h-[40px] rounded-full bg-white flex items-center justify-center text-base text-mudPurple font-semibold'>
															{translateTargetText(history.target_text)}
														</div>
													</div>
													<div className='ml-5 flex flex-row items-center'>
														<p className='text-mudPurple font-semibold text-base'>{t('schedule')}</p>

														<div className='px-3 mx-4 h-[40px] rounded-full bg-white flex items-center justify-center text-base text-mudPurple font-semibold'>
															{history.schedule
																? format_day(history.schedule_date, true, i18n.language)
																: t('off')}
														</div>
													</div>
												</div>
												<p className='text-sm text-mudPurple'>
													<span className='opacity-60'>{t('created')}:</span>{' '}
													{format_day(history.created_date, true, i18n.language)}
												</p>
											</div>
										</div>
									);
								} else {
									return '';
								}
							})}
						</div>
					</div>
				</div>
				<ConfirmationModal
					modalIsOpen={deleteModalOpen}
					closeModal={closeDelModal}
					confirmation={deleteContent}
					message={t('doYouWantToDeleteThis') + ` ${selDelete.type}?`}
				/>
			</div>
		</ReactModal>
	);
}

export default ProgramHistoryModal;
