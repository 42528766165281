import React, { useRef, useState, useEffect } from 'react';
import '../../styles/management/ManageTeacher.css';
import '../../styles/management/ClassList.css';
import '../../styles/management/TeacherList.css';
import '../../styles/management/ClassHome.css';
import '../../styles/management/ManagementHome.css';
import { ReactComponent as SearchIcon } from '../../assets/svg/SearchIcon.svg';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../auth';
import ManagementHeader from './ManagementHeader';
import TeacherManagement from './TeacherManagement';
import ClassManagement from './ClassManagement';

function ManagementHome() {
	const [managementType, setManagementType] = useState('class');
	const [searchText, setSearchText] = useState('');
	const { user, setUser } = useUser();
	const { t, locale } = useTranslation();
	const timeout = useRef(null);
	const searchRef = useRef();
	const [newTeacherOpen, setNewTeacherOpen] = useState(false);
	const childRef = useRef(null);

	const addNewClass = () => {
		childRef.current.addNewClass();
	};

	const handleChange = (e) => {
		e.preventDefault();
		setSearchText(e.target.value);
		if (timeout !== null) {
			clearTimeout(timeout.current);
		}
		timeout.current = setTimeout(() => {
			childRef.current.searchClass(e.target.value);
		}, 700);
	};

	const hideTeacherButton = !(user?.role == 'school_admin' || user?.role_coverage?.target_text == 'All students');

	return (
		<div id='managementId' className='management-home-container bg-[#ECEBF8] '>
			<ManagementHeader />
			<div className='managements-lists-container xxs:max-w-[95%] xs:max-w-[95%] md:max-w-[95%] mx-auto 3xl:max-w-[1480px]'>
				<div className='managements-lists-nav flex flex-row flex-wrap xl:flex-nowrap gap-y-[10px]'>
					<div className='flex flex-row items-center flex-1 '>
						<div
							className={`managements-lists-nav-item ${managementType === 'class' ? '' : 'hover-unselected'}`}
							style={{ marginRight: 30 }}
							onClick={() => setManagementType('class')}
						>
							<p>{t('classManagement')}</p>
						</div>
						{!hideTeacherButton && (
							<div
								className={`managements-lists-nav-item ${managementType === 'teacher' ? '' : 'hover-unselected'}`}
								onClick={() => setManagementType('teacher')}
							>
								<p>{t('teacherManagement')}</p>
							</div>
						)}
					</div>
					<div className='flex flex-row items-center '>
						<div style={{ flex: 1 }}></div>
						{managementType === 'teacher' && (
							<div className='transparent-button' onClick={() => setNewTeacherOpen(true)}>
								{t('addATeacher')}
							</div>
						)}
						{managementType === 'class' && (
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
								<div
									className='stroke-deselectPurple hover:stroke-deepPurple flex flex-row items-center border-deselectPurple border py-[0.65rem] px-4 w-[18rem] rounded-full'
									style={{ cursor: 'pointer', marginRight: 16 }}
								>
									<input
										type='text'
										value={searchText}
										className='w-full h-full text-mudPurple font-semibold text-sm'
										onChange={(e) => handleChange(e)}
										style={{ outlineStyle: 'none' }}
										ref={searchRef}
										placeholder={t('search')}
									/>
									<SearchIcon onClick={() => searchRef.current.focus()} />
								</div>
								<div className='transparent-button text-center' onClick={() => addNewClass()}>
									{t('addANewClass')}
								</div>
							</div>
						)}
					</div>
				</div>
				{managementType === 'teacher' && (
					<TeacherManagement newTeacherOpen={newTeacherOpen} setNewTeacherOpen={setNewTeacherOpen} />
				)}
				{managementType === 'class' && <ClassManagement ref={childRef} />}
			</div>
		</div>
	);
}

export default ManagementHome;
