import React, { useState, useEffect, useContext } from 'react';
import { FaChevronLeft, FaTimes, FaSpinner, FaChevronRight } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import '../../styles/content/ContentList.css';
import { getBy, deleteById } from '../../server/server';
import { useUser } from '../../auth';
import ConfirmationModal from '../modals/ConfirmationModal';
import { format_day } from '../../utils/format_day';
import { ContentContext } from './ContentContext';
import { fileSystemUrl } from '../../constants/Connections';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ReactComponent as DeleteContent } from '../../assets/svg/DeleteContent.svg';
import { ReactComponent as EditContent } from '../../assets/svg/EditContent.svg';

import ReadMoreText from '../ReadMoreText';
import { notify, translateTargetText } from '../../utils/util';
function ContentTemplate({ setSelTemplate, type, selectTemplateToEdit = () => {} }) {
	const { user, setUser } = useUser();
	let history = useHistory();
	const { t, locale } = useTranslation();

	const [contents, setContent] = useState([]);
	const [deleteModalOpen, setDelModalOpen] = useState(false);
	const [deleted, setDeleted] = useState([]);
	const [contentID, setContentID] = useState('');
	const [loading, setLoading] = useState(true);

	const loadContent = async () => {
		const response = await getBy({
			collection: 'template',
			sort_field: 'created_date',
			page_size: 100,
			sort_direction: -1,
			page: 1,
			query: {
				institution_id: user?.institution_id,
				type: type,
				user_id: user?.id,
			},
		});
		if (response.status === 200) {
			setLoading(false);
			setContent(response?.data?.data);
		}
	};

	useEffect(() => {
		window.history.replaceState(null, null, '?type=' + type);
		loadContent();
	}, []);

	const closeDelModal = () => {
		setDelModalOpen(false);
	};
	const openDelModal = (id) => {
		setDelModalOpen(true);
		setContentID(id);
	};
	const deleteContent = async () => {
		const newContent = contents.filter((c) => {
			return c.id !== contentID;
		});
		const resp = await deleteById({ collection: 'template', id: contentID });
		if (resp.status) {
			notify(t('templateDeleted'));
			setDelModalOpen(false);
			setContent(newContent);
		}
	};

	return (
		<div className='w-full flex flex-wrap gap-4 mt-5 '>
			{contents?.map((content, index) => {
				return (
					<Template
						content={content}
						openDelModal={openDelModal}
						setSelTemplate={setSelTemplate}
						index={index}
						editTemplate={selectTemplateToEdit}
						key={index}
					/>
				);
			})}
			{contents.length % 2 === 1 && <div className='w-[325px] p-4 rounded-xl flex-[1_1_325px] flex flex-col justify-between'></div>}
			{!loading && contents.length === 0 && (
				<p className='text-center text-deepPurple text-base font-semibold w-full mt-20'>{t('No items found')}</p>
			)}

			<ConfirmationModal
				modalIsOpen={deleteModalOpen}
				closeModal={closeDelModal}
				confirmation={deleteContent}
				message={t('doYouWantToDeleteThis')}
			/>
		</div>
	);
}

const Template = ({ content, openDelModal = () => {}, setSelTemplate = () => {}, editTemplate = () => {} }) => {
	const { t, locale } = useTranslation();
	const [showMore, setShowMore] = useState(false);
	return (
		<div className='w-[325px] p-4 bg-[#F8F8FB] rounded-xl flex-[1_1_325px] flex flex-col' id={content.id}>
			<div className='flex-1 flex flex-col'>
				<div className='flex items-center justify-between'>
					<h3 className='text-deepPurple font-bold text-xl text-left flex-1'>{content?.title}</h3>
					<span
						className='pointer mr-[10px]'
						onClick={() => editTemplate(content)}
						style={i18n.language == 'ar' ? { right: 'auto', left: '20px' } : {}}
					>
						<EditContent fill='#BEBECE' />
					</span>
					<span
						className='pointer'
						onClick={() => openDelModal(content?.id)}
						style={i18n.language == 'ar' ? { right: 'auto', left: '20px' } : {}}
					>
						<DeleteContent fill='#FF747F' />
					</span>
				</div>

				<p className='text-[#413F5E] leading-[22px] text-sm text-left mt-3 text-[16px]'>
					{showMore ? content?.message : content?.message?.substring(0, 160)}
					{!showMore && content.message?.length > 160 && '...'}
					{content?.message?.length > 85 && (
						<span className='font-bold text-[#5E639B] pointer text-[14px]' onClick={() => setShowMore(!showMore)}>
							<br />
							{!showMore ? t('showMore') : t('showLess')}
						</span>
					)}
				</p>

				<div className='flex flex-col flex-1 justify-end'>
					<div className='flex justify-between items-center my-2'>
						<span className='text-[#5E639B] font-semibold text-base'>{t('targetGroup')} </span>
						<div className='text-[#5E639B] font-semibold text-base flex items-center justify-center bg-white rounded-full py-2 min-w-[130px]'>
							{translateTargetText(content?.target_text)}
						</div>
					</div>
					<div className='flex justify-between items-center my-2'>
						<span className='text-[#5E639B] font-semibold text-base'>{t('schedule')} </span>
						<div className='text-[#5E639B] font-semibold flex items-center justify-center text-base bg-white rounded-full py-2 min-w-[130px]'>
							{content?.schedule ? format_day(content?.publicationDate, true, i18n.language) : t('off')}
						</div>
					</div>
				</div>
			</div>

			<div className='flex justify-center items-center w-full'>
				<div
					className='rounded-full bg-[#5E639B] py-3 w-[100px] text-[#FDDF6D] flex items-center justify-center font-semibold pointer'
					onClick={() => setSelTemplate(content)}
				>
					{t('select')}
				</div>
			</div>
		</div>
	);
};

export default ContentTemplate;
