import React, { useState } from 'react';
import { ReactComponent as SearchBlue } from '../../assets/svg/SearchBlue.svg';
import { ReactComponent as ButtonGraphIcon } from '../../assets/svg/ButtonGraphIcon.svg';
import { ReactComponent as SurveyIconNew } from '../../assets/svg/SurveyIconNew.svg';
import { ReactComponent as TrashIcon } from '../../assets/svg/TrashIcon.svg';
import { useTranslation } from 'react-i18next';
import { translateTargetText } from '../../utils/util';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ReactTooltip from 'react-tooltip';
import ConfirmationModal from '../modals/ConfirmationModal';

const fakeSurvey = {
	title: 'Mindset questionnaire',
	createdBy: 'Dmytro Revchuk',
	target: 'All Students',
	type: 'survey',
	description:
		'This survey is designed to give you an idea of how your students are feeling about exams overall. It will help you identify specific challenges your students might currently be facing regarding exams, or other factors affecting how theyre approaching their exams also. It could be used at the start of an exam cycle or as a reflection point at any point throughout that cycle either',
};

function AnalyticsDataLeft({ contentList, searchText, setSearchText, selectContent, removeContentFromTrends = async () => {} }) {
	const { t } = useTranslation();
	const [selectedContent, setSelectedContent] = useState({});
	const [deleteModalOpen, setDeleteModalOpened] = useState(false);

	const closeDelModal = () => {
		setDeleteModalOpened(false);
		setSelectedContent({});
	};

	const deleteContent = async () => {
		await removeContentFromTrends(selectedContent);
		setDeleteModalOpened(false);
		setSelectedContent({});
	};

	return (
		<div className='analytics-data-left h-full'>
			<div className='flex flex-row justify-between items-end w-full mb-[15px] gap-[5px]'>
				<p className='font-bold text-[20px] text-[#413F5E]'>{t('Track trends')} </p>
				<Link to={`/content?type=poll`} className='text-[14px] text-[#413F5E] ease-out transition-all duration-200 hover:underline'>
					{t('Add a new trend')}
				</Link>
			</div>
			<div className='analytics-searchbar'>
				<input
					type='text'
					placeholder={t('searchQuestion')}
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					autoComplete='off'
				/>
				<SearchBlue />
			</div>
			<div className='border-t border-dashed border-[#9392AD] h-[1px] w-full mt-[15px] '></div>
			<div className='w-full flex flex-row justify-start '>
				<p className='text-[14px] leading-5 text-[#413F5E] font-medium mt-[10px]'>{t('clickToSeeQuestionInDetails')} </p>
			</div>
			<div className='analytics-content-list dark-grey-small-scroll'>
				<div style={{ paddingRight: 12 }}>
					{contentList?.map((item, index) => (
						<Link to={`analytics/data?type=data&content=${item.id}`}>
							<div
								className='flex flex-col gap-[10px] p-5 rounded-[10px] bg-[#F8F8FB] cursor-pointer mb-[10px] hover:bg-[#ECEBF8] analytics-content-item'
								key={index}
							>
								<div className='flex flex-row mb-[10px]'>
									<p className='flex-1 text-[16px] leading-5 font-bold text-darkenPurple' style={{ color: '#413F5E' }}>
										{item.title}
									</p>
									<div className='w-[40px] h-[40px] bg-[#FDDF6D] rounded-[100px] ml-[20px] analytics-graph-button'>
										<ButtonGraphIcon />
									</div>
								</div>
								<div className='flex flex-row items-center justify-between'>
									<p className='text-[14px] leading-5 text-deselectPurple font-medium ' style={{ color: '#413F5E' }}>
										{translateTargetText(item.target_text)}
									</p>

									<div
										className='w-[40px] analytics-trash-button'
										data-tip={t('Remove from trends')}
										data-for={`removeFromTrends${index}`}
										onClick={(e) => {
											e.preventDefault();
											setSelectedContent(item);
											setDeleteModalOpened(true);
										}}
									>
										<ReactTooltip
											id={`removeFromTrends${index}`}
											arrowColor='#5E639B'
											effect='solid'
											backgroundColor='#5E639B'
											className='wellbeing-tooltip'
											classNameArrow='tooltip-arrow'
										/>
										<TrashIcon style={{ color: 'red' }} />
									</div>
								</div>
							</div>
						</Link>
					))}
					{/* <div
						className='flex flex-col  p-5 rounded-[10px] bg-[#FFD06938] cursor-pointer mb-[10px] hover:bg-[#ECEBF8] analytics-content-item'
						onClick={() => {
							selectContent(fakeSurvey);
						}}
					>
						<div className='flex flex-row  '>
							<SurveyIconNew width={20} height={20} />
							<p className='flex-1 text-[14px] leading-5 font-medium text-deepPurple ml-[5px]'>{'Open survey'}</p>
							<div className='w-[40px] h-[40px] bg-[#FDDF6D] rounded-[100px] ml-[20px] analytics-graph-button'>
								<ButtonGraphIcon />
							</div>
						</div>
						<p className='flex-1 text-[16px] leading-5 font-bold text-darkenPurple'>{fakeSurvey.title}</p>
						<div className='flex flex-row items-center justify-between mt-[17px]'>
							<p className='text-[14px] leading-5 text-darkenPurple font-medium '>{fakeSurvey.target}</p>
							<p className='text-[14px] leading-5 text-darkenPurple font-medium '>
								{t('schedule')}: <span style={{ color: '#413F5E' }}>{true ? t('On') : t('Off')}</span>
							</p>
						</div>
					</div> */}
				</div>
			</div>
			<ConfirmationModal
				modalIsOpen={deleteModalOpen}
				closeModal={closeDelModal}
				confirmation={deleteContent}
				message={t('doYouWantToDeleteThis')}
			/>
		</div>
	);
}

export default AnalyticsDataLeft;
