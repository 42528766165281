import React, { useEffect, useState } from 'react';
import { ReactComponent as ProgramIcon } from '../../assets/svg/ProgramIcon.svg';
import { ReactComponent as SurveyContentIcon } from '../../assets/svg/SurveyContentIcon.svg';
import { ReactComponent as JournalContentIcon } from '../../assets/svg/JournalContentIcon.svg';
import { ReactComponent as SearchMagnifyIcon } from '../../assets/svg/SearchMagnifyIcon.svg';
import { ReactComponent as RunningIcon } from '../../assets/svg/RunningIcon.svg';
import { getContentByTopic, getContentJournals, getContentPrograms, getContentSurveys, getPrograms } from '../../server/server';
import { useTranslation } from 'react-i18next';
import SingleProgram from './ToGo/SingleProgram';
import SingleSurvey from './ToGo/SingleSurvey';
import SingleJournal from './ToGo/SingleJournal';
import RunningProgram from './ToGo/RunningProgram';
import ReactTooltip from 'react-tooltip';
import ContentTopicButtons from './ContentTopicButtons';
import TypeHomeList from './TypeHomeList';
import TopicHomeList from './TopicHomeList';
import { logDOM } from '@testing-library/react';
import i18n from '../../i18n';

function ReadyToGo(props) {
	const query = new URLSearchParams(props.location.search);
	const initMode = query.get('mode') ?? 'Programs';
	const [mode, setMode] = useState(initMode);
	const [loadingContent, setLoadingContent] = useState(true);
	const [contentGroup, setContentGroup] = useState('type');
	const [contentItems, setContentItems] = useState([]);
	const [topicItems, setTopicItems] = useState({});
	const [loadedTopicItems, setLoadedTopicItems] = useState({});
	const [loadedContents, setLoadedContents] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedTopic, setSelectedTopic] = useState('generalWellbeing');
	const [selContent, setSelContent] = useState('');
	const [screen, setScreen] = useState('home');
	const locale = i18n.language;
	const { t } = useTranslation();
	const [selectedRunningProgram, setSelectedRunningProgram] = useState([]);

	const contentList = [
		{
			name: 'Programs',
			description: 'Choose from our range of ready-to-go programs designed to support your students and save you time',
			icon: <ProgramIcon />,
		},
		{
			name: 'Surveys',
			description: 'Each of our ready-to-go surveys is designed to help you understand your students better',
			icon: <SurveyContentIcon />,
		},
		{
			name: 'Journals',
			description: 'Pick a journal that will keep your students engaged',
			icon: <JournalContentIcon />,
		},
	];

	const loadPrograms = async () => {
		const resp = await getPrograms(locale);
		if (resp.status === 200) {
			const data = resp.data.list;
			setLoadedContents(data?.filter((item) => item?.days?.length !== 0));
			setContentItems(data?.filter((item) => item?.days?.length !== 0));
			setSelectedRunningProgram([]);
		} else {
			setLoadedContents([]);
			setContentItems([]);
			setSelectedRunningProgram([]);
		}
		setLoadingContent(false);
	};

	const loadContentByTopic = async (topic) => {
		setLoadingContent(true);
		const resp = await getContentByTopic(topic, i18n.language);
		if (resp.status === 200) {
			const data = resp.data;
			setLoadingContent(false);
			setLoadedTopicItems(data);
			setTopicItems(data);
		}
	};

	const loadSurveys = async () => {
		const resp = await getContentSurveys();
		if (resp.status === 200) {
			const data = resp.data.filter((e) => e.locale === locale);
			setLoadedContents(data);
			setContentItems(data);
			setLoadingContent(false);
		}
	};
	const loadJournals = async () => {
		const resp = await getContentJournals();

		if (resp.status === 200) {
			const data = resp.data.filter((e) => e?.locale === locale);
			// const data = resp.data;
			setLoadedContents(data);
			setContentItems(data);
			setLoadingContent(false);
		}
	};
	useEffect(() => {
		setLoadingContent(true);
		switch (mode) {
			case 'Programs':
				loadPrograms();
				break;
			case 'Surveys':
				loadSurveys();
				break;
			case 'Journals':
				loadJournals();
				break;
			default:
				break;
		}
	}, [mode, i18n.language]);

	useEffect(() => {
		if (contentGroup === 'topic') loadContentByTopic(selectedTopic);
	}, [contentGroup, selectedTopic, i18n.language]);

	const contentSingleMap = {
		Programs: <SingleProgram programID={selContent} setScreen={setScreen} />,
		Surveys: <SingleSurvey surveyID={selContent} setScreen={setScreen} />,
		Journals: <SingleJournal journal={selContent} setScreen={setScreen} />,
	};

	useEffect(() => {
		//set teachers with search parameter
		if (contentGroup === 'type')
			if (searchTerm.length > 0) {
				setContentItems(
					loadedContents.filter((item) => `${item?.name ?? item?.title}`.toLowerCase().includes(searchTerm?.toLowerCase()))
				);
			} else setContentItems(loadedContents);
		else {
			if (searchTerm.length > 0) {
				setTopicItems({
					programs: loadedTopicItems.programs.filter((item) =>
						`${item?.name ?? item?.title}`.toLowerCase().includes(searchTerm?.toLowerCase())
					),
					surveys: loadedTopicItems.surveys.filter((item) =>
						`${item?.name ?? item?.title}`.toLowerCase().includes(searchTerm?.toLowerCase())
					),
					journals: loadedTopicItems.journals.filter((item) =>
						`${item?.name ?? item?.title}`.toLowerCase().includes(searchTerm?.toLowerCase())
					),
				});
			} else setTopicItems(loadedTopicItems);
		}
	}, [searchTerm]);

	const openCurrentlyRunningPrograms = (runningPrograms) => {
		setSelectedRunningProgram(runningPrograms.map((item) => item.id));
		setScreen('manage');
	};
	const handleManageRunningPrograms = () => {
		setSelectedRunningProgram([]);
		setScreen(screen === 'manage' ? 'home' : 'manage');
	};

	const selectType = () => {
		setContentGroup('type');
		setScreen('home');
	};
	const selectTopic = () => {
		setContentGroup('topic');
		setSelectedTopic('generalWellbeing');
		setMode('Programs');
		setSearchTerm('');
		setScreen('home');
	};

	const setSingleItemType = (item, mode) => {
		setSelContent(mode === 'Journals' || item.type == 'journal' ? item : item?.id);
		setMode(mode);
		setScreen('single');
		setSearchTerm('');
	};

	return (
		<div className='bg-lightBG w-full h-full pt-12 flex flex-col overflow-y-scroll'>
			<div className='relative h-max flex justify-center items-center'>
				<div className='bg-white w-[1200px] rounded-xl flex flex-col px-[50px] py-[40px] '>
					<div className='flex flex-row items-end'>
						<div className='flex-1'>
							<h1 className='text-[40px] text-darkenPurple font-bold'>{t('readyToGoContent')} </h1>
						</div>

						<div className='flex flex-row items-center'>
							<span className='text-[#9392AD] text-[18px] font-semibold mr-[14px]'>{t('sortBy')} :</span>
							<div className='bg-[#F8F8FB] h-[50px] items-center justify-center w-[220px] rounded-[100px] flex flex-row'>
								<button
									className={`flex-1 font-bold text-[18px] rounded-[100px] h-[50px] ${
										contentGroup === 'type' ? 'bg-[#5E639B] text-[#FDDF6D]' : 'text-[#9392AD]'
									}`}
									onClick={selectType}
								>
									{t('type')}
								</button>
								<button
									className={`flex-1 font-bold text-[18px] rounded-[100px] h-[50px] ${
										contentGroup === 'topic' ? 'bg-[#5E639B] text-[#FDDF6D]' : 'text-[#9392AD]'
									}`}
									onClick={selectTopic}
								>
									{t('topic')}
								</button>
							</div>
						</div>
					</div>
					<p className='text-base text-mudPurple'>{t('evidenceBasedContentPreparedForYouByOurTeamOfExperts')} </p>
					{contentGroup === 'type' && (
						<>
							<div className='flex flex-row justify-between mt-8'>
								{contentList?.map((content, index) => {
									const isSelected = mode == content?.name;
									return (
										<div
											className='relative flex justify-center'
											onClick={() => {
												setMode(content?.name);
												setScreen('home');
												setSearchTerm('');
											}}
											key={index}
										>
											<div
												className={`p-7  pointer rounded-xl max-w-[340px] flex flex-col ${
													isSelected ? 'bg-[#5E639B]' : 'bg-white hover:bg-lightBG border-2 border-[#5E639B]'
												}`}
											>
												<div className='flex flex-row justify-start items-center'>
													{React.cloneElement(content?.icon, { fill: isSelected ? '#FDDF6D' : '#5E639B' })}
													<span
														className={`ml-3 font-bold  text-xl ${
															isSelected ? 'text-[#FDDF6D]' : 'text-deepPurple'
														}`}
													>
														{t(content?.name)}
													</span>
												</div>
												<p
													className={` text-base text-left mt-4 ${
														isSelected ? 'text-white' : 'text-darkenPurple'
													}`}
												>
													{t(content?.description)}
												</p>
											</div>
											{isSelected && (
												<div
													className='w-0 h-0 absolute'
													style={{
														borderRight: '20px solid transparent',
														borderLeft: '20px solid transparent',
														borderTop: '20px solid #5E639B',
														bottom: -20,
													}}
												></div>
											)}
										</div>
									);
								})}
							</div>
						</>
					)}
					{contentGroup === 'topic' && <ContentTopicButtons selectTopic={setSelectedTopic} selectedTopic={selectedTopic} />}
					<div
						className='w-full flex flex-row items-center justify-center mt-[40px] pt-[25px]'
						style={{ borderTop: '1px dashed #BEBECE' }}
					>
						<div className='w-[341px] h-[50px] flex flex-row border border-deselectPurple rounded-full items-center pr-[17px]'>
							<input
								type='text'
								placeholder={t('search')}
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								className='w-full h-full p-4 flex-1 rounded-full'
								autoComplete='off'
							/>
							<SearchMagnifyIcon />
						</div>
						<div className='flex-1'></div>
						{mode === 'Programs' && (
							<div
								className={` pointer flex items-center  justify-center font-semibold text-lg rounded-full h-[50px] w-[334px]  ${
									screen === 'manage'
										? 'bg-deepPurple text-white hover:text-highlightColor'
										: 'bg-white  border border-[#BEBECE] text-[#BEBECE] hover:border-[#5E639B] hover:text-[#5E639B]'
								}`}
								onClick={() => {
									handleManageRunningPrograms();
								}}
							>
								{t('manageRunningPrograms')}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className='w-full flex justify-center mt-[30px]'>
				{screen === 'single' && <div className='bg-white w-[1200px] rounded-xl flex flex-col  h-max'>{contentSingleMap[mode]}</div>}
				{screen === 'home' && contentGroup === 'type' && (
					<TypeHomeList
						content={contentItems}
						type={mode}
						onClick={setSingleItemType}
						openCurrentlyRunningPrograms={openCurrentlyRunningPrograms}
						loading={loadingContent}
						key={mode}
					/>
				)}
				{screen === 'home' && contentGroup === 'topic' && (
					<TopicHomeList
						content={topicItems}
						type={mode}
						onClick={setSingleItemType}
						openCurrentlyRunningPrograms={openCurrentlyRunningPrograms}
						loading={loadingContent}
						key={selectedTopic}
					/>
				)}
				{screen === 'manage' && (
					<div>
						<RunningProgram selectedRunningProgram={selectedRunningProgram} />
					</div>
				)}
			</div>
		</div>
	);
}

export default ReadyToGo;
