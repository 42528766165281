import React, { useState, useEffect } from 'react';
import '../../styles/analytics/AnalyticsHome.css';

import 'react-datepicker/dist/react-datepicker.css';
import {
	getAllStudentsTarget,
	getAnalyticsContent,
	getAnalyticsHowDoYouFillStats,
	loadPrimaryStudents,
	removeContentFromTrendsAsync,
} from '../../server/server';
import { useUser } from '../../auth/index';
import TargetGroupModal from '../modals/TargetGroupModal';
import AnalyticsDataRight from './AnalyticsDataRight';
import AnalyticsDataLeft from './AnalyticsDataLeft';
// import { t } from 'i18next';
import { notify, useQuery } from '../../utils/util';
import AnalyticsHeader from './AnalyticsHeader';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

const defaultTargetOptions = {
	selection: [],
	type: 'root',
};

function AnalyticsHome() {
	const { t, locale } = useTranslation();
	const history = useHistory();
	const query = useQuery();
	const { user } = useUser();
	const initType = query?.get('type') ?? 'home';
	const [loading, setLoading] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [contentList, setContentList] = useState([]);
	const [selectedContent, setSelectedContent] = useState({});
	const [searchText, setSearchText] = useState('');
	const [displayMode, setDisplayMode] = useState('regular');
	const isRegularDisplayMode = displayMode === 'regular';
	const [selectedMode, setSelectedMode] = useState(initType);

	useEffect(() => {
		let d = new Date();
		d.setDate(d.getDate() - 19);
		setStartDate(d);
		checkUser();
	}, []);

	const checkUser = () => {
		if (!!user.hasOnlyPrimaryStudents) {
			history.replace(`analytics/data?type=data&content=how-do-you-feel&primary=true`);
		}
	};

	useEffect(() => {
		loadContent(0);
	}, [searchText]);

	const loadContent = async (number = contentList.length) => {
		setLoading(true);
		const institution_id = user.institution_id;
		const data = {
			institution_id: institution_id,
			text: searchText,
		};
		const resp = await getAnalyticsContent(data);
		if (resp.status === 200) {
			const resp_data = resp.data;
			const list = resp_data.list.filter((e) => e.type !== 'feedback');
			const previousList = number === 0 ? [] : contentList;
			setContentList([...previousList, ...list]);
		} else {
			setContentList([]);
		}
		setLoading(false);
	};

	const removeContentFromTrends = async (content) => {
		const resp = await removeContentFromTrendsAsync(content.id);
		if (resp.status === 200) {
			notify(t('Content has been removed from trends'));
			loadContent(0);
		}
	};

	const selectContent = (content) => {
		if (content?.type === 'survey') {
			setSelectedMode('survey');
			setSelectedContent(content);
			return;
		} else {
			setSelectedContent(content);
			setSelectedMode('data');
		}
	};

	return (
		<div id='basic-student' className='analytics-container  light-small-scroll bg-[#ECEBF8] '>
			<div className='analytics-inner-container mb-[40px] '>
				{!!user?.hasPrimaryClasses && !user.hasOnlyPrimaryStudents && (
					<div className='flex flex-row gap-[20px] mb-[38px] items-center'>
						<p className='text-[#9392AD] font-semibold text-[18xp]'>{t('Display')}: </p>
						<div className='flex flex-row bg-[#FFFFFF] rounded-full'>
							<Link to={`/analytics`}>
								<button
									className={`h-[50px] px-[25px] rounded-full justify-center items-center font-bold ${
										isRegularDisplayMode ? 'bg-[#5E639B] text-[#FDDF6D]' : 'text-[#9392AD]'
									}`}
									disabled={isRegularDisplayMode}
								>
									{t('Regular')}
								</button>
							</Link>
							<Link to={`analytics/data?type=data&content=how-do-you-feel&primary=true`}>
								<button
									className={`h-[50px] px-[25px] rounded-full justify-center items-center font-bold ${
										!isRegularDisplayMode ? 'bg-[#5E639B] text-[#FDDF6D]' : 'text-[#9392AD]'
									}`}
									disabled={!isRegularDisplayMode}
								>
									{t('Primary')}
								</button>
							</Link>
						</div>
					</div>
				)}
				<div className='analytics-header'>
					<AnalyticsHeader />
				</div>
				<div className='w-full h-full flex flex-row justify-center gap-10 mt-5'>
					<AnalyticsDataLeft
						selectContent={selectContent}
						setSearchText={setSearchText}
						searchText={searchText}
						contentList={contentList}
						removeContentFromTrends={removeContentFromTrends}
					/>
					<AnalyticsDataRight setSelectedMode={setSelectedMode} selectContent={selectContent} />
				</div>
			</div>
		</div>
	);
}

export default AnalyticsHome;
