import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { emotions_color } from '../../constants/Constants';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';
var de = require('apexcharts/dist/locales/de.json');
var en = require('apexcharts/dist/locales/en.json');

function AnalyticsChart({ data, loading = true, height = 300, width = '100%', showAdditionalText = false }) {
	const [resultData, setResultData] = useState([]);
	const [colors, setColors] = useState([]);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (!loading) reorderData();
		else renderInit();
	}, [data, loading]);

	const reorderData = () => {
		let newSeries = data?.map((item, index) => {
			return {
				name: t(item.key) ?? item.key,
				data: item.values,
			};
		});
		let newColors = data?.map((item) => item.color);

		console.log(newSeries);

		setResultData(newSeries);
		setColors(newColors);
	};
	const renderInit = () => {
		let newSeries = [
			{
				name: 'Option 1',
				data: [
					{
						x: 1602442754613,
						y: 1,
					},
					{
						x: 1602942754613,
						y: 12,
					},
					{
						x: 1603442754613,
						y: 2,
					},
				],
			},
			{
				name: 'Option 2',
				data: [
					{
						x: 1602442754613,
						y: 6,
					},
					{
						x: 1602942754613,
						y: 3,
					},
					{
						x: 1603442754613,
						y: 12,
					},
				],
			},
			{
				name: 'Option 2',
				data: [
					{
						x: 1602442754613,
						y: 1,
					},
					{
						x: 1602942754613,
						y: 6,
					},
					{
						x: 1603442754613,
						y: 12,
					},
				],
			},
		];
		let newColors = ['#ECEBF8', '#ECEBF8', '#ECEBF8', '#FAE480', '#FAE480', '#FAE480', '#FAE480'];
		setResultData(newSeries);
		setColors(newColors);
	};

	var options = {
		series: resultData,
		chart: {
			id: 'chart2',
			width,
			type: 'line',
			animations: {
				initialAnimation: {
					enabled: true,
				},
			},
			toolbar: {
				autoSelected: 'pan',
				show: false,
			},
			locales: [de, en],
			defaultLocale: i18n.language,
		},
		colors,
		stroke: {
			curve: 'smooth',
			width: 2,
		},
		legend: {
			position: 'top',
			horizontalAlign: 'left',
			fontSize: '12px',
			fontFamily: 'Quicksand',
			fontWeight: 500,
			textTransform: 'capitalize',
			labels: {
				colors: ['#413F5E'],
				useSeriesColors: false,
				// textTransform: 'capitalize',
			},
		},
		xaxis: {
			type: 'datetime',
			labels: {
				style: {
					fontFamily: 'Quicksand',
					fontWeight: 500,
				},
				datetimeUTC: false,
			},
		},
		yaxis: {
			min: 0,
			forceNiceScale: true,
		},
		tooltip: {
			style: {
				fontSize: '12px',
				fontFamily: 'Quicksand',
			},
		},
	};

	var options2 = {
		series: resultData,
		chart: {
			id: 'chart1',
			width,
			type: 'line',
			animations: {
				initialAnimation: {
					enabled: true,
				},
			},
			brush: {
				target: 'chart2',
				enabled: true,
			},
			selection: {
				enabled: true,
			},
			locales: [de, en],
			defaultLocale: i18n.language,
		},
		colors,
		stroke: {
			curve: 'smooth',
			width: 2,
		},

		legend: {
			show: false,
		},
		xaxis: {
			type: 'datetime',
			tooltip: {
				enabled: false,
			},
			labels: {
				style: {
					fontFamily: 'Quicksand',
					fontWeight: 500,
					// colors: ['#9392AD'],
					textAlign: 'right',
				},
				datetimeUTC: false,
				// offsetX: 50,
			},
		},
		yaxis: {
			tickAmount: 3,
		},
	};

	return (
		<div style={{ position: 'relative' }}>
			<Chart series={resultData} height={height} options={options} />
			<Chart series={resultData} height={100} options={options2} />
			{loading && (
				<div
					style={{
						position: 'absolute',
						display: 'flex',
						top: height / 2 - 25,
						alignSelf: 'center',
						left: 0,
						right: 0,

						justifyContent: 'center',
						justifyItems: 'center',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							justifyItems: 'center',
							alignItems: 'center',
							textAlign: 'center',
						}}
					>
						<Loading size={50} />
						{showAdditionalText && <p style={{ color: '#9392AD', fontWeight: 600 }}>{t('thisCanTakeAwhile')} </p>}
					</div>
				</div>
			)}
		</div>
	);
}

export default AnalyticsChart;
