import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../../auth';
import { emotions_color, emotions_list, primary_emotions_list } from '../../../../constants/Constants';
import { analyze, get_my_class } from '../../../../server/server';
import BarChartClassroom from '../../../classroom/BarChartClassroom';
import Loading from '../../../Loading';
import { useTranslation } from 'react-i18next';

function YourClassroom() {
	const history = useHistory();
	const { user, setUser } = useUser();
	const [barChartResults, setBarChartResults] = useState([]);
	const [barChartMaxValue, setBarChartMaxValue] = useState(0);
	const [loading, setLoading] = useState(true);
	const { t } = useTranslation();

	const formatDate = (date) => `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;

	const loadMyClass = async () => {
		const display_day = Date.now();
		const data = {
			user: user,
			number_of_students: 0,
			day: display_day,
			day_code: formatDate(new Date(display_day)),
			query: {},
			primaryClassesOnly: user.hasOnlyPrimaryStudents,
			// primaryClassesOnly: true,
		};
		const response = await get_my_class(data);
		if (response?.status === 200) {
			const data = response.data;
			let _my_students = data['my_class'];
			// setMyVisibleStudents(newClasses);
			let _answers = _my_students
				.map((_class) => _class['data'].map((e) => e.feedback.emotion))
				.flat()
				.filter((e) => e);
			const labels = user.hasOnlyPrimaryStudents ? primary_emotions_list : emotions_list;
			const result_data = await Promise.all(
				labels.map(async (e, index) => {
					//const newKey = await $translate(e);
					return {
						label: e,
						value: _answers.filter((k) => k == e).length,
						color: emotions_color[e],
					};
				})
			);
			filterData(labels, result_data, _my_students);
			let tempValues = result_data.map((item) => item.value);
			let tempMax = Math.max(...tempValues);
			setBarChartMaxValue(tempMax);
			setLoading(false);
		}
	};
	const filterData = (selectedEmotions, data = barChartResults, students) => {
		const _resultDataChart = data.map((e) => {
			const _opacity = selectedEmotions.includes(e.label.toLowerCase()) ? '' : '66';
			return { ...e, color: emotions_color[e.label.toLowerCase()] + _opacity };
		});
		setBarChartResults(_resultDataChart);
	};
	useEffect(() => {
		loadMyClass();
	}, []);
	const openLink = (link) => {
		history.push(link);
	};
	return (
		<div
			className='bg-white rounded-[10px] w-full flex justify-between h-[240px] items-center pointer'
			onClick={() => openLink('/classroom')}
		>
			<div className=' h-full flex items-center justify-between p-[35px] flex-col '>
				<div className='w-full flex flex-col'>
					<p className='font-bold text-[16px] leading-5 text-[#413F5E] mb-[5px]'>{t('yourClassroom')} </p>
					<p className='text-sm text-[#413F5E] font-medium '>{t('checkWhatsGoingOn')} </p>
				</div>
				<div className=' rounded-full bg-deepPurple flex font-bold items-center justify-center text-highlightColor w-[150px] h-[50px] hover:bg-highlightColor hover:text-deepPurple ease-in-out duration-[230ms] transition-all'>
					<p className='text-center text-[16px] leading-5'>{t('myClassroom')}</p>
				</div>
			</div>
			<div className='p-[35px] pl-0 w-full h-full flex justify-end'>
				<div className=' w-full h-full relative'>
					{!loading && (
						<BarChartClassroom
							resultDataChart={barChartResults}
							maxValue={barChartMaxValue}
							height={140}
							handleSelectedLabel={() => {}}
							showStripes={true}
							showCursor={false}
							showMash={false}
							showEmotions={true}
							emotionSize={20}
						/>
					)}
					{loading && (
						<div className='absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center'>
							<Loading size={32} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default YourClassroom;
