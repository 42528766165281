import React, { useEffect } from 'react';
import { useState } from 'react';
import { getStudentDailyQuestionUsage } from '../../server/server';
import '../../styles/dashboard/WelcomeBanner.css';
import DonutChartDash from './components/DonutChartDash';
import WelcomeBannerImage from '../../assets/images/WelcomeBannerImage.png';
import { useUser } from '../../auth';
import { useTranslation } from 'react-i18next';
const WelcomeBanner = ({}) => {
	const [loadingStudentPercentage, setLoadingStudentPercentage] = useState(true);
	const [studentUsagePercentage, setStudentUsagePercentage] = useState(0.3);
	const { user } = useUser();
	const { t } = useTranslation();

	useEffect(() => {
		fetchStudentPercentage();
	}, []);

	const fetchStudentPercentage = async () => {
		const resp = await getStudentDailyQuestionUsage();
		if (resp.status === 200) {
			setLoadingStudentPercentage(false);
			setStudentUsagePercentage(resp.data);
		} else {
			setLoadingStudentPercentage(false);
		}
	};
	return (
		<div className='welcome-banner-container'>
			<img src={WelcomeBannerImage} alt='' className='absolute top-[0px]' width={226.82} height={177} />
			<div className='welcome-banner-text-container'>
				<h1 className='welcome-banner-text-title'>
					{t('welcomeBack')}, {user.first_name} {user.last_name}
				</h1>
				<p className='welcome-banner-text-subtitle'>{t('Here’s what happened while you were away')}...</p>
			</div>
			{/* <div className='welcome-banner-donut-container'>
				<DonutChartDash percentage={studentUsagePercentage} loading={loadingStudentPercentage} />
				<p className='welcome-banner-donut-text'>{t('ofStudentsUsingHowAreYouTodayAtLeastOncePerWeek')} </p>
			</div> */}
		</div>
	);
};

export default WelcomeBanner;
