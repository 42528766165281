import React from 'react';
import {
	getAdditionalFields,
	getById,
	getNumberOfWellbeingProfilesForReviewAsync,
	getSurveys,
	getTasks,
	getUnseenConversations,
	ifUserHasPrimaryClasses,
	ifUserHasRegularClasses,
	ifUserNeedsTutorial,
	logLoginTimeAsync,
} from './server';

export const loadUndoneTasks = async (user) => {
	const resp = await getTasks({ user_id: user.id, number_of_tasks: 0 });
	if (resp.status == 200) {
		const data = resp.data;
		return data?.tasks || [];
	}
	return [];
};
export const loadUndoneSurveys = async (user) => {
	const resp = await getSurveys();
	if (resp.status == 200) {
		const data = resp.data;
		return data?.data || [];
	}
	return [];
};
export const loadAssignedFeedbacks = async () => {
	const resp = await getNumberOfWellbeingProfilesForReviewAsync();
	if (resp.status == 200) {
		const data = resp.data;
		return data || [];
	}
	return [];
};

export const loadUnseenConversations = async () => {
	const resp = await getUnseenConversations();
	if (resp.status == 200) {
		return resp?.data || [];
	}
	return [];
};

export const loadSchool = async (user) => {
	const resp = await getById({ collection: 'institutions', id: user?.institution_id });
	if (resp.status == 200) {
		return resp?.data || {};
	}
	return {};
};

export const checkIfUserNeedsTutorial = async () => {
	const resp = await ifUserNeedsTutorial();
	if (resp.status == 200) {
		return resp?.data ?? false;
	}
	return false;
};
export const checkIfTeacherHasPrimaryClasses = async () => {
	const resp = await ifUserHasPrimaryClasses();
	if (resp.status == 200) {
		return resp?.data ?? false;
	}
	return false;
};
export const checkIfTeacherHasRegularClasses = async () => {
	const resp = await ifUserHasRegularClasses();
	if (resp.status == 200) {
		console.log('Regular classes: ', resp?.data);
		return resp?.data ?? false;
	}
	return false;
};

export const logLoginTime = async (data, write = true) => {
	const resp = await logLoginTimeAsync(data);
	if (resp.status != 200) {
		let restLogins = JSON.parse(localStorage.getItem('loginsLogs') || '[]');
		restLogins.push(data);
		if (write) localStorage.setItem('loginsLogs', JSON.stringify(restLogins));
		return false;
	}
	return true;
};
export const loadAdditionalFields = async () => {
	const resp = await getAdditionalFields();
	if (resp.status === 200) {
		let data = resp?.data ?? {};
		return data;
	}
	return {};
};
