import React, { useEffect } from 'react';
import { ReactComponent as Stop } from '../../../assets/svg/Stop.svg';
import { ReactComponent as EditContent } from '../../../assets/svg/EditContent.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/DownloadIcon.svg';
import { ReactComponent as DeleteContentIcon } from '../../../assets/svg/DeleteContentIcon.svg';
import { ReactComponent as NotFoundIcon } from '../../../assets/svg/NotFoundIcon.svg';
import { baseUrl, videoUrl } from '../../../constants/Connections';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { capitaliseFirstLetter, format_day, translateTargetText } from '../../../utils/util';
import ProgressBar from '../../../components/ProgressBar';
import { fileSystemUrl } from '../../../constants/Connections';
import { useUser } from '../../../auth';
import BarCharts from '../../dashboard/BarCharts';
import { BarColors } from '../../../constants/BarColors';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { stopContent } from '../../../server/server';
import BigImageModal from '../BigImageModal';
import { width } from '@mui/system';
import ImageWithPlaceholder from '../../dashboard/components/ImageWithPlaceholder';
import i18n from '../../../i18n';
import ContentResultFilter from './ContentResultFilter';

function ContentPollResults({ content, setRefresh, deleteContent }) {
	const { t, locale } = useTranslation();
	const [exporting, setExporting] = useState(false);
	const { user, setUser } = useUser();
	const [loading, setLoading] = useState(true);
	const [results, setResults] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);
	const [allYears, setAllYears] = useState({});
	const [selectedClass, setSelectedClass] = useState('all');
	const [selectedYear, setSelectedYears] = useState('all');
	const [searchText, setSearchText] = useState('');
	const [resetTrigger, setResetTrigger] = useState(new Date());
	const [initialLoading, setInitialLoading] = useState(true);
	const [loadingImage, setLoadingImage] = useState(true);
	const [selectedImage, setSelectedImage] = useState('');
	const [imageModalOpen, setImageModalOpen] = useState(false);
	const imgUrl = `${fileSystemUrl}/${content?.files?.storage_name}?token=${user?.token}`;

	const exportData = async () => {
		setExporting(true);
		const token = localStorage.getItem('token');
		const data = content;
		var req = {
			method: 'POST',
			url: `${baseUrl}/api/export_dashboard_content`,
			responseType: 'arraybuffer',
			headers: { Authorization: token },
			data: data,
		};
		var reader = new FileReader();

		reader.onload = function (e) {
			window.open(decodeURIComponent(reader.result), '_self', '', false);
		};

		axios(req).then(
			function (response) {
				var contentType = response.headers['content-type'];
				var blob = new Blob([response.data], { type: contentType });

				var a = document.createElement('a');
				document.body.appendChild(a);
				a.style = 'display: none';

				var url = window.URL.createObjectURL(blob);
				a.href = url;

				a.download = `Content data-${content?.title}.xlsx`;
				a.click();
				setExporting(false);
			},
			function (resp) {}
		);
	};

	useEffect(() => {
		const users = content.users;
		let yearsDict = {};

		for (let i = 0; i < users.length; i++) {
			const user = users[i];
			const year = user.year;
			const className = user.classesName;

			if (!yearsDict[year]) {
				yearsDict[year] = new Set();
			}
			yearsDict[year].add(className);
		}

		for (const year in yearsDict) {
			yearsDict[year] = Array.from(yearsDict[year]);
		}
		setAllUsers(users);
		setFilteredUsers(users.map((e) => e.id));
		setFilteredResults(content);
		setAllYears(yearsDict);
		setInitialLoading(false);
	}, []);

	const stop = async () => {
		const resp = await stopContent({ id: content?.id });
		if (resp.status == 200) {
			content.status = 'stop';
			setRefresh(content?.id);
		}
	};

	const openBigImage = (url) => {
		setSelectedImage(url);
		setImageModalOpen(true);
	};

	const closeImageModal = () => {
		setImageModalOpen(false);
		setSelectedImage('');
	};

	const submitClassFilter = (filterData) => {
		const className = filterData.class;
		const yearName = filterData.year;
		let users = [];
		if (className === 'all' && yearName === 'all') {
			users = allUsers.map((e) => e.id);
		} else if (yearName !== 'all' && className === 'all') {
			users = allUsers.filter((e) => e.year === yearName).map((e) => e.id);
		} else {
			users = allUsers.filter((e) => e.classesName === className).map((e) => e.id);
		}
		setSearchText('');
		setFilteredUsers(users);
		setSelectedClass(className);
		setSelectedYears(yearName);
		const newAnswersUsers = content?.answeredUsers?.filter((e) => users.includes(e.id));
		const filteredResults = { ...content, answeredUsers: newAnswersUsers, answers: newAnswersUsers?.map((e) => e.answer) };
		setFilteredResults(filteredResults);
	};

	const doSearch = (text) => {
		if (text === '') {
			setFilteredUsers([...allUsers].map((e) => e.id));
			setFilteredResults(content);
		} else {
			const users = allUsers.filter((e) => `${e.first_name} ${e.last_name}`.toLowerCase().includes(text)).map((e) => e.id);
			const newAnswersUsers = content?.answeredUsers?.filter((e) => users.includes(e.id));
			const filteredResults = { ...content, answeredUsers: newAnswersUsers, answers: newAnswersUsers?.map((e) => e.answer) };
			setFilteredResults(filteredResults);
			setFilteredUsers(users);
		}
		setResetTrigger(new Date());
	};

	useEffect(() => {
		if (!initialLoading) {
			const delayDebounceFn = setTimeout(() => {
				doSearch(searchText.toLowerCase());
			}, 400);
			return () => clearTimeout(delayDebounceFn);
		}
	}, [searchText]);

	const hasImage = !!content.files;
	const isVideo = content?.files?.type === 'video';
	const mediaUrl = hasImage ? `${isVideo ? videoUrl : fileSystemUrl}/${content.files.storage_name}?token=${user.token}` : '';

	return (
		<div>
			<div className='bg-containerGray w-full p-5 px-6 rounded-xl'>
				<div className='flex justify-between items-center'>
					<h2 className='font-bold text-xl  text-deepPurple text-left '>{capitaliseFirstLetter(t(content?.type))}</h2>
					{/* <p className='text-mudPurple text-[16px]'>{content?.status == 'start' ? t('live') : t('archived')}</p> */}
				</div>
				{content?.status == 'stop' ? (
					<div
						className='border pointer bg-[#efeff3] fill-mudPurple hover:fill-white hover:bg-deselectPurple text-mudPurple hover:text-white mt-2 border-deselectPurple py-3 px-5 rounded-full w-full flex justify-center items-center'
						onClick={exportData}
					>
						<span className='text-[16px] font-semibold  mr-3'>{t('download')} </span>{' '}
						<DownloadIcon style={{ fill: 'inherit' }} />
					</div>
				) : (
					<div className='flex flex-row w-full justify-between items-center mt-4'>
						<div
							className='border pointer fill-mudPurple hover:fill-white hover:bg-deselectPurple text-mudPurple hover:text-white border-deselectPurple py-3 px-5 rounded-full w-52 flex justify-center items-center'
							onClick={stop}
						>
							<span className='text-[16px] font-semibold  mr-3'>{t('stop')} </span> <Stop style={{ fill: 'inherit' }} />
						</div>
						<Link to={`/content?editId=${content?.id}`}>
							<div className='border pointer fill-mudPurple hover:fill-white hover:bg-deselectPurple text-mudPurple hover:text-white border-deselectPurple py-3 px-5 rounded-full w-52 flex justify-center items-center'>
								<span className='text-[16px] font-semibold  mr-3'>{t('edit')} </span>{' '}
								<EditContent style={{ fill: 'inherit' }} />
							</div>
						</Link>
						<div
							className='border pointer fill-mudPurple hover:fill-white hover:bg-deselectPurple text-mudPurple hover:text-white border-deselectPurple py-3 px-5 rounded-full w-52 flex justify-center items-center'
							onClick={exportData}
						>
							<span className='text-[16px] font-semibold  mr-3'>{t('download')}</span>{' '}
							<DownloadIcon style={{ fill: 'inherit' }} />
						</div>
						<div
							className='border pointer fill-deleteRed hover:fill-white hover:bg-deleteRed text-deleteRed hover:text-white border-deleteRed py-3 px-5 rounded-full w-52 flex justify-center items-center'
							onClick={() => deleteContent(content)}
						>
							<span className='text-[16px] font-semibold  mr-3'>{t('delete')} </span>{' '}
							<DeleteContentIcon style={{ fill: 'inherit' }} />
						</div>
					</div>
				)}
				{!!allUsers.length && content?.target_text !== 'Staff' && (
					<ContentResultFilter
						data={allYears}
						setSearchText={setSearchText}
						searchText={searchText}
						submitClassFilter={submitClassFilter}
						resetTrigger={resetTrigger}
					/>
				)}
			</div>
			<div className='px-2 py-3 rounded-xl'>
				<div className='gray-small-scroll p-5' style={{ overflowY: 'scroll', height: '50vh' }}>
					<div className='flex flex-row gap-5 '>
						{/* {content?.files && <div>{showImage(content?.files, 150)}</div>} */}
						{hasImage &&
							(isVideo ? (
								<div className='simage-container-new min-w-[200px] !max-w-[200px]'>
									<video controls controlsList='nodownload'>
										<source src={mediaUrl} />
										{t('yourBrowserDoesNotSupportHTMLVideo')}
									</video>
								</div>
							) : (
								<div
									className=' min-w-[150px] !max-w-[150px] pointer'
									style={{
										marginRight: i18n.language == 'ar' ? 0 : 20,
										marginLeft: i18n.language == 'ar' ? '20' : 0,
									}}
									onClick={() => openBigImage(imgUrl)}
								>
									<ImageWithPlaceholder url={mediaUrl} altText={content?.title} width={150} />
								</div>
							))}
						<div className='flex-1'>
							<div className='flex justify-between items-center'>
								<span className='text-mudPurple font-medium text-sm'>
									<span className='text-[#9392ad99]'>{t('target')}:</span> {translateTargetText(content?.target_text)}
								</span>
								<p>
									<span className='text-[#9392ad99] font-medium text-sm'>
										{t('date')} & {t('time')}:{' '}
									</span>
									<span className='text-mudPurple font-medium text-sm'>
										{format_day(content?.publicationDate, true, i18n.language)}
									</span>
								</p>
							</div>
							<div className='single-content-body my-3 flex justify-between items-start'>
								<h2 className='text-deepPurple font-bold text-xl text-left max-w-xl'>{content?.title}</h2>
								<p className='text-right text-sm text-mudPurple font-medium'>
									{content?.answeredUsers?.length}/{content?.target_count}
								</p>
							</div>
							<p className='text-sm text-mudPurple text-left mt-3'>{content?.message ?? content?.description}</p>
						</div>
					</div>
					<div className='mt-4'>
						<ProgressBar bgcolor={'#5E639B'} completed={Math.round((content?.answers?.length / content?.target_count) * 100)} />
					</div>

					{filteredResults?.answeredUsers?.length > 0 ? (
						<div>
							{content?.type === 'poll' && (
								<div className='mt-6'>
									<BarCharts
										options={content?.options}
										answers={content?.answers}
										showNum={true}
										subData={filteredResults.answers}
										haveSubData
									/>
								</div>
							)}
							<h2 className='font-bold text-xl  text-deepPurple text-left mt-4'>{t('answers')} </h2>
							<div>
								{filteredResults?.answeredUsers?.map((a, index) => {
									return (
										<div
											className={`bg-containerGray w-full  py-2 px-2 flex items-center justify-start text-left my-2 rounded-xl ${
												content?.type === 'poll' ? 'pointer' : ''
											}`}
											key={index}
										>
											<div
												className='min-w-[10px] h-[10px] rounded-full mr-3'
												style={{
													background:
														content?.type == 'poll'
															? BarColors[content?.options?.indexOf(a?.answer) % BarColors?.length]
															: BarColors[0],
												}}
											></div>
											<div className='flex flex-col justify-between max-w-[calc(100%-22px)]'>
												<p className='text-[16px] font-bold text-deepPurple'>
													{a?.first_name} {a?.last_name}
												</p>
												<p className='text-sm text-mudPurple w-full break-words'>{a?.answer}</p>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					) : (
						<div className='flex justify-center w-full flex-col items-center my-10'>
							<NotFoundIcon />
							<span className='text-base text-deepPurple font-bold mt-5'>{t('thereIsntAnyAnswerYet')} </span>
						</div>
					)}
				</div>
			</div>
			<BigImageModal modalIsOpen={imageModalOpen} closeModal={closeImageModal} selectedImage={selectedImage} />
		</div>
	);
}

export default ContentPollResults;
