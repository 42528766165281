// fox
import { ReactComponent as FoxOk } from './fox/fox_ok.svg';
// panda
import { ReactComponent as PandaOk } from './panda/panda_ok.svg';
// tiger
import { ReactComponent as TigerOk } from './tiger/tiger_ok.svg';
// raccoon
import { ReactComponent as RaccoonOk } from './raccoon/raccoon_ok.svg';
// shark
import { ReactComponent as SharkOk } from './shark/shark_ok.svg';
// hamster
import { ReactComponent as HamsterOk } from './hamster/hamster_ok.svg';
// penguin
import { ReactComponent as PenguinOk } from './penguin/penguin_ok.svg';
// bear
import { ReactComponent as BearOk } from './bear/bear_ok.svg';
// octopus
import { ReactComponent as OctopusOk } from './octopus/octopus_ok.svg';
// dog
import { ReactComponent as DogOk } from './dog/dog_ok.svg';
// bird
import { ReactComponent as BirdOk } from './bird/bird_ok.svg';
// koala
import { ReactComponent as KoalaOk } from './koala/koala_ok.svg';
// giraffe
import { ReactComponent as GiraffeOk } from './giraffe/giraffe_ok.svg';
// otter
import { ReactComponent as OtterOk } from './otter/otter_ok.svg';
// deer
import { ReactComponent as DeerOk } from './deer/deer_ok.svg';
// bunny
import { ReactComponent as BunnyOk } from './bunny/bunny_ok.svg';
// elephant
import { ReactComponent as ElephantOk } from './elephant/elephant_ok.svg';
// zebra
import { ReactComponent as ZebraOk } from './zebra/zebra_ok.svg';
// sugarGlider
import { ReactComponent as SugarGliderOk } from './sugarGlider/sugarGlider_ok.svg';
// leopard
import { ReactComponent as LeopardOk } from './leopard/leopard_ok.svg';
// import LeopardAngry from './leopard/leopard_angry.svg';
// fish
import { ReactComponent as FishOk } from './fish/fish_ok.svg';
// darkBear
import { ReactComponent as DarkBearOk } from './darkBear/darkBear_ok.svg';
// import DarkBearAngry from './darkBear/darkBear_angry.svg';
// kitty
import { ReactComponent as KittyOk } from './kitty/kitty_ok.svg';
// beaver
import { ReactComponent as BeaverOk } from './beaver/beaver_ok.svg';
// frog
import { ReactComponent as FrogOk } from './frog/frog_ok.svg';
// hedgeHog
import { ReactComponent as HedgeHogOk } from './hedgeHog/hedgeHog_ok.svg';
// lion
import { ReactComponent as LionOk } from './lion/lion_ok.svg';
// lama
import { ReactComponent as LamaOk } from './lama/lama_ok.svg';
// owl
import { ReactComponent as OwlOk } from './owl/owl_ok.svg';
// rabbit
import { ReactComponent as RabbitOk } from './rabbit/rabbit_ok.svg';

const ANIMAL_WIDTH = 240;

export const animalImages = {
	fox: {
		ok: <FoxOk width={ANIMAL_WIDTH} />,
	},
	panda: {
		ok: <PandaOk width={ANIMAL_WIDTH} />,
	},
	tiger: {
		ok: <TigerOk width={ANIMAL_WIDTH} />,
	},
	raccoon: {
		ok: <RaccoonOk width={ANIMAL_WIDTH} />,
	},
	shark: {
		ok: <SharkOk width={ANIMAL_WIDTH} />,
	},
	hamster: {
		ok: <HamsterOk width={ANIMAL_WIDTH} />,
	},
	penguin: {
		ok: <PenguinOk width={ANIMAL_WIDTH} />,
	},
	bear: {
		ok: <BearOk width={ANIMAL_WIDTH} />,
	},
	octopus: {
		ok: <OctopusOk width={ANIMAL_WIDTH} />,
	},
	dog: {
		ok: <DogOk width={ANIMAL_WIDTH} />,
	},
	bird: {
		ok: <BirdOk width={ANIMAL_WIDTH} />,
	},
	koala: {
		ok: <KoalaOk width={ANIMAL_WIDTH} />,
	},
	giraffe: {
		ok: <GiraffeOk width={ANIMAL_WIDTH} />,
	},
	otter: {
		ok: <OtterOk width={ANIMAL_WIDTH} />,
	},
	deer: {
		ok: <DeerOk width={ANIMAL_WIDTH} />,
	},
	bunny: {
		ok: <BunnyOk width={ANIMAL_WIDTH} />,
	},
	elephant: {
		ok: <ElephantOk width={ANIMAL_WIDTH} />,
	},
	zebra: {
		ok: <ZebraOk width={ANIMAL_WIDTH} />,
	},
	sugarGlider: {
		ok: <SugarGliderOk width={ANIMAL_WIDTH} />,
	},
	leopard: {
		ok: <LeopardOk width={ANIMAL_WIDTH} />,
	},
	fish: {
		ok: <FishOk width={ANIMAL_WIDTH} />,
	},
	darkBear: {
		ok: <DarkBearOk width={ANIMAL_WIDTH} />,
	},
	kitty: {
		ok: <KittyOk width={ANIMAL_WIDTH} />,
	},
	beaver: {
		ok: <BeaverOk width={ANIMAL_WIDTH} />,
	},
	frog: {
		ok: <FrogOk width={ANIMAL_WIDTH} />,
	},
	hedgeHog: {
		ok: <HedgeHogOk width={ANIMAL_WIDTH} />,
	},
	lion: {
		ok: <LionOk width={ANIMAL_WIDTH} />,
	},
	lama: {
		ok: <LamaOk width={ANIMAL_WIDTH} />,
	},
	owl: {
		ok: <OwlOk width={ANIMAL_WIDTH} />,
	},
	rabbit: {
		ok: <RabbitOk width={ANIMAL_WIDTH} />,
	},
};
