import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from '../../assets/svg/LeftArrow.svg';

function DayPickModule({ day, setDay, disabled = false }) {
	const { t, locale } = useTranslation();

	const handleChange = (dayp) => {
		if (dayp > 30) {
			setDay(1);
		} else {
			if (dayp < 1) {
				setDay(30);
			} else {
				setDay(dayp);
			}
		}
	};
	return (
		<div className='flex justify-center items-center flex-col '>
			<div className='flex items-center justify-center gap-3'>
				<button disabled={disabled} onClick={(e) => handleChange(day - 1)}>
					<Arrow className='' />
				</button>
				<div className='bg-lightBG rounded-[5px] min-w-[78px] h-[40px] flex flex-row justify-between items-center p-[10px]'>
					<p className='text-base font-semibold text-left text-[#413F5E]'>{t('day')}:</p>
					<span className='text-[#5E639B] font-bold w-[20px]'>{day.toString().padStart(2, 0)}</span>
				</div>
				<button onClick={(e) => handleChange(day + 1)} disabled={disabled}>
					<Arrow className='pointer' style={{ transform: 'scaleX(-1)' }} />
				</button>
			</div>
		</div>
	);
}

export default DayPickModule;
