import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FaTimes, FaChevronLeft, FaChevronRight, FaCamera } from 'react-icons/fa';
import '../../styles/content/poll.css';
import TargetGroupModal from '../modals/TargetGroupModal';
import PollScheduleModal from '../modals/PollScheduleModal';
import { ContentContext } from './ContentContext';
import { useUser } from '../../auth';
import { getAllStudentsTarget, upload, create, update, createContent, createTemplate } from '../../server/server';
import { notify, translateTargetText } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import RepeatModal from '../modals/RepeatModal';

function Question() {
	let history = useHistory();
	const { t, locale } = useTranslation();
	const [contentItem, setContentItem] = useContext(ContentContext);
	const titleRef = useRef(null);
	const descRef = useRef(null);

	const [isError, setIsError] = useState(false);
	const [toggleOptions, setToggleOptions] = useState({ anon: false, tWell: false });

	const [showTargetGroupModal, setShowTargetGroupModal] = useState(false);
	const [showScheduleModal, setShowScheduleModal] = useState(false);

	const [titleValue, setTitleValue] = useState('');
	const [descValue, setDescValue] = useState('');
	const [image, setImage] = useState(undefined);
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [editFile, setEditFile] = useState({});
	const { user, setUser } = useUser();
	const [showRepeatModal, setShowRepeatModal] = useState(false);

	const [returnSchedule, setReturnSchedule] = useState({
		enabled: false,
		text: 'Off',
		recurrentText: 'Never',
		repeatEnabled: false,
		schedule_date: 0,
	});
	const [returnRepeat, setReturnRepeat] = useState({
		repeat: false,
		repeatPeriodText: 'Never',
		recurrentType: '',
		selectedDay: 0,
		selectedDays: [],
		endOfRecurrenceDate: 0,
	});
	const [returnTarget, setReturnTarget] = useState({
		name: 'My students',
		target: [],
		selection: [],
		type: 'root',
	});
	const loadAllTarget = async () => {
		const response = await getAllStudentsTarget();
		if (response.status === 200) {
			setReturnTarget(response.data);
		}
	};

	useEffect(() => {
		if (contentItem.edit) {
			console.log('returnTArget', contentItem?.editTarget);
			setReturnTarget(contentItem?.editTarget);
			setReturnSchedule(contentItem?.editschedule);
			if (contentItem.files?.storage_name) {
				setEditFile(contentItem?.files);
			}
			setTitleValue(contentItem?.title);
			setToggleOptions({ anon: contentItem?.isAnon ?? false, tWell: contentItem?.trackWellbeing ?? false });
			setDescValue(contentItem.msg);
			if (contentItem.imgUrl) {
				fetchImg();
				descRef.current.style.height = '100px';
			} else {
				descRef.current.style.minHeight = descRef.current.style.height;
			}
			if (contentItem?.editTarget?.name === 'My students' || contentItem?.editTarget?.target === undefined) {
				loadAllTarget();
			}
		} else {
			loadAllTarget();
		}
	}, []);

	const fetchImg = async () => {
		await fetch(contentItem?.imgUrl)
			.then((res) => res.blob())
			.then((blob) => {
				const objectUrl = URL.createObjectURL(blob);
				setImage(objectUrl);
				return () => {
					console.log('remove img');
					URL.revokeObjectURL(objectUrl);
				};
			});
	};
	const cleanContext = () => {
		setContentItem({
			edit: false,
			type: 'question',
			title: '',
			msg: '',
			options: ['', '', ''],
			link: '',
			imgUrl: '',
			returnID: '',
			files: {
				old_name: '',
				storage_name: '',
				size: null,
				type: 'image',
			},
			editTarget: {
				name: 'My students',
				target: [],
				selection: [],
				type: 'root',
			},
			editschedule: {
				enabled: false,
				text: 'Off',
				recurrentText: 'Never',
				repeatEnabled: false,
				schedule_date: 0,
			},
			editRepeat: {
				repeat: false,
				repeatPeriodText: 'Never',
				recurrentType: '',
				selectedDay: 0,
				selectedDays: [],
				endOfRecurrenceDate: 0,
			},
			likes: 0,
			views: 0,
			isReq: false,
			trackWellbeing: false,
			addedToAnalytics: false,
			repeat: false,
			isAnon: false,
			editID: null,
			programDetails: {
				programId: '',
				dayIndex: 0,
			},
		});
	};

	const handleBack = () => {
		cleanContext();
		switch (contentItem.edit) {
			case 'template':
				history.push('/content/templates');
				break;
			case 'list':
				history.push('/content/list');
				break;
			case 'program':
				history.push(`/content/program?id=${contentItem.returnID}`);
				break;
			case 'feed':
				history.push(`/feeds`);
				break;
			case '':
				history.push('/content/');
				break;
			default:
				history.push('/content/');
				break;
		}
	};

	const closeImage = () => {
		setImage(undefined);
		setSelectedFile(undefined);
	};

	useEffect(() => {
		if (!selectedFile) {
			setImage(undefined);
			console.log('undefined img');
			return;
		}
		console.log('set img');
		const objectUrl = URL.createObjectURL(selectedFile);
		setImage(objectUrl);
		return () => {
			console.log('remove img');
			URL.revokeObjectURL(objectUrl);
		};
	}, [selectedFile]);
	const DEFAULT_DATA = {
		anonymous: toggleOptions?.anon == false ? null : true,
		created_date: Date.now(),
		device: 'desktop',
		gender_filter: 'all',
		institution_id: user?.institution_id,
		likes: 0,
		message: descValue,
		required: false,
		trackWellbeing: toggleOptions?.tWell == false ? null : true,
		schedule: returnSchedule?.enabled,
		schedule_info: {
			enabled: returnSchedule?.enabled,
			recurrentText: returnSchedule?.recurrentText,
			repeatEnabled: returnSchedule?.repeatEnabled,
			text: returnSchedule?.text,
			schedule_date: returnSchedule?.schedule_date == 0 ? null : returnSchedule?.schedule_date,
		},
		status: 'start',
		target: returnTarget?.target ? returnTarget.target.map((a) => a.id) : [],
		targetOptions: {
			selection: returnTarget?.selection,
			type: returnTarget?.type,
		},
		target_count: returnTarget?.target?.length,
		target_text: returnTarget?.name,
		title: titleValue,
		type: 'question',
		unfilteredTarget: returnTarget?.target,
		user_id: user?.id,
		views: 0,
		url: undefined,
		programDetails: contentItem?.programDetails?.programId ? contentItem?.programDetails : undefined,
		schedule_date: returnSchedule?.schedule_date == 0 ? null : returnSchedule?.schedule_date,
		repeatPeriodText: returnRepeat?.repeatPeriodText,
		repeatData: {
			repeat: returnRepeat?.repeat,
			recurrentType: returnRepeat?.recurrentType == '' ? null : returnRepeat?.recurrentType,
			selectedDay: returnRepeat?.selectedDay,
			selectedDays: returnRepeat?.selectedDays == [] ? null : returnRepeat?.selectedDays,
			endOfRecurrenceDate: returnRepeat?.endOfRecurrenceDate == 0 ? null : returnRepeat?.endOfRecurrenceDate,
			repeatPeriodText: returnRepeat?.repeatPeriodText,
		},
		repeat: returnRepeat?.repeat,
	};
	const uploadFile = async (postType) => {
		const req = await upload({ file: selectedFile, type: 'image' });
		console.log('igmeag', selectedFile, image);
		if (req) {
			console.log('tteatea', req);

			switch (postType) {
				case 'update':
					updateContent(req.data);
					break;
				case 'post':
					postQuestion(req.data);
					break;
				case 'template':
					saveTemplate(req.data);
					break;
				default:
					console.log('error');
					break;
			}
		}
	};

	const postQuestion = async (propFiles) => {
		const PostData = { ...DEFAULT_DATA, files: propFiles };

		const resp = await createContent({
			data: PostData,
		});
		if (resp.status === 200) {
			handleBack();
			notify(t('contentIsCreated'));
		} else {
			notify(t('contentIsNotCreated'));
		}
	};
	const updateContent = async (propFiles) => {
		const updateFiles = propFiles ? propFiles : image ? contentItem?.files : undefined;
		const updateData = {
			...DEFAULT_DATA,
			likes: contentItem?.likes,
			views: contentItem?.views,
			files: updateFiles,
			id: contentItem?.editID,
		};
		console.log(updateData);
		const resp = await createContent({
			data: updateData,
		});
		if (resp.status === 200) {
			handleBack();
			notify(t('yourDataIsUpdated'));
		} else {
			notify(t('yourDataIsNotUpdated'));
		}
	};
	const saveTemplate = async (propFiles) => {
		const updateFiles = propFiles ? propFiles : image ? contentItem.files : undefined;
		const PostData = { ...DEFAULT_DATA, files: updateFiles };
		const resp = await createTemplate({ data: PostData });
		if (resp.status === 200) {
			handleBack();
			notify(t('templateIsCreated'));
		} else {
			notify(t('templateIsNotCreated'));
		}
	};
	const handlePost = () => {
		if (titleValue === '') {
			setIsError(true);
		} else {
			if (contentItem.editID) {
				if (selectedFile) {
					uploadFile('update');
				} else {
					updateContent();
				}
			} else {
				if (selectedFile) {
					uploadFile('post');
				} else {
					postQuestion();
				}
			}
		}
	};
	const handleTemplateSave = () => {
		if (titleValue === '') {
			setIsError(true);
		} else {
			if (selectedFile) {
				uploadFile('template');
			} else {
				saveTemplate();
			}
		}
	};

	const onImageChange = (e) => {
		console.log(image);
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		setSelectedFile(e.target.files[0]);
	};

	const openTargetGroupModal = () => {
		console.log('otvara modal');
		setShowTargetGroupModal(true);
	};
	const closeTargetGroupModal = () => {
		console.log('zatvara modal');
		setShowTargetGroupModal(false);
	};

	const openScheduleModal = () => {
		console.log('otvara modal');
		setShowScheduleModal(true);
	};
	const closeScheduleModal = () => {
		console.log('zatvara modal');
		setShowScheduleModal(false);
	};

	const press_upload = () => {
		document.getElementById('photo_upload_modal')?.click();
	};
	const resetInputValue = (e) => {
		e.target.value = null;
	};
	const openRepeatModal = () => {
		console.log('otvara modal');
		setShowRepeatModal(true);
	};
	const closeRepeatModal = () => {
		console.log('zatvara modal');
		setShowRepeatModal(false);
	};
	useEffect(() => {
		titleRef.current.style.height = '80px';
		const scrollHeight = titleRef.current.scrollHeight;
		titleRef.current.style.height = scrollHeight - 30 + 'px';
	}, [titleValue]);
	useEffect(() => {
		descRef.current.style.height = '100px';
		const scrollHeight = descRef.current.scrollHeight;
		descRef.current.style.height = scrollHeight - 10 + 'px';
	}, [descValue]);
	return (
		<div className='content'>
			<div className='row'>
				<div className='col-lg-8 col-xl-6' id='col8'>
					<div className={`poll-card ${i18n.language == 'ar' ? 'ar-lang' : ''}`} style={{ display: 'flex' }}>
						<div className='poll-card-header'>
							<div className='poll-card-title'>
								<span
									className='back_button_poll content-back'
									onClick={() => {
										handleBack();
									}}
								>
									{i18n.language == 'ar' ? (
										<FaChevronRight id='back-program-arrow' />
									) : (
										<FaChevronLeft id='back-program-arrow' />
									)}
									{t('back')}
								</span>
								<h1>{t('createAQuestion')}</h1>
							</div>
							<textarea
								placeholder={isError ? "The Title can't be empty!" : t('typeAQuestionTitle')}
								id='autoExpand_title'
								ref={titleRef}
								value={titleValue}
								rows='1'
								className={isError ? 'error-title' : ''}
								onFocus={() => setIsError(false)}
								onChange={(e) => {
									setTitleValue(e.target.value);
								}}
							></textarea>
						</div>
						<div className='card_description' style={{ flex: '1', paddingBottom: `${image ? '0' : '0px'}` }}>
							<textarea
								placeholder={t('addAnOptionalMessage')}
								id='autoExpand_desc'
								rows='2'
								ref={descRef}
								value={descValue}
								onChange={(e) => {
									setDescValue(e.target.value);
								}}
							></textarea>
						</div>

						<div className='card_footer'>
							<div className='course-media-container' style={{ alignItems: 'center' }}>
								{!image && (
									<div className='upload-image-container'>
										<div className='upload-image-container-icon'>
											<FaCamera style={{ width: 42, height: 42, color: '#8484b1' }} />
										</div>
										<button
											className='upload-image-container-button mt-2'
											onClick={(e) => {
												e.preventDefault();
												press_upload();
											}}
										>
											{t('addPhoto')}
										</button>
									</div>
								)}
								{image && (
									<div className='upload-image-container'>
										<div
											style={{
												width: 340,
												maxHeight: 280,
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'space-between',
												alignItems: 'flex-end',
												backgroundColor: ' rgb(161, 184, 230)',
											}}
										>
											<div
												onClick={() => closeImage()}
												style={{
													padding: 10,
													borderRadius: 20,
													backgroundColor: 'white',
													opacity: 0.4,
													cursor: 'pointer',
												}}
											>
												<FaTimes
													style={{
														width: 20,
														height: 20,
														stroke: 'white',
														display: 'block',
													}}
												/>
											</div>

											<img
												style={{
													objectFit: 'contain',
													width: '100%',
													minHeight: 170,
													marginTop: 10,
												}}
												src={image}
												alt=''
											/>
										</div>
									</div>
								)}
								<input
									title='Add Video / Photo'
									style={{ display: 'none' }}
									accept='video/*, image/*'
									type='file'
									id='photo_upload_modal'
									onChange={onImageChange}
									onClick={resetInputValue}
								/>
							</div>

							<div
								className='feed-settings-container'
								style={i18n.language == 'ar' ? { marginLeft: 'auto', marginRight: '5px' } : {}}
							>
								<div
									className='feed-setting-item'
									onClick={() => setToggleOptions({ tWell: false, anon: !toggleOptions?.anon })}
								>
									<label className='feed-setting-item-title'>{t('anonymous')}</label>
									<label className='feed-setting-item-value'>{toggleOptions?.anon ? 'On' : 'Off'}</label>
								</div>
								<div
									className='feed-setting-item'
									onClick={() => setToggleOptions({ anon: false, tWell: !toggleOptions?.tWell })}
								>
									<label className='feed-setting-item-title'>{t('addToWellbeingTracker')}</label>
									<label className='feed-setting-item-value'>{toggleOptions?.tWell ? 'On' : 'Off'}</label>
								</div>
								<div className='feed-setting-item' onClick={() => openTargetGroupModal()}>
									<label className='feed-setting-item-title'>{t('targetGroup')}</label>
									<label className='feed-setting-item-value'>
										<b>{translateTargetText(returnTarget?.name)}</b>
									</label>
								</div>
								<div className='feed-setting-item' onClick={() => openScheduleModal()}>
									<label className='feed-setting-item-title'>{t('schedule')}</label>
									<label className='feed-setting-item-value'>
										<b>
											{returnSchedule?.enabled
												? new Date(returnSchedule?.schedule_date)
														.toISOString()
														.split('T', 2)
														.join(' ')
														.split(':', 2)
														.join(':')
												: t('off')}
										</b>
									</label>
								</div>
								<div
									className={`feed-setting-item ${returnSchedule?.enabled ? '' : 'hidden'}`}
									onClick={() => openRepeatModal()}
								>
									<label className='feed-setting-item-title'>{t('repeat')}</label>
									<label className='feed-setting-item-value'>
										<b>{returnRepeat?.repeatPeriodText}</b>
									</label>
								</div>

								<div className='feed-setting-spacer'></div>
								<div className='feed-submit-container'>
									<button className='feed-button-save-template' onClick={() => handleTemplateSave()}>
										{t('saveAsTemplate')}
									</button>
									<button
										className='feed-button-submit'
										style={i18n.language == 'ar' ? { marginLeft: 'auto', marginRight: '5px' } : {}}
										onClick={() => handlePost()}
									>
										{t('post')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<TargetGroupModal showTarget={showTargetGroupModal} closeTarget={closeTargetGroupModal} setReturnTarget={setReturnTarget} />
			<PollScheduleModal showSchedule={showScheduleModal} closeSchedule={closeScheduleModal} setReturnSchedule={setReturnSchedule} />
			<RepeatModal showRepeat={showRepeatModal} closeRepeat={closeRepeatModal} setReturnRepeat={setReturnRepeat} />
		</div>
	);
}

export default Question;
