import '../styles/feedsAndDashboard/TaskFeed.css';

const ProgressBar = (props) => {
	const { bgcolor, completed, backgroundColor = '#ecebf8' } = props;

	return (
		<div className='containerStyles' style={{ backgroundColor: backgroundColor }}>
			<div className='fillerStyles' style={{ width: `${Math.min(completed, 100)}%` }}>
				<div className='labelStyles' style={{ backgroundColor: bgcolor, height: 3, padding: 0 }}></div>
			</div>
		</div>
	);
};

export default ProgressBar;
