import React from 'react';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../context/AppContext';

function StudentsTable({ selectedClass, loading, openStudent, myFilter }) {
	const { t, locale } = useTranslation();
	const { appState } = useAppContext();
	const visibleAdditionalFields = appState?.additionalFields?.fields?.filter((e) => e.showInTable) ?? [];
	return (
		<div>
			<table style={{ width: '100%', borderCollapse: 'collapse' }}>
				<thead>
					{selectedClass?.primary !== true && (
						<tr className='teachers-header-row'>
							<th className='text-center' style={{ width: '5%' }}>
								#
							</th>
							<th style={{ width: '23%' }}>{t('name')}</th>
							<th style={{ width: '27%' }}>{t('email')}</th>
							{visibleAdditionalFields?.map((field) => (
								<th style={{ textAlign: 'center' }} key={field?.code}>
									{field.title}
								</th>
							))}
							<th style={{ width: '15%', textAlign: 'center' }}>{t('loggedIn')} </th>
							<th className='text-center'>{t('action')}</th>
						</tr>
					)}
					{selectedClass?.primary === true && (
						<tr className='teachers-header-row'>
							<th style={{ width: '10%' }}>#</th>
							<th style={{ width: '40%' }}>{t('name')}</th>
							<th style={{ width: '40%' }}>{t('Avatar')}</th>
							<th style={{ width: '10%' }} className=' text-center'>
								{t('action')}
							</th>
						</tr>
					)}
				</thead>
				<tbody>
					{!loading &&
						selectedClass?.students
							?.filter((student) => myFilter(student))
							.sort((a, b) => {
								if (a.first_name > b.first_name) return 1;
								return -1;
							})
							?.map((student, index) =>
								selectedClass?.primary !== true ? (
									<tr
										className='teachers-body-row'
										key={student.id}
										onClick={(e) => {
											e.preventDefault();
											openStudent(student);
										}}
									>
										<td className='teacher-text-center' style={{ width: '5%' }}>
											{index + 1}
										</td>
										<td style={{ width: '23%' }}>
											{student.first_name} {student.last_name}
										</td>
										<td className='teacher-email-text' style={{ width: '27%' }}>
											{student.email}
										</td>
										{visibleAdditionalFields.map((field) => (
											<td className='' style={{ textAlign: 'center' }}>
												{student[field?.code] ?? '-'}
											</td>
										))}
										<td style={{ width: '15%', textAlign: 'center' }}>{student?.loggedIn ? t('loggedIn') : '-'}</td>
										<td className='teacher-text-center underline-text'>
											<u>{t('manage')}</u>
										</td>
									</tr>
								) : (
									<tr
										className='teachers-body-row'
										key={student.id}
										onClick={(e) => {
											e.preventDefault();
											openStudent(student);
										}}
									>
										<td style={{ width: '10%' }}>{index + 1}</td>
										<td style={{ width: '40%' }}>
											{student.first_name} {student.last_name}{' '}
										</td>
										<td
											className={`capitalize ${!!student?.animal ? 'text-deepPurple' : 'text-deleteRed'}`}
											style={{ width: '40%' }}
										>
											{!!student.animal ? student.animal : t('Not Setuped')}
										</td>

										<td style={{ width: '10%' }} className='text-center underline-text'>
											<u>{t('manage')}</u>
										</td>
									</tr>
								)
							)}
					{selectedClass?.students.length === 0 && !loading && (
						<tr className='teachers-body-row-empty'>
							<td colSpan='5' className='teacher-text-center no-teachers-yet'>
								{t('noStudentsYet')}
							</td>
						</tr>
					)}
					{loading && (
						<tr className='teachers-body-row'>
							<td colSpan='5' className='teacher-text-center'>
								<div className='loading-center teacher-list-loader'>
									<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={75} stroke={4} />
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

export default StudentsTable;
