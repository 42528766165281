import React, { useState } from 'react';
import { ReactComponent as PreviousIcon } from '../../assets/svg/PreviousIcon.svg';
import { ReactComponent as PollIconNew } from '../../assets/svg/PollIconNew.svg';
import { format } from 'date-fns';
import '../../styles/feedsAndDashboard/TaskFeed.css';
import { fileSystemUrl, videoUrl } from '../../constants/Connections';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { answer } from '../../server/server';
import { notify } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import BigImageModal from '../modals/BigImageModal';
import Loading from '../Loading';

function SinglePoll({ task, user, loadTasks, setResultsNum, resultsNum, sameDay, openBigImage, checkUsersFeedbackNeeded }) {
	const url = `${fileSystemUrl}/${task?.files?.storage_name}?token=${user.token}`;
	const [loadingImage, setLoadingImage] = useState(true);
	const imgUrl = task.files ? url : false;
	const [answers, setAnswers] = useState([]);
	const { t, locale } = useTranslation();
	const [requesting, setRequesting] = useState(false);
	const [imageModalOpen, setImageModalOpen] = useState(false);
	const [showMore, setShowMore] = useState(false);

	const selectAnswer = (index) => {
		if (task.option_type?.name === 'Pick one option') {
			const answerIndex = answers.indexOf(index);
			if (answerIndex != -1) {
				setAnswers([]);
			} else {
				setAnswers([index]);
			}
		} else {
			const answerIndex = answers.indexOf(index);
			if (answerIndex != -1) {
				let newAnswers = answers.filter((ans, i) => ans !== index);
				setAnswers(newAnswers);
			} else {
				setAnswers([...answers, index]);
			}
		}
	};

	const handleImageLoading = () => {
		setLoadingImage(false);
	};
	const handleSubmit = async (e) => {
		const answersSubmit = task.options.filter((e, index) => answers.includes(index));
		e.preventDefault();
		setRequesting(true);
		if (!(answers.length === 0)) {
			const response = await answer({
				answers: answersSubmit,
				user_id: user.id,
				content_id: task.id,
				created_date: Date.now(),
				trackWellbeing: !!task?.trackWellbeing,
			});
			if (response.status === 200) {
				notify(t('yourAnswerIsSaved'));
				setAnswers([]);
				resultsNum !== 0 ? setResultsNum(0) : loadTasks();
				checkUsersFeedbackNeeded();
			} else {
				console.log('error');
			}
		}
		setRequesting(false);
	};

	const openInNewTab = (url) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	const hasImage = !!task.files;
	const isVideo = task?.files?.type === 'video';
	const mediaUrl = hasImage ? `${isVideo ? videoUrl : fileSystemUrl}/${task.files.storage_name}?token=${user.token}` : '';
	return (
		<div className={`bg-white ${sameDay ? 'mt-[10px]' : 'mt-[15px]'} rounded-xl w-full`}>
			<div className=' rounded-[10px] bg-containerGray p-[20px] flex flex-col w-full '>
				<div
					className='w-full flex flex-row gap-[10px] bg-containerGray rounded-[10px] '
					//onClick={() => changeSelectedMode('pollMode', task)}
				>
					{hasImage &&
						(isVideo ? (
							<div className='image-container-new max-w-none max-h-[300px]'>
								<video controls controlsList='nodownload' className='max-h-[300px]'>
									<source src={mediaUrl} />
									{t('yourBrowserDoesNotSupportHTMLVideo')}
								</video>
							</div>
						) : (
							<div className='image-container-new cursor-pointer' onClick={() => openBigImage(imgUrl)}>
								{loadingImage && (
									<SkeletonTheme baseColor='#dadaff' highlightColor='#efefef'>
										<Skeleton />
									</SkeletonTheme>
								)}
								<img
									src={`${url}${url.includes('https') ? '&width=450' : ''}`}
									alt='postfeed'
									width={40}
									onLoad={handleImageLoading}
								/>
							</div>
						))}
					<div className='w-full'>
						<div className='flex flex-row items-center'>
							<PollIconNew />
							<p className='ml-[10px] text-[16px] font-[500]  text-[#413F5E]'>{t('open_poll')}</p>
							<div className='flex-1'></div>
							<div className='text-[14px] leading-5 font-medium text-[#413F5E] opacity-60 mr-[5px]'>{t('time')}:</div>
							<div className='text-[14px] leading-5 font-medium text-[#413F5E]'>
								{format(new Date(task.publicationDate), 'HH:mm')}
							</div>
						</div>
						<div className='mt-[15px] text-[18px] leading-5 font-bold text-darkPurple'>{task.title}</div>
						{!!task.message && (
							<div className='mt-[10px]'>
								<pre className='text-[#9392AD] font-medium text-[14px] whitespace-pre-wrap '>
									{showMore ? task?.message : task?.message?.substring(0, 160)}
									{!showMore && task.message?.length > 160 && '...'}
									{task?.message?.length > 85 && (
										<span
											className='font-bold text-[#5E639B] pointer text-[14px]'
											onClick={() => setShowMore(!showMore)}
										>
											<br />
											{!showMore ? t('showMore') : t('showLess')}
										</span>
									)}
								</pre>
							</div>
						)}
						{task?.url && (
							<div
								className='bg-white w-full h-[40px] flex items-center justify-center text-mudPurple font-bold pointer text-[12px] rounded-[10px] mt-3'
								onClick={() => openInNewTab(task?.url)}
							>
								{t('openLink')}
							</div>
						)}
					</div>
				</div>
				<div className='h-[1px] mt-5 ' style={{ borderBottom: '1px dashed #BEBECE' }}></div>
				<p className='mt-[20px] text-[16px] font-semibold text-deepPurple'>{t('chooseTheAnswer')}</p>
				<div className='mt-[15px]'>
					{task.options?.map((option, index) => {
						return (
							<div
								className={`mb-[6px] rounded-[10px] flex items-center cursor-pointer min-h-[40px] pl-[20px] py-[10px] ${
									!answers.includes(index) ? 'bg-[#9392AD1A] hover:bg-[#9392AD40]' : 'bg-mudPurple'
								} `}
								onClick={(e) => {
									selectAnswer(index);
								}}
								key={index}
							>
								<p className={`text-[16px] font-medium  ${!answers.includes(index) ? 'text-[#5E639B]' : 'text-white'}`}>
									{option}
								</p>
							</div>
						);
					})}
				</div>
				<button
					className={`mt-[15px] rounded-[10px] h-[50px] flex items-center justify-center w-full ${
						answers.length === 0
							? 'bg-containerGray text-mudPurple'
							: 'bg-deepPurple text-white hover:text-highlightColor cursor-pointer'
					}`}
					onClick={(e) => handleSubmit(e)}
					disabled={answers.length === 0 || requesting}
					style={{ border: '1px solid #BEBECE' }}
				>
					{requesting ? (
						<Loading primaryColor='#ffffff' secondaryColor='#9392AD' size={25} stroke={7} />
					) : (
						<p className={`text-[14px] font-semibold  leading-5`}>{t('submit')} </p>
					)}
				</button>
			</div>
		</div>
	);
}

export default SinglePoll;
