import { ReactComponent as UpstriveEducationLogo } from '../../assets/svg/UpstriveEducationLogo.svg';
import '../../styles/analytics/AnalyticsHome.css';
import { ReactComponent as PreviousIcon } from '../../assets/svg/PreviousIcon.svg';
import { ReactComponent as MyselfIcon } from '../../assets/howAreYouSvg/MyselfIcon.svg';
import AnalyticsChart from './AnalyticsChart';
import BarChartClassroom from '../classroom/BarChartClassroom';
import GenderBarChart from './GenderBarChart';
import DonutAnalytic from './DonutAnalytic';
import DonutChartDash from '../dashboard/components/DonutChartDash';
import { useEffect, useReducer } from 'react';
import {
	emotionColorsArray,
	emotions_color,
	emotions_heads,
	emotions_list,
	primaryEmotionColorsArray,
	primary_emotions_list,
	reasonsJson,
} from '../../constants/Constants';
import { useUser } from '../../auth';
import { analyze } from '../../server/server';
import { analyzeMostReasons, nameCapitalized } from '../../utils/util';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';
import ClassroomEngagementTrendItem from '../classroom/ClassroomEngagementTrendItem';
import ReactTooltip from 'react-tooltip';

const AnalyticsDataContainer = ({
	initLoading = true,
	content = {},
	target,
	rangeChanged = new Date(),
	startDate = new Date(),
	endDate = new Date(),
	goBack = () => {},
	displayMode = 'regular',
}) => {
	const { user } = useUser();
	const { t } = useTranslation();
	const [state, setState] = useReducer((oldState, newState) => ({ ...oldState, ...newState }), {
		timeLapsData: [],
		barChartData: [],
		genderChartData: [],
		pieChartData: [],
		loading: true,
		loadingTimeLaps: true,
		totalComments: 0,
		totalEmotions: 0,
		engagementOnEmotions: {},
	});
	const {
		timeLapsData,
		barChartData,
		genderChartData,
		pieChartData,
		loading,
		loadingTimeLaps,
		totalComments,
		totalEmotions,
		engagementOnEmotions,
	} = state;
	const contentType = content.type;
	const isRegularDisplayMode = displayMode === 'regular';
	const listOfEmotions = isRegularDisplayMode ? emotions_list : primary_emotions_list;
	const colors = isRegularDisplayMode ? emotionColorsArray : primaryEmotionColorsArray;

	const options = contentType == 'feedback' ? listOfEmotions : content.options;

	useEffect(() => {
		if (!initLoading) {
			setState({ loadingTimeLaps: true });
			doAnalyze();
		}
	}, [target, rangeChanged, displayMode, initLoading]);

	const analyzeDataResults = (data, content) => {
		const keys = options;
		let ret = [];
		let index = 0;
		for (var key of keys) {
			let key_data = [];
			for (var day of data) {
				const value = day['data']?.filter((e) => e?.answer?.includes(key))?.length ?? 0;
				key_data.push({
					x: day['date'],
					y: value,
				});
			}
			ret.push({
				key: nameCapitalized(t(key)),
				values: key_data.reverse(),
				color: colors[index],
			});
			index += 1;
		}
		return ret;
	};

	const doAnalyze = async () => {
		const analyzeCriteria = {
			time_interval: [startDate.getTime(), endDate.getTime()],
			target: target,
			content: content,
			institution_id: user.institution_id,
		};
		const resp = await analyze(analyzeCriteria);
		if (resp.status == 200) {
			const respData = resp.data;
			const data = respData['all'];
			const dataByDays = respData['days'];
			const totalEmotions = respData?.totalEmotions;
			const timeLapsDataResults = analyzeDataResults(dataByDays, content);
			setState({ timeLapsData: timeLapsDataResults, loadingTimeLaps: false, engagementOnEmotions: respData['engagementOnEmotions'] });
			const values = timeLapsDataResults.map((e) => e.values.map((k) => k.y));
			const resultData = await Promise.all(
				options.map(async (e, index) => {
					const numberOfEmotion = values[index]?.reduce((a, b) => a + b, 0);
					const numberOfDaysWithEmotion = values[index]?.filter((e) => e > 0)?.length || 1;
					return {
						label: e,
						value: Number((numberOfEmotion / numberOfDaysWithEmotion)?.toFixed(1)),
						color: colors[index % colors.length],
					};
				})
			);
			setState({ barChartData: resultData, loadingTimeLaps: false, loading: false, totalEmotions });
			analyzeGenders(content, data);
		}
	};

	const analyzeGenders = async (content, data) => {
		const options = contentType === 'feedback' ? listOfEmotions : content.options;
		let genderChartData = [];
		let pieChartData = [];
		let totalComments = 0;
		let maxGenderValue = 0;
		const boysCount = data?.filter((e) => e.user.gender?.toLowerCase() === 'male').length;
		const girlsCount = data?.filter((e) => e.user.gender?.toLowerCase() === 'female').length;
		const allStudentsCount = boysCount + girlsCount;
		let index = 0;
		for (var option of options) {
			const optionData = data?.filter((e) => e?.answer?.includes(option));
			const value_boy = optionData?.filter((e) => e.user.gender?.toLowerCase() == 'male')?.length;
			const value_girl = optionData?.filter((e) => e.user.gender?.toLowerCase() == 'female')?.length;
			const numberOfMessages = optionData.length;
			const reasons =
				contentType === 'feedback' && isRegularDisplayMode ? analyzeMostReasons(optionData?.map((e) => e.reasons).flat(), 3) : [];
			genderChartData.push({
				label: option,
				boysValue: !!boysCount ? ((value_boy / boysCount) * 100).toFixed(1) : 0,
				girlsValue: !!girlsCount ? ((value_girl / girlsCount) * 100).toFixed(1) : 0,
			});
			pieChartData.push({
				label: option,
				value: !!allStudentsCount ? (((value_boy + value_girl) / allStudentsCount) * 100).toFixed(1) : 0,
				color: colors[index % colors.length],
				numberOfMessages: numberOfMessages,
				totalValue: value_boy + value_girl,
				mostReasons: reasons,
			});
			index += 1;
		}
		setState({ genderChartData: genderChartData, pieChartData: pieChartData, totalComments });
	};
	return (
		<div className='w-full bg-white rounded-[10px] p-[35px] mt-[30px] mb-12 '>
			<div className='flex flex-row items-center justify-between '>
				<div className='flex flex-row items-center gap-[13px]'>
					{!user.hasOnlyPrimaryStudents && (
						<div
							className='min-w-[46px] h-[46px] bg-[#5E639B] rounded-full flex items-center justify-center  cursor-pointer '
							onClick={goBack}
						>
							<PreviousIcon style={{ color: '#FDDF6D' }} />
						</div>
					)}
				</div>
				<p className='text-[32px] leading-5 text-darkenPurple font-bold'>{t(content.title)}</p>
				<div className='ml-14'></div>
			</div>
			<div className='h-[1px] bg-lightBG mt-[23px] mb-[20px]'></div>
			<div className='w-full '>
				<div id='lineChart1'>
					<AnalyticsChart data={timeLapsData} loading={loadingTimeLaps} showAdditionalText={target?.length > 7} />
				</div>
			</div>

			<div className='w-full'>
				<div className='flex flex-row items-center justify-between mt-[30px] mb-[35px]'>
					<p className='text-[24px] leading-5 text-[#413F5E] font-bold'>{t('barView')} </p>
					<p className='text-[16px] leading-5 text-darkenPurple font-medium'>{t('Emotion distribution for selected period')}</p>
				</div>
				<BarChartClassroom
					resultDataChart={barChartData}
					showCursor={false}
					showStripes={false}
					showEmotions={contentType === 'feedback'}
				/>
			</div>

			<div className='w-full'>
				<div className='flex flex-row items-center justify-between my-[20px]'>
					<div className='flex flex-row items-center gap-5'>
						<p className='text-[24px] leading-5 text-[#413F5E] font-bold'>{t('gender')} </p>
						<div className='gender-barchart-legend'>
							<div className='gender-boys-legend'>
								<div className='boys-circle-blue'></div>
								<p>{t('male')}</p>
							</div>
							<div className='gender-boys-legend'>
								<div className='girls-circle-blue'></div>
								<p>{t('female')}</p>
							</div>
						</div>
					</div>
					<p className='text-[16px] leading-5 text-darkenPurple font-medium'>{t('percentageOfResponseDistributionByGender')} </p>
				</div>
				<GenderBarChart resultDataChart={genderChartData} presetMaxValue={false} showEmotions={contentType === 'feedback'} />
			</div>

			{contentType === 'feedback' && (
				<div className='w-full  mt-[80px]'>
					<div className='flex flex-row items-center justify-between mt-[30px] mb-[35px]'>
						<p className='text-[24px] leading-5 text-[#413F5E] font-bold'>{t('general')} </p>
						<p className='text-[16px] leading-5 text-darkenPurple font-medium'>{t('Total response percentage')}</p>
					</div>

					<div className={`flex flex-row justify-between ${isRegularDisplayMode ? 'h-[551px]' : 'h-[451px]'}`}>
						<div className='w-[379px] flex flex-col items-center justify-center bg-[#F8F8FB] p-[24px] rounded-[15px] mr-[15px] gap-[10px]'>
							{/* <div className='-ml-[130px] -mt-[9px] -mb-[11px]'>
									<DonutAnalytic width={220} height={220} resultData={pieChartData} />
								</div> */}
							<div className='flex-1'>
								<DonutAnalytic width={336} height={336} resultData={pieChartData} />
							</div>
							<div className='flex flex-row gap-[20px] justify-center bg-[#ECEBF8] w-full rounded-[15px] py-[15px] px-[25px] analytics-p-exception-full-w'>
								<ClassroomEngagementTrendItem
									title={t('Engagement with emotions')}
									trendPlaceholder={t(`Relative change to the same selected previous period`)}
									hoverPlaceholder={t(`Percentage of students that submitted an emotion in the selected time frame`)}
									smallTitleText={true}
									loading={loading}
									percentage={engagementOnEmotions?.percentage ?? 0}
									trend={engagementOnEmotions?.trend ?? 0}
									showTrend={false}
								/>
							</div>
							<div className='flex flex-row gap-[20px] justify-center bg-[#ECEBF8] w-full rounded-[15px] py-[15px] px-[25px]'>
								<div className='flex gap-[8px] items-center flex-1'>
									<div
										className='cursor-help'
										data-tip={t(`Total number of emotions submitted in the time frame`)}
										data-for={`totalNumberOfEmotions`}
									>
										<div className='h-[45px] min-w-[45px] px-[7px] bg-[#7b7ac3] rounded-full flex justify-center items-center text-[#FDDF6D] font-semibold transition-all ease-in-out'>
											{loading ? <Loading /> : totalEmotions ?? 0}
										</div>
										{!loading && (
											<ReactTooltip
												id={`totalNumberOfEmotions`}
												arrowColor='#5E639B'
												effect='solid'
												backgroundColor='#5E639B'
												className='wellbeing-tooltip'
												classNameArrow='tooltip-arrow'
											/>
										)}
									</div>
									<p className={` !font-medium text-[#5E639B]  flex-1 ${true ? 'text-sm' : 'text-[16px]'}`}>
										{t('Total emotions submitted')}
									</p>
								</div>
							</div>
						</div>
						<div className='w-[722px] bg-lightBG rounded-[10px]'>
							<div className='flex flex-row items-center justify-between p-5 pt-[10px]'>
								<p className='w-[120px] text-[14px] leading-5 text-mudPurple font-semibold ml-5'>{t('emotion')}</p>
								<p className='text-[14px] leading-5 text-mudPurple font-semibold max-w-[220px] text-center mr-[30px]'>
									{t('No. of emotions')}
								</p>
								<p className='text-[14px] leading-5 text-mudPurple font-semibold mr-[40px]'>%</p>
							</div>
							<div className='pr-[7px] pl-[20px]'>
								<div className='overflow-auto h-[472px] grey-small-scroll pr-[9px]'>
									{pieChartData.map((item, index) => {
										return (
											<div
												className='px-[6px] mb-[6px] h-10 rounded-full bg-containerGray  flex flex-row items-center justify-between relative overflow-hidden hidden-div-parent cursor-pointer'
												key={index}
											>
												<div className='flex flex-row items-center gap-2 w-[120px] relative '>
													<img
														width={30}
														height={30}
														src={emotions_heads[item.label]}
														alt='Users'
														style={{ zIndex: 2, position: 'absolute' }}
													/>
													<p className='text-[14px] leading-5 text-deepPurple font-semibold capitalize ml-[40px]'>
														{t(item.label)}
													</p>
												</div>
												<p className='text-[14px] leading-5 text-deepPurple font-semibold w-[150px] text-center'>
													{item.numberOfMessages}
												</p>
												<p className='text-[14px] leading-5 text-darkenPurple font-semibold mr-5 w-[50px] text-right'>
													{item.value}%
												</p>
												{isRegularDisplayMode && (
													<div className='bg-mudPurple h-10 absolute top-0 w-full z-1 rounded-full hidden-div flex flex-row items-center justify-between pl-[40px]'>
														<p className='text-[14px] leading-5 text-white font-medium ml-[10px]'>
															{t('mostCommonReasons')}:{' '}
														</p>
														<div className=' h-10 flex flex-row items-center justify-center rounded-full bg-containerGray gap-[8px] px-[10px]'>
															{item?.mostReasons?.map((reason) => {
																return (
																	<>
																		{reasonsJson[reason?.code]}
																		<p className='text-[14px] leading-5 text-deepPurple font-semibold'>
																			{t(reason?.name)} ( {reason?.percentage}% )
																		</p>
																	</>
																);
															})}
															{item?.mostReasons?.length === 0 && (
																<div className='px-[20px] text-[14px] leading-5 text-deepPurple font-semibold'>
																	{t('noResults')}
																</div>
															)}
														</div>
													</div>
												)}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AnalyticsDataContainer;
