import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as UpcomingDropdown } from '../../assets/svg/UpcomingDropdown.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import UpcomingSingleContent from '../dashboard/UpcomingSingleContent';
import '../../styles/modals/UpcomingContentModal.css';
import { Link, useHistory } from 'react-router-dom';
import { deleteById, getUpcomingContent } from '../../server/server';
import { useEffect } from 'react';
import BigImageModal from './BigImageModal';
import ConfirmationModal from './ConfirmationModal';
import { notify } from '../../utils/util';
import i18n from '../../i18n';

function UpcomingContentModal({ showRun, closeRun, num, setRefreshNum }) {
	const { t, locale } = useTranslation();
	const [resultNum, setResultNum] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const history = useHistory();
	const [imageModalOpen, setImageModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState('');
	const [deleteModalOpen, setDelModalOpen] = useState(false);
	const [selContent, setSelContent] = useState({});

	const openDeleteForSingle = (content) => {
		setSelContent(content);
		setDelModalOpen(true);
	};
	const closeDelModal = () => {
		setDelModalOpen(false);
	};

	const openBigImage = (url) => {
		setSelectedImage(url);
		setImageModalOpen(true);
	};

	const closeImageModal = () => {
		setImageModalOpen(false);
		setSelectedImage('');
	};

	const handleDelete = async () => {
		const resp = await deleteById({ collection: 'content', id: selContent?.id });
		if (resp.status === 200) {
			if (resultNum === 0) loadContent();
			else setResultNum(0);
			setRefreshNum(new Date().toString());
			notify(t('contentDeleted'));
			closeDelModal();
		} else {
			notify(t('yourDataIsNotUpdated'));
			closeDelModal();
		}
	};

	useEffect(() => {
		if (showRun) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
			document.getElementById('upcoming-content-modal-trigger').style.display = 'none';
		} else {
			document.getElementById('home').style.filter = 'none';
			document.getElementById('upcoming-content-modal-trigger').style.display = 'flex';
		}
	}, [showRun]);

	const tempContents = [];
	const [contents, setContents] = useState(tempContents);
	const openPage = (page) => {
		closeRun();
		setTimeout(() => {
			history.push(page);
		}, 300);
	};
	const loadContent = async () => {
		const resp = await getUpcomingContent({ loadedContent: resultNum });

		if (resp?.status === 200) {
			const size = resp?.data?.size;
			const data = resp?.data?.list;

			if (resultNum === 0) {
				console.log('upcoming content ', resp.data);
				setContents(data);
				setHasMore(size > data?.length);
				console.log('has more ', size > data?.length);
			} else {
				console.log('upcoming content ', resp.data);
				setContents((prev) => [...prev, ...data]);
				setHasMore(size > contents?.length + data.length);
				console.log('has add more ', size > contents?.length + data.length);
			}
		} else {
			setContents([]);
			setResultNum(0);
		}
	};

	useEffect(() => {
		if (showRun) loadContent();
	}, [resultNum, showRun]);

	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={showRun}
			// onAfterOpen={loadContent}
			onRequestClose={closeRun}
			style={{
				overlay: {
					zIndex: 10,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: '70%',
					maxWidth: 600,
					minWidth: 600,
					margin: 'auto',
					bottom: 50,
					height: '100%',
					marginRight: -40,
					backgroundColor: 'transparent',
					border: 'none',
					boxShadow: 'none',
				},
			}}
		>
			<div>
				<div className='bg-white rounded-xl mt-10 mr-6 h-full  min-h-[calc(100vh-115px)]'>
					<div className='p-10 pb-5'>
						<h2 className='font-bold pointer text-xl text-deepPurple text-left '>{t('upcomingContent')} </h2>
						<div className='mt-6'>
							<div
								className='pointer bg-whiteContrast flex items-center justify-center border border-mudPurple hover:border-deepPurple rounded-xl py-4 text-sm text-deepPurple font-semibold'
								onClick={() => {
									openPage(`/content`);
								}}
							>
								{t('createYourOwnContent')}
							</div>

							<div
								className='mt-3 pointer flex items-center justify-center bg-whiteContrast border border-mudPurple hover:border-deepPurple rounded-xl py-4 text-sm text-deepPurple font-semibold'
								onClick={() => {
									openPage(`/readytogo`);
								}}
							>
								{t('runReadyToGoContent')}
							</div>
						</div>
					</div>

					<div
						id='scroll-upcoming-feed'
						style={{ overflowY: 'scroll', height: 'calc(100vh - 365px)' }}
						className='gray-small-scroll mx-2'
					>
						<InfiniteScroll
							dataLength={contents?.length}
							next={() => setResultNum(contents?.length)}
							hasMore={hasMore}
							scrollableTarget='scroll-upcoming-feed'
							scrollThreshold={0.8}
							inverse={false}
						>
							{contents?.map((content, index) => {
								return (
									<UpcomingSingleContent
										content={content}
										handleDelete={openDeleteForSingle}
										key={index}
										openBigImage={openBigImage}
									/>
								);
							})}
						</InfiniteScroll>
					</div>
				</div>
				<div
					className=' absolute bottom-6 right-6 font-bold pointer bg-deepPurple rounded-3xl text-highlightColor py-[0.8rem] px-5 flex items-center'
					id='upcoming-content-modal-trigger'
					onClick={closeRun}
				>
					<div className='relative flex items-center '>
						<UpcomingDropdown />
						<span className='ml-3'>{t('upcomingContent')} </span>
						<div className='w-[30px] h-[30px] rounded-full bg-highlightColor border text-deepPurple bottom-5 -right-5 border-white absolute flex items-center justify-center'>
							{num}
						</div>
					</div>
				</div>
			</div>
			<ConfirmationModal
				modalIsOpen={deleteModalOpen}
				closeModal={closeDelModal}
				confirmation={handleDelete}
				message={t('doYouWantToDeleteThis')}
				subMessage={i18n.language == 'en' ? `Delete ${selContent?.type} content?` : ''}
			/>
			<BigImageModal modalIsOpen={imageModalOpen} closeModal={closeImageModal} selectedImage={selectedImage} />
		</ReactModal>
	);
}

export default UpcomingContentModal;
