import React, { useState, useEffect, useContext } from 'react';
import { FaChevronLeft, FaTimes } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import '../../styles/content/ContentList.css';
import { getBy, deleteById } from '../../server/server';
import { useUser } from '../../auth';
import ConfirmationModal from '../modals/ConfirmationModal';
import { format_day } from '../../utils/format_day';
import { ContentContext } from './ContentContext';
import { fileSystemUrl } from '../../constants/Connections';
import { useTranslation } from 'react-i18next';
import ReadMoreText from '../ReadMoreText';
import { translateTargetText } from '../../utils/util';
function ContentList() {
	let history = useHistory();
	const { t, locale, i18n } = useTranslation();
	const [contentItem, setContentItem] = useContext(ContentContext);
	const { user, setUser } = useUser();
	const [content, setContent] = useState([]);
	const [deleteModalOpen, setDelModalOpen] = useState(false);
	const [deleted, setDeleted] = useState([]);
	const [contentID, setContentID] = useState('');

	const handleSelect = (
		type,
		title,
		msg,
		options,
		link,
		imgUrl,
		files,
		target_text,
		target,
		targetOptions,
		likes,
		views,
		isReq,
		isAnon,
		editID,
		programDetails,
		schedule,
		schedule_info,
		status
	) => {
		setContentItem({
			edit: 'list',
			type: type,
			title: title,
			msg: msg,
			options: options,
			link: link,
			imgUrl: imgUrl,
			returnID: '',
			files: files,
			editTarget: {
				name: target_text,
				target: target,
				selection: targetOptions?.selection,
				type: targetOptions?.type,
			},
			likes: likes,
			views: views,
			isReq: isReq,
			isAnon: isAnon,
			editID: editID,
			programDetails: {
				programId: programDetails?.programId,
				dayIndex: programDetails?.dayIndex,
			},
			editschedule: {
				schedule: schedule,
				schedule_info: schedule_info,
				status: status,
			},
		});
		history.push(`/content/${type}`);
	};

	const loadContent = async () => {
		const response = await getBy({
			collection: 'content',
			sort_field: 'created_date',
			sort_direction: -1,
			query: {
				institution_id: user?.institution_id,
				type: { $in: ['poll', 'feed', 'question'] },
				$or: [{ title: { $regex: '', $options: 'i' } }, { decription: { $regex: '', $options: 'i' } }],
			},
		});
		if (response.status === 200) {
			console.log(user?.institution_id, response.data.data);
			setContent(response.data.data);
		}
	};

	useEffect(() => {
		loadContent();
	}, []);

	const closeDelModal = () => {
		setDelModalOpen(false);
	};
	const openDelModal = (id) => {
		setDelModalOpen(true);
		setContentID(id);
	};
	const deleteContent = async () => {
		console.log('deleted:', contentID);
		const resp = await deleteById({ collection: 'content', id: contentID });
		if (resp.status) {
			setDelModalOpen(false);
			setDeleted([...deleted, contentID]);
		}
	};
	const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';
	return (
		<div className='content' style={{ overflowX: 'hidden', position: 'relative' }}>
			<div className='content_list_header' style={{ marginLeft: 10 }}>
				<span
					className='back_button_list'
					onClick={() => {
						history.push('/content');
					}}
				>
					<FaChevronLeft id='back-program-arrow' />
					{t('back')}
				</span>
				<h1>{t('allCreatedContent')}</h1>
			</div>
			<div className='content_template_body'>
				<div className='content_row'>
					{content.map((content, index) => {
						return (
							<div className={`widget_card ${deleted.includes(content?.id) ? 'hidden' : ''}`} key={index}>
								<div className='widget_header'>
									<p>
										<span className='content_type_above'>{capitalize(content?.type)}</span>
									</p>
									<h4>{content?.title}</h4>
									<span
										style={{
											marginTop: '-20px',
											alignSelf: 'flex-end',
											color: '#b4c2cc',
										}}
									>
										{format_day(content?.created_date, true, i18n.language)}
									</span>
									<span className='widget_delete pointer' onClick={() => openDelModal(content?.id)}>
										<FaTimes />
									</span>
									<div className='widget_line'></div>
									{content?.type === 'poll' && (
										<div style={{ flex: 1 }}>
											<p style={{ fontWeight: 600 }}>{t('options')}:</p>
											{content.options.map((option, index) => {
												return (
													<p key={index} className='content-poll-option'>
														<span>🔘</span>
														{option}
													</p>
												);
											})}
										</div>
									)}
									{content?.type === 'question' && (
										<div style={{ flex: 1 }}>
											<pre>
												{content?.message !== '' && (
													<p style={{ fontWeight: 600 }}>
														<ReadMoreText message={content?.message} lines={5} />
													</p>
												)}
											</pre>
										</div>
									)}
									{content?.type === 'feed' && (
										<div style={{ flex: 1 }}>
											<pre>
												{content?.message !== '' && (
													<p style={{ fontWeight: 600 }}>
														<ReadMoreText message={content?.message} lines={5} />
													</p>
												)}
											</pre>
										</div>
									)}
									<div className='widget_options'>
										<span className='target_text' style={{ flex: 1 }}>
											<span style={{ marginRight: '5px' }}>🎯</span> {t('targetGroup')}:
											<b> {translateTargetText(content?.target_text)}</b>
										</span>
										<span className='target_created_date'>
											<span style={{ marginRight: '5px' }}>⏰</span>
											{t('schedule')}:<b>{content?.schedule ? t('on') : t('off')}</b>
										</span>
									</div>
								</div>
								<div className='widget_content'>
									<button
										className='btn btn-primary'
										style={{ fontWeight: 600 }}
										onClick={() =>
											handleSelect(
												content?.type,
												content?.title,
												content?.message,
												content?.options,
												content?.url,
												content?.files
													? `${fileSystemUrl}/${content.files.storage_name}?token=${user.token}`
													: undefined,
												content?.files ? content?.files : undefined,
												content?.target_text,
												content.targetOptions?.selection,
												content?.targetOptions,
												content?.likes,
												content?.views,
												content?.isReq,
												content?.isAnon,
												content?.id,
												content?.programDetails,
												content?.schedule,
												content?.schedule_info,
												content?.status,
												content?.repeatData,
												content?.trackWellbeing,
												content?.addedToAnalytics
											)
										}
									>
										{t('edit')}
									</button>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<ConfirmationModal
				modalIsOpen={deleteModalOpen}
				closeModal={closeDelModal}
				confirmation={deleteContent}
				message={t('doYouWantToDeleteThis')}
			/>
		</div>
	);
}

export default ContentList;
