const TRANSLATION_DE = {
	confirmDeletion: 'Löschen bestätigen',
	deleteTheTemplateFor: 'Vorlage löschen ',
	cancel: 'Abbrechen',
	delete: 'Löschen',
	createContent: 'Inhalte erstellen',
	askAQuestion: 'Frage stellen',
	createAPoll: 'Abstimmung starten',
	createAPost: 'Post erstellen',
	orChooseFromTemplatesBelow: 'oder eine der Vorlagen (s.u.) auswählen',
	on: 'An',
	off: 'Aus',
	Off: 'Aus',
	never: 'Nie',
	chooseTemplate: 'Vorlage wählen ',
	thereAreNoTemplatesCreated: 'Keine Vorlagen erstellt',
	howAreYouToday: 'Wie geht es Dir heute? ',
	selectEmotion: 'Wähle einen Emoji',
	question: 'Frage',
	genderSelection: 'Geschlecht',
	all: 'Alle',
	male: 'Männlich',
	Male: 'Männlich',
	female: 'Weiblich',
	Female: 'Weiblich',
	settings: 'Einstellungen',
	support: 'Hilfe',
	privacyPolicy: 'Datenschutzbestimmungen',
	about: 'Über',
	dayReview: 'Mein Tag',
	dailyReflection: 'Wie war mein Tag',
	youLiked: 'Gefällt dir',
	like: 'Gefällt mir',
	edit: 'Bearbeiten',
	answered: 'Beantwortet',
	showLess: 'Weniger anzeigen',
	showMore: 'Mehr anzeigen',
	search: 'Suchen',
	targetGroup: 'Zielgruppe',
	quickAction: 'Abkürzung',
	select: 'Auswahl',
	selectByGroup: 'Nach Gruppe auswählen',
	selectSchoolYearsThatYouLikeToTarget: 'Jahrgänge wählen',
	years: 'Jahrgänge',
	year: 'Jahrgang',
	selectClassesThatYouLikeToTarget: 'Gewünschte Klassen wählen',
	classes: 'Klassen',
	class: 'Klasse',
	selectACustomListThatYouLikeToTarget: 'Benutzerdefinierte Liste wählen',
	custom: 'Benutzerdefiniert ',
	selectTeachersYouWouldLikeToTarget: 'Lehrer wählen',
	staff: 'Mitarbeiter',
	selectYears: 'Jahre auswählen',
	selectClasses: 'Klassen auswählen',
	selectFromMyStudents: 'Aus meinen Schülern wählen',
	multipleClasses: 'Klassen',
	multipleYears: 'Jahrgänge',
	allStudents: 'Alle Schüler',
	done: 'Fertig',
	waitForTeachersToFeedYouWithSomeNewInformations: 'Auf Informationen der Lehrer warten',
	feedIsEmpty: 'Eingabefeld ist leer',
	thereIsNoFeedForThisSearch: 'Kein Ergebnis für diese Suche',
	createNewPostsForYourStudentsAndHereIsAShortcutForYou: 'Neuen Post erstellen',
	waitForTeacherToFeedYouWithSomeNewInformation: 'Auf Informationen der Lehrer warten',
	createFeedPost: 'Beitrag erstellen',
	targetList: 'Zielgruppe',
	beAmazing: 'Sei großartig!',
	login: 'Log In',
	getStarted: 'Los geht’s!',
	enterYourSchoolEmail: 'Gib deine Schulemailadresse ein',
	password: 'Passwort',
	email: 'E-Mail',
	requestNewPassword: 'Neues Passwort anfordern',
	newPassword: 'Neues Passwort',
	enterYourSchoolEmailAndWeWillSendYouALinkToGetBackIntoYourAccount:
		'Gib deine Schulemailadresse ein und du erhälst eine E-Mail mit einem Bestätigungslink in deinen Account',
	sendRequest: 'Anfrage senden',
	verifyYourEmail: 'E-Mail bestätigen',
	weHaveSentYouALinkToGetBackIntoYourAccountAndCheckYourMailAndCopyYourTokenHere:
		'Wir haben dir einen Link geschickt, mit dem du wieder in dein Konto kommst. Prüfe deinen Posteingang und füge den Token hier ein. ',
	resendARequest: 'Anfrage erneut senden',
	didntGetAMail: 'Du hast keine E-Mail erhalten? ',
	submit: 'Abschicken',
	enterYourNewPassword: 'Neues Passwort eingeben',
	apply: 'Beantragen',
	howDoYouFeel: 'Wie fühlst du dich?',
	'How do you feel?': 'Wie fühlst du dich?',
	poll: 'Abstimmung  ',
	thereAreNoAnswersOrFeedbacksForThisDay: 'Für diesen Tag gibt es keine Antworten oder Feedback',
	title: 'Titel',
	message: 'Nachricht ',
	requested: 'Angefragt',
	anonymous: 'Anonym',
	url: 'URL ',
	addPhoto: 'Foto hinzufügen',
	removePhoto: 'Foto entfernen',
	youAreAllDone: 'Fertig!',
	congratsEverythingIsDoneForNowWaitForNewTasks: 'Gratuliere, du bist fertig! Warte auf neue Aufgaben…',
	excellentWorkWeAreDelightedToHearThingsAreSoMuchBetter: 'Gut gemacht! Schön zu hören, dass es dir besser geht.',
	thisIsYourSafePlaceAndNothingWillBeShared: 'Dies ist Dein privater Raum. Nur du kannst sehen was du schreibst.',
	enterANameForTodaysNotes: 'Benenne deinen heutigen Eintrag',
	iDontWantToSaveNotes: 'Ich möchte meine Einträge nicht speichern',
	worse: 'Schlechter',
	sorryToHearThatThingsAreWorseWhichOfTheFollowingWouldHelpRightNow:
		'Es tut uns leid zu hören, dass es dir schlechter geht. Welche von den Vorschlägen unten kann dir jetzt weiterhelfen?',
	muchWorse: 'Viel schlechter',
	sorryToHearThatThingsAreMuchWorseAndWhichOfTheFollowingWouldHelpRightNow:
		'Es tut uns leid zu hören, dass es dir viel schlechter geht. Welche von den Vorschlägen unten kann dir jetzt weiterhelfen?',
	same: 'Genauso',
	whatAreSomeIdeasToMakeThingsBetter: 'Was kannst du jetzt tun, um die Lage zu verbessern?',
	better: 'Besser',
	goodToHearThingsAreBetterAndWhatAreSomeIdeasToTakeItEvenFurther:
		'Schön zu hören, dass sich deine Lage verbessert hat. Hast du Ideen wie du sie vielleicht noch besser machen könntest? ',
	muchBetter: 'Viel besser',
	greatToHearThingsAreMuchBetterAndThinkAboutHowToImproveEvenMoreOrKeepItOnAPositiveLevel:
		'Schön zu hören, dass sich die Lage verbessert hat. Hast du Ideen wie du sie vielleicht noch besser machen könntest? ',
	ImOkWithIt: 'Das ist in Ordnung',
	IdPreferIfTheyWereBetter: 'Es wäre schön, wenn die Lage besser wäre',
	loadMoreSuggestions: 'Weitere Vorschläge',
	continue: 'Weiter',
	deleteNote: 'Eintrag löschen',
	today: 'Heute',
	comparedToLastTimeTodayIamFeeling: 'Verglichen mit dem letzten Mal fühle ich mich heute....',
	howDoYouFeelComparedToLastTimeYouDidThePoll: 'Wie fühlst du dich, verglichen mit der letzten Umfrage?',
	thisInformationWillBeSharedWith: 'Diese Info wird auch deine(n) Lehrer(in) geschickt',
	feelFreeToShareAnyThoughtsHereWhyYouFeelThisWay: 'Falls du Lust hast, kannst du hier beschreiben warum du dich so fühlst',
	feedback: 'Feedback',
	choose: 'Auswahl',
	newFeeds: 'Neue Einträge',
	openTasks: 'Offene Aufgaben',
	taskArchive: 'Aufgaben Archiv',
	congratsAndEverythingIsDoneForNowAndWaitForNewTasksFromYourTeacher:
		'Gratuliere, alles ist erledigt! Warte auf neue Aufgaben von deinem Lehrer',
	newConversation: 'Neuer Chat',
	teacher: 'Lehrer',
	teachers: 'Lehrer',
	counselor: 'Vertrauenslehrer',
	secretKey: 'Passwortschlüssel',
	advancedSettings: 'Erweiterte Einstellungen',
	showDailyReflectionScreen: 'Wie war mein Tag anzeigen',
	termsAndConditions: 'Geschäftsbedingungen',
	surname: 'Nachname',
	firstName: 'Vorname',
	gender: 'Geschlecht',
	Gender: 'Geschlecht',
	other: 'Andere ',
	Other: 'Andere ',
	thisIsYourSafePlace: 'Hier bist du sicher  ',
	thisIsYourPrivateRoom: 'Dies ist der private Teil deines Profil, in den nur Du Einsicht hast.',
	safePlaceWithDailyReflectionNotes: 'Diese Notizen kannst nur du sehen',
	personalInformation: 'Persönliche Informationen',
	privacyAndSecurity: 'Datenschutz ',
	dashboard: 'Überblick',
	update: 'Aktualisieren',
	unselect: 'Auswahl aufheben',
	noResults: 'Keine Ergebnisse',
	open: 'Öffnen',
	share: 'Teilen',
	back: 'Zurück',
	task: 'Aufgabe',
	tasks: 'Aufgaben',
	logout: 'Ausloggen',
	save: 'Speichern',
	name: 'Name',
	profile: 'Profil',
	classroom: 'Klassenzimmer',
	feed: 'Feed',
	live: 'Live',
	archive: 'Archiv',
	yesterday: 'Gestern',
	tomorrow: 'Morgen',
	results: 'Ergebnisse',
	required: 'Erforderlich',
	schedule: 'Zeitplan',
	happy: 'glücklich',
	excited: 'aufgeregt',
	ecstatic: 'begeistert',
	inspired: 'inspiriert',
	calm: 'ruhig',
	lethargic: 'träge',
	grumpy: 'verärgert',
	sad: 'traurig',
	stressed: 'gestresst',
	angry: 'wütend',
	sunday: 'Sonntag',
	monday: 'Montag',
	tuesday: 'Dienstag',
	wednesday: 'Mittwoch',
	thursday: 'Donnerstag',
	friday: 'Freitag',
	saturday: 'Samstag',
	scheduled: 'Geplant',
	delivered: 'Gesendet',
	first: 'Erste ',
	second: 'Zweite',
	third: 'Dritte',
	seen: 'Gesehen',
	selectStaff: 'Mitarbeiter auswählen',
	forgotPassword: 'Passwort vergessen?',
	repeatPassword: 'Passwort wiederholen',
	retypePassword: 'Passwort erneut eingeben',
	changePassword: 'Passwort ändern',
	hidePassword: 'Passwort ausblenden',
	dailyFeedback: 'Tägliches Feedback',
	createNew: 'Erstelle neu',
	selectionType: 'Auswahl',
	enterSurname: 'Nachnamen eingeben',
	enterEmail: 'E-Mail eingeben',
	newMessage: 'Neue Nachricht!',
	myTasks: 'Meine Aufgaben',
	addOption: 'Option hinzufügen',
	newQuestion: 'Neue Frage',
	editQuestion: 'Frage bearbeiten',
	editPost: 'Beitrag bearbeiten',
	editPoll: 'Abstimmung bearbeiten',
	editGroup: 'Gruppe bearbeiten',
	tomorrowPlans: 'Pläne für morgen',
	confirmStopping: 'Bestätigen Sie das Anhalten',
	newPoll: 'Neue Abstimmung',
	answer: 'Antworten',
	post: 'Post',
	hi: 'Hallo',
	lastTime: 'Letztes Mal',
	suggestions: 'Vorschläge',
	sendAnswer: 'Antwort senden',
	comment: 'Kommentar',
	ok: 'OK',
	passwordMustBeLongerThan8CharactersAndMustContainsUppercaseLowercaseNumberAndSpecialSymbol:
		'Passwort muss länger als 8 Zeichen haben und muss Groß-, und Kleinbuchstaben, Zahlen und Sonderzeichen enthalten',
	enterYourWorkEmailAndPassword: 'Login E-Mail eingeben',
	submitNewPassword: 'Neues Passwort bestätigen',
	pleaseCheckYourMailToConfirmYourEmailAddress:
		'Wir haben eine E-Mail an dich geschickt, um die Adresse zu bestätigen. Bitte klick auf die Bestätigung in der E-Mail',
	howDoYouFeelAboutThingsBeingTheSameToday: 'Wie denkst du darüber, dass es dir heute genauso geht?',
	pollHasBeenUpdated: 'Abstimmung wurde aktualisiert',
	typeAPostTitle: 'Titel eingeben',
	addAnOptionalMessage: 'Schreib eine Nachricht',
	writeYourMessageHere: 'Hier Nachricht schreiben',
	enterUrlLink: 'Web-Link eingeben',
	saveAndContinue: 'Sichern und weiter',
	nameOfTodayNote: 'Name für die heutige Notiz',
	whatAreJust3GoodThingsThatHappenedToday: 'Welche drei Dinge waren heute gut?',
	yourDirectManager: 'Dein direkter Manager',
	secretKeyHasBeenSaved: 'Sicherheitscode gespeichert',
	enterYourSecretKeyToEncryptLllYourMessagesAndAnswers:
		'Gib hier einen persönlichen Sicherheitscode ein, um deine Notizen zu verschlüsseln',
	enterSecretKey: 'Sicherheitscode eingeben',
	advancedSettingsAreSuccessfullySaved: 'Die zusätzlichen Einstellungen sind gespeichert',
	youCanUseTheButtonsAboveToContactYourTeacherOrCounselorForSupportAtAnyPoint:
		'Du kannst die Felder oben benutzen, um deinen Lehrer oder Vertrauenslehrer zu kontaktieren',
	waitForStudentsToContactYouToHelpThem: 'Hier werden die Nachrichten der Schüler sein, die dich kontaktieren',
	passwordsDoNotMatch: 'Passwörter sind nicht identisch',
	startNewChat: 'Neuen Chat starten',
	shareWithTeacher: 'Mit dem Lehrer teilen',
	tasksForThisSection: 'Aufgaben in diesem Bereich',
	howWasYourDay: 'Wie war dein Tag?',
	thisIsAutoReplyMessage: 'Dies ist eine automatische Nachricht',
	thankYouForReachingOutWeWillReplyToYourMessageSoonIfYouOrSomeoneYouKnowIsInImmediateDangerPleaseCallEmergencyServices:
		'Vielen Dank für deine Nachricht. Wir werden dir sobald wie möglich antworten.',
	doYouThinkThingsAreForAnyReasonInParticular: 'Denkst du, dass es einen bestimmten Grund gibt, warum du dich {{result}} fühlst?',
	yourPrivateNotes: 'Persönliche Notizen',
	createNewPoll: 'Neue Abstimmung starten',
	saveAsTemplate: 'Als Vorlage speichern',
	addPostContentHere: 'Hier den Inhalt des Beitrags hinzufügen',
	typeAPollQuestion: 'Hier die Frage für die Abstimmung eingeben',
	questionHasBeenUpdated: 'Frage wurde aktualisiert',
	questionHasBeenPosted: 'Frage wurde verschickt',
	iDontWantToThinkAboutThisNow: 'Ich möchte da jetzt nicht drüber nachdenken',
	appVersion: 'Anwendungsversion',
	selectLanguage: 'Sprache auswählen',
	userIsUpdated: 'Benutzer ist aktualisiert',
	imageIsNotUploaded: 'Bild ist nicht hochgeladen',
	feedHasBeenUpdated: 'Feed wurde aktualisiert',
	permissionIsNotAccepted: 'Erlaubnis ist nicht akzeptiert',
	feedHasBeenPosted: 'Feed wurde veröffentlicht',
	pollHasBeenPosted: 'Abstimmung wurde veröffentlicht',
	templateHasBeenSaved: 'Vorlage wurde gespeichert',
	privateNoteHasBeenSaved: 'Private Notiz wurde gespeichert',
	compareToLastTime: 'Vergleiche mit dem letzten Mal',
	yourAnswerIsSaved: 'Ihre Antwort ist gespeichert',
	staySecuredFirst: 'Bleib zuerst gesichert',
	day: 'Tag',
	dateOfBirth: 'Geburtsdatum',
	teacherInfo: 'Lehrer Details',
	studentInfo: 'Schüler Details',
	manage: 'Bearbeiten',
	openListWithAllClassesFromThisSchool: 'Liste mit allen Klassen öffnen, die zu dieser Schule gehören',
	wizardToCreateNewClassAddStudentsAndHeaTteacher: 'Hier neue Klasse, neue Schüler und einen Leiter hinzufügen',
	openListWithAllTeachersThatGoToThisSchool: 'Liste mit allen Lehrern öffnen, die zu dieser Schule gehören',
	wizardToCreateNewTeacherAndLinkItToProperClass: 'Hier neuen Lehrer hinzufügen und einer Klasse zuordnen',
	chooseDataRange: 'Wähle den Datenbereich',
	thisListRepresentsAllContentFromThisTypeYouCanEditOrDeleteTt:
		'Diese Liste zeigt alle Inhalte dieser Art. Du kannst sie verändern oder löschen',
	lastCreatedpoll: 'Letzte Abstimmung',
	lastCreatedfeed: 'Letzter Feed',
	lastCreatedquestion: 'Letzte Frage',
	listOfDays: 'Liste der Tage',
	selectThisquestion: 'Diese Umfrage',
	selectThispoll: 'Diese Abstimmung wählen',
	selectThisfeed: 'Diesen Feed wählen',
	createOwn: 'Eigene Version machen',
	showAllContent: 'Alle Inhalte zeigen',
	programDuration: 'Programm Dauer',
	seeThisProgram: 'Dieses Programm verwenden',
	programs: 'Programme',
	Programs: 'Programme',
	hereYouCanCreateAPollToLearnMoreAboutYourStudents: 'Hier kannst du eine Umfrage starten und mehr über deine Schüler erfahren',
	askAnOpenQuestionToGetDetailedFeedback: 'Stelle hier eine offene Frage, um detaillierte Antworten zu bekommen',
	classManagement: 'Klassen bearbeiten',
	teacherManagement: 'Lehrer bearbeiten',
	analyze: 'Analytik',
	instructionVideos: 'Erklärungsvideos',
	created: 'Erstellt',
	pickOneOption: 'Wählen Sie eine Option',
	listOfClasses: 'Liste der Klassen',
	newClass: 'Neue Klasse',
	action: 'Handlung',
	numberOfStudents: 'Anzahl der Schüler',
	listOfTeachers: 'Liste der Lehrer',
	newTeacher: 'Neue Lehrer',
	options: 'Optionen',
	refresh: 'Aktualisieren',
	selectDate: 'Datum auswählen',
	time: 'Zeit',
	date: 'Datum',
	Repaet: 'Wiederholen',
	feedDescription: 'Hier kannst du Text, Bilder, Vidoes or Links hinzufuegen',
	studentsLoginData: 'Schüler Login Daten',
	listOfStudentsWhoHaventLoggedInYet: 'Liste von Schülern und Schülerinnen, die sich noch nicht eingeloggt haben',
	'Open list': 'Öffnen',
	'Number of answers': 'Anzahl der Antworten',
	user: 'Benutzer',
	easilyManageYourStudentsAndTeachersWithSeparateFunctions: 'Verwalte deine Schüler und Lehrer einfach mit getrennten Funktionen',
	userManagement: 'Benutzerverwaltung',
	and: 'und',
	chat: 'Chat',
	trackCounseling: 'Beratung nachverfolgen',
	openLink: 'Link öffnen',
	openListOfUsersWhoHaventLoggedIn: 'Öffne die Liste der Benutzer, die sich nicht angemeldet haben',
	loadMoreClasses: 'Mehr Klassen laden',
	loginInfo: 'Login Information',
	role: 'Rolle',
	loadMore: 'Mehr laden',
	noTeachersYet: 'Noch keine Lehrer',
	classInfo: 'Klassen Information',
	enterClassName: 'Geben Sie den Klassennamen ein',
	enterSchoolYear: 'Geben Sie das Schuljahr ein',
	schoolYear: 'Schuljahr',
	className: 'Klassennamen',
	listOfStudents: 'Schülerliste',
	doYouWantToRemoveStudent: 'Möchten Sie den Schüler löschen?',
	doYouWantToDeleteClass: 'Möchten Sie die Klasse löschen?',
	emailIsInvalid: 'E-Mail ist ungültig.',
	emailIsAvailable: 'E-Mail ist verfügbar!',
	emailAlreadyExists: 'E-Mail existiert bereits!',
	fieldIsRequired: 'Feld ist erforderlich',
	showPassword: 'Passwort anzeigen',
	enterName: 'Name eingeben',
	selectRole: 'Rolle auswählen',
	teachersRoleNotSetYet: 'Lehrrolle noch nicht ausgewählt',
	create: 'Schaffen',
	doYouWantToDeleteTeacher: 'Möchten Sie den Lehrer löschen?',
	classIsCreated: 'Die Klasse ist gemacht!',
	reminderForToday: 'Erinnerung für heute',
	closed: 'abgeschlossen',
	youDontHaveAnyRemindersForToday: 'Du hast keine Erinnerungen für heute',
	firstEnterANote: 'Gib zuerst eine Notiz ein',
	noteIsCreated: 'Notiz wird erstellt',
	reminderHasBeenChanged: 'Erinnerung wurde geändert!',
	status: 'Status',
	exportReport: 'Bericht exportieren',
	notes: 'Notizen',
	Notes: 'Notizen',
	editReminder: 'Erinnerung bearbeiten',
	removeReminder: 'Erinnerung löschen',
	enterYourNoteHere: 'Notiz hinzufügen',
	createANote: 'Notiz erstellen',
	addReminder: 'Erinnerung hinzuffügen',
	teacherLogin: 'Lehrer-Login',
	enterCredentialsYouReceivedFromSchool: 'Gib deine Schul- Anmeldedaten ein',
	deletePost: 'Beitrag löschen',
	doYouWantToDeletePost: 'Möchten Sie diesen Eintrag löschen?',
	setDateForReminderOfStudent: 'Setze ein Datum, an dem du an diese(n) Schüler(in) erinnert werden möchtest',
	createReminder: 'Erinnerung erstellen',
	writeFullAnswerHere: 'Schreibe deine Antwort hier aus',
	stop: 'Beenden',
	answers: 'Antworten',
	actionTaken: 'Fall in Bearbeitung',
	iWillTakeAction: 'Ich werde mich kümmern',
	seenBy: 'Gesehen von',
	thereAreNoAnswersForThatDay: 'Es gibt keine Antworten für diesen Tag ...',
	describeHowYouWillDealWithThisStudent: 'Beschreiben Sie, wie Sie mit diesem Schüler umgehen werden',
	writeYourThoughtsHere: 'Hier kannst du deine Gedanken dazu aufschreiben',
	studentData: 'Schülerdaten',
	student: 'Schüler',
	enterStudentUPN: 'UPN eingeben',
	showAll: 'Alles zeigen',
	loginStatistic: 'Login-Statistiken',
	hereYouCanAddTextImagesVideosOrLinks: 'Hier können Sie Texte, Bilder, Videos oder Links hinzufügen',
	typeAQuestionTitle: 'Geben Sie einen Fragetitel ein',
	typeYourHeadlineHere: 'Geben Sie hier Ihre Überschrift ein',
	optional: ' (Optional)',
	invalidLink: 'Ungültiger Link',
	chooseTemplateFor: 'Vorlage auswählen für ',
	doYouWantToDeleteThis: 'Willst du das löschen?',
	yourDataIsUpdated: 'Deine Daten werden aktualisiert!',
	yourDataIsNotUpdated: 'Deine Daten werden nicht aktualisiert!',
	contentIsCreated: 'Inhalt wird erstellt!',
	contentIsNotCreated: 'Inhalt wird nicht erstellt!',
	templateIsCreated: 'Vorlage ist erstellt!',
	templateIsNotCreated: 'Vorlage wird nicht erstellt!',
	allCreatedContent: 'Alle erstellten Inhalte',
	runProgram: 'Programm starten',
	teachersOnly: 'Nur für Lehrer',
	programTitle: 'Programmtitel',
	thisToolHelpsYouPlanTheEntireProgramOnce:
		'Dieses Werkzeug hilft dir, das gesamte Programm einmal zu planen, damit du es nicht ständig überprüfen musst',
	startDate: 'Anfangsdatum',
	selectDays: 'Tage wählen',
	pickTime: 'Zeit wählen',
	thisProgaramIsAlreadyRunning: 'Dieses Programm wird bereits ausgeführt, erneut ausführen?',
	youAreRunningThisProgramSuccessfully: 'Sie führen dieses Programm erfolgreich aus',
	surveys: 'Umfragen',
	Surveys: 'Umfragen',
	survey: 'Umfrage',
	description: 'Beschreibung',
	typeASurveyTitle: 'Umfragetitel erstellen',
	addASurveyDescription: 'Umfragenbeschreibung hinzufügen',
	surveyTitle: 'Umfragetitel',
	section: 'Abschnitt',
	sections: 'Abschnitte',
	enterSectionTitleHere: 'Abschnittstitel hinzufügen',
	enterSectionDescriptionHere: 'Abschnittsbeschreibung hinzufügen',
	enterQuestionHere: 'Frage hinzufügen',
	openAnswer: 'Antwort öffnen',
	addQuestion: 'Frage hinzufügen',
	addSection: 'Kategorie hinzufügen',
	deleteSurvey: 'Umfrage löschen',
	doYouWantToRemoveSurvey: 'Möchten Sie die Umfrage löschen?',
	yesNo: 'Ja/Nein',
	confirmation: 'Bestätigung',
	manageRunningPrograms: 'Laufenden Programme managen',
	numberOfSections: 'Anzahl der Abschnitte',
	thisToolHelpsYouToControlAllRunningProgramsAndTrackTheirInfluenceToStudents:
		'Dieses Tool hilft dir alle laufenden Programme zu managen und ihren Effekt auf die Schüler zu verfolgen',
	currentPrograms: 'Aktuelle Programme',
	yourRunningProgramListIsEmptyStartByAddingOneFromTheListOfPrograms:
		'Deine Liste laufender Programme ist leer. Starte ein Neues, indem du eines von der Programmliste auswählst',
	deleteProgram: 'Programm löschen',
	days: 'Tage',
	surveyIsCreated: 'Umfrage wird erstellt',
	yes: 'Ja',
	no: 'Nein',
	Yes: 'Ja',
	No: 'Nein',
	configureSurvey: 'Umfrage konfigurieren',
	editSurvey: 'Umfrage bearbeiten',
	runSurvey: 'Umfrage starten',
	selectMyStudents: 'Wählen Sie meine Schüler.',
	openFavorites: 'Favoriten öffnen',
	thereIsNoDataToShow: 'Keine Daten zum Anzeigen ',
	selectAll: 'Wählen Sie Alle',
	deselectAll: 'Alle abwählen',
	saveAsFavorite: 'Als Favorit speichern',
	doYouWantToDeleteFavorite: 'Möchten Sie die Favoritengruppe löschen?',
	saveYourFavoriteGroup: 'Speichern Sie Ihre Lieblingsgruppe',
	deleteWasSuccessful: 'Löschen war erfolgreich',
	listOf: 'Liste: ',
	deleteThis: 'Lösch das:',
	editThis: 'Bearbeite dies',
	youHaveNoChats: 'Du hast noch keine Chats',
	reminderSetFor: 'Erinnerung eingestellt für ',
	doYouWantToDeleteThisTask: 'Möchten Sie diese Aufgabe löschen?',
	assignedTo: 'Für:',
	selectTeacher: 'Lehrer auswählen',
	wellbeingTracker: 'Wohlgefühl Tracker',
	open_poll: 'Abstimmung öffnen',
	open_question: 'Frage öffnen',
	open_survey: 'Umfrage öffnen',
	students: 'Schüler',
	assignedActions: 'Zugewiesene Aktionen',
	reminder: 'Erinnerung',
	youDontHaveAnyAssignedAction: 'Sie haben keine zugewiesene Aktion',
	'Take it': 'Annehmen',
	'How are you?': 'Wie geht es Dir?',
	'Type an action': 'Geben Sie eine Aktion ein',
	'Do you want to close this counseling profile?': 'Möchten Sie dieses Beratungsprofil schließen?',
	'Do you want to open this counseling profile?': 'Möchten Sie dieses Beratungsprofil öffnen?',
	'This counseling profile is open!': 'Dieses Beratungsprofil ist offen!',
	'This counseling profile is closed!': 'Dieses Beratungsprofil ist geschlossen!',
	'Reminder has been removed!': 'Die Erinnerung wurde entfernt!',
	'Assign action': 'Aktion zuweisen',
	'Export data': 'Daten exportieren',
	'Message only visible to staff with special access': 'Nachricht nur für Mitarbeiter mit speziellem Zugang sichtbar',
	'Set a date when you would like to be reminded about this student':
		'Legen Sie ein Datum fest, an dem Sie an diesen Schüler erinnert werden möchten',
	'Message is visible to all': 'Nachricht ist für alle sichtbar',
	'Choose a date': 'Wählen Sie ein Datum',
	'Teacher is created and password has been sent!': 'Lehrer ist erstellt und Passwort wurde gesendet!',
	'Teacher is not created!': 'Lehrkraft ist nicht erstellt!',
	'Teacher is removed!': 'Lehrer wurde entfernt!',
	'Teacher is not removed!': 'Lehrer wurde nicht entfernt!',
	'Unregistered teachers': 'Nicht registrierte Lehrer',
	'Unregistered users': 'Nicht registrierte Benutzer',
	'Registered teachers': 'Registrierte Lehrer',
	'Registered users': 'Registrierte Benutzer',
	'Login data': 'Anmeldedaten',
	'Current Emotion': 'Aktuelle Emotion',
	'Add a teacher': 'Lehrkraft hinzufügen',
	'Add a new class': 'Neue Klasse hinzufügen',
	'Last login': 'Letzter Log in',
	'Most Popular Emotion': 'Am häufigsten gewählte Emotionen',
	'Type any additional text here': 'Zusätzlichen Text hier eingeben',
	chooseTheAnswer: 'Wähle eine Antwort',
	findTheRightSolution: 'Finde die richtige Lösung',
	'7 Days': '7 Tage',
	'3 Months': '3 Monate',
	'1 Month': '1 Monat',
	'1 Year': '1 Jahr',
	'Add note': 'Notiz hinzufügen',
	'User activity': 'Benutzeraktivität',
	'Stop editing': 'Hör auf zu bearbeiten',
	'Student is created!': 'Schüler:in ist erstellt!',
	'Student is not created!': 'Schüler:in ist nicht erstellt!',
	'All emotions': 'Alle Emotionen',
	'Trend of your classrooms': 'Klassenzimmer Trends',
	'Your Upstrive (Tasks)': 'Deine Upstrive (Aufgaben)',
	'Write the answer': 'Schreibe die Antwort',
	assignAction: 'Aktion zuweisen',
	AssignActionToATeacherToCheckOnThisStudent: 'Lehrkraft mit der Kontrolle dieses Schülers / dieser Schülerin beauftragen',
	assignToTeacher: 'Lehrkraft zuweisen',
	chooseFromOurRangeOfReadyToGoProgramsDesignedToSupportYourStudentsAndSaveYouTimesNow:
		'Aus einer Auswahl fertiger Programme wählen, um deine Schüler zu unterstützen und um Zeit zu sparen',
	journals: 'Tagebücher',
	Journals: 'Tagebücher',
	createAQuestion: 'Frage erstellen',
	turnOff: 'Ausschalten',
	staffLogin: 'Mitarbeiter/-innen Login',
	token: 'Wertmarke',
	createYourOwn: 'Inhalte selbst erstellen',
	readyToGo: 'Ready-to-go',
	favorites: 'Favoriten',
	addToFavorites: 'Zu Favoriten hinzufügen',
	repeat: 'Wiederholen',
	addToWellbeingTracker: 'Zum Wellbeing Tracker hinzufügen',
	addToAnalytics: 'Zu Analysen hinzufügen',
	open_journal: 'Tagebuch öffnen',
	actionComment: 'Kommentar zur Aktion',
	lastNote: 'Letzte Anmerkung',
	Overdue: 'Überfällig',
	currentEmotion: 'Aktuelle Emotion',
	mostPopularEmotion: 'Am häufigsten gewählte Emotionen',
	messageIsVisibleToAll: 'Nachricht ist für alle sichtbar',
	messageOnlyVisibleToStaffWithSpecialAccess: 'Nachricht nur für Mitarbeiter mit speziellem Zugang sichtbar',
	assignActionSuccess: 'Aktion erfolgreich zugewiesen',
	filterIsNotSet: 'Filter wurde nicht angewendet',
	commentHasBeenSeen: 'Kommentar wurde gesehen!',
	catchUp: 'Catch-up',
	catchUpOnAnyStudentCommentsAttachedTo:
		'Nacharbeiten von Kommentaren der Schüler:innen zu unangenehmen Gefühlen, die möglicherweise übersehen wurden',
	unseen: 'Ungelesen',
	markAsSeen: 'Als gesehen markieren',
	fullTraining: 'Komplettes Training',
	individualFeaturesTraining: 'Individuelles Fertigkeiten Training',
	instructionVideosForStaff: 'Anleitungsvideo für Mitarbeiter/-innen',
	deleteYourPrivateNote: 'Willst du diese private Notiz wirklich löschen?',
	emotions: 'Emotionen',
	Emotions: 'Emotionen',
	studentRemoved: 'Schüler/-in wurde entfernt',
	fileHasBeenUploadedDataWillBeUpdatedWithin24Hours: 'Datei wurde hochgeladen. Die Daten werden in den nächsten 24 Stunden erneuert.',
	Neutral: 'Neutral',
	downloadTemplateFile: 'Vorlage-Datei herunterladen',
	previousUploads: 'Vorherige Uploads',
	fileLink: 'Dateien link',
	attachFile: 'Datei anhängen',
	uploadData: 'Datei hochladen',
	noUnsignedTeachers: 'Keine unsignierten Lehrer/-innen',
	noUnsignedStudents: 'Keine unsignierten Schüler:innen',
	reviewYourLoginStatistics: 'Überprüfe deine Anmeldestatistiken',
	registeredStudents: 'Registrierte Schüler:innen',
	unregisteredStudents: 'Unregistrierte Schüler:innen',
	uploadUserData: 'Benutzerdaten hochladen',
	addATeacher: 'Lehrkraft hinzufügen',
	addANewClass: 'Neue Klasse hinzufügen',
	enterStudentsDetails: 'Schüler:innen Details hinzufügen',
	enterTeachersDetails: 'Lehrer/-innen Details hinzufügen',
	cantCreateStudentWithoutCreateClass: 'Schüler/-in kann nicht ohne Klasse hinzugefügt werden',
	createWellbeingProfile: 'Wellbeing Profil erstellen',
	yourStudentListIsEmpty: 'Deine Schüler:innen Liste ist leer',
	addAStudent: 'Schüler/-in hinzufügen',
	letThemSetTheirOwnPasswordsAfterLogin: 'Eigenes Passwort nach erstem Login erstellen lassen',
	keepThisPassword: 'Dieses Passwort behalten',
	rolemodelIsnotLoaded: 'Beispiel konnte nicht geladen werden...Lade die Seite erneut!',
	loginData: 'Anmeldedaten',
	exportData: 'Daten exportieren',
	passwordIsNotCorrect: 'Passwort stimmt nicht',
	closePopUp: 'Pop-up schließen',
	deleteStudent: 'Schüler/-in löschen',
	deleteTeacher: 'Lehrkraft löschen',
	hidePasswordmanager: 'Passwort-Manager ausblenden',
	setStudentsPasswordManually: 'Schüler:innen Passwort manuell auswählen',
	generateNewPassword: 'Neues Passwort erstellen',
	lastName: 'Nachname',
	noStudentsYet: 'Keine Schüler:innen bisher',
	loggedIn: 'Eingeloggt',
	noAnswer: 'Keine Antworten',
	changeStatus: 'Status ändern',
	answerOnHowAreYou: 'Antwort zu "Wie geht es dir?"',
	teacherRole: 'Lehrer/-innenrolle',
	selectTeacherRole: 'Lehrer/-innen Rolle auswählen',
	thisTeacherWillbe: 'Dieser Lehrer wird',
	negative: 'Negativ',
	classIsUpdated: 'Klasse ist aktualisiert',
	classIsNotUpdated: 'Klasse ist nicht aktualisiert',
	teacherIsCreatedAndPasswordHasBeenSent: 'Lehrer ist erstellt und Passwort wurde gesendet!',
	teacherIsNotCreated: 'Lehrkraft ist nicht erstellt!',
	createYourOwnContent: 'Erstelle deinen eigenen Inhalt',
	contentDeleted: 'Inhalt gelöscht',
	upcomingContent: 'Zukünftige Events',
	overview: 'Übersicht',
	feedPosts: 'Feed Post',
	welcomeBack: 'Willkommen zurück',
	ofStudentsUsingHowAreYouTodayAtLeastOncePerWeek: '% der Schüler:innen benutzen "Wie geht es dir heute?" mindestens einmal pro Woche',
	yourClassroom: 'Dein Klassenzimmer',
	checkWhatsGoingOn: 'Prüfe was gerade vor sich geht',
	myClassroom: 'Mein Klassenzimmer',
	youDeletedTargetGroup: 'Zielgruppe löschen',
	deeperInsights: 'Tiefere Einblicke',
	findYourStudentsWhoAreLoggingUncomfortableEmotions: 'Finde Schüler:innen die negative Emotionen angeben ',
	monitoringGroups: 'Gruppe kontrollieren',
	createGroup: 'Gruppe erstellen',
	whatIsIt: 'Was ist das?',
	createdNewMonitoringGroup: 'Neue Kontrollgruppe erstellen!',
	updatedMonitoringGroup: 'Kontrollgruppe aktualisieren',
	nameANewGroup: 'Neue Gruppe benennen',
	chooseEmotions: 'Emotion wählen',
	close: 'Schließen',
	actionRequired: 'Maßnahmen erforderlich',
	stayUpToDateWithStudentSupport: 'Mit der Unterstützung von Schüler:innen auf dem Laufenden bleiben',
	actionsAssignedToMe: 'Mir zugewiesene Aufgaben',
	studentChat: 'Schüler/-innen Chat',
	unreadMessage: 'ungelesene Nachricht',
	assignedBy: 'Zugewiesen durch',
	addNote: 'Notiz hinzufügen',
	engagementStatistics: 'Engagement-Statistik',
	trackEngagementResponsivenessLogins: 'Engagement, Antwortverhalten und Anmeldungen verfolgen',
	analytics: 'Analysen',
	ofStudentsUsingUpstriveWeekly: '% aller Schüler:innen nutzen Upstrive wöchentlich',
	ofResponsesToActivePollsQuestion: '% der Antworten auf aktive Umfragen',
	ofTeachersLoggedIn: '% der Lehrkräfte haben sich angemeldet',
	ofStudentsLoggedIn: '% der Schüler:innen haben sich eingeloggt',
	displayAll: 'Alle anzeigen',
	'display all': 'Alle anzeigen',
	'Display all': 'Alle anzeigen',
	onlyMine: 'Nur meine',
	searchResults: 'Antworten durchsuchen',
	filter: 'Filter',
	from: 'Von',
	to: 'Bis',
	notSelected: 'Nicht ausgewählt',
	questions: 'Fragen',
	Questions: 'Fragen',
	polls: 'Abstimmungen',
	Polls: 'Abstimmungen',
	runReadyToGo: 'Ready-to-go Inhalte verwenden',
	journal: 'Tagebuch',
	target: 'Zielgruppe',
	clickToBrowseResults: 'Klicke, um Ergebnisse anzuzeigen',
	by: 'Von',
	archived: 'Archiviert',
	download: 'Download',
	thereIsntAnyAnswerYet: 'Es gibt bisher keine Antworten',
	start: 'Start',
	createPosts: 'Beitrag erstellen',
	deleteFeedContent: 'Beiträge löschen?',
	congratulations: 'Herzlichen Glückwunsch',
	youHaveCompletedAllYourTasks: 'Du hast alle Aufgaben erledigt',
	submitAnswer: 'Antwort abgeben',
	takeIt: 'Annehmen',
	runReadyToGoContent: 'Ready-to-go Inhalte verwenden',
	yourFeedBackHasBeenSubmited: 'Deine Rückmeldung wurde gespeichert',
	useTemplate: 'Vorlage verwenden',
	templateDeleted: 'Vorlage wurde gelöscht',
	fileIsToBig: 'Datei ist zu groß!',
	templateIsUpdated: 'Die Vorlage wurde aktualisiert.',
	contentNeedsToBeReviewedByTheScoolAdmin: 'Der Inhalt muss von der Schulleitung überprüft werden!',
	headline: 'Überschrift',
	typeHeadlineHere: 'Gebe die Überschrift hier ein',
	supportiveText: 'Unterstützender Text',
	enterLinkOptional: 'Link hinzufügen (optional)',
	writeOption1Here: 'Schreibe hier Option 1 hinein',
	deleteOption: 'Option löschen',
	'Select target': 'Auswählen',
	pleaseFillRequiredFields: 'Pflichtfelder ausfüllen',
	updateTemplate: 'Vorlage hochladen',
	templateIsSaved: 'Vorlage wurde gespeichert!',
	publish: 'Veröffentlichen',
	surveyIsCreatedAsTemplate: 'Umfrage wurde als Vorlage gespeichert!',
	surveyIsDeleted: 'Umfrage wurde gelöscht!',
	moveSectionUp: 'Sektion nach oben verschieben',
	moveSectionDown: 'Sektion nach unten verschieben',
	headlineForSection: 'Überschrift für die Sektion',
	descriptionForSection: 'Beschreibung für die Sektion',
	moveQuestionUp: 'Frage nach oben verschieben',
	moveQuestionDown: 'Frage nach unten verschieben',
	selectTypeOfQuestion: 'Fragentyp wählen',
	writeOption: 'Schreibe hier Option {{result}} hinein',
	setupADateAndTime: 'Zeit und Datum festlegen',
	weekly: 'Wöchentlich',
	monthly: 'Monatlich',
	everyMonthOn: 'Jeden Monat am',
	endDate: 'Enddatum',
	selectACategory: 'Wähle eine Kategorie',
	selectSpecificYearGroupsToGatherMoreInformationFrom: 'Wähle eine Jahrgangsstufe aus, um weitere Informationen zu erhalten',
	customize: 'Anpassen',
	selectYourFavoriteGroup: 'Wähle deine Lieblingsgruppe',
	selectCustom: 'Benutzerdefiniert ',
	setATime: 'Setze eine Zeit fest',
	readyToGoContent: 'Sofort einsatzbereite Inhalte',
	sortBy: 'Sortieren nach',
	type: 'Typ',
	evidenceBasedContentPreparedForYouByOurTeamOfExperts: 'Evidenzbasierte Inhalte von Expertenteams für dich zusammengestellt',
	'Choose from our range of ready-to-go programs designed to support your students and save you time':
		'Wähle aus unserem Angebot an sofort einsatzbereiten Programmen, die deine Schüler:innen unterstützen und dir Zeit sparen.',
	'Each of our ready-to-go surveys is designed to help you understand your students better':
		'Jede unserer sofort einsatzbereiten Umfragen soll dir helfen, deine Schüler:innen besser zu verstehen.',
	'Pick a journal that will keep your students engaged': 'Wähle einen Beitrag, der deine Schüler:innen interessiert.',
	currentlyRunning: 'In Bearbeitung',
	running: 'Läuft',
	paused: 'Pausiert',
	playProgram: 'Programm abspielen',
	pauseProgram: 'Programm pausieren',
	doYouWantToDeleteThisProgram: 'Möchtest du dieses Programm löschen?',
	doYouWantToStartThisProgram: 'Möchtest du dieses Programm starten?',
	doYouWantToStopThisProgram: 'Möchtest du dieses Programm stoppen?',
	postIndividually: 'Individuell posten',
	lastCreated: 'Zuletzt kreieren',
	youMustSelectTargetGroup: 'Du musst eine Zielgruppe auswählen!',
	youMustSelectDaysOfWeek: 'Du musst Wochentage auswählen!',
	Sun: 'So',
	Mon: 'Mo',
	Tue: 'Di',
	Wed: 'Mi',
	Thu: 'Do',
	Fri: 'Fr',
	Sat: 'Sa',
	runIt: 'Durchlaufen lassen',
	classroomTrends: 'Klassenzimmer Trends',
	searchForStudent: 'Nach Schüler/-in suchen',
	searchForTeacher: 'Nach Lehrkraft suchen',
	'all emotions': 'Alle Emotionen',
	uncomfortable: 'Unwohl',
	filterClassroom: 'Klassenzimmer filtern',
	yourIndividualStudentsResponses: 'Individuelle Antworten deiner Schüler:innen',
	uncomfortableEmontionsWithComments: 'Negative Emotionen mit Kommentaren',
	wellbeingOfTeachers: 'Wellbeing der Lehrer/-innen',
	youMustFillData: 'Du musst Daten angeben',
	actionHasBeenAssignedToTeacher: 'Aufgabe wurde der Lehrkraft zugewiesen',
	assignActionFailed: 'Aktion zuweisen fehlgeschlagen',
	sendMessage: 'Nachricht schicken',
	classroomTeacher: 'Klassenlehrer/-in',
	headOfYear: 'Jahrgangsleiter/-in',
	otherTeachers: 'Andere Lehrkräfte',
	typeAnAction: 'Geben Sie eine Aktion ein',
	sendPrivateMessageToStudent: 'Private Nachricht an einen Schüler/ eine Schülerin senden',
	messageHasBeenSent: 'Nachricht wurde gesendet',
	getDeeperInsights: 'Hier kannst du Trends verfolgen',
	searchQuestion: 'Frage suchen',
	clickToSeeQuestionInDetails: 'Klicke, um die detaillierte Frage zu sehen',
	getDeeperInsightsIntoYourStudentsEmotionalTrends: 'Erhalte tiefere Einblicke in die emotionalen Trends deiner Schüler:innen',
	getInsights: 'Einblicke erhalten',
	totalCommentsLeftUsingHowAreYouToday: 'Gesamtzahl der Kommentare, die unter "Wie geht es dir heute?" hinterlassen wurden',
	basics: 'Basics',
	getBackToMainAnalytics: 'Zurück zu Hauptanalyse',
	searchStudents: 'Schüler/-in suchen',
	lastLogin: 'Letzter Log in',
	neverLoggedIn: 'Noch nie eingeloggt',
	loggedInRecently: 'Kürzlich eingeloggt',
	studentsNotFound: 'Schüler/-in nicht gefunden',
	showMoreStudents: 'Mehr Schüler:innen anzeigen',
	searchTeachers: 'Lehrer/-in suchen',
	position: 'Position',
	teachersNotfound: 'Lehrer/-in nicht gefunden',
	showMoreTeachers: 'Mehr Lehrer/-innen anzeigen',
	studentsWhoDidntRespondToAnyQuestionInPastTwoWeeks: 'Schüler:innen, die keine Fragen in den letzten zwei Wochen beantwortet haben',
	thisCanTakeAwhile: 'Dies kann eine Weile dauern',
	barView: 'Balkenansicht',
	averageResponseDistributionForSelectedPeriod: 'Durchschnittliche Antwortverteilung für den ausgewählten Zeitraum',
	percentageOfResponseDistributionByGender: 'Prozentuale Verteilung der Antworten nach Geschlecht',
	general: 'Allgemein',
	percentageOfResponseDistributionIntotalForSelectedPeriod: 'Prozentsatz der Antwortverteilung insgesamt für den ausgewählten Zeitraum',
	totalCommentsInHowareYouToday: 'Alle Kommentare zu "Wie geht es dir heute?"',
	emotion: 'Emotion',
	noOfCommentsAnsweringHowAreYouToday: 'Anzahl an Kommentaren, die auf "Wie geht es dir heute?" geantwortet haben',
	change: 'Ändern',
	mostCommonReasons: 'Häufigste Gründe',
	thereIsnotAnyConversationYet: 'Es gibt bisher keine Unterhaltungen',
	replyOn: 'Antworten auf',
	myMessage: 'Meine Nachricht',
	'Multiple years': 'Jahrgänge',
	'Multiple classes': 'Klassen',
	addPhotoVideo: 'Foto/Video hinzufügen',
	remove: 'Löschen',
	sorryToHearThatThingsAreWorse: 'Es tut uns leid zu hören, dass es dir schlechter geht.',
	sorryToHearThatThingsAreMuchWorse: 'Es tut uns leid zu hören, dass es dir schlechter geht.',
	goodToHearThingsAreBetter: 'Schön zu hören, dass sich die Lage verbessert hat.',
	greatToHearThingsAreMuchBetter: 'Schön zu hören, dass sich die Lage verbessert hat.',
	'You have no live content': 'Es gibt noch keine Inhalte',
	'Create something and see the results live!': 'Erstelle deinen Inhalt und bekomme live feedback!',
	'Creation shortcuts for you': 'Hier kannst du Inhalte machen',
	'You have no chats': 'Du hast noch keine Chats',
	'Type a question': 'Hier Frage eingeben',
	anonymous_question: 'Anonyme Frage',
	anonymous_survey: 'Anonyme Umfrage',
	anonymous_poll: 'Anonyme Abstimmung',
	'Pick one option': 'Eine Option wählen',
	'Allow more than one answer': 'Mehr als eine Antwort erlauben',
	solutions: 'Lösungswege',
	sos: 'SOS',
	'copy message': 'Nachricht kopieren',
	"List of students who haven't logged in yet and it’s ": 'List von Schülern die sich noch nicht eingeloggt haben',
	'Students login data': 'Schüler Login Daten',
	'Select from favorites': 'Aus Favoriten auswählen',
	Favorite: 'Favorit',
	'Select your favorite group': 'Wähle deine Lieblingsgruppe',
	'New Version is Available': 'Neue Version verfügbar',
	'Delete this note?': 'Diesen Eintrag löschen?',
	'Stop tracking live results for title': 'Für {{title}} keine Ergebnisse mehr specihern',
	'Delete this favorite group?': 'Diesen Favoriten löschen?',
	'Do not agree': 'Ich stimme nicht zu',
	"If you don't agree to Terms and Conditions or Privacy Policy you are not going to be allowed to use the app!":
		'Bitte Datenschutzbestimmungen und Privacy Policy zustimmen',
	'You will be logged out': 'Du wirst jetzt ausgeloggt',
	'Delete this feed?': 'Diese Beiträge löschen?',
	'Delete the template for title': 'Vorlage für {{title}}  löschen',
	'No one yet': 'Niemand bisher',
	'Seen by:': 'Gesehen von:',
	Copied: 'Kopiert',
	'Action taken': 'Fall in Bearbeitung',
	'Write your thoughts here': 'Hier kannst du deine Gedanken dazu aufschreiben',
	'Describe how will you take action about this student': 'Was genau hast du geplant?',
	'I will take action': 'Ich werde mich kümmern',
	'Ask your manager to assign you to class.': 'Bitte an Admin wenden, um eine Klasse zugeordnet zu bekommen',
	'You have no data about your classroom': 'Es gibt noch keine Daten aus deiner Klasse',
	'Terms and Conditions and Privacy Policy': 'Bedingungen, Richtlinien und Privatsphäre',
	"I confirm that I read and agree to Upstrive's":
		'Ich bestätige, dass ich die Datenschutzbestimmungen und die Bedingungen und Richtlinien von Upstrive gelesen habe und bestätige diese.',
	'Terms and Conditions': 'Bedingungen und Richtlinien',
	'I do not agree': 'Ich stimmt nicht zu',
	'New survey!': 'Neue Umfrage!',
	youHaveASurveyAbout: 'Du hast eine Umfrage zu ',
	voluntary: 'freiwillig',
	mandatory: 'Pflicht',
	'Language is changed': 'Sprache wurde geändert',
	'Your counseling list is empty, start with adding a new counseling profile for one of your students':
		'Die Liste ist leer. Beginne eine neue, indem du ein Profil für einen deiner Schüler erstellst.',
	'Reminder for today': 'Erinnerung für heute',
	'Track Counseling': 'Beratung nachverfolgen',
	'Congrats, everything is done for now!': 'Glückwunsch, du hast alles geschafft!',
	'Wait for new tasks...': 'Neue Aufgaben kommen sicher bald… ',
	'Manage running programs': 'Laufenden Programme managen',
	'This tool helps you to control all running programs and track their influence to students':
		'Dieses Tool hilft dir alle laufenden Programme zu managen und ihren Effekt auf die Schüler zu verfolgen',
	'Your running program list is empty, start by adding one from the list of programs':
		'Deine Liste laufender Programme ist leer. Starte ein Neues, indem du eines von der Programmliste auswählst',
	'Run program': 'Programm starten',
	"This tool helps you to schedule whole program once so you don't need to check it all the time":
		'Dieses Tool hilft dir ein Programm so zu erstellen, dass es selbstständig läuft',
	'Target group:': 'Zielgruppe',
	'Start date:': 'Anfangsdatum:',
	'Select days:': 'Tage wählen:',
	'Pick time:': 'Zeit wählen:',
	'Current programs:': 'Aktuelle Programme:',
	Running: 'Läuft',
	'Show more programs': 'Weitere Programme zeigen',
	'Create a survey': 'Umfragen erstellen',
	'Type a survey title': 'Umfragenüberschrift erstellen',
	'add a survey description': 'Umfragenbeschreibung hinzufügen',
	'enter a section title here': 'Abschnittstitel hinzufügen',
	Section: 'Abschnitt',
	Sections: 'Abschnitte',
	'enter a section description here': 'Abschnittsbeschreibung hinzufügen',
	'Enter a question here': 'Frage hinzufügen',
	'Add section': 'Kategorie hinzufügen',
	'Delete survey': 'Umfrage löschen',
	'Save Survey': 'Umfrage speichern',
	'Edit Survey': 'Umfrage bearbeiten',
	'This tool helps you to run a survey for your students': 'Dieses Tool hilft dir eine Umfrage für deine Schüler zu erstellen',
	'Configure survey': 'Umfrage konfigurieren',
	'Easily manage your students and teachers, using separate functions': 'Hier kannst du Schülern und Lehrern einfach verwalten',
	'Login info': 'Login Information',
	'Class info': 'Klassen Information',
	Download: 'Download',
	'Export report': 'Bericht exportieren',
	'Enter your note here': 'Notiz hinzufügen',
	'Add reminder': 'Erinnerung hinzuffügen',
	'Show more surveys': 'mehr Umfragen zeigen',
	'Open survey results': 'Umfragenergebnisse öffnen',
	'Take it now': 'Jetzt Umfrage starten',
	'Take it later': 'Später',
	Category: 'Kategorie',
	'Select all': 'Wählen Sie Alle',
	'Survey results': 'Umfrageergebnisse',
	'We hope you’ve had a good day!': 'Wir hoffen, Du hattest einen guten Tag!',
	'Save your favorite group': 'Speichern Sie Ihre Lieblingsgruppe',
	anonymous_journal: 'Anonymes Tagebuch',
	'Welcome back': 'Willkommen zurück!',
	'Here’s what happened while you were away': 'Hier siehst du was passiert ist während du weg warst',
	Overview: 'Übersicht',
	Posts: 'Posts',
	'Action Required': 'Maßnahmen erforderlich',
	'Your Classrooms': 'Dein Klassenzimmer',
	'Engagement Statistics': 'Engagement-Statistik',
	'% of responses on active polls & questions': '% der Antworten auf aktive Umfragen',
	'% of students using "How are you?" at least once per week':
		'% deiner Schüler:innen benutzen "Wie geht es dir?" mindestens einmal pro Woche',
	'Edit Group': 'Gruppe bearbeiten',
	'Create Group': 'Gruppe erstellen',
	'Monitoring group': 'Gruppe kontrollieren',
	'You have to select at least one emotion': 'Du musst mindestens eine Emotion auswählen',
	'Create Group and Apply filter': 'Gruppe erstellen',
	'Edit Group and Apply filter': 'Kontrollgruppe aktualisieren',
	'Name a new group type here': 'Nenne hier eine neue Gruppenart',
	'Created new monitoring group!': 'Neue Kontrollgruppe erstellen!',
	'Updated monitoring group!': 'Kontrollgruppe aktualisieren',
	'Search results': 'Antworten durchsuchen',
	Filter: 'Filter',
	'Filter results': 'Resultate filtern',
	'Filter posts': 'Posts filtern',
	'Setup your filter': 'Filter erstellen',
	'Filter by dates': 'Nach Datum filtern',
	'Select what to display': 'Auswählen, was angezeigt werden soll',
	'Display All': 'Alle anzeigen',
	From: 'Von',
	To: 'Bis',
	Date: 'Datum',
	Target: 'Zielgruppe',
	By: 'Von',
	Time: 'Zeit',
	'Delete this question?': 'Diese Frage löschen?',
	'Delete this poll?': 'Diese Abstimmung löschen?',
	'Delete this survey?': 'Diese Umfrage löschen?',
	'Delete this post?': 'Diesen Beitrag löschen?',
	'Delete this program?': 'Dieses Programm löschen?',
	'Open link': 'Link öffnen',
	'Action needed': 'Handlungsbedarf',
	'Select emotion that fits your mood': 'Wähle eine Emotion, die zu deiner Stimmung passt',
	"What's the main reason?": 'Was ist der Hauptgrund hierfür?',
	'Pick up to 3': 'Wähle bis zu 3',
	myself: 'Ich',
	family: 'Familie',
	friends: 'Freunde',
	'social media': 'Soziale Medien',
	wellbeing: 'Wellbeing',
	health: 'Gesundheit',
	school: 'Schule',
	achievement: 'Erfolge',
	sleep: 'Schlaf',
	Myself: 'Ich',
	Family: 'Familie',
	Friends: 'Freunde',
	'Social media': 'Soziale Medien',
	Wellbeing: 'Wellbeing',
	Health: 'Gesundheit',
	School: 'Schule',
	Achievement: 'Erfolge',
	Sleep: 'Schlaf',
	'How do you feel compared to last time you logged in?': 'Wie fühlst du dich im Vergleich zum letzten Mal als du dich eingeloggt hast?',
	'Do you think things are same for any reason in particular?': 'Gibt es einen bestimmten Grund, warum alles wie beim letzten Mal ist?',
	'Type name for your note here': 'Gebe hier einen Namen für deine Notiz an ',
	Chat: 'Chat',
	'Catch-up': 'Catch-up',
	Menu: 'Menü',
	'Wellbeing Tracker': 'Wohlgefühl Tracker',
	Day: 'Tag',
	'Choose your answer': 'Wähle deine Antwort',
	'1st': '1.',
	'2nd': '2.',
	'3rd': '3.',
	History: 'Historie',
	'My Classroom': 'Mein Klassenzimmer',
	'Actions required': 'Maßnahmen erforderlich',
	'Comments for review': 'Kommentare zur Überprüfung',
	'Actions assigned to me': 'Mir zugewiesene Aufgaben',
	'Student chat': 'Schüler/-innen Chat',
	'Select one option': 'Wähle eine Option',
	Finish: 'Beenden',
	Next: 'Nächste',
	Uncomfortable: 'Unwohl',
	'Classroom Trends': 'Klassenzimmer Trends',
	'Filter Emotions': 'Emotionen filtern',
	Comments: 'Kommentare',
	'Filter Classroom': 'Klassenzimmer filtern',
	'No comments': 'Keine Kommentare',
	'Seen by': 'Gesehen von',
	'Assigned to': 'Zugewiesen zu',
	'Send Private message to student': 'Private Nachricht an einen Schüler/ eine Schülerin senden',
	'Write personal message for the student here': 'Schreibe hier eine private Nachricht für einen Schüler/ eine Schülerin',
	'Select teacher': 'Lehrer auswählen',
	'Assign to teacher': 'Lehrkraft zuweisen',
	'Classroom Teacher': 'Klassenlehrer/-in',
	'Head of Year': 'Jahrgangsleiter/-in',
	'Other Teachers': 'Andere Lehrkräfte',
	'Assign to this teacher': 'Dieser Lehrkraft zuweisen',
	'Type here': 'Hier eingeben ',
	'Message has been sent to student!': 'Nachricht wurde an den Schüler/ die Schülerin verschickt',
	'Check for any student comments that may have been overlooked': 'Prüfe, ob du Kommentare von Schüler/-innen übersehen hast',
	'My message': 'Meine Nachricht',
	Unseen: 'Ungelesen',
	'No unseen replies left': 'Keine ungelesenen Antworten',
	'Congratulations!': 'Herzlichen Glückwunsch!',
	'wellbeing tracker': 'Wohlgefühl Tracker',
	'Mark as seen': 'Als gesehen markieren',
	'Comment has been seen!': 'Kommentar wurde gesehen!',
	'You can press yellow button and create a new Wellbeing profile':
		'Du kannst den gelben Button wählen, um ein neues Wellbeing Profil zu erstellen',
	'Your list is empty': 'Deine Liste ist leer',
	'You are not in charge of this student so your messages will be forwarded to school admin or counselor':
		'Du bist nicht für diesen Schüler/ diese Schülerin verantwortlich. Deine Nachricht wird zum Schuladministrator weitergeleitet.',
	'Log comment': 'Kommentar protokollieren',
	'Click to copy email': 'Klicken, um E-mail zu kopieren',
	'% of responses on active polls, questions': '% von Antworten auf aktive Umfragen/Fragen',
	'Date of Birth': 'Geburtstag',
	'Create your own': 'Inhalte selbst erstellen',
	'Ready-to-go': 'Ready-to-go',
	'Feed Posts': 'Feed Post',
	'You are not allowed to create content': 'Du bist nicht dazu berechtigt Inhalte zu erstellen',
	'Upcoming Content': 'Zukünftige Events',
	'Pending Content': 'Ausstehender Inhalt',
	'Create a question': 'Frage erstellen',
	'Headline for question': 'Überschrift der Frage',
	'Headline for poll': 'Überschrift  der Abstimmung',
	Headline: 'Überschrift',
	'Supportive Text': 'Unterstützender Text',
	'Select one item from the list': 'Wähle eine Möglichkeit aus der Liste',
	'Pick one or more options': 'Wähle eine oder mehrere Optionen',
	'Select one or more items from the list': 'Wähle eine oder mehrere Optionen aus der Liste',
	'Enter Link (optional)': 'Link hinzufügen (optional)',
	'Enter any url here': 'Gebe hier eine URL ein',
	AddVideoPhoto: 'Video/Foto hinzufügen',
	'The maximum image size for download is 1400px x 1400px': 'Die maximale Größe für Bilder beträgt 1400px x 1400px',
	'Media file is too big. Try smaller one': 'Mediendatei ist zu groß. Versuche es mit einer kleineren…',
	'Add to Wellbeing Tracker': 'Zum Wellbeing Tracker hinzufügen',
	'Target group is not selected': 'Zielgruppe wurde nicht ausgewählt',
	Option: 'Option',
	'Write option': 'Option schreiben',
	here: 'Hier ',
	'Use Template': 'Vorlage verwenden',
	"You don't have any incoming content": 'Du hast keine externen Beiträge erhalten',
	'Run a journal': 'Tagebuch starten',
	'View Details': 'Details ansehen',
	'Run it': 'Durchlaufen lassen',
	'Run a survey': 'Umfrage starten',
	'Survey needs to be reviewed by the school admin': 'Umfrage muss durch den Schuladministrator geprüft werden',
	'Survey has been created': 'Umfrage wurde erstellt',
	'Select a category': 'Wähle eine Kategorie',
	Favorites: 'Favoriten',
	Customize: 'Anpassen',
	OK: 'OK',
	'Post individual': 'Individueller Beitrag',
	'Select specific teachers you would like to get information from': 'Wähle Lehrkräfte von denen du gerne Informationen erhalten würdest',
	'Select specific year groups to gather more information from': 'Wähle eine Jahrgangsstufe aus, um weitere Informationen zu erhalten',
	'Select specific classes to gather more information from': 'Wähle Klassen aus, um weitere Informationen zu sammeln',
	'Create your own customized group to gather information from': 'Erstelle deine eigene Gruppe, um Informationen zu sammeln',
	'Add to favorites': 'Zu Favoriten hinzufügen',
	feelingSameQuestion: 'Was kannst du jetzt tun, um die Lage zu verbessern?',
	good: 'gut',
	numb: 'betäubt',
	worried: 'besorgt',
	read: 'lesen',
	received: 'Erhalten',
	experienceIt: '% erleben es',
	anonymousQuestion: 'Anonyme Frage',
	openQuestion: 'Frage öffnen',
	Attention: 'Achtung!',
	toEnsureYourPrivacyThisKeyIsNotRecoverableAllYourNotesWillBeLostIfYouForgetThisKey:
		'Um deine Privatsphäre zu schützen, ist dieser Schlüssel nicht wiederherstellbar. Wenn du diesen Schlüssel vergisst, gehen alle deine Notizen verloren!',
	anonymousPoll: 'Anonyme Abstimmung',
	Previous: 'Vorher',
	'At least 8 characters': 'Mindestens 8 Zeichen',
	'Your results are saved!': 'Deine Ergebnisse wurden gespeichert!',
	rememberMe: 'Angemeldet bleiben',
	instructionVideo: 'Erklärungsvideo',
	yourTasks: 'Deine Aufgaben',
	activitiesForCompletion: 'Ausstehende Aufgaben',
	oopsThereSeemsToBeAnIssueWithConnection: 'Es scheint ein Problem mit deiner Internetverbindung zu geben',
	pleaseTryAgainInAFewMinutestOrContactUpstriveSupport: 'Bitte versuche es erneut in ein paar Minuten oder kontaktiere Upstrive Support',
	loadingMoreFeeds: 'Mehr Inhalte werden geladen',
	openJournal: 'Tagebuch öffnen',
	selectEmotionThatBestDescribesYourMoodToday: 'Wähle eine Emotion, die deine heutige Stimmung am besten beschreibt',
	yourBrowserDoesNotSupportHTMLVideo: 'Dein Browser unterstützt kein HTML-Video',
	whatIsTheMainReason: 'Was ist der Hauptgrund hierfür?',
	pickUpTo3: 'Wähle bis zu 3',
	typeAnyAdditionalTextHere: 'Zusätzlichen Text hier eingeben',
	howDoYouFeelComparedToLastTimeYouLoggedIn:
		'Wie fühlst du dich im Vergleich zu dem Zeitpunkt, an dem du dich das letzte Mal eingeloggt hast?',
	iDontWantToThinkAboutItNow: 'Ich möchte gerade nicht darüber nachdenken',
	stepBack: 'Zurückgehen',
	whatCouldYouDoToImproveThingsRightNow: 'Was könntest du gerade tun, um die Dinge zu verbessern?',
	selectOneOption: 'Wähle eine Option',
	youHaveMissedThisQuestion: 'Du hast die Frage ausgelassen',
	finish: 'Beenden',
	nextSection: 'Nächster Teil',
	yourAnswersAreSaved: 'Deine Antworten wurden gespeichert!',
	typeYourAnswerHere: 'Gebe deine Antwort hier ein',
	iHaveAnIssueRelatedTo: 'Ich habe ein Problem mit....',
	sorryYourSchoolRestrictedAccessToThisModule: 'Leider hat deine Schule den Zugang zu diesem Modul eingeschränkt.',
	exploreThingsYouCanDoRightNow: 'Was du tun kannst - schnell und einfach',
	seeHowOtherStudentsHandleThis: 'Schaue dir an, wie andere Schüler/-innen damit umgehen ',
	connectWithTheConselor: 'Kontakt zur Beratungslehrkraft herstellen',
	quickTips: 'Schnelle Tipps',
	howToDealWithThisProblem: 'Wie du mit dem Problem umgehen kannst',
	thingsYouCanDoRightNow: 'Dinge, die du gerade tun kannst',
	yesThanks: 'Ja, danke!',
	notReally: 'Nicht wirklich',
	expertRecommendation: 'Experten Empfehlung',
	wasThisSolutionHelpful: 'War die Lösung hilfreich?',
	send: 'Senden',
	topic: 'Thema',
	'I Care / I Listen': 'SOS',
	privateNotes: 'Private Notizen',
	yourSecretKeyIsSaved: 'Dein Passwort wurde gespeichert!',
	changeSecurityKey: 'Geheimen Schlüssel ändern',
	passwordSetup: 'Passwort wählen',
	atLeast8Caracters: 'Mindestens 8 Zeichen',
	mobileAndDesktopStudentVersion: 'Mobile und Desktop - Schüler/-innen Version.mp4',
	weHopeYouHaveAGoodDay: 'Wir wünschen dir einen schönen Tag!',
	whatAre3ThingsThatAreABitChallengingAtTheMoment: 'Welche drei Dinge sind momentan etwas herausfordernd?',
	whatsJustOneThingYouWillDoDifferentlyTomorrow: 'Nenne eine Sache, die du morgen anders machen wirst ',
	typeOneThingHere: 'Nenne eine Sache',
	'Type 1st thing here': 'Schreibe die erste Sache hier',
	noteHasBeenDeleted: 'Die Notiz wurde gelöscht. ',
	returnBack: 'Kehre zurück',
	yourResultsAreSaved: 'Deine Ergebnisse wurden gespeichert!',
	majorUpdate: 'Wichtige Aktualisierung',
	whatsNew: 'Was gibt es Neues?',
	sameGreatAppFreshNewLook: 'Die gleiche tolle App mit neuem Aussehen und neuen Funktionen!',
	next: 'Nächste',
	closePopup: 'Pop-up schließen',
	Browse: 'Suchen/Stöbern',
	'% of students using Upstrive (per week, any function)': '% aller Schüler/-innen nutzen Upstrive wöchentlich',
	'% of teachers using Upstrive (per week, any function)': '% der Lehrkräfte haben sich angemeldet',
	'% this week': '% diese Woche',
	'Click on emotion to go into the classroom details': 'Klicken Sie auf "Emotion", um zu den Details des Klassenzimmers zu gelangen',
	'Type name in the field below': 'Geben Sie den Namen in das Feld darunter ein',
	'Track student task responses': 'Hier siehst du die Antworten der Schüler:innen auf die Abstimmungen und Umfragen',
	'Full name': 'Full name',
	"You can't delete yourself!": 'Du kannst dich nicht selbst löschen!',
	'Choose from our range of ready-to-go programs designed to support your students and save you times now':
		'Aus einer Auswahl fertiger Programme wählen, um deine Schüler zu unterstützen und um Zeit zu sparen',
	'% of students using “How are you?” at least once per week': '% der Schüler/-innen haben sich eingeloggt',
	feelingMuchWorseQuestion:
		'Es tut uns leid zu hören, dass es dir schlechter geht. Welche von den Vorschlägen unten kann dir jetzt weiterhelfen?',
	feelingBetterQuestion:
		'Schön zu hören, dass sich die Lage verbessert hat. Hast du Ideen wie du sie vielleicht noch besser machen könntest? ',
	'Teacher only': 'nur Lehrer',
	'Your tasks': 'Deine Aufgaben',
	'Tasks archive': 'Aufgaben-Archiv',
	'You have completed all your tasks': 'Du hast alle Aufgaben erledigt',
	'I have an issue related to': 'Ich habe ein Problem in Bezug auf',
	'Choose topic': 'Thema auswählen',
	'Sorry, your school restricted access to this module': 'Entschuldigung, deine Schule hat den Zugriff auf dieses Modul eingeschränkt',
	'Assignment for': 'Auftrag für',
	'Create Wellbeing profile': 'Wellbeing Profil erstellen',
	Home: 'Startseite',
	'Send message to all students': 'Nachricht an alle Schüler senden',
	'Send message to all teachers': 'Nachricht an alle Lehrer senden',
	'Finish later': 'Später beenden',
	'This day is locked!': 'Dieser Tag ist abgeschlossen!',
	'General Wellbeing': 'Allgemeines Wohlbefinden',
	'This is a great place to start if you’re looking for some general wellbeing content before diving into some of the more specific topics.':
		'Dies ist ein großartiger Ausgangspunkt, wenn du nach allgemeinem Wohlbefinden-Inhalt suchst, bevor du dich mit spezifischeren Themen beschäftigst.',
	'Personal Wellbeing': 'Persönliches Wohlbefinden',
	'Here you’ll find more personal wellbeing content designed to help you and your students learn more about specific challenges and ways to overcome them.':
		'Hier findest du mehr Inhalte zum persönlichen Wohlbefinden, die darauf abzielen, dir und deinen Schülern zu helfen, spezifische Herausforderungen zu verstehen und zu überwinden.',
	'If it’s a school-related topic such as getting organised, excelling in exams, or being productive, it’s right here for you!':
		'Wenn es um schulbezogene Themen wie Organisation, Prüfungserfolg oder Produktivität geht, findest du hier alles, was du brauchst!',
	'Stress Management': 'Stressmanagement',
	'Find out more about your students’ & colleagues’ experiences of stress with a survey and support them with a range of stress management tools.':
		'Finde mehr über die Erfahrungen deiner Schüler & Kollegen mit Stress heraus, indem du eine Umfrage durchführst, und unterstütze sie mit einer Reihe von Stressmanagement-Tools.',
	'Self-management': 'Selbstmanagement',
	'Retrieving data. Wait a few seconds and try to cut or copy again.':
		'Daten werden abgerufen. Bitte warte einige Sekunden und versuche dann erneut zu schneiden oder zu kopieren',
	SEL: 'SEL',
	'Our range of social and emotional learning (SEL) content is all designed to help you & your students enhance self-awareness and develop greater self-management skills.':
		'Unsere Palette an Inhalten zum sozialen und emotionalen Lernen (SEL) soll dir und deinen Schülern dabei helfen, das Selbstbewusstsein zu stärken und bessere Selbstmanagement-Fähigkeiten zu entwickeln.',
	mood: 'Stimmung',
	Default: 'Default',
	'% of all students are with selected type of emotions': '% aller Schüler:innen haben bestimmte Emotionen.',
	'It is 71% less then on previous week': 'Es ist {{num}}% weniger als in der Vorwoche',
	'It is 71% more then on previous week': 'Es ist {{num}}% mehr als in der Vorwoche',
	'Cannot create anonymous survey for 1 user': 'Es ist nicht möglich, eine anonyme Umfrage für einen einzelnen Benutzer zu erstellen.',
	'Cannot create anonymous poll for 1 user': 'Es ist nicht möglich, eine anonyme Abstimmung für einen einzelnen Benutzer zu erstellen.',
	'Cannot create anonymous poll for 2 users': 'Es ist nicht möglich, eine anonyme Abstimmung für nur zwei Benutzer zu erstellen.',
	'Release survey by days': 'Die Umfrage kann nach Tagen freigegeben werden.',
	"This tool helps you to schedule whole survey once so you don't need to check it all the time":
		'Dieses Tool hilft dir dabei, die gesamte Umfrage auf einmal zu planen, sodass du sie nicht ständig überwachen musst.',
	'Please select file': 'Bitte Datei auswählen',
	'Schedule Survey': 'Umfrage planen',
	'No items found': 'Es wurden keine Elemente gefunden.',
	completed: 'abgeschlossen',
	filterResults: 'Resultate filtern',
	'Add student': 'Schüler/-in hinzufügen',
	'Select surveys': 'Umfragen auswählen',
	'Type of class to create': 'Typ der zu erstellenden Klasse',
	Regular: 'Regulär',
	Primary: 'Primär',
	'Primary class': 'Primärklasse',
	Avatar: 'Avatar',
	'Not Setuped': 'Nicht eingerichtet',
	'Transfer to another class': 'In eine andere Klasse versetzen',
	'You must select class': 'Sie müssen eine Klasse auswählen',
	'Current class': 'Aktuelle Klasse',
	'Select new class': 'Neue Klasse auswählen',
	'Select class': 'Klasse auswählen',
	'Change avatar': 'Avatar ändern',
	'Edit Student': 'Schüler bearbeiten',
	'Step 1/2': 'Schritt 1/2',
	'Step 2/2': 'Schritt 2/2',
	'Choose Gender': 'Geschlecht auswählen',
	'Choose animal': 'Tier auswählen',
	'Create Student': 'Schüler erstellen',
	Available: 'Verfügbar',
	Taken: 'Belegt',
	'If you will choose already taken character it will auto unpin from existing child':
		'Wenn Sie ein bereits belegtes Charakterbild auswählen, wird es automatisch von einem vorhandenen Kind entfernt.',
	'Choose later': 'Zu einem späteren Zeitpunkt auswählen',
	'Save with selected avatar': 'Mit ausgewähltem Avatar speichern',
	'Are you sure you want to delete class?': 'Sind Sie sicher, dass Sie die Klasse löschen möchten?',
	'All Student in this class will be deleted': 'Alle Schüler dieser Klasse werden gelöscht',
	'No, cancel': 'Nein, abbrechen',
	'Yes, Delete': 'Ja, löschen',
	'Class successfully deleted': 'Klasse erfolgreich gelöscht',
	Success: 'Erfolg',
	'Transfer to another class successfully finished': 'Erfolgreicher Transfer in eine andere Klasse abgeschlossen',
	'Old class': 'Alte Klasse',
	Display: 'Anzeige',
	'Your Primary Classes': 'Ihre Primärklassen',
	'The new password has been sent!': 'Das neue Passwort wurde gesendet!',
	'Password delivery failed!': 'Die Übermittlung des Passworts ist fehlgeschlagen!',
	'File has been removed': 'Datei wurde entfernt',
	'Preset Ranges': 'Preset Ranges',
	'Last Month': 'Letzter Monat',
	'Last Quarter': 'Leztes Quartal',
	'Last Year': 'Letztes Jahr',
	'Data is saved': 'Daten werden gespeichert',
	'Allocation of answers from students who answered on “How are you Today?”':
		'Anzahl der Antworten von Schülern, die auf die Frage "Wie geht es Dir heute?" geantwortet haben',
	'New password has already been generated today for this user. Do you want to generate it again?':
		'Ein neues Passwort wurde heute bereits für diesen Benutzer generiert. Möchten Sie es erneut generieren?',
	'Not logged in': 'Nicht eingeloggt',
	'Logged in': 'Eingeloggt',
	'Current Weekly Engagement Statistics': 'Aktuelle wöchentliche Engagement-Statistiken',
	'Student logins': 'Studenten Logins',
	'Student check in': 'Einchecken der Studenten',
	'Student engagement with content': 'Auseinandersetzung der Studierenden mit Inhalten',
	'Teacher logins': 'Logins für Lehrkräfte',
	'This percentage reflects the number of teacher logins for this week':
		'Dieser Prozentsatz spiegelt die Anzahl der Anmeldungen von Lehrern für diese Woche wider',
	'This percentage reflects  students interacting with app content beyond check-ins.':
		'Dieser Prozentsatz spiegelt die Interaktion der Schüler mit den App-Inhalten über das Einchecken hinaus wider.',
	'This percentage reflects students who have both logged in and completed a check-in for the week':
		'Dieser Prozentsatz spiegelt die Schüler wider, die sich sowohl angemeldet als auch einen Check-in für die Woche durchgeführt haben.',
	'This statistic reflects the number of student logins for this week':
		'Diese Statistik zeigt die Anzahl der Anmeldungen von Schülern in dieser Woche',
	'Use this pre-set Sample Group to monitor the uncomfortable emotions your students might log.':
		'Verwenden Sie diese voreingestellte Mustergruppe, um die unangenehmen Emotionen zu beobachten, die Ihre Schüler aufzeichnen könnten.',
	'Sample Group': 'Test Gruppe',
	'Comfortable Emotions': 'Bequeme Emotionen',
	'Uncomfortable Emotions': 'Unbequeme Emotionen',
	clickTheButtonsOnTheRightToAccessSpecificAnalyticsForThoseTimeframes:
		'Klicke auf die Schaltflächen rechts, um spezifische Analysen für diese Zeiträume aufzurufen',
	effortlesslyMonitorYourStudentsEmotionalExperiencesByCreatingYourOwnCustomisedGroupsHere:
		'Erkenne die emotionalen Erfahrungen deiner Schüler, indem du hier deine eigenen Gruppen erstellst',
	'% of students responding to "How are you today? at least once a week.':
		'% der Schüler, die mindestens einmal pro Woche auf die Frage "Wie geht es Dir heute?"',
	'No. of comments left in response to "How are you today?':
		'Anzahl der hinterlassenen Kommentare auf die Frage "Wie geht es Dir heute?" ',
	'Emotion distribution for selected period': 'Gefühlsverteilung für den ausgewählten Zeitraum',
	'No. of comments left in response to "How are you today?"':
		'Anzahl der hinterlassenen Kommentare auf die Frage "Wie geht es Dir heute?" ',
	'No. of comments responding to, “How are you today?”': 'Anzahl der Kommentare auf die Frage "Wie geht es Dir heute?"',
	'More details': 'Mehr details',
	'Overall engagement': 'Gesamtengagement',
	'Engagement with emotions': 'Engagement mit Emotionen',
	'Total emotions submitted': 'Gesamte Emotionen',
	'Total number of emotions submitted in the time frame': 'Gesamtzahl der in dem Zeitraum eingereichten Emotionen',
	'Relative change to the same selected previous period': 'Relative Veränderung im Vergleich zum gleichen ausgewählten Vorzeitraum',
	'Percent of students that submitted an emotion in the selected time frame':
		'Prozentsatz der Studenten, die im ausgewählten Zeitraum eine Emotion eingereicht haben',
	'Percentage of students that used Upstrive in the period (any function, just opened it)':
		'Prozentsatz der Schüler, die Upstrive in diesem Zeitraum genutzt haben (jede Funktion, nur geöffnet)',
	'Percentage of students who have experienced these emotions in selected period':
		'Prozentsatz der Schüler, die in dem ausgewählten Zeitraum diese Emotionen erlebt haben',
	'Percentage of teachers who have registered to the app': 'Prozentsatz der Lehrer, die sich für die App registriert haben',
	'Percentage of students who have registered to the app': 'Prozentsatz der Studenten, die sich für die App registriert haben',
	'Percentage of students engaging with content this week':
		'Prozentsatz der Schüler, die sich in dieser Woche mit dem Inhalt beschäftigt haben',
	'Percentage of student check-ins this week': 'Prozentsatz der Schüler-Check-ins in dieser Woche',
	'Percentage of student logins this week': 'Prozentsatz der Anmeldungen von Schülern in dieser Woche',
	'Percentage of teacher logins this week': 'Prozentsatz der Lehreranmeldungen in dieser Woche',
	'Time period': 'Zeitspanne',
	'Time frame': 'Zeitrahmen',
	'Add a new trend': 'Einen neuen Trend hinzufügen',
	'Engagement with content': 'Beschäftigung mit Inhalten',
	'Percentage of students that submitted an emotion in the selected time frame':
		'Prozentsatz der Studenten, die im ausgewählten Zeitraum eine Emotion eingereicht haben',
	'Percentage of students engaging with content in the selected time frame':
		'Prozentsatz der Schüler, die sich im ausgewählten Zeitraum mit dem Inhalt beschäftigt haben',
	'General Engagement': 'Allgemeines Engagement',
	Registration: 'Anmeldung',
	'Percentage of students in your group who have registered in Upstrive':
		'Prozentsatz der Schüler in Ihrer Gruppe, die sich bei Upstrive angemeldet haben',
	'Percentage of teachers in your group who have registered in Upstrive':
		'Prozentsatz der Lehrkräfte in Ihrer Gruppe, die sich bei Upstrive registriert haben',
	'Remove from trends': 'Aus Trends entfernen',
	'Student Engagement on Emotion': 'Studentisches Engagement für Emotionen',
	'No. of emotions': 'Anzahl an Emotionen',
	'Create & Manage Reports': 'Berichte erstellen und verwalten',
	'Create report': 'Erstelle Bericht',
	'Latest reports': 'Neueste Berichte',
	'View More': 'Mehr sehen',
	'Return to Analytics': 'Zurück zur Analyse',
	'Output fields': 'Ausgebe Felder',
	'Target users': 'Zielgruppe',
	'Create Reports': 'Erstelle Berichte',
	'Report format': 'Bericht Format',
	Raw: 'Rohdaten',
	'Select report dates': 'Wähle Berichtsdaten aus',
	'Remember to set up filters to get started!': 'Denke daran, Filter einzurichten, um loszulegen!',
	'Last step': 'Letzter Schritt',
	'Setup report name as your last step': 'Richte im letzten Schritt einen Berichtnamen ein',
	'Report name': 'Berichtnamen',
	'Enter file name': 'Dateinamen eingeben',
	'You will be notified once your report is ready': 'Sie werden benachrichtigt, sobald Ihr Bericht bereit ist.',
	'Report-2024-01-29': 'Report-2024-01-29',
	'Welcome! Create your first report here': 'Willkommen! Erstelle deinen ersten Bericht. ',
	'Your opinion matters to us!': 'Deine Meinung ist uns wichtig!',
	'Your feedback helps us to improve. If you find our app helpful, please consider rating it.':
		'Dein Feedback trägt zur Verbesserung bei. Wenn du unsere App hilfreich findest, bitte bewerte sie.',
	'Share your feedback': 'Teil uns deine Meinung mit. ',
	'Leave your feedback': 'Teil uns deine Meinung mit. ',
	'Type your feedback here': 'Teile dein Feedback hier.',
	'Select time range': 'Zeitraum wählen',
	'Number of student who answered "How are you today?" on this date':
		'Anzahl der Schüler:innen, die an diesem Tag "Wie geht es dir" beantortet haben',
	'From creating confidence to goal setting, you’ll find a great range of skill-based surveys, programs, and journals here.':
		'Von "Selbstvertrauen gewinnen" bis "Ziele erreichen", hier findest du alles was du brauchst: Kurse, Umfragen und Tagebücher',
	'Track trends': 'Trends beobachten',

	'Content has been removed from trends': 'Der Inhalt wurde aus den Trends entfernt',
	'In process': 'In Arbeit',
	'End date:': 'End Datum:',

	'Total response percentage': 'Gesamt Antworten',
	'You are going to generate new password for this student?': 'Möchtest du ein neues Passwort fur diese(n) Schüler:in generieren?',
	'New password has already been generated today for this user! Do you want to generate it again?':
		'Es wurde bereits ein Passwort für diese(n) Schüler:in generiert, möchtest du totzdem ein neues generieren?',
	'New class': 'Neue Klasse',
	'Day review': 'Mein Tag',
	'Seen 6 days ago': 'Vor {{time}} gesehen worden',
	'Left 6 days ago': 'Vor {{time}} Tagen',
	'Thank you for helping us to make Upstrive better.': 'Danke, dass du uns hilfst Upstrive besser zu machen',

	month: 'Monat',
	months: 'Monate',
	hour: 'Stunde',
	hours: 'Stunden',
	minute: 'Minute',
	minutes: 'Minuten',
	seconds: 'Sekunden',
	'No reports have been created yet': 'Es wurden noch keine Berichte erstellt',
	Grade: 'Jahrgang',
	'Feedback date': 'Feedback-Datum',
	"Student's emotion": 'Emotion des Studenten',
	"Student's commnet": 'Comment',
	Reasons: 'Gründe',
	reasons: 'Gründe',
	"Teachers' action": 'Lehreraktion',
	'Number of students that left a comment combined with uncomfortable emotion':
		'Anzahl der Studierenden, die einen Kommentar zusammen mit einem unangenehmen Gefühl hinterlassen haben.',
	'7d': '7t',
	'1m': '1m',
	'3m': '3m',
	'1y': '1j',
	'Percentage of students that submitted an emotion this week':
		'Das ist der Prozentsatz der Schüler, der diese Woche eine Emotion eingereicht haben',
	'Write your personal notes here (only you can see this)': 'Hier kannst du Notizen machen (nur für dich sichtbar)',
	'Thank you!': 'Vielen Dank!',
	'Tell us how we can improve Upstrive in the box below': 'Teile uns im unten stehenden Feld mit, wie wir Upstrive verbessern können',
	'Reminder has been deleted!': 'Erinnerung wurde gelöscht!',
	'Program has been deleted!': 'Das Programm wurde gelöscht!',
	'New password is generated': 'Ein neues Passwort wurde generiert.',
	"Student's feedback": 'Feedback der Studierenden',
	"Student's comment": 'Kommentar des Schülers',
	'You must select at least one option': 'Sie müssen mindestens eine Option auswählen!',

	On: 'On',
	Class: 'Class',
	Classes: 'Classes',
	SOS: 'SOS',
	'Template is updated': 'Template is updated',
	'Options must differ ': 'Options must differ ',
	Open: 'Open',
	'Click to get back to all Tasks': 'Click to get back to all Tasks',
	'Choose the answer': 'Choose the answer',
	'Not selected': 'Not selected',
	Reset: 'Reset',
	'What are 3 things that are a bit challenging at the moment?': 'What are 3 things that are a bit challenging at the moment?',
	'What’s just one thing you will do differently tomorrow?': 'What’s just one thing you will do differently tomorrow?',
	'all students': 'all students',
	'Can`t create student without create class': 'Can`t create student without create class',
	Analytics: 'Analytics',
	'Full Desktop version': 'Full Desktop version',
	'Full Mobile version': 'Full Mobile version',
	Frequency: 'Häufigkeit',
};
export default TRANSLATION_DE;
