import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import Happy from '../../assets/emotions/01-Happy.svg';
function DonutAnalytic({ resultData, width = 180, height = 220 }) {
	const [data, setData] = useState([]);
	const [labels, setLabels] = useState([]);
	const [colors, setColors] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		reorderData();
	}, [resultData]);

	useEffect(() => {
		reorderData();
	}, []);

	const reorderData = () => {
		let newSeries = resultData?.map((item, index) => Number(item.value));
		let newColors = resultData?.map((item) => item.color);
		let newLabels = resultData?.map((item) => item.label);
		setData(newSeries);
		setColors(newColors);
		setLabels(newLabels);
	};
	// log;
	var options = {
		states: {
			active: {
				filter: {
					type: 'none' /* none, lighten, darken */,
				},
			},
			hover: {
				filter: {
					type: 'none',
				},
			},
		},
		series: data,
		chart: {
			width,
			type: 'donut',
		},
		labels: labels,
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						position: 'bottom',
					},
				},
			},
		],
		colors,
		legend: {
			show: false,
		},
		plotOptions: {
			pie: {
				expandOnClick: false,
				donut: {
					size: '20%',
				},
				dataLabels: {
					offset: 20,
				},
			},
		},
		stroke: {
			show: false,
		},
		selection: {
			enabled: false,
		},
		tooltip: {
			followCursor: false,
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				const emotion = labels[seriesIndex];
				const totalValue = series[seriesIndex];
				return `<div class="apexcharts-tooltip-custom">
				<div class="apexchart-custom-${emotion}"></div>
						<div class="apexcharts-tooltip-custom-text">${t(emotion)} ${totalValue}%</div>
					</div>`;
			},
		},
		dataLabels: {
			enabled: true,
			formatter: function (val) {
				return val.toFixed(1) + '%';
			},
			style: {
				fontSize: '14px',
				fontFamily: 'Quicksand',
				colors: ['#413F5E'],
				fontWeight: 700,
			},
			background: {
				enabled: false,
			},
			dropShadow: {
				enabled: false,
			},
		},
	};

	return <Chart type='donut' series={options.series} height={height} options={options} />;
}

export default DonutAnalytic;
