import React, { useState } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/PollRepeatModal.css';
import i18n from 'i18next';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import arLocale from 'date-fns/locale/ar-SA';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as Calendar } from '../../assets/svg/CalendarIcon.svg';
import DayPickModule from './DayPickModule';

function RepeatModal({ showRepeat, closeRepeat, setReturnRepeat }) {
	React.useEffect(() => {
		if (showRepeat) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [showRepeat]);
	const { t, locale } = useTranslation();
	const [type, setType] = useState('weekly');
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [days, setDays] = useState({
		Sun: false,
		Mon: false,
		Tue: false,
		Wed: false,
		Thu: false,
		Fri: false,
		Sat: false,
	});

	const dayIndex = {
		Sun: 0,
		Mon: 1,
		Tue: 2,
		Wed: 3,
		Thu: 4,
		Fri: 5,
		Sat: 6,
	};
	const [selDay, setSelDay] = useState(1);
	const changeDate = (date) => {
		if (date === null && date === undefined) return;
		setStartDate(date);
	};
	const localeMap = {
		ar: arLocale,
		en: enLocale,
		de: deLocale,
	};
	const handleOff = () => {
		setReturnRepeat({
			repeat: false,
			repeatPeriodText: 'Never',
			selectedDay: 0,
			selectedDays: [],
		});
		closeRepeat();
	};
	const handleContinue = () => {
		const tempSelDays = [
			...Object.keys(days)
				.map((day) => {
					if (days[day] == true) return dayIndex[day];
				})
				.filter((day) => day !== undefined),
		];

		if (type === 'monthly') {
			setReturnRepeat({
				repeat: true,
				recurrentType: 'monthly',
				repeatPeriodText: `${selDay} of every month`,
				selectedDay: +selDay,
				selectedDays: [],
				endOfRecurrenceDate: startDate.getTime(),
			});
			closeRepeat();
		} else {
			if (tempSelDays.length > 0) {
				setReturnRepeat({
					repeat: true,
					recurrentType: 'weekly',
					repeatPeriodText: `Every ${Object.keys(days)
						.filter((day) => days[day] == true)
						.join(', ')}`,
					selectedDay: 1,
					endOfRecurrenceDate: startDate.getTime(),
					selectedDays: tempSelDays,
				});
				closeRepeat();
			} else {
				setReturnRepeat({
					repeat: false,
					repeatPeriodText: 'Never',
					selectedDay: 0,
					selectedDays: [],
				});
				closeRepeat();
			}
		}
	};
	const changeType = (type) => {
		return type === 'weekly' ? 'monthly' : 'weekly';
	};

	const range = (start, stop, step = 1) =>
		Array(Math.ceil((stop - start) / step))
			.fill(start)
			.map((x, y) => x + y * step);
	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={showRepeat}
			onAfterOpen={() => {}}
			onRequestClose={closeRepeat}
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					minWidth: 600,
					margin: 'auto',
					marginTop: '2%',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					margin: 'auto',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div className='w-full justify-center flex'>
				<div
					className='hover:bg-darkenPurple pointer absolute top-[10px] flex bg-deepPurple rounded-full w-[60px] h-[60px] justify-center items-center'
					onClick={() => closeRepeat()}
				>
					<CloseModalIcon />
				</div>

				<div className='bg-white rounded-xl mt-10 h-full w-full '>
					<div className='pt-10 pb-5 bg-lightBG rounded-xl'>
						<p className='text-deepPurple font-bold text-[25px] text-center'>{t('repeat')}</p>
					</div>
					<div className='justify-center items-center flex flex-col w-full'>
						<div className='flex justify-between w-[540px] mt-5 text-sm '>
							<div
								className={`flex relative flex-row font-semibold items-center justify-center rounded-full w-[260px] h-[44px] pointer ${
									type === 'weekly'
										? 'bg-deepPurple text-white'
										: 'border-deselectPurple border text-mudPurple bg-whiteContrast'
								}`}
								onClick={() => {
									setType(changeType(type));
									setSelDay(1);
								}}
							>
								{t('weekly')}
								{type === 'weekly' && (
									<div
										className='w-0 h-0 absolute'
										style={{
											borderRight: '10px solid transparent',
											borderLeft: '10px solid transparent',
											borderTop: '10px solid #5E639B',
											top: 44,
										}}
									></div>
								)}
							</div>
							<div
								className={`flex relative flex-row  font-semibold items-center justify-center rounded-full w-[260px] h-[44px] pointer ${
									type === 'weekly'
										? 'border-deselectPurple border bg-whiteContrast text-mudPurple'
										: ' bg-deepPurple text-white'
								}`}
								onClick={() => {
									setType(changeType(type));
									setSelDay(1);
								}}
							>
								{t('monthly')}
								{type === 'monthly' && (
									<div
										className='w-0 h-0 absolute'
										style={{
											borderRight: '10px solid transparent',
											borderLeft: '10px solid transparent',
											borderTop: '10px solid #5E639B',
											top: 44,
										}}
									></div>
								)}
							</div>
						</div>
					</div>

					<div className=' justify-center items-center flex w-full flex-col '>
						{type === 'weekly' && (
							<div className='bg-lightBG rounded-full flex mt-5 items-center justify-start px-4 h-[40px] w-[540px]'>
								<p className='text-deselectPurple font-semibold text-base mr-16'>{t('selectDays')}:</p>
								<div className='flex flex-row items-center '>
									{Object.keys(days).map((day, index) => {
										return (
											<div
												className={`text-deselectPurple mx-1 pointer p-1 text-base font-semibold ${
													days[day] ? 'text-deepPurple bg-white rounded-full ' : ''
												}`}
												onClick={() => setDays(Object.assign({}, days, { [day]: !days[day] }))}
												key={index}
											>
												{day}
											</div>
										);
									})}
								</div>
							</div>
						)}
						{type === 'monthly' && (
							<div className='flex items-center mt-4'>
								<p className='text-base font-semibold text-left text-deselectPurple mt-7 mr-5'>{t('everyMonthOn')}:</p>
								<div className='day-picker flex flex-col items-center justify-center'>
									<DayPickModule setDay={setSelDay} day={selDay} />
								</div>
							</div>
						)}
						<div className='relative bg-lightBG rounded-full flex mt-5 items-center justify-center px-4 h-[40px] w-[540px]'>
							<div className='flex items-center gap-2 pointer not-selectable' onClick={() => setIsDatePickerOpen(true)}>
								<Calendar />
								<p className='text-base text-deselectPurple font-semibold'> {t('startDate')}: </p>
								<p className='text-base text-deepPurple font-semibold'>{startDate.toLocaleDateString()}</p>
							</div>
							<div style={{ position: 'absolute', top: 0, left: 270, visibility: 'hidden' }}>
								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[i18n.language]}>
									<DatePicker
										open={isDatePickerOpen}
										onClose={() => setIsDatePickerOpen(false)}
										label='Date desktop'
										inputFormat='MM/dd/yyyy'
										value={startDate}
										onChange={changeDate}
										renderInput={(params) => <TextField {...params} />}
									/>
								</LocalizationProvider>
							</div>
						</div>
					</div>
					<div className='items-center justify-center flex w-full my-5 gap-3'>
						<div
							className='rounded-full h-[50px] flex items-center pointer justify-center text-center font-semibold w-[140px] text-deepPurple bg-lightBG'
							onClick={handleOff}
						>
							{t('turnOff')}
						</div>
						<div
							className='rounded-full h-[50px] flex items-center pointer justify-center text-center font-semibold w-[140px] text-highlightColor bg-deepPurple'
							onClick={handleContinue}
						>
							{t('continue')}
						</div>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default RepeatModal;
