import React, { useState, useEffect } from 'react';
import '../../styles/modals/RunProgramTimePick.css';
import { ReactComponent as AngleUp } from '../../assets/svg/angle-up.svg';
import { ReactComponent as AngleDown } from '../../assets/svg/angle-down.svg';
function PickTimeHorizontalModule({ hour, minute, setReturnHour, setReturnMinute, name, className = '', horizontal = false }) {
	const checkMinute = (value, topLimit) => {
		if (value < 0) {
			setReturnMinute(55);
		} else {
			if (value > topLimit) {
				setReturnMinute(0);
			} else {
				setReturnMinute(value);
			}
		}
	};
	const checkHour = (value, topLimit) => {
		if (value < 0) {
			setReturnHour(23);
		} else {
			if (value > topLimit) {
				setReturnHour(0);
			} else {
				setReturnHour(value);
			}
		}
	};

	const increaseMM = (step) => {
		let tempMIN = parseInt(minute) + step;
		checkMinute(tempMIN, 55);
	};
	const handleMChange = (e) => {
		let value = parseInt(e.target.value);
		checkMinute(value, 59);
	};

	const increaseHH = (step) => {
		let tempH = parseInt(hour) + step;
		checkHour(tempH, 23);
	};
	const handleHChange = (e) => {
		let value = parseInt(e.target.value);
		checkHour(value, 23);
	};

	return (
		<div className='flex flex-row items-center'>
			<div className='flex flex-row items-center'>
				<button className='-rotate-90 p-[7px] mr-[7px]' onClick={() => increaseHH(-1)}>
					<AngleUp />
				</button>
				<div>
					<input
						type='text'
						onChange={(e) => handleHChange(e)}
						className='bg-lightBG text-deepPurple font-bold text-base rounded-[5px] w-[40px] h-[40px] text-center'
						value={hour.toString().padStart(2, 0)}
					/>
				</div>
				<button className='rotate-90  p-[7px] ml-[7px]' onClick={() => increaseHH(1)}>
					<AngleUp />
				</button>
			</div>
			<td className='font-bold text-[16px] text-[#5E639B]'>:</td>
			<div className='flex flex-row items-center'>
				<button className='-rotate-90 p-[7px] mr-[7px]' onClick={() => increaseMM(-5)}>
					<AngleUp />
				</button>
				<div>
					<input
						type='text'
						onChange={(e) => handleMChange(e)}
						className='bg-lightBG text-deepPurple font-bold text-base rounded-[5px] w-[40px] h-[40px] text-center'
						value={minute.toString().padStart(2, 0)}
					/>
				</div>
				<button className='rotate-90  p-[7px] ml-[7px]' onClick={() => increaseMM(5)}>
					<AngleUp />
				</button>
			</div>
		</div>

		// <div className={`no-text-select ${className}`}>
		// 	<div className='program-picker text-center'>
		// 		<p className='text-sm text-mudPurple'>{name}</p>
		// 		<table className='timepicker'>
		// 			<tbody>
		// 				<tr className='text-center'>
		// 					<td className='increment-up' onClick={() => increaseHH(1)}>
		// 						<p>
		// 							<span>
		// 								<AngleUp />
		// 							</span>
		// 						</p>
		// 					</td>
		// 					<td>&nbsp;</td>
		// 					<td className='increment-up' onClick={() => increaseMM(5)}>
		// 						<p>
		// 							<span>
		// 								<AngleUp />
		// 							</span>
		// 						</p>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td className='program-form-group'>
		// 						<input
		// 							type='text'
		// 							onChange={(e) => handleHChange(e)}
		// 							className='bg-lightBG text-deepPurple font-bold text-base rounded-[5px] w-[40px] h-[40px] text-center'
		// 							value={hour.toString().padStart(2, 0)}
		// 						/>
		// 					</td>
		// 					<td className='uib-separator text-deepPurple'>:</td>
		// 					<td className='program-form-group'>
		// 						<input
		// 							type='text'
		// 							onChange={(e) => handleMChange(e)}
		// 							className='bg-lightBG text-deepPurple font-bold text-base rounded-[5px] w-[40px] h-[40px] text-center'
		// 							value={minute.toString().padStart(2, 0)}
		// 						/>
		// 					</td>
		// 				</tr>
		// 				<tr>
		// 					<td className='increment-down' onClick={() => increaseHH(-1)}>
		// 						<p>
		// 							<span>
		// 								<AngleDown />
		// 							</span>
		// 						</p>
		// 					</td>
		// 					<td>&nbsp;</td>
		// 					<td className='increment-down' onClick={() => increaseMM(-5)}>
		// 						<p>
		// 							<span>
		// 								<AngleDown />
		// 							</span>
		// 						</p>
		// 					</td>
		// 				</tr>
		// 			</tbody>
		// 		</table>
		// 	</div>
		// </div>
	);
}

export default PickTimeHorizontalModule;
