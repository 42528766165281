import React, { useState, useEffect, useRef, useReducer } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { useTranslation } from 'react-i18next';
import '../../styles/modals/StudentInfoModal.css';
import '../../styles/modals/StudentModal.css';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { ReactComponent as Save } from '../../assets/svg/Save.svg';
import { Toaster } from 'react-hot-toast';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { formatDateOnly, format_day, notify } from '../../utils/util';
import { deleteStudentAsync, email_exists, generate_new_password, updateStudent } from '../../server/server';
import { useUser } from '../../auth';
import { localeMap } from '../../constants/Constants';
import TransferToAnotherClassModal from './TransferToAnotherClassModal';
import SelectInput from '../SelectInput';
import { useAppContext } from '../../context/AppContext';
import { env } from '../../constants/Connections';
import ConfirmationModal from './ConfirmationModal';
import { format, isToday } from 'date-fns';

function StudentInfoModal({ studentInfoOpen, closeStudentInfo, student, currentClass, updateClassStudents, reloadStudentsList }) {
	const { t, locale } = useTranslation();
	const { appState } = useAppContext();
	const additionalFields = appState?.additionalFields?.fields ?? [];
	const { register, handleSubmit, watch, formState, reset, setValue, getValues, setError } = useForm();
	const { isDirty, isValid, errors } = formState;
	const { user } = useUser();
	const [additionalFieldsValues, setAdditionalFieldsValue] = useReducer((oldState, newState) => ({ ...oldState, ...newState }), {});
	const [gender, setGender] = useState('Male');
	const [birthDate, setBirthDate] = useState(new Date());
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [showManualPassword, setShowManualPassword] = useState(false);
	const [newPasswordRequired, setNewPasswordRequired] = useState(false);
	const timeout = useRef(null);
	const [emailExists, setEmailExists] = useState(false);
	const [emailValidText, setEmailValidText] = useState('');
	const [transferClassModalOpen, setTransferClassModalOpen] = useState(false);
	const [deleteModalOpen, setDelModalOpen] = useState(false);
	const [confirmDeleteStudentModal, setConfirmDeleteStudentModal] = useState(false);
	const handleChange = (newValue) => setBirthDate(newValue);

	useEffect(() => {
		if (studentInfoOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('managementId').style.filter = 'blur(5px)';
			document.getElementById('sideBar').style.filter = 'blur(5px)';
		} else {
			document.getElementById('managementId').style.filter = 'unset';
			document.getElementById('sideBar').style.filter = 'unset';
		}
	}, [studentInfoOpen]);

	useEffect(() => {
		if (student != {}) {
			setValue('student_upn', student?.student_upn);
			setValue('first_name', student?.first_name);
			setValue('last_name', student?.last_name);
			setValue('email', student?.email);
			setGender(student?.gender);
			setBirthDate(new Date(student?.date_of_birth * 1000));
			setNewPasswordRequired(false);
			setShowManualPassword(false);
			let additionalValues = {};
			additionalFields.forEach((element) => {
				additionalValues[element.code] = student[element.code] ?? '';
			});
			setAdditionalFieldsValue(additionalValues);
			//validate_email(student.email);
		}
	}, [student]);

	const enableSetupManuallyPassword = () => {
		setShowManualPassword(!showManualPassword);
	};

	const save = () => {
		if (emailExists) {
			notify(emailValidText);
			return;
		}
		const formData = getValues();
		student = { ...student, ...additionalFieldsValues };
		student.new_password_required = true;
		if (showManualPassword) {
			if (formData.password === formData.repeat_password) {
				student.password = formData.password;
				student.new_password = true;
				if (newPasswordRequired) student.new_password_required = true;
				else delete student?.new_password_required;
			} else {
				setError('password', { type: 'custom', message: "Passwords don't match" });
				return;
			}
		}
		student.student_upn = formData.student_upn;
		student.first_name = formData.first_name;
		student.last_name = formData.last_name;
		student.email = formData.email.toLowerCase();
		student.gender = gender?.toLocaleLowerCase();
		student.date_of_birth = parseInt(birthDate.getTime() / 1000);

		if (!!student.id) {
			setNewStudent(student);
			return;
		}
		const institution_id = localStorage.getItem('institution_id');
		student.institution_id = institution_id;
		setNewStudent(student);
	};

	const setNewStudent = (newStudent) => {
		if (currentClass.name == '' || currentClass.year == '') notify(t('Can`t create student without create class'));
		// newStudent.class_id = currentClass.id;
		newStudent.classesName = currentClass.name;
		newStudent.classes = [
			{
				id: currentClass.id,
				name: currentClass.name,
				year: currentClass.year,
			},
		];
		newStudent.year = currentClass.year;
		newStudent.role = 0;
		newStudent.validEmail = !dontValidateEmail;
		updateStudentFunction(newStudent);
	};

	const updateStudentFunction = async (stud) => {
		const resp = await updateStudent(stud);
		if (resp.status === 200) {
			currentClass.students.forEach((element, index) => {
				if (element.id === stud.id) {
					currentClass.students[index] = stud;
				}
			});
			reloadStudentsList();
			closeStudentInfo();
			notify(t('Data is saved'));
		} else {
			notify(t('yourDataIsNotUpdated'));
		}
	};

	const validate_email = async (email) => {
		const valid = validEmail(email);
		if (!valid) {
			setEmailValidText(t('emailIsInvalid'));
			setEmailExists(true);
		} else {
			const resp = await email_exists(email);
			if (resp.status === 200) {
				const email_exists = resp.data;
				setEmailValidText(email_exists ? t('emailAlreadyExists') : t('emailIsAvailable'));
				setEmailExists(email_exists);
			}
		}
	};

	function validEmail(e) {
		if (dontValidateEmail) return true;
		var filter = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		return String(e).search(filter) !== -1;
	}
	const handleChangeStudentEmail = (e) => {
		if (timeout !== null) {
			clearTimeout(timeout.current);
		}
		timeout.current = setTimeout(() => {
			validate_email(e.target.value);
		}, 800);
	};

	const closeTransferClassModal = () => {
		setTransferClassModalOpen(false);
	};

	const closeDelModal = () => {
		setDelModalOpen(false);
	};

	const generatePassword = async () => {
		const resp = await generate_new_password(student);
		if (resp.status === 200) {
			student.emailSent = resp.data?.emailSent;
			notify(t('New password is generated'));
			closeDelModal();
		} else {
			notify('Error');
			closeDelModal();
		}
	};

	const closeConfirmDeleteStudentModal = () => {
		setConfirmDeleteStudentModal(false);
	};

	const openConfirmDeleteStudentModal = () => {
		setConfirmDeleteStudentModal(true);
	};

	const removeStudent = async () => {
		if (student !== null || student !== undefined) {
			const resp = await deleteStudentAsync(student);
			if (resp.status === 200) {
				notify(t('studentRemoved'));
				closeConfirmDeleteStudentModal();
				closeStudentInfo();
				reloadStudentsList();
			} else {
			}
		} else {
		}
	};

	const closeInfoModal = () => {
		setTransferClassModalOpen(false);
		closeStudentInfo();
	};

	function isNumber(value) {
		return typeof value === 'number';
	}

	const dontValidateEmail =
		user.institution_id === '62a6c9134d2524bd8331a463' ||
		(user.institution_id === '5f25b9fc7fc9788dacc0ce7d' &&
			((env === 'staging' && parseInt(currentClass?.year) <= 5) || env !== 'staging')) || //Demo school
		(user.institution_id === '625486a1b964d3df1b2b9163' && parseInt(currentClass?.year) <= 5);
	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={studentInfoOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				reset();
				setEmailValidText('');
				closeTransferClassModal();
			}}
			onRequestClose={closeStudentInfo}
			className='noScroll'
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 654,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'scroll',
					paddingTop: 35,
					paddingBottom: 35,
				},
			}}
		>
			<div className='student-info-modal'>
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<div className='close-modal-icon ' onClick={() => closeStudentInfo()}>
						<CloseModalIcon />
					</div>
				</div>

				<div className='student-info-title'>
					<p>{t('studentInfo')} </p>
				</div>
				<div className='w-full flex flex-row items-center justify-between mt-2'>
					<p className='text-deepPurple text-[18px] leading-[23px] font-medium '>
						{t('Class')}: <span className='text-darkenPurple'>{currentClass?.name}</span>
					</p>

					{!!student.id && (
						<button
							className='flex items-center justify-center px-8 rounded-full h-[50px] bg-lightBG'
							onClick={() => setTransferClassModalOpen(true)}
						>
							<p className='text-deepPurple text-[18px] leading-[22px] font-semibold '>{t('Transfer to another class')}</p>
						</button>
					)}
				</div>
				<div className='light-small-scroll mt-8'>
					<form onSubmit={handleSubmit(save)}>
						<div className={`student-info-form`}>
							<div className={`student-input ${errors.student_upn ? 'pink-border' : ''}`} style={{ position: 'relative' }}>
								<label style={{ color: errors.student_upn ? '#FF747F' : '' }}>{t('student')} UPN</label>
								<input
									type='text'
									placeholder={t('enterStudentUPN')}
									autoComplete='off'
									{...register('student_upn', { required: false })}
								/>
								{errors.student_upn && <span className='field-validation-text'>{t('fieldIsRequired')}</span>}
							</div>
							<div className={`student-input ${errors.first_name ? 'pink-border' : ''}`} style={{ position: 'relative' }}>
								<label style={{ color: errors.first_name ? '#FF747F' : '' }}>{t('firstName')} </label>
								<input
									type='text'
									placeholder={t('enterName')}
									autoComplete='off'
									{...register('first_name', { required: true })}
								/>
								{errors.first_name && <span className='field-validation-text'>{t('fieldIsRequired')}</span>}
							</div>
							<div className={`student-input ${errors.last_name ? 'pink-border' : ''}`} style={{ position: 'relative' }}>
								<label style={{ color: errors.last_name ? '#FF747F' : '' }}>{t('lastName')} </label>
								<input
									type='text'
									placeholder={t('enterSurname')}
									autoComplete='off'
									{...register('last_name', { required: true })}
								/>
								{errors.last_name && <span className='field-validation-text'>{t('fieldIsRequired')}</span>}
							</div>
							<div className='student-input ' style={{ position: 'relative' }}>
								<div
									className='student-info-select-button'
									onClick={(e) => {
										e.preventDefault();
										setIsDatePickerOpen(true);
									}}
									style={{ marginTop: 0, zIndex: 3 }}
								>
									<p>{t('dateOfBirth')} </p>
									<span style={{ flex: 1 }}></span>
									<p>{formatDateOnly(birthDate)}</p>
								</div>
								<div style={{ position: 'absolute', top: 0, zIndex: 2, visibility: 'hidden' }}>
									<LocalizationProvider adapterLocale={localeMap[i18n.language]} dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											open={isDatePickerOpen}
											onClose={() => setIsDatePickerOpen(false)}
											label='Date desktop'
											inputFormat='MM/dd/yyyy'
											value={birthDate}
											onChange={handleChange}
											maxDate={new Date()}
											renderInput={(params) => <TextField {...params} />}
										/>
									</LocalizationProvider>
								</div>
							</div>

							<SelectInput
								name={t('gender')}
								array={['male', 'female', 'other']}
								value={t(gender?.toLowerCase())}
								callbackClick={setGender}
								translate
							/>

							{additionalFields.map((field) => {
								return (
									<SelectInput
										name={field?.title}
										array={field?.options ?? []}
										key={field?.code}
										value={additionalFieldsValues[field?.code]}
										callbackClick={(value) => {
											let data = {};
											data[field?.code] = value;
											setAdditionalFieldsValue(data);
										}}
									/>
								);
							})}

							<div
								className={`student-input ${errors.email ? 'pink-border' : ''}`}
								style={{ marginTop: 30, marginBottom: 0, position: 'relative' }}
							>
								<label style={{ color: errors.email ? '#FF747F' : '' }}>{t('email')}</label>
								<input
									type='text'
									placeholder={t('enterEmail')}
									autoComplete='off'
									{...register('email', {
										required: true,
										// pattern: null,
										pattern: dontValidateEmail ? false : /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
									})}
									onChange={(e) => {
										e.preventDefault();
										e.stopPropagation();
										handleChangeStudentEmail(e);
										setValue('email', e.target.value, { shouldValidate: true });
									}}
								/>
								<span className='field-validation-text'>{errors.email ? t('emailIsInvalid') : emailValidText}</span>
							</div>
							{/* {getValues()['email']} */}
						</div>
						{isNumber(student?.emailSent) && (
							<div className='w-full mt-[15px] bg-lightBG rounded-[15px] h-[80px] flex justify-center gap-[51px] items-center '>
								<p className=' text-deepPurple text-lg font-semibold -tracking-[0.36px] '>
									Last email with password was sent:{' '}
								</p>
								<p className=' text-darkenPurple text-lg font-semibold -tracking-[0.36px] '>
									{format(student?.emailSent, 'dd/MM/yyyy HH:mm')}
								</p>
							</div>
						)}
						{showManualPassword && (
							<div className='student-info-form mt-[15px]'>
								<div className={`student-input ${errors.password ? 'pink-border' : ''}`} style={{ position: 'relative' }}>
									<label style={{ color: errors.password ? '#FF747F' : '' }}>{t('password')} </label>
									<input
										type='password'
										placeholder={t('password')}
										autoComplete='off'
										{...register('password', { required: true })}
									/>
									{errors?.password?.type == 'required' && (
										<span className='field-validation-text'>{t('fieldIsRequired')}</span>
									)}
									{!!errors?.password?.message && (
										<span className='field-validation-text'>{t('passwordsDoNotMatch')} </span>
									)}
								</div>

								<div
									className={`student-input ${errors.repeat_password ? 'pink-border' : ''}`}
									style={{ position: 'relative' }}
								>
									<label style={{ color: errors.repeat_password ? '#FF747F' : '' }}>{t('repeatPassword')} </label>
									<input
										type='password'
										placeholder={t('password')}
										autoComplete='off'
										{...register('repeat_password', { required: true })}
									/>

									{errors.repeat_password && <span className='field-validation-text'>{t('fieldIsRequired')}</span>}
								</div>
								<div className='password-settings-switch'>
									<label
										className={`password-setting-switch-item ${
											newPasswordRequired ? 'selected-password-item' : 'password-setting-switch-item-hover'
										}`}
										onClick={() => setNewPasswordRequired(true)}
									>
										{t('letThemSetTheirOwnPasswordsAfterLogin')}
									</label>
									<label
										className={`password-setting-switch-item ${
											!newPasswordRequired ? 'selected-password-item' : 'password-setting-switch-item-hover'
										}`}
										onClick={() => setNewPasswordRequired(false)}
									>
										{t('keepThisPassword')}
									</label>
								</div>
								<div
									className='transparent-button w-full'
									onClick={enableSetupManuallyPassword}
									style={{ fontSize: 18, height: 65, padding: 22 }}
								>
									<p>{t('hidePasswordmanager')} </p>
								</div>
							</div>
						)}

						<div
							style={{
								paddingRight: 5,
								marginTop: 50,
							}}
						>
							{!showManualPassword && (
								<div
									className='transparent-button w-full'
									onClick={enableSetupManuallyPassword}
									style={{ fontSize: 18, height: 65, padding: 22 }}
								>
									<p>{t('setStudentsPasswordManually')} </p>
								</div>
							)}
							{!!student?.id && (
								<div
									className='transparent-button mt-[20px] w-full'
									onClick={() => setDelModalOpen(true)}
									style={{ fontSize: 18, height: 65, padding: 22 }}
								>
									<p>{t('generateNewPassword')} </p>
								</div>
							)}
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', marginTop: !!student?.id ? 20 : 30 }}>
							{!student?.id && (
								<div
									className='transparent-button'
									onClick={closeStudentInfo}
									style={{ fontSize: 18, margin: 'auto', width: 322, height: 65, padding: 22 }}
								>
									<p>{t('closePopUp')} </p>
								</div>
							)}

							{!!student?.id && (
								<div
									className='delete-student-button'
									onClick={() => {
										openConfirmDeleteStudentModal();
									}}
								>
									<p>{t('deleteStudent')} </p>
								</div>
							)}
							<button className='save-submit-button' style={{ margin: 'auto', width: !!student?.id ? 347 : 192 }}>
								<p style={{ marginRight: 10 }}>{t('save')} </p>
								<Save />
							</button>
						</div>
					</form>
				</div>
				<TransferToAnotherClassModal
					transferClassModalOpen={transferClassModalOpen}
					closeTransferClassModal={closeTransferClassModal}
					student={student}
					currentClass={currentClass}
					updateClassStudents={updateClassStudents}
					closeInfoModal={closeInfoModal}
				/>
				<ConfirmationModal
					modalIsOpen={deleteModalOpen}
					closeModal={closeDelModal}
					confirmation={generatePassword}
					message={
						isNumber(student?.emailSent) && isToday(student?.emailSent)
							? t('New password has already been generated today for this user! Do you want to generate it again?')
							: t('You are going to generate new password for this student?')
					}
				/>
				<ConfirmationModal
					modalIsOpen={confirmDeleteStudentModal}
					closeModal={closeConfirmDeleteStudentModal}
					confirmation={removeStudent}
					message={t('doYouWantToDeleteThis')}
				/>
			</div>
		</ReactModal>
	);
}

export default StudentInfoModal;
