import React, { useContext, useState } from 'react';

const InstitutionContext = React.createContext();

const InstitutionProvider = (props) => {
	const [institution, setInstitution] = useState({});

	return <InstitutionContext.Provider value={{ institution, setInstitution }} {...props} />;
};

function useInstitution() {
	const context = useContext(InstitutionContext);

	if (context === undefined) {
		throw new Error(`useInstitution must be used within a InstitutionProvider`);
	}

	return context;
}

export { InstitutionProvider, useInstitution };
