import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DonutChartAureole from './DonutChartAureole';
import { ReactComponent as Save } from '../../assets/svg/Save.svg';
import { ReactComponent as Plus } from '../../assets/svg/Plus.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/DeleteIcon.svg';
import { notify } from '../../utils/util';
import DonutPrimary from './DonutPrimary';
import { useInstitution } from '../../auth/InstitutionContext';
import { create } from '../../server/server';
import Loading from '../Loading';

function CreateNewClass({
	setNewClassFlag,
	currentClass,
	setcurrentClass,
	setDeleteClassModalOpen,
	reloadClasses = () => {},
	openStudent = () => {},
	openStudentInfo = () => {},
}) {
	const { t, locale } = useTranslation();
	const [classText, setClassText] = useState('');
	const [yearText, setYearText] = useState('');
	const [type, setType] = useState('regular');
	const { institution } = useInstitution();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (currentClass !== null) {
			setClassText(currentClass.name);
			setYearText(currentClass.year);
		} else {
			setClassText('');
			setYearText('');
		}
	}, [currentClass]);

	const createNewClass = async () => {
		if (!!currentClass?.id) return;
		if (classText == '' || yearText == '') {
			notify(t('fieldIsRequired'));
			return;
		}
		const institution_id = localStorage.getItem('institution_id');
		const data = {
			name: classText,
			year: yearText,
			students: [],
			institution_id: institution_id,
		};
		if (type === 'primary') data.primary = true;
		createClass(data);
	};
	const createClass = async (data) => {
		setLoading(true);
		const resp = await create({ collection: 'class', data: data });
		if (resp.status === 200) {
			setcurrentClass({ ...resp.data, chart_data: { number_of_users: 0 } });
			notify(t('classIsCreated'));
			setLoading(false);
		} else {
			setLoading(false);
		}
	};
	return (
		<div>
			<div className='mt-[34px] bg-containerGray rounded-[10px] p-[27px] '>
				{!!institution?.allowPrimaryClasses && (
					<div className='flex flex-row items-center '>
						<p className='text-mudPurple text-[18px] leading-6 font-semibold '>{t('Type of class to create')}:</p>
						<div className='flex flex-row rounded-full bg-white ml-6 '>
							<button
								className='flex items-center justify-center px-6 py-4 rounded-full '
								style={{ backgroundColor: type !== 'regular' ? '#ffffff' : '#5E639B' }}
								onClick={() => setType('regular')}
							>
								<p
									className=' text-[16px] leading-5 font-bold '
									style={{ color: type !== 'regular' ? '#9392AD' : '#FDDF6D' }}
								>
									{t('Regular')}
								</p>
							</button>
							<button
								className='flex items-center justify-center px-6 py-4 rounded-full '
								style={{ backgroundColor: type === 'regular' ? '#ffffff' : '#5E639B' }}
								onClick={() => setType('primary')}
							>
								<p
									className=' text-[16px] leading-5 font-bold '
									style={{ color: type === 'regular' ? '#9392AD' : '#FDDF6D' }}
								>
									{t('Primary')}
								</p>
							</button>
						</div>
					</div>
				)}
				<div className='classes-lists-container' style={{ marginTop: 48 }}>
					{type === 'regular' && (
						<div className='management-statistics' style={{ width: 170 }}>
							<div className='management-statistics-items' style={{ marginTop: 0 }}>
								<div className='management-statistics-point'></div>
								<p>
									{0}% {t('registeredStudents')}
								</p>
							</div>
							<div className='management-statistics-items' style={{ marginTop: 0 }}>
								<div className='management-statistics-point' style={{ backgroundColor: '#FFD069' }}></div>
								<p>
									{100}% {t('unregisteredStudents')}
								</p>
							</div>
						</div>
					)}
					{type === 'regular' ? <DonutChartAureole percentage={0.5} /> : <DonutPrimary />}

					<div className='class-data'>
						<p>{type === 'regular' ? t('Class') : t('Primary class')}</p>
						<input
							className='edit-class-input'
							type='text'
							value={classText}
							onChange={(e) => setClassText(e.target.value)}
							autoFocus={true}
							readOnly={!!currentClass?.id ? true : false}
						/>
					</div>
					<div className='class-data'>
						<p>{t('year')}</p>

						<input
							type='text'
							value={yearText}
							className='edit-class-input'
							onChange={(e) => setYearText(e.target.value)}
							readOnly={!!currentClass?.id ? true : false}
						/>
					</div>
					<div className='class-data'>
						<p>{t('numberOfStudents')}</p>
						<p>{!!currentClass ? currentClass.chart_data?.number_of_users : 0}</p>
					</div>
					<div style={{ flex: 1 }}></div>

					{!!currentClass && (
						<button
							className='h-[56px] min-w-[56px] bg-whiteContrast rounded-full cursor-pointer flex items-center justify-center mr-3 border border-[#FC4C59aa] text-[#FC4C59aa] hover:text-[#FC4C59] hover:border-[#FC4C59]'
							onClick={() => setDeleteClassModalOpen()}
						>
							<DeleteIcon />
						</button>
					)}

					<button
						className='h-[56px] min-w-[56px] bg-deepPurple rounded-full cursor-pointer flex items-center justify-center mr-3  '
						onClick={() => createNewClass()}
						disabled={loading}
					>
						{loading ? <Loading /> : <Save style={{ color: '#FDDF6D' }} />}
					</button>
					<button
						className='h-[56px] min-w-[56px] bg-whiteContrast rounded-full cursor-pointer flex items-center justify-center  border  border-[#9392ad99] hover:border-mudPurple text-[#9392ad99] hover:text-mudPurple'
						onClick={() => {
							setNewClassFlag(false);
							if (currentClass !== null) reloadClasses();
						}}
					>
						<Plus />
					</button>
				</div>

				{!!currentClass && (
					<div className='management-lists-container' style={{ marginTop: 49 }}>
						<div className='management-lists-search'>
							<p>{t('listOfStudents')} </p>

							<div
								className='transparent-button'
								style={{ margin: 'auto', width: 630, height: 44, padding: 13 }}
								onClick={() => {
									if (currentClass?.name == '' || currentClass?.year == '') {
										notify(t('cantCreateStudentWithoutCreateClass'));
										return;
									}
									openStudentInfo();
								}}
							>
								{t('addAStudent')}
							</div>
						</div>
						<div>
							<table style={{ width: '100%', borderCollapse: 'collapse' }}>
								<thead>
									{currentClass?.primary !== true && (
										<tr className='teachers-header-row'>
											<th className='text-center' style={{ width: '5%' }}>
												#
											</th>
											<th style={{ width: '23%' }}>{t('name')}</th>
											<th style={{ width: '27%' }}>{t('email')}</th>
											<th style={{ width: '15%', textAlign: 'center' }}>{t('loggedIn')} </th>
											<th className='text-center'>{t('action')}</th>
										</tr>
									)}
									{currentClass?.primary === true && (
										<tr className='teachers-header-row'>
											<th style={{ width: '10%' }}>#</th>
											<th style={{ width: '40%' }}>{t('name')}</th>
											<th style={{ width: '40%' }}>{t('Avatar')}</th>
											<th style={{ width: '10%' }} className=' text-center'>
												{t('action')}
											</th>
										</tr>
									)}
								</thead>
								<tbody>
									{currentClass?.students?.map((student, index) =>
										currentClass.primary !== true ? (
											<tr
												className='teachers-body-row'
												key={student.id}
												onClick={(e) => {
													e.preventDefault();
													openStudent(student);
												}}
											>
												<td className='teacher-text-center' style={{ width: '5%' }}>
													{index + 1}
												</td>
												<td style={{ width: '23%' }}>
													{student.first_name} {student.last_name}{' '}
												</td>
												<td className='teacher-email-text' style={{ width: '27%' }}>
													{student.email}{' '}
												</td>
												<td style={{ width: '15%', textAlign: 'center' }}>
													{student?.loggedIn ? t('loggedIn') : '-'}
												</td>
												<td className='teacher-text-center underline-text'>
													<u>{t('manage')}</u>
												</td>
											</tr>
										) : (
											<tr
												className='teachers-body-row'
												key={student.id}
												onClick={(e) => {
													e.preventDefault();
													openStudent(student);
												}}
											>
												<td style={{ width: '10%' }}>{index + 1}</td>
												<td style={{ width: '40%' }}>
													{student.first_name} {student.last_name}{' '}
												</td>
												<td className='capitalize ' style={{ width: '40%' }}>
													{!!student.animal ? student.animal : t('Not Setuped')}
												</td>

												<td style={{ width: '10%' }} className='text-center underline-text'>
													<u>{t('manage')}</u>
												</td>
											</tr>
										)
									)}
									{currentClass?.students?.length === 0 && (
										<tr className='teachers-body-row-empty'>
											<td colSpan='5' className='teacher-text-center no-teachers-yet'>
												{t('noStudentsYet')}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default CreateNewClass;
