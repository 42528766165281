import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DonutChartLogin from './DonutChartLogin';
import LoginDataModal from '../modals/LoginDataModal';
import { getUsersLoginsInfo } from '../../server/server';
import { useInstitution } from '../../auth/InstitutionContext';
import { useUser } from '../../auth';

function ManagementHeader() {
	const { t, locale } = useTranslation();
	let history = useHistory();
	const { institution } = useInstitution();
	const { user } = useUser();
	const [loginDataOpen, setLoginDataOpen] = useState(false);
	const [studentPercentage, setStudentPercentage] = useState(0);
	const [teacherPercentage, setTeacherPercentage] = useState(0);
	const [unsignedStudents, setUnsignedStudents] = useState([]);
	const [unsignedTeachers, setUnsignedTeachers] = useState([]);
	const [totalStudents, setTotalStudents] = useState(0);
	const [totalTeachers, setTotalTeachers] = useState(0);

	useEffect(() => {
		loadUserLogins();
	}, []);

	const loadUserLogins = async () => {
		const resp = await getUsersLoginsInfo();
		if (resp.status === 200) {
			const signedStudents = resp.data?.students?.logged_in ?? 0;
			const unsignedStudents = resp.data?.students?.unregistered_users ?? 0;
			const totalStudents = signedStudents + unsignedStudents;
			const signedTeachers = resp.data?.teachers?.logged_in ?? 0;
			const unsignedTeachers = resp.data?.teachers?.unregistered_users ?? 0;
			const totalTeachers = signedTeachers + unsignedTeachers;
			setStudentPercentage(totalStudents === 0 ? 0 : signedStudents / totalStudents);
			setTeacherPercentage(totalTeachers === 0 ? 0 : signedTeachers / totalTeachers);
			setTotalTeachers(totalTeachers);
			setTotalStudents(totalStudents);
		}
	};

	const openLoginData = () => {
		setLoginDataOpen(true);
	};

	const closeLoginData = () => {
		setLoginDataOpen(false);
	};

	const openUploadData = () => {
		history.push('/management/upload_user_data');
	};

	const downloadLink = useMemo(() => {
		const name = `${institution?.name}_password.xlsx`?.replaceAll(' ', '_');
		return `https://files.upstrivesystem.com/upload/${name}?token=${user.token}`;
	}, [user, institution]);

	return (
		<div className='management-header xxs:max-w-[95%] xs:max-w-[95%] mx-auto  3xl:max-w-[1480px]'>
			<div className='header-section  flex flex-col justify-center'>
				<p className='header-section-title'>{t('loginStatistic')}</p>
				<p className='header-section-description '>{t('reviewYourLoginStatistics')}</p>
				{user?.allowedToDownloadPasswords && (
					<a href={downloadLink} target='_blank' className='header-section-description underline'>
						Download students credentials
					</a>
				)}
			</div>
			<div
				className='header-section'
				style={{ alignItems: 'center', justifyContent: 'end', display: 'flex', flexDirection: 'row', marginLeft: -35 }}
			>
				<div className='management-statistics'>
					<div className='flex flex-row gap-[8px] items-center'>
						<p className='management-statistics-title'>{t('students')}</p>
						<span className='bg-[#FDDF6D] px-[15px] py-[5px] rounded-full text-[#413F5E] font-semibold text-[16px]'>
							{totalStudents}
						</span>
					</div>
					<div className='management-statistics-items'>
						<div className='management-statistics-point'></div>
						<p>
							{Math.round(studentPercentage * 100)}% {t('Logged in')}
						</p>
					</div>
					<div className='management-statistics-items' style={{ marginTop: 0 }}>
						<div className='management-statistics-point' style={{ backgroundColor: '#FFD069' }}></div>
						<p>
							{100 - Math.round(studentPercentage * 100)}% {t('Not logged in')}
						</p>
					</div>
				</div>
				<div style={{ marginRight: 20 }}>
					<DonutChartLogin percentage={studentPercentage} />
				</div>
			</div>
			<div className='header-section' style={{ alignItems: 'center', justifyContent: 'end', display: 'flex', flexDirection: 'row' }}>
				<div className='management-statistics'>
					<div className='flex flex-row gap-[8px] items-center'>
						<p className='management-statistics-title'>{t('teachers')}</p>
						<span className='bg-[#FDDF6D] px-[15px] py-[5px] rounded-full text-[#413F5E] font-semibold text-[16px]'>
							{totalTeachers}
						</span>
					</div>
					<div className='management-statistics-items'>
						<div className='management-statistics-point'></div>
						<p>
							{Math.round(teacherPercentage * 100)}% {t('Logged in')}
						</p>
					</div>
					<div className='management-statistics-items' style={{ marginTop: 0 }}>
						<div className='management-statistics-point' style={{ backgroundColor: '#FFD069' }}></div>
						<p>
							{100 - Math.round(teacherPercentage * 100)}% {t('Not logged in')}
						</p>
					</div>
				</div>
				<div>
					<DonutChartLogin percentage={teacherPercentage} />
				</div>
			</div>
			<div
				className='ml-[20px] xl:flex-1'
				style={{
					justifyContent: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
					gap: 10,
				}}
			>
				<div className='transparent-button' onClick={openLoginData}>
					{t('Login data')}
				</div>
				<div className='transparent-button text-center' onClick={openUploadData}>
					{t('uploadUserData')}
				</div>
			</div>
			<LoginDataModal
				loginDataOpen={loginDataOpen}
				closeLoginData={closeLoginData}
				unsignedStudents={unsignedStudents}
				unsignedTeachers={unsignedTeachers}
			/>
		</div>
	);
}

export default ManagementHeader;
