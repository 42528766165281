import React, { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function NewPassword(props) {
    const [errorMessage, setErrorMessage] = useState("");
    const { t, locale } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const resp = await props.onSubmit(props.email);
        if (resp.status === 200) {
            props.setAuthSection("token");
        } else {
            const error = resp?.data?.message || resp.data;
            setErrorMessage(error);
        }
    };

    return (
        <div className="new-password-container">
            <div className="modal-title">
                <p className="text-left font-bold  text-3xl text-white">{t("newPassword")}</p>
            </div>
            <div className="text-left text-deselectPurple text-sm mt-2">
                <p>{t("enterYourSchoolEmailAndWeWillSendYouALinkToGetBackIntoYourAccount")}</p>
            </div>
            <div className="sign-up-section">
                <div className="">
                    <form onSubmit={handleSubmit}>
                        <div className="my-5">
                            <span className="text-white text-lg font-semibold text-left flex justify-start">{t("email")}</span>
                            <input
                                className="w-full text-white border border-b-deselectPurple py-3 border-x-0 border-t-0"
                                placeholder={t("email")}
                                style={{ backgroundColor: "transparent" }}
                                value={props.email}
                                // type="email"
                                name="email"
                                onChange={(e) => props.setEmail(e.target.value)}
                            />
                        </div>
                        <div className="error-message">{errorMessage}</div>
                        <div className="login-form-element-holder px-6 py-3">
                            <button className="auth-form-button text-deepPurple">{t("sendRequest")}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
