import React, { useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/FeedBackModal.css';
import '../../styles/modals/StudentFeedbackModal.css';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as DropdownTransparent } from '../../assets/svg/DropdownTransparent.svg';
import { ReactComponent as QuestionIconNew } from '../../assets/svg/QuestionIconNew.svg';
import { ReactComponent as PollIconNew } from '../../assets/svg/PollIconNew.svg';
import { ReactComponent as SearchBlue } from '../../assets/svg/SearchBlue.svg';
import { ReactComponent as History } from '../../assets/svg/History.svg';
import { ReactComponent as Undo } from '../../assets/svg/Undo.svg';
import { emotions_color, emotions_heads, reasonsJson } from '../../constants/Constants';
import { useTranslation } from 'react-i18next';
import { convertDateToFormatddMMyyy, notify } from '../../utils/util';
import { useUser } from '../../auth';
import { format } from 'date-fns';
import { fileSystemUrl } from '../../constants/Connections';
import {
	getArchive,
	getDayArchive,
	makeAssignment,
	getTeachersForAssignment,
	sendSeenFeedbackComment,
	takeActionFeedbackComment,
	getById,
	getSuperiorTeachers,
	sendMessageToStudentFromFeedback,
	sendNotification,
} from '../../server/server';
import InfiniteScroll from 'react-infinite-scroll-component';
import DailyResponse from '../classroom/DailyResponse';
import PollTask from '../PollTask';
import QuestionTask from '../QuestionTask';
import i18n from 'i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { FaAngleLeft, FaCheckSquare, FaHistory, FaSpinner, FaTimes } from 'react-icons/fa';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import BigImageModal from './BigImageModal';
import { Toaster } from 'react-hot-toast';
import Loading from '../Loading';
import { useSocket } from '../../context/socket';

function FeedBackModal({ modalIsOpen, closeModal, student, day }) {
	const { t } = useTranslation();
	const { user } = useUser();
	const { socketStates, socket, changeSocketState } = useSocket();
	const [studentUser, setStudentUser] = useState({});
	const [feedback, setFeedback] = useState({});
	const [feedbackDay, setFeedbackDay] = useState(day);
	const [profileState, setProfileState] = useState('day');
	const [actionTaken, setActionTaken] = useState(false);
	const [sendMessageError, setSendMessageError] = useState(false);
	const [archiveContent, setArchiveContent] = useState([]);
	const [anonymous, setAnonymous] = useState(false);
	const [loadingContent, setLoadingContent] = useState(true);
	const [loadingHistory, setLoadingHistory] = useState(false);
	const [historyList, setHistoryList] = useState([]);
	const [fullHistorySize, setFullHistorySize] = useState(-1);
	const [hasMore, setHasMore] = useState(true);
	const [openAssignAction, setOpenAssignAction] = useState(false);
	const [openActionMessage, setOpenActionMessage] = useState(false);

	const [currentState, setCurrentState] = useState('init');

	const [showDropdown, setShowDropdown] = useState(false);
	const [teachers, setTeachers] = useState([]);
	const [loadedTeachers, setLoadedTeachers] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [message, setMessage] = useState('');
	const [actionMessage, setActionMessage] = useState('');

	const [selectedTeacher, setSelectedTeacher] = useState({});
	const [selectedImage, setSelectedImage] = useState('');
	const [imageModalOpen, setImageModalOpen] = useState(false);
	const [loadingImage, setLoadingImage] = useState(true);
	const [superiorTeachers, setSuperiorTeachers] = useState({});
	const [sendingMessage, setSendingMessage] = useState(false);

	const [resultsNum, setResultsNum] = useState(0);

	useEffect(() => {
		setActionTaken(student.feedback?.actionTakenBy?.map((e) => e.id).includes(user.id));
		setStudentUser(student.user);
		setFeedback({ ...student.feedback, takenActionBy: student.feedback?.actionTakenBy?.map((e) => e.id) || [] });
		console.log('student feedback ', student);
		loadArchiveStudent();
		if (student.feedback?.emotion) sendSeenComment();
	}, [student]);

	useEffect(() => {
		//set teachers with search parameter
		if (searchTerm.length > 0) {
			setTeachers(
				loadedTeachers.filter((teacher) =>
					`${teacher?.first_name} ${teacher?.last_name}`.toLowerCase().includes(searchTerm?.toLowerCase())
				)
			);
		} else setTeachers(loadedTeachers);
	}, [searchTerm]);

	useEffect(() => {
		if (modalIsOpen) {
			loadTeachers();
			loadSuperiorTeachers();
			setProfileState('day');
		}
	}, [modalIsOpen]);

	const loadTeachers = async () => {
		const resp = await getTeachersForAssignment();

		if (resp.status === 200) {
			console.log('load teachers', resp.data);
			setLoadedTeachers(resp?.data);
			setTeachers(resp?.data);
		}
	};

	const loadSuperiorTeachers = async () => {
		const resp = await getSuperiorTeachers(student.user);
		if (resp.status === 200) {
			// console.log('superior teachers ', resp);
			if (resp.data.headOfClass || resp.data.headOfYear) setSuperiorTeachers(resp.data);
			// else
			// 	setSuperiorTeachers({
			// 		headOfClass: { first_name: 'test', last_name: 'tester' },
			// 		headOfYear: { first_name: 'head tester', last_name: 'head laster' },
			// 	});
		}
	};

	const loadFeedbackData = async () => {
		const resp = await getById({ collection: 'feedback', id: student?.feedback?.id });
		if (resp.status === 200) {
			const data = resp.data;
			console.log('feedback ', data);
			//setFeedback(data);
			setFeedback({ ...data, takenActionBy: data?.actionTakenBy?.map((e) => e.id) || [] });
		} else {
			console.log('error');
		}
	};

	const loadArchiveStudent = async () => {
		if (student.user === undefined) return;
		const data = {
			user_id: student.user?.id,
			number_of_archives: 0,
			day: feedbackDay,
		};
		const resp = await getDayArchive(data);
		if (resp.status === 200) {
			const data = resp.data;
			setArchiveContent(data.data);
			console.log('taskovi : ', data.data);
			setLoadingContent(false);
		} else {
			console.log('Error....');
			console.log(resp);
		}
	};
	const sendSeenComment = async () => {
		const data = {
			user_id: user['id'],
			feedback_id: student.feedback?.id,
		};
		const resp = await sendSeenFeedbackComment(data);
		if (resp.status === 200) {
			const data = resp.data;
			setActionTaken(!!data.actionTakenBy?.map((e) => e.id).includes(user.id));
			setFeedback({ ...data, takenActionBy: data.actionTakenBy?.map((e) => e.id) || [] });
		} else {
			console.log('Send Seen error: ', resp);
		}
	};

	const openHistory = () => {
		setProfileState('history');
		loadStudentHistory();
	};
	const closeHistory = () => {
		setHistoryList([]);
		setFullHistorySize(-1);
		setProfileState('day');
	};

	const loadStudentHistory = async () => {
		const data = {
			user_id: studentUser['id'],
			number_of_archives: historyList.length,
			collection: 'answers',
		};
		const resp = await getArchive(data);
		if (resp.status === 200) {
			const respData = resp.data;
			setFullHistorySize(respData.size);

			if (historyList.length === 0) {
				setLoadingHistory(true);
				// if (data.length === 0) {
				// 	setIsEmpty(true);
				// }
				setHistoryList(respData.data);
			} else {
				setHistoryList((prev) => [...prev, ...respData.data]);
			}
			setHasMore(respData.size > historyList.length);
		}
		setLoadingHistory(false);
	};
	const loadNextHistory = () => {
		if (historyList.length < fullHistorySize) loadStudentHistory();
		else {
			setHasMore(false);
		}
	};

	const pressAssignAction = async () => {
		setCurrentState(currentState === 'assignAction' ? 'init' : 'assignAction');
		setShowDropdown(false);
		setSelectedTeacher({});
		setMessage('');
	};

	const pressSendMessageButton = () => {
		setCurrentState(currentState === 'sendPrivateMessage' ? 'init' : 'sendPrivateMessage');
	};
	const pressTakeAction = async () => {
		if (!actionTaken) {
			setCurrentState(currentState === 'takingAction' ? 'init' : 'takingAction');
			setOpenAssignAction(false);
			setActionMessage('');
			setOpenActionMessage(!openActionMessage);
		} else {
			// takeAction();
		}
	};

	const messageSent = () => {
		setActionTaken(true);
	};

	const takeAction = async (message = '') => {
		if (message === '') {
			setSendMessageError(true);
			return;
		}
		const data = {
			user_id: user['id'],
			feedback_id: feedback['id'],
			user_message: message,
		};
		const resp = await takeActionFeedbackComment(data);
		if (resp.status === 200) {
			const data = resp.data;
			setActionTaken(!!data.actionTakenBy?.map((e) => e.id).includes(user.id));
			setFeedback({ ...data, takenActionBy: data.actionTakenBy?.map((e) => e.id) || [] });
		}
		setCurrentState('init');
	};

	const assignAction = async () => {
		if (selectedTeacher.first_name === undefined) {
			notify(t('Select teacher'));
			return;
		}
		if (message === '') {
			notify(t('youMustFillData'));
			return;
		}
		let data = {
			teacher: selectedTeacher,
			message,
		};
		if (!!feedback?.id) data['feedback_id'] = feedback?.id;
		else data['student_id'] = studentUser?.id;
		const resp = await makeAssignment(data);
		if (resp.status === 200) {
			const data = resp.data;
			notify(t('actionHasBeenAssignedToTeacher'));
			socket?.emit('make_action_assignment', data);
			loadFeedbackData();
			sendNotification({ data: { assignedTeacher: selectedTeacher, type: 'assignment' } });
			if (!feedback.id) closeModal();
		} else {
			notify(t('assignActionFailed'));
		}
		setCurrentState('init');
	};

	const openBigImage = (url) => {
		setSelectedImage(url);
		setImageModalOpen(true);
	};
	const closeImageModal = () => {
		setImageModalOpen(false);
		setSelectedImage('');
	};
	const handleImageLoading = () => {
		setLoadingImage(false);
	};

	React.useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);

	return (
		<ReactModal
			ariaHideApp={false}
			transparent
			closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				setHistoryList([]);
				setCurrentState('init');
			}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 22,
				},
				content: {
					padding: 0,
					margin: 'auto',
					paddingTop: 20,
					opacity: 1,
					inset: 0,
					width: 600,
					backgroundColor: 'transparent',
					maxHeight: '100vh',
					height: '100vh',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					overflow: 'hidden',
					position: 'relative',
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 20,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className={`feedback-modal-icon ${profileState == 'history' && 'bg-darkPurple '}`} onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
			</div>
			{profileState === 'history' && (
				<div className='feedback-student-modal'>
					<div
						className='feedback-modal-header'
						style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', background: '#5e639b' }}
					>
						<div className='history-back-button' onClick={() => closeHistory()}>
							<Undo style={{ color: '#BEBECE' }} />
						</div>
						<div className='history-header-title'>
							<p>
								{student.user?.first_name} {student.user?.last_name}
							</p>
							<p>{t('User activity')}</p>
						</div>
						<div className='history-back-button' style={{ opacity: 0, cursor: 'default' }}>
							<Undo style={{ color: '#BEBECE' }} />
						</div>
					</div>
					<div className='histrory-list-container'>
						<div id='scrollableDiv' className='history-list-scroll light-small-scroll'>
							<div style={{ paddingRight: 15 }}>
								<InfiniteScroll
									dataLength={historyList.length}
									next={loadNextHistory}
									hasMore={hasMore}
									scrollableTarget='scrollableDiv'
								>
									{historyList.map(
										(item, index) =>
											(item.content.type === 'daily' && <DailyResponse item={item} key={index} />) ||
											(item.content.type === 'poll' && <PollTask task={item} key={index} />) ||
											(item.content.type === 'question' && <QuestionTask task={item} key={index} />)
									)}
								</InfiniteScroll>
							</div>
						</div>
					</div>
				</div>
			)}
			{profileState === 'day' && (
				<div className='feedback-student-modal' style={{ overflow: 'visible', maxHeight: '78vh' }}>
					<div className='feedback-modal-header' style={{ background: `${emotions_color[student.feedback?.emotion]}85` }}>
						<div className='flex flex-row items-center'>
							<div>
								<img src={emotions_heads[student.feedback?.emotion]} width={70} height={70} alt='emotion' />
							</div>
							<div className='feedback-header-title'>
								<p>
									{student.user?.first_name} {student.user?.last_name}
								</p>
								<span>{convertDateToFormatddMMyyy(feedbackDay)}</span>
							</div>
							<div style={{ flex: 1 }}></div>
							<div className='feedback-history-button' onClick={() => openHistory()}>
								<History />
							</div>
						</div>
						{!!student?.feedback?.reasons && (
							<div className='mt-[11px] -mb-[6px] flex flex-row items-center'>
								<p className='text-[#5E639B] font-bold text-[18px] mr-[10px]'>{t('mood')}:</p>
								<div className='flex flex-row gap-[15px] items-center'>
									{student?.feedback?.reasons?.map((reason, index) => (
										// <div className='flex flex-row items-center gap-[10px]'>
										<div key={index}>
											{reasonsJson[reason.toLowerCase()]}
											<span className='text-[#5E639B] uppercase font-semibold text-[16px] ml-[5px]'>{t(reason)}</span>
										</div>
										// </div>
									))}
								</div>
							</div>
						)}
					</div>
					<div className='pb-5 bg-[#fafaff] rounded-[10px]'>
						<div className='feedback-modal-body'>
							<div className='feedback-modal-action-sector mb-5'>
								{!!student.feedback?.emotion && !student?.feedback?.primary && (
									<div
										className='feedback-modal-buttons'
										style={{ backgroundColor: currentState === 'sendPrivateMessage' ? '#5E639B' : 'transparent' }}
										onClick={pressSendMessageButton}
									>
										<p
											style={{
												textAlign: 'center',
												color: currentState === 'sendPrivateMessage' ? '#FDDF6D' : '#5E639B',
											}}
										>
											{t('sendMessage')}
										</p>
										{currentState === 'sendPrivateMessage' && <div className='selected-button-arrow-down'></div>}
									</div>
								)}
								<div style={{ flex: 1 }}></div>

								<div
									className='feedback-modal-buttons'
									style={{
										backgroundColor: currentState === 'assignAction' ? '#5E639B' : 'transparent',
										marginRight: 10,
									}}
									onClick={pressAssignAction}
								>
									<p style={{ textAlign: 'center', color: currentState === 'assignAction' ? '#FDDF6D' : '#5E639B' }}>
										{t('assignAction')}
									</p>
									{currentState === 'assignAction' && <div className='selected-button-arrow-down'></div>}
								</div>
								{!!student.feedback?.emotion && (
									<div
										className='feedback-modal-buttons'
										style={{
											backgroundColor: currentState === 'takingAction' ? '#5E639B' : 'transparent',
											cursor: actionTaken ? 'default' : 'pointer',
										}}
										onClick={pressTakeAction}
									>
										<p style={{ textAlign: 'center', color: currentState === 'takingAction' ? '#FDDF6D' : '#5E639B' }}>
											{actionTaken ? t('actionTaken') : t('iWillTakeAction')}
										</p>
										{currentState === 'takingAction' && <div className='selected-button-arrow-down'></div>}
									</div>
								)}
							</div>
							{currentState === 'init' && (
								<div className=' '>
									<div className='feedback-modal-messege-body'>
										{feedback.message && (
											<>
												<p>{t('comment')}</p>
												<span>{feedback.message}</span>
												<div className='feedback-spacer' style={{ width: '100%' }}></div>
											</>
										)}
										<div className='feedback-seenby-row'>
											<p>{t('seenBy')}: </p>
											<div className='student-feedback-seen-list'>
												{feedback.seenBy?.map((item, index) => (
													<div key={index} className='student-feedback-seen-list-item'>
														<p style={{ marginLeft: 5 }}>
															{item.first_name} {item.last_name}
														</p>
														{feedback.takenActionBy?.includes(item.id) && (
															<FaCheckSquare style={{ margin: 0, marginLeft: 2 }} />
														)}
														{index < feedback.seenBy?.length - 1 && <p>,</p>}
													</div>
												))}
											</div>
										</div>
									</div>

									{feedback.assignments?.map((assignment, index) => {
										return (
											<div className='feedback-modal-assignment-body mt-2'>
												<div className='w-full flex flex-row items-center justify-between '>
													<p className='feedback-modal-assignment-body-text '>{t('assignedBy')}: </p>
													<span className='feedback-modal-assignment-body-span'>
														{assignment.assignedBy.first_name} {assignment.assignedBy.last_name}
													</span>
												</div>
												<div className='feedback-spacer' style={{ width: '100%', marginBottom: 0 }}></div>

												<div className='w-full mt-[10px]'>
													<div className='w-full flex flex-row items-center justify-between '>
														<p className='feedback-modal-assignment-body-text text-deepPurple'>
															{t('assignedTo')}
														</p>
														<span className='feedback-modal-assignment-body-span text-deepPurple'>
															{assignment.assignedTo?.first_name} {assignment.assignedTo?.last_name}
														</span>
													</div>
													<div key={index} className='teacher-assignment-box'>
														<p className='teacher-assignment-comment'>{assignment.actionMessage}</p>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							)}
							{!loadingContent && currentState === 'init' && archiveContent.length === 0 && (
								<div className='feedback-empty-archive-content'>
									<p>{t('thereAreNoAnswersForThatDay')}</p>
								</div>
							)}
							{!loadingContent && currentState === 'init' && archiveContent.length !== 0 && (
								<div className='flex flex-col mt-5'>
									<p className=' text-[16px] font-bold text-deepPurple'>{t('tasks')}:</p>
									{archiveContent?.map((archive, index) => {
										const time = format(new Date(archive.created_date), 'HH:mm');
										const url = `${fileSystemUrl}/${archive?.content?.files?.storage_name}?token=${user.token}`;
										const imgUrl = archive.content?.files ? url : false;
										return archive.content?.type === 'question' ? (
											<div
												className='w-full p-5 my-2 flex flex-col gap-[10px] bg-containerGray rounded-[10px] border border-lightPurpleGray'
												//onClick={() => changeSelectedMode('questionMode', task)}
												key={index}
											>
												<div className='w-full flex flex-row gap-[10px] '>
													{imgUrl != '' && (
														<div className='image-container-new' onClick={() => openBigImage(imgUrl)}>
															{loadingImage && (
																<SkeletonTheme baseColor='#dadaff' highlightColor='#efefef'>
																	<Skeleton />
																</SkeletonTheme>
															)}
															<img
																src={`${url}${url.includes('https') ? '&width=450' : ''}`}
																alt='postfeed'
																width={40}
																onLoad={handleImageLoading}
															/>
														</div>
													)}
													<div className='w-full'>
														<div className='flex flex-row items-center '>
															<QuestionIconNew />
															<p className='ml-[5px] text-[14px]  text-mudPurple'>{t('open_question')}</p>
															<div className='flex-1'></div>
															<div className='text-[14px]  text-mudPurple opacity-60 mr-[5px]'>
																{t('time')}:{' '}
															</div>
															<div className='text-[14px]  text-mudPurple'>{time}</div>
														</div>
														<div className='mt-[10px] text-[16px] font-bold text-darkPurple'>
															{archive.content?.title}
														</div>
													</div>
												</div>
												<div className='mt-[15px]'>
													{archive.answers?.map((option, index) => {
														return (
															<div
																className={`mb-[6px] rounded-[10px] flex items-center min-h-[40px] py-[10px] px-[20px] bg-lightBG`}
																key={index}
															>
																<p className={`text-[16px] font-medium  text-darkenPurple`}>{option}</p>
															</div>
														);
													})}
												</div>
											</div>
										) : archive.content?.type === 'poll' ? (
											<div
												className='w-full p-5 my-2 flex flex-col gap-[10px] bg-containerGray rounded-[10px] border border-lightPurpleGray'
												//onClick={() => changeSelectedMode('pollMode', task)}
											>
												<div className='w-full flex flex-row gap-[10px] '>
													{imgUrl != '' && (
														<div className='image-container-new' onClick={() => openBigImage(imgUrl)}>
															{loadingImage && (
																<SkeletonTheme baseColor='#dadaff' highlightColor='#efefef'>
																	<Skeleton />
																</SkeletonTheme>
															)}
															<img
																src={`${url}${url.includes('https') ? '&width=450' : ''}`}
																alt='postfeed'
																width={40}
																onLoad={handleImageLoading}
															/>
														</div>
													)}
													<div className='w-full'>
														<div className='flex flex-row items-center'>
															<PollIconNew />
															<p className='ml-[5px] text-[14px]  text-mudPurple'>{t('open_poll')}</p>
															<div className='flex-1'></div>
															<div className='text-[14px]  text-mudPurple opacity-60 mr-[5px]'>
																{t('time')}:{' '}
															</div>
															<div className='text-[14px]  text-mudPurple'>{time}</div>
														</div>
														<div className='mt-[10px] text-[16px] font-bold text-darkPurple'>
															{archive.content?.title}
														</div>
													</div>
												</div>
												<div className='mt-[15px]'>
													{archive.answers?.map((option, index) => {
														return (
															<div
																className={`mb-[6px] rounded-[10px] flex items-center h-[40px] pl-[20px] bg-deepPurple`}
																key={index}
															>
																<p className={`text-[16px] font-medium  text-highlightColor`}>{option}</p>
															</div>
														);
													})}
												</div>
											</div>
										) : (
											<></>
										);
									})}
								</div>
							)}
							{currentState === 'assignAction' && (
								<div className='assign-action-container overflow-visible '>
									<div className='w-full flex flex-row items-center justify-between'>
										<p className='text-[18px] leading-[22px] font-semibold text-deepPurple'>{t('assignedTo')}</p>
										<p className='text-[14px] leading-5 font-medium text-deepPurple'>
											{selectedTeacher.first_name != undefined
												? `${selectedTeacher.first_name} ${selectedTeacher.last_name}`
												: ''}
										</p>
									</div>
									<div
										className='flex flex-col max-h-[calc(78vh-180px)] overflow-auto no-scroll mb-[10px] z-50 relative  w-full mt-4 rounded-b-3xl'
										onMouseLeave={(e) => {
											e.preventDefault();
											//setShowDropdown(false);
										}}
									>
										<div
											className={`w-full min-h-[55px] ${
												showDropdown ? 'bg-deepPurple border-deepPurple' : 'bg-lightBG border-lightBG'
											} border hover:border-deepPurple rounded-[54px] flex flex-row items-center justify-center z-[3] cursor-pointer overflow-visible`}
											onClick={(e) => {
												e.preventDefault();
												if (searchTerm != '') setSearchTerm('');
												setShowDropdown(!showDropdown);
											}}
										>
											<p
												className={`text-[18px] leading-[22px] font-semibold ${
													showDropdown
														? 'text-white'
														: selectedTeacher.first_name != undefined
														? 'text-darkenPurple'
														: 'text-deepPurple'
												} `}
											>
												{selectedTeacher.first_name != undefined
													? `${selectedTeacher.first_name} ${selectedTeacher.last_name}`
													: t('selectTeacher')}
											</p>

											<div style={{ transform: showDropdown ? 'scaleY(-1)' : 'none', marginLeft: 10 }}>
												<DropdownTransparent style={{ color: showDropdown ? 'white' : '#5E639B' }} />
											</div>
										</div>
										{showDropdown && (
											<div className='-mt-[55px] flex flex-col items-center w-full bg-lightBG rounded-[28px] z-[2]'>
												<div className='w-full mt-[55px]'>
													<div
														className='feedback-teachers-list'
														style={{ paddingRight: 13, paddingLeft: 12, width: '100%' }}
													>
														<div className='feedback-management-search-container '>
															<input
																type='text'
																value={searchTerm}
																onChange={(e) => setSearchTerm(e.target.value)}
																autoComplete='off'
																placeholder={t('search')}
															/>
															<SearchBlue />
														</div>

														{superiorTeachers.headOfClass !== undefined && (
															<div
																onClick={() => {
																	setSelectedTeacher(superiorTeachers.headOfClass);
																	setShowDropdown(false);
																	setSearchTerm('');
																}}
																className='w-full min-h-[50px] bg-white rounded-[5px] flex flex-row items-center justify-between mb-2 px-5 cursor-pointer border border-highlightColor hover:bg-highlightColor'
															>
																<p className='text-[16px] leading-5 font-bold text-darkenPurple'>
																	{superiorTeachers?.headOfClass?.first_name}{' '}
																	{superiorTeachers?.headOfClass?.last_name}
																</p>
																<p className='text-[14px] leading-5 font-medium text-deepPurple'>
																	{t('classroomTeacher')}
																</p>
															</div>
														)}
														{superiorTeachers.headOfYear !== undefined && (
															<div
																onClick={() => {
																	setSelectedTeacher(superiorTeachers.headOfYear);
																	setShowDropdown(false);
																	setSearchTerm('');
																}}
																className='w-full min-h-[50px] bg-white rounded-[5px] flex flex-row items-center justify-between mb-2 px-5 cursor-pointer border border-highlightColor hover:bg-highlightColor'
															>
																<p className='text-[16px] leading-5 font-bold text-darkenPurple'>
																	{superiorTeachers?.headOfYear?.first_name}{' '}
																	{superiorTeachers?.headOfYear?.last_name}
																</p>
																<p className='text-[14px] leading-5 font-medium text-deepPurple'>
																	{t('headOfYear')}
																</p>
															</div>
														)}
														{(superiorTeachers.headOfYear !== undefined ||
															superiorTeachers.headOfClass !== undefined) && (
															<p className='text-[14px] leading-5 font-medium text-deepPurple my-[15px] text-center'>
																{t('otherTeachers')}
															</p>
														)}

														{teachers?.map((teacher, index) => (
															<div
																onClick={() => {
																	setSelectedTeacher(teacher);
																	setShowDropdown(false);
																	setSearchTerm('');
																}}
																key={index}
																className='w-full min-h-[50px] bg-white rounded-[5px] flex flex-row items-center justify-between mb-2 px-5 cursor-pointer'
															>
																<p className='text-[16px] leading-5 font-bold text-darkenPurple'>
																	{teacher?.first_name} {teacher?.last_name}
																</p>
																<p className='text-[14px] leading-5 font-medium text-deepPurple'>
																	{teacher?.role_coverage?.target_text}
																</p>
															</div>
														))}
													</div>
												</div>
											</div>
										)}
									</div>
									{!showDropdown && (
										<div className='feedback-assigned-multiline' style={{ marginTop: 20 }}>
											<div className='feedback-textarea'>
												<TextareaAutosize
													placeholder={t('typeAnAction')}
													minRows={4}
													value={message}
													style={{ resize: 'none' }}
													autoFocus
													onChange={(e) => {
														e.preventDefault();
														setMessage(e.target.value);
													}}
												/>
											</div>
											<div className='textarea-button' onClick={() => assignAction()}>
												<p>{t('submit')}</p>
											</div>
										</div>
									)}
								</div>
							)}
							{currentState === 'takingAction' && (
								<div className='feedback-assigned-multiline' style={{ marginTop: 40 }}>
									<p className='feedback-take-action-title'>{t('describeHowYouWillDealWithThisStudent')}</p>
									<div className='feedback-textarea'>
										<TextareaAutosize
											placeholder={t('Write your personal notes here (only you can see this)')}
											minRows={4}
											value={actionMessage}
											style={{ resize: 'none' }}
											autoFocus
											onChange={(e) => {
												e.preventDefault();
												setSendMessageError(false);
												setActionMessage(e.target.value);
											}}
										/>
									</div>
									<p className='send-message-error' style={{ opacity: sendMessageError ? 1 : 0 }}>
										{t('youMustFillData')}
									</p>
									<div
										className='textarea-button'
										onClick={() => {
											takeAction(actionMessage);
										}}
									>
										<p>{t('submit')}</p>
									</div>
								</div>
							)}
							{currentState === 'sendPrivateMessage' && (
								<MessageContainer setCurrentState={setCurrentState} feedback={feedback} messageSent={messageSent} />
							)}
						</div>
					</div>
				</div>
			)}
			<BigImageModal modalIsOpen={imageModalOpen} closeModal={closeImageModal} selectedImage={selectedImage} />
			<Toaster position='top-right' reverseOrder={false} toastOptions={{ duration: 3000 }} />
		</ReactModal>
	);
}

const MessageContainer = ({ feedback, setCurrentState = () => {}, messageSent = () => {} }) => {
	console.log(feedback);
	const { t } = useTranslation();
	const [privateMessage, setPrivateMessage] = useState('');
	const [sendMessageError, setSendMessageError] = useState(false);
	const [sendingMessage, setSendingMessage] = useState(false);

	const sendPrivateMessageToStudent = async () => {
		if (privateMessage === '') {
			setSendMessageError(true);
			return;
		}
		setSendingMessage(true);
		const data = {
			feedback_id: feedback['id'],
			message_body: privateMessage,
			created_date: Date.now(),
			...feedback,
		};
		const resp = await sendMessageToStudentFromFeedback(data);
		if (resp.status === 200) {
			notify(t('messageHasBeenSent'));
			setCurrentState('init');
			setSendingMessage(false);
			messageSent(true);
		}
	};
	return (
		<div className='feedback-assigned-multiline' style={{ marginTop: 40 }}>
			<p className='feedback-take-action-title'>{t('sendPrivateMessageToStudent')} </p>
			<div className='feedback-textarea' style={{ borderColor: sendMessageError ? '#FF747F' : '#bebece' }}>
				<TextareaAutosize
					placeholder={t('Write personal message for the student here')}
					minRows={4}
					value={privateMessage}
					style={{ resize: 'none' }}
					autoFocus
					onChange={(e) => {
						e.preventDefault();
						setSendMessageError(false);
						setPrivateMessage(e.target.value);
					}}
				/>
			</div>
			<p className='send-message-error' style={{ opacity: sendMessageError ? 1 : 0 }}>
				{t('youMustFillData')}
			</p>
			<div className='textarea-button' onClick={sendPrivateMessageToStudent}>
				{sendingMessage ? <Loading /> : <p>{t('submit')}</p>}
			</div>
		</div>
	);
};

export default FeedBackModal;
