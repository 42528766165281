import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import ContentJournalResults from './ContentResults/ContentJournalResults';

import ContentPollResults from './ContentResults/ContentPollResults';
import ContentSurveyResults from './ContentResults/ContentSurveyResults';

function ShowContentResultsModal({ showRun, closeRun, content, setRefresh, deleteContent }) {
	const { t, locale } = useTranslation();
	React.useEffect(() => {
		if (showRun) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [showRun]);

	const mapType = {
		poll: <ContentPollResults content={content} setRefresh={setRefresh} deleteContent={deleteContent} />,
		question: <ContentPollResults content={content} setRefresh={setRefresh} deleteContent={deleteContent} />,
		survey: <ContentSurveyResults content={content} setRefresh={setRefresh} deleteContent={deleteContent} />,
		journal: <ContentJournalResults content={content} setRefresh={setRefresh} deleteContent={deleteContent} />,
	};

	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={showRun}
			onAfterOpen={() => {}}
			onRequestClose={closeRun}
			style={{
				overlay: {
					zIndex: 11,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 1000,
					minWidth: 1000,
					margin: 'auto',
					// marginTop: '2%',
					backgroundColor: 'transparent',
					border: 'none',
					margin: 'auto',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div>
				<div className='w-full justify-center flex'>
					<div
						className='hover:bg-darkenPurple pointer absolute top-[10px] flex bg-deepPurple rounded-full w-[60px] h-[60px] justify-center items-center'
						onClick={closeRun}
					>
						<CloseModalIcon />
					</div>
				</div>
				<div className='bg-white rounded-xl mt-10 mr-6 h-full '>{mapType[content?.type]}</div>
			</div>
		</ReactModal>
	);
}

export default ShowContentResultsModal;
