import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { useTranslation } from 'react-i18next';

function VideoModal({ video, index, modalIsOpen, closeModal }) {
	const { t, locale } = useTranslation();
	const videos = [];

	useEffect(() => {
		ReactModal.setAppElement('#home');
	}, []);
	return (
		<ReactModal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			style={{
				overlay: {
					// backgroundColor: '#00000065',
					zIndex: 10,
				},
				content: {
					width: 900,
					minHeight: 630,
					padding: 0,
					opacity: 1,
					minWidth: 900,
					backgroundColor: 'transparent',
					border: 'none',
					boxShadow: 'none',
					// overflow: 'hidden',
					position: 'absolute',
					left: '50%',
					marginLeft: '-450px',
					top: '50%',
					marginTop: '-360px',
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: 0,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					zIndex: 12,
				}}
			>
				<div className='logout-modal-icon' onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
			</div>
			<div
				style={{
					width: 900,
					minHeight: 600,
					padding: 0,
					opacity: 1,
					minWidth: 900,
					backgroundColor: '#FAFAFF',
					borderRadius: 10,
				}}
			>
				<div className='video-widget-header video-play-card bg-[#ECEBF8]'>
					<p>{video.title}</p>
				</div>
				<div style={{ padding: 40 }}>
					<video controls controlsList='nodownload' style={{ width: '100%', height: '100%' }} autoPlay>
						<source src={video.src} />
						{t('yourBrowserDoesNotSupportHTMLVideo')}
					</video>
				</div>
			</div>
		</ReactModal>
	);
}

export default VideoModal;
