import React, { useContext, useEffect, useState } from 'react';
import '../../styles/management/UploadData.css';
import { useUser } from '../../auth';
import { fileSystemUrl } from '../../constants/Connections';
import { getUploadsHistory, removeUserDataFile, uploadUserData } from '../../server/server';
import { formatDateOnly, notify } from '../../utils/util';
import { ReactComponent as PreviousIcon } from '../../assets/svg/PreviousIcon.svg';
import { ReactComponent as TrashIcon } from '../../assets/svg/TrashIcon.svg';
import { ReactComponent as UploadDataIcon } from '../../assets/svg/UploadDataIcon.svg';
import { ReactComponent as UploadMascot } from '../../assets/svg/UploadMascot.svg';
import { ReactComponent as ExitCross } from '../../assets/svg/ExitCross.svg';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';
import { FaTrash, FaTrashAlt } from 'react-icons/fa';
import ConfirmationModal from '../modals/ConfirmationModal';

export default function UploadUserDataScreen() {
	const navigate = useHistory();
	const { t } = useTranslation();
	const { user } = useUser();
	const [uploads, setUploads] = useState([]);
	const [loading, setLoading] = useState(true);
	const [deleteModalOpen, setDeleteModalOpened] = useState(false);
	const [deletingFile, setDeletingFile] = useState({});
	const [uploading, setUploading] = useState(false);
	const [errorText, setFileError] = useState('');
	const [image, setImage] = useState(undefined);
	const [selectedFile, setSelectedFile] = useState(undefined);

	const token = user?.token ?? '';

	useEffect(() => {
		getUploads();
	}, []);

	const getUploads = async () => {
		const resp = await getUploadsHistory();
		if (resp.status == 200) {
			setLoading(false);
			setUploads(resp?.data?.reverse());
		}
	};

	const goBack = () => {
		navigate.goBack();
	};

	const pressUploadPicture = () => {
		if (!!selectedFile) return;
		document.getElementById('photo_upload_modal')?.click();
	};

	const closeImage = () => {
		setImage(undefined);
		setSelectedFile(undefined);
		setUploading(false);
	};
	const onImageChange = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		setFileError('');
		let file = e.target.files[0];
		if (file.size > 15728640) {
			alert(t('fileIsToBig'));
			setSelectedFile(undefined);
			return;
		}
		setSelectedFile(file);
	};
	const resetInputValue = (e) => {
		e.target.value = null;
	};

	const uploadFile = async () => {
		if (!selectedFile) {
			setFileError('Please select file');
			return;
		}
		setUploading(true);
		const resp = await uploadUserData(selectedFile, user.institution_id);
		if (resp.status === 200) {
			notify(t('fileHasBeenUploadedDataWillBeUpdatedWithin24Hours'));
			setSelectedFile(false);
			setImage(undefined);
			setSelectedFile(undefined);
			setUploading(false);
			getUploads();
		}
	};

	const openDeleteModal = (upload) => {
		setDeletingFile(upload);
		setDeleteModalOpened(true);
	};
	const closeModal = () => {
		setDeletingFile({});
		setDeleteModalOpened(false);
	};

	const deleteFile = async () => {
		const resp = await removeUserDataFile(deletingFile);
		if (resp.status == 200) {
			getUploads();
			setDeletingFile({});
			notify(t('File has been removed'));
			setDeleteModalOpened(false);
		}
	};

	return (
		<div className='w-full bg-[#FAFAFF] h-full flex-col flex max-h-screen '>
			<div className='mt-[40px] mx-auto w-full max-w-[1200px] flex flex-col flex-1 overflow-hidden'>
				<div className='h-[140px] w-full rounded-[10px] bg-[#5E639B] mb-[30px] flex flex-row  items-center px-[35px] upload-header'>
					<div className='flex flex-row items-center flex-1'>
						<button className='rounded-full h-[70px] w-[70px] bg-[#ECEBF8]' onClick={goBack}>
							<PreviousIcon style={{ color: '#5E639B' }} height={20} width={18} />
						</button>
						<p className='text-[#FFFFFF] text-[32px] font-bold ml-[32px]'>{t('uploadUserData')} </p>
					</div>
					<div className='mr-[20px]'>
						<UploadMascot />
					</div>
					<a
						className='h-[50px] w-[280px] flex items-center justify-center rounded-full bg-highlightColor text-deepPurple font-bold text-[16px] transition-all ease-in-out duration-[230ms] hover:bg-[#FFFFFF]'
						href={`${fileSystemUrl}/public/School_Info_template.xlsx`}
						target='_blank'
						rel='noopener noreferrer'
					>
						{t('downloadTemplateFile')}
					</a>
				</div>
				<div className='flex flex-row gap-[30px] flex-1 overflow-hidden'>
					<div className='bg-[#FFFFFF] rounded-[10px] w-[500px] custom-shadow max-h-[770px] p-[30px] flex flex-col mb-[40px] '>
						<p className='mb-[20px] text-[#413F5E] font-bold text-[20px]'>{t('previousUploads')}:</p>
						<div className='flex-1 overflow-x-auto grey-small-scroll pr-[5px] -mr-[5px]'>
							{loading && (
								<div className='flex-1 flex justify-center items-center h-full'>
									<Loading size={40} />
								</div>
							)}
							{uploads.map((upload, index) => {
								const name = upload.storage_name;
								const deletingInProcess = deletingFile.id === upload.id;
								return (
									<div className='mb-[10px] rounded-[10px] bg-[#F8F8FB] p-[20px] w-full text-start'>
										<span className='text-[#413F5E] font-bold text-[16px]'>
											{upload?.user?.name} - {formatDateOnly(upload?.date)} -{' '}
										</span>
										<a
											href={`https://files.upstrivesystem.com/${name}?token=${token}`}
											target='_blank'
											className='text-[#5E639B] font-bold text-[16px] underline'
										>
											{t('fileLink')}
										</a>
										{upload?.user?.id == user.id && (
											<button
												style={{ float: 'right' }}
												className={deletingInProcess ? '' : 'remove-trash'}
												onClick={() => {
													openDeleteModal(upload);
												}}
												disabled={deletingInProcess}
											>
												<TrashIcon style={{ color: 'red' }} />
											</button>
										)}
									</div>
								);
							})}
						</div>
					</div>
					<div className='flex-1 h-[290px] rounded-[10px] bg-[#ECEBF8] p-[20px] custom-shadow flex flex-col'>
						<div
							className={`flex-1 bg-[#FFFFFF] rounded-[10px] flex items-center border-2 justify-center transition-all duration-300 ease-in-out flex-col text-[#BEBECE] font-semibold relative
                                ${!!selectedFile ? 'border-[#BEBECE]' : 'pointer hover:border-[#BEBECE]  border-[#FFFFFF]'}
                            `}
							onClick={pressUploadPicture}
						>
							<UploadDataIcon />
							<div className='mt-[5px]'>{selectedFile ? selectedFile?.name : t('attachFile')}</div>
							{selectedFile && (
								<button
									className='flex h-[30px] w-[30px] rounded-full bg-[#BEBECE] items-center justify-center transition-all duration-300 ease-in-out absolute top-3 right-3 hover:bg-[#413F5E]'
									onClick={closeImage}
								>
									<ExitCross style={{ fill: 'white' }} />
								</button>
							)}
						</div>
						<div className='flex flex-row  mt-[20px] items-center'>
							<div className='flex-1  flex flex-row justify-between mr-[20px]'>
								<span></span>
								<p className='text-deleteRed font-semibold text-[16px]'>{t(errorText)}</p>
							</div>
							<button
								className={`h-[50px] w-[280px] flex items-center justify-center rounded-full bg-deepPurple text-[#FDDF6D] font-bold text-[16px] transition-all ease-in-out duration-[230ms] 
                                    ${uploading ? '' : 'hover:bg-[#FDDF6D] hover:text-deepPurple'}
                                `}
								onClick={uploadFile}
								disabled={uploading}
							>
								{uploading ? <Loading /> : t('uploadData')}
							</button>
						</div>
					</div>
				</div>
			</div>
			<input
				title='Add Video/Photo'
				style={{ display: 'none' }}
				accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
				type='file'
				id='photo_upload_modal'
				onChange={onImageChange}
				onClick={resetInputValue}
			/>
			<ConfirmationModal
				modalIsOpen={deleteModalOpen}
				closeModal={closeModal}
				confirmation={deleteFile}
				message={t('doYouWantToDeleteThis')}
			/>
		</div>
	);
}
