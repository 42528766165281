import React from 'react';
import Actions from './overview/Actions';
import Monitoring from './overview/Monitoring';
import Statistics from './overview/Statistics';
import YourClassroom from './overview/YourClassroom';

function Overview() {
	return (
		<div className='flex flex-col'>
			<div className=''>
				<YourClassroom />
			</div>
			<div className='flex flex-row my-[10px] lg:my-[25px]'>
				<div className='w-[357px]'>
					<Monitoring />
				</div>
				<div className='flex-1 ml-[10px] lg:ml-[25px]'>
					<Actions />
				</div>
			</div>
			<div className=''>
				<Statistics />
			</div>
		</div>
	);
}

export default Overview;
