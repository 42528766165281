const TRANSLATION_EN = {
	confirmDeletion: 'Confirm deletion',
	deleteTheTemplateFor: 'Delete the template for',
	cancel: 'Cancel',
	delete: 'Delete',
	createContent: 'Create content',
	askAQuestion: 'Ask a question',
	createAPoll: 'Create a poll',
	createAPost: 'Create a post',
	orChooseFromTemplatesBelow: 'or choose from templates below',
	on: 'On',
	off: 'Off',
	never: 'Never',
	chooseTemplate: 'Choose template',
	thereAreNoTemplatesCreated: 'There are no templates created',
	howAreYouToday: 'How are you today?',
	selectEmotion: 'Select emotion',
	question: 'Question',
	genderSelection: 'Gender selection',
	all: 'All',
	male: 'Male',
	female: 'Female',
	settings: 'Settings',
	support: 'Support',
	privacyPolicy: 'Privacy policy',
	about: 'About',
	dayReview: 'Day review',
	dailyReflection: 'Daily reflection',
	youLiked: 'You liked',
	like: 'Like',
	edit: 'Edit',
	answered: 'Answered',
	showLess: 'Show Less',
	showMore: 'Show More',
	search: 'Search',
	targetGroup: 'Target group',
	quickAction: 'Quick action',
	select: 'Select',
	selectByGroup: 'Select by group',
	selectSchoolYearsThatYouLikeToTarget: 'Select school years that you like to target',
	years: 'Years',
	year: 'Year',
	selectClassesThatYouLikeToTarget: 'Select classes that you like to target',
	classes: 'Classes',
	class: 'Class',
	selectACustomListThatYouLikeToTarget: 'Select a custom list that you like to target',
	custom: 'Custom',
	selectTeachersYouWouldLikeToTarget: 'Select teachers you would like to target',
	staff: 'Staff',
	selectYears: 'Select years',
	selectClasses: 'Select classes',
	selectFromMyStudents: 'Select from my students',
	multipleClasses: 'Multiple Classes',
	multipleYears: 'Multiple years',
	allStudents: 'All students',
	done: 'Done',
	waitForTeachersToFeedYouWithSomeNewInformations: 'Wait for teachers to feed you with some new informations',
	feedIsEmpty: 'Feed is empty',
	thereIsNoFeedForThisSearch: 'There is no feed for this search',
	createNewPostsForYourStudentsAndHereIsAShortcutForYou: 'Create new posts for your students. Here is a shortcut for you.',
	waitForTeacherToFeedYouWithSomeNewInformation: 'Wait for teacher to feed you with some new information',
	createFeedPost: 'Create feed post',
	targetList: 'Target list',
	beAmazing: 'Be amazing!',
	login: 'Login',
	getStarted: 'Get started',
	enterYourSchoolEmail: 'Enter your school email',
	password: 'Password',
	email: 'Email',
	requestNewPassword: 'Request new password',
	newPassword: 'New password',
	enterYourSchoolEmailAndWeWillSendYouALinkToGetBackIntoYourAccount:
		"Enter your school email and we'll send you a validation token to get back into your account",
	sendRequest: 'Send request',
	verifyYourEmail: 'Verify your email',
	weHaveSentYouALinkToGetBackIntoYourAccountAndCheckYourMailAndCopyYourTokenHere:
		"We've sent you a validation token to get back into your account. Check your mail and copy your token here.",
	resendARequest: 'Resend a request',
	didntGetAMail: "Didn't get a mail?",
	submit: 'Submit',
	enterYourNewPassword: 'Enter your new password',
	apply: 'Apply',
	howDoYouFeel: 'How do you feel?',
	'How do you feel?': 'How do you feel?',
	poll: 'Poll',
	thereAreNoAnswersOrFeedbacksForThisDay: 'There are no answers or feedbacks for this day',
	title: 'Title',
	message: 'Message',
	requested: 'Requested',
	anonymous: 'Anonymous',
	url: 'Url',
	addPhoto: 'Add Photo',
	removePhoto: 'Remove Photo',
	youAreAllDone: 'You are all done!',
	congratsEverythingIsDoneForNowWaitForNewTasks: 'Congrats, everything is done for now! Wait for new tasks...',
	excellentWorkWeAreDelightedToHearThingsAreSoMuchBetter: 'Excellent work! We’re delighted to hear things are so much better.',
	thisIsYourSafePlaceAndNothingWillBeShared: 'This is your safe place. Nothing will be shared.',
	enterANameForTodaysNotes: "Enter a name for today's notes.",
	iDontWantToSaveNotes: 'I don’t want to save notes',
	worse: 'Worse',
	sorryToHearThatThingsAreWorseWhichOfTheFollowingWouldHelpRightNow:
		'Sorry to hear that things are worse. Which of the following would help right now?',
	muchWorse: 'Much worse',
	sorryToHearThatThingsAreMuchWorseAndWhichOfTheFollowingWouldHelpRightNow:
		'Sorry to hear that things are much worse. Which of the following would help right now?',
	same: 'Same',
	whatAreSomeIdeasToMakeThingsBetter: 'What are some ideas to make things better?',
	better: 'Better',
	goodToHearThingsAreBetterAndWhatAreSomeIdeasToTakeItEvenFurther:
		'Good to hear things are better. What are some ideas to take it even further?',
	muchBetter: 'Much better',
	greatToHearThingsAreMuchBetterAndThinkAboutHowToImproveEvenMoreOrKeepItOnAPositiveLevel:
		'Great to hear things are much better. Think about how to improve even more or keep it on a positive level',
	suggestions: 'Suggestions',
	ImOkWithIt: 'I’m Ok with it',
	ok: 'OK',
	IdPreferIfTheyWereBetter: 'I’d prefer if they were better',
	loadMoreSuggestions: 'Load more suggestions',
	continue: 'Continue',
	deleteNote: 'DELETE NOTE',
	today: 'Today',
	comparedToLastTimeTodayIamFeeling: "Compared to last time, today I'm feeling",
	howDoYouFeelComparedToLastTimeYouDidThePoll: 'How do you feel compared to last time you did the poll?',
	thisInformationWillBeSharedWith: 'This information will be shared with teacher.',
	feelFreeToShareAnyThoughtsHereWhyYouFeelThisWay: 'Feel free to share any thoughts here why you feel this way',
	feedback: 'Feedback',
	choose: 'Choose',
	newFeeds: 'New feeds',
	openTasks: 'Open Tasks',
	taskArchive: 'Task Archive',
	congratsAndEverythingIsDoneForNowAndWaitForNewTasksFromYourTeacher:
		'Congrats, everything is done for now! Wait for new tasks from your teacher',
	newConversation: 'New conversation',
	teacher: 'Teacher',
	teachers: 'Teachers',
	counselor: 'Counselor',
	secretKey: 'Secret key',
	advancedSettings: 'Advanced settings',
	showDailyReflectionScreen: 'Show daily reflection screen',
	termsAndConditions: 'Terms & Conditions',
	appVersion: 'App version: ',
	surname: 'Surname',
	firstName: 'First Name',
	gender: 'Gender',
	other: 'Other',
	thisIsYourSafePlace: 'This is your safe place',
	thisIsYourPrivateRoom: 'This is your private room.',
	safePlaceWithDailyReflectionNotes: 'Safe place with daily reflection notes',
	personalInformation: 'Personal information',
	privacyAndSecurity: 'Privacy and security',
	dashboard: 'Dashboard',
	update: 'Update',
	unselect: 'Unselect',
	noResults: 'No results',
	open: 'Open',
	share: 'Share',
	back: 'Back',
	task: 'Task',
	tasks: 'Tasks',
	logout: 'Logout',
	save: 'Save',
	name: 'Name',
	profile: 'Profile',
	classroom: 'Classroom',
	feed: 'Feed',
	live: 'Live',
	archive: 'Archive',
	yesterday: 'Yesterday',
	tomorrow: 'Tomorrow',
	results: 'Results',
	required: 'Required',
	schedule: 'Schedule',
	happy: 'happy',
	excited: 'excited',
	ecstatic: 'ecstatic',
	inspired: 'inspired',
	calm: 'calm',
	lethargic: 'lethargic',
	grumpy: 'grumpy',
	sad: 'sad',
	stressed: 'stressed',
	angry: 'angry',
	sunday: 'Sunday',
	monday: 'Monday',
	tuesday: 'Tuesday',
	wednesday: 'Wednesday',
	thursday: 'Thursday',
	friday: 'Friday',
	saturday: 'Saturday',
	scheduled: 'Scheduled',
	delivered: 'Delivered',
	first: 'First',
	second: 'Second',
	third: 'Third',
	seen: 'Seen',
	selectStaff: 'Select staff',
	forgotPassword: 'Forgot password?',
	repeatPassword: 'Repeat password',
	retypePassword: 'Retype password',
	changePassword: 'Change password',
	hidePassword: 'Hide password',
	dailyFeedback: 'Daily Feedback',
	createNew: 'Create new',
	selectionType: 'Selection type',
	enterSurname: 'Enter surname',
	enterEmail: 'Enter email',
	newMessage: 'New message!',
	myTasks: 'My Tasks',
	addOption: 'Add option',
	newQuestion: 'New Question',
	editQuestion: 'Edit Question',
	editPost: 'Edit post',
	editPoll: 'Edit poll',
	editGroup: 'Edit Group',
	tomorrowPlans: 'Tomorrow plans',
	confirmStopping: 'Confirm stopping',
	newPoll: 'New poll',
	answer: 'Answer',
	post: 'Post',
	hi: 'Hi',
	stop: 'Stop',
	lastTime: 'Last time',
	sendAnswer: 'Send answer',
	comment: 'Comment',
	passwordMustBeLongerThan8CharactersAndMustContainsUppercaseLowercaseNumberAndSpecialSymbol:
		'Password must be longer than 8 characters, must contains uppercase, lowercase, number and special symbol',
	enterYourWorkEmailAndPassword: 'Enter your work email and password',
	submitNewPassword: 'Submit new password',
	pleaseCheckYourMailToConfirmYourEmailAddress: 'Please check your mail to confirm your email address!',
	howDoYouFeelAboutThingsBeingTheSameToday: 'How do you feel about things being the same today?',
	pollHasBeenUpdated: 'Poll has been updated',
	typeAPostTitle: 'Type a post title',
	addAnOptionalMessage: 'Add an optional message',
	writeYourMessageHere: 'Write your message here',
	enterUrlLink: 'Enter url link',
	saveAndContinue: 'Save and continue',
	nameOfTodayNote: 'Name of today note',
	whatAreJust3GoodThingsThatHappenedToday: 'What are just 3 good things that happened today?',
	yourDirectManager: 'your direct manager',
	secretKeyHasBeenSaved: 'Secret key has been saved',
	enterYourSecretKeyToEncryptLllYourMessagesAndAnswers: 'Enter your secret key to encrypt all your messages and answers',
	enterSecretKey: 'Enter secret keys',
	advancedSettingsAreSuccessfullySaved: 'Advanced settings are successfully saved!',
	youCanUseTheButtonsAboveToContactYourTeacherOrCounselorForSupportAtAnyPoint:
		'You can use the buttons above to contact your teacher or counselor for support at any point',
	waitForStudentsToContactYouToHelpThem: 'Wait for students to contact you to help them',
	passwordsDoNotMatch: 'Passwords do not match',
	startNewChat: 'Start new chat',
	shareWithTeacher: 'Share with teacher',
	tasksForThisSection: 'Tasks for this section',
	howWasYourDay: 'How was your day?',
	thisIsAutoReplyMessage: 'This is auto reply message',
	thankYouForReachingOutWeWillReplyToYourMessageSoonIfYouOrSomeoneYouKnowIsInImmediateDangerPleaseCallEmergencyServices:
		'Thank you for reaching out. We will reply to your message soon.',
	doYouThinkThingsAreForAnyReasonInParticular: 'Do you think things are {{result}} for any reason in particular?',
	yourPrivateNotes: 'Your private notes',
	createNewPoll: 'Create new poll',
	saveAsTemplate: 'Save as template',
	addPostContentHere: 'Add post content here',
	typeAPollQuestion: 'Type a poll question ...',
	questionHasBeenUpdated: 'Question has been updated',
	questionHasBeenPosted: 'Question has been posted',
	iDontWantToThinkAboutThisNow: "I don't want to think about this now",
	selectLanguage: 'Select language',
	userIsUpdated: 'User is updated',
	imageIsNotUploaded: 'Image is not uploaded',
	feedHasBeenUpdated: 'Feed has been updated',
	permissionIsNotAccepted: 'Permission is not accepted',
	feedHasBeenPosted: 'Feed has been posted',
	pollHasBeenPosted: 'Poll has been posted',
	templateHasBeenSaved: 'Template has been saved',
	privateNoteHasBeenSaved: 'Private note has been saved',
	yourEmotionHasBeenShared: 'Your emotion has been shared',
	compareToLastTime: 'Compare to last time',
	yourAnswerIsSaved: 'Your answer is saved',
	staySecuredFirst: 'Stay secured first',
	day: 'Day',
	dateOfBirth: 'Day of birth',
	teacherInfo: 'Teacher info',
	studentInfo: 'Student info',
	manage: 'Manage',
	openListWithAllClassesFromThisSchool: 'Open list with all classes from this school',
	wizardToCreateNewClassAddStudentsAndHeaTteacher: 'Wizard to create new class, add students and head teacher',
	openListWithAllTeachersThatGoToThisSchool: 'Open list with all teachers that go to this school',
	wizardToCreateNewTeacherAndLinkItToProperClass: 'Wizard to create new teacher and link it to proper class',
	chooseDataRange: 'Choose data range',
	thisListRepresentsAllContentFromThisTypeYouCanEditOrDeleteTt:
		'This list represents all content from this type. You can edit or delete it',
	lastCreatedpoll: 'Last created poll',
	lastCreatedfeed: 'Last created feed',
	lastCreatedquestion: 'Last created question',
	listOfDays: 'List of days',
	selectThisquestion: 'Select this question',
	selectThispoll: 'Select this poll',
	selectThisfeed: 'Select this feed',
	createOwn: 'Create own',
	showAllContent: 'Show all content',
	programDuration: 'Program duration',
	seeThisProgram: 'See this program',
	programs: 'Programs',
	hereYouCanCreateAPollToLearnMoreAboutYourStudents: 'Here you can create a poll to learn more about your students',
	askAnOpenQuestionToGetDetailedFeedback: 'Ask an open question to get detailed feedback',
	classManagement: 'Class management',
	teacherManagement: 'Teacher management',
	analyze: 'Analyze',
	instructionVideos: 'Instruction Videos',
	created: 'Created',
	pickOneOption: 'Pick one option:',
	listOfClasses: 'List of classes',
	newClass: 'New class',
	action: 'Action',
	numberOfStudents: 'Number of students',
	listOfTeachers: 'List of teachers',
	newTeacher: 'New teacher',
	options: 'Options',
	send: 'Send',
	refresh: 'Refresh',
	selectDate: 'Select date',
	time: 'Time',
	date: 'Date',
	feedDescription: 'Here you can add text, pictures, videos or links',
	'Choose from our range of ready-to-go programs designed to support your students and save you times now':
		'Choose from our range of ready-to-go programs designed to support your students and save you times now',
	listOfStudentsWhoHaventLoggedInYet: "List of students who haven't logged in yet",
	studentsLoginData: 'Students login data',
	loadMoreClasses: 'Load more classes',
	loginInfo: 'Login info',
	role: 'Role',
	loadMore: 'Load more',
	noTeachersYet: 'No teachers yet',
	classInfo: 'Class info',
	enterClassName: 'Enter class name',
	enterSchoolYear: 'Enter school year',
	schoolYear: 'School year',
	className: 'Class name',
	listOfStudents: 'List of students',
	doYouWantToRemoveStudent: 'Do you want to remove the student?',
	doYouWantToDeleteClass: 'Do you want to delete the class?',
	emailIsInvalid: 'Email is invalid.',
	emailIsAvailable: 'Email is available!',
	emailAlreadyExists: 'Email already exists!',
	fieldIsRequired: 'Field is required',
	showPassword: 'Show password',
	enterName: 'Enter name',
	selectRole: 'Select role',
	teachersRoleNotSetYet: 'Teaching role not yet selected',
	create: 'Create',
	doYouWantToDeleteTeacher: 'Do you want to delete the teacher?',
	classIsCreated: 'The class is made!',
	reminderForToday: 'Reminder for today',
	closed: 'Closed',
	youDontHaveAnyRemindersForToday: "You don't have any reminders for today",
	firstEnterANote: 'First enter a note',
	noteIsCreated: 'Note is created',
	reminderHasBeenChanged: 'Reminder has been changed!',
	status: 'Status',
	exportReport: 'Export report',
	notes: 'Notes',
	Notes: 'Notes',
	editReminder: 'Edit reminder',
	removeReminder: 'Remove reminder',
	enterYourNoteHere: 'Enter your note here',
	createANote: 'Create a note',
	addReminder: 'Add reminder',
	teacherLogin: 'Teacher Login',
	enterCredentialsYouReceivedFromSchool: 'Enter credentials you received from school',
	received: 'Received',
	deletePost: 'Delete this post',
	doYouWantToDeletePost: 'Would you like to delete this post?',
	easilyManageYourStudentsAndTeachersWithSeparateFunctions: 'Easily manage your students and teachers with separate functions',
	user: 'User',
	userManagement: 'User management',
	chat: 'Chat',
	trackCounseling: 'Track Counseling',
	and: 'and',
	openLink: 'Open link',
	openListOfUsersWhoHaventLoggedIn: "Open list of users who haven't logged in",
	setDateForReminderOfStudent: "Set a date when you'd like to be reminded about this student",
	createReminder: 'Create reminder',
	writeFullAnswerHere: 'write the full answer here',
	answers: 'Answers',
	actionTaken: 'Action taken',
	iWillTakeAction: 'I will take action',
	seenBy: 'Seen by',
	thereAreNoAnswersForThatDay: 'There are no answers for that day ...',
	describeHowYouWillDealWithThisStudent: 'Describe how you will deal with this student',
	writeYourThoughtsHere: 'Write your thoughts here',
	studentData: 'Student data',
	student: 'Student',
	enterStudentUPN: 'Enter Student UPN',
	showAll: 'Show all',
	loginStatistic: 'Login statistics',
	hereYouCanAddTextImagesVideosOrLinks: 'Here you can add text, images, videos or links',
	typeAQuestionTitle: 'Enter a Question title...',
	typeYourHeadlineHere: 'Type your headline here ...',
	optional: ' (optional)',
	invalidLink: 'Invalid link',
	chooseTemplateFor: 'Choose template for ',
	doYouWantToDeleteThis: 'Do you want to delete this?',
	yourDataIsUpdated: 'Your data is updated!',
	yourDataIsNotUpdated: 'Your data is not updated!',
	contentIsCreated: 'Content is created!',
	contentIsNotCreated: 'Content is not created!',
	templateIsCreated: 'Template is created!',
	templateIsNotCreated: 'Template is not created!',
	allCreatedContent: 'All created content',
	runProgram: 'Run program',
	teachersOnly: 'Teachers only',
	programTitle: 'Program title',
	thisToolHelpsYouPlanTheEntireProgramOnce: "This tool helps you plan the entire program once so you don't have to keep checking it.",
	startDate: 'Start date',
	selectDays: 'Select days',
	pickTime: 'Pick time',
	thisProgaramIsAlreadyRunning: 'This program is already running, run again?',
	youAreRunningThisProgramSuccessfully: 'You successfully run this program',
	surveys: 'Surveys',
	survey: 'Survey',
	description: 'Description',
	typeASurveyTitle: 'Type a survey title ...',
	addASurveyDescription: 'add a survey description',
	surveyTitle: 'Survey title',
	section: 'Section',
	sections: 'Sections',
	enterSectionTitleHere: 'Enter section title here...',
	enterSectionDescriptionHere: 'Enter section description here...',
	enterQuestionHere: 'Enter question here ...',
	openAnswer: 'Open answer',
	addQuestion: 'Add question',
	addSection: 'Add section',
	deleteSurvey: 'Delete survey',
	doYouWantToRemoveSurvey: 'Do you want to remove survey?',
	yesNo: 'Yes/No',
	confirmation: 'Confirmation',
	manageRunningPrograms: 'Manage running programs',
	numberOfSections: 'Number of sections',
	thisToolHelpsYouToControlAllRunningProgramsAndTrackTheirInfluenceToStudents:
		'This tool helps you to control all running programs and track their influence to students',
	currentPrograms: 'Current programs',
	yourRunningProgramListIsEmptyStartByAddingOneFromTheListOfPrograms:
		'Your running program list is empty, start by adding one from the list of programs',
	deleteProgram: 'Delete program',
	days: 'days',
	surveyIsCreated: 'Survey is created',
	yes: 'Yes',
	no: 'No',
	configureSurvey: 'Configure survey',
	editSurvey: 'Edit Survey',
	runSurvey: 'Run survey',
	selectMyStudents: 'Select my students',
	openFavorites: 'Open Favorites',
	thereIsNoDataToShow: 'There is no data to show',
	selectAll: 'Select all',
	deselectAll: 'Deselect all',
	saveAsFavorite: 'Save as favorite',
	doYouWantToDeleteFavorite: 'Do you want to delete Favorite group?',
	saveYourFavoriteGroup: 'Save your favorite group',
	deleteWasSuccessful: 'Delete was successful.',
	listOf: 'List of : ',
	deleteThis: 'Delete this ',
	editThis: 'Edit this ',
	youHaveNoChats: 'You have no chats',
	reminderSetFor: 'Reminder set for ',
	doYouWantToDeleteThisTask: 'Do you want to delete this task?',
	repeat: 'Repeat',
	addToWellbeingTracker: 'Add to Wellbeing Tracker',
	addToAnalytics: 'Add to analytics',
	wellbeingTracker: 'Wellbeing Tracker',
	assignedActions: 'Assigned actions',
	reminder: 'Reminder',
	youDontHaveAnyAssignedAction: 'You have no assigned actions yet',
	assignAction: 'Assign action',
	AssignActionToATeacherToCheckOnThisStudent: 'Assign action to a teacher to check on this student',
	assignToTeacher: 'Assign to teacher',
	chooseFromOurRangeOfReadyToGoProgramsDesignedToSupportYourStudentsAndSaveYouTimesNow:
		'Choose from our range of ready-to-go programs designed to support your students and save you times now',
	journals: 'Journals',
	assignedTo: 'Assigned to:',
	selectTeacher: 'Select teacher',
	createAQuestion: 'Create a question',
	turnOff: 'Turn off',
	weekly: 'Weekly',
	monthly: 'Monthly',
	endOfRecurrence: 'End of recurrence',
	everyMonthOn: 'Every month on',
	until: 'Until',
	occursEvery: 'Occurs every',
	staffLogin: 'Staff login',
	token: 'Token',
	createYourOwn: 'Create your own',
	readyToGo: 'Ready-to-go',
	favorites: 'Favorites',
	addToFavorites: 'Add to favorites',
	Attention: 'Attention',
	toEnsureYourPrivacyThisKeyIsNotRecoverableAllYourNotesWillBeLostIfYouForgetThisKey:
		'To ensure your privacy, this key is not recoverable. All your notes will be lost if you forget this key!',
	findTheRightSolution: 'Find the right solution',
	changeSecurityKey: 'Change security key',
	passwordSetup: 'Password setup',
	'Take it': 'Take it',
	open_poll: 'Open poll',
	open_question: 'Open question',
	open_journal: 'Open journal',
	open_survey: 'Open survey',
	'How are you?': 'How are you?',
	'Type an action': 'Type an action',
	actionComment: 'Action comment',
	lastNote: 'Last note',
	Overdue: 'Overdue',
	'Do you want to close this counseling profile?': 'Do you want to close this counseling profile?',
	'Do you want to open this counseling profile?': 'Do you want to open this counseling profile?',
	'This counseling profile is open!': 'This counseling profile is open!',
	'This counseling profile is closed!': 'This counseling profile is closed!',
	'Reminder has been removed!': 'Reminder has been removed!',
	'Assign action': 'Assign action',
	'Export data': 'Export data',
	'Message only visible to staff with special access': 'Message only visible to staff with special access',
	'Message is visible to all': 'Message is visible to all',
	'Set a date when you would like to be reminded about this student': 'Set a date when you would like to be reminded about this student',
	'Choose a date': 'Choose a date',
	'Teacher is created and password has been sent!': 'Teacher is created and password has been sent!',
	'Teacher is not created!': 'Teacher is not created!',
	'Teacher is removed!': 'Teacher is removed!',
	'Teacher is not removed!': 'Teacher is not removed!',
	students: 'Students',
	'Unregistered teachers': 'Unregistered teachers',
	'Unregistered users': 'Unregistered users',
	'Registered teachers': 'Registered teachers',
	'Registered users': 'Registered users',
	'Login data': 'Login data',
	noAnswer: 'No answer',
	changeStatus: 'Change status',
	answerOnHowAreYou: 'Answer on “How are you?”',
	'Your Upstrive (Tasks)': 'Your Upstrive (Tasks)',
	'Take a look what’s on your plate': 'Take a look what’s on your plate',
	'Click to get back to all Tasks': 'Click to get back to all Tasks',
	chooseTheAnswer: 'Choose the answer',
	'Type any additional text here': 'Type any additional text here',
	'Write the answer': 'Write the answer',
	'7 Days': '7 Days',
	'3 Months': '3 Months',
	'1 Month': '1 Month',
	'1 Year': '1 Year',
	'all emotions': 'All emotions',
	'Trend of your classrooms': 'Trend of your classrooms',
	'of students using “How are you” at least once per week': 'of students using “How are you” at least once per week',
	'All emotions': 'All emotions',
	good: 'good',
	numb: 'numb',
	worried: 'worried',
	negative: 'Negative',
	'Wellbeing of your classrooms': 'Wellbeing of your classrooms',
	'User activity': 'User activity',
	'Current Emotion': 'Current Emotion',
	'Most Popular Emotion': 'Most Popular Emotion',
	'Stop editing': 'Stop editing',
	'Add note': 'Add note',
	'Class is updated!': 'Class is updated!',
	'Class is not updated!': 'Class is not updated!',
	'Student is created!': 'Student is created!',
	'Student is not created!': 'Student is not created!',
	'Add a teacher': 'Add a teacher',
	'Add a new class': 'Add a new class',
	'Last login': 'Last login',
	Journals: 'Journals',
	currentEmotion: 'Current Emotion',
	mostPopularEmotion: 'Most Popular Emotion',
	messageIsVisibleToAll: 'Message is visible to all',
	messageOnlyVisibleToStaffWithSpecialAccess: 'Message only visible to staff with special access',
	assignActionSuccess: 'Assign action success',
	filterIsNotSet: 'Filter is not set!',
	commentHasBeenSeen: 'Comment has been seen!',
	catchUp: 'Catch-up',
	catchUpOnAnyStudentCommentsAttachedTo:
		'Catch up on any student comments attached to an uncomfortable emotion that may have been missed along the way',
	unseen: 'Unseen',
	markAsSeen: 'Mark as seen',
	fullTraining: 'Full Training',
	individualFeaturesTraining: 'Individual Features Training',
	instructionVideosForStaff: 'Instructions video for Staff',
	noteHasBeenDeleted: 'Note has been deleted',
	deleteYourPrivateNote: 'Delete your private note?',
	emotions: 'Emotions',
	studentRemoved: 'Student removed',
	fileHasBeenUploadedDataWillBeUpdatedWithin24Hours: 'File has been uploaded. Data will be updated within 24 hours',
	Neutral: 'Neutral',
	downloadTemplateFile: 'Download Template File',
	previousUploads: 'Previous uploads',
	fileLink: 'File link',
	attachFile: 'Attach file',
	uploadData: 'Upload data',
	noUnsignedTeachers: 'No unsigned teachers',
	noUnsignedStudents: 'No unsigned students',
	reviewYourLoginStatistics: 'Review your login statistics',
	registeredStudents: 'Registered students',
	unregisteredStudents: 'Unregistered students',
	uploadUserData: 'Upload user data',
	addATeacher: 'Add a teacher',
	addANewClass: 'Add a new class',
	enterStudentsDetails: "Enter student's details",
	enterTeachersDetails: "Enter teacher's details",
	cantCreateStudentWithoutCreateClass: 'Can`t create student without create class',
	createWellbeingProfile: 'Create Wellbeing profile',
	yourStudentListIsEmpty: 'Your students list is empty',
	addAStudent: 'Add a student',
	letThemSetTheirOwnPasswordsAfterLogin: 'Let them set their own passwords after login',
	keepThisPassword: 'Keep this password',
	rolemodelIsnotLoaded: 'Role model is not loaded... Please refresh page!',
	loginData: 'Login data',
	exportData: 'Export data',
	passwordIsNotCorrect: 'Password is not correct',
	closePopUp: 'Close popup',
	deleteStudent: 'Delete student',
	deleteTeacher: 'Delete teacher',
	hidePasswordmanager: 'Hide password manager',
	setStudentsPasswordManually: "Set student's password manually",
	generateNewPassword: 'Generate new password',
	lastName: 'Last name',
	noStudentsYet: 'No students yet',
	loggedIn: 'Logged in',
	teacherRole: 'Teacher role',
	selectTeacherRole: 'Select teacher role',
	thisTeacherWillbe: 'This teacher will be',
	classIsUpdated: 'Class is updated!',
	classIsNotUpdated: 'Class is not updated!',
	teacherIsCreatedAndPasswordHasBeenSent: 'Teacher is created and password has been sent.',
	teacherIsNotCreated: 'Teacher is not created!',
	createYourOwnContent: 'Create Your Own Content',
	contentDeleted: 'Content deleted',
	upcomingContent: 'Upcoming Content',
	overview: 'Overview',
	feedPosts: 'Feed Posts',
	welcomeBack: 'Welcome back',
	ofStudentsUsingHowAreYouTodayAtLeastOncePerWeek: '% of students using “How are you today?” at least once per week',
	yourClassroom: 'Your Classroom',
	checkWhatsGoingOn: 'Check what’s going on',
	myClassroom: 'My Classroom',
	youDeletedTargetGroup: 'You deleted target group',
	deeperInsights: 'Deeper insights',
	findYourStudentsWhoAreLoggingUncomfortableEmotions: 'Find your students who are logging uncomfortable emotions',
	monitoringGroups: 'Monitoring groups',
	createGroup: 'Create Group',
	whatIsIt: 'What is it?',
	createdNewMonitoringGroup: 'Created new monitoring group!',
	updatedMonitoringGroup: 'Updated monitoring group!',
	nameANewGroup: 'Name a new group',
	chooseEmotions: 'Choose emotions',
	close: 'Close',
	actionRequired: 'Action Required',
	stayUpToDateWithStudentSupport: 'Stay up to date with student support',
	actionsAssignedToMe: 'Actions assigned to me',
	studentChat: 'Student chat',
	unreadMessage: 'unread message',
	assignedBy: 'Assigned by',
	addNote: 'Add note',
	engagementStatistics: 'Engagement Statistics',
	trackEngagementResponsivenessLogins: 'Track engagement, responsiveness & logins',
	analytics: 'Analytics',
	ofStudentsUsingUpstriveWeekly: '% of students using  Upstrive weekly',
	ofResponsesToActivePollsQuestion: '% of responses to active polls questions',
	ofTeachersLoggedIn: '% of teachers logged in',
	ofStudentsLoggedIn: '% of students logged in',
	displayAll: 'Display All',
	'display all': 'Display All',
	'Display all': 'Display all',
	onlyMine: 'Only mine',
	searchResults: 'Search results',
	filter: 'Filter',
	from: 'From',
	to: 'To',
	notSelected: 'Not selected',
	questions: 'Questions',
	Questions: 'Questions',
	polls: 'Polls',
	Polls: 'Polls',
	runReadyToGo: 'Run ready-to-go',
	journal: 'Journal',
	target: 'Target',
	clickToBrowseResults: 'Click to browse results',
	by: 'By',
	archived: 'Archived',
	download: 'Download',
	thereIsntAnyAnswerYet: 'There isn`t any answer yet',
	start: 'Start',
	createPosts: 'Create Posts',
	deleteFeedContent: 'Delete feed content?',
	selectEmotionThatBestDescribesYourMoodToday: 'Select an emotion that best describes your mood today',
	yourTasks: 'Your tasks',
	activitiesForCompletion: 'Activities for completion',
	congratulations: 'Congratulations',
	youHaveCompletedAllYourTasks: 'You have completed all your tasks.',
	typeAnyAdditionalTextHere: 'Type any additional text here',
	submitAnswer: 'Submit answer',
	yourResultsAreSaved: 'Your results are saved!',
	takeIt: 'Take it',
	runReadyToGoContent: 'Run ready-to-go content',
	selectOneOption: 'Select one option',
	youHaveMissedThisQuestion: 'You have missed this question',
	finish: 'Finish',
	nextSection: 'Next section',
	yourAnswersAreSaved: 'Your answers are saved!',
	yourBrowserDoesNotSupportHTMLVideo: 'Your browser does not support HTML video.',
	whatIsTheMainReason: 'What’s the main reason?',
	pickUpTo3: 'Pick up to 3',
	myself: 'myself',
	family: 'family',
	friends: 'friends',
	school: 'school',
	'social media': 'social media',
	wellbeing: 'wellbeing',
	health: 'health',
	achievement: 'achievement',
	sleep: 'sleep',
	Myself: 'Myself',
	Family: 'Family',
	Friends: 'Friends',
	'Social media': 'Social media',
	Wellbeing: 'Wellbeing',
	Health: 'Health',
	School: 'School',
	Achievement: 'Achievement',
	Sleep: 'Sleep',
	howDoYouFeelComparedToLastTimeYouLoggedIn: 'How do you feel compared to the last time you logged in?',
	iDontWantToThinkAboutItNow: 'I don’t want to think about it now',
	stepBack: 'Step back',
	whatCouldYouDoToImproveThingsRightNow: 'What could you do to improve things right now?',
	yourFeedBackHasBeenSubmited: 'Your feedback has been submitted!',
	useTemplate: 'Use Template',
	templateDeleted: 'Template deleted',
	fileIsToBig: 'File is too big!',
	templateIsUpdated: 'Template is updated',
	contentNeedsToBeReviewedByTheScoolAdmin: 'Content needs to be reviewed by the school admin!',
	headline: 'Headline',
	typeHeadlineHere: 'Type headline here',
	supportiveText: 'Supportive Text',
	enterLinkOptional: 'Enter Link (optional)',
	writeOption1Here: 'Write option 1 here',
	deleteOption: 'Delete option',
	'Select target': 'Select target',
	pleaseFillRequiredFields: 'Please fill required fields',
	updateTemplate: 'Update template',
	templateIsSaved: 'Template is saved!',
	publish: 'Publish',
	surveyIsCreatedAsTemplate: 'Survey is created as template!',
	surveyIsDeleted: 'Survey is deleted!',
	moveSectionUp: 'Move section up',
	moveSectionDown: 'Move section down',
	headlineForSection: 'Headline for section',
	descriptionForSection: 'Description for section',
	moveQuestionUp: 'Move question up',
	moveQuestionDown: 'Move question down',
	selectTypeOfQuestion: 'Select type of question',
	writeOption: 'Write option {{result}} here',
	setupADateAndTime: 'Setup a Date & Time',
	endDate: 'End date',
	selectACategory: 'Select a category',
	selectSpecificYearGroupsToGatherMoreInformationFrom: 'Select specific year groups to gather more information from',
	customize: 'Customize',
	selectYourFavoriteGroup: 'Select your favorite group',
	selectCustom: 'Select Custom',
	setATime: 'Set a time',
	readyToGoContent: 'Ready-to-go Content',
	sortBy: 'Sort by',
	type: 'Type',
	topic: 'Topic',
	evidenceBasedContentPreparedForYouByOurTeamOfExperts: 'Evidence-based content prepared for you by our team of experts',
	'Choose from our range of ready-to-go programs designed to support your students and save you time':
		'Choose from our range of ready-to-go programs designed to support your students and save you time.',
	'Each of our ready-to-go surveys is designed to help you understand your students better':
		'Each of our ready-to-go surveys is designed to help you understand your students better.',
	'Pick a journal that will keep your students engaged': 'Pick a journal that will keep your students engaged.',
	currentlyRunning: 'Currently running',
	running: 'Running',
	paused: 'Paused',
	playProgram: 'Play program',
	pauseProgram: 'Pause program',
	doYouWantToDeleteThisProgram: 'Do you want to delete this program?',
	doYouWantToStartThisProgram: 'Do you want to start this program?',
	doYouWantToStopThisProgram: 'Do you want to stop this program?',
	postIndividually: 'Post individually',
	lastCreated: 'Last created',
	youMustSelectTargetGroup: 'You must select target group!',
	youMustSelectDaysOfWeek: 'You must select days of week!',
	Sun: 'Sun',
	Mon: 'Mon',
	Tue: 'Tue',
	Wed: 'Wed',
	Thu: 'Thu',
	Fri: 'Fri',
	Sat: 'Sat',
	runIt: 'Run it',
	classroomTrends: 'Classroom Trends',
	searchForStudent: 'Search for student',
	searchForTeacher: 'Search for teacher',
	uncomfortable: 'Uncomfortable',
	Emotions: 'Emotions',
	filterClassroom: 'Filter Classroom',
	yourIndividualStudentsResponses: 'Your individual students’ responses',
	uncomfortableEmontionsWithComments: 'Uncomfortable emotions with comments',
	wellbeingOfTeachers: 'Wellbeing of teachers',
	youMustFillData: 'You must fill data',
	actionHasBeenAssignedToTeacher: 'Action has been assigned to teacher',
	assignActionFailed: 'Assign action failed',
	sendMessage: 'Send Message',
	classroomTeacher: 'Classroom Teacher',
	headOfYear: 'Head of Year',
	otherTeachers: 'Other Teachers',
	typeAnAction: 'Type an action',
	sendPrivateMessageToStudent: 'Send Private message to student',
	messageHasBeenSent: 'Message has been sent',
	getDeeperInsights: 'Get deeper insights',
	searchQuestion: 'Search question',
	clickToSeeQuestionInDetails: 'Click to see question in details',
	getDeeperInsightsIntoYourStudentsEmotionalTrends: 'Get deeper insights into your students emotional trends',
	getInsights: 'Get insights',
	totalCommentsLeftUsingHowAreYouToday: 'Total comments left using “How are you today?”',
	basics: 'Basics',
	getBackToMainAnalytics: 'Get Back to Main Analytics',
	searchStudents: 'Search students',
	lastLogin: 'Last login',
	neverLoggedIn: 'Never logged in',
	loggedInRecently: 'Logged in recently',
	studentsNotFound: 'Students not found',
	showMoreStudents: 'Show more students',
	searchTeachers: 'Search Teachers',
	position: 'Position',
	teachersNotfound: 'Teachers not found',
	showMoreTeachers: 'Show more teachers',
	studentsWhoDidntRespondToAnyQuestionInPastTwoWeeks: 'Students who didn`t respond to any question in past two weeks',
	thisCanTakeAwhile: 'This can take a while',
	barView: 'Bar view',
	averageResponseDistributionForSelectedPeriod: 'Average response distribution for selected period',
	percentageOfResponseDistributionByGender: 'Percentage of response distribution by gender',
	general: 'General',
	percentageOfResponseDistributionIntotalForSelectedPeriod: 'Percentage of response distribution in total for selected period',
	totalCommentsInHowareYouToday: 'Total comments in "How are you today?"',
	emotion: 'Emotion',
	noOfCommentsAnsweringHowAreYouToday: 'No. of comments answering, “How are you today?”',
	change: 'Change',
	mostCommonReasons: 'Most common reasons',
	thereIsnotAnyConversationYet: 'There isn`t any conversation yet',
	replyOn: 'Reply on',
	myMessage: 'My message',
	'Track student task responses': 'Track student task responses',
	'Full name': 'Full name',
	"You can't delete yourself!": "You can't delete yourself!",
	'This day is locked!': 'This day is locked!',
	'Multiple years': 'Multiple years',
	'% of all students are with selected type of emotions': '% of all students are with selected type of emotions.',
	'It is 71% less then on previous week': 'It is {{num}}% less then on previous week',
	'It is 71% more then on previous week': 'It is {{num}}% more then on previous week',
	cannotCreateAnonymousSurveyFor1User: 'Cannot create anonymous survey for 1 user',
	'Cannot create anonymous poll for 1 user': 'Cannot create anonymous poll for 1 user',
	'Cannot create anonymous poll for 2 users': 'Cannot create anonymous poll for 2 users',
	'Release survey by days': 'Release survey by days',
	"This tool helps you to schedule whole survey once so you don't need to check it all the time":
		"This tool helps you to schedule whole survey once so you don't need to check it all the time",
	mood: 'Mood',
	'Schedule Survey': 'Schedule Survey',
	'Please select file': 'Please select file',
	yourSecretKeyIsSaved: 'Your secret key is saved',
	'1st': '1st',
	'2nd': '2nd',
	'3rd': '3rd',
	'% of responses on active polls & questions': '% of responses on active polls & questions',
	'% of responses on active polls, questions': '% of responses on active polls, questions',
	'% of students using "How are you?" at least once per week': '% of students using "How are you?" at least once per week',
	'% of students using Upstrive (per week, any function)': '% of students using Upstrive (per week, any function)',
	'% of students using “How are you?” at least once per week': '% of students using “How are you?” at least once per week',
	'% of teachers using Upstrive (per week, any function)': '% of teachers using Upstrive (per week, any function)',
	'% this week': '% this week',
	'Action Required': 'Action Required',
	'Action needed': 'Action needed',
	'Action taken': 'Action taken',
	'Actions assigned to me': 'Actions assigned to me',
	'Actions required': 'Actions required',
	'Add reminder': 'Add reminder',
	'Add section': 'Add section',
	'Add to Wellbeing Tracker': 'Add to Wellbeing Tracker',
	'Add to favorites': 'Add to favorites',
	AddVideoPhoto: 'Add Video/Photo',
	'Allow more than one answer': 'Allow more than one answer',
	'Ask your manager to assign you to class.': 'Ask your manager to assign you to class',
	'Assign to teacher': 'Assign to teacher',
	'Assign to this teacher': 'Assign to this teacher',
	'Assigned to': 'Assigned to',
	'Assignment for': 'Assignment for',
	'At least 8 characters': 'At least 8 characters',
	Browse: 'Browse',
	By: 'By',
	'Cannot create anonymous survey for 1 user': 'Cannot create anonymous survey for 1 user',
	'Catch-up': 'Catch-up',
	Category: 'Category',
	Chat: 'Chat',
	'Check for any student comments that may have been overlooked': 'Check for any student comments that may have been overlooked',
	'Choose topic': 'Choose topic',
	'Choose your answer': 'Choose your answer',
	'Class info': 'Class info',
	'Classroom Teacher': 'Classroom Teacher',
	'Classroom Trends': 'Classroom Trends',
	'Click on emotion to go into the classroom details': 'Click on emotion to go into the classroom details',
	'Click to copy email': 'Click to copy email',
	'Comment has been seen!': 'Comment has been seen!',
	Comments: 'Kommentare',
	'Comments for review': 'Comments for review',
	'Configure survey': 'Configure survey',
	'Congrats, everything is done for now!': 'Congrats, everything is done for now!',
	'Congratulations!': 'Congratulations!',
	Copied: 'Copied',
	'Create Group': 'Create Group',
	'Create Group and Apply filter': 'Create Group and Apply filter',
	'Create Wellbeing profile': 'Create Wellbeing profile',
	'Create a question': 'Create a question',
	'Create a survey': 'Create a survey',
	'Create something and see the results live!': 'Create something and see the results live!',
	'Create your own': 'Create your own',
	'Create your own customized group to gather information from': 'Create your own customized group to gather information from',
	'Created new monitoring group!': 'Created new monitoring group!',
	'Creation shortcuts for you': 'Creation shortcuts for you',
	'Current programs:': 'Current programs:',
	Customize: 'Customize',
	Date: 'Date',
	'Date of Birth': 'Date of Birth',
	Day: 'Day',
	Default: 'Default',
	'Delete survey': 'Delete survey',
	'Delete the template for title': 'Delete the template for {{title}}',
	'Delete this favorite group?': 'Delete this favorite group?',
	'Delete this feed?': 'Delete this feed?',
	'Delete this note?': 'Delete this note?',
	'Delete this poll?': 'Delete this poll?',
	'Delete this post?': 'Delete this post?',
	'Delete this program?': 'Delete this program?',
	'Delete this question?': 'Delete this question?',
	'Delete this survey?': 'Delete this survey?',
	'Describe how will you take action about this student': 'Describe how will you take action about this student',
	'Display All': 'Display All',
	'Do not agree': 'Do not agree',
	'Do you think things are same for any reason in particular?': 'Do you think things are same for any reason in particular?',
	Download: 'Download',
	'Easily manage your students and teachers, using separate functions':
		'Easily manage your students and teachers, using separate functions',
	'Edit Group': 'Edit Group',
	'Edit Group and Apply filter': 'Edit Group and Apply filter',
	'Edit Survey': 'Edit Survey',
	'Engagement Statistics': 'Engagement Statistics',
	'Enter Link (optional)': 'Enter Link (optional)',
	'Enter a question here': 'Enter a question here',
	'Enter any url here': 'Enter any url here',
	'Enter your note here': 'Enter your note here',
	'Export report': 'Export report',
	Favorite: 'Favorite',
	Favorites: 'Favorites',
	'Feed Posts': 'Feed Posts',
	Filter: 'Filter',
	'Filter Classroom': 'Filter Classroom',
	'Filter Emotions': 'Filter Emotions',
	'Filter by dates': 'Filter by dates',
	'Filter posts': 'Filter posts',
	'Filter results': 'Filter results',
	'Find out more about your students’ & colleagues’ experiences of stress with a survey and support them with a range of stress management tools.':
		'Find out more about your students’ & colleagues’ experiences of stress with a survey and support them with a range of stress management tools.',
	Finish: 'Finish',
	'Finish later': 'Finish later',
	From: 'From',
	'General Wellbeing': 'General Wellbeing',
	'Head of Year': 'Head of Year',
	Headline: 'Headline',
	'Headline for poll': 'Headline for poll',
	'Headline for question': 'Headline for question',
	'Here you’ll find more personal wellbeing content designed to help you and your students learn more about specific challenges and ways to overcome them.':
		'Here you’ll find more personal wellbeing content designed to help you and your students learn more about specific challenges and ways to overcome them.',
	'Here’s what happened while you were away': 'Here’s what happened while you were away',
	History: 'History',
	Home: 'Home',
	'How do you feel compared to last time you logged in?': 'How do you feel compared to last time you logged in?',
	'I Care / I Listen': 'I Care / I Listen Chat',
	"I confirm that I read and agree to Upstrive's": "I confirm that I read and agree to Upstrive's",
	'I do not agree': 'I do not agree',
	'I have an issue related to': 'I have an issue related to',
	'I will take action': 'I will take action',
	'If it’s a school-related topic such as getting organised, excelling in exams, or being productive, it’s right here for you!':
		'If it’s a school-related topic such as getting organised, excelling in exams, or being productive, it’s right here for you!',
	"If you don't agree to Terms and Conditions or Privacy Policy you are not going to be allowed to use the app!":
		"If you don't agree to Terms and Conditions or Privacy Policy you are not going to be allowed to use the app!",
	'Language is changed': 'Language is changed',
	"List of students who haven't logged in yet and it’s ": "List of students who haven't logged in yet and it’s ",
	'Log comment': 'Log comment',
	'Login info': 'Login info',
	'Manage running programs': 'Manage running programs',
	'Mark as seen': 'Mark as seen',
	'Media file is too big. Try smaller one': 'Media file is too big. Try smaller one',
	Menu: 'Menu',
	'Message has been sent to student!': 'Message has been sent to student!',
	'Monitoring group': 'Monitoring group',
	'Multiple classes': 'Multiple Classes',
	'My Classroom': 'My Classroom',
	'My message': 'My message',
	'Name a new group type here': 'Name a new group type here',
	'New Version is Available': 'New Version is Available',
	'New survey!': 'New survey!',
	Next: 'Next',
	No: 'No',
	'No comments': 'No comments',
	'No items found': 'No items found',
	'No one yet': 'No one yet',
	'No unseen replies left': 'No unseen replies left',
	'Number of answers': 'Number of answers',
	OK: 'OK',
	Off: 'Off',
	'Open link': 'Open link',
	'Open list': 'Open list',
	'Open survey results': 'Open survey results',
	Option: 'Option',
	'Other Teachers': 'Other Teachers',
	'Our range of social and emotional learning (SEL) content is all designed to help you & your students enhance self-awareness and develop greater self-management skills.':
		'Our range of social and emotional learning (SEL) content is all designed to help you & your students enhance self-awareness and develop greater self-management skills.',
	Overview: 'Overview',
	'Pending Content': 'Pending Content',
	'Personal Wellbeing': 'Personal Wellbeing',
	'Pick one option': 'Pick one option',
	'Pick one or more options': 'Pick one or more options',
	'Pick time:': 'Pick time:',
	'Pick up to 3': 'Pick up to 3',
	'Post individual': 'Post individual',
	Posts: 'Posts',
	Previous: 'Previous',
	Programs: 'Programs',
	'Ready-to-go': 'Ready-to-go',
	'Reminder for today': 'Reminder for today',
	Repaet: 'Repaet',
	'Retrieving data. Wait a few seconds and try to cut or copy again.':
		'Retrieving data. Wait a few seconds and try to cut or copy again.',
	'Run a journal': 'Run a journal',
	'Run a survey': 'Run a survey',
	'Run it': 'Run it',
	'Run program': 'Run program',
	Running: 'Running',
	SEL: 'SEL',
	'Save Survey': 'Save Survey',
	'Save your favorite group': 'Save your favorite group',
	'Search results': 'Search results',
	Section: 'Section',
	Sections: 'Sections',
	'Seen by': 'Seen by',
	'Seen by:': 'Seen by:',
	'Select a category': 'Select a category',
	'Select all': 'Select all',
	'Select days:': 'Select days:',
	'Select emotion that fits your mood': 'Select emotion that fits your mood',
	'Select from favorites': 'Select from favorites',
	'Select one item from the list': 'Select one item from the list',
	'Select one option': 'Select one option',
	'Select one or more items from the list': 'Select one or more items from the list',
	'Select specific classes to gather more information from': 'Select specific classes to gather more information from',
	'Select specific teachers you would like to get information from': 'Select specific teachers you would like to get information from',
	'Select specific year groups to gather more information from': 'Select specific year groups to gather more information from',
	'Select teacher': 'Select teacher',
	'Select what to display': 'Select what to display',
	'Select your favorite group': 'Select your favorite group',
	'Self-management': 'Self-management',
	'Send Private message to student': 'Send Private message to student',
	'Send message to all students': 'Send message to all students',
	'Send message to all teachers': 'Send message to all teachers',
	'Setup your filter': 'Setup your filter',
	'Show more programs': 'Show more programs',
	'Show more surveys': 'Show more surveys',
	solutions: 'Solutions',
	'Sorry, your school restricted access to this module': 'Sorry, your school restricted access to this module',
	'Start date:': 'Start date:',
	'Stop tracking live results for title': 'Stop tracking live results for {{title}}',
	'Stress Management': 'Stress Management',
	'Student chat': 'Student chat',
	'Students login data': 'Students login data',
	'Supportive Text': 'Supportive Text',
	'Survey has been created': 'Survey has been created',
	'Survey needs to be reviewed by the school admin': 'Survey needs to be reviewed by the school admin',
	'Survey results': 'Survey results',
	Surveys: 'Surveys',
	'Take it later': 'Take it later',
	'Take it now': 'Take it now',
	Target: 'Target',
	'Target group is not selected': 'Target group is not selected',
	'Target group:': 'Target group:',
	'Tasks archive': 'Tasks archive',
	'Teacher only': 'Teacher only',
	'Terms and Conditions': 'Terms and Conditions',
	'Terms and Conditions and Privacy Policy': 'Terms and Conditions and Privacy Policy',
	'The maximum image size for download is 1400px x 1400px': 'The maximum image size for download is 1400px x 1400px',
	'This is a great place to start if you’re looking for some general wellbeing content before diving into some of the more specific topics.':
		'This is a great place to start if you’re looking for some general wellbeing content before diving into some of the more specific topics.',
	'This tool helps you to control all running programs and track their influence to students':
		'This tool helps you to control all running programs and track their influence to students',
	'This tool helps you to run a survey for your students': 'This tool helps you to run a survey for your students',
	"This tool helps you to schedule whole program once so you don't need to check it all the time":
		"This tool helps you to schedule whole program once so you don't need to check it all the time",
	Time: 'Time',
	To: 'To',
	'Track Counseling': 'Track Counseling',
	'Type 1st thing here': 'Type 1st thing here',
	'Type a question': 'Type a question',
	'Type a survey title': 'Type a survey title',
	'Type here': 'Type here',
	'Type name for your note here': 'Type name for your note here',
	'Type name in the field below': 'Type name in the field below',
	Uncomfortable: 'Uncomfortable',
	Unseen: 'Unseen',
	'Upcoming Content': 'Upcoming Content',
	'Updated monitoring group!': 'Updated monitoring group!',
	'Use Template': 'Use Template',
	'View Details': 'View Details',
	'Wait for new tasks...': 'Wait for new tasks...',
	'We hope you’ve had a good day!': 'We hope you’ve had a good day!',
	'Welcome back': 'Welcome back',
	'Wellbeing Tracker': 'Wellbeing Tracker',
	'wellbeing tracker': 'Wellbeing Tracker',
	"What's the main reason?": "What's the main reason?",
	'Write option': 'Write option',
	'Write personal message for the student here': 'Write personal message for the student here',
	'Write your thoughts here': 'Write your thoughts here',
	Yes: 'Yes',
	'You are not allowed to create content': 'You are not allowed to create content',
	'You are not in charge of this student so your messages will be forwarded to school admin or counselor':
		'You are not in charge of this student so your messages will be forwarded to school admin or counselor',
	'You can press yellow button and create a new Wellbeing profile': 'You can press yellow button and create a new Wellbeing profile',
	"You don't have any incoming content": "You don't have any incoming content",
	'You have a survey about': 'You have a survey about',
	'You have completed all your tasks': 'You have completed all your tasks',
	'You have no chats': 'You have no chats',
	'You have no data about your classroom': 'You have no data about your classroom',
	'You have no live content': 'You have no live content',
	'You have to select at least one emotion': 'You have to select at least one emotion',
	'You will be logged out': 'You will be logged out',
	'Your Classrooms': 'Your Classrooms',
	'Your counseling list is empty, start with adding a new counseling profile for one of your students':
		'Your counseling list is empty, start with adding a new counseling profile for one of your students.',
	'Your list is empty': 'Your list is empty',
	'Your results are saved!': 'Your results are saved!',
	'Your running program list is empty, start by adding one from the list of programs':
		'Your running program list is empty, start by adding one from the list of programs',
	'Your tasks': 'Your tasks',
	'add a survey description': 'add a survey description',
	addPhotoVideo: 'Add Photo/Video',
	anonymousPoll: 'Anonymous poll',
	anonymousQuestion: 'Anonymous question',
	anonymous_journal: 'Anonymous journal',
	anonymous_poll: 'Anonymous poll',
	anonymous_question: 'Anonymous question',
	anonymous_survey: 'Anonymous survey',
	atLeast8Caracters: 'At least 8 caracters',
	closePopup: 'Close pop-up',
	completed: 'completed',
	connectWithTheConselor: 'Connect with counselor',
	'copy message': 'copy message',
	'enter a section description here': 'enter a section description here',
	'enter a section title here': 'Enter a section title here',
	experienceIt: '% experience it',
	expertRecommendation: 'Expert recommendation',
	exploreThingsYouCanDoRightNow: 'Explore things you can do right now',
	feelingBetterQuestion: 'Nice to hear that the situation has improved. Do you have ideas on how you could possibly make it even better?',
	feelingMuchWorseQuestion: "We're sorry to hear that you're feeling worse. Which of the suggestions below can help you right now?\"",
	feelingSameQuestion: 'What can you do right now to make your situation better?',
	goodToHearThingsAreBetter: 'Good to hear things are better.',
	greatToHearThingsAreMuchBetter: 'Great to hear things are much better.',
	here: 'here',
	howToDealWithThisProblem: 'How to deal with this problem?',
	iHaveAnIssueRelatedTo: 'I have an issue related to...',
	instructionVideo: 'Instruction Video',
	loadingMoreFeeds: 'Loading more feeds',
	majorUpdate: 'Major Update',
	mandatory: 'mandatory',
	mobileAndDesktopStudentVersion: 'Mobile and Desktop - Student Version.mp4',
	next: 'Next',
	notReally: 'Not really',
	oopsThereSeemsToBeAnIssueWithConnection: 'Oops there seems to be an issue with connection',
	openJournal: 'Open journal',
	openQuestion: 'Open question',
	pleaseTryAgainInAFewMinutestOrContactUpstriveSupport: 'Please try again in a few minutes or contact Upstrive Support',
	privateNotes: 'Private notes',
	quickTips: 'Quick tips',
	read: 'read',
	rememberMe: 'Remember me',
	remove: 'Remove',
	returnBack: 'Return back',
	sameGreatAppFreshNewLook: 'Same great app fresh new look!',
	seeHowOtherStudentsHandleThis: 'See how other students handle this',
	sorryToHearThatThingsAreMuchWorse: 'Sorry to hear that things are much worse.',
	sorryToHearThatThingsAreWorse: 'Sorry to hear that things are worse.',
	sorryYourSchoolRestrictedAccessToThisModule: 'Sorry your school restricted access to this module.',
	sos: 'SOS',
	thingsYouCanDoRightNow: 'Things you can do right now',
	typeOneThingHere: 'Type one thing here',
	typeYourAnswerHere: 'Type your answer here',
	voluntary: 'voluntary',
	wasThisSolutionHelpful: 'Was this solution helpful?',
	weHopeYouHaveAGoodDay: 'We hope you have a good day!',
	whatAre3ThingsThatAreABitChallengingAtTheMoment: 'What are 3 things that are a bit challenging at the moment?',
	whatsJustOneThingYouWillDoDifferentlyTomorrow: 'What is just one thing you will do differently tomorrow? ',
	whatsNew: "What's new?",
	yesThanks: 'Yes Thanks!',
	filterResults: 'Filter results',
	'Add student': 'Add student',
	'Select surveys': 'Select surveys',
	'Type of class to create': 'Type of class to create',
	Regular: 'Regular',
	Primary: 'Primary',
	'Primary class': 'Primary class',
	Avatar: 'Avatar',
	'Not Setuped': 'Not Setuped',
	'Transfer to another class': 'Transfer to another class',
	'You must select class': 'You must select class',
	'Current class': 'Current class',
	'Select new class': 'Select new class',
	'Select class': 'Select class',
	'Change avatar': 'Change avatar',
	'Edit Student': 'Edit Student',
	'Step 1/2': 'Step 1/2',
	'Step 2/2': 'Step 2/2',
	'Choose Gender': 'Choose Gender',
	'Choose animal': 'Choose animal',
	'Create Student': 'Create Student',
	Available: 'Available',
	Taken: 'Taken',
	'If you will choose already taken character it will auto unpin from existing child':
		'If you will choose already taken character it will auto unpin from existing child',
	'Choose later': 'Choose later',
	'Save with selected avatar': 'Save with selected avatar',
	'Are you sure you want to delete class?': 'Are you sure you want to delete class?',
	'All Student in this class will be deleted': 'All Student in this class will be deleted',
	'No, cancel': 'No, cancel',
	'Yes, Delete': 'Yes, Delete',
	'Class successfully deleted': 'Class successfully deleted',
	Success: 'Success',
	'Transfer to another class successfully finished': 'Transfer to another class successfully finished',
	'Old class': 'Old class',
	Display: 'Display',
	'Your Primary Classes': 'Your Primary Classes',
	'The new password has been sent!': 'The new password has been sent!',
	'Password delivery failed!': 'Password delivery failed!',
	'File has been removed': 'File has been removed',
	'Preset Ranges': 'Preset Ranges',
	'Last Month': 'Last Month',
	'Last Quarter': 'Last Quarter',
	'Last Year': 'Last Year',
	'Data is saved': 'Data is saved',
	'Allocation of answers from students who answered on “How are you Today?”':
		'Allocation of answers from students who answered on “How are you Today?”',
	'New password has already been generated today for this user. Do you want to generate it again?':
		'New password has already been generated today for this user. Do you want to generate it again?',
	'Not logged in': 'Not logged in',
	'Logged in': 'Logged in',
	'Current Weekly Engagement Statistics': 'Current Weekly Engagement Statistics',
	'Student logins': 'Student logins',
	'Student check in': 'Student check in',
	'Student engagement with content': 'Student engagement with content',
	'Teacher logins': 'Teacher logins',
	'This percentage reflects the number of teacher logins for this week':
		'This percentage reflects the number of teacher logins so far this week. ',
	'This percentage reflects  students interacting with app content beyond check-ins.':
		'This percentage reflects  students interacting with app content beyond check-ins so far this week.',
	'This percentage reflects students who have both logged in and completed a check-in for the week':
		'This percentage reflects students who have both logged in and completed a check-in so far this week.',
	'This statistic reflects the number of student logins for this week':
		'This statistic reflects the number of student logins so far this week',
	'Use this pre-set Sample Group to monitor the uncomfortable emotions your students might log.':
		'Use this pre-set Sample Group to monitor the uncomfortable emotions your students might log.',
	'Sample Group': 'Sample Group',
	'Comfortable Emotions': 'Comfortable Emotions',
	'Uncomfortable Emotions': 'Uncomfortable Emotions',
	clickTheButtonsOnTheRightToAccessSpecificAnalyticsForThoseTimeframes:
		'Click the buttons on the right to access specific analytics for those timeframes.',
	effortlesslyMonitorYourStudentsEmotionalExperiencesByCreatingYourOwnCustomisedGroupsHere:
		"Effortlessly monitor your students' emotional experiences by creating your own customised groups here.",
	'% of students responding to "How are you today? at least once a week.':
		'% of students responding to "How are you today? at least once a week.',
	'No. of comments left in response to "How are you today?': 'No. of comments left in response to "How are you today?',
	'Emotion distribution for selected period': 'Emotion distribution for selected period',
	'No. of comments left in response to "How are you today?"': 'No. of comments left in response to "How are you today?"',
	'No. of comments responding to, “How are you today?”': 'No. of comments responding to, “How are you today?”',
	'More details': 'More details',
	'Overall engagement': 'Overall engagement',
	'Engagement with emotions': 'Engagement with emotions',
	'Total emotions submitted': 'Total emotions ',
	'Total number of emotions submitted in the time frame': 'Total number of emotions submitted in the time frame',
	'Relative change to the same selected previous period': 'Relative change to the same selected previous period',
	'Percent of students that submitted an emotion in the selected time frame':
		'Percent of students that submitted an emotion in the selected time frame',
	'Percentage of students that used Upstrive in the period (any function, just opened it)':
		'Percentage of students that used Upstrive in the period (any function, just opened it)',
	'Percentage of students who have experienced these emotions in selected period':
		'Percentage of students who have experienced these emotions in selected period',
	'Percentage of teachers who have registered to the app': 'Percentage of teachers who have registered to the app',
	'Percentage of students who have registered to the app': 'Percentage of students who have registered to the app',
	'Percentage of students engaging with content this week': 'Percentage of students engaging with content this week',
	'Percentage of student check-ins this week': 'Percentage of student check-ins this week',
	'Percentage of student logins this week': 'Percentage of student logins this week',
	'Percentage of teacher logins this week': 'Percentage of teacher logins this week',
	'Time period': 'Time period',
	'Time frame': 'Time frame',
	'Add a new trend': 'Add a new trend',
	'Engagement with content': 'Engagement with content',
	'Percentage of students that submitted an emotion in the selected time frame':
		'Percentage of students that submitted an emotion in the selected time frame',
	'Percentage of students engaging with content in the selected time frame':
		'Percentage of students engaging with content in the selected time frame',
	'Remove from trends': 'Remove from trends',
	'Student Engagement on Emotion': 'Student Engagement on Emotion',
	'No. of emotions': 'No. of emotions',
	'Create & Manage Reports': 'Create & Manage Reports',
	'Welcome! Create your first report here': 'Welcome! Create your first report here',
	'Create report': 'Create report',
	'Latest reports': 'Latest reports',
	'View More': 'View More',
	'Return to Analytics': 'Return to Analytics',
	'Create Reports': 'Create Reports',
	'Report format': 'Report format',
	Raw: 'Raw',
	'Output fields': 'Output fields',
	'Target users': 'Target users',
	'Select report dates': 'Select report dates',
	'Remember to set up filters to get started!': 'Remember to set up filters to get started!',
	'Last step': 'Last step',
	'Setup report name as your last step': 'Setup report name as your last step',
	'Report name': 'Report name',
	'Enter file name': 'Enter file name',
	'You will be notified once your report is ready': 'You will be notified once your report is ready',
	'Report-2024-01-29': 'Report-2024-01-29',
	'Your opinion matters to us!': 'Your opinion matters to us!',
	'Your feedback helps us to improve. If you find our app helpful, please consider rating it.':
		'Your feedback helps us to improve. If you find our app helpful, please consider rating it.',
	'Share your feedback': 'Share your feedback',
	'Leave your feedback': 'Leave your feedback',
	'Type your feedback here': 'Type your feedback here',

	'Select time range': 'Select time range',
	'Number of student who answered "How are you today?" on this date': 'Number of student who answered "How are you today?" on this date',
	'From creating confidence to goal setting, you’ll find a great range of skill-based surveys, programs, and journals here.':
		'From creating confidence to goal setting, you’ll find a great range of skill-based surveys, programs, and journals here.',
	'Track trends': 'Track trends',

	'Content has been removed from trends': 'Content has been removed from trends',
	'In process': 'In process',
	'End date:': 'End date:',

	'Total response percentage': 'Total response percentage',
	'You are going to generate new password for this student?': 'You are going to generate new password for this student?',
	'New password has already been generated today for this user! Do you want to generate it again?':
		'New password has already been generated today for this user! Do you want to generate it again?',
	'New class': 'New class',
	'Day review': 'Day review',
	'Seen 6 days ago': 'Seen {{time}} ago',
	'Left 6 days ago': 'Left {{time}} ago',
	'Thank you for helping us to make Upstrive better.': 'Thank you for helping us to make Upstrive better.',

	month: 'month',
	months: 'months',
	hour: 'hour',
	hours: 'hours',
	minute: 'minute',
	minutes: 'minutes',
	seconds: 'seconds',

	'New password is generated': 'New password is generated',
	'Thank you!': 'Thank you!',
	'Tell us how we can improve Upstrive in the box below': 'Tell us how we can improve Upstrive in the box below',
	reasons: 'Reasons',
	'Reminder has been deleted!': 'Reminder has been deleted!',
	'Program has been deleted!': 'Program has been deleted!',
	'No reports have been created yet': 'No reports have been created yet',
	Grade: 'Grade',
	'Feedback date': 'Feedback date',
	"Student's feedback": "Student's feedback",
	"Student's comment": "Student's comment",
	Reasons: 'Reasons',
	"Teachers' action": "Teachers' action",
	'Number of students that left a comment combined with uncomfortable emotion':
		'Number of students that left a comment combined with uncomfortable emotion',
	'7d': '7d',
	'1m': '1m',
	'3m': '3m',
	'1y': '1y',
	'Percentage of students that submitted an emotion this week': 'Percentage of students that submitted an emotion this week',
	'Write your personal notes here (only you can see this)': 'Write your personal notes here (only you can see this)',
	'Percentage of teachers in your group who have registered in Upstrive':
		'Percentage of teachers in your group who have registered in Upstrive',
	'Percentage of students in your group who have registered in Upstrive':
		'Percentage of students in your group who have registered in Upstrive',
	Registration: 'Registration',
	'General Engagement': 'General Engagement',
	'Thank you!': 'Thank you!',
	'Tell us how we can improve Upstrive in the box below': 'Tell us how we can improve Upstrive in the box below',
	'You must select at least one option': 'You must select at least one option!',

	On: 'On',
	Class: 'Class',
	Classes: 'Classes',
	SOS: 'SOS',
	'Template is updated': 'Template is updated',
	'Options must differ ': 'Options must differ ',
	Open: 'Open',
	'Choose the answer': 'Choose the answer',
	'Not selected': 'Not selected',
	Reset: 'Reset',
	'What are 3 things that are a bit challenging at the moment?': 'What are 3 things that are a bit challenging at the moment?',
	'What’s just one thing you will do differently tomorrow?': 'What’s just one thing you will do differently tomorrow?',
	'all students': 'all students',
	'Can`t create student without create class': 'Can`t create student without create class',
	Analytics: 'Analytics',
	'Full Desktop version': 'Full Desktop version',
	'Full Mobile version': 'Full Mobile version',
	Gender: 'Gender',
	"Student's emotion": "Student's emotion",
	Frequency: 'Frequency',
};
export default TRANSLATION_EN;
