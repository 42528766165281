import React, { useEffect, useState, useRef } from 'react';
import ReactModal from 'react-modal';
import '../../styles/modals/FeedBackModal.css';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as DropdownTransparent } from '../../assets/svg/DropdownTransparent.svg';
import { ReactComponent as SearchBlue } from '../../assets/svg/SearchBlue.svg';
import { emotions_color, emotions_heads } from '../../constants/Constants';
import { useTranslation } from 'react-i18next';
import { convertDateToFormatddMMyyy, format_day, notify } from '../../utils/util';
import { useUser } from '../../auth';
import {
	getArchive,
	getDayArchive,
	makeAssignment,
	getTeachersForAssignment,
	sendSeenFeedbackComment,
	takeActionFeedbackComment,
} from '../../server/server';
import TextareaAutosize from 'react-textarea-autosize';
import { FaAngleLeft, FaCheckSquare, FaHistory, FaSpinner, FaTimes } from 'react-icons/fa';
import { Toaster } from 'react-hot-toast';
import i18n from '../../i18n';

function WellBeingAsignModal({ modalIsOpen, closeModal, student, note, assignMessage = '' }) {
	const { t, locale } = useTranslation();
	const [showDropdown, setShowDropdown] = useState(false);
	const [teachers, setTeachers] = useState([]);
	const [loadedTeachers, setLoadedTeachers] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [message, setMessage] = useState(assignMessage);
	const [selecetedTeacher, setSelecetedTeacher] = useState({});

	useEffect(() => {
		loadTeachers();
	}, []);

	useEffect(() => {
		//set teachers with search parameter
		if (searchTerm.length > 0) {
			setTeachers(
				loadedTeachers.filter((teacher) =>
					`${teacher?.first_name} ${teacher?.last_name}`.toLowerCase().includes(searchTerm?.toLowerCase())
				)
			);
		} else setTeachers(loadedTeachers);
	}, [searchTerm]);
	useEffect(() => {
		console.log('student ', note);
	}, [note]);

	const loadTeachers = async () => {
		const resp = await getTeachersForAssignment();
		if (resp.status === 200) {
			setLoadedTeachers(resp?.data);
			setTeachers(resp?.data);
		}
	};

	const assignAction = async () => {
		if (message == '' || selecetedTeacher.first_name == undefined) {
			notify(t('youMustFillData'));
			return;
		}
		let data = {
			message: message,
			teacher: selecetedTeacher,
		};
		if (note?.feedback_id) data['feedback_id'] = note.feedback_id;
		else data['student_id'] = student?.id;
		const resp = await makeAssignment(data);
		if (resp.status === 200) {
			notify(t('assignActionSuccess'));
			closeModal(true);
		} else {
			notify(t('assignActionFailed'));
		}
	};

	React.useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
			if (assignMessage) setMessage(assignMessage);
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);

	return (
		<ReactModal
			// ariaHideApp={false}
			transparent
			closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				setMessage('');
				setSelecetedTeacher({});
			}}
			onRequestClose={closeModal}
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '95vh',
					border: 'none',
					borderRadius: 10,
					boxShadow: 'none',
					position: 'relative',
					overflow: 'unset',
					inset: 20,
				},
			}}
		>
			<div
				style={{
					position: 'absolute',
					top: -30,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className='feedback-modal-icon' onClick={() => closeModal()}>
					<CloseModalIcon />
				</div>
			</div>
			<div className='feedback-student-modal max-h-[95vh-190px] overflow-auto grey-small-scroll'>
				<div
					className='feedback-modal-header'
					style={{ display: 'flex', flexDirection: 'row', background: `${emotions_color[note?.emotion]}85` }}
				>
					<div>
						<img src={emotions_heads[!!note?.emotion ? note?.emotion : 'default']} width={70} height={70} alt='emotion' />
					</div>

					<div className='feedback-header-title'>
						<p>
							{student?.first_name} {student?.last_name}
						</p>
						{!!note?.created_date && <span>{format_day(note?.created_date, false, i18n.language)}</span>}
					</div>
				</div>
				{/* background: #fafaff; */}
				<div className='rounded-[10px] py-5 px-[30px]  bg-whiteContrast '>
					{!!note?.comment && (
						<div className='feedback-modal-messege-body'>
							<p>{t('comment')} </p>
							<span>{note.message}</span>
						</div>
					)}

					<div className='flex flex-col mt-3  '>
						<p className='text-[#5E639B] font-semibold text-[18px] leading-[22px]'>{t('assignedTo')}</p>
						<div
							className='flex flex-col mb-[10px] relative w-full mt-4'
							onMouseLeave={(e) => {
								e.preventDefault();
								setShowDropdown(false);
							}}
						>
							<div
								className='feedback-info-select-button'
								onClick={(e) => {
									e.preventDefault();
									if (searchTerm != '') setSearchTerm('');
									setShowDropdown(!showDropdown);
								}}
								style={{
									justifyContent: 'center',
									zIndex: 3,
									backgroundColor: showDropdown ? '#5E639B' : '#ecebf8',
								}}
							>
								<p style={{ color: showDropdown ? 'white' : '#5E639B' }}>
									{selecetedTeacher.first_name != undefined
										? `${selecetedTeacher.first_name} ${selecetedTeacher.last_name}`
										: t('selectTeacher')}
								</p>

								<div style={{ transform: showDropdown ? 'scaleY(-1)' : 'none', marginLeft: 10 }}>
									<DropdownTransparent style={{ color: showDropdown ? 'white' : '#5E639B' }} />
								</div>
							</div>

							{showDropdown && (
								<div className='absolute top-0 mb-2 pr-4 flex flex-col items-end w-full bg-lightBG rounded-[28px] cursor-pointer z-[2] '>
									<p style={{ height: 56 }}></p>
									<div
										className='blue-small-scroll'
										style={{ width: '100%', overflowY: 'auto', maxHeight: '42vh', marginBottom: 20 }}
									>
										<div className='flex flex-col px-3 w-full '>
											<div className='feedback-management-search-container '>
												<input
													type='text'
													value={searchTerm}
													onChange={(e) => setSearchTerm(e.target.value)}
													autoComplete='off'
													placeholder={t('search')}
												/>
												<SearchBlue />
											</div>
											{teachers?.map((teacher, index) => (
												<div
													onClick={(e) => {
														setSelecetedTeacher(teacher);
														setShowDropdown(false);
														setSearchTerm('');
													}}
													key={index}
													className='feedback-teacher-item'
												>
													<p style={{ textAlign: 'left' }}>
														{teacher?.first_name} {teacher?.last_name}
													</p>
													<p>{teacher?.role_coverage?.target_text}</p>
												</div>
											))}
										</div>
									</div>
								</div>
							)}
						</div>
						<div className={`feedback-assigned-multiline  ${showDropdown ? 'invisible' : ''}`}>
							<div className='feedback-textarea'>
								<TextareaAutosize
									placeholder={t('typeAnAction')}
									minRows={6}
									value={message}
									style={{ resize: 'none' }}
									autoFocus
									onChange={(e) => {
										e.preventDefault();
										setMessage(e.target.value);
									}}
								/>
							</div>
							<div className='textarea-button' onClick={() => assignAction()}>
								<p>{t('submit')} </p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Toaster position='top-right' reverseOrder={false} toastOptions={{ duration: 3000 }} />
		</ReactModal>
	);
}

export default WellBeingAsignModal;
