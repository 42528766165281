import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import '../../styles/modals/ConfirmationFeedDeleteModal.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import { ReactComponent as CsvIcon } from '../../assets/svg/ReportIcons/CsvIcon.svg';
import { ReactComponent as ExcelIcon } from '../../assets/svg/ReportIcons/ExcelIcon.svg';
import { ReactComponent as PdfIcon } from '../../assets/svg/ReportIcons/PdfIcon.svg';
import Loading from '../Loading';
import Switcher from '../content/Switcher';
import { Switch } from '@mui/material';
import ScheduleSwitcher from '../content/ScheduleSwitcher';
import { formatDayCode } from '../../utils/util';
import ReactTooltip from 'react-tooltip';
import { useUser } from '../../auth';
import { generateCustomReport } from '../../server/server';
import i18n from '../../i18n';

function CreateReportModal({ modalIsOpen = true, afterOpenModal = () => {}, closeModal = () => {}, data = {}, onSuccess = () => {} }) {
	const { t, locale } = useTranslation();
	const [step, setStep] = useState('init');
	const [progress, setProgress] = useState(false);
	const pdfEnabled = data.reportFormat === 'pdf';
	const csvEnabled = data.reportFormat === 'raw';
	const [reportName, setReportName] = useState(
		pdfEnabled ? `Custom-Analytics-Report-${formatDayCode(new Date())}` : `Report-${formatDayCode(new Date())}`
	);
	const { user } = useUser();

	useEffect(() => {
		if (modalIsOpen) {
			Modal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);

	const nextStep = () => {
		if (step === 'init') createReport();
		else onSuccess();
	};

	const createReport = async () => {
		setProgress(true);
		const reportData = {
			...data,
			user_id: user.id,
			created_date: Date.now(),
			formats: pdfEnabled ? ['pdf'] : ['csv'],
			title: reportName,
			institution_id: user.institution_id,
			locale: i18n.language,
		};
		try {
			generateCustomReport(reportData);
		} catch (e) {}
		setTimeout(() => {
			setProgress(false);
			setStep('last');
		}, 1500);
	};

	return (
		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			contentLabel='Example Modal'
			onAfterClose={() => {
				setStep('init');
				setReportName(`Report-${formatDayCode(new Date())}`);
			}}
			style={{
				overlay: {
					zIndex: 12,
				},
				content: {
					width: 490,
					padding: 0,
					opacity: 1,
					minWidth: 490,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '90%',
					border: 'none',
					margin: 'auto',
					boxShadow: 'none',
					overflow: 'hidden',
					transition: 'all ease-in-out 230ms',
				},
			}}
		>
			<div className='absolute top-0 w-full flex items-center justify-center '>
				<div
					className='w-[60px] h-[60px] bg-deepPurple rounded-full cursor-pointer flex items-center justify-center z-[2] hover:bg-darkenPurple '
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						closeModal();
					}}
				>
					<CloseModalIcon />
				</div>
			</div>
			<div className='bg-whiteContrast rounded-xl flex flex-col  items-center justify-between p-10 mt-[30px]'>
				{step === 'init' && (
					<div className='w-full'>
						<div className='text-[#5E639B] font-bold text-[30px]'>{t('Last step')}</div>
						<div className='text-[#413F5E] font-medium text-[16px]'>{t('Setup report name as your last step')}</div>

						<div className='mt-[20px]'>
							<p className='text-[#5E639B] text-[18px] font-semibold mb-[20px]'>{t('Report name')}</p>
							<input
								type='text'
								placeholder={t('Enter file name')}
								className='w-full bg-[transparent] border-b border-b-[#BEBECE] pb-[14px] font-medium'
								value={reportName}
								onChange={(e) => setReportName(e.target.value)}
							/>
						</div>
					</div>
				)}
				{step !== 'init' && (
					<div>
						<img
							src={require('../../assets/images/CreateReportModalImage.png')}
							alt='Create report mascot'
							style={{ width: 453, marginRight: 10, height: 215 }}
						/>
						<p className='text-center font-bold text-[30px] text-[#5E639B]'>
							{t('You will be notified once your report is ready')}
						</p>
					</div>
				)}
				<div className='flex flex-row justify-center  items-center w-full mt-10'>
					<button
						className={`bg-deepPurple pointer flex flex-row items-center justify-center  transition-all duration-[230ms] ease-in-out  rounded-full  px-[25px] min-w-[250px] h-[50px] text-white ${
							progress ? '' : 'hover:bg-[#FDDF6D] hover:text-deepPurple'
						}`}
						onClick={nextStep}
						disabled={progress}
					>
						{progress ? (
							<Loading />
						) : (
							<p className=' text-[18px] leading-[23px] font-semibold'>{step !== 'init' ? t('close') : t('Create report')}</p>
						)}
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default CreateReportModal;
