import React, { useState, useEffect } from 'react';
import magnifier from '../../assets/svg/magnifier.svg';
import { ReactComponent as ChatIcon } from '../../assets/svg/ChatIcon.svg';
import { FaCaretRight, FaSpinner } from 'react-icons/fa';
import '../../styles/chat/Chat.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import Conversation from './Conversation';
import { useSocket } from '../../context/socket';
import useWindowFocus from 'use-window-focus';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getMessageList, getMessages, sendMessage, seenMessage } from '../../server/server';
import { format_day, translateTargetText } from '../../utils/util';
import { useUser } from '../../auth';
import { ReactComponent as SearchIcon } from '../../assets/svg/SearchIcon.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/svg/NotFoundIcon.svg';
import ReactTooltip from 'react-tooltip';

function Chat() {
	const [messageList, setMessageList] = useState([]);
	const { user, setUser } = useUser();
	const { t, locale } = useTranslation();
	const { socketStates, changeSocketState } = useSocket();
	const [conversation, setConversation] = useState({});
	const [selectedConversation, setSelectedConversation] = useState({
		last_message: { seen: [] },
	});
	const [searchTerm, setSearchTerm] = useState('');
	const [hasMore, setHasMore] = useState(false);
	const [loading, setLoading] = useState(true);
	const [resultsNum, setResultsNum] = useState(0);
	const [isExpanded, setIsExpanded] = useState(window.innerWidth < 1200);
	const getMessagesList = async () => {
		const response = await getMessageList({
			number_of_loaded: resultsNum,
			search_text: searchTerm,
		});
		const list = response?.data?.list;
		const size = response?.data?.size;
		if (response.status === 200) {
			if (resultsNum === 0) {
				setLoading(true);
				setMessageList(list);
			} else {
				setMessageList((prev) => [...prev, ...list]);
			}
			setLoading(false);
			console.log('geag', size, messageList);
			setHasMore(size > messageList.length);
		} else {
			setMessageList([]);
			setResultsNum(0);
		}
	};

	useEffect(() => {
		getMessagesList();
		setLoading(true);
		console.log('resultsNum change');
	}, [resultsNum]);
	useEffect(() => {
		setResultsNum(0);
		setMessageList([]);
		getMessagesList();
		setLoading(true);
		console.log('search change');
	}, [searchTerm]);
	// useEffect(() => {
	//     getMessagesList();
	//     setLoading(true);
	// }, [socketStates.lastMessage]);
	const getConversationStatus = (conversation) => {
		const receivedMessage = user.id !== conversation.last_message?.user_id;
		const isNewMessage = !conversation?.last_message?.seen?.includes(user.id) && conversation.last_message.user_id != user.id;
		const status = receivedMessage ? (isNewMessage ? i18n.t('newMessage') : i18n.t('received')) : i18n.t('delivered');
		return status;
	};
	// useEffect(() => {
	//     return () => {
	//         changeSocketState({ openedConversation: null });
	//     };
	// }, []);
	const checkUnseenConversations = (conversation_id) => {
		const index = socketStates?.unseenConversations?.findIndex((e) => e === conversation_id);
		let _socket = { ...socketStates };
		_socket['openedConversation'] = conversation_id;
		if (index > -1) {
			const ids = socketStates?.unseenConversations?.filter((e) => e !== conversation_id);
			console.log('Changing unseen conversations: ', ids);
			_socket['unseenConversations'] = ids;
		}
		changeSocketState(_socket);
	};
	const handleSeen = async (conversation_id, last_message) => {
		if (!last_message?.seen?.includes(user.id) && last_message.user_id != user.id) {
			const response = await seenMessage({
				message_body: last_message?.message_body,
				conversation_id: conversation_id,
				user_id: last_message?.user_id,
				seen: [...last_message?.seen, user.id],
				created_date: last_message?.created_date,
				id: last_message?.message_id,
			});
			if (response.status === 200) {
				console.log('ok');
				getMessagesList();
			}
		}
	};
	useEffect(() => {
		if (selectedConversation?.conversation_id) {
			handleSeen(selectedConversation?.conversation_id, selectedConversation?.last_message);
			changeSocketState({
				openedConversation: selectedConversation?.conversation_id,
			});
			checkUnseenConversations(selectedConversation?.conversation_id);
		}
	}, [selectedConversation]);

	const handleItemSelect = (item) => {
		setSelectedConversation(item);
		if (window.innerWidth < 1200 && isExpanded) {
			setIsExpanded(false);
		}
	};

	const handleExpand = () => {
		if (window.innerWidth < 1200 && isExpanded === false) {
			setIsExpanded(true);
		}
	};
	const initials = (string) => {
		const initial = string
			.split(' ')
			.map((i) => i.charAt(0).toUpperCase())
			.slice(0, 2);
		return initial.join('');
	};

	return (
		<div className='content' style={{ lineHeight: '14px', overflow: 'hidden' }}>
			<div className={`chat-container ${i18n.language == 'ar' ? 'ar-lang' : ''}`}>
				<div
					className={`chat-list-container chat-list-container-active ${
						selectedConversation?.conversation_id
							? i18n.language == 'ar'
								? 'shadow-chat-ar ml-[20px]'
								: 'shadow-chat'
							: 'shadow-chat-none'
					}`}
					onClick={() => handleExpand()}
				>
					<div className='stroke-deselectPurple hover:stroke-deepPurple flex flex-row items-center border-deselectPurple border py-3 px-4 w-full rounded-full'>
						<input
							type='text'
							placeholder={t('search')}
							className='w-full h-full text-mudPurple font-semibold text-sm'
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
						<SearchIcon className='w-[19px] h-[19px]' stroke='#5E639B' />
					</div>
					{messageList?.length > 0 ? (
						<div className='chat-list mt-5 grey-small-scroll' id='scroll-inf-target'>
							<InfiniteScroll
								dataLength={messageList?.length}
								next={() => setResultsNum(messageList?.length)}
								hasMore={hasMore}
								scrollableTarget='scroll-inf-target'
								style={{ overflowX: 'hidden', overflowY: 'auto' }}
							>
								{messageList.map((message, index) => {
									const rtl = `pl-0  ${
										selectedConversation.conversation_id === message.conversation_id ? 'selected-chat' : ''
									}`;
									const ltr = ` ${
										selectedConversation.conversation_id === message.conversation_id ? 'selected-chat' : ''
									}`;
									return (
										<div
											className={`chat-item ${i18n.language == 'ar' ? rtl : ltr}`}
											key={index}
											onClick={() =>
												handleItemSelect({
													conversation_id: message.conversation_id,
													members: message.members,
													label_for_teacher: message.label_for_teacher,
													additional_info: message.additional_info,
													color: message.color,
													names: message.names,
													last_message: message.last_message,
													conversation_type: message.conversation_type,
												})
											}
										>
											<div className={`chat-icon ml-[10px]`}>
												<div
													className='chat-icon-circle'
													style={{
														backgroundColor:
															message.conversation_type === 'counselor'
																? '#F47D3A'
																: message.conversation_type === 'sos'
																? '#6693E4'
																: '#FDDF6D',
													}}
													data-tip={
														message.conversation_type === 'counselor'
															? t('counselor')
															: message.conversation_type === 'sos'
															? t('SOS')
															: ''
													}
													data-for={`chatStudents`}
												>
													<ChatIcon
														color={
															message.conversation_type === 'counselor' || message.conversation_type === 'sos'
																? '#ffffff'
																: '#5E639B'
														}
													/>
													{(message.conversation_type === 'counselor' || message.conversation_type === 'sos') && (
														<ReactTooltip
															id={`chatStudents`}
															arrowColor='#5E639B'
															effect='solid'
															backgroundColor='#5E639B'
															className='wellbeing-tooltip'
															classNameArrow='tooltip-arrow'
														/>
													)}
												</div>
											</div>
											<div className={`chat-details`}>
												<div
													className={`chat-name flex flex-row ${
														i18n.language == 'ar'
															? selectedConversation.conversation_id === message.conversation_id
																? 'flex justify-between'
																: 'flex justify-between ml-5'
															: ''
													} `}
												>
													<span className='flex-1'>{message?.label_for_teacher}</span>
													<span className='conversation-addition'>
														{translateTargetText(message.additional_info)}
													</span>
												</div>
												<div className={`chat-status chat-status-active`}>
													<div className='message-status-text'>
														{!message?.last_message?.seen?.includes(user.id) &&
															message.last_message.user_id != user.id && (
																<div className='new-conversation-icon'>
																	<FaCaretRight />
																</div>
															)}

														<div className='conversation-status'>{getConversationStatus(message)}</div>
													</div>
													<span
														className={`chat-status-item ${
															i18n.language == 'ar'
																? selectedConversation.conversation_id === message.conversation_id
																	? 'text-left pl-0'
																	: 'text-left pl-5'
																: ''
														}`}
													>
														{format_day(message.last_message.created_date, true, i18n.language)}
													</span>
												</div>
											</div>
										</div>
									);
								})}
							</InfiniteScroll>
							{messageList.lenght === 0 && <div className='empty-conversation-list'>{t('youHaveNoChats')}</div>}
						</div>
					) : (
						!loading && (
							<div className='chat-list h-full flex items-center justify-center'>
								<div className='flex justify-center w-full flex-col items-center '>
									<NotFoundIcon />
									<span className='text-base text-deepPurple font-bold mt-5'>{t('thereIsnotAnyConversationYet')} </span>
								</div>
							</div>
						)
					)}
				</div>
				{selectedConversation?.conversation_id && isExpanded && (
					<div className='click-chat' onClick={() => setIsExpanded(false)}></div>
				)}

				{!!selectedConversation?.conversation_id && <Conversation conversation={selectedConversation} user={user} />}
			</div>
		</div>
	);
}

export default Chat;
