import { minutesToSeconds } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import '../../styles/modals/RunProgramTimePick.css';
import PickTimeModule from './PickTimeModule';
import { useTranslation } from 'react-i18next';
function RunProgramTimePick({ pickedDate, setPickedDate }) {
	const [pollHour, setPollHour] = useState(8);
	const [pollMinute, setPollMinute] = useState(0);
	const { t, locale } = useTranslation();
	const [questionHour, setQuestionHour] = useState(8);
	const [questionMinute, setQuestionMinute] = useState(0);

	const [feedHour, setFeedHour] = useState(8);
	const [feedMinute, setFeedMinute] = useState(0);

	useEffect(() => {
		let newObject = {
			poll: { hours: parseInt(pollHour), minutes: parseInt(pollMinute) },
			question: { hours: parseInt(questionHour), minutes: parseInt(questionMinute) },
			feed: { hours: parseInt(feedHour), minutes: parseInt(feedMinute) },
		};
		setPickedDate(newObject);
	}, [pollHour, pollMinute, questionHour, questionMinute, feedHour, feedMinute]);
	return (
		<div className='flex flex-row items-center w-full justify-end gap-10'>
			<PickTimeModule
				className=''
				hour={pollHour}
				minute={pollMinute}
				setReturnMinute={setPollMinute}
				setReturnHour={setPollHour}
				name={t('poll')}
			/>
			<PickTimeModule
				className=''
				hour={questionHour}
				minute={questionMinute}
				setReturnMinute={setQuestionMinute}
				setReturnHour={setQuestionHour}
				name={t('question')}
			/>
			<PickTimeModule
				className=''
				hour={feedHour}
				minute={feedMinute}
				setReturnMinute={setFeedMinute}
				setReturnHour={setFeedHour}
				name={t('feed')}
			/>
		</div>
	);
}

export default RunProgramTimePick;
