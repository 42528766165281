import React, { useEffect, useReducer, useState } from 'react';
import { ReactComponent as ContentTemplateIcon } from '../../assets/svg/ContentTemplateIcon.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { create, deleteById, update, getById, sendNotification, createContent } from '../../server/server';
import { useUser } from '../../auth';
import { formatDateOnly, notify, translateTargetText } from '../../utils/util';
import ConfirmationModal from '../modals/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteContentIcon } from '../../assets/svg/DeleteContentIcon.svg';
import { ReactComponent as DownArrowRearrange } from '../../assets/svg/DownArrowRearrange.svg';
import { ReactComponent as AngleUp } from '../../assets/svg/AngleUp.svg';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as OptionRemove } from '../../assets/svg/DeleteContent.svg';
import { SCHEDULE_CONTENT_INIT, TARGET_GROUP_INIT } from '../../constants/Constants';

import i18n from 'i18next';
import Switcher from './Switcher';
import PollScheduleModal from '../modals/PollScheduleModal';
import TargetGroupModal from '../modals/TargetGroupModal';
import Loading from '../Loading';
import { useSocket } from '../../context/socket';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
function ContentSurvey({ selectedContent }) {
	let history = useHistory();
	const { t, locale } = useTranslation();
	const { socketStates, socket, changeSocketState } = useSocket();

	const [templateSaved, setTemplateSaved] = useState(false);
	const [titleValue, setTitleValue] = useState('');
	const [descValue, setDescValue] = useState('');
	const [titleError, setTitleError] = useState(false);
	const [sectionTitleError, setSectionTitleError] = useState(false);
	const [questionsTitleError, setQuestionsTitleError] = useState(false);
	const [optionsTitleError, setOptionsTitleError] = useState(false);
	const [sameOptionsError, setSameOptionsError] = useState(false);
	const [openTarget, setOpenTarget] = useState(false);
	const [anonymous, setAnonymous] = useState(false);
	const [required, setRequired] = useState(false);
	const [progress, setProgress] = useState(false);
	const [targetError, setTargetError] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [openSchedule, setOpenSchedule] = useState(false);
	const [returnTarget, setReturnTarget] = useState(TARGET_GROUP_INIT);
	const [returnSchedule, setReturnSchedule] = useState(SCHEDULE_CONTENT_INIT);
	const setSchedule = (option) => {
		setReturnSchedule({
			enabled: false,
			text: 'Off',
			recurrentText: 'Never',
			repeatEnabled: false,
			schedule_date: 0,
		});
	};

	const closeScheduleModal = () => {
		setOpenSchedule(false);
	};

	useEffect(() => {
		setTargetError(false);
	}, [returnTarget]);

	const { user, setUser } = useUser();
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const _removeValue = (arr, x) => {
		arr.splice(x, 1);
		return arr;
	};
	const DEFAULT_SECTIONS = {
		title: '',
		description: '',
		questions: [
			{
				title: '',
				type: 'yesNo',
				options: ['Yes', 'No'],
			},
		],
	};
	const [sections, setSections] = useState([DEFAULT_SECTIONS]);
	const [deleteModalOpen, setDelModalOpen] = useState(false);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		if (selectedContent?.id) {
			setTitleValue(selectedContent?.title);
			setDescValue(selectedContent?.description);
			setSections(selectedContent?.sections);
			setReturnTarget({
				selection: selectedContent?.targetOptions?.selection ?? [],
				type: selectedContent?.targetOptions?.type ?? 'root',
				target: selectedContent?.target ?? [],
				name: selectedContent?.target?.length > 0 ? selectedContent?.target_text : 'Select target',
			});
			forceUpdate();
		}
	}, []);

	const handleQuestionTypeChange = (e, index, Qindex) => {
		if (e.target.value === 'options') {
			setSections(
				(state) => (
					(state[index].questions[Qindex] = { ...state[index].questions[Qindex], options: ['', ''], type: 'options' }), state
				)
			);
		}
		if (e.target.value === 'frequency') {
			setSections(
				(state) => (
					(state[index].questions[Qindex] = {
						...state[index].questions[Qindex],
						options: ['Never', 'Sometimes', 'Often', 'Always'],
						type: 'options',
					}),
					state
				)
			);
		} else {
			setSections(
				(state) => (
					(state[index].questions[Qindex] = {
						...state[index].questions[Qindex],
						options: ['Yes', 'No'],
						type: e.target.value,
					}),
					state
				)
			);
		}
		forceUpdate();
	};

	const handleAddQuestion = (index) => {
		setSections((state) => ((state[index].questions = [...state[index].questions, DEFAULT_SECTIONS.questions[0]]), state));
		forceUpdate();
	};
	const handleQuestionRemove = (index, Qindex) => {
		setSections((state) => ((state[index].questions = _removeValue(state[index].questions, Qindex)), state));
		forceUpdate();
	};
	const handleAddSection = () => {
		setSections([
			...sections,
			{
				title: '',
				description: '',
				questions: [
					{
						title: '',
						type: 'yesNo',
						options: ['Yes', 'No'],
					},
				],
			},
		]);
	};
	const handleSectionRemove = (index) => {
		setSections(_removeValue(sections, index));
		forceUpdate();
	};
	const closeDelModal = () => {
		setDelModalOpen(false);
	};
	const closeTargetModal = () => {
		setOpenTarget(false);
	};
	const checkIfDuplicateExists = (arr) => {
		return new Set(arr).size !== arr.length;
	};
	const validate = () => {
		let valid = true;
		console.log('Survey sections ', sections);
		sections.forEach((element) => {
			if (element.title === '') {
				setSectionTitleError(true);
				valid = false;
				return;
			}
		});
		sections.forEach((section) => {
			section.questions.forEach((question) => {
				if (question.title === '') {
					setQuestionsTitleError(true);
					valid = false;
					return;
				}
			});
		});
		sections.forEach((section) => {
			section.questions.forEach((question) => {
				if (question?.type === 'options') {
					if (checkIfDuplicateExists(question.options)) {
						setSameOptionsError(true);
						valid = false;
					}
					question.options.forEach((option) => {
						if (option === '') {
							setOptionsTitleError(true);
							valid = false;
							return;
						}
					});
				}
			});
		});
		if (titleValue === '') {
			setTitleError(true);
			valid = false;
		}
		if (returnTarget?.target?.length === 0) {
			setTargetError(true);
			valid = false;
		}
		setShowErrorMessage(!valid);
		return valid;
	};

	useEffect(() => {
		if (returnTarget?.target?.length < 2 && anonymous) {
			notify(t(`Cannot create anonymous survey for 1 user`));
			setAnonymous(false);
		}
	}, [returnTarget?.target]);

	const switchAnonymous = (state) => {
		const numberOfTarget = returnTarget?.target?.length ?? 0;
		console.log('number of target ', numberOfTarget);
		if (numberOfTarget > 0 && numberOfTarget < 2) {
			notify(t(`Cannot create anonymous survey for 1 user`));
			return;
		}
		//setTrackWellbeingEnabled(false);
		setAnonymous(state);
	};

	const handlePublish = async () => {
		if (!validate()) return;
		setProgress(true);
		let content = {
			user_id: user.id,
			title: titleValue,
			description: descValue,
			sections: sections,
			locale: 'en',
		};
		content.institution_id = user.institution_id;
		content.device = 'desktop';
		content.target = returnTarget.target.map((e) => e.id ?? e);
		content.target_text = returnTarget.name;
		content.created_date = Date.now();
		content.status = 'start';
		content.type = 'survey';
		content.anonymous = anonymous;
		content.schedule = !!returnSchedule?.enabled;
		content.schedule_info = returnSchedule;
		content.required = required;
		content.created_by = {
			first_name: user.first_name,
			last_name: user.last_name,
		};
		if (!!returnSchedule?.enabled) content.schedule_date = returnSchedule?.schedule_date;
		if (selectedContent.id) {
			const resp = await createContent({ data: { ...content, id: selectedContent?.id } });
			if (resp.status === 200) {
				const data = resp.data;
				history?.push('/dashboard');
				if (resp?.data?.status !== 'pending') {
					notify(t('yourDataIsUpdated'));
				} else {
					socket?.emit('new_content_reviewing', data);
					sendNotification({ content: data, type: 'toReview' });
					notify(t('contentNeedsToBeReviewedByTheScoolAdmin'));
				}
			} else {
				notify(t('contentIsNotCreated'));
			}
		} else {
			const resp = await createContent({ data: content });
			if (resp.status === 200) {
				const data = resp.data;
				if (resp?.data?.status !== 'pending') {
					socket?.emit('new_content', data);
					history?.push('/dashboard');
					notify(t('contentIsCreated'));
				} else {
					socket?.emit('new_content_reviewing', data);
					sendNotification({ content: data, type: 'toReview' });
					notify(t('contentNeedsToBeReviewedByTheScoolAdmin'));
				}
			} else {
				notify(t('contentIsNotCreated'));
			}
		}
	};

	const handleSave = async () => {
		if (!validate()) return;
		if (selectedContent?.id) {
			const resp = await update({
				collection: 'surveys',
				data: {
					targetSchools: [user.institution_id],
					user_id: user.id,
					title: titleValue,
					description: descValue,
					sections: sections,
					id: selectedContent?.id,
					locale: 'en',
				},
			});
			if (resp.status === 200) {
				notify(t('templateIsUpdated'));
				setTemplateSaved(true);
				// history.push('/readytogo?mode=Surveys');
			}
		} else {
			const response = await create({
				collection: 'surveys',
				data: {
					targetSchools: [user.institution_id],
					user_id: user.id,
					title: titleValue,
					description: descValue,
					sections: sections,
					locale: 'en',
				},
			});
			if (response.status === 200) {
				notify(t('surveyIsCreatedAsTemplate'));
				setTemplateSaved(true);
				// history.push('/readytogo?mode=Surveys');
			}
		}
	};
	const handleAddOption = (index, Qindex) => {
		setSections((state) => ((state[index].questions[Qindex].options = [...state[index].questions[Qindex].options, '']), state));
		forceUpdate();
	};
	const handleOptionRemove = (index, Qindex, Oindex) => {
		setSections(
			(state) => ((state[index].questions[Qindex].options = _removeValue(state[index].questions[Qindex].options, Oindex)), state)
		);
		forceUpdate();
	};
	const handleOptionChange = (e, index, Qindex, Oindex) => {
		const newOptions = [...sections[index].questions[Qindex].options];
		newOptions.splice(Oindex, 1, e.target.value);

		setSections((state) => ((state[index].questions[Qindex].options = newOptions), state));
		forceUpdate();
	};
	const handleQuestionTitleChange = (e, index, Qindex) => {
		const newQuestions = [...sections[index].questions];
		newQuestions.splice(Qindex, 1, {
			...newQuestions[Qindex],
			title: e.target.value,
		});
		setSections((state) => ((state[index].questions = newQuestions), state));
		forceUpdate();
	};
	const handleSectionTitleChange = (e, index) => {
		setSections((state) => ((state[index].title = e.target.value), state));
		forceUpdate();
	};
	const handleSectionDescChange = (e, index) => {
		setSections((state) => ((state[index].description = e.target.value), state));
		forceUpdate();
	};
	const handleDelete = async () => {
		if (selectedContent?.id) {
			const response = await deleteById({
				collection: 'surveys',
				id: selectedContent?.id,
			});
			if (response.status === 200) {
				notify(t('surveyIsDeleted'));
				history.push('/content/');
			}
		}
	};
	const handleDeleteClick = () => {
		if (selectedContent?.id) {
			setDelModalOpen(true);
		}
	};

	const moveSectionDown = (section, index) => {
		const _sections = [...sections];
		_sections.splice(index + 2, 0, section);
		_sections.splice(index, 1);
		setSections(_sections);
		setTimeout(() => {
			document.getElementById(`section_${index + 1}`)?.scrollIntoView({ behavior: 'smooth' });
		}, 10);
	};
	const moveSectionUp = (section, index) => {
		const _sections = [...sections];
		_sections.splice(index - 1, 0, section);
		_sections.splice(index + 1, 1);
		setSections(_sections);
		setTimeout(() => {
			document.getElementById(`section_${index - 1}`)?.scrollIntoView({ behavior: 'smooth' });
		}, 10);
	};
	const moveQuestionDown = (section, index, sectionId) => {
		let _sections = [...sections];
		const _question = _sections[sectionId].questions;
		_question.splice(index + 2, 0, section);
		_question.splice(index, 1);
		_sections[sectionId].questions = _question;
		console.log(_sections);
		setSections(_sections);
		// setTimeout(() => {
		// 	document.getElementById(`section_${sectionId}_question${index + 1}`)?.scrollIntoView({ behavior: 'smooth' });
		// }, 10);
	};
	const moveQuestionUp = (section, index, sectionId) => {
		let _sections = [...sections];
		const _question = _sections[sectionId].questions;
		_question.splice(index - 1, 0, section);
		_question.splice(index + 1, 1);
		_sections[sectionId].questions = _question;
		setSections(_sections);
		// setTimeout(() => {
		// 	document.getElementById(`section_${sectionId}_question${index - 1}`)?.scrollIntoView({ behavior: 'smooth' });
		// }, 10);
	};

	// Function to update list on drop
	const handleDrop = (droppedItem) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...sections];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		// Update State
		setSections(updatedList);
		//document.getElementById('saveChanges')?.click();
	};

	return (
		<div>
			{/* <div className="bg-containerGray rounded-full py-2 flex justify-center items-center pointer"> */}
			{/* <span className="mr-2 text-base font-semibold text-mudPurple">Use Template</span> */}
			{/* <ContentTemplateIcon /> */}
			{/* </div> */}
			<div>
				<div className='mt-5'>
					<p className='text-deepPurple font-semibold text-lg text-left'>{t('headline')} </p>
					<input
						className={`w-full text-deepPurple  border py-3 border-x-0 border-t-0 ${
							titleError ? 'border-b-deleteRed placeholder-deleteRed' : 'border-b-deselectPurple '
						}`}
						placeholder={`${titleError ? t('typeHeadlineHere') + '*' : t('typeHeadlineHere')}`}
						style={{ backgroundColor: 'transparent' }}
						onFocus={() => setTitleError(false)}
						value={titleValue}
						onChange={(e) => setTitleValue(e.target.value)}
					/>
				</div>
				<div className='mt-6'>
					<p className='text-deepPurple font-semibold text-lg text-left'>{t('supportiveText')}</p>
					<textarea
						className='w-full text-deepPurple mt-2  border border-deselectPurple hover:border-deepPurple focus:border-deepPurple p-2 rounded-xl resize-none'
						placeholder={t('typeAnyAdditionalTextHere')}
						rows='3'
						style={{ backgroundColor: 'transparent' }}
						value={descValue}
						onChange={(e) => setDescValue(e.target.value)}
					/>
				</div>
			</div>
			<DragDropContext onDragEnd={handleDrop}>
				<Droppable droppableId='list-container'>
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{sections?.map((section, index) => {
								return (
									<SingleSection
										index={index}
										handleAddOption={handleAddOption}
										handleAddQuestion={handleAddQuestion}
										handleOptionChange={handleOptionChange}
										handleOptionRemove={handleOptionRemove}
										handleQuestionRemove={handleQuestionRemove}
										handleQuestionTitleChange={handleQuestionTitleChange}
										handleQuestionTypeChange={handleQuestionTypeChange}
										handleSectionDescChange={handleSectionDescChange}
										handleSectionRemove={handleSectionRemove}
										handleSectionTitleChange={handleSectionTitleChange}
										moveQuestionDown={moveQuestionDown}
										moveQuestionUp={moveQuestionUp}
										moveSectionDown={moveSectionDown}
										moveSectionUp={moveSectionUp}
										section={section}
										sections={sections}
										setSections={setSections}
										sectionTitleError={sectionTitleError}
										questionsTitleError={questionsTitleError}
										optionsTitleError={optionsTitleError}
										sameOptionsError={sameOptionsError}
										key={index}
									/>
								);
							})}
							{provided.placeholder}
							<div
								className='bg-mudPurple hover:bg-deepPurple rounded-xl py-4 w-full my-5 pointer  font-semibold flex items-center justify-center text-base text-white'
								onClick={() => handleAddSection()}
							>
								{t('addSection')} {sections.length + 1}
							</div>
						</div>
					)}
				</Droppable>
			</DragDropContext>

			{/* <div
				className='bg-deepPurple rounded-full py-4 w-full pointer  my-5  font-semibold flex items-center justify-center text-base text-highlightColor hover:bg-highlightColor hover:text-deepPurple'
				onClick={() => handleSave()}
			>
				Save as template
			</div> */}
			<div className=' mb-3'>
				<div className='flex flex-row justify-between items-center gap-[40px]'>
					<div className='flex flex-row justify-between items-center h-[40px] flex-1'>
						<p className={` text-lg font-medium ${targetError ? 'text-[#FF747F]' : 'text-mudPurple'}`}>
							{t('targetGroup')}
							{targetError ? '*' : ''}
						</p>
						<div
							className={`text-lg text-mudPurple hover:text-deepPurple font-medium rounded-full py-1 px-4 bg-containerGray pointer ${
								targetError ? 'text-[#FF747F]' : ''
							}
							${returnTarget?.target?.length > 0 ? 'text-deepPurple' : ''}
							
							`}
							onClick={() => setOpenTarget(true)}
						>
							{translateTargetText(returnTarget?.name)}
						</div>
					</div>
					<div className='flex flex-row justify-between items-center h-[40px] flex-1'>
						<p className='text-[#9392AD] text-lg mr-[20px]'>{t('required')} </p>

						<Switcher setSwitcher={setRequired} switcher={required} />
					</div>
				</div>
				<div className='flex flex-row justify-between items-center gap-[40px]'>
					<div className='flex flex-row justify-between items-center my-3 h-[40px] flex-1'>
						<p className='text-mudPurple text-lg'>{t('schedule')}</p>
						<div
							className='text-lg flex flex-row justify-between items-center
						text-mudPurple rounded-full py-1 px-4 bg-containerGray pointer'
							onClick={() => setOpenSchedule(true)}
						>
							<Switcher setSwitcher={setSchedule} switcher={returnSchedule?.enabled} />
							<span className='ml-2 font-semibold text-lg text-mudPurple'>
								{returnSchedule?.enabled && formatDateOnly(returnSchedule?.schedule_date)}
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between items-center h-[40px] flex-1'>
						<p className='text-mudPurple text-lg mr-[20px]'>{t('anonymous')} </p>

						<Switcher setSwitcher={switchAnonymous} switcher={anonymous} />
					</div>
				</div>
			</div>
			<div className='mt-2'>
				<p className={`text-[#FF747F] font-medium text-[14px] text-center ${showErrorMessage ? '' : 'opacity-0'}`}>
					*{t('pleaseFillRequiredFields')}
				</p>
			</div>
			{
				<div className='flex flex-row justify-between mt-5'>
					<div
						className='border pointer border-mudPurple hover:bg-lightBG  rounded-full py-3 flex min-w-[334px] justify-center text-deepPurple deepPurple font-semibold text-lg'
						onClick={() => handleSave()}
					>
						{templateSaved ? t('templateIsSaved') : t('saveAsTemplate')}
					</div>
					<div
						className='pointer rounded-full py-3 flex justify-center min-w-[334px] bg-deepPurple text-highlightColor hover:bg-highlightColor hover:text-deepPurple  deepPurple font-semibold text-lg'
						onClick={handlePublish}
					>
						{progress ? <Loading /> : !!selectedContent.id ? t('update') : t('publish')}
					</div>
				</div>
			}
			<PollScheduleModal showSchedule={openSchedule} closeSchedule={closeScheduleModal} setReturnSchedule={setReturnSchedule} />

			<TargetGroupModal showTarget={openTarget} closeTarget={closeTargetModal} setReturnTarget={setReturnTarget} />
		</div>
	);
}

export default ContentSurvey;

const SingleSection = ({
	index,
	moveSectionUp,
	moveSectionDown,
	sections,
	section,
	handleSectionRemove,
	handleSectionTitleChange,
	handleSectionDescChange,
	moveQuestionUp,
	moveQuestionDown,
	handleQuestionRemove,
	handleQuestionTitleChange,
	handleQuestionTypeChange,
	handleOptionChange,
	handleOptionRemove,
	handleAddOption,
	handleAddQuestion,
	setSections,
	sectionTitleError,
	questionsTitleError,
	optionsTitleError,
	sameOptionsError,
}) => {
	const { t, locale } = useTranslation();
	const [showSection, setShowSection] = useState(true);

	const handleDropQuestion = (droppedItem) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...section.questions];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		// Update State
		let _sections = [...sections];
		_sections[index].questions = updatedList;
		console.log(_sections);
		setSections(_sections);
	};
	return (
		<Draggable key={index.toString()} draggableId={index.toString()} index={index}>
			{(provided) => (
				<div className='mb-5 ' ref={provided.innerRef} {...provided.draggableProps}>
					<div
						className={`bg-whiteContrast rounded-xl p-4  ${i18n.language == 'ar' ? 'text-right' : ''}`}
						key={index}
						id={`section_${index}`}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					>
						<div className=' flex flex-row justify-between items-center' {...provided.dragHandleProps}>
							<p className='text-mudPurple font-semibold text-lg flex-1' style={i18n.language == 'ar' ? { left: 0 } : {}}>
								{t('section')} {index + 1}
							</p>
							<div className='mr-[15px] flex flex-row gap-[10px]'>
								<button
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										moveSectionUp(section, index);
									}}
									disabled={index === 0}
								>
									<div
										className={`h-[40px] w-[40px] border border-[#BEBECE] transition-all duration-300 ease-in-out rounded-full flex items-center justify-center ${
											index === 0 ? '' : 'hover:border-[#5E639B] pointer'
										}`}
										data-tip={'Move section up'}
										data-for={`survey-section-${index}-go-up`}
									>
										<DownArrowRearrange className='rotate-180' />
										<ReactTooltip
											id={`survey-section-${index}-go-up`}
											arrowColor='#5E639B'
											effect='solid'
											backgroundColor='#5E639B'
											className='tooltip-poll'
											disable={index === 0}
										/>
									</div>
								</button>
								<button
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										moveSectionDown(section, index);
									}}
									disabled={index === sections.length - 1}
								>
									<div
										className={`h-[40px] w-[40px] border border-[#BEBECE] transition-all duration-300 ease-in-out rounded-full flex items-center justify-center  ${
											index === sections.length - 1 ? '' : 'hover:border-[#5E639B] pointer'
										}`}
										data-tip={'Move section down'}
										data-for={`survey-section-${index}-go-down`}
									>
										<DownArrowRearrange />
										<ReactTooltip
											id={`survey-section-${index}-go-down`}
											arrowColor='#5E639B'
											effect='solid'
											backgroundColor='#5E639B'
											className='tooltip-poll'
											disable={index === sections.length - 1}
										/>
									</div>
								</button>
							</div>
							{sections.length > 1 && (
								<div
									className='flex flex-row pointer justify-between items-center'
									onClick={() => handleSectionRemove(index)}
								>
									<p className='text-deleteRed font-semibold text-lg mr-3'>{t('delete')}</p>
									<DeleteContentIcon fill='#FF747F' />
								</div>
							)}
							<button
								className={`flex items-center justify-center min-w-[55px] h-[55px] rounded-full ${
									showSection ? 'bg-white ' : 'bg-deepPurple'
								} ml-[35px] cursor-pointer`}
								onClick={() => setShowSection((prev) => !prev)}
								style={{ transform: !showSection ? 'rotate(180deg)' : 'none' }}
							>
								<AngleUp color={showSection ? '#9392AD' : '#ffffff'} />
							</button>
						</div>
						{showSection && (
							<div>
								<div className=' my-5'>
									<div>
										<p className='text-deepPurple font-semibold text-lg text-left'>
											{t('headlineForSection')} {index + 1}
										</p>

										<input
											className={`w-full text-deepPurple  border py-3 border-x-0 border-t-0 ${
												sectionTitleError && section.title === ''
													? 'border-b-deleteRed placeholder-deleteRed'
													: 'border-b-deselectPurple '
											}`}
											placeholder={`${
												sectionTitleError && section.title === ''
													? t('typeHeadlineHere') + '*'
													: t('typeHeadlineHere')
											}`}
											style={{ backgroundColor: 'transparent' }}
											//onFocus={() => setTitleError(false)}
											value={section?.title}
											onChange={(e) => handleSectionTitleChange(e, index)}
										/>
									</div>

									<div className='mt-6'>
										<p className='text-deepPurple font-semibold text-lg text-left'>
											{t('descriptionForSection')} {index + 1}
										</p>
										<textarea
											className='w-full text-deepPurple mt-2  border border-deselectPurple hover:border-deepPurple focus:border-deepPurple p-2 rounded-xl resize-none'
											placeholder={t('typeAnyAdditionalTextHere')}
											rows='3'
											style={{ backgroundColor: 'transparent' }}
											value={section?.description}
											onChange={(e) => handleSectionDescChange(e, index)}
										/>
									</div>
								</div>
								<DragDropContext onDragEnd={handleDropQuestion}>
									<Droppable droppableId={`questions-${index}`}>
										{(provided) => (
											<div {...provided.droppableProps} ref={provided.innerRef}>
												{section?.questions.map((question, questionIndex) => {
													return (
														<Draggable
															key={questionIndex.toString()}
															draggableId={questionIndex.toString()}
															index={questionIndex}
														>
															{(provided) => (
																<div
																	className='mb-5'
																	ref={provided.innerRef}
																	{...provided.dragHandleProps}
																	{...provided.draggableProps}
																>
																	<SingleQuestion
																		key={questionIndex}
																		section={section}
																		question={question}
																		index={index}
																		questionIndex={questionIndex}
																		moveQuestionDown={moveQuestionDown}
																		handleAddOption={handleAddOption}
																		handleOptionChange={handleOptionChange}
																		handleOptionRemove={handleOptionRemove}
																		handleQuestionRemove={handleQuestionRemove}
																		handleQuestionTitleChange={handleQuestionTitleChange}
																		handleQuestionTypeChange={handleQuestionTypeChange}
																		moveQuestionUp={moveQuestionUp}
																		questionsTitleError={questionsTitleError}
																		optionsTitleError={optionsTitleError}
																		sameOptionsError={sameOptionsError}
																	/>
																</div>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}
												<div
													className='bg-lightBG hover:bg-deselectPurple border  border-deselectPurple flex items-center justify-center rounded-xl py-3 w-full pointer  font-semibold text-base text-deepPurple '
													onClick={() => handleAddQuestion(index)}
												>
													{t('addQuestion')} {section?.questions?.length + 1} (section {index + 1})
												</div>
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</div>
						)}
					</div>
				</div>
			)}
		</Draggable>
	);
};

const SingleQuestion = ({
	section,
	question,
	index,
	questionIndex,
	moveQuestionUp,
	moveQuestionDown,
	handleQuestionRemove,
	handleQuestionTitleChange,
	handleQuestionTypeChange,
	handleOptionChange,
	handleOptionRemove,
	handleAddOption,
	questionsTitleError,
	optionsTitleError,
	sameOptionsError,
}) => {
	const { t, locale } = useTranslation();
	const [showQuestion, setShowQuestion] = useState(true);
	const [sameOptions, setSameOptions] = useState(false);

	const checkIfDuplicateExists = (arr) => {
		return new Set(arr).size !== arr.length;
	};

	useEffect(() => {
		if (question.type === 'options' && checkIfDuplicateExists(question.options)) {
			setSameOptions(true);
		} else setSameOptions(false);
	}, [question.options]);

	return (
		<div
			className='bg-lightBG rounded-xl p-4  border border-lightBG hover:border-deepPurple'
			style={i18n.language == 'ar' ? { borderLeft: 'none', borderRight: '3px solid #fedbb9' } : {}}
			key={questionIndex}
			id={`section_${index}_question${questionIndex}`}
		>
			<div className=' '>
				<div
					className=' flex flex-row justify-between items-center '
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<p className='text-deepPurple font-semibold text-base text-left flex-1'>
						{t('question')} {questionIndex + 1} ({t('section')} {index + 1})
					</p>
					<div className='mr-[15px] flex flex-row gap-[10px]'>
						<button
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								moveQuestionUp(question, questionIndex, index);
							}}
							disabled={questionIndex === 0}
						>
							<div
								className={`h-[40px] w-[40px] border border-[#BEBECE] transition-all duration-300 ease-in-out rounded-full flex items-center justify-center ${
									questionIndex === 0 ? '' : 'hover:border-[#5E639B] pointer'
								}`}
								data-tip={t('moveQuestionUp')}
								data-for={`survey-${index}-question-${questionIndex}-go-up`}
							>
								<DownArrowRearrange className='rotate-180' />
								<ReactTooltip
									id={`survey-${index}-question-${questionIndex}-go-up`}
									arrowColor='#5E639B'
									effect='solid'
									backgroundColor='#5E639B'
									className='tooltip-poll'
									disable={questionIndex === 0}
								/>
							</div>
						</button>
						<button
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								moveQuestionDown(question, questionIndex, index);
							}}
							disabled={questionIndex === section?.questions?.length - 1}
						>
							<div
								className={`h-[40px] w-[40px] border border-[#BEBECE] transition-all duration-300 ease-in-out rounded-full flex items-center justify-center  ${
									questionIndex === section?.questions?.length - 1 ? '' : 'hover:border-[#5E639B] pointer'
								}`}
								data-tip={t('moveQuestionDown')}
								data-for={`survey-${index}-question-${questionIndex}-go-down`}
							>
								<DownArrowRearrange />
								<ReactTooltip
									id={`survey-${index}-question-${questionIndex}-go-down`}
									arrowColor='#5E639B'
									effect='solid'
									backgroundColor='#5E639B'
									className='tooltip-poll'
									disable={questionIndex === section?.questions?.length - 1}
								/>
							</div>
						</button>
					</div>
					{section.questions?.length > 1 && (
						<div
							className='flex flex-row pointer justify-between items-center'
							onClick={() => handleQuestionRemove(index, questionIndex)}
						>
							<p className='text-deleteRed font-semibold text-lg mr-3'>{t('delete')}</p>
							<DeleteContentIcon fill='#FF747F' />
						</div>
					)}
					<button
						className={`flex items-center justify-center min-w-[55px] h-[55px] rounded-full ${
							showQuestion ? 'bg-white ' : 'bg-deepPurple'
						} ml-[35px] cursor-pointer`}
						onClick={() => setShowQuestion((prev) => !prev)}
						style={{ transform: !showQuestion ? 'rotate(180deg)' : 'none' }}
					>
						<AngleUp color={showQuestion ? '#9392AD' : '#ffffff'} />
					</button>
				</div>
				{showQuestion && (
					<div className='my-3'>
						<input
							type='text'
							className={`w-full text-deepPurple  border py-3 border-x-0 border-t-0 ${
								questionsTitleError && question.title === ''
									? 'border-b-deleteRed placeholder-deleteRed'
									: 'border-b-deselectPurple '
							}`}
							style={{ backgroundColor: 'transparent' }}
							placeholder={`${
								questionsTitleError && question.title === '' ? t('enterQuestionHere') + '*' : t('enterQuestionHere')
							}`}
							//onFocus={() => setTitleError(false)}
							value={question.title}
							onChange={(e) => handleQuestionTitleChange(e, index, questionIndex)}
						/>
					</div>
				)}
			</div>
			{showQuestion && (
				<div className=''>
					<div className={`flex flex-row items-center justify-between ${i18n.language == 'ar' ? 'text-right' : ''}`}>
						<p className='text-deepPurple font-semibold text-base text-left'>{t('selectTypeOfQuestion')}</p>
						<select
							name='cars'
							id='cars'
							className='border border-deselectPurple rounded-full py-2 text-sm font-semibold text-deepPurple px-3 bg-lightBG'
							defaultValue={question.type}
							onChange={(e) => handleQuestionTypeChange(e, index, questionIndex)}
						>
							<option value='yesNo'>{t('yesNo')}</option>
							<option value='options'>{t('options')}</option>
							<option value='frequency'>{t('Frequency')}</option>
							<option value='openAnswer'>{t('openAnswer')}</option>
						</select>
					</div>
				</div>
			)}
			{showQuestion && question?.type == 'yesNo' && (
				<div>
					<div className='flex my-3 pointer text-deepPurple flex-row items-center justify-between border border-deselectPurple hover:border-mudPurple rounded-xl py-3 px-4'>
						{t('Yes')}
					</div>
					<div className='flex pointer my-3 text-deepPurple  flex-row items-center justify-between border border-deselectPurple hover:border-mudPurple rounded-xl py-3 px-4'>
						{t('No')}
					</div>
				</div>
			)}
			{showQuestion && question?.type === 'options' && (
				<div className=''>
					<div className=''>
						{question?.options.map((option, Oindex) => {
							return (
								<div
									className='flex my-3 flex-row items-center justify-between border border-deselectPurple hover:border-mudPurple rounded-xl py-3 px-4'
									key={Oindex}
								>
									<input
										type='text'
										className={`w-full text-deepPurple  border py-3 border-x-0 border-t-0 ${
											optionsTitleError && option === ''
												? 'border-b-deleteRed placeholder-deleteRed'
												: 'border-b-deselectPurple '
										}`}
										placeholder={`${
											optionsTitleError && option === ''
												? t('writeOption', {
														result: Oindex + 1,
												  }) + '*'
												: t('writeOption', {
														result: Oindex + 1,
												  })
										}`}
										style={{ backgroundColor: 'transparent' }}
										value={option}
										onChange={(e) => handleOptionChange(e, index, questionIndex, Oindex)}
									/>
									{question?.options.length > 2 && (
										<div
											onClick={() => handleOptionRemove(index, questionIndex, Oindex)}
											className='pointer'
											data-tip={t('deleteOption')}
											data-for='survey-option-remove'
										>
											<OptionRemove fill='#FFA28D' />
											<ReactTooltip
												id='survey-option-remove'
												arrowColor='#5E639B'
												effect='solid'
												backgroundColor='#5E639B'
												className='tooltip-poll'
											/>
										</div>
									)}
								</div>
							);
						})}
						<div className='mt-2'>
							<p
								className={`text-[#FF747F] font-medium text-[14px] text-center ${
									sameOptions && sameOptionsError ? '' : 'opacity-0'
								}`}
							>
								*{t('Options must differ ')}
							</p>
						</div>
						<div
							className='bg-lightBG border border-deepPurple flex items-center justify-center pointer rounded-xl py-3 w-full font-semibold text-base text-deepPurple'
							onClick={() => handleAddOption(index, questionIndex)}
						>
							{t('addOption')}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
