import React from "react";
import ShowMoreText from "react-show-more-text";
import { useTranslation } from "react-i18next";

function ReadMoreText({ message, lines = 3 }) {
    const { t, locale } = useTranslation();

    return (
        <ShowMoreText
            /* Default options */
            lines={lines}
            more={t("showMore")}
            less={t("showLess")}
            anchorClass="my-anchor-css-class"
            //onClick={executeOnClick}
            expanded={false}
            truncatedEndingComponent={"... "}
        >
            {message}
        </ShowMoreText>
    );
}

export default ReadMoreText;
