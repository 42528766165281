import React, { useEffect, useState } from 'react';
import { ReactComponent as PreviousIcon } from '../../assets/svg/PreviousIcon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/svg/DownloadIcon.svg';
import { ReactComponent as DeleteContentIcon } from '../../assets/svg/DeleteContentIcon.svg';
import { ReactComponent as AngleUp } from '../../assets/svg/AngleUp.svg';
import { ReactComponent as Search } from '../../assets/svg/Search.svg';
import { ReactComponent as LongArrow } from '../../assets/svg/LongArrow.svg';
import { ReactComponent as DoubleArrowRight } from '../../assets/svg/DoubleArrowRight.svg';
import { useTranslation } from 'react-i18next';
import { format_day } from '../../utils/util';
import { BarColors } from '../../constants/BarColors';
import ReactTooltip from 'react-tooltip';

const surveysFakeList = [
	{
		time: new Date(),
	},
	{
		time: new Date(),
	},
	{
		time: new Date(),
	},
	{
		time: new Date(),
	},
	{
		time: new Date(),
	},
	{
		time: new Date(),
	},
	{
		time: new Date(),
	},
];

const DEFAULT_SECTIONS = {
	title: 'Section #1',
	description: 'Description of section',
	questions: [
		{
			title: 'I feel that I have too much homework at the moment. ',
			description: 'Some description if needed',
			type: 'options',
			options: [
				'Strongly Agree',
				'Agree',
				'Depends on the situation with very long text',
				'Disagree',
				'Strongly Disagree',
				'Always Awesome',
				'new option1',
			],
			answers1: [
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Strongly Agree',
				'Agree',
				'Depends on the situation with very long text',
				'Strongly Agree',
				'Strongly Disagree',
				'Always Awesome',
				'Strongly Disagree',
				'Always Awesome',
				'Strongly Disagree',
				'Always Awesome',
				'Strongly Disagree',
				'Always Awesome',
				'Depends on the situation with very long text',
				'Strongly Agree',
				'Strongly Disagree',
				'Always Awesome',
				'Strongly Disagree',
				'Always Awesome',
				'Strongly Disagree',
				'Always Awesome',
				'Strongly Disagree',
				'Always Awesome',
				'Agree',
				'Depends on the situation with very long text',
				'Strongly Agree',
				'Depends on the situation with very long text',
				'Strongly Agree',
				'Agree',
				'Strongly Agree',
				'Agree',
				'Strongly Agree',
				'Strongly Disagree',
				'Always Awesome',
				'Strongly Disagree',
				'Always Awesome',
				'Agree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
			],
			answers2: [
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Depends on the situation with very long text',
				'Strongly Agree',
				'Strongly Disagree',
				'Always Awesome',
				'Strongly Disagree',
				'Always Awesome',
				'Strongly Disagree',
				'Always Awesome',
				'Strongly Disagree',
				'Always Awesome',
				'Agree',
				'Depends on the situation with very long text',
				,
				'Agree',
				'Depends on the situation with very long text',
				'Agree',
				'Agree',
				'Agree',
				'Agree',
				'Agree',
				'Agree',
				'Agree',
				'Agree',
				'Agree',
				'Agree',
				'Depends on the situation with very long text',
				'Agree',
				'Depends on the situation with very long text',
				'Agree',
				'Depends on the situation with very long text',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
				'Disagree',
			],
		},
		{
			title: 'I feel that I have too much homework at the moment. ',
			description: 'Some description if needed',
			type: 'options',
			options: ['Yes', 'No', 'Maybe'],
			answers1: ['Yes', 'No', 'Yes', 'No', 'Yes', 'No', 'Yes', 'No', 'Yes', 'Maybe', 'Yes', 'Maybe', 'Maybe', 'No', 'No', 'Maybe'],
			answers2: ['Yes', 'No', 'Yes', 'No', 'Maybe', 'No', 'No', 'No', 'Yes', 'No', 'No', 'No', 'No', 'No', 'No', 'Maybe'],
		},
	],
};

const fakeContent = {
	id: '1',
	title: '',
	description: '',
	sections: [DEFAULT_SECTIONS, DEFAULT_SECTIONS, DEFAULT_SECTIONS],
};

const fakeUsersList = [
	{
		first_name: 'Petar',
		last_name: '',
		answer: 'Strongly Agree',
	},
	{
		first_name: 'Linda',
		last_name: 'Bonnar',
		answer: 'Agree',
	},
	{
		first_name: 'Audrey',
		last_name: 'Hametner',
		answer: 'Depends on the situation',
	},
	{
		first_name: 'Petar',
		last_name: '',
		answer: 'Strongly Agree',
	},
	{
		first_name: 'Linda',
		last_name: 'Bonnar',
		answer: 'Agree',
	},
	{
		first_name: 'Audrey',
		last_name: 'Hametner',
		answer: 'Depends on the situation',
	},
	{
		first_name: 'Petar',
		last_name: '',
		answer: 'Strongly Agree',
	},
	{
		first_name: 'Linda',
		last_name: 'Bonnar',
		answer: 'Agree',
	},
	{
		first_name: 'Audrey',
		last_name: 'Hametner',
		answer: 'Depends on the situation',
	},
];

function AnalyticsSurveyContainer({ content, goBack = () => {} }) {
	const { t, i18n } = useTranslation();
	const [searchText, setSearchText] = useState('');
	const [selectedSurveys, setSelectedSurveys] = useState([]);

	const handleSelect = (index) => {
		if (selectedSurveys.includes(index)) {
			let newArray = selectedSurveys;
			const currentIndex = selectedSurveys.indexOf(index);
			console.log('removed index ', currentIndex);
			newArray.splice(currentIndex, 1);
			console.log('new Array ', newArray);
			setSelectedSurveys([...newArray]);
		} else {
			setSelectedSurveys([...selectedSurveys, index]);
		}
	};

	return (
		<div className='w-full bg-white rounded-[10px] mt-[32px] mb-12 '>
			<div className='  rounded-[10px] bg-[#DAE6FE80] py-[45px] px-[35px]'>
				<div className='flex flex-row items-center w-full'>
					<div
						className='min-w-[70px] h-[70px] bg-[#5E639B] rounded-full flex items-center justify-center  cursor-pointer '
						onClick={goBack}
					>
						<PreviousIcon width={18} height={20} style={{ color: '#FDDF6D' }} />
					</div>
					<div className='flex flex-col gap-5 ml-[26px]'>
						<p className='text-[32px] leading-[40px] text-darkenPurple font-bold'>{content?.title} </p>
						<div className='flex flex-row gap-4 items-center'>
							<p className='text-[14px] leading-5 text-deepPurple font-medium'>
								<span className='text-[#5E639B96]'>{t('By')}: </span>
								{content?.createdBy}
							</p>
							<p className='text-[14px] leading-5 text-deepPurple font-medium'>
								<span className='text-[#5E639B96]'>{t('Target')}: </span>
								{content?.target}
							</p>
						</div>
					</div>
					<div className='flex-1'></div>
					<div
						className='border pointer fill-deleteRed hover:fill-white hover:bg-deleteRed text-deleteRed hover:text-white border-deleteRed py-4 px-5 rounded-full w-52 flex justify-center items-center'
						//onClick={() => deleteContent(content)}
					>
						<span className='text-[16px] font-semibold leading-5  mr-[10px]'>{t('delete')} </span>{' '}
						<DeleteContentIcon style={{ fill: 'inherit' }} />
					</div>
					<div
						className='border pointer fill-mudPurple hover:fill-white hover:bg-deselectPurple text-mudPurple hover:text-white border-deselectPurple py-4 px-5 rounded-full w-52 flex justify-center items-center ml-[10px]'
						//onClick={exportData}
					>
						<span className='text-[16px] font-semibold leading-5 mr-[10px]'>{t('download')}</span>{' '}
						<DownloadIcon style={{ fill: 'inherit' }} />
					</div>
				</div>
			</div>
			<div className='p-[35px] pt-5 rounded-[10px]'>
				<p className='text-[16px] font-medium text-darkenPurple leading-5 '>{content?.description} </p>
				<div className='stroke-deselectPurple  bg-containerGray flex flex-row items-center h-[41px] px-4 w-[268px] rounded-full mt-5'>
					<input
						type='text'
						placeholder={t('searchStudents')}
						className='w-full h-full text-deepPurple font-medium text-[16px] leading-5 bg-containerGray focus:font-normal   placeholder:text-mudPurple placeholder:font-normal'
						onChange={(e) => setSearchText(e.target.value)}
						value={searchText}
					/>
					<Search />
				</div>
				<div className='w-full flex flex-row items-center mt-5 gap-[14px] overflow-hidden'>
					<p className='text-[20px] font-bold text-darkenPurple leading-[25px] whitespace-nowrap'>{t('Select surveys')} </p>

					<div className='overflow-auto  flex flex-row gap-[10px] py-[10px] blue-small-scroll-horizontal'>
						{surveysFakeList.map((item, index) => (
							<div
								className={`min-w-[160px] h-[44px] flex items-center justify-center border border-deselectPurple rounded-full pointer ${
									selectedSurveys.includes(index) ? 'bg-deepPurple border-deepPurple' : ' '
								}`}
								onClick={() => handleSelect(index)}
								key={index}
							>
								{' '}
								<p
									className={`text-[18px] font-semibold leading-[22px] ${
										selectedSurveys.includes(index) ? 'text-white ' : 'text-mudPurple'
									}`}
								>
									{format_day(item.time, false, i18n.language)}
								</p>{' '}
							</div>
						))}
					</div>
				</div>
				<div className='mt-5 '>
					{fakeContent.sections?.map((section, index) => {
						return <SingleSection section={section} index={index} />;
					})}
				</div>
			</div>
		</div>
	);
}

export default AnalyticsSurveyContainer;

const SingleSection = ({ section, index }) => {
	const [showSection, setShowSection] = useState(index === 0 ? true : false);
	const { t } = useTranslation();
	return (
		<div className='bg-lightBG p-5 rounded-[10px] my-5' key={index}>
			<div className='flex flex-row items-center justify-between pr-5'>
				<div className='flex flex-col gap-2 '>
					<p className='text-[20px] leading-[25px] text-darkenPurple font-bold'>{section?.title} </p>
					<p className='text-[14px] leading-[20px] text-darkenPurple font-medium'>{section?.description} </p>
				</div>
				<button
					className={`flex items-center justify-center min-w-[55px] h-[55px] rounded-full ${
						showSection ? 'bg-white ' : 'bg-deepPurple'
					}  cursor-pointer`}
					onClick={() => setShowSection((prev) => !prev)}
					style={{ transform: !showSection ? 'rotate(180deg)' : 'none' }}
				>
					<AngleUp color={showSection ? '#9392AD' : '#ffffff'} />
				</button>
			</div>
			{showSection && (
				<div>
					{section?.questions?.map((question, questionIndex) => {
						return <SingleQuestion question={question} questionIndex={questionIndex} index={index} />;
					})}
				</div>
			)}
		</div>
	);
};

const SingleQuestion = ({ question, questionIndex, index }) => {
	const [showAnswers, setShowAnswers] = useState(questionIndex === 0 ? true : false);
	const { t, i18n } = useTranslation();
	return (
		<div className='bg-containerGray p-5 rounded-[10px] my-5'>
			<div className='flex flex-row items-center justify-between'>
				<div className='flex flex-col gap-2 '>
					<p className='text-[20px] leading-[25px] text-darkenPurple font-bold'>
						{index + 1}. {questionIndex + 1}. {question?.title}{' '}
					</p>
					<p className='text-[14px] leading-[20px] text-darkenPurple font-medium'>{question?.description} </p>
				</div>
				<div
					className={`flex items-center justify-center min-w-[55px] h-[55px] rounded-full border border-deselectPurple ${
						showAnswers ? 'bg-containerGray ' : 'bg-deselectPurple'
					}  cursor-pointer`}
					onClick={() => setShowAnswers((prev) => !prev)}
					style={{ transform: !showAnswers ? 'rotate(180deg)' : 'none' }}
				>
					<AngleUp color={showAnswers ? '#6B6C7E' : '#ffffff'} />
				</div>
			</div>
			{question.type !== 'openAnswer' && (
				<div className='mt-[6px]'>
					<BarChart
						options={question?.options}
						presentAnswers={question?.answers1 ?? []}
						previousAnswers={question.answers2 ?? []}
					/>
				</div>
			)}
			{showAnswers && (
				<div className=' flex flex-row justify-between mt-5 max-h-[350px] overflow-auto grey-small-scroll'>
					<div className='flex-1 flex flex-col'>
						<div className=' flex items-center justify-between'>
							<p className='text-[18px] leading-[20px] text-darkenPurple font-bold'>{t('answers')} </p>
							<p className='text-[18px] leading-[20px] text-darkenPurple font-bold'>
								{format_day(surveysFakeList[0].time, false, i18n.language)}{' '}
							</p>
						</div>
						<div className=' mt-9'>
							{fakeUsersList?.map((userData, indexA) => {
								return (
									<div
										className='bg-containerGray w-full py-2 px-2 flex items-center justify-start text-left my-2 rounded-xl'
										key={indexA}
									>
										<div
											className='w-[10px] h-[10px] rounded-full mr-3'
											style={{
												background: BarColors[question?.options?.indexOf(userData?.answer) % BarColors?.length],
											}}
										></div>
										<div className='flex flex-col justify-between'>
											<p className='text-[16px] font-bold text-deepPurple'>
												{userData?.first_name} {userData?.last_name}
											</p>
											<p className='text-sm text-mudPurple'>{userData?.answer}</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className='flex-auto max-w-[24px] flex items-center justify-center'>
						<DoubleArrowRight />
					</div>
					<div className='flex-1 flex flex-col'>
						<div className=' flex items-center justify-between'>
							<p className='text-[18px] leading-[20px] text-darkenPurple font-bold'>{t('answers')} </p>
							<p className='text-[18px] leading-[20px] text-darkenPurple font-bold'>
								{format_day(surveysFakeList[0].time, false, i18n.language)}{' '}
							</p>
						</div>
						<div className=' mt-9'>
							{fakeUsersList?.map((userData, indexA) => {
								return (
									<div
										className='bg-containerGray w-full py-2 px-2 flex items-center justify-start text-left my-2 rounded-xl'
										key={indexA}
									>
										<div
											className='w-[10px] h-[10px] rounded-full mr-3'
											style={{
												background: BarColors[question?.options?.indexOf(userData?.answer) % BarColors?.length],
											}}
										></div>
										<div className='flex flex-col justify-between'>
											<p className='text-[16px] font-bold text-deepPurple'>
												{userData?.first_name} {userData?.last_name}
											</p>
											<p className='text-sm text-mudPurple'>{userData?.answer}</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const BarChart = ({ options, presentAnswers, previousAnswers, showNum = true, showHover = false }) => {
	const [maxHeight, setMaxHeight] = useState(80);
	const [minHeight, setMinHeight] = useState(2);
	const [showNumbersHeight, setShowNumbersHeight] = useState(23);
	const [heightData, setHeightData] = useState({
		maxCount: 0,
		minCount: 0,
		presentDataMap: {},
		previousDataMap: {},
	});

	useEffect(() => {
		const presentDataMap = [...options, ...presentAnswers].reduce((acc, curr) => ((acc[curr] = acc[curr] + 1 || 0), acc), {});
		const previousDataMap = [...options, ...previousAnswers].reduce((acc, curr) => ((acc[curr] = acc[curr] + 1 || 0), acc), {});
		const maxCount = Math.max(...Object.values(presentDataMap), ...Object.values(previousDataMap));
		const minCount = Math.min(...Object.values(presentDataMap), ...Object.values(previousDataMap));
		setHeightData({
			maxCount: maxCount,
			minCount: minCount,
			presentDataMap,
			previousDataMap,
		});
	}, []);

	return (
		<div className='w-full flex flex-col overflow-x-auto blue-small-scroll-horizontal'>
			<div className='min-h-[70px] flex flex-row ' style={{ paddingTop: showNum ? showNumbersHeight : 0 }}>
				{options?.map((option, index) => {
					return (
						<div className={`flex flex-col  mx-[10px] flex-1 relative min-w-[88px] `} style={{ height: maxHeight }} key={index}>
							<div
								className={`   `}
								style={{ height: maxHeight - (maxHeight * heightData.presentDataMap[option]) / heightData.maxCount }}
							></div>
							<div
								className={`   rounded-t-[10px]`}
								style={{
									minHeight: minHeight,
									height: (maxHeight * heightData.presentDataMap[option]) / heightData.maxCount,
									background: BarColors[index % BarColors.length],
								}}
							>
								{showNum && (
									<div className='flex flex-row items-center justify-center gap-[5px] -mt-[23px] '>
										<p className='text-[12px] leading-[15px] text-darkenPurple font-medium'>
											{heightData?.previousDataMap[option]}
										</p>
										<LongArrow />
										<p className='text-[12px] leading-[15px] text-darkenPurple font-medium'>
											{heightData?.presentDataMap[option]}
										</p>
									</div>
								)}
							</div>
							<div
								className={`absolute bg-mudPurple opacity-[0.18] bottom-0 w-full   rounded-t-[10px]`}
								style={{
									minHeight: minHeight,
									height: (maxHeight * heightData.previousDataMap[option]) / heightData.maxCount,
								}}
							></div>
						</div>
					);
				})}
			</div>
			<div className='h-[1px] w-full bg-deselectPurple'></div>
			<div className=' flex flex-row '>
				{options?.map((option, index) => {
					return (
						<div className={`flex flex-col  mx-[10px] flex-1 min-w-[88px]`} key={index}>
							<p className='text-[12px] text-center text-darkPurple ellipsis-two-lines ' style={{ height: 36 }}>
								{option}
							</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};
