import React from 'react';
import { Oval } from 'react-loader-spinner';

const Loading = ({ primaryColor = '#FDDF6D', secondaryColor = '#DEDCF6', size = 20, stroke = 7 }) => (
	<Oval
		strokeWidth={stroke}
		strokeWidthSecondary={stroke}
		color={primaryColor}
		secondaryColor={secondaryColor}
		height={size}
		width={size}
	/>
);

export default Loading;
