import React, { useState, useEffect, useRef } from 'react';
import '../../styles/counseling/CounselingHome.css';
import { FaBell, FaPlus, FaSpinner } from 'react-icons/fa';
import magnify from '../../assets/svg/magnify.svg';
import { getCounsellingProfile, getNumberProfilesForReview, getReminders, getWellbeingReview } from '../../server/server';
import { useUser } from '../../auth/index';
import InfiniteScroll from 'react-infinite-scroll-component';
import rigthArrowCircle from '../../assets/svg/rigth-arrow-circle.svg';
import { format_day } from '../../utils/util';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WellBeingProfileModal from '../modals/WellbeingProfileModal';
import i18n from 'i18next';
import { emotions_heads } from '../../constants/Constants';
import { ReactComponent as SearchBlue } from '../../assets/svg/SearchBlue.svg';
import { ReactComponent as PlusTransparent } from '../../assets/svg/PlusTransparent.svg';
import { ReactComponent as AngleUp } from '../../assets/svg/AngleUp.svg';
import CreateWellbeingProfileModal from '../modals/CreateWellbeingProfileModal';
import Loading from '../Loading';
import { Assignment } from '../modals/AssignedActionsToMeModal';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

function CounselingHome() {
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};
	let query = useQuery();
	let assignedActionsOpenOnInit = query?.get('assignedActions');
	const [loading, setLoading] = useState(true);
	const [loadingReminders, setLoadingReminders] = useState(true);
	const [counsellingProfiles, setCounsellingProfiles] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [status, setStatus] = useState('open');
	const [counsellingSize, setCounsellingSize] = useState(0);
	const [reminders, setReminders] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [today, setToday] = useState(new Date().setHours(0));
	const [typeOption, setTypeOption] = useState(!!assignedActionsOpenOnInit);
	const [wellbeings, setWellbeings] = useState([{}]);
	const [showWellbeingModal, setShowWellbeingModal] = useState(false);
	const [loadingWellbeing, setLoadingWellbeing] = useState(true);
	const [hoveredButton, setHoveredButton] = useState(false);
	const [activatedIndex, setActivatedIndex] = useState(null);
	const { user } = useUser();
	let history = useHistory();
	const timeout = useRef(null);
	const { t, locale } = useTranslation();

	useEffect(() => {
		getCounsellingProfiles();
		fetchWellbeingReview();
		getReminder();
	}, []);

	const getCounsellingProfiles = async (
		loaded = counsellingProfiles.length,
		refresh = false,
		text = searchText,
		startStatus = status
	) => {
		const institution_id = localStorage.getItem('institution_id');
		const data = {
			institution_id: institution_id,
			loaded: loaded,
			status: startStatus,
			refresh_data: refresh,
			text: text,
		};
		const resp = await getCounsellingProfile(data);
		if (resp.status === 200) {
			let temp = refresh ? [] : counsellingProfiles;
			temp = [...temp, ...resp.data.data];
			setCounsellingProfiles(temp);
			setCounsellingSize(resp.data.size);
			setLoading(false);
			console.log('counseling : ', temp);
		}
	};
	const getReminder = async () => {
		const data = {
			institution_id: user.institution_id,
		};
		const resp = await getReminders(data);
		if (resp.status === 200) {
			setReminders(resp.data);
			setLoadingReminders(false);
			console.log('reminder : ', resp.data);
		}
	};
	const fetchWellbeingReview = async () => {
		const resp = await getWellbeingReview();
		if (resp.status === 200) {
			setLoadingWellbeing(false);
			setWellbeings(resp.data);
		}
	};

	const closeWellbeingModal = () => {
		setShowWellbeingModal(false);
	};
	const openWellbeingModal = () => {
		setShowWellbeingModal(true);
	};
	const loadNext = () => {
		if (counsellingSize <= counsellingProfiles.length) {
			setHasMore(false);
			return;
		}
		getCounsellingProfiles();
	};

	const changeStatus = (statusParam) => {
		setStatus(statusParam);
		setSearchText('');
		setHasMore(true);
		getCounsellingProfiles(0, true, '', statusParam);
	};

	const searchCounsellingProfiles = (e) => {
		setLoading(true);
		setSearchText(e.target.value);
		if (timeout !== null) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			getCounsellingProfiles(0, true, e.target.value, status);
		}, 700);
	};
	const openProfile = (profile) => {
		history.push(generatePath(`/counseling/student_profile?id=${profile?.id}`));
	};

	const activateAssignment = (index) => {
		setActivatedIndex(index);
	};

	const actionTaken = () => {
		if (wellbeings?.length != 1) {
			setActivatedIndex(null);
			fetchWellbeingReview();
		}
	};

	return (
		<div className='well-container bg-[#ECEBF8]'>
			<div className='well-tracker-container'>
				<div className='well-tracker-header'>
					<p>{t('wellbeingTracker')}</p>
					<div style={{ flex: 1 }}></div>
					<div className='well-search-container'>
						<SearchBlue />
						<input
							type='text'
							placeholder={t('search')}
							value={searchText}
							onChange={(e) => searchCounsellingProfiles(e)}
							autoComplete='off'
						/>
					</div>
					<div className='well-tracker-addbutton' onClick={openWellbeingModal}>
						<PlusTransparent style={{ color: '#FDDF6D' }} />
					</div>
				</div>
				<div id='scrollableDivProfiles' className='well-tracker-list light-small-scroll'>
					<div style={{ display: 'flex', flexDirection: 'column', paddingRight: 10 }}>
						{!loading && counsellingProfiles.length !== 0 && (
							<InfiniteScroll
								dataLength={counsellingProfiles.length}
								next={loadNext}
								hasMore={hasMore}
								scrollableTarget='scrollableDivProfiles'
							>
								{counsellingProfiles.map((profile, index) => (
									<div
										className='well-tracker-card'
										onClick={(e) => {
											e.preventDefault();
											openProfile(profile);
										}}
										key={index}
									>
										<div className='well-card-name'>
											<p>
												{profile.student.first_name} {profile.student.last_name}
											</p>
											<p>{profile.student.email}</p>
										</div>
										<div style={{ flex: 1 }}></div>
										<p className='well-class-name'>{profile.student.classesName}</p>
										<div className='well-arrow-right'>
											<AngleUp style={{ color: '#9392AD' }} />
										</div>
									</div>
								))}
							</InfiniteScroll>
						)}
					</div>
					{!loading && counsellingProfiles.length == 0 && (
						<div className='counseling-list-empty h-full flex items-center justify-center'>
							<div className='   text-[#595f9080] text-[14px]'>
								{t('Your counseling list is empty, start with adding a new counseling profile for one of your students')}
							</div>
						</div>
					)}
					{loading && (
						<div className='loading-center counseling-loader'>
							<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={75} stroke={4} />
						</div>
					)}
				</div>
			</div>
			<div className='well-reminder-container'>
				<div className='well-reminder-header'>
					<div
						className='well-reminder-button'
						onClick={() => {
							if (typeOption) setTypeOption(false);
						}}
						style={{
							flex: 1,
							backgroundColor: !typeOption ? '#5E639B' : '#fafaff',
						}}
						onMouseEnter={() => setHoveredButton(true)}
						onMouseLeave={() => setHoveredButton(false)}
					>
						<p style={{ color: !typeOption ? '#FDDF6D' : hoveredButton ? '#5e639b' : '#9392AD' }}>{t('reminder')}</p>
						<div className='wellreminder-triangle' style={{ display: !typeOption ? 'flex' : 'none' }}></div>
					</div>
					<div
						className='well-reminder-button'
						onClick={() => {
							if (!typeOption) setTypeOption(true);
						}}
						style={{
							flex: 1.5,
							backgroundColor: typeOption ? '#5E639B' : '#fafaff',
						}}
						onMouseEnter={() => setHoveredButton(true)}
						onMouseLeave={() => setHoveredButton(false)}
					>
						<p className='text-center' style={{ color: typeOption ? '#FDDF6D' : hoveredButton ? '#5e639b' : '#9392AD' }}>
							{t('assignedActions')}
						</p>
						<div className='wellreminder-triangle' style={{ display: typeOption ? 'flex' : 'none' }}></div>
					</div>
				</div>
				<div className='well-tracker-list light-small-scroll'>
					<div style={{ paddingRight: 10 }}>
						{typeOption ? (
							<>
								{!loadingWellbeing &&
									wellbeings?.map((profile, index) => (
										<Assignment
											assignment={profile}
											activated={index == activatedIndex}
											activate={activateAssignment}
											index={index}
											actionTaken={actionTaken}
											key={index}
										/>
									))}
								{!loadingWellbeing && wellbeings?.length === 0 && (
									<div className='reminder-empty'>
										<p>{t('youDontHaveAnyAssignedAction')}</p>
									</div>
								)}
								{loadingWellbeing && (
									<div className='loading-center reminder-loader'>
										<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={75} stroke={4} />
									</div>
								)}
							</>
						) : (
							<>
								{!loadingReminders &&
									reminders.length !== 0 &&
									reminders.map((reminder, index) => (
										<div className='well-tracker-card' style={{ cursor: 'default' }} key={index}>
											<div className='well-card-name'>
												<p>
													{reminder.student.first_name} {reminder.student.last_name}
												</p>
												<p>
													{t('lastNote')}: {format_day(reminder.lastNoteDate, true, i18n.language)}
												</p>
											</div>
											<div style={{ flex: 1 }}></div>
											{reminder.reminder < today && (
												<div className='well-overdue-button'>
													<p>{t('Overdue')}</p>
												</div>
											)}
											<div
												className='well-arrow-right-reminder'
												onClick={(e) => {
													e.preventDefault();
													openProfile(reminder);
												}}
											>
												<AngleUp style={{ color: '#9392AD' }} />
											</div>
										</div>
									))}
								{!loadingReminders && reminders.length === 0 && (
									<div className='reminder-empty'>
										<p>{t('youDontHaveAnyRemindersForToday')}</p>
									</div>
								)}
								{loadingReminders && (
									<div className='loading-center reminder-loader'>
										<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={75} stroke={4} />
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
			<CreateWellbeingProfileModal modalIsOpen={showWellbeingModal} closeModal={closeWellbeingModal} />
		</div>
	);
}

export default CounselingHome;
