import React, { useEffect, useContext, useState } from 'react';
import io from 'socket.io-client';
import { env, socketUrl } from '../constants/Connections';
import { useUser } from '../auth';
import useWindowFocus from 'use-window-focus';
const SocketContext = React.createContext();

const SocketProvider = (props) => {
	const [socket, setSocket] = useState();
	const [socketStates, setSocketStates] = useState({
		messages: [],
		unseenConversations: [],
		lastMessage: null,
		seenList: [],
	});
	const { user } = useUser();
	const windowFocused = useWindowFocus();

	const changeSocketState = (newState) => {
		setSocketStates((old) => ({ ...old, ...newState }));
	};

	const data = {
		socket,
		setSocket,
		socketStates,
		changeSocketState,
	};

	useEffect(() => {
		if (!user) return;
		if (env == 'local') return;

		const socketClient = io(`${socketUrl}`, {
			autoConnect: true,
			timeout: 2000,
			jsonp: true,
			transportOptions: {
				polling: {
					extraHeaders: {
						Authorization: `Bearer ${user.token}`,
					},
				},
			},
		});
		console.log(`Socket connected: ${socketClient}`);
		setSocket(socketClient);
		socketClient.emit('user_connected', user.id);
		socketClient.on('connect', (data) => {
			console.log('\nConnected to socket!');
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	socket?.off('new_message')?.on('new_message', (data) => {
		console.log('New message');
		let _socket = { ...socketStates };
		if (data.user_id !== user.id) {
			_socket['messages'].push(data);
			const conversation_id = data?.conversation_id;
			console.log(windowFocused);
			if (
				!_socket?.unseenConversations?.includes(conversation_id) &&
				(conversation_id !== _socket?.openedConversation || !windowFocused)
			) {
				_socket?.unseenConversations?.push(conversation_id);
			}
		}
		_socket['lastMessage'] = data;
		changeSocketState(_socket);
	});
	socket?.off('new_seen')?.on('new_seen', (data) => {
		let _socket = { ...socketStates };
		const json_data = data;
		if (_socket?.seenList?.map((e) => e.id)?.includes(json_data.id)) return;
		_socket['seenList'].push(json_data);
		_socket['lastMessage'] = data;
		changeSocketState(_socket);
	});
	socket?.off('self_seen')?.on('self_seen', (data) => {
		changeSocketState({ lastMessage: data });
	});

	useEffect(() => {
		return () => {
			if (!socket) return;
			socket.removeAllListeners();
			socket.close();
		};
	}, [socket]);

	return <SocketContext.Provider value={data} {...props} />;
};

function useSocket() {
	const context = useContext(SocketContext);

	if (context === undefined) {
		throw new Error(`useSocket must be used within a SocketProvider`);
	}
	return context;
}

export { SocketProvider, useSocket };
