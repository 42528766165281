import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import '../../styles/modals/ConfirmationFeedDeleteModal.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConfirmImage } from '../../assets/svg/ConfirmImage.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/DeleteIcon.svg';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import Loading from '../Loading';

function ConfirmationPrimaryClassModal({ modalIsOpen, afterOpenModal, closeModal, currentClass, confirmation, message, subMessage = '' }) {
	const { t, locale } = useTranslation();

	const [progress, setProgress] = useState(false);

	useEffect(() => {
		if (modalIsOpen) {
			Modal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [modalIsOpen]);

	const submitConfirm = () => {
		confirmation(currentClass);
		// closeModal();
	};

	return (
		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onAfterClose={() => {
				setProgress(false);
			}}
			onRequestClose={closeModal}
			className='noScroll'
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'scroll',
					paddingTop: 35,
					paddingBottom: 35,
				},
			}}
		>
			<div className='student-info-modal'>
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<div className='close-modal-icon ' onClick={() => closeModal()}>
						<CloseModalIcon />
					</div>
				</div>
				<div className='bg-whiteContrast rounded-xl flex flex-col  items-center justify-between  mt-[20px]'>
					<p className='text-[#FC4C59] text-[32px] leading-[42px] font-bold text-center max-w-[430px] flex-wrap'>{message}</p>
					<p className='text-[#FC4C59] text-[14px] leading-[17px] font-medium'>{subMessage}</p>
					<ConfirmImage />
					<div className='bg-white px-[30px] py-[40px] rounded-[20px] flex flex-row justify-between items-center w-full'>
						<div className=' flex flex-col '>
							<p className='text-mudPurple text-[18px] leading-[23px] font-medium'>{t('className')} </p>
							<p className='text-deepPurple text-[32px] leading-[42px] font-bold'>{currentClass?.name}</p>
						</div>
						<div className=' flex flex-col '>
							<p className='text-mudPurple text-[18px] leading-[23px] font-medium'>{t('year')}</p>
							<p className='text-deepPurple text-[32px] leading-[42px] font-bold'>{currentClass?.year}</p>
						</div>
						<div className=' flex flex-col '>
							<p className='text-mudPurple text-[18px] leading-[23px] font-medium'>{t('numberOfStudents')}</p>
							<p className='text-deepPurple text-[32px] leading-[42px] font-bold'>
								{currentClass?.chart_data?.number_of_users}
							</p>
						</div>
					</div>

					<div className='flex flex-row justify-center gap-5 items-center w-full mt-5'>
						<button
							className='bg-[#FC4C59] pointer flex flex-row items-center justify-center   rounded-full px-[25px] min-w-[185px] h-[50px]'
							onClick={() => {
								setProgress(true);
								submitConfirm();
							}}
							disabled={progress}
						>
							{progress ? (
								<Loading />
							) : (
								<div className=' flex flex-row items-center justify-center gap-[10px]'>
									<DeleteIcon style={{ color: '#FFFFFF' }} />
									<p className='text-white text-[18px] leading-[23px] font-semibold'>{t('Yes, Delete')}</p>
								</div>
							)}
						</button>
						<button
							className='bg-deepPurple pointer flex flex-row items-center justify-center   rounded-full  px-[25px] h-[50px]'
							onClick={() => closeModal()}
						>
							<p className='text-white text-[18px] leading-[23px] font-semibold'>{t('No, cancel')}</p>
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default ConfirmationPrimaryClassModal;
