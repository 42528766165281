import React from 'react';
import { format_day } from '../utils/util';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

function QuestionTask({ task }) {
	const { t, locale } = useTranslation();
	return (
		<div className='studnet-task-item'>
			<div className='task-poll-header'>
				<p style={{ color: '#35316a', fontSize: 17, fontWeight: 600 }}>{task.content?.title}</p>
			</div>
			<div className='task-poll-body'>
				{task.answers.map((answer, index) => (
					<div className='task-poll-answer' key={index}>
						<p style={{ color: 'white', padding: 10, fontSize: 16, fontWeight: 600, margin: 0 }}>{answer}</p>
					</div>
				))}
			</div>
			<div className='task-poll-footer'>
				<p style={{ fontSize: 15, color: '#8f8fa1', fontWeight: 600, textAlign: i18n.language == 'ar' ? 'right' : 'left' }}>
					{t('question')}
				</p>
				<p style={{ marginTop: 5 }}>{format_day(task.created_date, true, i18n.language)}</p>
			</div>
		</div>
	);
}

export default QuestionTask;
