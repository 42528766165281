import React from 'react';
import Loading from '../Loading';

import { ReactComponent as GreenArrowUp } from '../../assets/svg/GreenArrowUp.svg';
import { ReactComponent as GreyArrowDown } from '../../assets/svg/GreyArrowDown.svg';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

function ClassroomEngagementTrendItem({
	loading = false,
	percentage = 0,
	trend = 0,
	smallTitleText,
	hoverPlaceholder = '',
	trendPlaceholder,
	title = '',
	showTrend = true,
}) {
	const { t, locale } = useTranslation();
	return (
		<div className='flex gap-[10px] items-center flex-1'>
			<div className='flex flex-row items-center gap-[5px]'>
				<div className='cursor-help' data-tip={hoverPlaceholder} data-for={title}>
					<div className='h-[45px] min-w-[45px] px-[2px] bg-[#7b7ac3] rounded-full flex justify-center items-center text-[#FDDF6D] font-semibold'>
						{loading ? <Loading /> : `${percentage ?? 0}%`}
					</div>
					{!loading && (
						<ReactTooltip
							id={title}
							arrowColor='#5E639B'
							effect='solid'
							backgroundColor='#5E639B'
							className='wellbeing-tooltip'
							classNameArrow='tooltip-arrow'
						/>
					)}
				</div>
				{showTrend && (
					<div
						className={`flex flex-col items-center cursor-help ${loading ? 'opacity-0' : ''}`}
						// className='absolute right-0 -top-[5px] rounded-full ml-[5px] cursor-help'
						data-tip={trendPlaceholder}
						data-for={`studentEngagementTrend`}
					>
						<ReactTooltip
							id={`studentEngagementTrend`}
							arrowColor='#5E639B'
							effect='solid'
							backgroundColor='#5E639B'
							className='wellbeing-tooltip'
							classNameArrow='tooltip-arrow'
						/>
						{trend > 0 ? (
							<GreenArrowUp style={{ width: 30, height: 30 }} />
						) : (
							<GreyArrowDown style={{ width: 30, height: 30 }} />
						)}
						<span className='text-[14px] font-medium w-[38px] text-center '>
							{trend !== 0 && <span>{trend > 0 ? '+' : '-'}</span>}
							{Math.abs(trend)}%
						</span>
					</div>
				)}
			</div>
			<p className={` !font-medium text-[#5E639B] max-w-[120px] flex-1  ${smallTitleText ? 'text-sm ' : 'text-[16px]'}`}>{title}</p>
		</div>
	);
}

export default ClassroomEngagementTrendItem;
