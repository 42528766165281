import React, { useState, useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FaTimes, FaChevronLeft, FaRegCheckCircle, FaCheckSquare, FaChevronRight } from 'react-icons/fa';
import '../../styles/content/CreateSurvey.css';
import { create, deleteById, update, getById } from '../../server/server';
import { useUser } from '../../auth';
import { notify } from '../../utils/util';
import ConfirmationModal from '../modals/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useSocket } from '../../context/socket';

function CreateSurvey() {
	let history = useHistory();
	const { socketStates, socket, changeSocketState } = useSocket();
	const { t, locale } = useTranslation();
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	};
	let query = useQuery();
	const { user, setUser } = useUser();
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const _removeValue = (arr, x) => {
		arr.splice(x, 1);
		return arr;
	};
	const DEFAULT_SECTIONS = {
		title: '',
		description: '',
		questions: [
			{
				title: '',
				type: 'yesNo',
				options: ['Yes', 'No'],
			},
		],
	};
	const [sections, setSections] = useState([DEFAULT_SECTIONS]);
	const [surveyTitle, setSurveyTitle] = useState('');
	const [surveyDesc, setSurveyDesc] = useState('');
	const [deleteModalOpen, setDelModalOpen] = useState(false);
	const [isError, setIsError] = useState(false);
	const loadSurvey = async () => {
		const response = await getById({
			collection: 'surveys',
			id: query.get('id'),
		});
		if (response.status === 200) {
			setSurveyTitle(response.data.title);
			setSurveyDesc(response.data.description);
			setSections(response.data.sections);
		}
	};

	useEffect(() => {
		console.log(query.get('id'));
		if (query.get('id')) {
			loadSurvey();
		}
	}, []);

	useEffect(() => {
		console.log('sekcije', sections);
	}, [sections]);
	const handleQuestionTypeChange = (e, index, Qindex) => {
		if (e.target.value === 'options') {
			setSections(
				(state) => ((state[index].questions[Qindex] = { ...state[index].questions[Qindex], options: [''], type: 'options' }), state)
			);
		} else if (e.target.value === 'frequency') {
			setSections(
				(state) => (
					(state[index].questions[Qindex] = {
						...state[index].questions[Qindex],
						options: ['Never', 'Sometimes', 'Often', 'Always'],
						type: 'options',
					}),
					state
				)
			);
		} else {
			setSections(
				(state) => (
					(state[index].questions[Qindex] = {
						...state[index].questions[Qindex],
						options: ['Yes', 'No'],
						type: e.target.value,
					}),
					state
				)
			);
		}
		forceUpdate();
	};

	const handleAddQuestion = (index) => {
		setSections((state) => ((state[index].questions = [...state[index].questions, DEFAULT_SECTIONS.questions[0]]), state));
		forceUpdate();
	};
	const handleQuestionRemove = (index, Qindex) => {
		setSections((state) => ((state[index].questions = _removeValue(state[index].questions, Qindex)), state));
		forceUpdate();
	};
	const handleAddSection = () => {
		setSections([
			...sections,
			{
				title: '',
				description: '',
				questions: [
					{
						title: '',
						type: 'yesNo',
						options: ['Yes', 'No'],
					},
				],
			},
		]);
	};
	const handleSectionRemove = (index) => {
		setSections(_removeValue(sections, index));
		forceUpdate();
	};
	const closeDelModal = () => {
		setDelModalOpen(false);
	};
	const handleSave = async () => {
		if (surveyTitle === '') {
			setIsError(true);
		} else {
			if (query.get('id')) {
				const resp = await update({
					collection: 'surveys',
					data: {
						targetSchools: [user.institution_id],
						user_id: user.id,
						title: surveyTitle,
						description: surveyDesc,
						sections: sections,
						id: query.get('id'),
					},
				});
				if (resp.status === 200) {
					notify('Surveys are updated!');
					history.push('/content/');
				}
			} else {
				const response = await create({
					collection: 'surveys',
					data: {
						targetSchools: [user.institution_id],
						user_id: user.id,
						title: surveyTitle,
						description: surveyDesc,
						sections: sections,
					},
				});
				if (response.status === 200) {
					const data = response.data;
					socket?.emit('new_content', data);
					notify('Surveys are created!');
					history.push('/content/');
				}
			}
		}
	};
	const handleAddOption = (index, Qindex) => {
		setSections((state) => ((state[index].questions[Qindex].options = [...state[index].questions[Qindex].options, ' ']), state));
		forceUpdate();
	};
	const handleOptionRemove = (index, Qindex, Oindex) => {
		setSections(
			(state) => ((state[index].questions[Qindex].options = _removeValue(state[index].questions[Qindex].options, Oindex)), state)
		);
		forceUpdate();
	};
	const handleOptionChange = (e, index, Qindex, Oindex) => {
		const newOptions = [...sections[index].questions[Qindex].options];
		newOptions.splice(Oindex, 1, e.target.value);

		setSections((state) => ((state[index].questions[Qindex].options = newOptions), state));
		forceUpdate();
	};
	const handleQuestionTitleChange = (e, index, Qindex) => {
		const newQuestions = [...sections[index].questions];
		newQuestions.splice(Qindex, 1, {
			...newQuestions[Qindex],
			title: e.target.value,
		});
		setSections((state) => ((state[index].questions = newQuestions), state));
		forceUpdate();
	};
	const handleSectionTitleChange = (e, index) => {
		setSections((state) => ((state[index].title = e.target.value), state));
		forceUpdate();
	};
	const handleSectionDescChange = (e, index) => {
		setSections((state) => ((state[index].description = e.target.value), state));
		forceUpdate();
	};
	const handleDelete = async () => {
		if (query.get('id')) {
			const response = await deleteById({
				collection: 'surveys',
				id: query.get('id'),
			});
			if (response.status === 200) {
				notify('Survey is deleted!');
				history.push('/content/');
			}
		}
	};
	const handleDeleteClick = () => {
		if (query.get('id')) {
			setDelModalOpen(true);
		}
	};

	return (
		<div className={`content relative ${i18n.language == 'ar' ? 'ar-lang' : ''}`} style={{ paddingTop: '5px' }}>
			<div className='surveys-header mt-6'>
				<h1>{t('survey')}</h1>
			</div>
			<span
				className='back_button_poll'
				style={i18n.language === 'ar' ? { position: 'absolute', top: 30, right: 30 } : { position: 'absolute', top: 30, left: 30 }}
				onClick={() => {
					history.push('/content/');
				}}
			>
				{i18n.language === 'ar' ? <FaChevronRight id='back-program-arrow' /> : <FaChevronLeft id='back-program-arrow' />}
				{t('back')}
			</span>
			<div className='survey-container'>
				<div className='survey-header'>
					<div className='form-body'>
						<div className='row'>
							<div className='col-md-12'>
								<div className='card_header flex'>
									<textarea
										name=''
										placeholder={t('typeASurveyTitle')}
										rows='1'
										onFocus={() => setIsError(false)}
										value={surveyTitle}
										onChange={(e) => setSurveyTitle(e.target.value)}
										className={isError ? 'error-title' : ''}
										style={{
											backgroundColor: '#42405f26',
											padding: '12px 16px',
											borderRadius: '8px',
											margin: '40px 0 10px 0',
											lineHeight: '45px',
										}}
									></textarea>
								</div>
							</div>
							<div className='col-md-12'>
								<div className='card_description'>
									<textarea
										name=''
										placeholder={t('addASurveyDescription')}
										rows='2'
										value={surveyDesc}
										id='autoExpand_desc'
										onChange={(e) => setSurveyDesc(e.target.value)}
									></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
				{sections.map((survey, index) => {
					return (
						<div className={`survey-section ${i18n.language == 'ar' ? 'text-right' : ''}`} key={index}>
							<label htmlFor='' className='survey-section-label' style={i18n.language == 'ar' ? { left: 0 } : {}}>
								{t('section')} {index + 1}
							</label>
							<label htmlFor='' className='survey-section-label-delete' onClick={() => handleSectionRemove(index)}>
								<FaTimes style={{ display: 'flex' }} />
							</label>
							<div className='survey-section-header'>
								<div className='survey-section-header-row'>
									<label htmlFor='Title' className='survey-section-title-label'>
										{t('title')}:
									</label>
									<input
										type='text'
										placeholder={t('enterSectionTitleHere')}
										style={{ margin: '3px 0 3px 5px' }}
										value={survey?.title}
										onChange={(e) => handleSectionTitleChange(e, index)}
									/>
								</div>
								<div className='survey-section-header-row'>
									<label htmlFor='Title' className='survey-section-title-label'>
										{t('description')}:
									</label>
									<input
										type='text'
										placeholder={t('enterSectionDescriptionHere')}
										style={{ margin: '3px 0 3px 5px' }}
										value={survey?.description}
										onChange={(e) => handleSectionDescChange(e, index)}
									/>
								</div>
							</div>
							<div className='survey-section-body'>
								<label htmlFor='' style={{ fontSize: '1.1em' }}>
									{t('question')}:
								</label>
								{survey?.questions.map((question, Qindex) => {
									return (
										<div
											className='survey-section-question-body'
											style={i18n.language == 'ar' ? { borderLeft: 'none', borderRight: '3px solid #fedbb9' } : {}}
											key={Qindex}
										>
											<div className='survey-section-question'>
												<label htmlFor=''>Q{Qindex + 1}</label>
												<div style={{ flex: '1' }}>
													<input
														type='text'
														className='survey-question-title'
														placeholder={t('enterQuestionHere')}
														value={question.title}
														onChange={(e) => handleQuestionTitleChange(e, index, Qindex)}
													/>
												</div>
												<button
													className='survey-remove-question-button'
													onClick={() => handleQuestionRemove(index, Qindex)}
												>
													<FaTimes />
												</button>
											</div>
											<div className='survey-section-question-info'>
												<label htmlFor=''>Type:</label>
												<div className={`survey-type-container ${i18n.language == 'ar' ? 'text-right' : ''}`}>
													<select
														name='cars'
														id='cars'
														defaultValue={question.type}
														onChange={(e) => handleQuestionTypeChange(e, index, Qindex)}
													>
														<option value='yesNo'>{t('yesNo')}</option>
														<option value='frequency'>{t('Frequency')}</option>
														<option value='options'>{t('options')}</option>
														<option value='openAnswer'>{t('openAnswer')}</option>
													</select>
												</div>
												<button className='survey-add-question-button' onClick={() => handleAddQuestion(index)}>
													{t('addQuestion')}
												</button>
											</div>
											{question?.type === 'options' && (
												<div className='survey-options'>
													<label htmlFor='Options'>{t('options')}: </label>
													<div className='survey-options-list'>
														{question?.options.map((option, Oindex) => {
															return (
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'row',
																		alignItems: 'center',
																	}}
																	key={Oindex}
																>
																	<input
																		type='text'
																		className='survey-option'
																		value={option}
																		onChange={(e) => handleOptionChange(e, index, Qindex, Oindex)}
																	/>
																	<span
																		style={{
																			cursor: 'pointer',
																			paddingLeft: 10,
																		}}
																		onClick={() => handleOptionRemove(index, Qindex, Oindex)}
																	>
																		<FaTimes />
																	</span>
																</div>
															);
														})}
														<button className='add-options' onClick={() => handleAddOption(index, Qindex)}>
															{t('addOption')}
														</button>
													</div>
												</div>
											)}
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
				<div className='section-footer'>
					<button className='add-section-button' onClick={() => handleAddSection()}>
						{t('addSection')}
					</button>
					<button className='add-section-button delete-survey' onClick={() => handleDeleteClick()}>
						{t('deleteSurvey')}
					</button>
					<button className='add-section-button save-survey' onClick={() => handleSave()}>
						{t('save')}
					</button>
				</div>
			</div>
			<ConfirmationModal
				modalIsOpen={deleteModalOpen}
				closeModal={closeDelModal}
				confirmation={handleDelete}
				message={t('doYouWantToRemoveSurvey')}
			/>
		</div>
	);
}

export default CreateSurvey;
