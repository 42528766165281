import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import '../../styles/management/ManageTeacher.css';
import '../../styles/management/ClassList.css';
import '../../styles/management/TeacherList.css';
import '../../styles/management/ClassHome.css';
import '../../styles/management/ManagementHome.css';
import { ReactComponent as SearchIcon } from '../../assets/svg/SearchIcon.svg';
import { ReactComponent as EditContent } from '../../assets/svg/EditContent.svg';
import { ReactComponent as AngleUp } from '../../assets/svg/AngleUp.svg';
import { ReactComponent as Save } from '../../assets/svg/Save.svg';
import { ReactComponent as Plus } from '../../assets/svg/Plus.svg';
import { ReactComponent as DeleteIcon } from '../../assets/svg/DeleteIcon.svg';
import { notify } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../auth';
import { searchClasses, get_students, updateClassAsync, deleteClassAsync } from '../../server/server';
import StudentInfoModal from '../modals/StudentInfoModal';
import DonutChartAureole from './DonutChartAureole';
import Loading from '../Loading';
import CreateNewClass from './CreateNewClass';
import StudentPrimaryInfoModal from '../modals/StudentPrimaryInfoModal';
import DonutPrimary from './DonutPrimary';
import ConfirmationPrimaryClassModal from '../modals/ConfirmationPrimaryClassModal';
import SuccessModal from '../modals/SuccessModal';
import StudentsTable from './StudentsTable';

const ClassManagement = forwardRef((prop, ref) => {
	const [selectedStudentListIndex, setSelectedStudentListIndex] = useState(-1);
	const [loading, setLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [studentsText, setStudentsText] = useState('');
	const [loadingMore, setLoadingMore] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [classes, setClasses] = useState([]);
	const { user, setUser } = useUser();
	const searchRef = useRef();
	const { t, locale } = useTranslation();
	const [classText, setClassText] = useState('');
	const [yearText, setYearText] = useState('');
	const [newClassFlag, setNewClassFlag] = useState(false);
	const [currentClass, setcurrentClass] = useState(null);
	const [student, setStudent] = useState({});
	const [deleteClassModalOpen, setDeleteClassModalOpen] = useState(false);
	const [successModalOpen, setSuccessModalOpen] = useState(false);
	const [studentInfoOpen, setStudentInfoOpen] = useState(false);
	const [studentPrimaryInfoOpen, setStudentPrimaryInfoOpen] = useState(false);
	const [loadingStudents, setLoadingStudents] = useState(false);
	const [pagination, setPagination] = useState({
		start: 0,
		page_size: 10,
		current_page: 1,
		end: 0,
		size: 0,
	});

	useEffect(() => {
		loadClasses();
	}, []);

	useImperativeHandle(ref, () => ({ addNewClass, searchClass }));

	const loadClasses = async (text = searchText.toLowerCase(), number = classes.length) => {
		if (classes.length === 0) setLoading(true);
		const institution_id = localStorage.getItem('institution_id');
		const data_classes = {
			institution_id: institution_id,
			text,
			loaded: number,
		};
		const resp_classes = await searchClasses(data_classes);
		if (resp_classes.status === 200 && resp_classes.data.length != 0) {
			const resp_data = resp_classes.data;
			const start = classes.length;
			const end = start + resp_data['list']?.length;
			setPagination({
				start: pagination.start,
				page_size: 10,
				current_page: pagination.current_page,
				end: end,
				pages: [],
				size: resp_data['size'],
			});

			let classesTemp = number === 0 ? [...resp_data['list']] : [...classes, ...resp_data['list']];
			for (let i = start; i < classesTemp.length; i++) {
				if (!!classesTemp[i].chart_data?.number_of_users) {
					const percentage = classesTemp[i].chart_data?.logged_in_users / classesTemp[i].chart_data?.number_of_users;
					classesTemp[i].percentage = percentage;
				}
				classesTemp[i].editable = false;
			}
			setClasses(classesTemp);
			setLoading(false);
			setLoadingMore(false);
		} else {
			setClasses([]);
			setLoading(false);
		}
	};

	const loadMoreClasses = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (pagination.end < pagination.page_size) return;
		setLoadingMore(true);
		pagination.current_page++;
		pagination.start += pagination.page_size;
		loadClasses();
	};

	const searchClass = (text) => {
		classes.splice(0, classes.length);
		setClasses(classes);
		pagination.start = 0;
		pagination.current_page = 1;
		loadClasses(text);
	};

	const update_class = async (editClass = currentClass, notification = true) => {
		setProcessing(true);
		delete editClass?.editable;
		const resp = await updateClassAsync(editClass);
		if (resp.status === 200) {
			refreshListOfClasses(editClass);
			setProcessing(false);
		} else {
			notify(t('classIsNotUpdated'));
			setProcessing(false);
		}
	};
	const loadStudentsList = async (_class, update_class_list = false) => {
		let selectedClass = JSON.parse(JSON.stringify(_class));
		setLoadingStudents(true);
		const data = {
			institution_id: selectedClass?.institution_id,
			class_name: selectedClass?.name,
		};
		const resp = await get_students(data);
		if (resp.status === 200) {
			let data_students = resp.data['list'];
			const signed = data_students.filter((e) => !!e.loggedIn);
			const percentage = data_students?.length === 0 ? 0 : signed.length / data_students?.length;
			let tempClass = {
				...selectedClass,
				chart_data: { number_of_users: data_students.length },
				students: data_students,
				percentage: percentage,
			};
			if (update_class_list) updateListOfClasses(resp.data['list'], percentage);
			setcurrentClass(tempClass);
			setLoadingStudents(false);
		} else {
			setLoadingStudents(false);
		}
	};
	const updateListOfClasses = (students, percentage) => {
		let newList = [...classes];
		let updatedClasses = newList.map((item) => {
			if (item.id === currentClass.id) {
				if (!!item.chart_data) item.chart_data.number_of_users = students?.length;
				else item = { ...item, chart_data: { number_of_users: students.length } };
				item.percentage = percentage;
			}
			return item;
		});
		setClasses(updatedClasses);
	};

	const reloadListOfClasses = () => {
		classes.splice(0, classes.length);
		setClasses(classes);
		pagination.start = 0;
		pagination.current_page = 1;
		loadClasses();
	};
	const openEditClass = (selectedIndex) => {
		let newClasses = classes.map((item, index) => {
			if (index === selectedIndex) item.editable = true;
			else item.editable = false;
			return item;
		});
		setClasses(newClasses);
	};
	const addNewClass = () => {
		if (newClassFlag) {
			if (currentClass === null) return;
			else reloadListOfClasses();
		}
		setNewClassFlag(true);
		setcurrentClass(null);
		setSelectedStudentListIndex(-1);
		setClassText('');
		setYearText('');
		closeEditClass();
	};
	const closeStudentInfo = () => {
		setStudent({});
		setStudentInfoOpen(false);
	};
	const closeStudentPrimaryInfo = () => {
		setStudent({});
		setStudentPrimaryInfoOpen(false);
	};
	const openStudentInfo = () => {
		setStudent({});
		if (currentClass?.primary === true) setStudentPrimaryInfoOpen(true);
		else setStudentInfoOpen(true);
	};
	const openStudent = (student) => {
		setStudent(student);
		if (student?.primary === true) setStudentPrimaryInfoOpen(true);
		else setStudentInfoOpen(true);
		closeEditClass();
	};
	const closeDelClassModal = () => {
		setDeleteClassModalOpen(false);
	};
	const closeSuccessModal = () => {
		setSuccessModalOpen(false);
	};
	const closeEditClass = () => {
		const newClasses = classes.map((item, index) => {
			item.editable = false;
			return item;
		});
		setClasses(newClasses);
	};
	const editClass = () => {
		let newClass = { ...currentClass, name: classText, year: yearText };
		update_class(newClass);
	};
	const closeStudentsList = () => {
		setSelectedStudentListIndex(-1);
		setcurrentClass(null);
		closeEditClass();
	};
	const handleChangeStudentList = (e) => {
		setStudentsText(e.target.value);
	};
	const myFilter = (item) => {
		return (
			(item?.first_name?.toLowerCase() + ' ' + item?.last_name?.toLowerCase()).includes(studentsText.toLowerCase()) ||
			item?.email?.toLowerCase().includes(studentsText.toLowerCase())
		);
	};
	const refreshListOfClasses = (editClass) => {
		let newList = [...classes];
		let updatedClasses = newList.map((item) => {
			if (item.id === editClass.id) return editClass;
			else return item;
		});
		setClasses(updatedClasses);
	};
	const removeClass = async (classForDelete) => {
		if (classForDelete !== null || classForDelete !== undefined) {
			const resp = await deleteClassAsync(classForDelete);
			if (resp.status === 200) {
				setSuccessModalOpen(true);
				setTimeout(() => {
					setSuccessModalOpen(false);
				}, 3500);
				setDeleteClassModalOpen(false);
				let newClasses = classes?.filter((item) => item.id !== classForDelete.id);
				setClasses(newClasses);
				setSelectedStudentListIndex(-1);
				setNewClassFlag(false);
			}
		} else {
		}
	};
	const updateClassStudents = async (classOutgoing, classIncoming) => {
		const dataOutgoing = {
			institution_id: user.institution_id,
			class_name: classOutgoing.name,
		};
		const dataIncoming = {
			institution_id: user.institution_id,
			class_name: classIncoming.name,
		};
		const studentsRespOutgoing = await get_students(dataOutgoing);
		const studentsRespIncoming = await get_students(dataIncoming);
		if (studentsRespOutgoing.status === 200 && studentsRespIncoming.status === 200) {
			const outgoingStudents = studentsRespOutgoing.data['list'];
			const incomingStudents = studentsRespIncoming.data['list'];
			let outgoingClass = { ...classOutgoing, chart_data: { number_of_users: outgoingStudents.length } };
			let incomingClass = { ...classIncoming, chart_data: { number_of_users: incomingStudents.length } };
			incomingClass.students = incomingStudents.sort((a, b) => {
				if (a.first_name > b.first_name) return 1;
				return -1;
			});
			const signedIcoming = incomingClass.students.filter((e) => !!e.loggedIn);
			incomingClass.percentage = incomingClass.students.length == 0 ? 0 : signedIcoming.length / incomingClass.students.length;
			incomingClass.editable = false;
			outgoingClass.students = outgoingStudents.sort((a, b) => {
				if (a.first_name > b.first_name) return 1;
				return -1;
			});
			const signedOutcoming = outgoingClass.students.filter((e) => !!e.loggedIn);
			outgoingClass.percentage = outgoingClass.students.length == 0 ? 0 : signedOutcoming.length / outgoingClass.students.length;
			outgoingClass.editable = false;
			let newClasses = classes?.map((item) => {
				if (item.id == incomingClass.id) return incomingClass;
				if (item.id == outgoingClass.id) return outgoingClass;
				else return item;
			});
			setClasses(newClasses);
			loadStudentsList(currentClass);
		} else {
			notify(t('yourDataIsNotUpdated'));
		}
	};
	const handleDeleteClassButton = (item) => {
		if (newClassFlag === true && currentClass !== null) {
			reloadListOfClasses();
		}
		setNewClassFlag(false);
		setcurrentClass(item);
		setDeleteClassModalOpen(true);
		setSelectedStudentListIndex(-1);
		closeEditClass();
	};
	const handleEditClassButton = (item, index) => {
		if (newClassFlag === true && currentClass !== null) {
			reloadListOfClasses();
		}
		setNewClassFlag(false);
		setClassText(item.name);
		setYearText(item.year);
		openEditClass(index);
		setcurrentClass(item);
		if (selectedStudentListIndex !== -1 && selectedStudentListIndex !== index) setSelectedStudentListIndex(-1);
	};
	const handleOpenStudentsListButton = (item, index) => {
		setStudentsText('');
		if (newClassFlag === true && currentClass !== null) {
			reloadListOfClasses();
			setNewClassFlag(false);
			loadStudentsList(item);
			setSelectedStudentListIndex(index);
		} else {
			loadStudentsList(item);
			setNewClassFlag(false);
			setSelectedStudentListIndex(index);
			if (!item?.editable) closeEditClass();
		}
	};

	return (
		<div>
			{newClassFlag && (
				<CreateNewClass
					setNewClassFlag={setNewClassFlag}
					currentClass={currentClass}
					setcurrentClass={setcurrentClass}
					openStudent={openStudent}
					openStudentInfo={openStudentInfo}
					reloadClasses={reloadListOfClasses}
					setDeleteClassModalOpen={() => setDeleteClassModalOpen(true)}
				/>
			)}
			{classes.map((item, index) => (
				<div key={item.id}>
					<div className='management-frame' style={{ marginTop: 20, paddingTop: 40, paddingBottom: 40 }}>
						<div className='classes-lists-container '>
							<div className='flex flex-row items-center'>
								{!item?.primary && (
									<div className='management-statistics' style={{ width: 170 }}>
										<div className='management-statistics-items' style={{ marginTop: 0 }}>
											<div className='management-statistics-point'></div>
											<p>
												{Math.round(item.percentage * 100)}% {t('Registered users')}
											</p>
										</div>
										<div className='management-statistics-items' style={{ marginTop: 0 }}>
											<div className='management-statistics-point' style={{ backgroundColor: '#FFD069' }}></div>
											<p>
												{100 - Math.round(item.percentage * 100)}% {t('Unregistered users')}
											</p>
										</div>
									</div>
								)}
								{!item?.primary ? <DonutChartAureole percentage={item.percentage} /> : <DonutPrimary />}
							</div>
							<div className='flex flex-row items-center'>
								<div className='class-data'>
									<p>{t('Class')} </p>
									{!item.editable && <p>{item.name}</p>}
									{item.editable && (
										<input
											className='edit-class-input'
											type='text'
											value={classText}
											autoFocus
											onChange={(e) => setClassText(e.target.value)}
										/>
									)}
								</div>
								<div className='class-data'>
									<p>{t('year')}</p>
									{!item.editable && <p>{item.year}</p>}
									{item.editable && (
										<input
											type='text'
											value={yearText}
											className='edit-class-input'
											onChange={(e) => setYearText(e.target.value)}
										/>
									)}
								</div>
								<div className='class-data'>
									<p>{t('numberOfStudents')}</p>
									<p className='text-center self-center'>{item.chart_data?.number_of_users}</p>
								</div>
							</div>
							<div style={{ flex: 1 }}></div>
							<div
								className='h-[56px] min-w-[56px] bg-whiteContrast rounded-full cursor-pointer flex items-center justify-center mr-3 border border-[#FC4C59aa] text-[#FC4C59aa] hover:text-[#FC4C59] hover:border-[#FC4C59]'
								onClick={() => handleDeleteClassButton(item)}
							>
								<DeleteIcon />
							</div>

							{!item.editable && (
								<div
									className='transparent-small-button fill-[#9392ad99] hover:fill-mudPurple'
									style={{ marginRight: 12 }}
									onClick={(e) => {
										e.preventDefault();
										handleEditClassButton(item, index);
									}}
								>
									<EditContent style={{ fill: 'inherit' }} />
								</div>
							)}
							{item.editable && (
								<button
									className='transparent-small-button'
									style={{ marginRight: 12, backgroundColor: '#5E639B' }}
									onClick={() => editClass()}
									disabled={processing}
								>
									{processing ? <Loading /> : <Save style={{ color: '#FDDF6D' }} />}
								</button>
							)}
							{item.editable && (
								<div
									className='h-[56px] min-w-[56px] bg-whiteContrast rounded-full cursor-pointer flex items-center justify-center mr-3  border border-[#9392ad99] hover:border-mudPurple text-[#9392ad99] hover:text-mudPurple'
									onClick={() => closeEditClass()}
								>
									<Plus />
								</div>
							)}
							{selectedStudentListIndex == index ? (
								<div
									className='transparent-small-button'
									style={{ backgroundColor: '#BEBECE' }}
									onClick={() => closeStudentsList()}
								>
									<div style={{ transform: 'rotate(180deg)' }}>
										<AngleUp style={{ color: 'white' }} />
									</div>
								</div>
							) : (
								<div
									className='transparent-small-button text-[#9392ad99] hover:text-mudPurple'
									onClick={() => handleOpenStudentsListButton(item, index)}
								>
									<AngleUp />
								</div>
							)}
						</div>

						{selectedStudentListIndex == index && (
							<div className='management-lists-container' style={{ marginTop: 49 }}>
								<div className='management-lists-search'>
									<p>{t('listOfStudents')}</p>
									<div
										className='stroke-deselectPurple hover:stroke-deepPurple flex flex-row items-center border-deselectPurple border py-[0.65rem] px-4 w-[18rem] rounded-full'
										style={{ cursor: 'pointer' }}
									>
										<input
											type='text'
											value={studentsText}
											className='w-full h-full text-mudPurple font-semibold text-sm'
											style={{ outlineStyle: 'none' }}
											onChange={handleChangeStudentList}
											ref={searchRef}
											placeholder={t('search')}
										/>
										<SearchIcon onClick={() => searchRef.current.focus()} />
									</div>
									<div
										className='transparent-button'
										onClick={() => openStudentInfo()}
										style={{ height: 44, padding: 13 }}
									>
										{t('addAStudent')}
									</div>
								</div>
								<StudentsTable
									selectedClass={currentClass}
									loading={loadingStudents}
									openStudent={openStudent}
									myFilter={myFilter}
								/>
							</div>
						)}
					</div>
				</div>
			))}
			{pagination.end < pagination.size && !loading && !loadingMore && (
				<div style={{ marginTop: 20 }}>
					<div className='transparent-button' onClick={loadMoreClasses} style={{ margin: 'auto' }}>
						{t('loadMoreClasses')}
					</div>
				</div>
			)}
			{(loading || loadingMore) && (
				<div className='loading-center' style={loadingMore ? { paddingTop: 30 } : { paddingTop: 120 }}>
					<Loading primaryColor='#5E639B' secondaryColor='#5E639B' size={75} stroke={4} />
				</div>
			)}
			<StudentInfoModal
				studentInfoOpen={studentInfoOpen}
				closeStudentInfo={closeStudentInfo}
				student={student}
				currentClass={currentClass}
				reloadStudentsList={() => loadStudentsList(currentClass, true)}
				updateClassStudents={updateClassStudents}
			/>
			<StudentPrimaryInfoModal
				studentInfoOpen={studentPrimaryInfoOpen}
				closeStudentInfo={closeStudentPrimaryInfo}
				student={student}
				currentClass={currentClass}
				reloadStudentsList={() => loadStudentsList(currentClass, true)}
			/>
			<ConfirmationPrimaryClassModal
				modalIsOpen={deleteClassModalOpen}
				closeModal={closeDelClassModal}
				confirmation={removeClass}
				currentClass={currentClass}
				message={t('Are you sure you want to delete class?')}
				subMessage={t('All Student in this class will be deleted')}
			/>
			<SuccessModal
				modalIsOpen={successModalOpen}
				closeModal={closeSuccessModal}
				confirmation={closeSuccessModal}
				currentClass={currentClass}
				message={t('Success')}
				subMessage={t('Class successfully deleted')}
			/>
		</div>
	);
});

export default ClassManagement;
