import React, { useEffect, useRef } from 'react';
import { ReactComponent as Stop } from '../../../assets/svg/Stop.svg';
import { ReactComponent as EditContent } from '../../../assets/svg/EditContent.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/DownloadIcon.svg';
import { ReactComponent as DeleteContentIcon } from '../../../assets/svg/DeleteContentIcon.svg';
import { ReactComponent as NotFoundIcon } from '../../../assets/svg/NotFoundIcon.svg';
import { baseUrl } from '../../../constants/Connections';
import { useTranslation } from 'react-i18next';
import { capitaliseFirstLetter, format_day, translateTargetText } from '../../../utils/util';
import ProgressBar from '../../../components/ProgressBar';
import ContentSurveyQuestion from './ContentSurveyQuestion';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { getLiveResults, getSurveyResults, stopContent } from '../../../server/server';
import { useUser } from '../../../auth';
import Loading from '../../Loading';
import ContentResultFilter from './ContentResultFilter';
import i18n from '../../../i18n';

function ContentSurveyResults({ content, setRefresh, deleteContent }) {
	const { user } = useUser();
	const [exporting, setExporting] = useState(false);
	const [results, setResults] = useState([]);
	const [survey, setSurvey] = useState(content);
	const [loading, setLoading] = useState(true);
	const [allUsers, setAllUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);
	const [allYears, setAllYears] = useState({});
	const [selectedClass, setSelectedClass] = useState('all');
	const [selectedYear, setSelectedYears] = useState('all');
	const [searchText, setSearchText] = useState('');
	const [resetTrigger, setResetTrigger] = useState(new Date());
	const [initialLoading, setInitialLoading] = useState(true);

	const [visibleSections, setVisibleSections] = useState(1);

	const { t, locale } = useTranslation();
	const containerRef = useRef(null);

	useEffect(() => {
		loadSurveyResults();
		loadSurvey();
	}, []);

	useEffect(() => {
		const container = containerRef.current;

		const handleScroll = () => {
			const scrollTop = container.scrollTop;
			const scrollHeight = container.scrollHeight;
			const clientHeight = container.clientHeight;

			const scrollBottom = scrollHeight - scrollTop - clientHeight;
			const threshold = 100; // Adjust the threshold as needed

			if (scrollBottom < threshold) {
				// The scroll is close to the bottom
				setVisibleSections(visibleSections + 1);
				// Trigger the action you want here, such as fetching more data
			}
		};

		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [visibleSections]);

	const doSearch = (text) => {
		if (text === '') {
			setFilteredUsers([...allUsers].map((e) => e.id));
			setFilteredResults(results);
		} else {
			const users = allUsers.filter((e) => `${e.first_name} ${e.last_name}`.toLowerCase().includes(text)).map((e) => e.id);
			// const filteredResults = results[0]?.questions[0]?.answersUsers.filter((e) => users.includes(e.user_id));

			const filteredResults = results.map((section) => ({
				...section,
				questions: section.questions.map((question) => {
					const newAnswersUsers = question.answersUsers.filter((e) => users.includes(e.user_id));
					return {
						...question,
						answersUsers: newAnswersUsers,
						answers: newAnswersUsers.map((e) => e.answer),
					};
				}),
			}));
			setFilteredResults(filteredResults);
			setFilteredUsers(users);
		}
		setResetTrigger(new Date());
	};

	useEffect(() => {
		if (!initialLoading) {
			const delayDebounceFn = setTimeout(() => {
				doSearch(searchText.toLowerCase());
			}, 400);
			return () => clearTimeout(delayDebounceFn);
		}
	}, [searchText]);

	const loadSurveyResults = async () => {
		const resp = await getSurveyResults(content.id);
		if (resp.status === 200) {
			const data = resp.data;
			setResults(data);
			setFilteredResults(data);
			setLoading(false);
			setInitialLoading(false);
		}
	};

	const loadSurvey = async () => {
		const resp = await getLiveResults({ user_id: user.id, content_id: content?.id, refresh_data: false });
		if (resp.status === 200) {
			const data = resp.data;
			const _survey = { ...data.results[0], status: survey.status };
			setSurvey(_survey);
			const users = _survey.users;
			let yearsDict = {};

			for (let i = 0; i < users.length; i++) {
				const user = users[i];
				const year = user.year;
				const className = user.classesName;

				if (!yearsDict[year]) {
					yearsDict[year] = new Set();
				}
				yearsDict[year].add(className);
			}

			for (const year in yearsDict) {
				yearsDict[year] = Array.from(yearsDict[year]);
			}
			setAllUsers(users);
			setFilteredUsers(users.map((e) => e.id));
			setAllYears(yearsDict);
		}
	};

	const submitClassFilter = (filterData) => {
		const className = filterData.class;
		const yearName = filterData.year;
		let users = [];
		if (className === 'all' && yearName === 'all') {
			users = allUsers.map((e) => e.id);
		} else if (yearName !== 'all' && className === 'all') {
			users = allUsers.filter((e) => e.year === yearName).map((e) => e.id);
		} else {
			users = allUsers.filter((e) => e.classesName === className).map((e) => e.id);
		}
		setSearchText('');
		setFilteredUsers(users);
		setSelectedClass(className);
		setSelectedYears(yearName);
		const filteredResults = results.map((section) => ({
			...section,
			questions: section.questions.map((question) => {
				const newAnswersUsers = question.answersUsers.filter((e) => users.includes(e.user_id));
				return {
					...question,
					answersUsers: newAnswersUsers,
					answers: newAnswersUsers.map((e) => e.answer),
				};
			}),
		}));
		setFilteredResults(filteredResults);
	};

	const exportData = async () => {
		setExporting(true);
		const token = localStorage.getItem('token');
		const data = content;
		var req = {
			method: 'POST',
			url: `${baseUrl}/api/export_dashboard_content`,
			responseType: 'arraybuffer',
			headers: { Authorization: token },
			data: data,
		};
		var reader = new FileReader();

		reader.onload = function (e) {
			window.open(decodeURIComponent(reader.result), '_self', '', false);
		};

		axios(req).then(
			function (response) {
				var contentType = response.headers['content-type'];
				var blob = new Blob([response.data], { type: contentType });

				var a = document.createElement('a');
				document.body.appendChild(a);
				a.style = 'display: none';

				var url = window.URL.createObjectURL(blob);
				a.href = url;

				a.download = `Content data-${content?.title}.xlsx`;
				a.click();
				setExporting(false);
			},
			function (resp) {}
		);
	};

	const stop = async () => {
		const resp = await stopContent({ id: content?.id });
		if (resp.status == 200) {
			content.status = 'stop';
			setRefresh(content?.id);
		}
	};

	return (
		<div>
			<div className='bg-[#edf3ff] w-full p-5 px-6 rounded-xl'>
				<div className='flex justify-between items-center'>
					<h2 className='font-bold text-xl  text-deepPurple text-left capitalize '>{t(content?.type)}</h2>
					{/* <p className='text-mudPurple text-[16px] capitalize'>{t(content?.status)}</p> */}
				</div>
				{content?.status == 'stop' ? (
					// <div
					// 	className='border pointer fill-mudPurple bg-lightBG hover:fill-white hover:bg-deselectPurple text-mudPurple hover:text-white border-deselectPurple py-3 px-5 rounded-full w-full mt-2 flex justify-center items-center'
					// 	onClick={exportData}
					// >
					// 	<span className='text-[16px] font-semibold  mr-3'>{t('download')}</span>{' '}
					// 	<DownloadIcon style={{ fill: 'inherit' }} />
					// </div>
					<div></div>
				) : (
					<div className='flex flex-row w-full justify-between items-center mt-4'>
						<div
							className='border pointer fill-mudPurple bg-lightBG hover:fill-white hover:bg-deselectPurple text-mudPurple hover:text-white border-deselectPurple py-3 px-5 rounded-full w-52 flex justify-center items-center'
							onClick={stop}
						>
							<span className='text-[16px] font-semibold  mr-3'>{t('stop')}</span> <Stop style={{ fill: 'inherit' }} />
						</div>
						<Link to={`/content?editId=${content?.id}`}>
							<div className='border pointer fill-mudPurple bg-lightBG hover:fill-white hover:bg-deselectPurple text-mudPurple hover:text-white border-deselectPurple py-3 px-5 rounded-full w-52 flex justify-center items-center'>
								<span className='text-[16px] font-semibold  mr-3'>{t('edit')}</span>{' '}
								<EditContent style={{ fill: 'inherit' }} />
							</div>
						</Link>
						{/* <div
							className='border pointer fill-mudPurple bg-lightBG hover:fill-white hover:bg-deselectPurple text-mudPurple hover:text-white border-deselectPurple py-3 px-5 rounded-full w-52 flex justify-center items-center'
							onClick={exportData}
						>
							<span className='text-[16px] font-semibold  mr-3'>{t('download')}</span>{' '}
							<DownloadIcon style={{ fill: 'inherit' }} />
						</div> */}
						<div
							onClick={() => deleteContent(content)}
							className='border pointer fill-deleteRed bg-lightBG hover:fill-white hover:bg-deleteRed text-deleteRed hover:text-white border-deleteRed py-3 px-5 rounded-full w-52 flex justify-center items-center'
						>
							<span className='text-[16px] font-semibold  mr-3'>{t('delete')}</span>{' '}
							<DeleteContentIcon style={{ fill: 'inherit' }} />
						</div>
					</div>
				)}
				{!!allUsers.length && content?.target_text !== 'Staff' && (
					<ContentResultFilter
						data={allYears}
						setSearchText={setSearchText}
						searchText={searchText}
						submitClassFilter={submitClassFilter}
						resetTrigger={resetTrigger}
					/>
				)}
			</div>

			<div className='px-2 py-3 rounded-xl'>
				<div className='gray-small-scroll px-5' ref={containerRef} style={{ overflowY: 'scroll', height: '60vh' }}>
					<div className='flex justify-between items-center mb-3'>
						<p className='text-sm text-mudPurple'>
							<span className='text-[#9392ad99]'>{t('by')}:</span> {content?.created_by?.first_name}{' '}
							{content?.created_by?.last_name}
						</p>
						<span className='text-mudPurple font-medium text-sm'>
							<span className='text-[#9392ad99]'>{t('target')}:</span>{' '}
							{!!content?.anonymous ? t('anonymous') : translateTargetText(content?.target_text)}
						</span>
						<p>
							<span className='text-[#9392ad99] text-sm'>
								{t('date')} & {t('time')}:{' '}
							</span>
							<span className='text-mudPurple text-sm'>{format_day(content?.publicationDate, true, i18n.language)}</span>
						</p>
					</div>
					<div className='single-content-body my-3 flex justify-between items-start'>
						<h2 className='text-deepPurple font-bold text-xl text-left max-w-xl'>{content?.title}</h2>
						<p className='text-right text-sm text-mudPurple font-medium'>
							{content?.answers?.length}/{content?.target_count}
						</p>
					</div>
					<p className='text-sm text-mudPurple text-left mt-3'>{content?.message ?? content?.description}</p>
					<div className='mt-4'>
						<ProgressBar
							bgcolor={'#5E639B'}
							completed={Math.round((content?.answers?.length / content?.target_count ?? 1) * 100)}
						/>
					</div>

					{!loading && (
						<div className='mt-5'>
							{results
								.filter((i, index) => index < visibleSections)
								?.map((section, index) => {
									return (
										<div className='bg-whiteContrast p-4 rounded-xl my-5' key={index}>
											<h3 className='text-left text-mudPurple text-lg font-semibold'>{section?.title}</h3>
											<p className='text-left text-sm text-mudPurple mt-2'>{section?.description}</p>
											<div>
												{section?.questions?.map((question, qi) => {
													return (
														<ContentSurveyQuestion
															question={question}
															realAnswers={question.answers ?? []}
															qi={qi}
															index={index}
															survey={survey}
															key={qi}
															filterName={searchText}
															filteredUsers={filteredUsers}
															filteredResults={filteredResults[index]?.questions[qi]}
														/>
													);
												})}
											</div>
										</div>
									);
								})}
						</div>
					)}
					{loading && (
						<div className=' w-full  flex items-center justify-center -mb-[20px]' style={{ height: 'calc(100% - 120px)' }}>
							<Loading size={32} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default ContentSurveyResults;
