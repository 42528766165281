import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as SearchMagnifyIcon } from '../../assets/svg/SearchMagnifyIcon.svg';
import '../../styles/modals/PollRepeatModal.css';
import '../../styles/modals/RoleModal.css';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalIcon } from '../../assets/svg/CloseModalIcon.svg';
import '../../styles/modals/TeacherModal.css';
import { getSchoolClasses, getSchoolYears } from '../../server/server';

function TeacherRoleModal({ showModal, closeModal = () => {}, roles = [], roleModel = {} }) {
	const { t, locale } = useTranslation();
	const [mode, setMode] = useState('root');
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedRoleType, setSelectedRoleType] = useState({});
	const [selectedItems, setSelectedItems] = useState([]);
	const [searchText, setSearchText] = useState('');

	const handleChange = (e) => {
		setSearchText(e.target.value);
	};

	const myFilter = (item) => {
		const text = searchText?.toLowerCase();
		return item?.name?.toLowerCase()?.includes(text);
	};

	const clickOnRoleButton = (role) => {
		setMode(!!role?.right ? role.right : 'root');
		setSelectedRoleType(role);
		if (role?.right === 'class') getClasses();
		if (role?.right === 'year') getYears();
	};

	const getClasses = async () => {
		const resp = await getSchoolClasses();
		if (resp.status === 200) {
			setList(resp.data);
			setLoading(false);
		}
	};
	const getYears = async () => {
		const resp = await getSchoolYears();
		if (resp.status === 200) {
			setList(resp.data);
			setLoading(false);
		}
	};

	const selectClassItem = (item) => {
		const index = selectedItems.findIndex((e) => e == item.code);
		if (index != -1) setSelectedItems(selectedItems.filter((e) => e != item.code));
		else setSelectedItems([...selectedItems, item.code]);
	};

	const submit = () => {
		let data = {
			role: selectedRoleType?.role,
		};
		let text = selectedRoleType?.name;
		data['target_text'] = 'All students';
		if (selectedRoleType?.right === 'class') {
			data['coverage'] = selectedItems;
			data['scope'] = selectedItems;
			data['field'] = 'class';
			data['target_text'] =
				selectedItems.length > 1 ? `Multiple classes` : `Class ${list.filter((e) => selectedItems.includes(e.code))[0]?.name}`;
		} else if (selectedRoleType?.right === 'year') {
			data['coverage'] = selectedItems;
			data['scope'] = selectedItems;
			data['field'] = 'year';
			data['target_text'] =
				selectedItems.length > 1 ? `Multiple years` : `Year ${list.filter((e) => selectedItems.includes(e.code))[0]?.name}`;
		}
		closeModal({
			text,
			data,
		});
	};

	React.useEffect(() => {
		if (showModal) {
			console.log('RoleModel', roleModel);
			if (!!roleModel?.role) {
				setSelectedItems(roleModel?.coverage ?? []);
				const index = roles.findIndex((e) => e.role == roleModel.role);
				const role = roles[index];
				clickOnRoleButton(role);
			}
			ReactModal.setAppElement('#root');
			document.getElementById('home').style.filter = 'blur(5px)';
		} else {
			document.getElementById('home').style.filter = 'unset';
		}
	}, [showModal]);

	return (
		<ReactModal
			transparent
			closeTimeoutMS={600}
			isOpen={showModal}
			onAfterClose={() => {
				setMode('root');
				setList([]);
				setSelectedItems([]);
				setSearchText('');
			}}
			onRequestClose={closeModal}
			className='noScroll'
			style={{
				overlay: {
					zIndex: 0,
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 654,
					margin: 'auto',
					backgroundColor: 'transparent',
					maxHeight: '100vh',
					border: 'none',
					boxShadow: 'none',
					overflow: 'scroll',
					paddingTop: 35,
					paddingBottom: 35,
				},
			}}
		>
			<div className='w-full justify-center flex relative'>
				<div
					className='hover:bg-darkenPurple pointer absolute top-[0px] flex bg-deepPurple rounded-full w-[60px] h-[60px] justify-center items-center'
					onClick={() => closeModal()}
				>
					<CloseModalIcon />
				</div>

				<div className='mt-[30px] w-full rounded-[10px] bg-whiteContrast p-10 '>
					<div>
						<div className='teacher-role-model-title'>
							{t('teacherRole')}
							{!!selectedRoleType?.role && <span>: {selectedRoleType?.name}</span>}
						</div>
						<div className='teacher-role-model-subtitle'>{t('selectTeacherRole')} </div>
					</div>
					{mode === 'root' && (
						<div className='flex flex-row flex-wrap pt-[40px] -mx-[11px]'>
							{roles.map((role, index) => {
								return (
									<div className='w-[50%] px-[11px] mb-[20px]' key={index}>
										<button
											className='teacher-role-button'
											onClick={() => {
												clickOnRoleButton(role);
											}}
											key={index}
										>
											<p className='teacher-role-name'>{role?.name}</p>
											<p className='teacher-role-text'>{role?.right_text}</p>
										</button>
									</div>
								);
							})}
						</div>
					)}
					{mode === 'class' && (
						<div className=''>
							<div className='teacher-role-classes-container'>
								<div className='teacher-role-classes-container-search'>
									<SearchMagnifyIcon />
									<input placeholder={t('search')} value={searchText} onChange={(e) => handleChange(e)} />
								</div>
								<div className='teacher-role-classes-container-list'>
									{list
										?.filter((item) => myFilter(item))
										.map((element, index) => {
											return (
												<button
													className='teacher-role-class-item'
													onClick={() => {
														selectClassItem(element);
													}}
													key={index}
												>
													<div className={`select-teacher-role-area`}>
														{selectedItems.includes(element.code) && (
															<div className='selected-teacher-role'></div>
														)}
													</div>
													<span className='teacher-role-class-item-name'>
														{t('Class')}: <span className='teacher-role-highline-text '>{element?.name}</span>
													</span>
													<span className='teacher-role-class-item-year'>
														{t('year')}: <span className='teacher-role-highline-text '>{element?.year}</span>
													</span>
													<span className='teacher-role-class-item-students'>
														{t('numberOfStudents')}:{' '}
														<span className='teacher-role-highline-text '>{element?.numberOfStudents}</span>
													</span>
												</button>
											);
										})}
								</div>
							</div>
							<div className='flex flex-row items-center'>
								<button
									onClick={() => {
										setMode('root');
										setSelectedRoleType({});
										setSelectedItems([]);
									}}
									className='teacher-role-back-button'
								>
									{t('back')}
								</button>
								<button
									className={`h-[65px] flex-1 rounded-[100px] font-semibold text-[18px] leading-[22px] text-center transition-all ${
										selectedItems.length === 0
											? 'bg-[#ECEBF8] text-[#BEBECE] cursor-default'
											: 'bg-deepPurple hover:bg-highlightColor text-highlightColor hover:text-deepPurple cursor-pointer'
									}`}
									onClick={submit}
									disabled={selectedItems.length === 0}
								>
									Ok
								</button>
							</div>
						</div>
					)}
					{mode === 'year' && (
						<div className=''>
							<div className='teacher-role-classes-container'>
								<div className='teacher-role-classes-container-search'>
									<SearchMagnifyIcon />
									<input placeholder={t('search')} value={searchText} onChange={(e) => handleChange(e)} />
								</div>
								<div className='teacher-role-classes-container-list'>
									{list
										?.filter((item) => myFilter(item))
										.map((element, index) => {
											return (
												<button
													className='teacher-role-class-item'
													onClick={() => {
														selectClassItem(element);
													}}
													key={index}
												>
													<div className={`select-teacher-role-area`}>
														{selectedItems.includes(element.code) && (
															<div className='selected-teacher-role'></div>
														)}
													</div>
													<span className='teacher-role-class-item-name'>
														{t('year')}: <span className='teacher-role-highline-text '>{element?.name}</span>
													</span>

													<span className='teacher-role-class-item-students'>
														{t('numberOfStudents')}:{' '}
														<span className='teacher-role-highline-text '>{element.numberOfStudents}</span>
													</span>
												</button>
											);
										})}
								</div>
							</div>
							<div className='flex flex-row items-center'>
								<button
									onClick={() => {
										setMode('root');
										setSelectedRoleType({});
										setSelectedItems([]);
									}}
									className='teacher-role-back-button'
								>
									{t('back')}
								</button>
								<button
									className={`h-[65px] flex-1 rounded-[100px] font-semibold text-[18px] leading-[22px] text-center transition-all ${
										selectedItems.length === 0
											? 'bg-[#ECEBF8] text-[#BEBECE] cursor-default'
											: 'bg-deepPurple hover:bg-highlightColor text-highlightColor hover:text-deepPurple cursor-pointer'
									}`}
									onClick={submit}
									disabled={selectedItems.length === 0}
								>
									Ok
								</button>
							</div>
						</div>
					)}
					{mode === 'all_rights' && (
						<div className=''>
							<div className='teacher-role-classes-container' style={{ alignItems: 'center', justifyContent: 'center' }}>
								<p className='teacher-role-admin'>
									{t('thisTeacherWillbe')} {selectedRoleType?.name}
								</p>
							</div>
							<div className='flex flex-row items-center'>
								<button
									onClick={() => {
										setMode('root');
										setSelectedRoleType({});
									}}
									className='teacher-role-back-button'
								>
									{t('back')}
								</button>
								<button className='teacher-role-submit-button' onClick={submit}>
									Ok
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</ReactModal>
	);
}

export default TeacherRoleModal;
