import { format } from 'date-fns';
import { FaCheck, FaCheckDouble } from 'react-icons/fa';
import { emotions_heads } from '../../constants/Constants';
import { datesAreOnSameDay, format_day } from '../../utils/util';
import { useTranslation } from 'react-i18next';

const Message = (props) => {
	const { t, locale, i18n } = useTranslation();
	const { message, user, messages, index, names, members, isGroupChat } = props;
	const time = format(new Date(message.created_date), 'HH:mm');
	const messageSender = message.user_id;
	const didUserSendMessage = messageSender === user.id;
	const diff_next = message?.created_date - messages[index + 1]?.created_date;
	const diff_previous = messages[index - 1]?.created_date - message?.created_date;
	const minutes_next = Math.ceil(diff_next / (1000 * 60)); // seconds
	const minutes_previous = Math.ceil(diff_previous / (1000 * 60)); // seconds
	const nextMessageSameSender = index + 1 <= messages?.length && messages[index + 1]?.user_id === messageSender && minutes_next < 15;
	const previousMessageSameSender = index - 1 >= 0 && messages[index - 1]?.user_id === messageSender && minutes_previous < 15;
	const nextDate = new Date(messages[index + 1]?.created_date);
	const messageType = didUserSendMessage ? 'sent' : 'received';
	const date = new Date(message?.created_date);
	const showDate = !datesAreOnSameDay(date, nextDate);
	const name = names[messageSender] || '';
	const seen = message.seen.length === members.length;
	const isFirstMessageInGroup = !nextMessageSameSender;
	const isMiddleMessageInGroup = previousMessageSameSender && nextMessageSameSender;
	const isLastMessageInGroup = !previousMessageSameSender;
	const messageClass =
		isFirstMessageInGroup && isLastMessageInGroup
			? 'first-message last-message'
			: isMiddleMessageInGroup
			? 'middle-message'
			: isLastMessageInGroup
			? 'last-message'
			: 'first-message';
	const isReply = !!message?.feedbackReply;

	return (
		<div key={message.id} className='single-message-group my-1'>
			<div className='flex flex-row justify-between items-center py-3'>
				<p className='text-sm text-mudPurple '>{didUserSendMessage ? t('myMessage') : name}</p>
				<p className='text-sm text-mudPurple '>{format_day(message.created_date, false, i18n.language)}</p>
			</div>

			<div className='w-full'>
				<div
					className={`w-full rounded-xl py-4 px-4 items-center ${
						didUserSendMessage ? 'border border-deselectPurple' : 'bg-[#eeeef5]'
					}`}
				>
					<div className='flex flex-row justify-between'>
						<div className='text-base text-deepPurple font-bold max-w-[90%]'>{message.message_body}</div>

						<span className='text-sm text-mudPurple'>{time}</span>
					</div>
					{isReply && (
						<div>
							<div className='flex flex-row items-center mt-[10px]'>
								<span className='mr-[5px] text-[14px] text-[#9392AD] font-medium'>{t('replyOn')} : </span>
								<img
									src={emotions_heads[message?.feedbackReply?.emotion]}
									width={20}
									height={20}
									alt={message?.feedbackReply?.emotion ?? 'emotion'}
								/>
							</div>
							{!!message?.feedbackReply?.student_message && (
								<div className='mt-[10px] text-[16px] text-[#5E639B] font-medium'>
									"{message?.feedbackReply?.student_message}"
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Message;
